import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { SCANNED_COOKIES } from '../constants'
import { Cookie } from '../types'

type Params = {
  projectId: number
}

const getScannedCookies = async (params: Params): Promise<Cookie[]> => {
  const { projectId } = params

  const response = await fetchAPI({
    path: `/projects/${projectId}/cookies`,
  })

  const data = get(response, 'data', [])

  return data
}

type UseQueryProps = {
  params: Params
  initialData: Cookie[]
}

export const useScannedCookies = (props: UseQueryProps) => {
  const { params, initialData } = props

  return useQuery<Cookie[], AxiosError>({
    queryKey: [SCANNED_COOKIES, params],
    queryFn: () => getScannedCookies(params),
    initialData,
  })
}
