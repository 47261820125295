import { z } from 'zod'
import { BANNER_LANGUAGE } from '@datawowio/cookie-banner-ui'

import { t } from '@/lib/helpers'

export const multilangSchema = z.object({
  ...Object.fromEntries(
    BANNER_LANGUAGE.map((key) => [
      key,
      z.string().refine((val) => val.trim(), {
        message: t('views.shared.input.required', undefined, 'Required'),
      }),
    ]),
  ),
})

export const editDialogInputSchema = z
  .object({
    accept_all_label: multilangSchema,
    ok_button_label: multilangSchema,
    preference_label: multilangSchema,
    reject_all_label: multilangSchema,
    view_details_label: multilangSchema,
  })
  .passthrough()
