import { Collapse, HStack, Text, Icon, Box, useToast } from '@chakra-ui/react'
import { BsFillExclamationTriangleFill } from 'react-icons/bs'
import { useFormik } from 'formik'

import { axios, getErrorMsg, t } from '@/lib/helpers'

type Props = {
  isOpen: boolean
  email: string
}

export const EmailConfirmationBanner = ({ isOpen, email }: Props) => {
  const toast = useToast()

  const formProps = useFormik({
    initialValues: {
      email,
    },
    onSubmit: async ({ email }) => {
      try {
        await axios.post('/users/confirmation', {
          email,
        })
        toast({
          title: t('views.components.layout.banner.email_sent'),
          status: 'success',
          variant: 'left-accent',
          position: 'top-right',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
  })

  return (
    <Collapse in={isOpen}>
      <Box
        sx={{
          h: '40px',
          bg: 'yellow.300',
          fontSize: '14px',
        }}
      >
        <HStack
          layerStyle="container"
          spacing="16px"
          sx={{
            h: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon
            as={BsFillExclamationTriangleFill}
            width="16px"
            color="brand.400"
          />

          <Text>{t('views.components.layout.banner.title')}</Text>

          <form
            method="post"
            action="/users/confirmation"
            onSubmit={formProps.handleSubmit}
          >
            <input
              name="email"
              style={{ display: 'none' }}
              value={formProps.values.email}
              onChange={formProps.handleChange}
            />
            <button
              type="submit"
              style={{
                textDecoration: 'underline',
                color: 'inherit',
                border: 'none',
                outline: 'none',
              }}
            >
              {t('views.components.layout.banner.resend_email')}
            </button>
          </form>
        </HStack>
      </Box>
    </Collapse>
  )
}
