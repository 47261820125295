import { z } from 'zod'

import { t } from '@/lib/helpers'

export const editUserSchema = z.object({
  folder_permissions: z.any().array(),
})

export const createUserSchema = editUserSchema.extend({
  email: z
    .string()
    .email({
      message: t('views.shared.form.invalid_email', undefined, 'Invalid Email'),
    })
    .nonempty({
      message: t('views.shared.input.required', undefined, 'Required'),
    }),
})
