const layerStyles = {
  container: {
    maxW: '1440px',
    mx: 'auto',
    p: 0,
  },
  mainContent: {
    bgColor: '#fff',
    borderRadius: '8px',
    mt: 8,
  },
  sectionHeader: {
    fontSize: '24px',
    padding: '24px 32px',
    borderBottom: '1px solid #ffede0',
    fontWeight: 500,
    borderRadius: '20px 20px 0 0',
  },
  sectionContent: {
    borderRadius: '0 0 20px 20px',
    backgroundColor: 'white',
    p: '0 32px 36px',
  },
  codeBlock: {
    borderRadius: '8px',
    backgroundColor: 'gray.100',
    border: '1px solid gray.300',
    padding: '24px',
    overflowY: 'auto',
    wordBreak: 'break-all',
  },
  popupMenuItem: {
    px: '3',
    py: '1',
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover': {
      bgColor: 'gray.100',
    },
  },
}

export default layerStyles
