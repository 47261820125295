import { Box, Text, Stack, RadioGroup, Radio, Collapse } from '@chakra-ui/react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAppContext } from '@/features/layout'

import { LogoUploader } from './LogoUploader'
import { RadioSection, UpgradeText } from '../../shared'

export const Branding = () => {
  const { isFree } = useAppContext()

  const { t: tDomainCustomize } = useTranslation('domainCustomize')

  const { watch } = useFormContext()

  const { field: logoTypeField } = useController({
    name: 'logo_type',
  })

  const showLogo = watch('show_logo')

  return (
    <Stack spacing="4">
      <RadioSection
        title={tDomainCustomize('branding.logo')}
        name="show_logo"
        id="CustomizeContent-show_logo"
        isDisabled={isFree}
      />

      <Collapse in={showLogo}>
        <RadioGroup
          colorScheme="brand"
          isDisabled={isFree}
          name={logoTypeField.name}
          onChange={logoTypeField.onChange}
          value={logoTypeField.value}
        >
          <Stack>
            <Radio value="cookiewow">
              {tDomainCustomize('branding.cookieWowLogo')}
            </Radio>
            <Radio value="custom">
              {tDomainCustomize('branding.customLogo')}
            </Radio>
          </Stack>
        </RadioGroup>
      </Collapse>

      <Collapse in={showLogo && logoTypeField.value === 'custom'}>
        <Stack spacing="4">
          <Controller
            name="logo"
            render={({ field }) => {
              return (
                <LogoUploader
                  name={field.name}
                  image={field.value}
                  onChange={field.onChange}
                  description={tDomainCustomize(
                    'branding.logoUploadDescription',
                  )}
                />
              )
            }}
          />

          <Box>
            <Text sx={{ color: 'brand.400', fontWeight: 700 }}>
              {tDomainCustomize('branding.position')}
            </Text>

            <Stack spacing="4" mt="2">
              <RadioSection
                title={tDomainCustomize('branding.prefCenterHeader')}
                name="show_preference_header_logo"
                id="CustomizeContent-show_preference_header_logo"
                isDisabled={isFree}
              />
              <RadioSection
                title={tDomainCustomize('branding.bannerFooter')}
                name="show_footer_logo"
                id="CustomizeContent-show_footer_logo"
                isDisabled={isFree}
              />
            </Stack>
          </Box>
        </Stack>
      </Collapse>

      <UpgradeText linkID="CustomizeBranding-upgrade" />
    </Stack>
  )
}
