import { useMemo } from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
  Box,
  Text,
  Icon,
} from '@chakra-ui/react'
import { FiChevronDown } from 'react-icons/fi'

import { LANGUAGE_OPTIONS } from '@/features/i18n/constants'
import { useAppContext } from '@/features/layout'

type Props = {
  handleChange: (value: BannerLanguage) => void
  id?: string
  isDisabled?: boolean
  isFullSize?: boolean
  isMini?: boolean
  value: BannerLanguage
}

export const LanguageSelect = (props: Props) => {
  const {
    handleChange,
    id = 'LanguageSelect',
    isDisabled = false,
    isFullSize = false,
    isMini = false,
    value,
  } = props

  const { isFree } = useAppContext()

  const option = useMemo(() => {
    return (
      LANGUAGE_OPTIONS.find((opt) => opt.value === value) || LANGUAGE_OPTIONS[0]
    )
  }, [value])

  return (
    <Menu id={id}>
      <MenuButton
        id="CustomizeLanguage-toggle"
        sx={{
          transition: 'all 0.2s',
          borderRadius: '4px',
          borderWidth: '1px',
          p: '8px',
          w: '100%',
          _disabled: {
            cursor: 'not-allowed',
            bg: 'gray.100',
          },
        }}
        disabled={isDisabled}
        type="button"
      >
        <Grid
          sx={{
            gridTemplateColumns:
              isFullSize || isMini ? '24px 1fr 14px' : '24px 14px',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              w: '24px',
              h: '18px',
              background: `no-repeat center/contain url(${option.icon})`,
            }}
          />
          {isFullSize && (
            <Text sx={{ textAlign: 'start' }}>{option.label}</Text>
          )}
          {isMini && (
            <Text sx={{ textAlign: 'start' }}>{option.shortLabel}</Text>
          )}
          <Icon as={FiChevronDown} />
        </Grid>
      </MenuButton>

      <MenuList sx={{ minW: '144px' }}>
        {LANGUAGE_OPTIONS.map((opt) => (
          <MenuItem
            key={opt.value}
            onClick={
              value !== opt.value ? () => handleChange(opt.value) : undefined
            }
            type="button"
            data-testid={`${id}-${opt.value}`}
            isDisabled={isFree && opt.isPaid}
          >
            <Grid
              sx={{
                gridTemplateColumns: '20px 1fr',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  w: '20px',
                  h: '16px',
                  background: `no-repeat center/contain url(${opt.icon})`,
                }}
              />
              <Text>{opt.label}</Text>
            </Grid>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
