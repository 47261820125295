import {
  Box,
  ButtonGroup,
  Collapse,
  Input,
  Link,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/button'
import { useFormContext, useWatch } from 'react-hook-form'
import { Fragment } from 'react'

import { t } from '@/lib/helpers'
import { DEFAULT_CONTENT_TEMPLATE } from '@/features/domains/constants'
import { BannerLanguage } from '@/features/domains/types'

import { DefaultLanguage } from './DefaultLanguage'
import { AutoDetect } from './AutoDetect'
import { FormControl, RadioSection } from '../../shared'

export type LanguageAndContentProps = {
  isNoticeOnly: boolean
}

export const LanguageAndContent = ({
  isNoticeOnly,
}: LanguageAndContentProps) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const previewLanguage = useWatch({
    name: 'preview_language',
  }) as BannerLanguage
  const title = useWatch({ name: 'title' })
  const content = useWatch({ name: 'content' })
  const showTitle = useWatch({ name: 'show_title' })

  const handleSetContentTemplate = (
    variant: 'short' | 'long' | 'notice_only',
  ) => {
    setValue(
      `title.${previewLanguage}`,
      DEFAULT_CONTENT_TEMPLATE[previewLanguage][variant].title,
    )
    setValue(
      `content.${previewLanguage}`,
      DEFAULT_CONTENT_TEMPLATE[previewLanguage][variant].content,
    )
  }

  return (
    <Stack spacing="16px">
      <Text sx={{ color: 'brand.400', fontWeight: 700 }}>
        {t('views.misc.languages')}
      </Text>

      <DefaultLanguage />

      <Box
        sx={{
          pb: '16px',
          borderBottom: '1px solid',
          borderColor: 'brand.50',
        }}
      >
        <AutoDetect />
      </Box>

      <Stack>
        <Text sx={{ color: 'brand.400', fontWeight: 700 }}>
          {t(
            'views.features.domains.show.customize.customize_content',
            undefined,
            'Customize Content',
          )}
        </Text>

        <RadioSection
          title={t('views.features.domains.show.customize.show_banner_title')}
          name="show_title"
          id="CustomizeContent-show_title"
        />
      </Stack>

      <Collapse in={showTitle}>
        <FormControl
          label={t(
            'views.features.domains.show.customize.title_banner',
            undefined,
            'Title',
          )}
          id={'title'}
          errorMsg={errors.title?.message}
        >
          <Input
            id="CustomizeContent-title"
            value={title[previewLanguage]}
            name={`title.${previewLanguage}`}
            onChange={({ target: { value } }) => {
              setValue('title', { ...title, [previewLanguage]: value })
            }}
          />
        </FormControl>
      </Collapse>

      <FormControl
        label={t(
          'views.features.domains.show.customize.content',
          undefined,
          'Content',
        )}
        id={'content'}
        errorMsg={errors.content?.message}
      >
        <Textarea
          id="CustomizeContent-content"
          value={content[previewLanguage]}
          name={`content.${previewLanguage}`}
          onChange={({ target: { value } }) => {
            setValue('content', { ...content, [previewLanguage]: value })
          }}
          sx={{ resize: 'none', h: '172px' }}
        />
      </FormControl>

      <Stack>
        <Text sx={{ fontWeight: 700 }}>
          {t(
            'views.features.domains.show.customize.use_default_content',
            undefined,
            'Use default content',
          )}
        </Text>

        <ButtonGroup
          sx={{
            button: {
              fontWeight: 500,
              letterSpacing: 'normal',
              textTransform: 'capitalize',
              borderRadius: '16px',
              px: '18px',
            },
          }}
          variant="outline"
          size="md"
          spacing="8px"
        >
          {isNoticeOnly ? (
            <Button
              id="CustomizeContent-notice_only"
              onClick={() => handleSetContentTemplate('notice_only')}
            >
              {t(
                'views.features.domains.show.customize.reset_content_default',
                undefined,
                'Reset content to default',
              )}
            </Button>
          ) : (
            <Fragment>
              <Button
                id="CustomizeContent-short"
                onClick={() => handleSetContentTemplate('short')}
              >
                {t(
                  'views.features.domains.show.customize.short',
                  undefined,
                  'Short',
                )}
              </Button>
              <Button
                id="CustomizeContent-long"
                onClick={() => handleSetContentTemplate('long')}
              >
                {t(
                  'views.features.domains.show.customize.long',
                  undefined,
                  'Long',
                )}
              </Button>
            </Fragment>
          )}
        </ButtonGroup>
      </Stack>

      <Link
        sx={{ color: 'brand.400', textDecor: 'underline' }}
        isExternal
        href="https://help.cookiewow.com/th/article/banner-multilingual-oyhnod/?bust=1621914525973"
      >
        {t(
          'views.features.domains.show.customize.setup_manual_for_multilang',
          undefined,
          'Setup manual for multilingual website',
        )}
      </Link>
    </Stack>
  )
}
