import { get, startsWith, endsWith, trimEnd } from 'lodash'

import { PathParams, routes } from '@/features/routes'

const isMatchPathnamePattern = (pattern: string) => {
  let currentPath = window.location.pathname

  const patternArr = pattern.split('/')

  if (endsWith(currentPath, '/')) {
    currentPath = trimEnd(currentPath, '/')
  }

  const currentPathArr = currentPath.split('/')

  if (patternArr.length !== currentPathArr.length) return false

  let isMatch = true

  for (let i = 0; i < patternArr.length; i += 1) {
    if (startsWith(patternArr[i], '[')) continue

    if (patternArr[i] !== currentPathArr[i]) {
      isMatch = false
      break
    }
  }

  return isMatch
}

const getRouteName = () => {
  const routeObj = routes.find((r) => {
    return isMatchPathnamePattern(r.pattern)
  })

  return get(routeObj, 'name')
}

const getRoutePattern = () => {
  const routeObj = routes.find((r) => {
    return isMatchPathnamePattern(r.pattern)
  })

  return get(routeObj, 'pattern')
}

export const useActiveRoute = () => {
  return function (targetRoutes: string[] = []) {
    const routeName = getRouteName()

    return targetRoutes.includes(routeName)
  }
}

export const getPathParams = () => {
  const pathname = window.location.pathname
  const result: {
    [K in PathParams]?: string
  } = {}

  const pattern = getRoutePattern()

  if (!pattern) return result

  const pathnameArr = pathname.split('/')
  const patternArr = pattern.split('/')

  for (let i = 0; i < pathnameArr.length; i += 1) {
    const section = patternArr[i]

    if (startsWith(section, '[')) {
      const key = section.substring(1, section.length - 1) as PathParams

      result[key] = pathnameArr[i]
    }
  }

  return result
}
