export default {
  passwordExpired: {
    subHeader: 'Due to your policy has a password age limit.',
    signInAnotherAccount: 'Sign in with another account',
  },
  passwordHint: {
    title: 'A password must contain:',
    lowercase: 'Lowercase characters (a-z)',
    uppercase: 'Uppercase characters (A-Z)',
    digits: 'Number (0-9)',
    symbols: 'Special character',
    min: 'At least 8 characters',
  },
}
