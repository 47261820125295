import { ReactNode } from 'react'
import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { noop } from 'lodash'

import { Modal } from '../shared'
import { ModalHeader } from '@chakra-ui/modal'
import { Box, HStack } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { t } from '@/lib/helpers'

export type PromptProps = {
  cancelText?: string
  children?: ReactNode
  colorScheme?: string
  disclosure: UseDisclosureReturn
  isLoading?: boolean
  okText?: string
  onConfirm?: () => Promise<void> | void
  title?: string
}

export const Prompt = (props: PromptProps) => {
  const {
    cancelText = t('views.shared.button.cancel', undefined, 'Cancel'),
    children,
    colorScheme = 'red',
    disclosure,
    isLoading,
    okText = t('views.shared.button.confirm', undefined, 'Confirm'),
    onConfirm = noop,
    title = `${t('views.shared.button.confirm', undefined, 'Confirm')} ?`,
  } = props

  const { onClose, isOpen } = disclosure

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxW="480px"
      closeOnOverlayClick={false}
      isLoading={isLoading}
    >
      <ModalHeader sx={{ fontSize: '20px', pb: '4' }}>{title}</ModalHeader>

      {children && <Box sx={{ color: 'gray.600', px: 6 }}>{children}</Box>}

      <HStack
        spacing="12px"
        sx={{ mt: 5, px: 5, pb: 1, justifyContent: 'flex-end' }}
      >
        <Button colorScheme="gray" onClick={onClose} isDisabled={isLoading}>
          {cancelText}
        </Button>
        <Button
          colorScheme={colorScheme}
          onClick={onConfirm}
          isLoading={isLoading}
        >
          {okText}
        </Button>
      </HStack>
    </Modal>
  )
}
