import { useMemo } from 'react'
import { Column } from 'react-table'

import { DataTable } from '@/components/DataDisplay/Table'

import { ActivityLog } from '../../types'
import { formatDateTime, t } from '@/lib/helpers'
import { Stack, Text, Tooltip } from '@chakra-ui/react'

export type ActivityLogTableProps = {
  data: ActivityLog[]
  isLoading: boolean
}

export const ActivityLogTable = (props: ActivityLogTableProps) => {
  const { data, isLoading } = props

  const columns = useMemo<Array<Column<ActivityLog>>>(() => {
    return [
      {
        Header: t(
          'views.features.activity_logs.index.timestamp',
          undefined,
          'Timestamp',
        ),
        accessor: 'created_at',
        disableSortBy: true,
        width: 28,
        Cell(cellProps) {
          const value = cellProps.value

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.features.activity_logs.index.user', undefined, 'User'),
        accessor: 'user',
        disableSortBy: true,
      },
      {
        Header: t(
          'views.features.activity_logs.index.domain',
          undefined,
          'Domain',
        ),
        accessor: 'domain',
        disableSortBy: true,
        Cell(cellProps) {
          const row = cellProps.row.original

          return (
            <CellWithSubtitle
              title={row.domain}
              subtitle={row.folder_name}
              projectToken={row.project_token}
            />
          )
        },
      },
      {
        Header: t(
          'views.features.activity_logs.index.function-feature',
          undefined,
          'Function/Feature',
        ),
        accessor: 'function',
        disableSortBy: true,
        width: 28,
        Cell(cellProps) {
          const row = cellProps.row.original

          return <CellWithSubtitle title={row.function} subtitle={row.action} />
        },
      },
      {
        Header: t(
          'views.features.activity_logs.index.description',
          undefined,
          'Description',
        ),
        accessor: 'description',
        disableSortBy: true,
        width: 56,
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <DataTable<ActivityLog>
      columns={columns}
      data={memoizedData}
      isLoading={isLoading}
      showPagination
      tableSx={{ fontSize: '14px' }}
    />
  )
}

type CellWithSubtitle = {
  title: string
  subtitle: string
  projectToken?: string
}

const CellWithSubtitle = (props: CellWithSubtitle) => {
  const { title, subtitle, projectToken } = props

  return (
    <Stack spacing="0">
      {projectToken ? (
        <Tooltip
          label={projectToken}
          aria-label="project token"
          placement="top"
          hasArrow
        >
          <Text>{title ?? '-'}</Text>
        </Tooltip>
      ) : (
        <Text>{title ?? '-'}</Text>
      )}
      <Text sx={{ fontSize: '12px', color: 'gray.600' }}>{subtitle}</Text>
    </Stack>
  )
}
