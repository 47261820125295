import { useTranslation } from 'react-i18next'

import Image from '@/images/project/auth/set_password.svg'

import { t } from '@/lib/helpers'

import AuthLayout from '../../layouts/AuthLayout'
import { withAuthPage } from '@/features/layout'

import { SetPasswordExpireForm } from '../../components'

type Props = {
  formActionPath: string
}

function SetPasswordExpire(props: Props) {
  const { t: tAuth } = useTranslation('auth')

  return (
    <AuthLayout
      image={Image}
      header={t('devise.passwords.edit.change_my_password')}
      subHeader={tAuth('passwordExpired.subHeader')}
      showBackHome={false}
    >
      <SetPasswordExpireForm formActionPath={props.formActionPath} />
    </AuthLayout>
  )
}

export default withAuthPage(SetPasswordExpire)
