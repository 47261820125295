import { useRef } from 'react'
import {
  Box,
  Button,
  CSSObject,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
  Icon,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

import { RecommendedBadge } from '../../Customize/components'

import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'

import { BannerModelFormInput } from '../types'

import { useUpdateBannerModel } from '../mutations'
import { useAbility } from '@/features/permissions'
import { NoticeOnlyWarningModal } from '../components'

type Props = {
  defaultValues: BannerModelFormInput
}

export const BannerModel = (props: Props): React.ReactElement => {
  const { defaultValues } = props

  const { t: tCommon } = useTranslation('common')
  const disclosure = useDisclosure()

  const query = getPathParams()
  const params = { project_id: Number(query?.project_id) }

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    watch,
  } = useForm<BannerModelFormInput>({
    defaultValues,
  })

  const { mutate: updateBannerModel, isLoading } = useUpdateBannerModel()

  const defaultValuesRef = useRef<BannerModelFormInput>()

  const ability = useAbility()
  const canUpdate = ability.can('update', 'customization')

  const isDisabled = !canUpdate

  const bannerModel = watch('banner_model')

  const handleSubmitForm = (data: BannerModelFormInput) => {
    defaultValuesRef.current = data
    updateBannerModel(
      {
        data,
        params,
      },
      {
        onSuccess() {
          reset(data)
          disclosure.onClose()
        },
      },
    )
  }

  return (
    <Box
      sx={{ h: 'full' }}
      as="form"
      className="BannerModelForm"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <Flex sx={{ flexDir: 'column', h: 'full' }}>
        <Stack spacing={3}>
          <Flex justify="space-between">
            <Text sx={{ fontSize: '18px', fontWeight: 600 }}>
              {t(
                'views.features.domains.show.customize.banner_model_setting',
                undefined,
                'Banner Model Setting',
              )}
            </Text>

            <HStack sx={{ mt: 'auto', justifyContent: 'flex-end' }}>
              <Button
                isDisabled={isLoading || !isDirty}
                variant="outline"
                onClick={() => reset(defaultValuesRef.current)}
                className="DiscardChangesButton"
                size="sm"
              >
                {tCommon('discardChanges')}
              </Button>
              <Button
                isLoading={isLoading}
                isDisabled={!isDirty || isLoading}
                size="sm"
                minW="120px"
                onClick={disclosure.onOpen}
              >
                {tCommon('save')}
              </Button>
            </HStack>
          </Flex>

          <Controller
            name="banner_model"
            defaultValue={defaultValues.banner_model}
            control={control}
            render={({ field }) => (
              <RadioGroup colorScheme="brand" size="lg" {...field}>
                <Stack spacing="5">
                  <Stack>
                    <Radio
                      value="default"
                      isDisabled={isDisabled}
                      sx={{ alignItems: 'center' }}
                    >
                      <HStack>
                        <Text sx={{ fontWeight: 600 }}>Default</Text>

                        <RecommendedBadge />
                      </HStack>
                    </Radio>

                    <ShrinkableText
                      shortenText={t(
                        'views.features.domains.show.customize.default_description_short',
                      )}
                      fullText={t(
                        'views.features.domains.show.customize.default_description_full',
                      )}
                      sx={{ pl: '28px' }}
                    />
                  </Stack>

                  <Stack>
                    <Radio value="notice_only" isDisabled={isDisabled}>
                      <Text sx={{ fontWeight: 600 }}>Notice Only</Text>
                    </Radio>

                    <ShrinkableText
                      shortenText={t(
                        'views.features.domains.show.customize.notice_only_description_short',
                      )}
                      fullText={t(
                        'views.features.domains.show.customize.notice_only_description_full',
                      )}
                      sx={{ pl: '28px' }}
                    />
                  </Stack>
                </Stack>
              </RadioGroup>
            )}
          />

          <Box
            sx={{
              p: '13px 24px',
              border: '1px solid',
              borderColor: 'red.300',
              borderRadius: 'md',
              mb: '24px',
              bg: 'red.50',
              color: 'red.300',
            }}
          >
            <HStack align="center">
              <Text sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
                {t('views.misc.caution', undefined, 'Caution')}
              </Text>
              <Icon as={AiOutlineInfoCircle} color="red.300" boxSize="18px" />
            </HStack>
            <Text>
              {t('views.features.domains.show.customize.notice_only_caution')}
            </Text>
          </Box>
        </Stack>
      </Flex>

      <NoticeOnlyWarningModal
        disclosure={disclosure}
        isLoading={isLoading}
        onClick={() => handleSubmitForm({ banner_model: bannerModel })}
      />
    </Box>
  )
}

type ShrinkableTextProps = {
  shortenText: React.ReactNode
  fullText: React.ReactNode
  sx?: CSSObject
}

const ShrinkableText = (props: ShrinkableTextProps) => {
  const { shortenText, fullText, sx } = props

  const { isOpen, onToggle } = useDisclosure()

  return (
    <Text sx={sx}>
      {isOpen ? fullText : shortenText}&nbsp;
      <Button
        variant="link"
        onClick={onToggle}
        sx={{ color: 'brand.400', fontSize: 'sm', whiteSpace: 'pre' }}
      >
        {isOpen
          ? t(
              'views.features.domains.show.customize.show_less',
              undefined,
              'Show Less',
            )
          : t(
              'views.features.domains.show.customize.read_more',
              undefined,
              'Read More',
            )}
      </Button>
    </Text>
  )
}
