import { CreditCard } from '@/features/payments/types'

export const getNewCard = (
  newCards: CreditCard[] | undefined,
  prevCards: CreditCard[],
) => {
  if (newCards === undefined) return []
  return newCards.filter((card1) => {
    return !prevCards.some((card2) => {
      return card1.id === card2.id
    })
  })
}

export const sortCreditCards = (
  cards: CreditCard[] = [],
  subscriptionCreditCard: CreditCard | undefined,
): CreditCard[] => {
  const nonSubscriptionCards = cards
    .filter((c) => c.id !== subscriptionCreditCard?.id)
    .sort((a, b) => b.id - a.id)

  return subscriptionCreditCard
    ? [subscriptionCreditCard].concat(nonSubscriptionCards)
    : cards
}
