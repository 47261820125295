import { Grid, Progress, Text } from '@chakra-ui/react'
import * as d3 from 'd3-format'

import { t } from '@/lib/helpers'

export type PageviewInfoProps = {
  pageviewUsed: number
  pageviewLimit?: number | null
}

export const PageviewInfo = (props: PageviewInfoProps) => {
  const { pageviewLimit, pageviewUsed } = props

  const formattedTotalPageviews = pageviewLimit
    ? d3.format(',d')(pageviewLimit)
    : t('views.features.packages.packages.unlimited', undefined, 'Unlimited')
  const formattedCurrentPageviews = d3.format(',d')(pageviewUsed)

  const percent = pageviewLimit ? (pageviewUsed / pageviewLimit) * 100 : 100
  const isExceed = percent >= 100 && pageviewLimit !== null

  return (
    <Grid
      sx={{
        alignContent: 'center',
        fontSize: '8px',
        bg: 'white',
      }}
      id="NavUserPageview"
    >
      <Text
        sx={{
          color: 'gray.600',
          textTransform: 'uppercase',
          fontWeight: 700,
        }}
      >
        {t('views.components.layout.nav.pageviews', undefined, 'Pageview')}
      </Text>
      <Text>
        <Text
          as="span"
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            ...(isExceed && {
              color: 'red.400',
            }),
          }}
        >
          {formattedCurrentPageviews}&nbsp;
        </Text>
        / {formattedTotalPageviews}
      </Text>
      <Progress
        colorScheme={isExceed ? 'red' : 'green'}
        value={percent}
        size="sm"
        sx={{ borderRadius: '4px' }}
      />
    </Grid>
  )
}
