import { Fragment } from 'react'
import {
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  Tooltip,
  Icon,
} from '@chakra-ui/react'
import { startCase } from 'lodash'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'

import { t } from '@/lib/helpers'
import { Category } from '../../types'

import { useAbility } from '@/features/permissions'

type ManageHeaderProps = {
  activeLanguage: BannerLanguage
  category: Category
  onOpenUpdateCategory: () => void
  onOpenDeleteCategory: () => void
}

export const ManageHeader = (props: ManageHeaderProps) => {
  const {
    activeLanguage,
    category,
    onOpenDeleteCategory,
    onOpenUpdateCategory,
  } = props

  const ability = useAbility()
  const canDeleteCategory = ability.can('delete', 'category')

  return (
    <Fragment>
      <Stack spacing="12px" id="CategoryDescription">
        <Flex
          sx={{ fontWeight: 600, justifyContent: 'space-between', w: 'full' }}
        >
          <HStack>
            <Text>{category.name[activeLanguage]}</Text>
            <Text
              sx={{
                color: 'gray.600',
                fontWeight: 'normal',
                fontSize: '14px',
              }}
            >
              (
              {t(
                'views.features.domains.show.manage.form.consent_model',
                undefined,
                'Consent Model',
              )}
              : {startCase(category.consent_model)})
            </Text>
          </HStack>

          <HStack spacing={4}>
            <Link
              id="Category-edit"
              as="button"
              sx={{
                color: 'brand.400',
                textDecor: 'underline',
                fontWeight: 600,
                d: 'flex',
                alignItems: 'center',
              }}
              onClick={onOpenUpdateCategory}
            >
              <Icon as={FiEdit2} fontSize="16px" mr="1" />
              {t('views.shared.button.edit', undefined, 'Edit')}
            </Link>

            {!category.default_category && canDeleteCategory && (
              <Tooltip
                hasArrow
                label={t('views.features.domains.show.manage.delete_category')}
                bg="#2b2b2b"
                size="lg"
                placement="top"
              >
                <Link
                  id="article-delete"
                  as="button"
                  sx={{
                    color: 'red.400',
                    textDecor: 'underline',
                    fontWeight: 600,
                    d: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={onOpenDeleteCategory}
                >
                  <Icon as={FiTrash2} fontSize="16px" mr="1" />
                  {t('views.shared.button.delete', undefined, 'Delete')}
                </Link>
              </Tooltip>
            )}
          </HStack>
        </Flex>

        <Text sx={{ wordBreak: 'break-word' }}>
          {category.description[activeLanguage]}
        </Text>
      </Stack>
    </Fragment>
  )
}
