import {
  VStack,
  Input,
  Checkbox,
  Button,
  Flex,
  Spacer,
  Link,
} from '@chakra-ui/react'

import { FormControl } from '@/components/FormControl'
import { PasswordInput } from '@/components/Input'

import {
  signUpURL,
  resendConfirmationURL,
  forgotPasswordURL,
} from '@/features/auth/constants/links'
import { inputStyle, linkStyle } from '@/features/auth/constants/styles'

import { t } from '@/lib/helpers'
import { getAuthenticityToken } from '@/lib/api'

type Props = {
  rememberable: boolean
  formActionPath: string
}

function SignInForm({
  rememberable,
  formActionPath,
}: Props): React.ReactElement {
  const authenticityToken = getAuthenticityToken()

  return (
    <VStack
      spacing="4"
      sx={{
        alignItems: 'stretch',
      }}
    >
      <form
        id="new_user"
        action={formActionPath}
        acceptCharset="UTF-8"
        method="post"
      >
        <VStack
          spacing="4"
          sx={{
            alignItems: 'stretch',
          }}
        >
          <FormControl label={t('activerecord.attributes.user.email')}>
            <Input
              isRequired
              id="user_email"
              type="email"
              autoComplete="email"
              autoFocus
              name="user[email]"
              placeholder="example@cookiewow.com"
              sx={inputStyle}
            />
          </FormControl>
          <FormControl label={t('activerecord.attributes.user.password')}>
            <PasswordInput
              isRequired
              id="user_password"
              autoComplete="current-password"
              name="user[password]"
              placeholder="รหัสผ่าน"
              sx={inputStyle}
            />
          </FormControl>

          <Flex>
            {rememberable && (
              <Checkbox
                id="user_remember_me"
                value="1"
                name="user[remember_me]"
                size="sm"
                sx={{
                  fontWeight: 'light',
                  color: 'gray.600',
                }}
              >
                {t('activerecord.attributes.user.remember_me')}
              </Checkbox>
            )}
            <Spacer />
            <Link
              href={forgotPasswordURL}
              sx={{ color: 'gray.600', fontWeight: 'medium', fontSize: '14px' }}
            >
              {t('devise.shared.links.forgot_your_password')}
            </Link>
          </Flex>
          <Input
            value={authenticityToken}
            type="hidden"
            name="authenticity_token"
          />
          <Button
            type="submit"
            name="commit"
            size="xl"
            value={t('devise.sessions.new.sign_in')}
            data-disable-with={t('devise.sessions.new.sign_in')}
          >
            {t('devise.sessions.new.sign_in')}
          </Button>
        </VStack>
      </form>
      <VStack
        spacing="3"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Link href={signUpURL} sx={linkStyle}>
          {t('devise.registrations.new.sign_up')}
        </Link>
        <Link href={resendConfirmationURL} sx={linkStyle}>
          {t('devise.shared.links.didn_t_receive_confirmation_instructions')}
        </Link>
      </VStack>
    </VStack>
  )
}

export default SignInForm
