import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'
import { getPathParams } from '@/lib/routes'

import { TOAST_CONFIG } from '@/lib/constants'
import { DIALOG, PROJECT } from '../../constants'

import { BannerModelFormInput } from '../types'

type Payload = {
  data: BannerModelFormInput
  params: {
    project_id: number
  }
}

const updateBannerModel = async ({ data, params }: Payload) => {
  const { project_id } = params

  return await fetchAPI({
    path: `/projects/${project_id}/dialogs`,
    method: 'put',
    data,
  })
}

export const useUpdateBannerModel = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()
  const { t } = useTranslation('domains')

  const query = getPathParams()
  const params = { project_id: Number(query?.project_id) }

  return useMutation<AxiosResponse, AxiosError<RailsError>, Payload>(
    updateBannerModel,
    {
      onSuccess() {
        queryClient.invalidateQueries([DIALOG, params])
        queryClient.invalidateQueries([PROJECT, params])

        toast({
          ...TOAST_CONFIG,
          description: t('bannerSettings.toast.updateBannerDisplaySuccess'),
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}
