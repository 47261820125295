import { useCallback, memo } from 'react'
import { get } from 'lodash'
import { Box, Flex, Text, Icon, Image, useToast } from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone'
import { FiPlus } from 'react-icons/fi'
import { IoMdCloseCircle } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

import logoPlaceholder from '@/images/project/customize/logo-upload-placeholder.svg'

import { useAppContext } from '@/features/layout'
import { useMemoImage } from '@/lib/hooks'

import { dashedBorder } from '../../shared'

import { _20MB } from '../../../constants'
import { ERROR_TOAST_CONFIG } from '@/lib/constants'

type LogoUploaderProps = {
  name: string
  image: string | File
  onChange: (file: File | string | null) => void
  placeholder?: string
  description?: string
}

export const LogoUploader = memo(
  ({ name, image, onChange, placeholder, description }: LogoUploaderProps) => {
    const { isFree, currentPackage } = useAppContext()
    const customBranding = get(currentPackage, 'details.custom_branding')

    const toast = useToast()
    const { t } = useTranslation('domainCustomize')

    const onDrop = useCallback((acceptedFiles) => {
      if (acceptedFiles[0].size > _20MB) {
        toast({
          ...ERROR_TOAST_CONFIG,
          description: t('chores.logo_exceed'),
        })

        return
      }

      onChange(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: ['.png', '.svg', '.jpg', '.jpeg'],
      multiple: false,
    })

    const memoizedImage = useMemoImage(image)

    return (
      <Box>
        {isFree || !customBranding ? (
          <Flex
            sx={{
              border: '1px solid',
              borderColor: 'gray.300',
              background: 'gray.100',
              minH: '100px',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'not-allowed',
            }}
          >
            <Box
              sx={{
                w: '206px',
                h: '42px',
                background: `no-repeat center/contain url(${logoPlaceholder})`,
              }}
            />
          </Flex>
        ) : (
          <Flex
            sx={{
              minH: '100px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              backgroundImage: dashedBorder,
              cursor: 'pointer',
              outline: 'none',
              position: 'relative',
            }}
            {...getRootProps()}
          >
            {image ? (
              <Box>
                <Icon
                  id="CustomizeBranding-removeLogo"
                  as={IoMdCloseCircle}
                  sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    color: 'red.400',
                    cursor: 'pointer',
                    fontSize: '20px',
                  }}
                  boxSize="20px"
                  onClick={(e) => {
                    e.stopPropagation()

                    onChange(null)
                  }}
                />
                <Image
                  src={memoizedImage}
                  sx={{ objectFit: 'cover', maxH: '90px' }}
                />
              </Box>
            ) : (
              <Flex sx={{ alignItems: 'center', color: 'brand.400' }}>
                <Icon as={FiPlus} boxSize="20px" fontSize="20px" />
                <Text sx={{ ml: '8px' }}>
                  {placeholder ?? t('chores.uploadYourLogo')}
                </Text>
              </Flex>
            )}
            <input
              {...getInputProps()}
              id="CustomizeBranding-uploadLogo"
              name={name}
            />
          </Flex>
        )}

        <Text sx={{ color: 'gray.400', fontSize: '14px', mt: '8px' }}>
          {description ?? 'Recommended: 172 x 32 px (.PNG or .SVG)'}
        </Text>
      </Box>
    )
  },
  (prev, next) => prev.image === next.image,
)
