import { get } from 'lodash'
import { useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { QUOTA } from '../constants'

const getQuota = async (): Promise<Quota> => {
  const response = await fetchAPI({
    path: '/quota',
  })

  const data = get(response, 'data', {})

  return data
}

export const useQuota = ({ initialData }: { initialData: Quota }) => {
  return useQuery<Quota, AxiosError, Quota>({
    queryKey: [QUOTA],
    queryFn: () => getQuota(),
    initialData,
  })
}

export const useQuotaData = () => {
  const queryClient = useQueryClient()

  return queryClient.getQueryData<Quota>(QUOTA)
}
