import { ModalBody, ModalHeader, UseDisclosureReturn } from '@chakra-ui/react'

import { BannerLanguage, Cookie, ScriptFormInput } from '../../types'

import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useUpdateScript } from '../../mutations'

import { Modal } from '@/components/shared/Modal'
import { ManageScriptForm } from './ManageScriptForm'

type Props = {
  activeLanguage: BannerLanguage
  disclosure: UseDisclosureReturn
  defaultValues: Cookie
}

export const EditScriptModal = (props: Props) => {
  const { activeLanguage, disclosure, defaultValues } = props
  const { isOpen, onClose } = disclosure

  const { project_id } = getPathParams()
  const params = {
    project_id: Number(project_id),
    script_id: defaultValues?.id,
  }

  const { mutate: updateScript, isLoading } = useUpdateScript()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="560px" withCloseButton>
      <ModalHeader>
        {t('views.features.domains.show.manage.form.edit_script')}
      </ModalHeader>

      <ModalBody>
        <ManageScriptForm
          onSubmitForm={(data) => {
            updateScript(
              { data, params },
              {
                onSuccess: onClose,
              },
            )
          }}
          onCancel={onClose}
          isLoading={isLoading}
          defaultValues={scriptIntoFormInput(defaultValues)}
          activeLanguage={activeLanguage}
        />
      </ModalBody>
    </Modal>
  )
}

const scriptIntoFormInput = (script: Cookie): ScriptFormInput => {
  return {
    name: script.name,
    category_id: String(script.category_id),
    purpose: script.purpose,
    url: script.url,
  }
}
