import { ReactNode, useEffect, createContext } from 'react'
import { useAbility as useAbilityFn } from '@casl/react'
import { Ability } from '@casl/ability'

import { Abilities, AppAbility, Rules } from './types'

type ProviderProps = {
  children: ReactNode
  policies: Rules
}

const ability = new Ability<Abilities>()

export const AbilityContext = createContext<AppAbility>(
  null as unknown as AppAbility,
)

export const useAbility = () => useAbilityFn(AbilityContext)

export const PermissionContextProvider = (props: ProviderProps) => {
  useEffect(() => {
    ability.update(props.policies)
  }, [])

  return (
    <AbilityContext.Provider value={ability}>
      {props.children}
    </AbilityContext.Provider>
  )
}
