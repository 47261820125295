import { Fragment, memo, ReactNode } from 'react'
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  CSSObject,
  Flex,
  FormControl as CKFormControl,
  FormControlProps as CKFormControlProps,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
  Link,
  Stack,
  Switch,
  SystemStyleObject,
  Text,
  Collapse,
  Input,
} from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { omit } from 'lodash'

import { useAppContext } from '@/features/layout'
import { ColorDot } from '@/components/shared'

import { t } from '@/lib/helpers'

type AccordionWrapperType = {
  buttonID?: string
  title: string
  children: ReactNode
  isDisabled?: boolean
}

export const AccordionWrapper = (props: AccordionWrapperType) => {
  const { title, children, buttonID, isDisabled = false } = props

  return (
    <AccordionItem border="none" id={buttonID} isDisabled={isDisabled}>
      {({ isExpanded, isDisabled }) => (
        <>
          <AccordionButton
            sx={{
              border: '1px solid',
              borderColor: 'gray.200',
              borderRadius: '4px',
              h: '56px',
              _expanded: {
                color: 'white',
                backgroundColor: 'brand.400',
              },
              _focus: {
                boxShadow: 'unset',
              },
              ...(isDisabled && {
                cursor: 'not-allowed',
                bg: 'gray.100',
                color: 'gray.600',
              }),
            }}
          >
            <Box
              sx={{
                fontWeight: gon.currentLocale === 'th' ? 400 : 600,
                flex: 1,
                textAlign: 'left',
              }}
            >
              {title}
            </Box>
            <Icon
              as={FiChevronRight}
              boxSize="24px"
              fontSize="20px"
              sx={{
                transform: isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)',
                transition: 'transform 0.2s ease',
              }}
            />
          </AccordionButton>

          <AccordionPanel
            sx={{
              pt: '24px',
              pb: '32px',
              px: '2px',
              borderBottom: '1px solid',
              borderColor: 'brand.50',
            }}
          >
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

export const UpgradeLink = memo(
  ({ sx, id }: { sx?: SystemStyleObject; id?: string }) => {
    return (
      <Link
        id={id || 'Package-upgrade'}
        href="/packages"
        sx={{
          color: 'brand.400',
          textDecor: 'underline',
          fontWeight: 600,
          ...sx,
        }}
      >
        {t(
          'views.features.domains.show.customize.upgrade_plan',
          undefined,
          'Upgrade Plan',
        )}
      </Link>
    )
  },
)

export const UpgradeText = memo(
  ({
    linkID,
    label,
    moreText = '',
    moreCondition = true,
    sx = {},
  }: {
    linkID?: string
    label?: string
    moreText?: string
    moreCondition?: boolean
    sx?: SystemStyleObject
  }) => {
    const { isFree } = useAppContext()

    if (!isFree && moreCondition) return null

    return (
      <Text sx={{ color: 'gray.600', ...sx }}>
        <UpgradeLink id={linkID} />
        &nbsp;
        {typeof label !== 'undefined'
          ? label
          : t(
              'views.features.domains.show.customize.to_unlock',
              undefined,
              'to unlock this feature',
            )}
        {moreText && <Fragment>&nbsp;{moreText}</Fragment>}
      </Text>
    )
  },
)

export const dashedBorder = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23EA834FFF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e")`

export const hiddenInputStyle: CSSObject = {
  border: '0px',
  clip: 'rect(0px, 0px, 0px, 0px)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0px',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
}

export const FormControl = (
  props: {
    label?: string
    id?: string
    children: ReactNode
    isRequired?: boolean
    errorMsg?: string
    sx?: SystemStyleObject
    disabled?: boolean
    htmlFor?: string
  } & CKFormControlProps,
) => {
  const { label, id, children, isRequired, errorMsg, sx, disabled, ...rest } =
    props

  return (
    <CKFormControl
      id={id}
      isRequired={isRequired}
      isInvalid={!!errorMsg}
      isDisabled={disabled}
      sx={sx}
      {...rest}
    >
      {typeof label !== 'undefined' && (
        <FormLabel sx={{ fontWeight: 400 }}>{label}</FormLabel>
      )}
      {children}
      <FormErrorMessage>{errorMsg}</FormErrorMessage>
    </CKFormControl>
  )
}

export const PreviewCodeBlock = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        p: '9px 17px 13px 10px',
        borderBottom: '1px solid',
        borderColor: 'gray.300',
        bg: '#161819',
        borderRadius: '8px',
      }}
    >
      <Flex
        sx={{
          h: '30px',
          alignItems: 'center',
          mb: '8px',
        }}
      >
        <Grid
          sx={{
            gridTemplateColumns: 'repeat(3, 15px)',
            gap: '7px',
            mr: '14px',
          }}
        >
          {['#ff6057', '#ffc12e', '#28ca40'].map((color) => (
            <ColorDot key={color} color={color} />
          ))}
        </Grid>
      </Flex>

      <Box sx={{ color: 'white', px: '4px' }}>{children}</Box>
    </Box>
  )
}

type RadioSectionProps = {
  description?: string
  defaultValue?: string | boolean
  id: string
  name: string
  title: string
  isDisabled?: boolean
}

export const RadioSection = (props: RadioSectionProps) => {
  const { description, id, name, title, defaultValue, isDisabled } = props

  const { field } = useController({
    name,
    defaultValue,
  })

  return (
    <Stack>
      <Flex align="center" justify="space-between">
        <Text
          className="customize-title"
          sx={{ fontSize: '14px', fontWeight: 600 }}
        >
          {title}
        </Text>

        <Switch
          size="lg"
          id={id}
          isChecked={Boolean(field.value)}
          isDisabled={isDisabled}
          {...omit(field, 'value')}
        />
      </Flex>

      {description && (
        <Text sx={{ fontSize: '12px', color: 'gray.600' }}>{description}</Text>
      )}
    </Stack>
  )
}

type RadioSectionWithInputProps = RadioSectionProps & {
  inputName: string
  inputId: string
  className: string
  isDisabledInput?: boolean
  errorMsg?: string
}

export const RadioSectionWithInput = (props: RadioSectionWithInputProps) => {
  const {
    className,
    description,
    id,
    inputId,
    inputName,
    name,
    title,
    isDisabledInput,
    errorMsg,
  } = props

  const radioValue = useWatch({
    name,
  })
  const inputValue = useWatch({
    name: inputName,
  })
  const previewLanguage = useWatch({
    name: 'preview_language',
  }) as BannerLanguage
  const { setValue } = useFormContext()

  return (
    <Grid className={className} gap={2}>
      <RadioSection
        title={title}
        name={name}
        id={id}
        defaultValue={radioValue}
      />

      <Collapse in={radioValue}>
        <FormControl errorMsg={errorMsg}>
          <Input
            id={inputId}
            name={`${inputName}.${previewLanguage}`}
            value={inputValue[previewLanguage]}
            isDisabled={isDisabledInput}
            onChange={({ target: { value } }) => {
              setValue(inputName, {
                ...inputValue,
                [previewLanguage]: value,
              })
            }}
          />
        </FormControl>
      </Collapse>

      <Text textStyle="formHelper">{description}</Text>
    </Grid>
  )
}

type LanguageDependantInputProps = {
  id: string
  name: string
  placeholder?: string
}

export const LanguageDependantInput = (props: LanguageDependantInputProps) => {
  const { name, id, placeholder } = props

  const previewLanguage = useWatch({
    name: 'preview_language',
  }) as BannerLanguage
  const inputValue = useWatch({
    name,
  })
  const { setValue } = useFormContext()

  return (
    <Input
      name={name}
      id={id}
      value={inputValue[previewLanguage] ?? ''}
      placeholder={placeholder}
      onChange={({ target: { value } }) => {
        setValue(name, {
          ...inputValue,
          [previewLanguage]: value,
        })
      }}
    />
  )
}
