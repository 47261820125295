import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Stack, Button } from '@chakra-ui/react'

import { FormControl } from '@/components/FormControl'
import { PasswordInput, PasswordInputValidator } from '@/components/Input'

import { t } from '@/lib/helpers'

import { ChangePasswordFormInput } from '../../types'
import { changePasswordFormSchema } from '../../schema'

type Props = {
  onSubmitForm: (form: ChangePasswordFormInput) => void
  isLoading: boolean
}

export const ChangePasswordForm = (props: Props) => {
  const { onSubmitForm, isLoading } = props
  const { t: tErrors } = useTranslation('errors')

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
  } = useForm<ChangePasswordFormInput>({
    resolver: zodResolver(changePasswordFormSchema),
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (form: ChangePasswordFormInput) => {
    if (isSubmitting) return

    onSubmitForm(form)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} role="form">
      <Stack spacing="24px">
        <FormControl
          isRequired
          id="current_password"
          label={t(
            'views.features.users.settings.change_password.current_password',
          )}
          errorMsg={tErrors(errors?.current_password?.message ?? '')}
        >
          <PasswordInput
            {...register('current_password')}
            placeholder={t(
              'views.features.users.settings.change_password.current_password',
            )}
            size="lg"
            data-testid="current-password"
          />
        </FormControl>

        <FormControl
          isRequired
          id="new_password"
          label={t(
            'views.features.users.settings.change_password.new_password',
          )}
          errorMsg={tErrors(errors?.new_password?.message ?? '')}
        >
          <Controller
            control={control}
            name="new_password"
            render={({ field: { name, value, onChange } }) => {
              return (
                <PasswordInputValidator
                  placeholder={t(
                    'views.features.users.settings.change_password.new_password',
                  )}
                  size="lg"
                  data-testid="new-password"
                  name={name}
                  value={value}
                  onChange={onChange}
                  autoComplete="new-password"
                />
              )
            }}
          />
        </FormControl>

        <FormControl
          isRequired
          id="confirm_password"
          label={t(
            'views.features.users.settings.change_password.confirm_password',
          )}
          errorMsg={tErrors(errors?.confirm_password?.message ?? '')}
        >
          <PasswordInput
            {...register('confirm_password')}
            placeholder={t(
              'views.features.users.settings.change_password.confirm_password',
            )}
            size="lg"
            data-testid="confirm-password"
            autoComplete="new-password"
          />
        </FormControl>

        <Button
          id={'Change-password'}
          type="submit"
          isLoading={isLoading}
          size="xl"
          data-testid="submit-change-password"
        >
          {t(
            'views.features.users.settings.change_password.change_your_password',
          )}
        </Button>
      </Stack>
    </form>
  )
}
