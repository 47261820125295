export default {
  h1: 'เริ่มต้นทดลองใช้ฟรี',
  backToPackage: 'กลับสู่หน้าเลือกแพ็กเกจ',
  coupon: {
    placeholder: 'ใช้รหัส Referral Code หรือส่วนลด',
    buttonTitle: 'ใช้ส่วนลด',
    useCoupon: 'ใช้โค้ดส่วนลด',
    discount: 'ส่วนลด {{ discount }}',
    useDiscountCode: 'ใช้คูปองส่วนลด',
    discountCodeAutoApply:
      'โค้ดส่วนลดจะถูกใช้งานอัตโนมัติ ผู้ใช้งานไม่จำเป็นต้องกลับมาใช้ซ้ำ',
  },
  note: 'หมายเหตุ',
  note_0:
    'คุณจะได้รับการแจ้งเตือนทางอีเมลเมื่อการทดลองใช้งานฟรีสิ้นสุดลง รวมถึงการเริ่มต้นเรียกเก็บเงินอัตโนมัติ',
  note_1:
    'การสมัครใช้บริการแบบชำระเงินจะเริ่มต้นโดยอัตโนมัติหลังจากที่ช่วงทดลองใช้ฟรีสิ้นสุดลง หรือจนกว่าคุณจะกดยกเลิกก่อนที่ช่วงทดลองใช้ฟรีจะสิ้นสุดลง โดยเราจะดำเนินการเรียกเก็บเงินสำหรับแพ็กเกจที่คุณเลือก',
  orderSummary: {
    title: 'สรุปยอดสั่งซื้อ',
    order: 'รายการ',
    sumTitle: 'ยอดรวม (บาท)',
    preVat: 'ราคาสุทธิสินค้าที่เสียภาษี / Pre-VAT',
    afterVat: 'จำนวนเงินรวมทั้งสิ้น',
    wht: 'ภาษีหัก ณ ที่จ่าย (3%)',
    whtDescription:
      'เนื่องจาก <strong>Cookie Wow</strong> ได้รับการส่งเสริมการลงทุนจากสำนักงานคณะกรรมการส่งเสริมการลงทุน (BOI) จึงได้รับการ <strong>ยกเว้นการถูกหักภาษี ณ ที่จ่าย</strong> ฉะนั้นผู้ใช้บริการจึงไม่ต้องทำการหักภาษี ณ ที่จ่ายแต่อย่างใด',
    total: 'ยอดรวมสุทธิ',
    payNow: 'ชำระวันนี้',
    freeTrialDuration: 'ทดลองใช้งานฟรี {{ day }} วัน',
    timer: {
      newUserCode: 'ส่วนลดผู้ใช้งานใหม่',
      freeBy: 'เมื่อทดลองใช้ฟรีภายใน',
      time: {
        hour: 'ชั่วโมง',
        minute: 'นาที',
        second: 'วินาที',
      },
    },
    acceptTerms:
      'ฉันยอมรับ <1>ข้อตกลงในการให้บริการ</1> และ <3>นโยบายการรักษาข้อมูลส่วนบุคคล</3>',
    collectAfter:
      'คุณจะสามารถใช้งานบริการบริการของเราฟรีจนถึงวันที่ <1>{{ day }}/{{ month }}/{{ year }}</1> และ <br />หลังจากนั้น<1>จะถูกหักเงิน</1>ตามข้อมูลข้างต้น โดยคุณสามารถยกเลิกได้ตลอดเวลา อ่านข้อมูลเพิ่มเติม <4>ที่นี่</4>',
    totalDiscount: 'ส่วนลดทั้งหมด',
  },
  form: {
    yearly: 'รายปี',
    monthly: 'รายเดือน',
    month: 'เดือน',
    selectPackage: 'เลือกแพ็กเกจ',
    freeTrial: 'ทดลองใช้ฟรี {{ day }} วัน',
    selectCycle: 'เลือกรอบบิล',
    pricePerMonth: '{{ price }}.- /เดือน',
    billMonthly: '{{ price }}.- ชำระเงินเป็นรายเดือน',
    billAnnually: '{{ price }}.- ชำระเงินเป็นรายปี',
    individual: 'บุคคลธรรมดา',
    company: 'นิติบุคคล',
    creditDebit: 'บัตรเครดิต/บัตรเดบิต',
    changeCard:
      'คุณสามารเปลี่ยนแปลงบัตรเครดิต/บัตรเดบิตได้ในภายหลังที่เมนูตั้งค่าผู้ใช้งาน',
    howToPay: 'วิธีการชำระค่าบริการ',
    helpdeskLink:
      'https://help.cookiewow.com/th/article/4lin4li04liy4li14lik4liz4lij4liw4lie4lmi4liy4lia4lij4li04lib4liy4lij-qkrp2k/',
    cardHolderNamePlaceholder: 'ชื่อผู้ถือบัตร',
    billingInformation: 'ข้อมูลสำหรับออกใบกำกับภาษี',
    taxInvoice:
      'ใบกำกับภาษี/ใบเสร็จรับเงินฉบับเต็ม จะนำส่งให้ในรูปแบบ E-Tax Invoice ทางอีเมลภายใน 3 วันทำการ หลังชำระค่าบริการ',
    namePlaceholder: 'ชื่อ - นามสกุล',
    taxIdPlaceholder: 'เลขประจำตัวผู้เสียภาษี',
    addressPlaceholder: 'ที่อยู่',
    submit: 'เริ่มทดลองใช้งานฟรี {{ day }} วัน',
    contactUsAt:
      'หากต้องการใบเสนอราคา ติดต่อได้ที่ Live Chat ของ Cookie Wow หรือ <1>{{mail}}</1> หรือ โทร <3>02-024-5560</3>',
    errors: {
      numberRequired: 'กรุณาใส่เลขบัตรเครดิต',
      numberInvalid: 'หมายเลขบัตรเครดิตไม่ถูกต้อง ',
      expiryRequired: 'กรุณาใส่วันหมดอายุของบัตร',
      expiryInvalid: 'วันหมดอายุไม่ถูกต้อง',
      cvcRequired: 'กรุณาใส่เลขหลังบัตร',
      cvcInvalid: 'รหัสหลังบัตรไม่ถูกต้อง',
    },
  },
  toast: {
    success: '',
    failed: '',
  },
  alreadySub: {
    title: 'เราเสียใจ...',
    description: `การทดลองใช้ฟรีสามารถใช้งานได้สำหรับผู้ใช้งานที่ยังไม่เคยใช้<br />แพ็กเกจใดๆ มาก่อนเท่านั้น`,
    button: 'กลับสู่หน้าแพ็กเกจ',
  },
  success: {
    title: 'คุณได้สมัคร Free Trial เรียบร้อยแล้ว',
    description:
      'ช่วงทดลองใช้ฟรีของคุณสามารถใช้ได้ถึงวันที่ <1>{{ date }}</1> และคุณสามารถยกเลิกได้ตลอดเวลา',
    complete: 'เสร็จสิ้น',
    info: '(ทางบริษัทจะหักค่าบริการหลังจากสิ้นสุด Free trial)',
  },
}
