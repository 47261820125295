import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { UserFormInput } from '../types'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { USERS } from '../constants'
import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  data: UserFormInput
  params: {
    user_id: number
  }
}

const updateUser = async ({ data, params }: Payload) => {
  const { user_id } = params

  return await fetchAPI({
    path: `/user_managements/${user_id}`,
    method: 'put',
    data,
  })
}

export const useUpdateUser = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, Payload>(updateUser, {
    async onSuccess() {
      await queryClient.refetchQueries([USERS])

      toast({
        ...TOAST_CONFIG,
        description: t('views.features.users.services.update_user_success'),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
