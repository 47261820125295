import { fetchAPI } from '@/lib/api'
import { ERROR_TOAST_CONFIG, TOAST_CONFIG } from '@/lib/constants'
import { t } from '@/lib/helpers'
import { useToast } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { PAGEVIEWS_STATUS } from '../constants'
import { getPageViewCsvStatus } from '../queries/getPageViewCsvStatus'

type Payload = {
  domain: string[]
  startDate: string
  endDate: string
}

const checkPageViewCsvStatus = async (payload: Payload) => {
  return fetchAPI({
    path: '/export',
    method: 'get',
    params: {
      domain: payload.domain,
      start_date: payload.startDate,
      end_date: payload.endDate,
    },
  })
}

const getPageViewCsvUrl = async () => {
  return await fetchAPI({
    path: '/company',
    method: 'get',
  })
}

export const useCheckPageViewCsvStatus = ({
  startOnMount,
}: {
  startOnMount: boolean
}) => {
  const [start, setStart] = useState(startOnMount)

  const toast = useToast()

  const { mutate } = useMutation(checkPageViewCsvStatus, {
    onSuccess() {
      setStart(true)
    },
    onError() {
      toast({
        ...ERROR_TOAST_CONFIG,
        description: t('views.features.packages.page_views.failed_download'),
      })
    },
  })

  const { status } = usePolling({ start, setStart })

  return {
    checkPageViewCsvStatus: mutate,
    isLoading: start,
    status,
  }
}

const usePolling = ({
  start,
  setStart,
}: {
  start: boolean
  setStart: Dispatch<SetStateAction<boolean>>
}) => {
  const toast = useToast()

  const { data } = useQuery({
    queryKey: [PAGEVIEWS_STATUS],
    queryFn: () => getPageViewCsvStatus(),
    async onSuccess(data) {
      const status = data.data.status

      switch (status) {
        case 'succeeded':
          const response = await getPageViewCsvUrl()

          const url = response.data.page_views_csv_url
          const a = document.createElement('a')

          a.href = url
          a.click()

          toast({
            ...TOAST_CONFIG,
            description: t(
              'views.features.packages.page_views.success_download',
            ),
          })

          setStart(false)

          return
        case 'failed':
          toast({
            ...ERROR_TOAST_CONFIG,
            description: t(
              'views.features.packages.page_views.failed_download',
            ),
          })

          setStart(false)

          return
        default:
          return
      }
    },
    enabled: start,
    refetchInterval: start ? 5000 : false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  return {
    status: data?.data?.status,
  }
}
