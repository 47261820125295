import { useMemo, useState } from 'react'
import { get, noop } from 'lodash'
import { Box, Flex } from '@chakra-ui/layout'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import weakMemoize from '@emotion/weak-memoize'
import {
  BannerContainer,
  BannerProvider,
  defaultTheme,
  CookieCategory,
  getMockBannerText,
  getInitialPurposes,
} from '@datawowio/cookie-banner-ui'
import Frame, { FrameContextConsumer } from 'react-frame-component'
import { Control, useWatch } from 'react-hook-form'

import { DialogFormInput, Screen } from '@/features/domains/types'
import { ResponsiveSwitch } from './ResponsiveSwitch'
import { isValidHex } from '../../utils'

import { useMemoImage } from '@/lib/hooks'
import { useAppContext } from '@/features/layout'

export const memoizedCreateCacheWithContainer = weakMemoize(
  (container: HTMLElement) => {
    const newCache = createCache({ key: 'iframe-css', container })

    return newCache
  },
)

type Props = {
  control: Control<DialogFormInput>
  cookieCategories: CookieCategory[]
  showWidget: boolean
}

export const CustomizePreview = (props: Props) => {
  const { control, cookieCategories, showWidget } = props

  const initialPurposes = getInitialPurposes({
    cookieCategories,
  })

  const { isFree } = useAppContext()

  const [screen, setScreen] = useState<Screen>('desktop')
  const containerStyle = useMemo(() => {
    let width

    switch (screen) {
      case 'mobile': {
        width = '375px'
        break
      }
      case 'tablet': {
        width = '768px'
        break
      }
      default: {
        width = '100%'
      }
    }

    return { width }
  }, [screen])

  const formValues = useWatch({ control }) as DialogFormInput
  const memoBrandLogo = useMemoImage(formValues.logo as DialogFormInput['logo'])
  const memoWidgetLogo = useMemoImage(formValues.widget_icon_url)

  const bannerLanguage = formValues.banner_language
  const previewLanguage = formValues.preview_language
  const closeButton = formValues.close_button
  const rejectAllButton = formValues.reject_all
  const preferenceButton = formValues.preference_button
  const bannerSize = formValues.font_size
  const bannerModel = formValues.banner_model
  const isNoticeOnly = bannerModel === 'notice_only'
  const showLogo = formValues.show_logo

  const logoType = isFree ? 'cookiewow' : formValues.logo_type
  const showLogoFooter = isFree
    ? true
    : showLogo &&
      (logoType === 'cookiewow' ||
        (get(formValues, 'show_footer_logo') && Boolean(memoBrandLogo)))

  return (
    <Box sx={{ h: '100%' }}>
      <Flex
        sx={{
          h: 'calc(100% - 72px)',
          justifyContent: 'center',
          maxW: '100%',
        }}
      >
        <Box
          sx={{
            m: 'auto',
            transition: 'width 0.4s, height 0.4s',
            background: 'gray.100',
            h: '100%',
          }}
          style={containerStyle}
        >
          <Frame width="100%" height="100%">
            <FrameContextConsumer>
              {({ document }) => {
                if (!document) return null

                return (
                  <CacheProvider
                    value={memoizedCreateCacheWithContainer(document.head)}
                  >
                    <BannerProvider
                      theme={{
                        ...defaultTheme,
                        colors: {
                          ...defaultTheme.colors,
                          primary: isValidHex(get(formValues, 'color', ''))
                            ? get(formValues, 'color', '')
                            : '#FFFFFF',
                          bg: get(formValues, 'bg_color', ''),
                          text: get(formValues, 'text_color', ''),
                        },
                      }}
                      bannerRadius={get(formValues, 'banner_radius')}
                      bannerSize={bannerSize}
                      bannerText={{
                        ...getMockBannerText(previewLanguage, isNoticeOnly),
                        ...(!isFree
                          ? {
                              acceptAll: isNoticeOnly
                                ? formValues.ok_button_label[previewLanguage]
                                : formValues.accept_all_label[previewLanguage],
                              rejectAll:
                                formValues.reject_all_label[previewLanguage],
                              changePreference: isNoticeOnly
                                ? formValues.view_details_label[previewLanguage]
                                : formValues.preference_label[previewLanguage],
                            }
                          : {}),
                        customPolicy: formValues.policy_title[previewLanguage],
                      }}
                      brandLogoUrl={memoBrandLogo}
                      buttonRadius={get(formValues, 'button_radius')}
                      colorMode={get(formValues, 'theme')}
                      position={get(formValues, 'position')}
                      showFooterLogo={showLogoFooter}
                      showTitle={get(formValues, 'show_title')}
                      logoType={logoType}
                      showPreferenceHeaderLogo={
                        !isFree &&
                        showLogo &&
                        logoType === 'custom' &&
                        formValues.show_preference_header_logo
                      }
                      customCss={formValues.custom_css}
                      emotionContainer={document.head}
                    >
                      <BannerContainer
                        bannerModel={bannerModel}
                        cookieCategories={cookieCategories}
                        description={get(
                          formValues,
                          `content.${previewLanguage}`,
                          '',
                        )}
                        isOpenBanner
                        onClickReject={
                          isNoticeOnly
                            ? undefined
                            : rejectAllButton
                            ? noop
                            : undefined
                        }
                        onExplicitClose={closeButton ? noop : undefined}
                        policyLink={
                          get(formValues, `policy_url.${previewLanguage}`) ||
                          formValues.policy_url[bannerLanguage] ||
                          ''
                        }
                        policyType={get(formValues, 'policy_type')}
                        showPreference={isNoticeOnly ? preferenceButton : true}
                        title={get(formValues, `title.${previewLanguage}`, '')}
                        widgetEnabled={get(formValues, 'revokable') === '1'}
                        widgetPosition={get(formValues, 'widget') ?? 'left'}
                        widgetIconType={
                          isFree
                            ? 'default'
                            : get(formValues, 'widget_icon_type') ?? 'default'
                        }
                        widgetIconUrl={memoWidgetLogo}
                        overrideDisplayBanner={!showWidget}
                        initialPurposes={initialPurposes}
                      />
                    </BannerProvider>
                  </CacheProvider>
                )
              }}
            </FrameContextConsumer>
          </Frame>
        </Box>
      </Flex>

      <Flex sx={{ justifyContent: 'center', pt: 4, pb: 2, bg: 'white' }}>
        <ResponsiveSwitch screen={screen} setScreen={setScreen} />
      </Flex>
    </Box>
  )
}
