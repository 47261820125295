import { Grid, Center, chakra, Link, SystemStyleObject } from '@chakra-ui/react'

import { useActiveRoute } from '@/lib/routes'

type Props = {
  routes: {
    name: string
    href: string
    checkActive: string[]
  }[]
  sx?: SystemStyleObject
}

export const TabNavigation = (props: Props) => {
  const { routes, sx } = props

  const checkActiveRoute = useActiveRoute()

  return (
    <Grid
      as="nav"
      sx={{
        gridTemplateColumns: `repeat(${routes.length}, 140px)`,
        h: '50px',
        ...sx,
      }}
      id="DomainNavigation"
    >
      {routes.map((t) => {
        return (
          <Link
            key={t.name}
            href={t.href}
            sx={{
              d: 'flex',
              justifyContent: 'center',
              ...(checkActiveRoute(t.checkActive) && {
                boxShadow: 'inset 0 -3px 0 0 #ea834f;',
                fontWeight: 700,
                color: 'brand.400',
              }),
            }}
          >
            <TabItem>{t.name}</TabItem>
          </Link>
        )
      })}
    </Grid>
  )
}

const TabItem = chakra(Center, {
  baseStyle: {
    fontSize: '14px',
    cursor: 'pointer',
  },
})
