export default {
  companySettingForm: {
    firstName: {
      label: 'First Name',
      placeholder: 'First Name',
    },
    lastName: {
      label: 'Last Name',
      placeholder: 'Last Name',
    },
  },
  changeEmail: 'Change Email',
  changeEmailDesc: 'Enter your new email',
  changeEmailForm: {
    oldEmail: 'Old Email',
    newEmail: 'New Email',
    newEmailPlaceholder: 'Email',
    toast: {
      success: 'Update email successfully',
    },
  },
  changeEmailSuccess: {
    title: 'Verification email sent',
    description:
      'You need to verify the new email before changes. please check your inbox in <1>{{ email }}</1> to verify within <3>{{ date }}</3>',
  },
  changeEmailPending: {
    description:
      'Pending change email. Please verify email to confirm your change<br />within {{ date }}.',
    resendVerification: 'Resend verification',
    toast: {
      resendSuccess:
        'A new verification email has been sent to you. Please check your inbox.',
      cancelSuccess: 'Email change request has been cancelled',
    },
  },
  confirmCancelUpdateEmailModal: {
    title: 'Are you sure ?',
    description: 'Are you sure to cancel this action? Please confirm.',
  },
  passwordWrapper: {
    title: 'Enter your password',
    subtitle: 'Enter your account password.',
  },
  verifyPasswordForm: {
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
    },
    toast: {
      success: '',
    },
  },
  passwordExpiration: {
    title: 'The security policy of password validity',
    emptyDetail: 'No password security policy has been set.',
    detail:
      "The company's security policy requires password changes every <1>{{ days }}</1> day.",
    detail_plural:
      "The company's security policy requires password changes every <1>{{ days }}</1> days.",
    modal: {
      header: 'Set the validity of the password',
      switchLabel: 'Enable the password validity',
    },
    form: {
      expirePasswordAfterDays: {
        label: 'The validity period of the password',
        helper:
          'Please specify a minimum number of 1 day and a maximum of 365 days.',
      },
      toast: {
        success: 'Your validity of password has been set successfully',
      },
    },
  },
}
