import { ModalBody, ModalHeader, UseDisclosureReturn } from '@chakra-ui/react'

import { t } from '@/lib/helpers'

import { Modal } from '@/components/shared/Modal'
import { DomainForm } from '../DomainForm'

import { useAddDomain } from '../../mutations'

import { SelectOptions } from '@/components/Select/types'

type Props = {
  disclosure: UseDisclosureReturn
  folderOptions: SelectOptions
}

export const AddDomainModal = (props: Props) => {
  const { disclosure, folderOptions } = props
  const { isOpen, onClose } = disclosure

  const { mutate: addDomain, isLoading } = useAddDomain()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="560px" withCloseButton>
      <ModalHeader>{t('views.features.domains.index.add_domain')}</ModalHeader>

      <ModalBody>
        <DomainForm
          onSubmitForm={(data) => {
            addDomain(
              { data },
              {
                onSuccess: onClose,
              },
            )
          }}
          isLoading={isLoading}
          onClose={onClose}
          folderNameOptions={folderOptions}
        />
      </ModalBody>
    </Modal>
  )
}
