import { Box, Button, Heading, Input, Stack, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { FormControl } from '@/components/FormControl'

import { TwoFactorOtpFormInput } from '../../types'

import { t } from '@/lib/helpers'

type DisableTwoFactorFormProps = {
  isLoading: boolean
  onSubmit: (data: TwoFactorOtpFormInput) => void
  onCancel: () => void
}

export const DisableTwoFactorForm = (
  props: DisableTwoFactorFormProps,
): React.ReactElement => {
  const { onCancel, onSubmit, isLoading } = props

  const { register, handleSubmit } = useForm<TwoFactorOtpFormInput>()

  return (
    <Box>
      <Stack sx={{ mb: '16px' }} spacing="4">
        <Heading as="h3" sx={{ pb: 0, fontSize: '24px' }}>
          {t(
            'views.features.two_factor.remove_two_factor',
            undefined,
            'Remove Two-Factor Authentication',
          )}
        </Heading>
        <Text>
          {t('views.features.two_factor.remove_two_factor_desc_1')}
          <br />
          <br />
          {t('views.features.two_factor.remove_two_factor_desc_2')}
        </Text>
      </Stack>

      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data)
        })}
      >
        <Stack spacing="6">
          <FormControl
            label={t(
              'views.features.two_factor.form.remove_code',
              undefined,
              'Authenticator Code / Recovery Code',
            )}
            isRequired
          >
            <Input
              size="lg"
              inputMode="numeric"
              autoComplete="one-time-code"
              autoFocus
              {...register('otp_attempt')}
              placeholder={t(
                'views.features.two_factor.form.remove_code_placeholder',
                undefined,
                'Enter code from authenticator app / Recovery Code',
              )}
            />
          </FormControl>

          <Stack>
            <Button size="xl" type="submit" isLoading={isLoading}>
              {t('views.shared.button.confirm', undefined, 'Confirm')}
            </Button>
            <Button
              size="xl"
              variant="ghost"
              colorScheme="gray"
              sx={{ color: 'gray.600' }}
              isDisabled={isLoading}
              onClick={onCancel}
            >
              {t('views.shared.button.discard', undefined, 'Discard')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}
