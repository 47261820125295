import { Stack } from '@chakra-ui/layout'
import {
  StackDivider,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'

import { withPage } from '@/features/layout'
import { DomainShowLayout } from '../../components'

import { Dialog, DomainPageProps } from '../../types'

import { BannerModel, BannerDisplay } from '../../Settings/containers'
import { CustomTab } from '../../Settings/components'
import { useTranslation } from 'react-i18next'

type Props = DomainPageProps & {
  initialDialog: Dialog
  surveysensum_url: string
}

function DomainSettingsPage(props: Props) {
  const { project, banner_polling_status, initialDialog, surveysensum_url } =
    props

  const { t } = useTranslation('domains')

  return (
    <DomainShowLayout
      project={project}
      surveyUrl={surveysensum_url}
      bannerPollingStatus={banner_polling_status}
    >
      <Tabs orientation="vertical" variant="" defaultIndex={0}>
        <Stack
          direction="row"
          divider={<StackDivider />}
          spacing={8}
          sx={{ px: 8, pb: 8, minH: 640, w: 'full' }}
        >
          <TabList sx={{ pt: 6 }}>
            <CustomTab>{t('bannerSettings.menus.bannerModel')}</CustomTab>
            <CustomTab>{t('bannerSettings.menus.displayBanner')}</CustomTab>
          </TabList>

          <TabPanels
            sx={{
              pt: 6,

              '> *': {
                p: '0 !important',
                flex: 1,
                h: 'full',
              },
            }}
          >
            <TabPanel>
              <BannerModel
                defaultValues={{ banner_model: initialDialog.banner_model }}
              />
            </TabPanel>
            <TabPanel>
              <BannerDisplay
                defaultValues={{
                  excluded_urls: initialDialog.excluded_urls,
                  included_urls: initialDialog.included_urls,
                  page_setting: initialDialog.page_setting,
                }}
              />
            </TabPanel>
          </TabPanels>
        </Stack>
      </Tabs>
    </DomainShowLayout>
  )
}

export default withPage(DomainSettingsPage)
