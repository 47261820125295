import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { TwoFactorOtpFormInput } from '../types'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'
import { TWO_FACTOR } from '../constants'

type Payload = {
  data: TwoFactorOtpFormInput
}

const verifyOtp = async ({ data }: Payload) => {
  return await fetchAPI({
    path: `/settings/two_factor/verify`,
    method: 'post',
    data,
  })
}

export const useVerifyOtp = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, Payload>(verifyOtp, {
    async onSuccess() {
      await queryClient.refetchQueries([TWO_FACTOR])

      toast({
        ...TOAST_CONFIG,
        description: t('views.features.two_factor.services.enable_success'),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
