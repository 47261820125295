import { Box, Image, Text, VStack } from '@chakra-ui/react'

import CashReceipt from '@/icons/cash-receipt.svg'
import { t } from '@/lib/helpers'

function DefaultPageViewTable() {
  return (
    <Box backgroundColor="gray.50" height="383px">
      <VStack
        spacing="16px"
        sx={{
          h: 'full',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image src={CashReceipt} width="106px" />
        <Text fontWeight="500" fontSize="24px" color="gray.900">
          {t('views.features.packages.page_views.filter.default_title_1')}
        </Text>
        <Text fontWeight="300" fontSize="18px" color="gray.900">
          {t('views.features.packages.page_views.filter.default_title_2')}
        </Text>
      </VStack>
    </Box>
  )
}

export default DefaultPageViewTable
