import { FormProvider, useForm } from 'react-hook-form'
import { Stack } from '@chakra-ui/layout'
import { Input } from '@chakra-ui/input'
import { Button } from '@chakra-ui/button'
import { Select } from '@chakra-ui/select'

import { t } from '@/lib/helpers'
import { FormControl } from '@/components/FormControl'
import { LanguageDependantInput } from '../../Manage/components/LanguageDependantInput'

import { useCookieCategories } from '../../queries'

import { BannerLanguage, CookieFormInput } from '../../types'
import { INITIAL_MULTI_LANG_FIELD } from '../../constants'

type Props = {
  activeLanguage: BannerLanguage
  categoryId?: number
  defaultValues?: CookieFormInput
  isLoading: boolean
  onCancel: () => void
  onSubmitForm: (data: CookieFormInput) => void
  isScraped?: boolean
}

export const ManageCookieForm = (props: Props) => {
  const {
    activeLanguage,
    categoryId,
    defaultValues,
    isLoading,
    onCancel,
    onSubmitForm,
    isScraped = false,
  } = props

  const { data: cookieCategories } = useCookieCategories()

  const method = useForm<CookieFormInput>({
    defaultValues: {
      ...defaultValues,
      purpose: defaultValues?.purpose ?? INITIAL_MULTI_LANG_FIELD,
      duration: defaultValues?.duration ?? INITIAL_MULTI_LANG_FIELD,
      category_id: `${categoryId || defaultValues?.category_id}`,
    },
  })
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = method

  const onSubmit = (data: CookieFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="24px">
          <FormControl
            isRequired
            id="name"
            label={t('views.features.domains.show.manage.form.cookie_name')}
            errorMsg={errors.name?.message}
            isDisabled={isScraped}
          >
            <Input
              {...register('name')}
              placeholder={t(
                'views.features.domains.show.manage.form.cookie_name',
              )}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="category_id"
            label={t('views.features.domains.show.manage.form.category')}
            isRequired
            sx={{ flex: 1 }}
            errorMsg={errors.category_id?.message}
          >
            <Select {...register('category_id')} size="lg">
              {cookieCategories?.map((cat) => {
                return (
                  <option key={cat.id} value={String(cat.id)}>
                    {cat.name[activeLanguage]}
                  </option>
                )
              })}
            </Select>
          </FormControl>

          <FormControl
            id="url"
            label={t('views.features.domains.show.manage.form.domain')}
            isRequired
            errorMsg={errors.url?.message}
            sx={{ flex: 2 }}
            isDisabled={isScraped}
          >
            <Input
              {...register('url')}
              placeholder={t('views.features.domains.show.manage.form.domain')}
              size="lg"
            />
          </FormControl>

          <FormControl
            isRequired
            id="duration"
            label={t('views.features.domains.show.manage.form.duration')}
          >
            <LanguageDependantInput
              activeLanguage={activeLanguage}
              id="duration_input"
              name="duration"
              placeholder={t(
                'views.features.domains.show.manage.form.duration_placeholder',
              )}
              size="lg"
            />
          </FormControl>

          <FormControl
            isRequired
            id="purpose"
            label={t('views.features.domains.show.manage.form.description')}
          >
            <LanguageDependantInput
              activeLanguage={activeLanguage}
              id="purpose"
              name="purpose"
              useTextArea
              placeholder={t(
                'views.features.domains.show.manage.form.description_placeholder',
              )}
              sx={{ minH: '124px' }}
              size="lg"
            />
          </FormControl>

          <Stack spacing="8px" alignItems="stretch">
            <Button
              id={defaultValues ? 'Cookie-updated' : 'Cookie-added'}
              size="xl"
              type="submit"
              isLoading={isLoading}
            >
              {defaultValues
                ? t('views.features.domains.show.manage.form.edit_cookie')
                : t('views.features.domains.show.manage.form.create_cookie')}
            </Button>
            <Button
              variant="ghost"
              colorScheme="gray"
              color="gray.600"
              size="xl"
              onClick={onCancel}
            >
              {t('views.shared.button.discard')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}
