import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  data: {
    subscription: string
  }
}

const emailResubscribe = async ({ data }: Payload) => {
  return await fetchAPI({
    path: `/subscribe`,
    method: 'post',
    data,
  })
}

export const useEmailResubscribe = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, Payload>(emailResubscribe, {
    async onSuccess() {
      await queryClient.refetchQueries([])

      toast({
        ...TOAST_CONFIG,
        description: 'สมัครรับอีเมลเรียบร้อยแล้ว',
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
