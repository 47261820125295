import AuthLayout from '@/features/auth/layouts/AuthLayout'
import SignUpForm from '@/features/auth/components/SignUpForm'

import Image from '@/images/project/auth/sign_up.svg'

import { t } from '@/lib/helpers'
import { withAuthPage } from '@/features/layout'

type Props = {
  formActionPath: string
  minimumPasswordLength: number
  email?: string
}

function SignUp(props: Props): React.ReactElement {
  return (
    <AuthLayout image={Image} header={t('devise.registrations.new.sign_up')}>
      <SignUpForm {...props} />
    </AuthLayout>
  )
}

export default withAuthPage(SignUp)
