import { AxiosError } from "axios"
import { useQuery } from "react-query"

import { fetchAPI } from "@/lib/api"
import { PAGEVIEWS_CSV_STATUS } from "../constants"
import { PollingResponse } from "../types"

type PageViewCsvResponse = {
  data: PollingResponse
}

export const getPageViewCsvStatus = async (): Promise<PageViewCsvResponse> => {
  const response = await fetchAPI({
    path: '/page_views/exports',
  })

  return {
    data: response.data,
  }
}

export const usePageViewCsvStatus = () => {
  return useQuery<PageViewCsvResponse, AxiosError>({
    queryKey: [PAGEVIEWS_CSV_STATUS],
    queryFn: () => getPageViewCsvStatus(),
  })
}
