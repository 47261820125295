import { Box, Image, Link } from '@chakra-ui/react'
import { SuccessFeedback } from '@datawowio/payment-ui'

import { getQueryParam } from '@/lib/queryParams'
import { logEvent } from '@/lib/stats'

import { withPage } from '@/features/layout'

import { FreeTrialSuccess } from '@/features/free-trial/components'

import CrossSellBanner from '@/images/pricing/cross-sell-banner.png'

import { CREATE_COOKIE_POLICY_LINK } from '@/constants/links'

type Props = {
  purchasedPackage: {
    name: PackageNameInUse
    recurring: Period
  }
}

function SuccessPage({ purchasedPackage }: Props) {
  const type = getQueryParam('type')

  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent" sx={{ padding: '48px 40px' }}>
        {type ? (
          <FreeTrialSuccess />
        ) : (
          <SuccessFeedback
            onClickBack={() => {
              window.location.href = '/projects'
            }}
          />
        )}
      </Box>

      <Link
        href={CREATE_COOKIE_POLICY_LINK}
        isExternal
        onClick={() => {
          logEvent({
            ga: {
              category: 'Thank You Page',
              action: 'Click Cookie Policy',
              label: purchasedPackage.name,
            },
          })
        }}
        sx={{ w: '800px', mx: 'auto', d: 'block' }}
      >
        <Image src={CrossSellBanner} sx={{ w: 'inherit', mt: 4 }} />
      </Link>
    </Box>
  )
}

export default withPage(SuccessPage)
