const variants = {
  pageSetting: (props) => {
    const { theme } = props
    const { colors } = theme

    return {
      track: {
        bg: colors.red[300],
        _checked: {
          bg: colors.green[400],
        },
      },
    }
  },
}

export const Switch = {
  parts: ['thumb', 'track'],
  baseStyle: {
    thumb: {
      bg: 'brand.400',
      _checked: {
        bg: 'brand.50',
      },
    },
    track: {
      bg: 'brand.50',
      boxShadow: 'none !important',
      _checked: {
        bg: 'brand.400',
      },
    },
  },
  sizes: {
    lg: {
      track: {
        padding: '4px',
        width: '2.575rem',
        height: '1.2rem',
      },
      thumb: {
        boxSize: '1.2rem',
        _checked: {
          transform: 'translateX(1.275rem)',
        },
      },
    },
    xl: {
      track: {
        padding: '8px',
        width: '64px',
        height: '32px',
        alignItems: 'center',
      },
      thumb: {
        boxSize: '32px',
        _checked: {
          transform: 'translateX(32px)',
        },
      },
    },
  },
  variants,
  defaultProps: {
    colorScheme: 'green',
  },
}
