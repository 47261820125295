import { Box } from '@chakra-ui/react'

import { TabNavigation } from '@/components/shared'

import { t } from '@/lib/helpers'

type Props = {
  children: React.ReactElement
}

export const PaymentLayout = (props: Props) => {
  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent">
        <Box layerStyle="sectionHeader">
          {t('views.features.packages.title')}
        </Box>
        <TabNavigation routes={tabs} />

        {props.children}
      </Box>
    </Box>
  )
}

const tabs = [
  {
    name: t('views.features.packages.packages.title'),
    href: '/packages',
    checkActive: ['packages'],
  },
  {
    name: t('views.features.packages.receipts.title'),
    href: '/transactions',
    checkActive: ['receipts'],
  },
  {
    name: t('views.features.packages.page_views.title'),
    href: '/page_views',
    checkActive: ['pageViews'],
  },
]
