import { useState } from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'

import { Select, valueToOption } from '@/components/Select'

import { t } from '@/lib/helpers'
import { useActivityLogs } from '../queries'

import { SelectOptions, FolderOptions } from '@/components/Select/types'
import { ActivityLogFilter, ActivityLogFilterType } from '../types'

import { useFilterContext } from '@/features/filters/context'
import { useNonInitialEffect } from '@/lib/hooks'

type ActivityLogFilterSectionProps = {
  folderOptions: FolderOptions
  functionOptions: SelectOptions
}

export const ActivityLogFilterSection = (
  props: ActivityLogFilterSectionProps,
) => {
  const { folderOptions, functionOptions } = props
  const { meta, setFilters } = useFilterContext<ActivityLogFilterType>()
  const { isLoading } = useActivityLogs({ meta })

  const { handleSubmit, control, watch, setValue } = useForm<ActivityLogFilter>(
    {
      defaultValues: meta,
    },
  )

  const folder = watch('folder')

  const [domainOptions, setDomainOptions] = useState<SelectOptions>(
    folderOptions[0].domains,
  )

  useNonInitialEffect(() => {
    setValue('domain', 'all')
  }, [folder])

  return (
    <form
      onSubmit={handleSubmit((values) => {
        setFilters(values)
      })}
    >
      <HStack justifyContent="flex-end" spacing="16px">
        <Controller
          name="folder"
          defaultValue="all"
          control={control}
          render={({ field }) => {
            const selectValue = valueToOption(field.value!, folderOptions)

            return (
              <Box sx={{ w: '340px' }}>
                <Select
                  placeholder={t('views.features.activity_logs.index.folders')}
                  options={folderOptions}
                  value={selectValue}
                  onChange={(option) => {
                    setDomainOptions(
                      option?.domains || folderOptions[0].domains,
                    )
                    field.onChange(option?.value)
                  }}
                  id="SelectFolderFilter"
                />
              </Box>
            )
          }}
        />
        <Controller
          name="domain"
          defaultValue="all"
          control={control}
          render={({ field }) => {
            const selectValue = valueToOption(field.value!, domainOptions)

            return (
              <Box sx={{ w: '340px' }}>
                <Select
                  placeholder={t('views.features.activity_logs.index.domains')}
                  options={domainOptions}
                  value={selectValue}
                  onChange={(option) => {
                    field.onChange(option?.value)
                  }}
                  id="SelectDomainFilter"
                />
              </Box>
            )
          }}
        />
        <Controller
          name="function"
          defaultValue="all"
          control={control}
          render={({ field }) => {
            const selectValue = valueToOption(field.value!, functionOptions)

            return (
              <Box sx={{ w: '340px' }}>
                <Select
                  placeholder={t(
                    'views.features.activity_logs.index.function-feature',
                  )}
                  options={functionOptions}
                  value={selectValue}
                  onChange={(option) => {
                    field.onChange(option?.value)
                  }}
                  id="SelectFunctionFilter"
                />
              </Box>
            )
          }}
        />

        <Button
          size="lg"
          sx={{ w: '140px' }}
          type="submit"
          isLoading={isLoading}
        >
          {t('views.shared.button.filter', undefined, 'Filter')}
        </Button>
      </HStack>
    </form>
  )
}
