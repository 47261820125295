export const Textarea = {
  variants: {
    outline: {
      backgroundColor: 'white',
    },
  },
  defaultProps: {
    focusBorderColor: 'brand.300',
  },
}
