export default {
  features: {
    packages: {
      downgrade: {
        consent_caution:
          'When you downgrade or unsubscribe, exceeded consent records will be permanently deleted. You should export your consent before your next plan’s effective date.',
        same_plan:
          'You can try the free trial <1>until {{ expiry }}</1> and your subscription will be changed to the monthly plan.',
      },
      upgrade: {
        subscribe_to_month:
          'Subscribe to <1>{{ package }}</1> Package<3/><4>{{ price }}</4> THB. <5>per Month?</5>',
        subscribe_to_year:
          'Subscribe to <1>{{ package }}</1> Package<3/><4>{{ price }}</4> THB. <5>per Year?</5>',
        upgrade_to_month:
          'Upgrade to <1>{{ package }}</1> Package<3/><4>{{ price }}</4> THB. <5>per Month?</5>',
        upgrade_to_year:
          'Upgrade to <1>{{ package }}</1> Package<3/><4>{{ price }}</4> THB. <5>per Year?</5>',
        consent_caution:
          'When the new package selection has been confirmed. <3/><1>The free trial period will end immediately</1> and your account will be charged in the first billing according to the new package you choose.',
      },
      cancel: {
        current_plan_expire:
          'Your current plan will <1>expire on {{ expiry }}</1>. All of features of your current plan will be available until then.',
        free_trial_expire:
          'You can try the free trial <1>until {{ expiry }}</1> and be able to use the features that are only available in your current package.',
        can_resub_anytime:
          'You can resubscribe or switch to another paid plan anytime.',
        back_to_free:
          'After that, you will be downgraded to the <1>{{ name }} Plan</1> with the restriction:',
        bullet_1: '<1>{{ pageview }}</1> Pageview per month',
        limited_domain: '{{ count }} Domain per account',
        limited_domain_plural: '{{ count }} Domains per account',
        unsubscribe_notice_description:
          'We still retain the banner setting from your previous plan unless you edited. If you want to use your previous banner setting, please <1>Subscribe</1>',
      },
      pricing: {},
    },
    payments: {
      update:
        'The credit card will be changed to <1>{{ cardNumber }}</1> <2/> An update to your current subscription will be reflected <4/> on your next billing date.',
    },
    domains: {
      scanner: {},
      banner_status: {
        unchecked: 'Not verify',
        checking: 'Checking...',
        active: 'Banner is <1>active</1>',
        inactive: 'Banner is <1>inactive</1>',
      },
      customize: {
        how_to_use_css:
          'For Cookie banner CSS Customization, please visit <1>here</1>',
        include_page_desc:
          'Enter the url of your website to <1>show</1> the banner on that specific pages',
        exclude_page_desc:
          'Enter the url of your website to <1>hide</1> the banner on that specific pages',
        banner_display_desc:
          'Banner Display supports entering URLs in <1>Specific URLs</1> or <1>Wildcard URLs</1> format.',
        banner_display_detail:
          'If you want to scan <1>Wildcard URLs</1>, you can use the asterisk ( <5>*</5> ) symbol as a placeholder to represent the part of the URL that can have different values. For example, using the wildcard URL pattern <5>"https://www.cookiewow.com/*"</5>, the system will put in all URLs that start with <5>"https://www.cookiewow.com/"</5>.',
        scanner_display_desc: `Cookie Wow supports entering URLs in various formats according to user preferences,
          whether it\'s <1>Specific URLs</1> or <1>Wildcard URLs</1>.`,
        scanner_display_detail:
          'If you want to scan <1>Wildcard URLs</1>, you can use the asterisk ( <5>*</5> ) symbol as a placeholder to represent the part of the URL that can have different values. For example, using the wildcard URL pattern <5>"https://www.cookiewow.com/*"</5>, the system will scan all URLs that start with <5>"https://www.cookiewow.com/"</5>.',
      },
      index: {
        domain_exceed:
          'Your domain numbers has reached maximum for the current plan <1>Upgrade plan</1> to add more domain',
        domain_exceed_free_plan:
          'Your domain numbers has reached maximum for the current plan <1>Upgrade plan (try free for up to 14 days)</1> to add more domain',
      },
    },
    layout: {
      exceed_75:
        'Your website <1>Pageview will be at maximum limit</1> for the current plan soon, we recommend to upgrade your plan to keep Cookie Wow recording your visitor consents. <3><0>Upgrade Plan</0></3>',
      exceed_100:
        'Your website <1>Pageview has reached maximum limit</1> for the current plan. <3><0>Upgrade Now</0></3> to keep Cookie Wow recording your visitor consents',
      exceed_120:
        'Your website <1>Pageview has exceeded maximum limit</1> for the current plan. <3><0>Upgrade Now</0></3> to keep Cookie Wow recording your visitor consents',
      free_trial_notice: {
        labels: {
          title:
            'Your free trial on {{ currentPackage }} plan will expire {{ daysBeforeExpire }}. <1>After cancellation, you still have access to the plan until the end of the free trial.</1>',
          subtitle:
            'The subscription fee will be billed at the end of the free trial period on <1>{{- expiry }}</1>',
        },
        links: ['Upgrade Package', 'Cancel Free Trial'],
      },
    },
    users: {
      change_password: {
        minimum_password_length: '{{ count }} character minimum',
        minimum_password_length_plural: '{{ count }} characters minimum',
      },
    },
    migration: {
      choose_domains: `Your current package is <1>{{name}}</1>. You can use <3>{{limit}}</3> domains at maximum`,
      please_select_domains:
        'Please select domains you want to continue using.',
      choose_users: `Your current package is <1>{{name}}</1>. You can have <3>{{limit}}</3> users at maximum.`,
      please_select_users:
        'Please select the user that can continue using the platform.',
    },
  },
  shared: {},
  misc: {
    month: '{{count}} month',
    month_plural: '{{count}} months',
  },
  welcome: 'WELCOME',
}
