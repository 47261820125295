import {
  Box,
  Button,
  Flex,
  Link,
  ModalCloseButton,
  ModalHeader,
  Stack,
  Text,
  UseDisclosureReturn,
  useToast,
  useClipboard,
} from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { copyText, t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureReturn
  script: string
  token: string
}

export const CopyScriptModal = (props: Props) => {
  const { disclosure, script, token } = props
  const { isOpen, onClose } = disclosure

  const toast = useToast()

  const { hasCopied, onCopy } = useClipboard(token)

  const handleCopy = () => {
    copyText(script, () => {
      toast({
        description: t('views.misc.copied'),
        status: 'success',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="640px">
      <ModalHeader>
        {t('views.features.domains.show.modal.cookie_banner_script')}
      </ModalHeader>
      <ModalCloseButton id="close_scriptModal_button" />
      <Box sx={{ p: '6', py: '2', pt: 0 }}>
        <Text>
          <Text as="span" sx={{ fontWeight: 600 }}>
            {t('views.features.domains.show.modal.copy_1')}
            <Text as="span" color="red.300">{` <head> `}</Text>
            {t('views.features.domains.show.modal.copy_2')}
            <Text as="span" color="red.300">{` </head> `}</Text>
            {t('views.features.domains.show.modal.copy_3')}
          </Text>
          <br />
          {t('views.features.domains.show.modal.copy_4')}
        </Text>

        <Stack sx={{ mb: 4 }}>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'gray.300',
              borderRadius: '8px',
              bg: 'gray.100',
              p: '4',
              mt: 4,
              fontSize: '18px',
              fontWeight: 400,
            }}
          >
            {script}
          </Box>

          <Button
            id="Customize-copyScript"
            onClick={handleCopy}
            sx={{ alignSelf: 'flex-end', h: '40px' }}
            size="md"
          >
            {t('views.shared.button.copy')}
          </Button>
        </Stack>

        <Stack sx={{ mb: 8 }}>
          <Text>{t('views.features.domains.show.modal.copy_5')}</Text>

          <Flex
            sx={{
              justifyContent: 'space-between',
              p: '7px 15px',
              border: '1px solid',
              borderColor: 'gray.300',
              borderRadius: '8px',
              bg: 'gray.100',
            }}
          >
            <Text id="CopyScriptModal-copyID">
              {hasCopied ? (
                <Text as="span" sx={{ color: 'brand.400' }}>
                  {t('views.misc.copied')}
                </Text>
              ) : (
                token
              )}
            </Text>
            <Button variant="link" onClick={onCopy}>
              {t('views.shared.button.copy')}
            </Button>
          </Flex>
        </Stack>

        <Flex justify="space-between">
          <Link
            id="Customize-gaLink"
            isExternal
            href="https://help.cookiewow.com/th/article/google-analytics-anonymous-google-analytics-gtm-15c7rdb/"
            color="brand.400"
          >
            {t('views.features.domains.show.modal.how_to_use_ga')}
          </Link>

          <Link
            id="Customize-helpLink"
            isExternal
            href="https://cookiewow.crisp.help/th/"
            color="brand.400"
          >
            {t('views.misc.help')}
          </Link>
        </Flex>
      </Box>
    </Modal>
  )
}
