export const errorPageButtonStyle = {
  bg: 'transparent',
  border: '1px solid #e8e8e8',
  borderRadius: '6px',
  color: '#2b2b2b',
  cursor: 'pointer',
  d: 'inline-flex',
  fontFamily: 'inherit',
  fontSize: '16px',
  fontWeight: 600,
  h: '48px',
  mt: '16px',
  transition: 'background-color 0.2s ease',
  textDecor: 'none',
  _hover: {
    bg: '#f6f6f6',
  },
}
