import { useEffect, useState } from 'react'
import {
  ModalHeader as ChakraModalHeader,
  Box,
  Text,
  Icon,
  Input,
  ModalFooter,
  Grid,
  Button,
  ModalHeaderProps,
} from '@chakra-ui/react'
import { Modal } from '@/components/shared/Modal'
import { FaExclamationCircle } from 'react-icons/fa'

import { t } from '@/lib/helpers'
import { UseDisclosureReturn } from '@chakra-ui/hooks'

export type DeleteDomainModalProps = {
  description?: string
  disclosure: UseDisclosureReturn
  defaultValue?: { name: string }
  isLoading: boolean
  onDelete: () => void
  title?: string
}

export const DeleteDomainModal = (props: DeleteDomainModalProps) => {
  const {
    description,
    disclosure,
    defaultValue,
    isLoading,
    onDelete,
    title = t(
      'views.features.domains.index.delete_domain',
      undefined,
      'Delete Domain',
    ),
  } = props
  const { isOpen } = disclosure

  const [text, setText] = useState('')

  useEffect(() => {
    if (!isOpen) {
      setText('')
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      maxW="500px"
      isLoading={isLoading}
    >
      <ChakraModalHeader>{title}</ChakraModalHeader>

      <Grid px={6} gap={3}>
        <Grid gap={2} mb={3}>
          {description && <Box>{description}</Box>}
          <Box
            sx={{
              padding: '8px',
              backgroundColor: 'brand.50',
              color: 'brand.400',
              borderRadius: '4px',
              border: '1px solid',
              borderColor: 'brand.400',
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              fontWeight: 500,
              fontSize: '14px',
            }}
          >
            <Text>{defaultValue?.name}</Text>
          </Box>
        </Grid>

        <Grid gap={2}>
          <Text>
            {t('views.components.modal.please_type')}&nbsp;
            <strong>DELETE</strong>
            &nbsp;{t('views.features.domains.index.confirm_decision')}
          </Text>
          <Input
            border="1px solid"
            borderColor="brand.400"
            _hover={{
              boxShadow: '0 0 0 1px brand.400',
              border: '2px solid',
              borderColor: 'brand.400',
            }}
            _focus={{
              boxShadow: '0 0 0 1px brand.400',
              borderColor: 'brand.400',
            }}
            borderRadius={'4px'}
            name="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Grid>

        <Box
          sx={{
            padding: '8px 16px',
            backgroundColor: '#EEDADA',
            color: 'red.300',
            borderRadius: '4px',
            borderColor: 'brand.400',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
          }}
          gap={2}
        >
          <Icon as={FaExclamationCircle} />
          <Text>
            {t('views.features.domains.index.domain_include')}&nbsp;
            <strong>
              {t('views.features.domains.index.permanently_delete')}
            </strong>
          </Text>
        </Box>

        <Text>{t('views.features.domains.index.confirm_delete')}</Text>
      </Grid>

      <ModalFooter>
        <Grid gridTemplateColumns="1fr 1fr" gap={4} width={'100%'}>
          <Button
            size="lg"
            variant="outline"
            onClick={disclosure.onClose}
            isDisabled={isLoading}
          >
            {t('views.shared.button.cancel', undefined, 'Cancel')}
          </Button>
          <Button
            id="Domain-deleted"
            size="lg"
            width={'100%'}
            colorScheme="brand"
            disabled={text !== 'DELETE'}
            onClick={onDelete}
            isLoading={isLoading}
          >
            {t('views.shared.button.delete', undefined, 'Delete')}
          </Button>
        </Grid>
      </ModalFooter>
    </Modal>
  )
}

export const ModalHeader = (props: ModalHeaderProps) => {
  return (
    <ChakraModalHeader sx={{ fontSize: '2xl', ...props.sx }}>
      {props.children}
    </ChakraModalHeader>
  )
}
