import { Dispatch, SetStateAction, useState } from 'react'
import { AxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { getScanStatus } from '../queries'
import { useErrorHandler } from '@/lib/hooks'

import { SCANNED_COOKIES, SCANNED_URL, SCAN_STATUS } from '../constants'
import { QUOTA } from '@/features/users/constants'
import { ERROR_TOAST_CONFIG, TOAST_CONFIG } from '@/lib/constants'

import { ScanCookieFormInput } from '../Scanner/types'

type Payload = {
  data: ScanCookieFormInput
  params: {
    projectId: number
  }
}

export const checkScanStatus = async ({ data, params }: Payload) => {
  const { projectId } = params

  await fetchAPI({
    path: `/projects/${projectId}/scrape`,
    method: 'post',
    data,
  })
}

type PollingScanCookiePayload = {
  projectId: number
  startOnMount: boolean
}

export const usePollingScanCookie = (payload: PollingScanCookiePayload) => {
  const { projectId, startOnMount = false } = payload

  const [start, setStart] = useState(startOnMount)

  const handlerError = useErrorHandler()

  const { mutateAsync } = useMutation<void, AxiosError, Payload>(
    checkScanStatus,
    {
      onSuccess() {
        setStart(true)
      },
      onError(error) {
        handlerError(error)
      },
    },
  )

  const { status, lastChecked } = usePolling({ projectId, start, setStart })

  return {
    checkScanStatus: (data: ScanCookieFormInput) =>
      mutateAsync({ data, params: { projectId } }),
    isLoading: start,
    status,
    lastChecked,
  }
}

type PollingPayload = {
  projectId: number
  start: boolean
  setStart: Dispatch<SetStateAction<boolean>>
}

const usePolling = (payload: PollingPayload) => {
  const { projectId, start, setStart } = payload

  const toast = useToast()
  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: [SCAN_STATUS, { projectId }],
    queryFn: () => getScanStatus({ projectId }),
    async onSuccess(data) {
      if (!data) {
        return setStart(false)
      }

      if (['succeeded', 'failed'].includes(data.status)) {
        await queryClient.refetchQueries([SCANNED_COOKIES, { projectId }])
        await queryClient.refetchQueries([QUOTA])

        queryClient.setQueryData([SCANNED_URL, { projectId }], data)

        if (data.status === 'succeeded') {
          toast({
            ...TOAST_CONFIG,
            description: t('views.features.domains.index.scan_success'),
            duration: null,
          })
        } else {
          toast({
            ...ERROR_TOAST_CONFIG,
            description: t('views.features.domains.index.scan_failed'),
            duration: null,
          })
        }

        setStart(false)
      }
    },
    enabled: start,
    refetchInterval: start ? 5000 : false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  return {
    status: data?.status,
    lastChecked: data?.ended_at,
  }
}
