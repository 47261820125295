import {
  Button,
  Image,
  Stack,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'

import { Modal } from '@/components/shared'
import { t } from '@/lib/helpers'
import Warning from '@/images/cookies/cookie-warning.svg'

type UnsaveChangeModalProps = {
  disclosure: UseDisclosureReturn
}

export const UnsaveChangeModal = (props: UnsaveChangeModalProps) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <Stack spacing="9" mt="12" px="8">
        <Image
          boxSize="155px"
          objectFit="cover"
          src={Warning}
          sx={{ margin: 'auto' }}
        />
        <Stack spacing="2" sx={{ textAlign: 'center' }}>
          <Text sx={{ fontSize: '24px', fontWeight: 700 }}>
            {t('views.features.payments.update.unsave')}
          </Text>
          <Text>{t('views.features.payments.update.unsave_desc')}</Text>
        </Stack>
        <Stack spacing="4">
          <Button
            colorScheme="brand"
            size="xl"
            onClick={() => {
              window.location.href = '/packages'
            }}
          >
            {t('views.features.payments.update.leave_page')}
          </Button>
          <Button variant="link" size="xl" py="5" onClick={onClose}>
            {t('views.features.payments.update.stay_on_page')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}
