import { Box, Center, Grid, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { Image } from '@chakra-ui/react'

import Error500Image from '@/images/errors/500-error.png'
import { errorPageButtonStyle } from './styles'

function Error500() {
  return (
    <Center
      sx={{
        w: '100%',
        h: '100vh',
        bg: 'white',
      }}
    >
      <Box maxW="850px" m="0 auto" p="4">
        <Grid gridTemplateColumns="max-content 1fr" gap="4" alignItems="center">
          <Box mr="32px" textAlign="center">
            <Image maxW="430px" src={Error500Image} />
          </Box>

          <Grid gap="1">
            <Text as="h2" fontSize="36px" fontWeight="700">
              Sorry!
            </Text>
            <Text color="#888" fontWeight="300">
              Oops! Something went wrong… The server encountered an internal
              error or misconfiguration and was unable to complete your request.
            </Text>

            <Button sx={errorPageButtonStyle} as="a" href="/">
              Back Homepage
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Center>
  )
}

export default Error500
