import { get } from 'lodash'
import { useMemo } from 'react'
import { AddressType } from '@datawowio/payment-ui'

import { PackageData } from '../types'

export const useTransformPackageData = (
  data: PackageData,
  recurringType: Period,
  addressType: AddressType,
) => {
  const recurringData = data.recurring[recurringType]
  const {
    vat,
    wht,
    vat_percent,
    wht_percent,
    amount,
    discount = 0,
  } = recurringData

  const total = useMemo(() => {
    let otherDiscount = 0

    return Math.max(amount - discount - otherDiscount, 0)
  }, [amount, discount, addressType, wht])

  const transformedPackageData = useMemo(() => {
    return {
      id: data.token,
      name: data.name,
      price: amount,
      net: amount,
      vat,
      vatPercent: vat_percent,
      withHoldingTaxPercent: wht_percent,
      preVatTotal: amount - vat,
      total,
      benefits: {
        autoBlockScript: true,
        autoSaveConsent: true,
        customizeBanner: get(data, 'custom_banner', false),
        customizeLogo: get(data, 'custom_branding', false),
      },
      ...(discount && {
        discount: [
          {
            label: 'ส่วนลดมูลค่าคงเหลือแพ็กเกจ',
            value: discount,
          },
        ],
      }),
      ...(total === 0 && {
        notice:
          'ไม่มีค่าใช้จ่ายใดๆ เพิ่มเติม เนื่องจากการปรับลดแพ็กเกจของคุณครอบคลุมค่าใช้จ่ายทั้งสิ้น',
      }),
    }
  }, [data, recurringType, addressType])

  return transformedPackageData
}
