export default {
  layout: {
    bannerStatus: 'Banner Status',
    checkStatus: 'Check Status',
    bannerStatusUpdated: 'Banner status updated',
    tabs: {
      consent: 'Consent',
      scanner: 'Cookie Scanner',
      manage: 'Manage Cookies',
      customize: 'Customize',
      bannerSettings: 'Banner Settings',
    },
  },
  bannerSettings: {
    menus: {
      bannerModel: 'Banner Model',
      displayBanner: 'Display Banner',
    },
    toast: {
      updateBannerModelSuccess: 'Update Banner Model Successfully',
      updateBannerDisplaySuccess: 'Update Banner Display Successfully',
    },
  },
  customize: {
    bannerButtons: {
      closeBannerDefault: 'Reappear the banner after 1 year',
      closeBannerSession: 'Reappear the banner after session expires.',
      closeBannerSessionTooltip:
        'Banner will appear again when user’s session ID changed; i.e., when closing the browser and reopen.',
      closeBannerTimer: 'Reappear the banner after (minutes)',
      closeBannerTimerTooltip:
        'The banner will reappear again after the specified time. The minimum value is 0 minute (the banner will appear immediately if the site visitor refresh the website or browse to different page within the same domain), and the maximum value is 1 year or 525,600 minutes.',
    },
  },
  manage: {
    category: {
      optInDesc:
        'Cookies in this category will be initially disabled. The website visitors must give consent before Cookie Wow allowing cookies in this category to set.',
      optOutDesc:
        'Cookies in this category will be initially set but the website visitor can disable this cookie category.',
      noticeOnlyDesc:
        'Cookies in this category will be initially set and cannot be disabled by the site visitor.',
    },
  },
  addDomain: {
    warning: {
      title: 'Cannot find domain on the server',
      desc: 'Your domain was not found on the server. <br/> But you can still use it.',
    },
  },
}
