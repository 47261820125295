import {
  Button,
  Collapse,
  FormLabel,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { COOKIE_WOW_URL } from '../../constants'

import { EmailUnsubscribeFormInput } from '../../types'

type Props = {
  reasons: Record<string, string>
  onSubmit: (data: EmailUnsubscribeFormInput) => void
  isLoading: boolean
}

export const EmailUnsubscribeForm = (props: Props) => {
  const { reasons, onSubmit, isLoading } = props

  const { handleSubmit, watch, register, setValue } =
    useForm<EmailUnsubscribeFormInput>({
      defaultValues: {
        reason: '1',
        reason_input: '',
      },
    })

  const reason = watch('reason')
  const isShowInput = reason === '6'

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}
    >
      <FormLabel fontWeight="bold" mb="16px">
        เหตุผลที่ยกเลิกรับอีเมลจาก Cookie Wow
      </FormLabel>
      <RadioGroup
        colorScheme="brand"
        sx={{ mb: 6 }}
        {...register('reason')}
        onChange={(value) => setValue('reason', value)}
      >
        <Stack spacing={4}>
          {Object.entries(reasons).map(([k, v]) => {
            return (
              <Radio key={k} value={k}>
                {v}
              </Radio>
            )
          })}

          <Collapse in={isShowInput}>
            <Input
              {...register('reason_input')}
              placeholder="โปรดระบุ"
              isRequired={isShowInput}
            />
          </Collapse>
        </Stack>
      </RadioGroup>

      <Stack spacing={4}>
        <Button size="xl" type="submit" isLoading={isLoading}>
          ยืนยัน
        </Button>
        <Link
          isExternal
          href={COOKIE_WOW_URL}
          sx={{
            color: 'brand.400',
            textDecor: 'underline',
            textAlign: 'center',
          }}
        >
          กลับสู่หน้าหลัก
        </Link>
      </Stack>
    </form>
  )
}
