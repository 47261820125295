import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { DIALOG } from '../../constants'
import { Dialog } from '../../types'
import { getPathParams } from '@/lib/routes'

type Params = {
  project_id: Project['id']
}

const getDialog = async (params: Params): Promise<Dialog> => {
  const { project_id } = params

  const response = await fetchAPI({
    path: `/projects/${project_id}/dialogs`,
  })

  const data = get(response, 'data', {})

  return data
}

export const useDialog = ({ initialData }: { initialData: Dialog }) => {
  const query = getPathParams()
  const params = { project_id: Number(query?.project_id) }

  return useQuery<Dialog, AxiosError>({
    queryKey: [DIALOG, params],
    queryFn: () => getDialog(params),
    initialData,
  })
}
