import { Fragment, Dispatch, SetStateAction } from 'react'
import {
  Box,
  Grid,
  Center,
  Text,
  Flex,
  Button,
  Image,
  Tooltip,
  Icon,
  Link,
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import { FiInfo } from 'react-icons/fi'

import { updatedPriceConfig } from '../../config'

import { renderCheckMark } from '../../helpers'
import { formatThousand, t } from '@/lib/helpers'
import { usePackageStore } from '../../stores'

import {
  PACKAGE_NAMES_IN_USE,
  PLAN_IMAGE,
  PRICING_TABLE_COLUMNS,
} from '../../constants'

type PricingTableProps = {
  currentPlan: {
    plan: PackageNameInUse
    period: Period
  }
  period: Period
  setPlan: Dispatch<SetStateAction<PackageNameInUse | null>>
  hadSubscribed: boolean
}

export const PricingTable = (props: PricingTableProps): React.ReactElement => {
  const { period = 'year', currentPlan, setPlan, hadSubscribed } = props

  const { t: tCommon } = useTranslation('common')
  const { t: tPackages } = useTranslation('packages')
  const { t: tUnit } = useTranslation('unit')

  const {
    upgradeFreeDisclosure,
    upgradeType1Disclosure,
    upgradeType2Disclosure,
    downgradeType1Disclosure,
    downgradeType2Disclosure,
    downgradeType31Disclosure,
    downgradeType32Disclosure,
    downgradeType4Disclosure,
    resubscribeDisclosure,
    cancelAtPeriodEnd,
    willDowngradeToPackage,
  } = usePackageStore()

  const renderButton = (plan: PackageNameInUse) => {
    if (plan === currentPlan.plan) {
      if (period === currentPlan.period) {
        if (cancelAtPeriodEnd || willDowngradeToPackage) {
          return (
            <Button
              colorScheme="green"
              key={plan}
              onClick={resubscribeDisclosure.onOpen}
              size="lg"
            >
              {t('views.shared.button.resubscribe')}
            </Button>
          )
        }

        return (
          <Text sx={{ color: 'brand.400', fontWeight: 'bold' }} key={plan}>
            {t('views.features.packages.packages.pricing.current_package')}
          </Text>
        )
      }
    }

    if (!hadSubscribed) {
      return (
        <Link href={`/packages/free_trial?package=${plan}`} key={plan} w="full">
          <Button
            id={`Package-subscribe-${plan}`}
            colorScheme="brand"
            size="lg"
          >
            {tPackages(`pricingTable.${plan}TryFree`)}
          </Button>
        </Link>
      )
    }

    if (currentPlan.plan === 'free') {
      return (
        <Button
          key={plan}
          id={`Package-subscribe-${plan}`}
          colorScheme="brand"
          size="lg"
          onClick={() => {
            setPlan(plan)
            upgradeFreeDisclosure.onOpen()
          }}
        >
          {t('views.shared.button.subscribe')}
        </Button>
      )
    }

    if (period === currentPlan.period) {
      if (isPlanAGreaterThanPlanB(plan, currentPlan.plan) > 0) {
        // GREATER
        return (
          <Button
            key={plan}
            id={`Package-upgrade-${plan}`}
            colorScheme="brand"
            size="lg"
            onClick={() => {
              setPlan(plan)
              upgradeType1Disclosure.onOpen()
            }}
          >
            {t('views.shared.button.subscribe')}
          </Button>
        )
      } else {
        // LOWER

        return (
          <Button
            key={plan}
            id={`Package-downgrade-${plan}`}
            colorScheme="brand"
            size="lg"
            onClick={() => {
              setPlan(plan)
              downgradeType1Disclosure.onOpen()
            }}
          >
            {t('views.shared.button.subscribe')}
          </Button>
        )
      }
    } else {
      // DIFF PERIOD

      if (currentPlan.period === 'month') {
        if (isPlanAGreaterThanPlanB(plan, currentPlan.plan) >= 0) {
          return (
            <Button
              key={plan}
              id={`Package-subscribe-${plan}`}
              colorScheme="brand"
              size="lg"
              onClick={() => {
                setPlan(plan)
                upgradeType2Disclosure.onOpen()
              }}
            >
              {t('views.shared.button.subscribe')}
            </Button>
          )
        } else {
          return (
            <Button
              key={plan}
              id={`Package-subscribe-${plan}`}
              colorScheme="brand"
              size="lg"
              onClick={() => {
                setPlan(plan)
                downgradeType4Disclosure.onOpen()
              }}
            >
              {t('views.shared.button.subscribe')}
            </Button>
          )
        }
      } else if (currentPlan.period === 'year') {
        if (currentPlan.plan === plan) {
          return (
            <Button
              key={plan}
              id={`Package-subscribe-${plan}`}
              colorScheme="brand"
              size="lg"
              onClick={() => {
                setPlan(plan)
                downgradeType2Disclosure.onOpen()
              }}
            >
              {t('views.shared.button.subscribe')}
            </Button>
          )
        }

        if (isPlanAGreaterThanPlanB(plan, currentPlan.plan) > 0) {
          return (
            <Button
              key={plan}
              id={`Package-subscribe-${plan}`}
              colorScheme="brand"
              size="lg"
              onClick={() => {
                setPlan(plan)
                downgradeType31Disclosure.onOpen()
              }}
            >
              {t('views.shared.button.subscribe')}
            </Button>
          )
        } else {
          return (
            <Button
              key={plan}
              id={`Package-subscribe-${plan}`}
              colorScheme="brand"
              size="lg"
              onClick={() => {
                setPlan(plan)
                downgradeType32Disclosure.onOpen()
              }}
            >
              {t('views.shared.button.subscribe')}
            </Button>
          )
        }
      }
    }
  }

  return (
    <Grid
      sx={{
        gridTemplateColumns: '258px 1fr 8px 1fr 8px 1fr',
        gridTemplateRows: 'max-content repeat(11, max-content)',
        gridAutoFlow: 'column',
      }}
    >
      <Box sx={titleCellStyle} />
      <Box
        sx={{
          d: 'contents',
          '> *:nth-of-type(odd)': {
            bg: 'gray.50',
          },
        }}
      >
        {PRICING_TABLE_COLUMNS.map((col) => {
          if (col.tooltip) {
            return (
              <Box
                sx={{ ...titleCellStyle, ...(col.sx && col.sx) }}
                key={col.value}
              >
                <Flex align="center">
                  {tPackages(col.value)}
                  <Tooltip
                    label={tPackages(col.tooltip)}
                    aria-label={col.ariaLabel}
                    placement="top"
                    hasArrow
                  >
                    <Center sx={{ ml: 1.5 }}>
                      <Icon as={FiInfo} color="gray.400" boxSize="16px" />
                    </Center>
                  </Tooltip>
                </Flex>
                {col.helperText && <Text>{tPackages(col.helperText)}</Text>}
              </Box>
            )
          }

          return (
            <Box sx={{ ...titleCellStyle, ...col.sx }} key={col.value}>
              {tPackages(col.value)}
            </Box>
          )
        })}
      </Box>
      <Box sx={titleCellStyle} />

      <Box gridRow="1/-1" gridColumn="3/4" />
      <Box gridRow="1/-1" gridColumn="5/6" />
      <Box gridRow="1/-1" gridColumn="7/8" />

      {PACKAGE_NAMES_IN_USE.map((plan) => {
        const {
          price,
          details: {
            consents,
            domains,
            pageView,
            users,
            retentionPeriod,
            scanQuota,
            autoBlockScript,
            autoSaveConsent,
            customizeBanner,
            customizeLogo,
          },
          name,
          description,
        } = updatedPriceConfig[plan]
        const { month } = price

        // const isPaidPlan = plan !== 'free'

        return (
          <Fragment key={plan}>
            <Flex
              sx={{
                ...topCellStyle,
                flexDir: 'column',
                h: 'full',
                pos: 'relative',
              }}
              spacing="0"
            >
              {plan === 'medium' && <BestChoice />}

              <Flex sx={{ flexDir: 'column' }}>
                <Image boxSize="120px" mx="auto" src={PLAN_IMAGE[plan]} />

                <Text
                  sx={{
                    fontSize: '32px',
                    mt: 4,
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  {name}
                </Text>

                <Text sx={{ color: 'gray.600' }}>
                  <Trans t={tPackages} i18nKey={description} />
                </Text>
              </Flex>

              <Box
                sx={{
                  alignSelf: 'flex-end',
                  pos: 'relative',
                  mt: 'auto',
                }}
              >
                {
                  // isPaidPlan &&
                  period === 'year' && (
                    <Text
                      sx={{
                        pos: 'absolute',
                        right: 0,
                        top: '0',
                        fontWeight: 'semibold',
                        color: 'gray.500',
                        textDecor: 'line-through',
                      }}
                    >
                      {month && formatThousand(month * 12)}.-
                    </Text>
                  )
                }
                {/* { 
                 isPaidPlan && */}
                <Text
                  sx={{
                    mt: 6,
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color: period === 'year' ? 'brand.400' : 'unset',
                    transition: 'color 0.2s',
                  }}
                >
                  {formatThousand(price[period])}
                  .-&nbsp;
                  <Text as="span" sx={{ fontSize: 'md', fontWeight: 'normal' }}>
                    / {tCommon(`${period}_unit`)}
                  </Text>
                </Text>
                {/* } */}
              </Box>
            </Flex>

            <Box
              sx={{
                d: 'contents',
                '> *:nth-child(odd)': {
                  bg: 'gray.50',
                },
              }}
            >
              <Center sx={{ ...middleCellStyle }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Text sx={{ fontWeight: 'bold' }}>
                    {pageView.toLocaleString()}
                  </Text>
                  <Text
                    sx={{
                      fontSize: '10px',
                      color: 'gray.500',
                      fontWeight: 'medium',
                      mt: -0.5,
                    }}
                  >
                    Pageviews
                  </Text>
                </Box>
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {domains === Number.POSITIVE_INFINITY ? (
                  tPackages('pricingTable.unlimitedDomains')
                ) : (
                  <Text>
                    {`${domains.toLocaleString()}  ${tUnit('domain', {
                      count: domains,
                    })}`}
                  </Text>
                )}
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {users === Number.POSITIVE_INFINITY ? (
                  tPackages('pricingTable.unlimitedUsers')
                ) : (
                  <Text>
                    {`${users.toLocaleString()}  ${tUnit('user', {
                      count: users,
                    })}`}
                  </Text>
                )}
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {scanQuota.toLocaleString()} URLs
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {consents === Number.POSITIVE_INFINITY ? (
                  tPackages('pricingTable.unlimitedConsents')
                ) : (
                  <Text>
                    {`${consents.toLocaleString()}  ${tPackages(
                      'pricingTable.consent',
                    )}`}
                  </Text>
                )}
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {retentionPeriod}&nbsp;
                {tUnit('month', {
                  count: retentionPeriod,
                })}
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {renderCheckMark(autoBlockScript)}
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {renderCheckMark(autoSaveConsent)}
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {renderCheckMark(customizeBanner)}
              </Center>
              <Center sx={{ ...middleCellStyle }}>
                {renderCheckMark(customizeLogo)}
              </Center>
            </Box>

            <Center
              sx={{
                ...bottomCellStyle,
                button: {
                  borderRadius: 'full',
                  w: 'full',
                },
              }}
            >
              {renderButton(plan)}
            </Center>
          </Fragment>
        )
      })}
    </Grid>
  )
}

const baseCellStyle = {
  fontWeight: 'medium',
  p: 4,
}

const titleCellStyle = {
  ...baseCellStyle,
  gridColumn: '1/2',
  fontWeight: 'semibold',
}

const topCellStyle = {
  ...baseCellStyle,
  border: '1px solid',
  borderBottom: 'none',
  borderRadius: '16px 16px 0 0',
  borderColor: 'gray.200',
}

const bottomCellStyle = {
  ...baseCellStyle,
  p: '24px 16px',
  border: '1px solid',
  borderTop: 'none',
  borderRadius: '0 0   16px 16px',
  borderColor: 'gray.200',
}

const middleCellStyle = {
  ...baseCellStyle,
  borderLeft: '1px solid',
  borderRight: '1px solid',
  borderColor: 'gray.200',
}

const isPlanAGreaterThanPlanB = (
  planA: PackageNameInUse,
  planB: PackageNameInUse,
) => {
  const planValues: Record<PackageNameInUse, number> = {
    free: 0,
    small: 1,
    medium: 2,
    large: 3,
  }

  // if 0 => same plan
  // if >0 => greater plan
  // if <0 => lower plan

  return planValues[planA] - planValues[planB]
}

const BestChoice = () => {
  return (
    <Box
      sx={{
        bg: 'brand.400',
        color: 'white',
        borderRadius: '15px 0 0 15px',
        pos: 'absolute',
        textTransform: 'uppercase',
        fontSize: 'xs',
        top: '20px',
        right: '-3px',
        py: 1,
        pr: 2,
        pl: '10px',
        fontWeight: 'bold',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
      }}
    >
      Best Choice
    </Box>
  )
}
