import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'
import { Invoice } from '../types'

type Payload = {
  data: {
    token: string
    coupon: string
  }
}

export const useApplyCoupon = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const { t: tPayment } = useTranslation('payment')

  return useMutation<Invoice, AxiosError, Payload>(
    async ({ data }) => {
      const { token, coupon } = data

      const response = await fetchAPI<Invoice>({
        method: 'post',
        path: `/invoices/${token}/coupon`,
        data: {
          code: coupon,
        },
      })

      return response.data
    },
    {
      onSuccess() {
        toast({
          ...TOAST_CONFIG,
          description: tPayment('coupon.toast.applySuccess'),
        })
      },
      onError(error) {
        handleError(error, {
          duration: 7000,
        })
      },
    },
  )
}
