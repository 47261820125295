import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Text,
  Stack,
  Image,
  Input,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { AxiosError } from 'axios'

import { CreditCard } from '../../types'
import { useUpdatePaymentServices } from '../../services/UpdatePaymentServices'
import { getErrorMsg } from '@/lib/helpers'
import { inputStyle } from '@/features/auth/constants/styles'

import Warning from '@/images/icons/maintenance.svg'
import { t } from '@/lib/helpers'
import { Modal } from '@/components/shared'
import { PasswordInput } from '@/components/Input'

type DeleteCardProps = {
  disclosure: UseDisclosureReturn
  card: CreditCard
  successOpen: () => void
}

export const DeleteCardModal = (props: DeleteCardProps) => {
  const { disclosure, card, successOpen } = props
  const { isOpen, onClose } = disclosure
  const PaymentServices = useUpdatePaymentServices()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [otherError, setOtherError] = useState<string>('')

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      setError(false)
    }
    setPassword(e.target.value)
  }

  const onCloseModal = () => {
    onClose()
    setError(false)
    setPassword('')
    setOtherError('')
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} maxW="524px" isCentered>
      <form
        onSubmit={async (e) => {
          e.preventDefault()
          setLoading(true)

          await PaymentServices.deleteCard(
            card.id,
            password,
            () => {
              onCloseModal()
              successOpen()
            },
            (error: AxiosError) => {
              const message = getErrorMsg(error)
              setError(true)
              if (message.includes('invalid_password')) return
              setOtherError(message)
            },
          )

          setLoading(false)
        }}
      >
        <Stack spacing="9" mt="12" px="8">
          <Image
            boxSize="140px"
            objectFit="cover"
            src={Warning}
            sx={{ margin: 'auto' }}
          />
          <Box sx={{ textAlign: 'center' }}>
            <Text sx={{ fontSize: '24px', fontWeight: 700 }}>
              {t('views.features.payments.delete.header')}
            </Text>
            <Text sx={{ mt: 2, color: 'gray.600' }}>
              {t('views.features.payments.delete.desc.1')}
            </Text>
            <Text sx={{ color: 'gray.600' }}>
              {t('views.features.payments.delete.desc.2', {
                card: card.number,
              })}
            </Text>
            <FormControl isInvalid={isError} mt="4">
              <PasswordInput
                sx={{ _invalid: { borderColor: 'red.300' } }}
                placeholder={t(
                  'views.features.payments.delete.input.placeholder',
                )}
                isRequired
                value={password}
                onChange={onChangeInput}
              />
              <FormErrorMessage sx={{ color: 'red.300' }}>
                {otherError ||
                  t(
                    'views.features.payments.delete.modal.error.invalid_password',
                  )}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Stack spacing="4">
            <Button
              colorScheme="brand"
              size="xl"
              h="56px"
              type="submit"
              isLoading={isLoading}
              loadingText={t('views.features.payments.delete.confirm_button')}
            >
              {t('views.features.payments.delete.confirm_button')}
            </Button>
            <Button
              variant="outline"
              size="xl"
              h="56px"
              py="3"
              onClick={onCloseModal}
            >
              {t('views.shared.button.discard')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Modal>
  )
}
