import AuthLayout from '@/features/auth/layouts/AuthLayout'
import ResendConfirmationForm from '@/features/auth/components/ResendConfirmationForm'

import Image from '@/images/project/auth/resend_confirmation.svg'

import { t } from '@/lib/helpers'
import { withAuthPage } from '@/features/layout'

type Props = {
  formActionPath: string
}

function ResendConfirmation(props: Props): React.ReactElement {
  return (
    <AuthLayout
      image={Image}
      header={t('devise.confirmations.new.resend_confirmation_instructions')}
    >
      <ResendConfirmationForm {...props} />
    </AuthLayout>
  )
}

export default withAuthPage(ResendConfirmation)
