import { ModalBody, ModalHeader, UseDisclosureReturn } from '@chakra-ui/react'

import { t } from '@/lib/helpers'

import { Modal } from '@/components/shared/Modal'
import { DomainForm } from '../DomainForm'

import { useUpdateDomain } from '../../mutations'

import { SelectOptions } from '@/components/Select/types'

type Props = {
  isProjectPage?: boolean
  disclosure: UseDisclosureReturn
  folderOptions: SelectOptions
  defaultValue: {
    folder_name: string
    name: string
    id: number
  }
}

export const EditDomainModal = (props: Props) => {
  const { disclosure, folderOptions, defaultValue, isProjectPage } = props
  const { isOpen, onClose } = disclosure

  const { mutate: updateDomain, isLoading } = useUpdateDomain()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="560px" withCloseButton>
      <ModalHeader>{t('views.features.domains.index.edit_domain')}</ModalHeader>

      <ModalBody>
        <DomainForm
          defaultValues={{
            folder_name: defaultValue.folder_name,
            url: defaultValue.name,
          }}
          folderNameOptions={folderOptions}
          isLoading={isLoading}
          onClose={onClose}
          onSubmitForm={(data) => {
            updateDomain(
              {
                data,
                isProjectPage,
                params: {
                  projectId: defaultValue.id,
                },
              },
              {
                onSuccess: onClose,
              },
            )
          }}
        />
      </ModalBody>
    </Modal>
  )
}
