import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'
import { COOKIE_CATEGORIES, PROJECT } from '../constants'

import { Category } from '../types'

type Payload = {
  data: {
    name?: {
      [K in BannerLanguage]?: string
    }
    description?: {
      [K in BannerLanguage]?: string
    }
    slug?: string
    consent_model?: Category['consent_model']
    status?: Category['status']
  }
  params: {
    project_id: number
    category_id: number
  }
}

const updateCategory = async ({ data, params }: Payload) => {
  const { project_id, category_id } = params

  const response = await fetchAPI<Category>({
    path: `/projects/${project_id}/categories/${category_id}`,
    method: 'put',
    data,
  })

  return response.data
}

type UseUpdateCategoryProps = {
  onSuccess: (cat: Category) => void
}

export const useUpdateCategory = ({ onSuccess }: UseUpdateCategoryProps) => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  return useMutation<Category, AxiosError, Payload>(updateCategory, {
    async onSuccess(data) {
      onSuccess(data)
      queryClient.invalidateQueries([COOKIE_CATEGORIES, params])
      queryClient.invalidateQueries([PROJECT, params])

      toast({
        ...TOAST_CONFIG,
        title: t(
          'views.features.domains.show.manage.services.category_updated',
        ),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
