export default {
  tab: 'สแกนคุกกี้',
  scan: 'สแกนคุกกี้',
  description_1:
    'คุกกี้บางชนิดที่พบบ่อยจะถูกจัดหมวดหมู่แล้วจะถูกเพิ่มเข้าไปในเมนู ‘จัดการคุกกี้’ โดยอัตโนมัติ ซึ่งคุกกี้ในหน้าจัดการคุกกี้จะถูกตั้งค่าให้ถูกบล็อกก่อนได้รับความยินยอมจากผู้ใช้งาน',
  description_2:
    'Cookie Wow พยายามอย่างเต็มที่ให้สามารถสแกนคุกกี้ให้ได้มากที่สุด แต่อย่างไรก็ตามในบางครั้งเราอาจจะไม่สามารถพบคุกกี้ได้ 100% ซึ่งอาจจะเกิดจากปัจจัยต่าง ๆ ทั้งนี้เราจึงแนะนำให้ท่านตรวจสอบเพิ่มเติมอีกครั้งและเพิ่มคุกกี้ที่สแกนไม่พบที่หน้า <1>จัดการแบนเนอร์คุกกี้</1>',
  description_3:
    'การทำงานของแบนเนอร์คุกกี้นั้น จำเป็นจะต้องใช้คุกกี้ในการทำงาน ท่านสามารถอ่านเพิ่มเติมเกี่ยวกับการใช้คุกกี้ของ Cookie Wow ได้ <1>ที่นี่</1>',
  description_3_link: 'ที่นี่',
  category_updated: 'อัปเดตหมวดหมู่ของคุกกี้เรียบร้อย',
  name: 'ชื่อ',
  domain_url: 'โดเมน / URL',
  type: 'ประเภท',
  duration: 'ระยะเวลาคุกกี้',
  category: 'หมวดหมู่ของคุกกี้',
  last_scanned: 'เช็คเมื่อ',
  title_scanned_url: 'รายการ URL ที่ถูกสแกนแล้ว',
  service_provider: 'ผู้ให้บริการ',
  back_to_cookies_list: 'กลับสู่รายการคุกกี้',
  scan_modal: {
    title: 'สแกนคุกกี้',
    description:
      'เพื่อให้เกิดความรวดเร็วในการประมวลผล โปรดจำกัดจำนวนการสแกนของคุณเท่าที่จำเป็นเท่านั้นเพื่อประโยชน์สูงสุด',
    description_2: '(แนะนำไม่เกิน 10 url ต่อวัน)',
    number_scan: 'จำนวน url ที่ต้องการสแกน',
    remaining_for_your_package:
      'คงเหลือสำหรับแพ็กเกจของคุณ: %{remaining}/%{total} url',
  },
  unassigned: 'ยังไม่ถูกจัดหมวดหมู่',
  include_label: 'URL ที่ต้องการ',
  optional: 'ถ้ามี',
  exclude_label: 'URL ที่ยกเว้น',
  exclude_scan_form_helper:
    'กรอก URL ที่ยกเว้นในการสแกน สามารถเพิ่ม URL ได้หลายรายการโดยคั่นด้วยเครื่องหมายจุลภาค ( , )',
  include_scan_form_helper:
    'กรอก URL ที่ต้องการในการสแกน สามารถเพิ่ม URL ได้หลายรายการโดยคั่นด้วยเครื่องหมายจุลภาค ( , )',
  scan_input_placeholder: 'กรอก URL ที่นี่ (http:// หรือ https://)',
  advanced_setting: 'การตั้งค่าขั้นสูง',
}
