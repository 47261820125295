import {
  BannerSize,
  BANNER_LANGUAGE,
  ConsentAcceptStatus,
  LogoType,
  Position,
} from '@datawowio/cookie-banner-ui'

import { COOKIE_ICON_OPTIONS } from './constants'
import { PAGE_SETTINGS, POLICY_TYPES } from './Customize/constants'

import { ConsentModel } from './Manage/types'
import { CurrentPackage, PollingStatus } from '../types'
import { ConsentAction } from './Consents/types'

export type Theme = 'dark' | 'light'
export type Screen = 'desktop' | 'tablet' | 'mobile'
export type PolicyType = typeof POLICY_TYPES[number]
export type BannerLanguage = typeof BANNER_LANGUAGE[number]
export type Widget = 'left' | 'right'
export type Revokable = '1' | '2'
export type CloseButtonBehavior = 'session' | 'timer' | 'default' | ''
export type BannerModel = 'notice_only' | 'default'
export type PageSetting = typeof PAGE_SETTINGS[number]
export type WidgetIconType = typeof COOKIE_ICON_OPTIONS[number]

export type DomainPageProps = {
  project: Project
  banner_polling_status: PollingStatus
  currentPackage: CurrentPackage
}

export type MultiLangField = Record<BannerLanguage, string>

export type Dialog = {
  accept_all_label: MultiLangField
  auto_detect: boolean
  banner_language: BannerLanguage
  banner_model: BannerModel
  banner_radius: number
  bg_color: string
  button_radius: number
  close_banner_timer: string
  close_button: boolean
  close_button_behavior: CloseButtonBehavior
  color: string
  content: MultiLangField
  created_at: string
  custom_css: string | null
  excluded_urls: string[]
  id: number
  included_urls: string[]
  logo_type: LogoType
  logo_url: string | null
  name: string
  ok_button_label: MultiLangField
  page_setting: PageSetting
  policy_title: MultiLangField
  policy_type: PolicyType
  policy_url: MultiLangField
  position: Position
  preference_button: boolean
  preference_label: MultiLangField
  preview_language: BannerLanguage
  reject_all: boolean
  reject_all_label: MultiLangField
  show_footer_logo: boolean
  show_logo: boolean
  show_preference_header_logo: boolean
  show_title: boolean
  text_color: string
  theme: Theme
  title: MultiLangField
  updated_at: string
  view_details_label: MultiLangField
  widget: Widget | null
  widget_icon_type: 'default' | 'upload' | 'favicon'
  widget_icon_url?: string
  z_index: number | null
}

export type DialogFormInput = {
  accept_all_label: MultiLangField
  auto_detect: boolean
  banner_language: BannerLanguage
  banner_model: BannerModel
  banner_radius: number
  bg_color?: string
  button_radius: number
  close_banner_timer?: string
  close_button: boolean
  close_button_behavior: CloseButtonBehavior
  color: string
  content: MultiLangField
  custom_css?: string
  excluded_urls: string[]
  font_size: BannerSize
  included_urls: string[]
  logo?: File | string | null
  logo_type: LogoType
  ok_button_label: MultiLangField
  page_setting: PageSetting
  policy_title: MultiLangField
  policy_type: PolicyType
  policy_url: MultiLangField
  position: Position
  preference_button: boolean
  preference_label: MultiLangField
  preview_language: BannerLanguage
  reject_all: boolean
  reject_all_label: MultiLangField
  revokable: Revokable
  show_footer_logo: boolean
  show_logo: boolean
  show_preference_header_logo: boolean
  show_title: boolean
  text_color?: string
  theme: Theme
  title: MultiLangField
  view_details_label: MultiLangField
  widget: Widget
  widget_icon_type: WidgetIconType
  widget_icon_url?: File | string | null
  z_index: number | null
}

export type AdvancedSettingFormInput = Pick<
  DialogFormInput,
  'banner_model' | 'included_urls' | 'excluded_urls' | 'page_setting'
>

export enum StoreType {
  Cookie = 'cookie',
  Script = 'script_tag',
  Local_Storage = 'local_storage',
}

export type Domain = {
  consents_count: number
  created_at: string
  id: number
  page_views_count: number
  token: string
  updated_at: string
  url: string
  user_id: number
  folder: {
    id: number
    name: string
  }
}

export type DomainFormInput = {
  url: string
  folder_name: string
}

export type ConsentLog = {
  banner_version: string
  category: string
  consent_action: ConsentAction
  remember_choice: ConsentAcceptStatus
  // consents?: {
  //   category: string
  //   remember_choice: ConsentAcceptStatus
  // }[]
  created_at: string
  id: number
  ip_address: {
    addr: number
    family: number
    mark_addr: number
  }
  session_id: string
  updated_at: string
}

export type CookieFormInput = {
  name: string
  category_id: string | number | null
  duration: Record<BannerLanguage, string>
  purpose: Record<BannerLanguage, string>
  url: string
}

export type ScriptFormInput = {
  name: string
  category_id: string | null
  purpose: Record<BannerLanguage, string>
  url: string
}

export type CookieType = 'cookie' | 'script'

export type Cookie = {
  category: Category | null
  category_id: number | null
  created_at: string
  duration: Record<BannerLanguage, string> | null
  expiry: string | null
  id: number
  name: string
  project_id: number
  provider: string
  purpose: Record<BannerLanguage, string>
  scraped: boolean
  state: string
  store_type: CookieType
  updated_at: string
  url: string
}

export type Category = {
  created_at: string
  cookies: Cookie[]
  consent_model: ConsentModel
  default_category?: boolean
  description: Record<BannerLanguage, string>
  id: number
  name: Record<BannerLanguage, string>
  slug: string
  status: 'active' | 'inactive'
  updated_at: string
}
