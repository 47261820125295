import { Dispatch, Fragment, SetStateAction } from 'react'
import { Button, Image, Stack, Text } from '@chakra-ui/react'

import { Can } from '@/features/permissions/components'
import AddIcon from '@/icons/plus-orange.svg'

import { t } from '@/lib/helpers'
import { Category } from '../../Manage/types'

type CategoryListProps = {
  activeLanguage: BannerLanguage
  cookieCategories: Category[]
  currentCategory?: Category
  isDisabled: boolean
  onClickCategory: Dispatch<SetStateAction<Category>>
  onOpenCreateCategory: () => void
}

export const CategoryList = (props: CategoryListProps) => {
  const {
    activeLanguage,
    cookieCategories,
    currentCategory,
    onClickCategory,
    onOpenCreateCategory,
    isDisabled,
  } = props

  return (
    <Fragment>
      <Stack spacing="16px">
        {cookieCategories.map((cat: Category, index: number) => {
          const activeCat = currentCategory?.id === cat.id
          const name = cat.name[activeLanguage]

          return (
            <Button
              colorScheme={activeCat ? 'brand' : 'gray'}
              id={`CategoryButton-${name}`}
              isDisabled={isDisabled}
              key={`${index}-${name}`}
              onClick={() => onClickCategory(cat)}
              sx={{ h: '56px', justifyContent: 'flex-start' }}
              variant={activeCat ? 'solid' : 'outline'}
            >
              <Text
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: 1.6,
                }}
              >
                {name}
              </Text>
            </Button>
          )
        })}

        <Can I="create" a="category">
          {() => {
            return (
              <Button
                id="Category-add"
                height="56px"
                variant="outline"
                borderStyle="dashed"
                onClick={onOpenCreateCategory}
                leftIcon={<Image src={AddIcon} boxSize="16px" />}
                isDisabled={isDisabled}
              >
                {t('views.features.domains.show.manage.add_new_cat')}
              </Button>
            )
          }}
        </Can>
      </Stack>
    </Fragment>
  )
}
