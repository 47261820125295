import { useRef } from 'react'
import { Button } from '@chakra-ui/button'
import { ModalHeader } from '@chakra-ui/modal'
import { Stack, Text } from '@chakra-ui/layout'
import { Trans } from 'react-i18next'
import * as d3 from 'd3-format'
import { get } from 'lodash'

import { Modal } from '@/components/shared'
import { SvgImage } from '@/components/DataDisplay'
import { PackageDetails } from './shared'

import { usePackageStore } from '@/features/payments/stores'
import { useCurrentUser } from '@/features/users/queries'
import { matchLanguage, t } from '@/lib/helpers'
import { logEvent } from '@/lib/stats'
import { priceConfig } from '../../config'

import downgradeMonth from '@/images/project/payments/downgrade-month.png'
import { DowngradeModalProps } from './types'

type Props = DowngradeModalProps & {
  useForType32?: boolean
}

// Shared with Downgrade Type 3.2

export const DowngradeType2Modal = (props: Props) => {
  const { disclosure, useForType32 = false } = props
  const { isOpen, onClose } = disclosure

  const initialRef = useRef(null)

  const { plan, onCheckout, expiry } = usePackageStore()
  const { data: user } = useCurrentUser()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      withCloseButton
      id={useForType32 ? 'downgrade-type-32-modal' : 'downgrade-type-2-modal'}
    >
      <Stack justify="center" spacing="16px" sx={{ px: 8, py: 6 }}>
        <SvgImage
          src={downgradeMonth}
          sx={{ w: '138px', h: '127px', mx: 'auto' }}
        />
        <ModalHeader
          sx={{ textAlign: 'center', fontSize: '2xl', fontWeight: 600 }}
        >
          {useForType32 ? (
            <Trans i18nKey={`features.packages.upgrade.subscribe_to_month`}>
              Subscribe to&nbsp;
              <Text
                as="span"
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: matchLanguage(700, 600),
                }}
              >
                {{ package: plan }}
              </Text>
              &nbsp;Package&nbsp;
              <br />
              <Text
                as="span"
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: matchLanguage(700, 600),
                }}
              >
                {{
                  price: d3.format(',d')(
                    get(priceConfig, `${plan}.month.price`),
                  ),
                }}
                THB.&nbsp;
              </Text>
              <Text
                as="span"
                sx={{
                  fontWeight: matchLanguage(700, 600),
                }}
              >
                per month
              </Text>
            </Trans>
          ) : (
            t('views.features.packages.packages.downgrade.continue_downgrade')
          )}
        </ModalHeader>

        {useForType32 ? (
          user?.current_package.trialing ? (
            <Text>
              <Trans i18nKey="features.packages.cancel.free_trial_expire">
                You can try the free trial&nbsp;
                <Text
                  as="span"
                  sx={{
                    fontWeight: matchLanguage<number>(700, 600),
                    color: 'red.400',
                  }}
                >
                  until {{ expiry }}
                </Text>
                and be able to use the features <br /> that are only available
                in your current package.
              </Trans>
            </Text>
          ) : (
            <Text>
              <Trans i18nKey="features.packages.cancel.current_plan_expire">
                Your current plan will&nbsp;
                <Text
                  as="span"
                  sx={{
                    fontWeight: matchLanguage<number>(700, 600),
                    color: 'red.400',
                  }}
                >
                  expire on {{ expiry }}
                </Text>
                . All of features of your current plan will be available until
                then.
              </Trans>
            </Text>
          )
        ) : user?.current_package.trialing ? (
          <Text>
            <Trans i18nKey="features.packages.downgrade.same_plan">
              You can continue the free trial&nbsp;
              <Text
                as="span"
                sx={{
                  fontWeight: matchLanguage<number>(700, 600),
                  color: 'red.400',
                }}
              >
                until {{ expiry }}
              </Text>
              and your subscription will be changed to the monthly plan.
            </Trans>
          </Text>
        ) : (
          <Text>
            {t('views.features.packages.packages.upgrade.desc_month')}&nbsp;
            <Text
              as="span"
              sx={{
                fontWeight: matchLanguage(700, 600),
              }}
            >
              {expiry}
            </Text>
          </Text>
        )}

        {useForType32 && plan && (
          <PackageDetails
            plan={plan}
            trialing={user?.current_package.trialing}
          />
        )}

        <Button
          colorScheme="brand"
          size="xl"
          ref={initialRef}
          onClick={() => {
            logEvent({
              ga: {
                category: 'Upgrade',
                action: 'Click Upgrade',
                ...(plan && {
                  label: plan,
                }),
              },
            })
            onCheckout()
          }}
        >
          {t('views.shared.button.downgrade')}
        </Button>
        <Button variant="link" size="xl" onClick={onClose}>
          {t('views.shared.button.discard')}
        </Button>
      </Stack>
    </Modal>
  )
}
