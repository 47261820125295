import { Button, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { FormControl } from '@/components/FormControl'
import { PasswordInput } from '@/components/Input'

import { TwoFactorPasswordFormInput } from '../../types'

import { t } from '@/lib/helpers'

export type TwoFactorPasswordFormProps = {
  onSubmit: (data: TwoFactorPasswordFormInput) => void
  onCancel: () => void
  isLoading: boolean
}

export const TwoFactorPasswordForm = (
  props: TwoFactorPasswordFormProps,
): React.ReactElement => {
  const { isLoading, onSubmit, onCancel } = props

  const { register, handleSubmit } = useForm<TwoFactorPasswordFormInput>()

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}
    >
      <Stack spacing="6">
        <FormControl
          label={t(
            'views.features.two_factor.form.password',
            undefined,
            'Password',
          )}
          isRequired
        >
          <PasswordInput
            size="lg"
            placeholder={t(
              'views.features.two_factor.form.password_placeholder',
              undefined,
              'Enter your password',
            )}
            {...register('password')}
          />
        </FormControl>

        <Stack>
          <Button size="xl" type="submit" isLoading={isLoading}>
            {t('views.shared.button.confirm', undefined, 'Confirm')}
          </Button>
          <Button
            size="xl"
            variant="ghost"
            colorScheme="gray"
            sx={{ color: 'gray.600' }}
            isDisabled={isLoading}
            onClick={onCancel}
          >
            {t('views.shared.button.discard', undefined, 'Discard')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
