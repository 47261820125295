import { Box, Grid, Stack, Text } from '@chakra-ui/layout'
import { Switch } from '@chakra-ui/switch'
import { Controller } from 'react-hook-form'

import { t } from '@/lib/helpers'

import { useAppContext } from '@/features/layout'
import { UpgradeText } from '../../shared'

export const AutoDetect = () => {
  const { isFree } = useAppContext()

  return (
    <Stack spacing="2" sx={{ fontSize: '14px' }}>
      <Grid sx={{ gridTemplateColumns: '1fr 49px' }}>
        <Stack spacing="1" sx={{ fontSize: '14px' }}>
          <Text sx={{ fontWeight: 700 }}>
            {t(
              'views.features.domains.show.customize.auto_detect_language',
              undefined,
              'Auto Detect Language',
            )}
          </Text>
          <Text sx={{ color: 'gray.600' }}>
            {t(
              'views.features.domains.show.customize.auto_translate_banner',
              undefined,
              'Auto-match banner to website language',
            )}
          </Text>
        </Stack>

        <Box sx={{ pt: '8px' }}>
          <Controller
            name="auto_detect"
            render={({ field }) => {
              return (
                <Switch
                  size="lg"
                  onChange={field.onChange}
                  isChecked={field.value}
                  id="AutoDetectLanguageSwitch"
                  isDisabled={isFree}
                />
              )
            }}
          />
        </Box>
      </Grid>
      <UpgradeText linkID="AutoDetectLanguage-upgrade" />
    </Stack>
  )
}
