import { ReactNode, ComponentType, useMemo, Fragment, useEffect } from 'react'
import { get } from 'lodash'
import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import dayjs from 'dayjs'
import 'dayjs/locale/th'

import { ThemeProvider } from '@/lib/styles'
import { AppContextProvider } from './ApplicationContext'
import { PermissionContextProvider } from '../permissions'

import { NavBar, Footer } from '@/components/Layout'
import { NewFeatureModal } from '@/features/layout/components'
import { PageviewAlertBanner, UnsubscribeBanner } from './components'

import { CurrentPackage } from '../types'
import { CurrentUser } from '../users/types'
import { Rules } from '../permissions/types'

import { useCurrentUser, useQuota } from '../users/queries'
import { useReadNotification } from './mutations'

import '@/features/i18n'
import 'focus-visible/dist/focus-visible'

import { logUser, logEcommerce } from '@/lib/stats'

dayjs.locale(gon.currentLocale)

type ApplicationProps = {
  children: ReactNode
  currentPackage: CurrentPackage
  policies: Rules
  quota: Quota
  user: CurrentUser
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

const ApplicationLayout = (props: ApplicationProps) => {
  const { children, user, policies, ...rest } = props

  const contextValues = {
    ...rest,
    platformLanguage: gon.currentLocale,
    currentUser: user,
    isFree: get(props, 'currentPackage.name') === 'free',
  }

  useEffect(() => {
    if (window.howuku) {
      window.howuku.identify({
        email: user.email,
        company: user.company_name ?? '',
      })
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AppContextProvider value={contextValues}>
          <PermissionContextProvider policies={policies}>
            {children}
          </PermissionContextProvider>
        </AppContextProvider>
      </ThemeProvider>

      <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
  )
}

// Plain wrapper with no other components
export const withApp =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P & ApplicationProps) => {
    return (
      <ApplicationLayout {...props}>
        <Component {...props} />
      </ApplicationLayout>
    )
  }

export const withAuthPage =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P & ApplicationProps) => {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <Component {...props} />
        </ThemeProvider>
      </QueryClientProvider>
    )
  }

// Wrapper with component
export const withPage = <P extends object>(Component: ComponentType<P>) =>
  withApp((props: P & ApplicationProps) => {
    const { user } = props

    useCurrentUser()
    const { data: quota } = useQuota({ initialData: props.quota })
    const { mutate: readNotification } = useReadNotification()

    const newFeatureDisclosure = useDisclosure()

    const quotaPercent = useMemo(() => {
      if (!quota?.page_view.limit) return 0

      return (quota?.page_view.used / quota?.page_view.limit) * 100
    }, [quota])

    useEffect(() => {
      if (user.unread_notifications.length) {
        newFeatureDisclosure.onOpen()
      }
    }, [])

    const currentPackage = user.current_package

    useEffect(() => {
      const id = user?.id ?? ''

      logUser(`${id}`)

      if (!id) {
        logEcommerce('', null)
      }
    }, [user?.id])

    return (
      <Fragment>
        <Flex flexDir="column" sx={{ minH: '100vh' }}>
          <PageviewAlertBanner quotaPercent={quotaPercent} />
          <Grid
            sx={{
              gridTemplateRows: `56px calc(100% - 56px - 56px) 56px`,
              flex: 1,
              h: '100%',
            }}
          >
            <NavBar />

            <Box sx={{ pb: 8 }}>
              <UnsubscribeBanner
                date={currentPackage.expiry}
                currentPackage={currentPackage.name}
                isOpen={
                  currentPackage.trialing &&
                  !currentPackage.cancel_at_period_end &&
                  dayjs(currentPackage.expiry).diff(dayjs(), 'days', true) < 7
                }
              />

              <Component {...props} />
            </Box>

            <Footer />
          </Grid>
        </Flex>

        {Boolean(user.unread_notifications.length) && (
          <NewFeatureModal
            disclosure={newFeatureDisclosure}
            notifications={user.unread_notifications}
            onReadNotification={() => {
              newFeatureDisclosure.onClose()
              readNotification()
            }}
          />
        )}
      </Fragment>
    )
  })
