import { ModalBody, UseDisclosureReturn } from '@chakra-ui/react'

import { Modal } from '@/components/shared'
import { ChangeEmailSuccess } from '../components'

type UpdateEmailSuccessModalProps = {
  disclosure: UseDisclosureReturn
  email: string
}

export const UpdateEmailSuccessModal = (
  props: UpdateEmailSuccessModalProps,
) => {
  const { disclosure, email } = props
  const { isOpen, onClose } = disclosure

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <ModalBody sx={{ mt: 8 }}>
        <ChangeEmailSuccess email={email} onClose={onClose} />
      </ModalBody>
    </Modal>
  )
}
