import { Box } from '@chakra-ui/layout'
import {
  DefaultNamespace,
  Namespace,
  TFuncKey,
  Trans,
  TransProps,
} from 'react-i18next'
import he from 'he'

export const UnescapedTrans = <
  K extends TFuncKey<N> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  E extends Element = HTMLDivElement,
>(
  props: TransProps<K, N, E>,
) => (
  <Box
    as="span"
    ref={(ref) => {
      if (!ref) return

      return Array.from(ref.childNodes).forEach((node) => {
        if (!node.textContent) {
          return node
        }

        node.textContent = he.decode(node.textContent)
      })
    }}
  >
    <Trans {...props} tOptions={{ interpolation: { escapeValue: true } }} />
  </Box>
)
