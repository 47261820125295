import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { getPathParams } from '@/lib/routes'

import { DASHBOARD_STATS } from '../constants'
import {
  CategoryStatItem,
  StatusStatItem,
  ConsentOverviewItem,
  ConsentRate,
  DashboardStatsMeta,
  DashboardStats,
  FilterPeriod,
} from '../types'

type UseDashboardStatsProps = {
  enabled?: boolean
}

type Params = { date_range: FilterPeriod; project_id: string }

async function getStats({
  date_range,
  project_id,
}: Params): Promise<DashboardStats> {
  const { data: consent_action_counts } = await fetchAPI<{
    data: { consent_action_counts: StatusStatItem[] }
    meta: DashboardStatsMeta
  }>({
    path: `/projects/${project_id}/dashboard/consent_action_counts`,
    params: { date_range },
  })

  const { data: consent_rate } = await fetchAPI<{
    data: { consent_rate: ConsentRate }
    meta: DashboardStatsMeta
  }>({
    path: `/projects/${project_id}/dashboard/consent_rate`,
    params: { date_range },
  })

  const { data: category_counts } = await fetchAPI<{
    data: { category_counts: CategoryStatItem[] }
    meta: DashboardStatsMeta
  }>({
    path: `/projects/${project_id}/dashboard/category_counts`,
    params: { date_range },
  })

  const { data: consent_overview } = await fetchAPI<{
    data: Record<FilterPeriod, ConsentOverviewItem[]>
  }>({
    path: `/projects/${project_id}/dashboard/graph`,
    params: { date_range },
  })

  return {
    data: {
      category_counts: category_counts.data.category_counts,
      consent_action_counts: consent_action_counts.data.consent_action_counts,
      consent_overview: consent_overview.data,
      consent_rate: consent_rate.data.consent_rate,
    },
    meta: consent_action_counts.meta,
  }
}

export const useDashboardStats = ({
  date_range,
  enabled,
}: Omit<Params, 'project_id'> & UseDashboardStatsProps) => {
  const { project_id } = getPathParams()

  const params = {
    project_id: project_id as string,
    date_range,
  }

  return useQuery<DashboardStats, AxiosError>({
    queryKey: [DASHBOARD_STATS, params],
    queryFn: () => getStats(params),
    enabled: enabled,
    retry: 5,
    retryDelay: 5000,
  })
}
