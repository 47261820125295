const textStyles = {
  formHelper: {
    color: 'gray.500',
    fontSize: 'sm',
  },
  formError: {
    color: 'red.400',
  },
}

export default textStyles
