import { useCallback, useState } from 'react'
import { Grid, Stack } from '@chakra-ui/layout'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  CreditCardForm,
  PlanForm,
  AddressForm,
  OrderSummary,
  PackageForm,
} from '../../components'

import { PACKAGE_NAMES_IN_USE } from '@/features/payments/constants'
import {
  OVERRIDE_STRIPE_STYLES,
  STRIPE_ELEMENTS,
  TAX_PAYER_TYPES,
} from '../../constants'
import { freeTrialFormSchema } from '../../schema'

import { useSubmitFreeTrial } from '../../mutations'
import { getQueryParam } from '@/lib/queryParams'

import {
  FreeTrialFormInput,
  StripeElementErrors,
  StripeElementString,
} from '../../types'

const defaultErrors: StripeElementErrors = {
  card_number: '',
  card_expiry: '',
  card_cvc: '',
}

export const FreeTrialFormContainer = (): React.ReactElement => {
  const stripe = useStripe()
  const elements = useElements()

  const { t } = useTranslation(['freeTrial'])

  const packageName = getQueryParam('package') as PackageNameInUse

  const [errors, setErrors] = useState(defaultErrors)

  const method = useForm<FreeTrialFormInput>({
    defaultValues: {
      package: PACKAGE_NAMES_IN_USE.includes(packageName)
        ? packageName
        : 'small',
      recurring: 'year',
      taxpayer_type: TAX_PAYER_TYPES[0],
    },
    resolver: zodResolver(freeTrialFormSchema),
  })
  const { handleSubmit } = method

  const { mutate, isLoading } = useSubmitFreeTrial()

  const validateStripeField = useCallback((className: StripeElementString) => {
    setErrors(defaultErrors)

    let element = document.querySelector(`.${className}.StripeElement--empty`)

    if (element) {
      let errorMsg = ''
      switch (className) {
        case 'card_number': {
          errorMsg = t('form.errors.numberRequired')
          break
        }
        case 'card_expiry': {
          errorMsg = t('form.errors.expiryRequired')
          break
        }
        case 'card_cvc': {
          errorMsg = t('form.errors.cvcRequired')
          break
        }
      }

      setErrors((state) => ({ ...state, [className]: errorMsg }))

      return false
    }

    let element2 = document.querySelector(
      `.${className}.StripeElement--invalid`,
    )

    if (element2) {
      let errorMsg = ''
      switch (className) {
        case 'card_number': {
          errorMsg = t('form.errors.numberInvalid')
          break
        }
        case 'card_expiry': {
          errorMsg = t('form.errors.expiryInvalid')
          break
        }
        case 'card_cvc': {
          errorMsg = t('form.errors.cvcInvalid')
          break
        }
      }

      setErrors((state) => ({ ...state, [className]: errorMsg }))

      return false
    }

    return true
  }, [])

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit((data) => {
          if (!stripe || !elements) {
            return
          }

          const number = elements.getElement(CardNumberElement)
          const expiry = elements.getElement(CardExpiryElement)
          const cvc = elements.getElement(CardCvcElement)

          let isPassed = true

          for (const element of STRIPE_ELEMENTS) {
            if (!validateStripeField(element)) {
              isPassed = false
              break
            }
          }

          if (!isPassed) return

          mutate({
            data: {
              form: data,
              number,
              expiry,
              cvc,
            },
          })
        })}
      >
        <Grid sx={{ gridTemplateColumns: '1fr 440px', gap: '16px' }}>
          <Stack
            sx={{
              p: 6,
              border: '1px solid',
              borderColor: 'gray.200',
              borderRadius: 'lg',
              ...OVERRIDE_STRIPE_STYLES,
            }}
            spacing={6}
          >
            <PackageForm />
            <PlanForm />
            <CreditCardForm errors={errors} />
            <AddressForm />
          </Stack>

          <OrderSummary isLoading={isLoading} />
        </Grid>
      </form>
    </FormProvider>
  )
}
