import { fetchAPI } from '@/lib/api'
import { useMutation, UseMutationResult } from 'react-query'
import { useTranslation } from 'react-i18next'

import { SetupIntentResponse } from '../types'

export const useSetupIntent = (): UseMutationResult<string> => {
  const { t } = useTranslation('payment')

  return useMutation(async () => {
    const response = await fetchAPI<SetupIntentResponse>({
      method: 'post',
      path: '/credit_cards/setup',
    })

    const clientSecret = response.data.client_secret

    if (!clientSecret) {
      throw new Error(t('toast.paymentFailed'))
    }

    return clientSecret
  })
}
