import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/toast'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'
import { DOMAINS } from '../constants'
import { QUOTA } from '@/features/users/constants'

type Payload = {
  projectIds: number[]
}

const deleteDomains = (payload: Payload) => {
  const { projectIds } = payload

  return fetchAPI({
    path: '/downgrade_conflicts',
    method: 'delete',
    data: {
      except_projects: projectIds,
    },
  })
}

export const useDeleteDomains = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse, AxiosError, Payload>(deleteDomains, {
    async onSuccess() {
      await queryClient.refetchQueries([QUOTA])
      await queryClient.refetchQueries([DOMAINS])

      toast({
        ...TOAST_CONFIG,
        description: t('views.features.domains.index.delete_success'),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
