import { PropsWithChildren, ReactNode } from 'react'
import { Box } from '@chakra-ui/layout'

export type ListPageLayoutProps = {
  heading: ReactNode
  contents?: ReactNode
  rightAddOn?: ReactNode
}

export const ListPageLayout = (
  props: PropsWithChildren<ListPageLayoutProps>,
) => {
  const { children, rightAddOn, heading, contents } = props

  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent">
        <Box
          layerStyle="sectionHeader"
          sx={{
            d: 'grid',
            justifyContent: 'space-between',
            gridTemplateColumns: 'repeat(2, max-content)',
            border: 'none',
          }}
        >
          <Box>
            <Box className="ListPage__heading">{heading}</Box>
            <Box sx={{ fontSize: '16px' }} className="ListPage__contents">
              {contents}
            </Box>
          </Box>

          {rightAddOn}
        </Box>
      </Box>

      <Box layerStyle="sectionContent">{children}</Box>
    </Box>
  )
}
