import { useState, useEffect } from 'react'
import { Box, Grid, Text, useToast } from '@chakra-ui/react'
import {
  AddressForm,
  SelectAddress,
  SelectAddressItem,
  Summary,
  TableOrder,
} from '@datawowio/payment-ui'
import { capitalize } from 'lodash'

import { withCheckoutPage, useCheckoutStore } from '@/features/payments/stores'
import { useCheckoutServices } from '@/features/payments/services/CheckoutServices'
import { logEcommerce } from '@/lib/stats'
import { t } from '@/lib/helpers'

import { ERROR_TOAST_CONFIG } from '@/lib/constants'

function CheckoutPage() {
  const toast = useToast()
  const {
    addressType,
    addresses,
    packageData,
    recurring,
    recurringType,
    selectedAddress,
    setAddressType,
    setRecurringType,
    setSelectedAddress,
  } = useCheckoutStore()
  const CheckoutServices = useCheckoutServices()

  const [errors, setErrors] = useState<Dict>({})
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (errors.selectedAddress) {
      toast({
        ...ERROR_TOAST_CONFIG,
        description: errors.selectedAddress as string,
      })
    }
  }, [errors])

  const selectedRecurring = recurring[recurringType]

  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent" sx={{ padding: '48px 40px' }}>
        <Text sx={{ fontSize: '24px', mb: '45px', fontWeight: 500 }}>
          {t('views.features.packages.checkout.summary')}
        </Text>

        <Grid
          sx={{
            gap: '24px',
            gridTemplateColumns: '1fr 440px',
            alignItems: 'start',
          }}
        >
          <Grid sx={{ gridColumn: '1/2', gap: '24px' }}>
            <Box>
              <TableOrder
                id={packageData.id}
                name={capitalize(packageData.name)}
                recurring={recurringType}
                price={packageData.price}
                net={packageData.net}
                onChangePackage={(data) => {
                  setRecurringType(data.recurring as Period)
                }}
              />
            </Box>

            <Box>
              <SelectAddress
                addressType={addressType}
                onChangeType={setAddressType}
                renderAddressForm={({ onClose }) => {
                  return (
                    <AddressForm
                      addressType={addressType}
                      onSubmit={(values) => {
                        CheckoutServices.createAddress(values, {
                          onSuccess: onClose,
                        })
                      }}
                    />
                  )
                }}
              >
                {addresses.map((addr) => {
                  const {
                    id: addressId,
                    name,
                    address,
                    primary,
                    taxId,
                    taxPayerType,
                  } = addr

                  return (
                    <SelectAddressItem
                      key={addressId}
                      id={addressId}
                      name={name}
                      address={address}
                      taxId={taxId}
                      primary={primary}
                      isSelected={selectedAddress === addressId}
                      onDelete={() => {
                        CheckoutServices.deleteAddress(addressId)
                      }}
                      onEdited={(values) => {
                        CheckoutServices.updateAddress({
                          id: addressId,
                          ...values,
                        })
                      }}
                      onClick={() => {
                        setSelectedAddress(addressId)
                      }}
                      addressType={taxPayerType}
                    />
                  )
                })}
              </SelectAddress>
            </Box>
          </Grid>

          <Box sx={{ gridColumn: '2/3' }}>
            <Summary
              net={selectedRecurring.amount}
              total={Math.max(selectedRecurring.total_amount, 0)}
              vat={selectedRecurring.vat}
              vatPercent={selectedRecurring.vat_percent}
              preVatTotal={selectedRecurring.before_vat}
              name={capitalize(packageData.name)}
              onErrors={(err) => {
                setErrors(err)
              }}
              extraValues={{
                selectedAddress,
              }}
              validate={(values) => {
                const errors: Record<string, string> = {}

                if (!values.selectedAddress) {
                  errors.selectedAddress = t(
                    'views.features.packages.checkout.errors.address_required',
                  )
                }

                return errors
              }}
              displayAcceptTerms={false}
              onSubmit={() => {
                console.log(packageData, recurring)
                setLoading(true)

                CheckoutServices.saveInformation({
                  onSuccess: () =>
                    logEcommerce('add_to_cart', {
                      value: selectedRecurring.total_amount,
                      items: [
                        {
                          item_id: `${packageData.name}_${recurringType}`,
                          item_name: `${packageData.name}_${recurringType}`,
                          price: selectedRecurring.total_amount,
                          quantity: 1,
                        },
                      ],
                    }),
                  onError: () => setLoading(false),
                })
              }}
              isLoading={isLoading}
              loadingText={t('views.features.packages.checkout.loading')}
              showWHTException={addressType === 'company'}
              {...(selectedRecurring.prorated_discount && {
                proRateDiscount: {
                  label: 'ส่วนลดมูลค่าคงเหลือแพ็กเกจ',
                  value: selectedRecurring.prorated_discount,
                },
              })}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default withCheckoutPage(CheckoutPage)
