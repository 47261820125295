import { Center, CircularProgress } from '@chakra-ui/react'

export const TableLoading = () => {
  return (
    <Center
      sx={{
        pos: 'absolute',
        w: '100%',
        h: '100%',
        background: 'rgba(255,255,255, 0.7)',
        cursor: 'not-allowed',
      }}
    >
      <CircularProgress isIndeterminate color="brand.400" />
    </Center>
  )
}
