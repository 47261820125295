import { PropsWithChildren, useState, useCallback } from 'react'

import { createCtx, omitByEmptyKey } from '@/lib/helpers'

import { Meta } from './types'

type SetFilterFunction = <T extends unknown>(key: string, value: T) => void
type SetFiltersFunction = <T extends object>(meta: Meta & T) => void

type FilterContext = {
  meta: Meta
  setFilter: SetFilterFunction
  setFilters: SetFiltersFunction
  resetFilters: () => void
}

export const defaultMeta = {
  page: 1,
  per: 15,
}

const filterContext = createCtx<FilterContext>()
const [, Provider] = filterContext
export const [useFilterContext] = filterContext

type FilterProviderProps = PropsWithChildren<{
  initialMeta?: Meta
}>

export const FilterContextProvider = (props: FilterProviderProps) => {
  const { children, initialMeta = {} } = props

  const initialState = {
    ...defaultMeta,
    ...initialMeta,
  }

  const [meta, setMeta] = useState<Meta>(initialState)

  const setFilter = useCallback<SetFilterFunction>((key, value) => {
    setMeta((curState) => ({
      ...curState,
      [key]: value,
      ...(key !== 'page' && { page: 1 }),
    }))
  }, [])

  const setFilters = useCallback<SetFiltersFunction>((meta) => {
    setMeta((curState) => {
      return omitByEmptyKey({ ...curState, ...meta })
    })
  }, [])

  const resetFilters = useCallback(() => {
    setMeta(initialState)
  }, [])

  const contextValue = {
    meta,
    setFilter,
    setFilters,
    resetFilters,
  }

  return <Provider value={contextValue}>{children}</Provider>
}
