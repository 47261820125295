import { useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { getPathParams } from '@/lib/routes'

import { SCANNED_URL } from '../constants'
import { PollingResponse } from '@/features/types'

type Params = {
  projectId: number
}

export const getScanStatus = async (
  params: Params,
): Promise<PollingResponse> => {
  const { projectId } = params

  const response = await fetchAPI({
    path: `/projects/${projectId}/scrape`,
  })

  return response.data
}

export const useScannedURL = (params: Params) => {
  return useQuery<PollingResponse, AxiosError>({
    queryKey: [SCANNED_URL, params],
    queryFn: () => getScanStatus(params),
  })
}

export const useScannedURLData = () => {
  const queryClient = useQueryClient()

  const query = getPathParams()
  const params = { projectId: Number(query?.project_id) }

  return queryClient.getQueryData<PollingResponse>([SCANNED_URL, params])
}
