import { useMemo, useState, useEffect, useCallback } from 'react'
import { CgClose } from 'react-icons/cg'
import { Button } from '@chakra-ui/button'
import { Box, Flex, HStack, Link, Stack, Text } from '@chakra-ui/layout'
import { Checkbox } from '@chakra-ui/checkbox'
import { IconButton, Image } from '@chakra-ui/react'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input'
import { useWatch, useFormContext } from 'react-hook-form'
import { useDisclosure } from '@chakra-ui/hooks'
import * as d3 from 'd3-format'
import dayjs from 'dayjs'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Trans, useTranslation } from 'react-i18next'

import { FreeTrialFormInput, CouponRule } from '../../types'
import {
  useCheckActiveCoupons,
  useCheckFreeTrialCoupon,
  FreeTrialCoupon,
} from '../../mutations'

import { PRIVACY_LINK, TERMS_LINK } from '@/constants/links'
import { HELPDESK_LINK } from '@/features/domains/constants'
import { updatedPriceConfig } from '../../../payments/config'
import { Tag } from './Tag'
import { CouponTimer } from './CouponTimer'
import CloseOrange from '@/images/icons/close-orange.svg'
import { linkStyle } from '@/features/auth/constants/styles'

type OrderSummaryProps = {
  isLoading: boolean
}

const COUPON_DURATION = 24 * 60 * 60 * 1000

export const OrderSummary = (props: OrderSummaryProps): React.ReactElement => {
  const { isLoading } = props

  const [coupon, setCoupon] = useState<string>('')
  const [couponRule, setCouponRule] = useState<CouponRule>()
  const [applyCoupon, setApplyCoupon] = useState<FreeTrialCoupon | undefined>()
  const [activeCoupon, setActiveCoupon] = useState<FreeTrialCoupon>()

  const [startTime, setStartTime] = useState(() => {
    const data = JSON.parse(localStorage.getItem('couponData') || '{}')

    return data.start_time ? Number(data.start_time) : Date.now()
  })

  const [timeLeft, setTimeLeft] = useState(
    COUPON_DURATION - (Date.now() - startTime),
  )

  const { mutateAsync: checkFreeTrialCoupon, isLoading: checkCouponLoading } =
    useCheckFreeTrialCoupon()

  const { mutateAsync: checkActiveFreeTrialCoupons } = useCheckActiveCoupons()

  const { setValue } = useFormContext<FreeTrialFormInput>()

  const recurring = useWatch<Pick<FreeTrialFormInput, 'recurring'>>({
    name: 'recurring',
  })
  const packageName = useWatch<Pick<FreeTrialFormInput, 'package'>>({
    name: 'package',
  })
  const taxPayerType = useWatch<Pick<FreeTrialFormInput, 'taxpayer_type'>>({
    name: 'taxpayer_type',
  })
  const termsDisclosure = useDisclosure()

  const handleUseCoupon = () => {
    if (activeCoupon && activeCoupon.coupon) {
      setCoupon(activeCoupon.coupon)
      setActiveCoupon(undefined)
    }
  }

  const priceObj = useMemo(() => {
    const initialPrice = updatedPriceConfig[packageName].price[recurring]
    let discount = 0
    let finalPrice = initialPrice

    switch (applyCoupon?.discount_type) {
      case 'fixed':
        discount = applyCoupon.discount_amount
        finalPrice = initialPrice - discount
        break
      case 'percentage':
        discount = (applyCoupon.discount_amount * initialPrice) / 100
        finalPrice = initialPrice - discount
        break
    }

    const VAT = 1.07
    const FORMAT = ',.2f'

    const preVat = finalPrice / VAT
    const vat = finalPrice - preVat

    return {
      initial: d3.format(FORMAT)(initialPrice),
      preVat: d3.format(FORMAT)(preVat),
      vat: d3.format(FORMAT)(vat),
      discount: d3.format(FORMAT)(discount),
      finalPrice: d3.format(FORMAT)(finalPrice),
    }
  }, [recurring, packageName, JSON.stringify(applyCoupon)])

  const activeCouponObj = useMemo(() => {
    let discountDisplay = ''

    switch (activeCoupon?.discount_type) {
      case 'fixed':
        discountDisplay = `฿${activeCoupon.discount_amount}`
        break
      case 'percentage':
        discountDisplay = `${activeCoupon.discount_amount}%`
        break
    }

    return {
      discountDisplay,
    }
  }, [activeCoupon])

  const { t } = useTranslation(['freeTrial'])

  const dueDate = useMemo(() => {
    const target = dayjs().add(packageName === 'large' ? 7 : 14, 'day')

    return {
      date: target.get('date'),
      month: target.get('month') + 1,
      year: target.get('year'),
      duration: packageName === 'large' ? 7 : 14,
    }
  }, [packageName])

  const removeCoupon = useCallback(() => {
    setValue('code', '')
    setApplyCoupon(undefined)
    setCouponRule(undefined)
  }, [])

  ///Check Coupon Rule///
  useEffect(() => {
    if (!couponRule) return

    const checkRecurring = () => {
      const isCorrect =
        couponRule.recurring === 'any' || couponRule.recurring === recurring

      if (isCorrect) return

      removeCoupon()
    }

    switch (couponRule.name) {
      case 'any':
        checkRecurring()
        break

      default:
        if (couponRule.name !== packageName) {
          removeCoupon()
          return
        }

        checkRecurring()

        break
    }
  }, [packageName, recurring])

  useEffect(() => {
    async function fetchActiveCoupon() {
      const res = await checkActiveFreeTrialCoupons({
        packageName,
        recurring,
      })
      setActiveCoupon(res[0])
    }

    if (!coupon && !applyCoupon) {
      fetchActiveCoupon()
    }
  }, [recurring, packageName, coupon, applyCoupon])

  useEffect(() => {
    const couponData = JSON.parse(localStorage.getItem('couponData') || '{}')
    const couponCreated = couponData.coupon_created

    if (
      activeCoupon &&
      (!couponData.start_time ||
        (couponCreated !== activeCoupon?.created_at && timeLeft < 0))
    ) {
      const newCouponData = {
        start_time: Date.now(),
        coupon_created: activeCoupon?.created_at,
      }
      localStorage.setItem('couponData', JSON.stringify(newCouponData))
      setStartTime(Date.now())
    }

    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime
      const remainingTime = COUPON_DURATION - elapsedTime
      setTimeLeft(remainingTime)
    }, 1000)

    return () => clearInterval(interval)
  }, [startTime, activeCoupon])

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: 'lg',
        px: 6,
        '> *': {
          py: 4,
        },
      }}
    >
      <Flex
        direction="column"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'inherit',
          pt: 6,
        }}
        gap={4}
      >
        <Text sx={{ fontSize: '20px', fontWeight: 700 }}>
          {t('orderSummary.title')}
        </Text>
        <HStack spacing={4} mt={4}>
          <InputGroup>
            <Input
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              placeholder={t('coupon.placeholder')}
              sx={{ fontSize: '14px' }}
            />
            <InputRightElement>
              {coupon && (
                <IconButton
                  aria-label="Delete coupon"
                  variant="ghost"
                  size="sm"
                  onClick={() => setCoupon('')}
                  sx={{
                    borderRadius: 'full',
                    color: 'gray.800',
                    _hover: { bgColor: 'gray.100' },
                    _active: { bgColor: 'gray.100' },
                  }}
                  icon={<CgClose />}
                />
              )}
            </InputRightElement>
          </InputGroup>

          <Button
            isLoading={checkCouponLoading}
            disabled={!coupon}
            sx={{ width: '150px', fontSize: '14px' }}
            onClick={async () => {
              await checkFreeTrialCoupon(
                { coupon, packageName, recurring },
                {
                  onSuccess(data) {
                    setValue('code', data.coupon)
                    setApplyCoupon(data)
                    setCouponRule(data.to_package)
                  },
                },
              )
            }}
          >
            {t('coupon.buttonTitle')}
          </Button>
        </HStack>

        {activeCoupon && timeLeft >= 0 && (
          <CouponTimer
            timeLeft={timeLeft}
            activeCoupon={activeCoupon}
            activeCouponObj={activeCouponObj}
            onUseCoupon={handleUseCoupon}
          />
        )}

        {coupon && (
          <Flex alignItems="center" sx={{ color: 'brand.400' }}>
            <AiOutlineExclamationCircle size="20px" />
            <Text
              sx={{
                ml: 2,
                fontSize: '14px',
                color: 'brand.400',
              }}
            >
              {t('coupon.discountCodeAutoApply')}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex
        justify="space-between"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'inherit',
          fontSize: '12px',
          color: 'gray.600',
        }}
      >
        <Text>{t('orderSummary.order')}</Text>
        <Text>{t('orderSummary.sumTitle')}</Text>
      </Flex>
      <Flex sx={rowStyle}>
        <Text>{packageName.toTitleCase()}</Text>
        <Text>{priceObj.initial}</Text>
      </Flex>

      <Stack spacing={4} sx={rowStyle}>
        <Flex justify="space-between">
          <Text>{t('orderSummary.preVat')}</Text>
          <Text>{priceObj.preVat}</Text>
        </Flex>
        <Flex justify="space-between">
          <Text>VAT 7%</Text>
          <Text>{priceObj.vat}</Text>
        </Flex>
      </Stack>

      {applyCoupon && (
        <Stack sx={rowStyle} spacing={4}>
          <Flex justify="space-between">
            <Text>{t('coupon.useDiscountCode')}</Text>
            <Text sx={{ fontWeight: 700 }}>{applyCoupon.coupon}</Text>
          </Flex>
          <Flex justify="space-between">
            <Text>{t('orderSummary.totalDiscount')}</Text>
            <Flex alignItems="center">
              <Text
                sx={{ color: 'brand.400', fontWeight: 500, cursor: 'pointer' }}
                onClick={removeCoupon}
              >
                -{priceObj.discount}
              </Text>
              <Text
                ml={2}
                sx={{ color: 'brand.400', cursor: 'pointer' }}
                onClick={removeCoupon}
              >
                <Image src={CloseOrange} sx={{ boxSize: '20px' }} />
              </Text>
            </Flex>
          </Flex>
        </Stack>
      )}

      {taxPayerType === 'company' && (
        <Stack spacing={1} sx={rowStyle}>
          <Flex justify="space-between">
            <Text>{t('orderSummary.wht')}</Text>
            <Text>-</Text>
          </Flex>
          <Text sx={{ color: 'brand.400', fontSize: 'xs' }}>
            <Trans t={t} i18nKey={'orderSummary.whtDescription'}>
              เนื่องจาก Cookie Wow
              ได้รับการส่งเสริมการลงทุนจากสำนักงานคณะกรรมการส่งเสริมการลงทุน
              (BOI) จึงได้รับการ ยกเว้นการถูกหักภาษี ณ ที่จ่าย
              ฉะนั้นผู้ใช้บริการจึงไม่ต้องทำการหักภาษี ณ ที่จ่ายแต่อย่างใด
            </Trans>
          </Text>
        </Stack>
      )}

      <Stack spacing={2} sx={{ pb: 0 }}>
        <Flex
          sx={{
            border: 'none',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <Text>{t('orderSummary.total')}</Text>
          <Text>{priceObj.finalPrice}</Text>
        </Flex>
        <Flex justify="space-between">
          <HStack spacing={4}>
            <Text sx={{ fontSize: '24px', fontWeight: 700 }}>
              {t('orderSummary.payNow')}
            </Text>

            <Tag>
              <Trans t={t} i18nKey="orderSummary.freeTrialDuration">
                <Text>ทดลองใช้งานฟรี {{ day: dueDate.duration }} วัน</Text>
              </Trans>
            </Tag>
          </HStack>
          <Text sx={{ fontSize: '24px', fontWeight: 700 }}>0.00</Text>
        </Flex>
        <Flex
          justify="center"
          sx={{
            mt: '16px !important',
            spacing: '16px',
            p: 2,
            border: '1px solid',
            borderColor: 'brand.400',
            borderRadius: 'lg',
            bgColor: 'brand.25',
          }}
        >
          <Text sx={{ textAlign: 'center', fontSize: '12px' }}>
            <Trans t={t} i18nKey="orderSummary.collectAfter">
              คุณจะสามารถใช้งานบริการบริการของเราฟรีจนถึงวันที่
              <Text as="span" sx={{ fontWeight: 700, color: 'brand.400' }}>
                {{
                  day: dueDate.date,
                }}
                {{ month: dueDate.month }}
                {{ year: dueDate.year }}
              </Text>
              <br />
              และหลังจากนั้นจะถูกหักเงินตามข้อมูลข้างต้น
              โดยคุณสามารถยกเลิกได้ตลอดเวลา อ่านข้อมูลเพิ่มเติม
              <Link
                href={HELPDESK_LINK.CANCEL_FREETRIAL}
                isExternal
                sx={{ ...linkStyle, fontWeight: 'bold', fontSize: '12px' }}
              >
                ที่นี่
              </Link>
            </Trans>
          </Text>
        </Flex>
      </Stack>

      <Stack>
        <Checkbox
          size="lg"
          isChecked={termsDisclosure.isOpen}
          onChange={termsDisclosure.onToggle}
        >
          <Text
            sx={{
              fontSize: '12px',
              color: 'gray.600',
              a: {
                color: 'gray.900',
              },
            }}
          >
            <Trans t={t} i18nKey="orderSummary.acceptTerms">
              ฉันยอมรับ&nbsp;
              <Link isExternal href={TERMS_LINK}>
                ข้อตกลงในการให้บริการ
              </Link>
              &nbsp;และ&nbsp;
              <Link isExternal href={PRIVACY_LINK}>
                นโยบายการรักษาข้อมูลส่วนบุคคล
              </Link>
            </Trans>
          </Text>
        </Checkbox>

        <Button
          type="submit"
          size="xl"
          isLoading={isLoading}
          isDisabled={!termsDisclosure.isOpen}
        >
          <Trans t={t} i18nKey="form.submit">
            เริ่มทดลองใช้งานฟรี {{ day: dueDate.duration }} วัน
          </Trans>
        </Button>
      </Stack>
    </Box>
  )
}

const rowStyle = {
  pb: 4,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  justifyContent: 'space-between',
}
