import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Accordion, Stack } from '@chakra-ui/react'
import { useFormState } from 'react-hook-form'

import { AccordionWrapper } from '../../shared'
import { DialogFormInput } from '@/features/domains/types'
import { t } from '@/lib/helpers'

import {
  Appearance,
  BannerButtons,
  Branding,
  CssCustomization,
  LanguageAndContent,
  Policy,
  Position,
  Revokable,
  BorderRadius,
} from '..'

type CustomizeFormProps = {
  isNoticeOnly: boolean
  setShowWidget: Dispatch<SetStateAction<boolean>>
}

export const CustomizeForm = ({
  isNoticeOnly,
  setShowWidget,
}: CustomizeFormProps) => {
  const [index, setIndex] = useState<number | number[] | undefined>(undefined)

  const { errors } = useFormState<DialogFormInput>()

  useEffect(() => {
    if (
      [
        errors.accept_all_label,
        errors.ok_button_label,
        errors.reject_all_label,
        errors.view_details_label,
        errors.preference_label,
      ].some(Boolean)
    ) {
      setIndex(4)
    }
  }, [errors])

  return (
    <Accordion
      allowToggle
      sx={{
        '.customize-title': {
          fontWeight: 700,
        },
      }}
      index={index}
      onChange={(activeIdx) => {
        setIndex(activeIdx)
        setShowWidget(activeIdx === 7)
      }}
    >
      <Stack spacing="16px">
        <AccordionWrapper
          buttonID="CustomizeBranding-toggle"
          title={`1. ${t(
            'views.features.domains.show.customize.branding',
            undefined,
            'Branding',
          )}`}
        >
          <Branding />
        </AccordionWrapper>

        <AccordionWrapper
          buttonID="CustomizeAppearance-toggle"
          title={`2. ${t(
            'views.features.domains.show.customize.appearance',
            undefined,
            'Appearance',
          )}`}
        >
          <Appearance />
        </AccordionWrapper>

        <AccordionWrapper
          buttonID="CustomizePosition-toggle"
          title={`3. ${t(
            'views.features.domains.show.customize.position',
            undefined,
            'Position',
          )}`}
        >
          <Position />
        </AccordionWrapper>

        <AccordionWrapper
          buttonID="CustomizeContent-toggle"
          title={`4. ${t(
            'views.features.domains.show.customize.banner_content',
            undefined,
            'Language nad Content',
          )}`}
        >
          <LanguageAndContent isNoticeOnly={isNoticeOnly} />
        </AccordionWrapper>

        <AccordionWrapper
          buttonID="CustomizeButtons-toggle"
          title={`5. ${t(
            'views.features.domains.show.customize.button',
            undefined,
            'Button',
          )}`}
        >
          <BannerButtons isNoticeOnly={isNoticeOnly} />
        </AccordionWrapper>

        <AccordionWrapper
          buttonID="CustomizeRadius-toggle"
          title={`6. ${t(
            'views.features.domains.show.customize.corner_radius',
          )}`}
        >
          <BorderRadius />
        </AccordionWrapper>

        <AccordionWrapper
          buttonID="CustomizePolicy-toggle"
          title={`7. ${t(
            'views.features.domains.show.customize.policy',
            undefined,
            'Policy',
          )}`}
        >
          <Policy />
        </AccordionWrapper>

        <AccordionWrapper
          buttonID="CustomizeReset-toggle"
          title={`8. ${t(
            'views.features.domains.show.customize.resetting_cookie',
            undefined,
            'Resetting Cookie',
          )}`}
          isDisabled={isNoticeOnly}
        >
          <Revokable />
        </AccordionWrapper>

        <AccordionWrapper
          buttonID="CustomizeCss-toggle"
          title={`9. ${t(
            'views.features.domains.show.customize.css_customize',
            undefined,
            'CSS Customization',
          )}`}
        >
          <CssCustomization />
        </AccordionWrapper>
      </Stack>
    </Accordion>
  )
}
