import { useMemo } from 'react'
import { Grid, Box, Text } from '@chakra-ui/react'
import { get } from 'lodash'
import * as d3 from 'd3-format'
import { Column } from 'react-table'
import { useTranslation } from 'react-i18next'

import { DataTable } from '@/components/DataDisplay/Table'
import { formatDateTime, t } from '@/lib/helpers'
import { Transaction } from '../../types'

type Props = {
  data: Transaction[]
}

export const ReceiptTable = (props: Props) => {
  const { data } = props

  const { t: tPayment } = useTranslation('payment')

  const columns = useMemo<Array<Column<Transaction>>>(() => {
    return [
      {
        Header: t('views.features.packages.receipts.transaction_date'),
        accessor: 'created_at',
        sortKey: 'created_at',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return <Text sx={{ color: 'gray.900' }}>{formatDateTime(value)}</Text>
        },
      },
      {
        Header: t('views.features.packages.receipts.payment_method'),
        accessor: 'package_id',
        disableSortBy: true,
        Cell: () => {
          // TODO Remove hard coded
          return tPayment('creditCard')
        },
      },
      // {
      //   Header: t('views.features.packages.receipts.description'),
      //   accessor: 'description',
      //   disableSortBy: true,
      // },
      {
        Header: t('views.features.packages.receipts.status'),
        accessor: 'paid',
        disableSortBy: true,
        Cell: (cellProps) => {
          const paid = get(cellProps, 'value')

          // TODO Remove hard coded
          return <Status status={paid ? 'success' : 'failed'} />
        },
      },
      {
        Header: t('views.features.packages.receipts.amount'),
        accessor: 'amount',
        disableSortBy: true,
        isNumeric: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return (
            <Text sx={{ textAlign: 'end' }}>
              {d3.format(',d')(value)} {t('views.misc.thb')}
            </Text>
          )
        },
      },

      // TODO EEK NAAN said by ice
      // {
      //   Header: t('views.features.packages.receipts.receipt'),
      //   accessor: 'receipt',
      //   disableSortBy: true,
      //   Cell: (cellProps) => {
      //     const value = get(cellProps, 'value')

      //     // TODO Add Link
      //     return (
      //       <Text
      //         sx={{
      //           fontWeight: 600,
      //           color: 'brand.400',
      //           textDecoration: 'underline',
      //           cursor: 'pointer',
      //         }}
      //       >
      //         {t('views.features.packages.receipts.receipt')}
      //       </Text>
      //     )
      //   },
      // },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return <DataTable<Transaction> data={memoizedData} columns={columns} />
}

const Status = (props: { status: string }) => {
  const { status } = props

  const getColor = () => {
    if (status === 'success') return '#6ac761'
    if (status === 'pending') return '#ffab2e'
    return '#f97272'
  }

  return (
    <Grid
      sx={{ gridTemplateColumns: '8px 1fr', gap: '8px', alignItems: 'center' }}
    >
      <Box
        sx={{
          w: '8px',
          h: '8px',
          borderRadius: '50%',
          backgroundColor: getColor(),
        }}
      />
      <Text sx={{ textTransform: 'capitalize' }}>
        {t(`views.shared.status.${status}`)}
      </Text>
    </Grid>
  )
}
