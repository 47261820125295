import { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import {
  Box,
  Text,
  Grid,
  Flex,
  Switch,
  CSSObject,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import discountTag from '@/images/project/payments/discount-tag.svg'

import { t } from '@/lib/helpers'
import { Period } from '@/features/types'

type Props = {
  period: Period
  onChange: Dispatch<SetStateAction<Period>>
}

export const PriceSwitch = (props: Props) => {
  const { period, onChange } = props

  const { t: tPackages } = useTranslation('packages')

  return (
    <VStack spacing="5">
      <Grid
        sx={{
          gridTemplateColumns: '100px max-content 100px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '40px',
          fontSize: '20px',
          fontWeight: 500,
        }}
      >
        <Text sx={{ justifySelf: 'end' }}>{t('views.misc.monthly')}</Text>
        <Switch
          id="Package-togglePlan"
          size="xl"
          name="price-switch"
          isChecked={period === 'year'}
          onChange={({ target: { checked } }) => {
            if (checked) {
              onChange('year')
            } else {
              onChange('month')
            }
          }}
        />
        <Box sx={{ position: 'relative' }}>
          {t('views.misc.yearly')}
          <DiscountTag sx={{ right: '-120px', top: '-10px' }}>
            <Text sx={{ fontSize: '16px', pl: '4px' }}>
              {t('views.features.packages.packages.pricing.save_20')}
            </Text>
          </DiscountTag>
        </Box>
      </Grid>

      <Text sx={{ fontWeight: 'medium', color: 'brand.400' }}>
        {tPackages(`${period}lyBilling`)}
      </Text>
    </VStack>
  )
}

const DiscountTag = (props: PropsWithChildren<{ sx: CSSObject }>) => {
  const { children, sx } = props

  return (
    <Flex
      sx={{
        position: 'absolute',
        background: `no-repeat center/contain url(${discountTag})`,
        w: '142px',
        h: '53px',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {children}
    </Flex>
  )
}
