import { Fragment } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Stack,
  FormHelperText,
  Text,
  Collapse,
  useDisclosure,
  HStack,
  Icon,
  Box,
} from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import { SubpathNumberInput } from '../ScanCookieModal/SubpathNumberInput'
import { CreatableInput } from '@/components/Input'
import { DomainDisplayDescription } from '@/features/domains/components/DomainDisplayDescription'

import { t } from '@/lib/helpers'
import { Trans } from 'react-i18next'

import { ScanCookieFormInput } from '../../types'
import { FormControl } from '@/components/FormControl'
import { PATTERN } from '@/features/domains/constants'

const schema = z.object({
  includes: z.string().url('invalidUrlFull').array(),
  excludes: z.string().url('invalidUrlFull').array(),
  limit: z.number().min(1, 'required'),
})

export type ScanCookieFormProps = {
  isLoading: boolean
  onCancel: () => void
  onSubmitForm: (data: ScanCookieFormInput) => void
  quota: number
  defaultValues: {
    includes: string[]
    excludes: string[]
  }
}

export const ScanCookieForm = (props: ScanCookieFormProps) => {
  const { onSubmitForm, onCancel, isLoading, quota, defaultValues } = props

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    setValue,
  } = useForm<ScanCookieFormInput>({
    defaultValues: {
      limit: 10,
      ...defaultValues,
    },
    resolver: zodResolver(schema),
  })

  const { t: tErrors } = useTranslation('errors')
  const { isOpen, onToggle } = useDisclosure()

  const onSubmit = (data: ScanCookieFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  const isDisabled = quota === 0

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="6">
        <Stack spacing="4">
          <SubpathNumberInput
            max={quota}
            onChange={(val) => setValue('limit', val)}
            isDisabled={isDisabled}
          />

          <Box>
            <Button variant="link" onClick={onToggle} sx={{ d: 'block' }}>
              <HStack spacing="1">
                <Text>
                  {t(
                    'views.features.domains.show.scanner.advanced_setting',
                    undefined,
                    'Advanced Setting',
                  )}
                </Text>
                <Icon
                  as={FiChevronRight}
                  boxSize="16px"
                  sx={{
                    transform: isOpen ? 'rotate(90deg)' : 'none',
                    transition: 'transform 0.2s ease',
                  }}
                />
              </HStack>
            </Button>
          </Box>

          <Collapse in={isOpen}>
            <Stack spacing="4">
              <Controller
                control={control}
                name="excludes"
                defaultValue={[]}
                render={({ field: { onChange, name, value } }) => {
                  const error =
                    (get(errors, 'excludes') ?? []).find((err) => err?.message)
                      ?.message ?? ''
                  const combinedError = error
                    ? `${tErrors(error)} ${tErrors('wildcardUrlFull')}`
                    : ''

                  return (
                    <FormControl
                      id={name}
                      errorMsg={combinedError}
                      label={
                        <Fragment>
                          {t(
                            'views.features.domains.show.scanner.exclude_label',
                            undefined,
                            'Exclude URL',
                          )}
                          &nbsp;
                          <Text
                            as="span"
                            sx={{
                              fontSize: '14px',
                              color: 'gray.600',
                              fontWeight: 400,
                            }}
                          >
                            (
                            {t(
                              'views.features.domains.show.scanner.optional',
                              undefined,
                              'Optional',
                            )}
                            )
                          </Text>
                        </Fragment>
                      }
                    >
                      <Stack spacing="2">
                        <DomainDisplayDescription
                          description={
                            <Trans i18nKey="features.domains.customize.scanner_display_desc">
                              Banner Display supports entering URLs in
                              <Text as="span" color="brand.400">
                                Specific URLs
                              </Text>
                              or
                              <Text as="span" color="brand.400">
                                Wildcard URLs
                              </Text>
                              format.
                            </Trans>
                          }
                          detail={
                            <Trans i18nKey="features.domains.customize.scanner_display_detail">
                              If you want to scan
                              <Text
                                as="span"
                                color="brand.600"
                                fontWeight={600}
                              >
                                Wildcard URLs
                              </Text>
                              , you can use the asterisk (
                              <Text as="span" color="brand.600">
                                *
                              </Text>
                              ) symbol as a placeholder to represent the part of
                              the URL that can have different values. For
                              example, using the wildcard URL pattern
                              <Text as="span" color="brand.600">
                                &quot;https://www.cookiewow.com/*&quot;
                              </Text>
                              , the system will scan all URLs that start with
                              <Text as="span" color="brand.600">
                                &quot;https://www.cookiewow.com/&quot;
                              </Text>
                              .
                            </Trans>
                          }
                        />
                        <CreatableInput
                          id={`${name}--container`}
                          name={name}
                          onChange={onChange}
                          placeholder={t(
                            'views.features.domains.show.scanner.scan_input_placeholder',
                            undefined,
                            'type URL here',
                          )}
                          placeholderId={`${name}--id`}
                          values={value}
                          pattern={PATTERN}
                        />

                        <FormHelperText sx={{ fontSize: '16px' }}>
                          {t(
                            'views.features.domains.show.scanner.exclude_scan_form_helper',
                            undefined,
                            `Enter URLs that you want to exclude in the scanning. Use comma ( , ) to separate multiple URLs.`,
                          )}
                        </FormHelperText>
                      </Stack>
                    </FormControl>
                  )
                }}
              />
              <Controller
                control={control}
                name="includes"
                defaultValue={[]}
                render={({ field: { onChange, name, value } }) => {
                  const error =
                    (get(errors, 'includes') ?? []).find((err) => err?.message)
                      ?.message ?? ''

                  return (
                    <FormControl
                      id={name}
                      errorMsg={tErrors(error)}
                      label={
                        <Fragment>
                          {t(
                            'views.features.domains.show.scanner.include_label',
                            undefined,
                            'Include URL',
                          )}
                          &nbsp;
                          <Text
                            as="span"
                            sx={{
                              fontSize: '14px',
                              color: 'gray.600',
                              fontWeight: 400,
                            }}
                          >
                            (
                            {t(
                              'views.features.domains.show.scanner.optional',
                              undefined,
                              'Optional',
                            )}
                            )
                          </Text>
                        </Fragment>
                      }
                    >
                      <CreatableInput
                        id={`${name}--container`}
                        name={name}
                        onChange={onChange}
                        placeholder={t(
                          'views.features.domains.show.scanner.scan_input_placeholder',
                          undefined,
                          'type URL here',
                        )}
                        placeholderId={`${name}--id`}
                        values={value}
                        pattern={/https?:\/\//}
                      />

                      <FormHelperText sx={{ fontSize: '16px' }}>
                        {t(
                          'views.features.domains.show.scanner.include_scan_form_helper',
                          undefined,
                          `Enter URLs that you want to include in the scanning. Use comma ( , ) to separate multiple URLs.`,
                        )}
                      </FormHelperText>
                    </FormControl>
                  )
                }}
              />
            </Stack>
          </Collapse>
        </Stack>

        <Stack alignItems="stretch">
          <Button
            size="xl"
            type="submit"
            isLoading={isLoading}
            isDisabled={isDisabled}
          >
            {t('views.features.domains.show.scanner.scan', undefined, 'Scan')}
          </Button>

          <Button
            variant="ghost"
            colorScheme="gray"
            color="gray.600"
            size="xl"
            onClick={onCancel}
          >
            {t('views.shared.button.discard', undefined, 'Discard')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
