import { useEffect } from 'react'

import { logEvent } from '@/lib/stats'

const FailedSignUpEvent = () => {
  useEffect(() => {
    setTimeout(() => {
      logEvent({
        ga: {
          category: 'Auth-Failed',
          action: 'FailedRegistration',
        },
      })
    }, 2000)
  }, [])

  return null
}

export default FailedSignUpEvent
