import { useEffect } from 'react'
import { isEmpty, get, noop } from 'lodash'
import {
  CellProps,
  HeaderProps,
  Hooks,
  SortingRule,
  TableInstance,
  useRowSelect,
} from 'react-table'
import { Box, Checkbox } from '@chakra-ui/react'

import { DataTableProps, SelectionHook } from './types'

type UseSortProps<T extends {}> = {
  tableInstance: TableInstance<T>
} & DataTableProps<T>

export function useSort<T>(props: UseSortProps<T>) {
  const { columns, onSort = noop, tableInstance } = props

  const {
    state: { sortBy },
  } = tableInstance

  const internalSort = (sortBy: SortingRule<T>[]) => {
    if (isEmpty(sortBy)) {
      return onSort({
        sortKey: '',
        sortDirection: '',
      })
    }

    const { id, desc } = sortBy[0]
    const column = columns.find((c) => c.accessor === id)

    const sortKey = get(column, 'sortKey')

    if (!sortKey) {
      throw new Error('Sortable column must have sortKey attribute')
    }

    onSort({
      sortKey,
      sortDirection: desc ? 'desc' : 'asc',
    })
  }

  useEffect(() => {
    internalSort(sortBy)
  }, [sortBy])
}

export const getRowSelectHooks = () => {
  const selectionHook: SelectionHook = (hooks: Hooks<any>) => {
    hooks.allColumns.push((columns) => [
      {
        id: '_selector',
        width: '5%',
        Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => {
          const { indeterminate: _, ...props } = getToggleAllRowsSelectedProps()

          return (
            <Box px="8px">
              <Checkbox size="lg" {...props} isChecked={props.checked} />
            </Box>
          )
        },
        Cell: ({ row }: CellProps<any>) => {
          const { indeterminate: _, ...props } = row.getToggleRowSelectedProps()

          return <Checkbox size="lg" {...props} isChecked={props.checked} />
        },
      },
      ...columns,
    ])
  }

  return [useRowSelect, selectionHook]
}
