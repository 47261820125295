import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { Stack, Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'
import { ModalBody, ModalHeader } from '@chakra-ui/modal'
import { Button } from '@chakra-ui/button'

import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useDeleteCookie, useUpdateCookie } from '../../mutations'

import { Modal } from '@/components/shared/Modal'
import GarbageIcon from '@/icons/garbage.svg'

import { Cookie } from '../../types'

type Props = {
  activeLanguage: BannerLanguage
  categoryName: string
  cookie: Cookie
  disclosure: UseDisclosureReturn
}

export const DeleteCookieModal = (props: Props) => {
  const { disclosure, cookie, categoryName, activeLanguage } = props
  const { isOpen, onClose } = disclosure

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id), cookie_id: cookie.id }

  const { mutate: deleteCookie, isLoading: deleteLoading } = useDeleteCookie()
  const { mutate: updateCookie, isLoading: updateLoading } = useUpdateCookie()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="560px" withCloseButton>
      <Stack justify="center" sx={{ pt: '48px' }}>
        <Image src={GarbageIcon} h="72px" />
      </Stack>

      <ModalHeader
        sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 400 }}
      >
        {t('views.features.domains.show.manage.delete')}&nbsp;
        <Text as="span" fontWeight={600}>
          {cookie.name}
        </Text>
        &nbsp;?
      </ModalHeader>

      <ModalBody>
        <Stack spacing="16px">
          <Text>{t('views.features.domains.show.manage.no_block_cookie')}</Text>

          <Stack spacing="24px" sx={{ bg: 'gray.300', p: '16px' }}>
            <Stack spacing="0.2em">
              <Text fontWeight={600}>
                {t('views.features.domains.show.manage.form.name')}
                :&nbsp;
                <Text as="span" fontWeight={400}>
                  {cookie.name}
                </Text>
              </Text>
              <Text fontWeight={600}>
                {t('views.features.domains.show.manage.form.duration')}:&nbsp;
                <Text as="span" fontWeight={400}>
                  {cookie.duration?.[activeLanguage]}
                </Text>
              </Text>
              <Text fontWeight={600}>
                {t('views.features.domains.show.manage.form.host')}:&nbsp;
                <Text as="span" fontWeight={400}>
                  {cookie.url}
                </Text>
              </Text>
              <Text fontWeight={600}>
                {t('views.features.domains.show.manage.form.category')}:&nbsp;
                <Text as="span" fontWeight={400}>
                  {categoryName}
                </Text>
              </Text>
            </Stack>

            <Text fontWeight={600}>
              {t('views.features.domains.show.manage.form.description')}: <br />
              <Text as="span" fontWeight={400}>
                {cookie.purpose[activeLanguage]}
              </Text>
            </Text>
          </Stack>

          <Stack>
            <Button
              id="Cookie-deleted"
              size="xl"
              onClick={() => {
                if (cookie.scraped) {
                  updateCookie(
                    {
                      data: {
                        category_id: null,
                      },
                      params: {
                        cookie_id: cookie.id,
                        project_id: cookie.project_id,
                      },
                    },
                    {
                      onSuccess: onClose,
                    },
                  )
                } else {
                  deleteCookie(params, {
                    onSuccess: onClose,
                  })
                }
              }}
              isLoading={deleteLoading || updateLoading}
            >
              {t('views.shared.button.delete')}
            </Button>
            <Button
              variant="ghost"
              colorScheme="gray"
              color="gray.600"
              onClick={onClose}
              size="xl"
            >
              {t('views.shared.button.discard')}
            </Button>
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
