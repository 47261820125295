export const HELPDESK_LINK = {
  CONSENT_TRANSACTION_RECORD:
    'https://help.cookiewow.com/th/article/consent-transaction-record-s7wrie/?bust=1633515350970',
  COOKIE_SCANNER:
    'https://help.cookiewow.com/th/article/cookie-scanner-1g9xdrc/?bust=1633515389154',
  MANAGE_COOKIE:
    'https://help.cookiewow.com/th/article/manage-cookies-ys1q71/?bust=1633515435124',
  COOKIE_PACKAGES:
    'https://help.cookiewow.com/th/category/4lmb4lie4lmh4lib4lma4lib4lii4lmb4lil4liw4lia4lij4li04lib4liy4lij-t0ybjg',
  CANCEL_FREETRIAL: 'https://help.cookiewow.com/th/article/free-trial-1ro18z8',
}
