import {
  Button,
  Collapse,
  Flex,
  Stack,
  Text,
  useBoolean,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { t } from '@/lib/helpers'

import { ChangePasswordForm } from '../components'

import { useUpdatePassword } from '../mutations'

export const ChangePasswordContainer = () => {
  const [open, setOpen] = useBoolean()
  const { mutate: updatePassword, isLoading } = useUpdatePassword()

  const { t: tCommon } = useTranslation('common')

  return (
    <Stack spacing="4">
      <Flex justify="space-between">
        <Text
          sx={{
            fontWeight: 700,
          }}
        >
          {t('views.features.users.settings.change_password.title')}
        </Text>

        <Button
          onClick={setOpen.toggle}
          variant="link"
          sx={{ textDecor: 'underline' }}
        >
          {open ? tCommon('close') : tCommon('change')}
        </Button>
      </Flex>

      <Collapse in={open}>
        <ChangePasswordForm
          onSubmitForm={(data) => {
            updatePassword(data)
          }}
          isLoading={isLoading}
        />
      </Collapse>
    </Stack>
  )
}
