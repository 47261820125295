import { useToast } from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { MarketingConsentFormInput } from '../types'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'
import { updateConsent } from '@/lib/utils/consent'

const updateMarketingConsent = async (data: MarketingConsentFormInput) => {
  const res: AxiosResponse = await fetchAPI({
    method: 'put',
    path: '/settings',
    data,
  })

  if (res.status === 200) {
    await updateConsent({
      email: data.email,
      consentStatus: data.marketing_consent,
      apiKey: data.consent_api_key,
      purposeId: data.consent_purpose_id,
      baseUrl: data.consent_base_url,
    })
  }
}

export const useUpdateMarketingConsent = () => {
  const toast = useToast()
  const handleError = useErrorHandler()

  return useMutation<void, AxiosError, MarketingConsentFormInput>(
    updateMarketingConsent,
    {
      onSuccess: () => {
        toast({
          description: t('views.shared.form.update_success'),
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      },
      onError: (error) => {
        handleError(error)
      },
    },
  )
}
