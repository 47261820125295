import { useToast, UseToastOptions } from '@chakra-ui/react'
import { AxiosError } from 'axios'

import { ERROR_TOAST_CONFIG } from '../constants'
import { getErrorMsg } from '../helpers'

export const useErrorHandler = () => {
  const toast = useToast()

  return function (
    error: AxiosError<RailsError>,
    toastConfig?: UseToastOptions,
  ) {
    const errors = error.response?.data.errors

    if (errors) {
      errors.forEach((error) => {
        toast({
          ...ERROR_TOAST_CONFIG,
          description: error,
          ...toastConfig,
        })
      })
    } else {
      toast({
        ...ERROR_TOAST_CONFIG,
        description: getErrorMsg(error),
        ...toastConfig,
      })
    }
  }
}
