import { Icon, Text, HStack } from '@chakra-ui/react'
import { AiFillStar } from 'react-icons/ai'

export type RecommendedBadgeProps = {
  isDisabled?: boolean
}

export const RecommendedBadge = ({
  isDisabled = false,
}: RecommendedBadgeProps) => {
  return (
    <HStack
      sx={{
        w: 'max-content',
        bg: isDisabled ? 'gray.300' : 'brand.400',
        alignItems: 'center',
        py: '2px',
        px: 2,
        borderRadius: 'md',
        color: 'white',
        userSelect: 'none',
        fontSize: '14px',
      }}
      spacing="1"
    >
      <Icon as={AiFillStar} />
      <Text>Recommended</Text>
    </HStack>
  )
}
