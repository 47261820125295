import AuthLayout from '@/features/auth/layouts/AuthLayout'
import SignInTwoFactorForm from '@/features/auth/components/SignInTwoFactorForm'

import Image from '@/images/project/auth/sign_in.svg'

import { withAuthPage } from '@/features/layout'

type Props = {
  backupable: boolean
  formActionPath: string
  backupMode: boolean
}

function SignInTwoFactor(props: Props): React.ReactElement {
  return (
    <AuthLayout image={Image} header={'การยืนยันตัวตนแบบสองชั้น'}>
      <SignInTwoFactorForm {...props} />
    </AuthLayout>
  )
}

export default withAuthPage(SignInTwoFactor)
