import { Dispatch, Fragment, SetStateAction } from 'react'
import { Box, Flex, Stack, Text } from '@chakra-ui/layout'
import { Switch } from '@chakra-ui/switch'
import { useDisclosure } from '@chakra-ui/hooks'

import { LanguageSelect } from '@/components/Input/LanguageSelect'
import { DeactivateCategoryModal } from '../DeactivateCategoryModal'

import { BannerLanguage } from '@/features/domains/types'
import { Category } from '../../types'

import { t } from '@/lib/helpers'
import { useUpdateCategory } from '@/features/domains/mutations'
import { getPathParams } from '@/lib/routes'
import { useAbility } from '@/features/permissions'

type Props = {
  activeLanguage: BannerLanguage
  category: Category
  isCreating: boolean
  isDisabled: boolean
  setActiveLanguage: Dispatch<SetStateAction<BannerLanguage>>
  setCurrentCat: Dispatch<SetStateAction<Category>>
}

export const ManageMenuBar = (props: Props) => {
  const {
    activeLanguage,
    category,
    isCreating,
    isDisabled,
    setActiveLanguage,
    setCurrentCat,
  } = props

  const { mutate: updateCategory, isLoading } = useUpdateCategory({
    onSuccess(updateCat) {
      setCurrentCat(updateCat)
      disclosure.onClose()
    },
  })
  const disclosure = useDisclosure()

  const ability = useAbility()
  const canUpdateCategory = ability.can('update', 'category')

  const { project_id } = getPathParams()
  const params = {
    project_id: Number(project_id),
    category_id: category.id,
  }

  const isChecked = category.status === 'active'

  const handleUpdateStatus = () => {
    updateCategory({
      data: {
        status: isChecked ? 'inactive' : 'active',
      },
      params,
    })
  }

  return (
    <Fragment>
      <Flex
        sx={{
          justifyContent: 'space-between',
          fontSize: '14px',
          fontWeight: 700,
          color: 'gray.600',
          borderBottom: '1px solid',
          borderColor: 'brand.25',
        }}
      >
        <Box sx={{ w: '160px' }}>
          <LanguageSelect
            isFullSize
            isDisabled={isDisabled}
            value={activeLanguage}
            handleChange={(lang) => {
              setActiveLanguage(lang)
            }}
            id="ManageCookieLanguageSelect"
          />
        </Box>

        {!isCreating && (
          <Stack direction="row" align="center" spacing="16px">
            <Text>
              {isChecked
                ? t('views.features.domains.show.manage.active')
                : t('views.features.domains.show.manage.inactive')}
            </Text>
            <Switch
              size="lg"
              isChecked={isChecked}
              onChange={isChecked ? disclosure.onOpen : handleUpdateStatus}
              isDisabled={isLoading || !canUpdateCategory}
              id="ToggleDisplayCategorySwitch"
            />
          </Stack>
        )}
      </Flex>

      {canUpdateCategory && (
        <DeactivateCategoryModal
          disclosure={disclosure}
          isLoading={isLoading}
          handleDeactivate={handleUpdateStatus}
        />
      )}
    </Fragment>
  )
}
