import { Box } from '@chakra-ui/react'

import { PaymentLayout, ReceiptTable } from '@/features/payments/components'
import { withPage } from '@/features/layout'

import { Transaction } from '../../types'

type Props = {
  transactions: Transaction[]
}

function TransactionPage(props: Props) {
  const { transactions } = props

  return (
    <PaymentLayout>
      <Box sx={{ p: 8, borderTop: '1px solid', borderColor: 'brand.25' }}>
        <ReceiptTable data={transactions} />
      </Box>
    </PaymentLayout>
  )
}

export default withPage(TransactionPage)
