import { Box, Stack } from '@chakra-ui/react'
import { t } from '@/lib/helpers'

import PageViewFilterSection from '../../components/PageViewFilterSection/PageViewFilterSection'
import PageViewTable from '../../components/PageViewTable/PageViewTable'

import { FilterContextProvider } from '@/features/filters/context'
import { PAYMENT_LAYOUT_TABS } from '../../constants'
import { ProjectOptions } from '../../types'
import { TabNavigation } from '@/components/shared'
import { withPage } from '@/features/layout'
import { PollingStatus } from '@/features/types'

type PageViewPageProps = {
  projectOptions: ProjectOptions
  downloadStatus: PollingStatus
  lastExportedAt: Date
}

function PageViewPage(props: PageViewPageProps) {
  const { projectOptions, downloadStatus, lastExportedAt } = props

  return (
    <FilterContextProvider>
      <Box layerStyle="container">
        <Box layerStyle="mainContent">
          <Box layerStyle="sectionHeader">
            {t('views.features.packages.title')}
          </Box>

          <TabNavigation routes={PAYMENT_LAYOUT_TABS} />

          <Box sx={{ p: 8, borderTop: '1px solid', borderColor: 'brand.25' }}>
            <Stack spacing="16px">
              <PageViewFilterSection
                projectOptions={projectOptions}
                downloadStatus={downloadStatus}
                lastExportedAt={lastExportedAt}
              />
              <PageViewTable />
            </Stack>
          </Box>
        </Box>
      </Box>
    </FilterContextProvider>
  )
}

export default withPage(PageViewPage)
