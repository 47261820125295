import { createIcon } from '@chakra-ui/react'

export const TabletIcon = createIcon({
  displayName: 'TabletIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M20 4.5V19.5C20 20.3284 19.2837 21 18.4 21H5.6C4.71634 21 4 20.3284 4 19.5L4 4.5C4 3.67157 4.71634 3 5.6 3H18.4C19.2837 3 20 3.67157 20 4.5Z"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="1"
    />,
    <path
      d="M9 18H15"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="2"
    />,
  ],
})
