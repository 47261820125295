import {
  Link,
  Select,
  Stack,
  Text,
  Collapse,
  FormHelperText,
} from '@chakra-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { t } from '@/lib/helpers'
import { LanguageDependantInput, FormControl } from '../../shared'

import { POLICY_TYPES } from '../../../constants'
import { CREATE_COOKIE_POLICY_LINK } from '@/constants/links'

import { DialogFormInput } from '@/features/domains/types'

export const Policy = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const policyType = useWatch<Pick<DialogFormInput, 'policy_type'>>({
    name: 'policy_type',
  })
  const { t: tDomainCustomize } = useTranslation('domainCustomize')

  return (
    <Stack spacing="24px">
      <Text sx={{ fontSize: '14px', color: 'gray.600' }}>
        {t(
          'views.features.domains.show.customize.policy_desc',
          undefined,
          'Policy Description',
        )}
      </Text>

      <FormControl
        label={t(
          'views.features.domains.show.customize.policy_type',
          undefined,
          'Policy Type',
        )}
        id="policy_type"
        errorMsg={errors.policy_type?.message}
      >
        <Select id="CustomizePolicy-dropdown" {...register('policy_type')}>
          {POLICY_TYPES.map((type) => (
            <option value={type} key={type} id={`CustomizePolicy-${type}`}>
              {t(
                `views.features.domains.show.customize.${type}`,
                undefined,
                type,
              )}
            </option>
          ))}
        </Select>
      </FormControl>

      <Collapse in={policyType === 'custom'}>
        <FormControl
          label={t(
            'views.features.domains.show.customize.policy_title',
            undefined,
            'Policy Title',
          )}
          id="policy_title"
        >
          <LanguageDependantInput
            id="CustomizePolicy-title"
            name="policy_title"
          />
        </FormControl>
      </Collapse>

      <FormControl
        label={t(
          'views.features.domains.show.customize.policy_url',
          undefined,
          'Policy Url',
        )}
        id="policy_url"
      >
        <LanguageDependantInput
          id="CustomizePolicy-url"
          placeholder="https://example.com"
          name="policy_url"
        />
        <FormHelperText>
          {tDomainCustomize('privacy.policyUrl.helper')}
        </FormHelperText>
      </FormControl>

      <Text sx={{ color: 'gray.600' }}>
        {t(
          'views.features.domains.show.customize.dont_have',
          undefined,
          `Don't have ?`,
        )}
        &nbsp;
        <Link
          isExternal
          id="CustomizePolicy-createPolicy"
          href={CREATE_COOKIE_POLICY_LINK}
          sx={{ color: 'brand.400', textDecor: 'underline', fontWeight: 600 }}
        >
          {t(
            'views.features.domains.show.customize.create',
            undefined,
            'Create',
          )}
        </Link>
        &nbsp;
        {t('views.features.domains.show.customize.now', undefined, 'Now!')}
      </Text>
    </Stack>
  )
}
