import { Checkbox } from '@chakra-ui/checkbox'
import { useEffect } from 'react'
import {
  CellProps,
  Hooks,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { Table } from './Table'

import { SelectionTableProps } from './types'

export const SelectionTable = <T extends Record<string, unknown>>(
  props: SelectionTableProps<T>,
) => {
  const {
    autoResetPage = false,
    autoResetSortBy,
    columns,
    data,
    initialState,
    isLoading,
    renderNoData,
    selectProps: { customRowDisable, handleSelectRow, limit },
    ...rest
  } = props

  const tableInstance = useTable<T>(
    {
      columns,
      data,
      autoResetPage,
      autoResetSortBy,
      initialState,
      ...rest,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: Hooks<any>) => [
      hooks.allColumns.push((columns) => [
        {
          id: '_selector',
          width: '5%',
          Cell: ({ row }: CellProps<any>) => {
            const { indeterminate: _, ...props } =
              row.getToggleRowSelectedProps()

            const isDisabled = customRowDisable
              ? customRowDisable(row.original)
              : false

            if (
              tableInstance.rows.filter((row) => row.isSelected).length <
                limit ||
              row.isSelected
            ) {
              return (
                <Checkbox
                  size="lg"
                  {...props}
                  isChecked={props.checked}
                  isDisabled={isDisabled}
                />
              )
            } else {
              return (
                <Checkbox
                  size="lg"
                  {...props}
                  isChecked={props.checked}
                  isDisabled={true}
                  style={{ cursor: 'not-allowed' }}
                />
              )
            }
          },
        },
        ...columns,
      ]),
    ],
  )

  useEffect(() => {
    if (handleSelectRow) {
      const selectedRows = tableInstance.selectedFlatRows.map((d) => d.original)

      handleSelectRow(selectedRows)
    }
  }, [tableInstance.state.selectedRowIds])

  return (
    <Table
      isLoading={isLoading}
      tableInstance={tableInstance}
      renderNoData={renderNoData}
    />
  )
}
