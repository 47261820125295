import { useMemo } from 'react'
import { get } from 'lodash'
import { prettyCardNumber } from '@datawowio/payment-ui'
// TODO update lib and remove this package
import creditCardType from 'credit-card-type'

import { CreditCard, RawCreditCard } from '../types'

export const useTransformCreditCards = (
  creditCardData: RawCreditCard[],
): CreditCard[] => {
  const transformedData = useMemo(() => {
    return creditCardData.map((creditData) => {
      const brand =
        creditData.brand === 'amex' ? 'american-express' : creditData.brand

      const cardNumber = prependEmptySpace(get(creditData, 'data.last4'), brand)

      return {
        id: creditData.id,
        primary: creditData.default_card,
        brand: brand,
        number: prettyCardNumber(cardNumber, brand),
      }
    })
  }, [creditCardData])

  return transformedData
}

const prependEmptySpace = (last4: string, cardType: string) => {
  const card = creditCardType.getTypeInfo(cardType)
  const [totalLength] = card.lengths

  return new Array(totalLength - 4).fill('X').join('') + last4
}
