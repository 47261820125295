import { Text, Grid, Button, Icon, useDisclosure } from '@chakra-ui/react'
import { Column } from 'react-table'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import { Fragment, useMemo, useState } from 'react'

import { NormalUser, User } from '../../types'

import {
  AnimatedActionColumn,
  AnimatedActionColumnIcon,
  DataTable,
} from '@/components/DataDisplay/Table'
import { Prompt } from '@/components/Prompt'
import { DeleteModal } from '@/components/shared'
import { EditUserModal } from '../UserModal/EditUserModal'

import { formatDateTime, t } from '@/lib/helpers'

import { useDeleteUser, useInviteUser } from '../../mutations'
import { useAppContext } from '@/features/layout'
import { useAbility } from '@/features/permissions'
import { ShowFolder } from '../ShowFolder'

export type UsersTableProps = {
  data: User[]
  isLoading: boolean
}

export const UsersTable = (props: UsersTableProps) => {
  const { data, isLoading } = props

  const { currentUser } = useAppContext()

  const [actionData, setActionData] = useState<NormalUser>()
  const { mutate: inviteUser, isLoading: resendLoading } = useInviteUser()
  const { mutate: deleteUser, isLoading: deleteLoading } = useDeleteUser()

  const deleteDisclosure = useDisclosure()
  const editDisclosure = useDisclosure()
  const resendDisclosure = useDisclosure()

  const ability = useAbility()
  const canUpdateUser = ability.can('update', 'user_management')
  const canDeleteUser = ability.can('delete', 'user_management')

  const columns: Array<Column<User>> = useMemo(() => {
    return [
      {
        Header: t('views.misc.email', undefined, 'Email'),
        accessor: 'email',
        sortKey: 'email',
        width: '30%',
      },
      {
        Header: t('views.features.users.index.status', undefined, 'Status'),
        accessor: 'status',
        sortKey: 'status',
        width: '25%',
        Cell(cellProps) {
          const data = cellProps.row.original

          const shouldNotShowResend =
            data.invitation_sent_at === null ||
            data.invitation_accepted_at !== null // if satisfied === active

          return (
            <Grid
              sx={{
                gridTemplateColumns: 'max-content 56px',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Text>
                {shouldNotShowResend
                  ? t(
                      'views.features.users.index.user_status_active',
                      undefined,
                      'Active',
                    )
                  : t(
                      'views.features.users.index.user_status_pending',
                      undefined,
                      'Pending',
                    )}
              </Text>

              {!shouldNotShowResend && (
                <Button
                  size="sm"
                  variant="outline"
                  sx={{ fontSize: '10px' }}
                  onClick={() => {
                    if (data.role === 'owner') return

                    setActionData({ ...data, role: data.role })
                    resendDisclosure.onOpen()
                  }}
                >
                  {t(
                    'views.features.users.index.resend_invite',
                    undefined,
                    'Resend',
                  )}
                </Button>
              )}
            </Grid>
          )
        },
      },
      {
        Header: t(
          'views.features.users.index.folder_name',
          undefined,
          'Folder Name',
        ),
        accessor: 'folder_permissions',
        disableSortBy: true,
        width: '20%',
        Cell(cellProps) {
          const value = cellProps.value.filter((v) => v.role !== 'no_access')

          return <ShowFolder folder_permissions={value} />
        },
      },
      {
        Header: t('views.misc.created_at', undefined, 'Created at'),
        accessor: 'created_at',
        sortKey: 'createdAt',
        width: '20%',
        Cell(cellProps) {
          const value = cellProps.value

          return formatDateTime(value)
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable<User>
        data={memoizedData}
        columns={columns}
        isLoading={isLoading}
        renderActionColumn={{
          width: '10%',
          Cell: (user) => {
            if (
              (!canUpdateUser && !canDeleteUser) ||
              user.role === 'owner' ||
              user.id === currentUser.id
            )
              return null

            const icons: AnimatedActionColumnIcon[] = []

            if (canDeleteUser) {
              icons.push({
                icon: (
                  <Icon
                    id="user-delete"
                    as={FiTrash2}
                    onClick={() => {
                      if (user.role === 'owner') return

                      setActionData({ ...user, role: user.role })
                      deleteDisclosure.onOpen()
                    }}
                    sx={{
                      color: 'red.400',
                      cursor: 'pointer',
                      boxSize: '20px',
                    }}
                  />
                ),
                label: t('views.shared.button.delete', undefined, 'Delete'),
              })
            }

            if (canUpdateUser) {
              icons.push({
                icon: (
                  <Icon
                    id="user-edit"
                    as={FiEdit3}
                    onClick={() => {
                      if (user.role === 'owner') return

                      setActionData({ ...user, role: user.role })
                      editDisclosure.onOpen()
                    }}
                    sx={{
                      cursor: 'pointer',
                      boxSize: '20px',
                      color: 'brand.400',
                    }}
                  />
                ),
                label: t('views.shared.button.edit', undefined, 'Edit'),
              })
            }

            return (
              <>
                <AnimatedActionColumn
                  ariaLabel="user column action button"
                  icons={icons}
                />
              </>
            )
          },
        }}
      />

      {!!actionData && (
        <Fragment>
          {canDeleteUser && (
            <DeleteModal
              disclosure={deleteDisclosure}
              onDelete={() => {
                deleteUser(
                  {
                    params: {
                      user_id: actionData.id,
                    },
                  },
                  {
                    onSuccess() {
                      deleteDisclosure.onClose()
                    },
                  },
                )
              }}
              isLoading={deleteLoading}
              title={t(
                'views.features.users.index.confirm_disable_user',
                { email: actionData.email },
                'Confirm delete this account?',
              )}
            />
          )}

          {canUpdateUser && (
            <EditUserModal disclosure={editDisclosure} user={actionData} />
          )}

          <Prompt
            disclosure={resendDisclosure}
            colorScheme="brand"
            isLoading={resendLoading}
            title={t(
              'views.features.users.index.resend_invite_title',
              undefined,
              'Confirm resend invitation?',
            )}
            onConfirm={() => {
              inviteUser(
                {
                  data: {
                    email: actionData.email,
                  },
                },
                {
                  onSuccess() {
                    resendDisclosure.onClose()
                  },
                },
              )
            }}
          >
            <Text>
              {t(
                'views.features.users.index.resend_invite_title',
                { email: actionData.email },
                'Confirm resend invitation to ?',
              )}
            </Text>
          </Prompt>
        </Fragment>
      )}
    </Fragment>
  )
}
