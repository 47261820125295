import { VStack, Input, Button, Link } from '@chakra-ui/react'

import { FormControl } from '@/components/FormControl'

import {
  signInURL,
  resendConfirmationURL,
} from '@/features/auth/constants/links'
import { inputStyle, linkStyle } from '@/features/auth/constants/styles'

import { t } from '@/lib/helpers'
import { getAuthenticityToken } from '@/lib/api'

type Props = {
  formActionPath: string
}

function ForgotPasswordForm({ formActionPath }: Props): React.ReactElement {
  const authenticityToken = getAuthenticityToken()

  return (
    <VStack
      spacing="4"
      sx={{
        alignItems: 'stretch',
      }}
    >
      <form
        id="new_user"
        action={formActionPath}
        acceptCharset="UTF-8"
        method="post"
      >
        <VStack
          spacing="4"
          sx={{
            alignItems: 'stretch',
          }}
        >
          <FormControl label={t('activerecord.attributes.user.email')}>
            <Input
              isRequired
              id="user_email"
              type="email"
              autoComplete="email"
              autoFocus
              name="user[email]"
              placeholder="example@cookiewow.com"
              sx={inputStyle}
            />
          </FormControl>

          <Input
            value={authenticityToken}
            type="hidden"
            name="authenticity_token"
          />

          <Button
            type="submit"
            name="commit"
            size="xl"
            value={t(
              'devise.passwords.new.send_me_reset_password_instructions',
            )}
            data-disable-with={t(
              'devise.passwords.new.send_me_reset_password_instructions',
            )}
          >
            {t('devise.passwords.new.send_me_reset_password_instructions')}
          </Button>
        </VStack>
      </form>
      <VStack
        spacing="3"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Link href={signInURL} sx={linkStyle}>
          {t('devise.sessions.new.sign_in')}
        </Link>
        <Link href={resendConfirmationURL} sx={linkStyle}>
          {t('devise.shared.links.didn_t_receive_confirmation_instructions')}
        </Link>
      </VStack>
    </VStack>
  )
}

export default ForgotPasswordForm
