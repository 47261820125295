import { Box, Stack, Text, Flex, HStack } from '@chakra-ui/layout'
import { Radio, RadioGroup } from '@chakra-ui/radio'
import { Button } from '@chakra-ui/button'
import { Collapse } from '@chakra-ui/transition'
import { Controller, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { get } from 'lodash'

import { PageSettingFormInput } from '../types'
import { CreatableInput } from '@/components/Input'
import { DomainDisplayDescription } from '@/features/domains/components/DomainDisplayDescription'

import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useUpdateBannerDisplay } from '../mutations'
import { PAGE_SETTINGS } from '../../Customize/constants'
import { PATTERN } from '../../constants'

const schema = z.object({
  included_urls: z.string().url('invalidUrlFull').array(),
  excluded_urls: z.string().url('invalidUrlFull').array(),
  page_setting: z.enum(PAGE_SETTINGS),
})

type Props = {
  defaultValues: PageSettingFormInput
}

export const BannerDisplay = (props: Props): React.ReactElement => {
  const { defaultValues } = props

  const query = getPathParams()
  const params = { projectId: Number(query?.project_id) }

  const { t: tCommon } = useTranslation('common')
  const { t: tErrors } = useTranslation('errors')

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<PageSettingFormInput>({
    defaultValues,
    resolver: zodResolver(schema),
  })

  const defaultValuesRef = useRef<PageSettingFormInput>()
  const { mutate, isLoading } = useUpdateBannerDisplay()

  return (
    <Box
      sx={{ h: 'full' }}
      as="form"
      className="BannerDisplayForm"
      onSubmit={handleSubmit((data) => {
        mutate(
          {
            data,
            params,
          },
          {
            onSuccess() {
              defaultValuesRef.current = data
              reset(data)
            },
          },
        )
      })}
    >
      <Flex sx={{ flexDir: 'column', h: 'full' }}>
        <Stack spacing={3}>
          <Flex justify="space-between">
            <Text sx={{ fontSize: '18px', fontWeight: 600 }}>
              {t(
                'views.features.domains.show.customize.show_banner_setting',
                undefined,
                'Show Banner Setting',
              )}
            </Text>

            <HStack sx={{ mt: 'auto', justifyContent: 'flex-end' }}>
              <Button
                isDisabled={isLoading || !isDirty}
                variant="outline"
                onClick={() => reset(defaultValuesRef.current)}
                className="DiscardChangesButton"
                size="sm"
              >
                {tCommon('discardChanges')}
              </Button>
              <Button
                type="submit"
                isLoading={isLoading}
                isDisabled={!isDirty}
                size="sm"
                minW="120px"
              >
                {tCommon('save')}
              </Button>
            </HStack>
          </Flex>

          <DomainDisplayDescription
            description={
              <Trans i18nKey="features.domains.customize.banner_display_desc">
                Banner Display supports entering URLs in
                <Text as="span" color="brand.400">
                  Specific URLs
                </Text>
                or
                <Text as="span" color="brand.400">
                  Wildcard URLs
                </Text>
                format.
              </Trans>
            }
            detail={
              <Trans i18nKey="features.domains.customize.banner_display_detail">
                If you want to scan
                <Text as="span" color="brand.600" fontWeight={600}>
                  Wildcard URLs
                </Text>
                , you can use the asterisk (
                <Text as="span" color="brand.600">
                  *
                </Text>
                ) symbol as a placeholder to represent the part of the URL that
                can have different values. For example, using the wildcard URL
                pattern
                <Text as="span" color="brand.600">
                  &quot;https://www.cookiewow.com/*&quot;
                </Text>
                , the system will scan all URLs that start with
                <Text as="span" color="brand.600">
                  &quot;https://www.cookiewow.com/&quot;
                </Text>
                .
              </Trans>
            }
          />

          <Controller
            name="page_setting"
            defaultValue={defaultValues.page_setting}
            control={control}
            render={({ field: radioField }) => {
              const includedError =
                (get(errors, 'included_urls') ?? []).find((err) => err?.message)
                  ?.message ?? ''
              const excludedError =
                (get(errors, 'excluded_urls') ?? []).find((err) => err?.message)
                  ?.message ?? ''
              const includeCombinedError = `${tErrors(
                includedError,
              )}\n${tErrors('wildcardUrlFull')}`
              const excludeCombinedError = `${tErrors(
                excludedError,
              )}\n${tErrors('wildcardUrlFull')}`

              return (
                <RadioGroup {...radioField} colorScheme="brand" size="lg">
                  <Stack spacing={3}>
                    <Radio value="all">
                      <Text sx={{ fontWeight: 600 }}>
                        {t(
                          'views.features.domains.show.customize.all_page',
                          undefined,
                          'Show Banner on All Page',
                        )}
                      </Text>
                    </Radio>

                    <Stack>
                      <Stack spacing={1}>
                        <Radio value="include">
                          <Text sx={{ fontWeight: 600 }}>
                            {t(
                              'views.features.domains.show.customize.include_page',
                              undefined,
                              'Show Banner on specific Page',
                            )}
                          </Text>
                        </Radio>

                        <Text sx={{ pl: '28px' }}>
                          <Trans i18nKey="features.domains.customize.include_page_desc">
                            Enter the url of your website to&nbsp;
                            <Text as="span" color="brand.400">
                              show
                            </Text>
                            &nbps; the banner on that specific pages
                          </Trans>
                        </Text>
                      </Stack>

                      <Stack sx={{ pl: '28px' }}>
                        <Controller
                          name="included_urls"
                          defaultValue={defaultValues.included_urls}
                          control={control}
                          render={({ field: { name, onChange, value } }) => {
                            return (
                              <CreatableInput
                                id={`${name}--container`}
                                name={name}
                                onChange={onChange}
                                placeholder={t(
                                  'views.features.domains.show.scanner.scan_input_placeholder',
                                  undefined,
                                  'type URL here',
                                )}
                                placeholderId={`${name}--id`}
                                values={value}
                                pattern={PATTERN}
                              />
                            )
                          }}
                        />

                        <Collapse in={Boolean(includedError)}>
                          {includedError && (
                            <Text
                              textStyle="formError"
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              {includeCombinedError}
                            </Text>
                          )}
                        </Collapse>
                      </Stack>
                    </Stack>

                    <Stack spacing={1}>
                      <Radio value="exclude">
                        <Text sx={{ fontWeight: 600 }}>
                          {t(
                            'views.features.domains.show.customize.exclude_page',
                            undefined,
                            'Hide Banner on specific Page',
                          )}
                        </Text>
                      </Radio>

                      <Text sx={{ pl: '28px' }}>
                        <Trans i18nKey="features.domains.customize.exclude_page_desc">
                          Enter the url of your website to&nbsp;
                          <Text as="span" color="brand.400">
                            hide
                          </Text>
                          &nbps;the banner on that specific pages
                        </Trans>
                      </Text>
                    </Stack>

                    <Stack sx={{ pl: '28px' }}>
                      <Controller
                        name="excluded_urls"
                        defaultValue={defaultValues.excluded_urls}
                        control={control}
                        render={({ field: { name, onChange, value } }) => {
                          return (
                            <CreatableInput
                              id={`${name}--container`}
                              name={name}
                              onChange={onChange}
                              placeholder={t(
                                'views.features.domains.show.scanner.scan_input_placeholder',
                                undefined,
                                'type URL here',
                              )}
                              placeholderId={`${name}--id`}
                              values={value}
                              pattern={PATTERN}
                            />
                          )
                        }}
                      />

                      <Collapse in={Boolean(excludedError)}>
                        {excludedError && (
                          <Text
                            textStyle="formError"
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            {excludeCombinedError}
                          </Text>
                        )}
                      </Collapse>
                    </Stack>
                  </Stack>
                </RadioGroup>
              )
            }}
          />
        </Stack>
      </Flex>
    </Box>
  )
}
