import React, {
  useState,
  useMemo,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'
import { isEmpty } from 'lodash'
import { AddressType } from '@datawowio/payment-ui'

import { createCtx } from '@/lib/helpers'

import { useTransformPackageData } from '../hooks'

import { withPage } from '@/features/layout'
import { PaymentProvider } from '../components'

import { Address, PackageData, RawAddress } from '../types'
import { CurrentPackage } from '@/features/types'

type CheckoutContextValue = {
  addressType: AddressType
  addresses: Address[]
  hostUrl: string
  packageData: any
  recurringType: Period
  selectedAddress: number | undefined
  setAddressType: Dispatch<SetStateAction<AddressType>>
  setRawAddresses: Dispatch<SetStateAction<RawAddress[]>>
  setRecurringType: Dispatch<SetStateAction<Period>>
  setSelectedAddress: Dispatch<SetStateAction<number | undefined>>
  recurring: Record<Period, RecurringItem>
}

type CheckoutPageProps = {
  addresses: RawAddress[]
  recurring: Period
  currentPackage: CurrentPackage
  host_url: string
  package: PackageData
}

const checkoutStoreContext = createCtx<CheckoutContextValue>()
const [, Provider] = checkoutStoreContext
export const [useCheckoutStore] = checkoutStoreContext

export const withCheckoutPage = (
  Component: React.ComponentType<CheckoutPageProps>,
) =>
  withPage((props: CheckoutPageProps) => {
    const [addressType, setAddressType] = useState<AddressType>(
      getAddressType(props.addresses),
    )
    const [rawAddresses, setRawAddresses] = useState<RawAddress[]>(
      props.addresses || [],
    )
    const [selectedAddress, setSelectedAddress] = useState<number | undefined>()
    const [recurringType, setRecurringType] = useState<Period>(props.recurring)

    const addresses = useTransformAddresses(rawAddresses, addressType)
    const packageData = useTransformPackageData(
      props.package,
      recurringType,
      addressType,
    )

    useEffect(() => {
      setSelectedAddress(getDefaultSelectedAddress(addresses))
    }, [addressType, addresses])

    const checkoutStoreValues: CheckoutContextValue = {
      addressType,
      addresses,
      hostUrl: props.host_url,
      packageData,
      recurringType,
      selectedAddress,
      setAddressType,
      setRawAddresses,
      setRecurringType,
      setSelectedAddress,
      recurring: props.package.recurring,
    }

    return (
      <PaymentProvider>
        <Provider value={checkoutStoreValues}>
          <Component {...props} />
        </Provider>
      </PaymentProvider>
    )
  })

const getAddressType = (addresses: RawAddress[]): AddressType => {
  if (isEmpty(addresses)) return 'company'

  const primaryAddress = addresses.find((data) => data.primary)
  if (!primaryAddress) return 'company'

  const defaultAddressType = primaryAddress.taxpayer_type

  return defaultAddressType
}

const getDefaultSelectedAddress = (addresses: Address[]) => {
  if (isEmpty(addresses)) {
    return undefined
  }

  const primaryIndex = addresses.findIndex((data) => data.primary)
  const initialSelectedId = addresses[primaryIndex]?.id

  if (initialSelectedId) return initialSelectedId

  return addresses[0].id
}

const useTransformAddresses = (
  data: RawAddress[] = [],
  addressType: AddressType,
): Address[] => {
  const transformedAddresses = useMemo(() => {
    const transformedList = data.map<Address>((item) => {
      return {
        id: item.id,
        name: item.name,
        primary: item.primary,
        taxId: item.taxpayer_id,
        taxPayerType: item.taxpayer_type,
        address: item.details,
      }
    })

    const filteredData = transformedList.filter(
      (item) => item.taxPayerType === addressType,
    )

    return filteredData
  }, [data, addressType])

  return transformedAddresses
}
