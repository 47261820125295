import {
  Button,
  Collapse,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useMemo } from 'react'

import { t } from '@/lib/helpers'

import type { UnsubscribeReasonFormInput } from '../../types'

export type UnsubscribeReasonFormProps = {
  isLoading: boolean
  onSubmit: (data: UnsubscribeReasonFormInput) => void
}

export const UnsubscribeReasonForm = (
  props: UnsubscribeReasonFormProps,
): React.ReactElement => {
  const { onSubmit, isLoading } = props

  const { handleSubmit, register, setValue, watch } =
    useForm<UnsubscribeReasonFormInput>()

  const options = useMemo(() => {
    return Array.from(Array(5)).map((_, idx) =>
      t(`views.features.packages.packages.unsubscribe.reasons.${idx + 1}`),
    )
  }, [])

  const reason = watch('reason')
  const isShowInput =
    reason === t(`views.features.packages.packages.unsubscribe.reasons.5`)

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}
    >
      <Grid gap={6}>
        <RadioGroup
          colorScheme="brand"
          size="lg"
          defaultValue="1"
          onChange={(value) => {
            setValue('reason', value)
          }}
        >
          <Stack spacing={4}>
            {options.map((opt) => (
              <Radio key={opt} value={opt}>
                <Text fontSize="16px">{opt}</Text>
              </Radio>
            ))}

            <Collapse in={isShowInput}>
              <Input {...register('reason_input')} isRequired={isShowInput} />
            </Collapse>
          </Stack>
        </RadioGroup>

        <Button size="xl" type="submit" isLoading={isLoading}>
          {t('views.shared.button.unsub_plan')}
        </Button>
      </Grid>
    </form>
  )
}
