import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/toast'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  userIds: number[]
}

const deleteUsers = (payload: Payload) => {
  const { userIds } = payload

  return fetchAPI({
    path: '/downgrade_conflicts',
    method: 'delete',
    data: {
      except_users: userIds,
    },
  })
}

export const useDeleteUsers = () => {
  const toast = useToast()
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse, AxiosError, Payload>(deleteUsers, {
    async onSuccess() {
      toast({
        ...TOAST_CONFIG,
        description: t('views.features.users.services.delete_user_success'),
      })
      setTimeout(() => {
        window.location.href = `/projects`
      }, 200)
    },
    onError(error) {
      handleError(error)
    },
  })
}
