import { ReactNode } from 'react'
import {
  Collapse,
  Flex,
  FormControl as ChakraFC,
  FormControlOptions,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  SystemStyleObject,
  Tooltip,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import { AiOutlineInfoCircle } from 'react-icons/ai'

type Props = FormControlOptions & {
  children: ReactNode
  className?: string
  errorMsg?: string | FieldError
  formHelperText?: string
  htmlFor?: string
  id?: string
  label?: string | React.ReactNode
  sx?: SystemStyleObject
  tooltipText?: string
  fakeInput?: boolean
}

export const FormControl = (props: Props) => {
  const {
    children,
    className,
    errorMsg,
    formHelperText,
    htmlFor,
    id,
    isDisabled,
    isReadOnly,
    isRequired = false,
    label,
    sx,
    tooltipText,
    fakeInput = false,
  } = props

  return (
    <ChakraFC
      id={id}
      sx={sx}
      isInvalid={Boolean(errorMsg)}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      className={className}
    >
      {label && (
        <Grid sx={{ gridTemplateColumns: 'max-content 18px' }}>
          <FormLabel
            htmlFor={htmlFor}
            sx={{
              ...(fakeInput && {
                opacity: 0.4,
              }),
            }}
          >
            {label}
          </FormLabel>
          {tooltipText && (
            <Tooltip
              label={tooltipText}
              placement="top-start"
              sx={{ padding: '12px', borderRadius: '8px' }}
              hasArrow
            >
              <Flex mt="5px">
                <Icon as={AiOutlineInfoCircle} color="gray.300" />
              </Flex>
            </Tooltip>
          )}
        </Grid>
      )}
      {children}
      {formHelperText && (
        <FormHelperText sx={{ fontSize: '12px' }}>
          {formHelperText}
        </FormHelperText>
      )}

      <Collapse in={Boolean(errorMsg)} animateOpacity>
        <FormErrorMessage data-testid="error-msg">{errorMsg}</FormErrorMessage>
      </Collapse>
    </ChakraFC>
  )
}
