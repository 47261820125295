export const BANNER_STATUS = 'banner_status'
export const CONSENTS = 'consents'
export const CONSENT_CSV_STATUS = 'consent_csv_status'
export const COOKIE_CATEGORIES = 'cookie_categories'
export const DIALOG = 'dialog'
export const DOMAINS = 'domains'
export const PROJECT = 'project'
export const SCANNED_COOKIES = 'scanned_cookies'
export const SCAN_STATUS = 'scan_status'
export const SCANNED_URL = 'scanned_url'
