import { ReactElement } from 'react'
import { Link, Stack, Text } from '@chakra-ui/layout'
import { useTranslation, Trans } from 'react-i18next'

import { getPathParams } from '@/lib/routes'

const linkStyle = {
  color: 'brand.400',
  fontWeight: 'bold',
  textDecoration: 'underline !important',
}

export const ScannerDescription = (): ReactElement => {
  const { project_id } = getPathParams()
  const { t } = useTranslation('domainScanner')

  return (
    <Stack
      sx={{
        p: 6,
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'gray.300',
        bg: 'gray.50',
        color: 'gray.600',
      }}
      spacing="24px"
    >
      <Text>{t('description_1')}</Text>
      <Text>
        <Trans t={t} i18nKey="description_2">
          Cookie Wow พยายามอย่างเต็มที่ให้สามารถสแกนคุกกี้ให้ได้มากที่สุด
          แต่อย่างไรก็ตามในบางครั้งเราอาจจะไม่สามารถพบคุกกี้ได้ 100%
          ซึ่งอาจจะเกิดจากปัจจัยต่าง ๆ
          ทั้งนี้เราจึงแนะนำให้ท่านตรวจสอบเพิ่มเติมอีกครั้งและเพิ่มคุกกี้ที่สแกนไม่พบที่หน้า
          <Link href={`/projects/${project_id}/manage`} sx={linkStyle}>
            จัดการแบนเนอร์คุกกี้
          </Link>
        </Trans>
      </Text>

      <Text>
        <Trans t={t} i18nKey="description_3">
          การทำงานของแบนเนอร์คุกกี้นั้น จำเป็นจะต้องใช้คุกกี้ในการทำงาน
          ท่านสามารถอ่านเพิ่มเติมเกี่ยวกับการใช้คุกกี้ของ Cookie Wow ได้
          <Link
            href="https://help.cookiewow.com/th/article/cookie-wow-15iu4lr"
            isExternal
            sx={linkStyle}
          >
            ที่นี่
          </Link>
        </Trans>
      </Text>
    </Stack>
  )
}
