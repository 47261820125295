import {
  Box,
  HStack,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  UnorderedList,
} from '@chakra-ui/react'

import { FolderPermission } from '../../types'

export type ShowFolderProps = {
  folder_permissions: FolderPermission[]
}

export const ShowFolder = (props: ShowFolderProps) => {
  const { folder_permissions } = props

  const length = folder_permissions.length

  if (!length) return <Text>-</Text>

  const [first, ...rest] = folder_permissions

  return (
    <HStack>
      <Text>{first.folder_name}</Text>
      {length > 1 && (
        <Popover trigger="hover" placement="top">
          <PopoverTrigger>
            <Box
              sx={{
                fontSize: '14px',
                color: 'gray.300',
                bg: 'gray.50',
                p: '2px 12px',
                borderRadius: '16px',
                cursor: 'pointer',
                userSelect: 'none',
              }}
            >
              {length - 1} {length > 2 && '+'}
            </Box>
          </PopoverTrigger>
          <PopoverContent sx={{ w: '200px' }}>
            <PopoverArrow />
            <PopoverBody>
              <UnorderedList>
                {rest.map((r) => {
                  return <ListItem key={r.folder_id}>{r.folder_name}</ListItem>
                })}
              </UnorderedList>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </HStack>
  )
}
