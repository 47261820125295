import { UseDisclosureReturn, ModalBody } from '@chakra-ui/react'

import { Modal } from '@/components/shared'
import { DisableTwoFactorForm } from '../../components/DisableTwoFactorForm'
import { PasswordWrapper } from '../../components/PasswordWrapper'

import { t } from '@/lib/helpers'

import { useDisableTwoFactor } from '../../mutations'

export type Disable2FAModalProps = {
  disclosure: UseDisclosureReturn
  isPasswordRequired: boolean
}

export const Disable2FAModal = (props: Disable2FAModalProps) => {
  const { disclosure, isPasswordRequired } = props
  const { isOpen, onClose } = disclosure

  const { mutate: disableTwoFactor, isLoading } = useDisableTwoFactor()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <ModalBody sx={{ py: 5 }}>
        <PasswordWrapper
          title={t(
            'views.features.two_factor.remove_two_factor',
            undefined,
            'Remove Two-Factor Authentication',
          )}
          isCompleted={!isPasswordRequired}
        >
          <DisableTwoFactorForm
            isLoading={isLoading}
            onCancel={onClose}
            onSubmit={(data) => {
              disableTwoFactor(
                { data },
                {
                  onSuccess() {
                    disclosure.onClose()
                  },
                },
              )
            }}
          />
        </PasswordWrapper>
      </ModalBody>
    </Modal>
  )
}
