import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useErrorHandler } from '@/lib/hooks'

import { COOKIE_CATEGORIES, PROJECT } from '../constants'
import { TOAST_CONFIG } from '@/lib/constants'

import { CategoryFormInput } from '../Manage/types'

type Payload = {
  data: CategoryFormInput
  params: {
    project_id: number
  }
}

const addCategory = async ({ data, params }: Payload) => {
  const { project_id } = params

  const response = await fetchAPI<Category>({
    path: `/projects/${project_id}/categories`,
    method: 'post',
    data,
  })

  return response.data
}

type UseAddCategoryProps = {
  onSuccess: (cat: Category) => void
}

export const useAddCategory = ({ onSuccess }: UseAddCategoryProps) => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  return useMutation<Category, AxiosError, Payload>(addCategory, {
    async onSuccess(data) {
      onSuccess(data)
      queryClient.setQueryData<Category[]>(
        [COOKIE_CATEGORIES, params],
        (currentData) => {
          return [...currentData!, data]
        },
      )

      queryClient.invalidateQueries([PROJECT, params])

      toast({
        ...TOAST_CONFIG,
        title: t(
          'views.features.domains.show.manage.services.category_created',
        ),
      })
    },
    onError(error) {
      handleError(error)
    },
    async onSettled() {
      await queryClient.refetchQueries([COOKIE_CATEGORIES, params])
    },
  })
}
