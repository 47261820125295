import { Fragment, useMemo } from 'react'
import {
  Box,
  Flex,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/layout'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Icon from '@chakra-ui/icon'
import { useDisclosure } from '@chakra-ui/hooks'
import { FiArrowLeft } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { withPage } from '@/features/layout'

import { AlreadySubModal } from '../../components/atoms'
import { FreeTrialFormContainer } from './FreeTrialFormContainer'

import { CurrentUser } from '@/features/users/types'

type Props = {
  stripePKey: string
  hadSubscribed: boolean
  user: CurrentUser
}

function FreeTrialPage(props: Props) {
  const { stripePKey, hadSubscribed } = props

  const stripePromise = useMemo(() => loadStripe(stripePKey), [stripePKey])

  const { t: T } = useTranslation(['freeTrial'])
  const disclosure = useDisclosure({
    isOpen: hadSubscribed,
  })

  return (
    <Fragment>
      <Elements stripe={stripePromise}>
        <Box layerStyle="container">
          <Flex
            sx={{
              my: 4,
            }}
          >
            <Link
              sx={{
                d: 'grid',
                gap: '8px',
                gridTemplateColumns: 'max-content 1fr',
                alignItems: 'center',
                fontWeight: 700,
                color: 'brand.400',
                w: 'max-content',
              }}
              href="/packages"
            >
              <Icon
                as={FiArrowLeft}
                sx={{ color: 'brand.400', fontSize: '24px' }}
              />
              {T('backToPackage')}
            </Link>
          </Flex>
          <Box layerStyle="mainContent" sx={{ mt: 0, p: 6 }}>
            <Stack spacing={4}>
              <Heading as="h1" sx={{ fontSize: '20px' }}>
                {T('h1')}
              </Heading>

              <FreeTrialFormContainer />

              <Stack sx={{ fontSize: '12px' }} spacing={1}>
                <Text sx={{ color: 'brand.400', textDecor: 'underline' }}>
                  {T('note')}
                </Text>
                <UnorderedList sx={{ pl: 6, color: 'gray.600' }}>
                  <ListItem>{T('note_0')}</ListItem>
                  <ListItem>{T('note_1')}</ListItem>
                </UnorderedList>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Elements>

      <AlreadySubModal disclosure={disclosure} />
    </Fragment>
  )
}

export default withPage(FreeTrialPage)
