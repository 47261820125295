import { forwardRef, useState } from 'react'
import { IconButton } from '@chakra-ui/button'
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/input'
import { FiEye, FiEyeOff } from 'react-icons/fi'

export type PasswordInputProps = InputProps & {}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref) => {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow((show) => !show)

    return (
      <InputGroup size={props.size}>
        <Input
          pr="2rem"
          {...props}
          type={show ? 'text' : 'password'}
          ref={ref}
        />
        <InputRightElement width="2rem">
          <IconButton
            aria-label="toggle view password"
            onClick={handleClick}
            variant="unstyled"
            colorScheme="gray"
            color="gray.600"
            fontSize="20px"
            icon={show ? <FiEyeOff /> : <FiEye />}
          />
        </InputRightElement>
      </InputGroup>
    )
  },
)
