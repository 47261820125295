import { Grid, Button, Input, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { FormControl } from '@/components/FormControl'

import { CompanySettingsFormInput } from '@/features/users/types'

export type SignUpProfileFormProps = {
  isLoading: boolean
  onSubmit: (data: CompanySettingsFormInput) => void
}

export const SignUpProfileForm = (props: SignUpProfileFormProps) => {
  const { onSubmit, isLoading } = props

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CompanySettingsFormInput>()

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}
    >
      <Stack spacing="6">
        <Stack spacing="3">
          <Grid sx={{ gap: '12px', gridTemplateColumns: '1fr 1fr' }}>
            <FormControl
              errorMsg={errors.first_name?.message}
              id="first_name"
              label="ชื่อ"
              isRequired
            >
              <Input {...register('first_name')} placeholder="ชื่อ" />
            </FormControl>

            <FormControl
              errorMsg={errors.last_name?.message}
              id="last_name"
              label="นามสกุล"
              isRequired
            >
              <Input {...register('last_name')} placeholder="นามสกุล" />
            </FormControl>
          </Grid>

          <FormControl
            errorMsg={errors.name?.message}
            id="name"
            label="ชื่อบริษัทหรือชื่อแบรนด์"
          >
            <Input
              {...register('name')}
              placeholder="กรอกชื่อบริษัทหรือชื่อแบรนด์"
            />
          </FormControl>
          <FormControl
            errorMsg={errors.phone_number?.message}
            id="phone_number"
            label="เบอร์โทรติดต่อ"
            isRequired
          >
            <Input
              {...register('phone_number')}
              placeholder="0XX-XXX-XXXX"
              type="tel"
              inputMode="tel"
            />
          </FormControl>
        </Stack>

        <Stack spacing="3">
          <Button size="xl" type="submit" isLoading={isLoading}>
            บันทึก
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
