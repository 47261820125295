import { useMemo, Dispatch, SetStateAction } from 'react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { FilterPeriod } from '../../types'

export type PeriodFilterProps = {
  period: FilterPeriod
  setPeriod: Dispatch<SetStateAction<FilterPeriod>>
}

export const PeriodFilter = (props: PeriodFilterProps) => {
  const { period, setPeriod } = props

  const { t } = useTranslation('domainConsent')

  const buttons = useMemo<{ label: string; value: FilterPeriod }[]>(() => {
    return [
      {
        label: t('thisMonth'),
        value: 'this_month',
      },
      {
        label: t('thisYear'),
        value: 'this_year',
      },
      {
        label: t('allTime'),
        value: 'all_time',
      },
    ]
  }, [])

  return (
    <ButtonGroup size="sm" sx={{ bg: '#E8E8E880', p: 1, borderRadius: '20px' }}>
      {buttons.map((b) => {
        const isActive = period === b.value

        return (
          <Button
            key={b.value}
            onClick={() => setPeriod(b.value)}
            sx={{ borderRadius: '16px', fontWeight: 'semibold' }}
            variant={isActive ? 'solid' : 'ghost'}
            colorScheme={isActive ? 'brand' : 'gray'}
            id={`${b.value}-button`}
          >
            {b.label}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}
