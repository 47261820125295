import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AspectRatio,
  Text,
  Stack,
  Flex,
  Divider,
  Button,
  ModalBody,
} from '@chakra-ui/react'
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/hooks'
import { PieChart } from 'react-minimal-pie-chart'

import { ConsentDashboardCard } from '../ConsentDashboardCard'
import { Modal, ModalHeader } from '@/components/shared'
import { ByCategoryDetailedChart } from '../ByCategoryDetailedChart/ByCategoryDetailedChart'

import { CategoryStatItem } from '../../types'

import { byCategoryColor, pieChartStyle } from '../../constants'

import { isDefaultCategory, randomColor } from '../../helpers'
import { EmptyPie } from '../EmptyPie'
import { ColorItem } from '../ColorItem'

export type ByCategoryProps = {
  data: CategoryStatItem[]
}

export const ByCategory = (props: ByCategoryProps): React.ReactElement => {
  const { data } = props

  const { t } = useTranslation('domainConsent')
  const { t: tCommon } = useTranslation('common')

  const disclosure = useDisclosure()

  const total = useMemo(
    () => data.reduce((sum, cur) => sum + cur.count, 0),
    [data],
  )

  const labelData = data.map((entry, idx) => {
    let color: string

    if (isDefaultCategory(entry.slug)) {
      color = byCategoryColor[entry.slug]
    } else {
      color = randomColor(idx, 8)
    }

    return {
      value: entry.count,
      title: entry.label,
      color,
    }
  })

  const pieData = labelData.filter((data) => Boolean(data.value))

  const moreThanFiveCategory = labelData.length > 5

  return (
    <Fragment>
      <ConsentDashboardCard
        title={t('byCategory')}
        tooltip={t('byCategoryTooltip')}
        id="by-category-card"
        bottomSection={
          <Flex flexDir="column">
            <Stack
              sx={{
                fontSize: 'xs',
              }}
            >
              <Flex
                sx={{
                  fontWeight: 'bold',
                  justifyContent: 'space-between',
                  fontSize: 'sm',
                }}
              >
                <Text>{tCommon('total')}</Text>
                <Text>{total}</Text>
              </Flex>

              <Divider />

              {labelData.slice(0, moreThanFiveCategory ? 4 : 5).map((datum) => {
                return (
                  <ColorItem
                    key={datum.title}
                    text={datum.title}
                    color={datum.color}
                    value={datum.value}
                  />
                )
              })}
            </Stack>

            {moreThanFiveCategory && (
              <Button
                variant="link"
                size="sm"
                onClick={disclosure.onOpen}
                mt="auto"
              >
                {tCommon('seeMore')}
              </Button>
            )}
          </Flex>
        }
      >
        <AspectRatio
          ratio={1 / 1}
          sx={{ w: 'full', alignSelf: 'start', maxH: '186px' }}
        >
          {total ? (
            <PieChart
              data={pieData}
              label={({ dataEntry }) => {
                const percent = Math.round((dataEntry.value / total) * 100)

                return percent < 5 ? null : percent + '%'
              }}
              {...pieChartStyle}
              paddingAngle={pieData.length > 1 ? 3 : 0}
            />
          ) : (
            <EmptyPie />
          )}
        </AspectRatio>
      </ConsentDashboardCard>

      <ByCategoryDetailedChartModal
        disclosure={disclosure}
        data={labelData}
        total={total}
      />
    </Fragment>
  )
}

type ByCategoryDetailedChartModalProps = {
  disclosure: UseDisclosureReturn
  data: { value: number; color: string; title: string }[]
  total: number
}

const ByCategoryDetailedChartModal = (
  props: ByCategoryDetailedChartModalProps,
) => {
  const { disclosure, data, total } = props
  const { isOpen, onClose } = disclosure

  const { t } = useTranslation('domainConsent')

  return (
    <Modal onClose={onClose} isOpen={isOpen} maxW="700px">
      <ModalHeader sx={{ fontSize: '2xl' }}>{t('byCategory')}</ModalHeader>
      <ModalBody sx={{ py: 0 }}>
        <ByCategoryDetailedChart data={data} total={total} />
      </ModalBody>
    </Modal>
  )
}
