import { AxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'
import { TOAST_CONFIG } from '@/lib/constants'
import { CouponRule } from '../types'

type Payload = {
  coupon: string
  packageName: Exclude<PackageName, 'free' | 'enterprise'>
  recurring: Period
}

type RawFreeTrialCoupon = {
  data: FreeTrialCoupon
}

export type FreeTrialCoupon = {
  coupon: string
  discount_amount: number
  discount_type: string
  from_package?: CouponRule
  to_package: CouponRule
  created_at?: number
}

export const useCheckFreeTrialCoupon = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const { t: tPayment } = useTranslation('payment')

  return useMutation<FreeTrialCoupon, AxiosError, Payload>(
    async ({ coupon, packageName, recurring }) => {
      const { data } = await fetchAPI<RawFreeTrialCoupon>({
        path: '/free_trial_coupon',
        method: 'get',
        params: {
          code: coupon,
          package: packageName,
          recurring,
        },
      })

      return data.data
    },
    {
      onSuccess() {
        toast({
          ...TOAST_CONFIG,
          description: tPayment('coupon.toast.applySuccess'),
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}
