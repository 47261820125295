import { useMemo } from 'react'
import { Column } from 'react-table'
import * as d3 from 'd3-format'

import { SelectionTable } from '@/components/DataDisplay/Table'

import { Domain } from '../types'
import { t } from '@/lib/helpers'
import { HandleSelectTableRow } from '@/components/DataDisplay/Table/types'

type Props = {
  data: Domain[]
  handleSelectRow: HandleSelectTableRow<Domain>
  limit: number
}

export const SelectDomainTable = (props: Props) => {
  const { data, handleSelectRow, limit } = props

  const columns: Array<Column<Domain>> = useMemo(() => {
    return [
      {
        Header: t('views.features.domains.index.domain_name'),
        accessor: 'url',
        sortKey: 'url',
        width: '40%',
        Cell: (cellProps) => {
          const data = cellProps.row.original

          return data.url
        },
      },
      {
        Header: t('views.features.folders.folder_name'),
        accessor: 'folder',
        sortKey: 'folder',
        width: '35%',
        Cell: (cellProps) => {
          const data = cellProps.row.original

          return data.folder.name
        },
      },
      {
        Header: t('views.features.domains.index.pageviews'),
        accessor: 'page_views_count',
        sortKey: 'page_views_count',
        width: '25%',
        Cell: (cellProps) => {
          const value = cellProps.value

          return d3.format(',d')(value)
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    const sortedDomains = [...data].sort(
      (a, b) => b.page_views_count - a.page_views_count,
    )

    return sortedDomains
  }, [data])

  const selectedRowIds = useMemo(() => {
    const result: {
      [k: string]: boolean
    } = {}

    // Pre-select row with page view
    for (let i = 0; i < limit; i += 1) {
      const current = memoizedData[i]
      if (!current || !current.page_views_count) break

      result[i] = true
    }

    return result
  }, [memoizedData])

  const memoizedSortBy = useMemo(
    () => [
      {
        id: 'page_views_count',
        desc: true,
      },
    ],
    [],
  )

  return (
    <SelectionTable<Domain>
      columns={columns}
      data={memoizedData}
      selectProps={{
        handleSelectRow,
        limit,
      }}
      initialState={{
        sortBy: memoizedSortBy,
        selectedRowIds,
      }}
    />
  )
}
