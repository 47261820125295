function baseStyle(props: Record<string, any>) {
  return {
    control: {
      bg: 'white',

      ...(props.alignItems === 'flex-start' && {
        mt: '4px',
      }),
    },
  }
}

export const Checkbox = {
  defaultProps: {
    colorScheme: 'brand',
  },
  baseStyle,
  sizes: {
    sm: {
      control: { h: 4, w: 4 },
      label: { fontSize: 'sm' },
      icon: { fontSize: '0.45rem' },
    },
  },
}
