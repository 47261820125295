import { Box, Button, Heading, Stack, Text, Textarea } from '@chakra-ui/react'

import { t } from '@/lib/helpers'

export type TwoFactorRecoveryCodeProps = {
  code: string[]
  onClose: () => void
}

export const TwoFactorRecoveryCode = (props: TwoFactorRecoveryCodeProps) => {
  const { code, onClose } = props

  return (
    <Box>
      <Stack sx={{ mb: '16px' }}>
        <Heading as="h3" sx={{ pb: 0, fontSize: '24px' }}>
          {t(
            'views.features.two_factor.recovery_code',
            undefined,
            'Recovery Code',
          )}
        </Heading>
        <Text sx={{ fontSize: '12px', color: 'gray.600' }}>
          {t(
            'views.features.two_factor.recovery_code_description',
            undefined,
            'In case you lose your phone or access to your one-time password, each of these recovery codes can be used once to regain access to your account.',
          )}
        </Text>
      </Stack>

      <Textarea
        sx={{
          border: '1px solid',
          borderColor: 'gray.300',
          borderRadius: 'md',
          p: '19px',
          mb: '8px',
        }}
        readOnly
        rows={10}
        defaultValue={code.join('\n')}
        resize="none"
      />
      <Text sx={{ mb: '24px', color: 'red.300' }}>
        {t(
          'views.features.two_factor.recovery_code_warning',
          undefined,
          'Please, download and save them in secure place, otherwise you will lose access to your account.',
        )}
      </Text>

      <Stack>
        <Button
          size="xl"
          type="submit"
          onClick={() => {
            saveFile(code.join('\n'), 'Recovery-code.txt')
          }}
        >
          {t(
            'views.features.two_factor.download_code',
            undefined,
            'Download Code',
          )}
        </Button>
        <Button
          size="xl"
          variant="ghost"
          colorScheme="gray"
          sx={{ color: 'gray.600' }}
          onClick={onClose}
        >
          {t('views.shared.button.close', undefined, 'Close')}
        </Button>
      </Stack>
    </Box>
  )
}

const saveFile = (str: string, fileName: string) => {
  const blob = new Blob([str], { type: 'text/plain;charset=utf-8' })

  const a = document.createElement('a')
  a.download = fileName
  a.href = URL.createObjectURL(blob)
  a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':')
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  setTimeout(function () {
    URL.revokeObjectURL(a.href)
  }, 1500)
}
