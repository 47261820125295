import { Dispatch, SetStateAction } from 'react'
import {
  Center,
  Flex,
  Icon,
  Input,
  ComponentWithAs,
  IconProps,
} from '@chakra-ui/react'
import { FiSmartphone } from 'react-icons/fi'
import { IconType } from 'react-icons/lib'

import { hiddenInputStyle } from './shared'
import { Screen } from '@/features/domains/types'
import { DesktopIcon, TabletIcon } from '@/components/Icons'

const screens: {
  id?: string
  icon: IconType | ComponentWithAs<'svg', IconProps>
  value: Screen
}[] = [
  { id: 'CustomizePreview-desktop', icon: DesktopIcon, value: 'desktop' },
  { id: 'CustomizePreview-tablet', icon: TabletIcon, value: 'tablet' },
  { id: 'CustomizePreview-mobile', icon: FiSmartphone, value: 'mobile' },
]

type Props = {
  screen: Screen
  setScreen: Dispatch<SetStateAction<Screen>>
}

export const ResponsiveSwitch = (props: Props) => {
  const { screen, setScreen } = props

  return (
    <Flex
      sx={{
        h: '48px',
        borderRadius: '4px',
      }}
    >
      {screens.map((sc) => {
        return (
          <ResponsiveRadioButton
            id={sc.id}
            icon={sc.icon}
            key={sc.value}
            value={sc.value}
            isChecked={screen === sc.value}
            onChange={setScreen}
          />
        )
      })}
    </Flex>
  )
}

type ResponsiveSwitchButtonProps = {
  value: Screen
  icon: IconType | ComponentWithAs<'svg', IconProps>
  isChecked: boolean
  onChange: any
  id?: string
}

const ResponsiveRadioButton = (props: ResponsiveSwitchButtonProps) => {
  const { value, icon, isChecked, onChange, id } = props

  return (
    <Center
      as="label"
      id={id}
      sx={{
        w: '116px',
        cursor: 'pointer',
        boxShadow: 'inset 0px 0px 0px 1px #e8e8e8',
        transition: 'box-shadow ease 0.2s, background-color ease 0.2s',
        _first: {
          borderRadius: '4px 0 0 4px',
        },
        _last: {
          borderRadius: '0 4px 4px 0',
        },
        ...(isChecked && {
          bg: 'brand.50',
          boxShadow: 'inset 0px 0px 0px 1px #ea834f',
        }),
      }}
    >
      <Input
        type="radio"
        name="screen"
        sx={hiddenInputStyle}
        onChange={() => onChange(value)}
      />
      <Icon
        as={icon}
        sx={{
          fontSize: '24px',
          transition: 'color ease 0.2s',
          ...(isChecked && {
            color: 'brand.400',
          }),
        }}
      />
    </Center>
  )
}
