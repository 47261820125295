import { useState, useMemo, useCallback } from 'react'
import {
  Button,
  Stack,
  Input,
  ModalBody,
  ModalHeader,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { t } from '@/lib/helpers'
import { useUpdateDomain } from '../../mutations'
import { URL_REGEX } from '../../schema'

import { Modal } from '@/components/shared/Modal'
import { FormControl } from '@/components/FormControl'

type Props = {
  disclosure: UseDisclosureReturn
  defaultValue: {
    folder_name: string
    name: string
    id: number
  }
}

export const EditDomainProject = (props: Props) => {
  const { disclosure, defaultValue } = props
  const { isOpen, onClose } = disclosure

  const [domainName, setDomainName] = useState(defaultValue.name)
  const [error, setError] = useState<'invalid' | 'empty' | undefined>()

  const { t: tCommon } = useTranslation('common')
  const { mutate: updateDomain, isLoading } = useUpdateDomain()

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'empty':
        return t('views.shared.input.required', undefined, 'Required')
      case 'invalid':
        return t('views.shared.form.invalid_url', undefined, 'Invalid URL')
      default:
        return ''
    }
  }, [error])

  const checkSchema = useCallback((url: string) => {
    if (!url) {
      setError('empty')
      return false
    }
    if (!URL_REGEX.test(url)) {
      setError('invalid')
      return false
    }

    setError(undefined)
    return true
  }, [])

  const closeModal = useCallback(() => {
    setDomainName(defaultValue.name)
    setError(undefined)
    onClose()
  }, [defaultValue.name])

  return (
    <Modal isOpen={isOpen} onClose={closeModal} maxW="560px" withCloseButton>
      <ModalHeader>{t('views.features.domains.index.edit_domain')}</ModalHeader>

      <ModalBody>
        <Stack spacing="6">
          <FormControl
            id="url"
            label={t(
              'views.features.domains.index.add_description',
              undefined,
              'Domain URL',
            )}
            errorMsg={errorMessage}
          >
            <Input
              tabIndex={1}
              placeholder="https://www.example.com or http://www.example.com"
              data-testid="url-input"
              id="url"
              value={domainName}
              onChange={(e) => {
                const value = e.target.value
                setDomainName(value)
                checkSchema(value)
              }}
            />
          </FormControl>

          <Stack spacing="2" sx={{ mt: '40px !important' }}>
            <Button
              colorScheme="brand"
              size="xl"
              isLoading={isLoading}
              data-testid=".edit-domain-submit"
              onClick={() => {
                const isPass = checkSchema(domainName)
                if (!isPass) return
                updateDomain(
                  {
                    data: {
                      url: domainName,
                      folder_name: defaultValue.folder_name,
                    },
                    isProjectPage: true,
                    params: {
                      projectId: defaultValue.id,
                    },
                  },
                  {
                    onSuccess: onClose,
                  },
                )
              }}
            >
              {tCommon('save')}
            </Button>

            <Button
              colorScheme="gray"
              variant="ghost"
              size="xl"
              color="gray.600"
              onClick={closeModal}
            >
              {t('views.shared.button.discard', undefined, 'Discard')}
            </Button>
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
