import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '@chakra-ui/button'
import { ModalBody, ModalHeader } from '@chakra-ui/modal'
import { Link, Stack, Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'

import AlreadySubImage from '@/images/free-trial/already-sub.svg'

import { Modal } from '@/components/shared'

type Props = {
  disclosure: UseDisclosureReturn
}

export const AlreadySubModal = (props: Props): React.ReactElement => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  const { t } = useTranslation('freeTrial')

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxW="524px"
      withCloseButton={false}
    >
      <Image
        src={AlreadySubImage}
        sx={{ w: '133px', h: '115px', mx: 'auto', mt: 8 }}
      />
      <ModalHeader
        sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 600, pb: 0 }}
      >
        {t('alreadySub.title')}
      </ModalHeader>

      <ModalBody sx={{ px: 8, pb: 4 }}>
        <Stack spacing={4}>
          <Text sx={{ textAlign: 'center' }}>
            <Trans t={t} i18nKey="alreadySub.description">
              การทดลองใช้ฟรีสามารถใช้งานได้สำหรับผู้ใช้งานที่ยังไม่เคยใช้
              แพ็กเกจใดๆ มาก่อนเท่านั้น
            </Trans>
          </Text>

          <Link href="/packages" sx={{ w: '100%' }}>
            <Button size="xl" sx={{ w: 'full' }}>
              {t('alreadySub.button')}
            </Button>
          </Link>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
