import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useErrorHandler } from '@/lib/hooks'

import { ScriptFormInput } from '../types'
import { TOAST_CONFIG } from '@/lib/constants'
import { COOKIE_CATEGORIES, PROJECT } from '../constants'

type Payload = {
  data: ScriptFormInput
  params: {
    project_id: number
    script_id: number
  }
}

const updateScript = async ({ data, params }: Payload) => {
  const { project_id, script_id } = params

  const response = await fetchAPI<Cookie>({
    path: `/projects/${project_id}/scripts/${script_id}`,
    method: 'put',
    data,
  })

  return response.data
}

export const useUpdateScript = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  return useMutation<Cookie, AxiosError, Payload>(updateScript, {
    onSuccess() {
      queryClient.invalidateQueries([COOKIE_CATEGORIES, params])
      queryClient.invalidateQueries([PROJECT, params])

      toast({
        ...TOAST_CONFIG,
        title: t('views.features.domains.show.manage.services.script_updated'),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
