import { Fragment, useMemo } from 'react'
import { get } from 'lodash'
import { Column } from 'react-table'
import { Box, Flex, Grid, Text, Tag } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { ConsentLog } from '../../types'

import { formatDateTime } from '@/lib/helpers'
import { useMemoDisplayDashboard } from '@/lib/hooks'

import { DataTable } from '@/components/DataDisplay/Table'
import { PaginationFilter } from '@/features/filters/components'

import { useConsents } from '../../queries'

type Props = {}

export const ConsentTable = (_: Props) => {
  const { t } = useTranslation('domainConsent')

  const isDisplayDashboard = useMemoDisplayDashboard()

  const { data: consents, isFetched } = useConsents({
    enabled: isDisplayDashboard,
  })

  const columns: Array<Column<ConsentLog>> = useMemo(() => {
    return [
      {
        Header: 'Timestamp',
        accessor: 'created_at',
        disableSortBy: true,
        width: '20%',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: 'Session Id',
        accessor: 'session_id',
        disableSortBy: true,
        width: '25%',
      },
      {
        Header: 'Ip Address',
        accessor: 'ip_address',
        disableSortBy: true,
        width: '20%',
      },
      {
        Header: 'Action',
        accessor: 'remember_choice',
        sortKey: 'remember_choice',
        width: '20%',
        Cell: ({ value }) => {
          return <Text>{t(`acceptedStatus.${value}`)}</Text>
        },
      },
      {
        Header: 'Category',
        accessor: 'category',
        disableSortBy: true,
        width: '15%',
      },
      {
        Header: 'Version',
        accessor: 'banner_version',
        disableSortBy: true,
        width: '15%',
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return consents?.data || []
  }, [consents?.data])

  return (
    <Fragment>
      <DataTable isLoading={!isFetched} data={memoizedData} columns={columns} />

      {consents?.meta.total && consents?.meta.per ? (
        <Flex sx={{ justifyContent: 'center' }}>
          <PaginationFilter
            totalPage={Math.ceil(consents.meta.total / consents.meta.per)}
          />
        </Flex>
      ) : null}
    </Fragment>
  )
}

type AcceptStatusBadgeListProps = {
  data: ConsentLog['consents']
}

const AcceptStatusBadgeList = (props: AcceptStatusBadgeListProps) => {
  const { data } = props

  const { t } = useTranslation('domainConsent')

  if (!data) return null

  return (
    <Grid
      sx={{
        gridTemplateColumns: 'repeat(2, max-content)',
        gap: '8px',
        alignItems: 'center',
        fontSize: 'xs',
      }}
      className="accept-status-badge-list"
    >
      {data.map((datum, idx) => {
        const choice = t(`acceptedStatus.${datum.remember_choice}`)

        return (
          <Box key={idx} sx={{ d: 'contents', textTransform: 'capitalize' }}>
            <Tag
              variant="subtle"
              colorScheme={
                {
                  allow: 'green',
                  deny: 'red',
                  informed: 'orange',
                  dismiss: 'gray',
                }[datum.remember_choice]
              }
              sx={{
                fontWeight: 'light',
                justifySelf: 'start',
                fontSize: 'inherit',
                textTransform: 'capitalize',
                ...{
                  allow: {
                    color: 'green.400',
                    bg: 'green.50',
                  },
                  deny: {
                    color: 'red.400',
                    bg: 'red.50',
                  },
                  dismiss: {
                    color: 'gray.400',
                    bg: 'gray.50',
                  },
                  informed: {
                    color: 'orange.400',
                    bg: 'orange.50',
                  },
                }[datum.remember_choice],
              }}
            >
              {choice}
            </Tag>

            <Text>{datum.category}</Text>
          </Box>
        )
      })}
    </Grid>
  )
}
