import { memo } from 'react'
import { observer } from 'mobx-react-lite'
import state from './mobxState'
import { Modal } from './Modal'

const ObservedModal = observer(() => {
  return (
    <Modal
      maxW={state.maxW}
      withCloseButton={state.showCloseButton}
      isOpen={state.isShown}
      onClose={state.hideModal}
      isCentered={state.isCentered}
    >
      {state.child}
    </Modal>
  )
})

export default memo(ObservedModal)
