const colors = {
  brand: {
    25: '#fffaf1',
    50: '#ffede0',
    100: '#faceb8',
    200: '#f2af8c',
    300: '#ec8f60',
    400: '#ea834f',
    500: '#ea834f',
    600: '#e66f33',
    700: '#9f4213',
    800: '#732f0d',
    900: '#461b04',
  },
  secondary: {
    50: '#fdf0e9',
    100: '#e0d7d0',
    200: '#c8beb7',
    300: '#b0a59b',
    400: '#998b80',
    500: '#7f7166',
    600: '#564C44',
    700: '#483f37',
    800: '#2d261f',
    900: '#120d00',
  },
  red: {
    50: '#ffe4e4',
    100: '#ffb4b5',
    200: '#fa8586',
    300: '#f65050',
    400: '#f32726',
    500: '#d90f0c',
    600: '#aa0909',
    700: '#7a0405',
    800: '#4b0102',
    900: '#1f0000',
  },
  gray: {
    50: '#f8f8f8',
    100: '#f6f6f6',
    200: '#e8e8e8',
    300: '#cccccc',
    400: '#b8b8b8',
    500: '#a3a3a3',
    600: '#888888',
    700: '#474747',
    800: '#3d3d3d',
    900: '#2b2b2b',
  },
  green: {
    50: '#dffcf2',
    100: '#beefdd',
    200: '#9ae3c7',
    300: '#73d6b2',
    400: '#60d0a6',
    500: '#60d0a6',
    550: '#15C65B',
    600: '#34b182',
    700: '#268a65',
    800: '#176247',
    900: '#073c2a',
  },
  blue: {
    50: '#ddeeff',
    100: '#b7d9fb',
    200: '#8dbff2',
    300: '#60a5eb',
    400: '#368ce5',
    500: '#1e72cb',
    600: '#14599f',
    700: '#0a3f72',
    800: '#002647',
    900: '#000e1d',
  },
  yellow: {
    50: '#fff9db',
    100: '#ffeeaf',
    200: '#ffe27f',
    300: '#ffd74f',
    400: '#ffcb1e',
    500: '#e6b106',
    600: '#b38a00',
    700: '#806300',
    800: '#4e3b00',
    900: '#1d1400',
  },
}

export default colors
