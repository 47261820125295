import { t } from '@/lib/helpers'
import { RoleSelectOptionType } from './types'

export const ROLE_OPTIONS: RoleSelectOptionType[] = [
  {
    value: 'viewer',
    label: 'Viewer',
    description: t(
      'views.features.users.index.viewer_description',
      undefined,
      `Viewer can view consent records, view cookie and banner settings and get the banner script in all domains in assigned folder but unable to edit or customize the banner.`,
    ),
  },
  {
    value: 'editor',
    label: 'Editor',
    description: t(
      'views.features.users.index.editor_description',
      undefined,
      `Editor can manage cookies, customize banners and manage users in all domains in assigned folder.
    but unable to manage subscription.`,
    ),
  },
]
