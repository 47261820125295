import React from 'react'
import { Box, Flex, VStack, Text, Image } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { CouponDisplay } from './CouponDisplay'
import Cookie from '@/images/cookies/cookie.svg'
import { FreeTrialCoupon } from '../../mutations'

type ActiveCouponObj = {
  discountDisplay: string
}

interface CouponTimerProps {
  timeLeft: number
  activeCouponObj: ActiveCouponObj
  activeCoupon: FreeTrialCoupon
  onUseCoupon: () => void
}

export const CouponTimer: React.FC<CouponTimerProps> = ({
  timeLeft,
  activeCoupon,
  activeCouponObj,
  onUseCoupon,
}) => {
  const { t } = useTranslation(['freeTrial'])

  function formatTime(ms: number) {
    const hours = Math.floor(ms / 3600000)
      .toString()
      .padStart(2, '0')
    const minutes = Math.floor((ms % 3600000) / 60000)
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor((ms % 60000) / 1000)
      .toString()
      .padStart(2, '0')

    return { hours, minutes, seconds }
  }

  const { hours, minutes, seconds } = formatTime(timeLeft)

  return (
    <VStack
      sx={{
        backgroundColor: 'brand.50',
        p: 4,
        borderRadius: 'md',
      }}
    >
      <Flex alignItems="center">
        <Image src={Cookie} sx={{ boxSize: '16px', mr: 2 }} />
        <Text sx={{ fontWeight: 'bold', color: 'brand.400' }}>
          {t('orderSummary.timer.newUserCode')}&nbsp;
        </Text>
        <Text sx={{ fontWeight: 700 }}>{t('orderSummary.timer.freeBy')}</Text>
      </Flex>

      <Flex align="center" sx={{ pt: 2 }}>
        <DigitGroup digits={hours} unit={t('orderSummary.timer.time.hour')} />
        <Colon />
        <DigitGroup
          digits={minutes}
          unit={t('orderSummary.timer.time.minute')}
        />
        <Colon />
        <DigitGroup
          digits={seconds}
          unit={t('orderSummary.timer.time.second')}
        />
      </Flex>

      <CouponDisplay
        coupon={activeCoupon.coupon}
        discount={activeCouponObj.discountDisplay}
        onUseCoupon={onUseCoupon}
      />
    </VStack>
  )
}

const Colon: React.FC = () => (
  <Text sx={{ fontWeight: 700, mx: '6px', mt: -4 }}>:</Text>
)

interface DigitGroupProps {
  digits: string
  unit: string
}

const DigitGroup: React.FC<DigitGroupProps> = ({ digits, unit }) => (
  <Flex direction="column" align="center">
    <Flex>
      <DigitBox>{digits[0]}</DigitBox>
      <DigitBox>{digits[1]}</DigitBox>
    </Flex>
    <Text sx={{ fontSize: '10px', mt: 1 }}>{unit}</Text>
  </Flex>
)

const DigitBox: React.FC = ({ children }) => (
  <Box
    sx={{
      w: '32px',
      h: '32px',
      p: '4',
      mx: '1px',
      fontWeight: 'bold',
      backgroundColor: 'white',
      borderRadius: 'md',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {children}
  </Box>
)
