import { Fragment, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { Box, Link, Stack, Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'
import { Tooltip } from '@chakra-ui/tooltip'
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/hooks'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import Icon from '@chakra-ui/icon'

import { BannerLanguage, Category, Cookie, CookieType } from '../../types'
import { t } from '@/lib/helpers'

import { Can } from '@/features/permissions/components'
import { DataTable } from '@/components/DataDisplay/Table'
import { EditCookieModal } from './EditCookieModal'
import { EditScriptModal } from './EditScriptModal'
import { DeleteScriptModal } from './DeleteScriptModal'
import { DeleteCookieModal } from './DeleteCookieModal'

import AddCookieIcon from '@/icons/add-cookie-script.svg'

type Props = {
  activeLanguage: BannerLanguage
  currentCategory: Category
  data: Cookie[]
  disclosure: {
    addCookieDisclosure: UseDisclosureReturn
    addScriptDisclosure: UseDisclosureReturn
  }
  filterType: CookieType
}

export const CookieTable = (props: Props) => {
  const { activeLanguage, currentCategory, data, disclosure, filterType } =
    props
  const { addCookieDisclosure, addScriptDisclosure } = disclosure

  const deleteCookieDisclosure = useDisclosure()
  const deleteScriptDisclosure = useDisclosure()
  const editCookieDisclosure = useDisclosure()
  const editScriptDisclosure = useDisclosure()

  const [actionData, setActionData] = useState<Cookie>()

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  const columns: Array<Column<Cookie>> = useMemo(() => {
    return filterType === 'cookie'
      ? [
          {
            Header: t('views.features.domains.show.manage.form.name'),
            accessor: 'name',
            sortKey: 'name',
            width: '20%',
          },
          {
            Header: `${t(
              'views.features.domains.show.manage.form.domain',
            )} / ${t('views.misc.url')}`,
            accessor: 'url',
            sortKey: 'url',
            width: '50%',
            Cell: ({ value }) => <Text color="gray.600">{value}</Text>,
          },
          {
            Header: t('views.features.domains.show.manage.form.duration'),
            accessor: 'duration',
            sortKey: 'duration',
            width: '15%',
            Cell: ({ value }) => (
              <Text color="gray.600">{value?.[activeLanguage] ?? '-'}</Text>
            ),
          },
          {
            Header: '',
            accessor: 'store_type',
            sortKey: 'store_type',
            width: '0%',
            Cell: () => null,
          },
        ]
      : [
          {
            Header: t('views.features.domains.show.manage.form.name'),
            accessor: 'name',
            sortKey: 'name',
            width: '20%',
          },
          {
            Header: `${t(
              'views.features.domains.show.manage.form.domain',
            )} / ${t('views.misc.url')}`,
            accessor: 'url',
            sortKey: 'url',
            width: '65%',
            Cell: ({ value }) => <Text color="gray.600">{value}</Text>,
          },
          {
            Header: '',
            accessor: 'store_type',
            sortKey: 'store_type',
            width: '0%',
            Cell: () => null,
          },
        ]
  }, [filterType, activeLanguage])

  return (
    <Fragment>
      <DataTable
        data={memoizedData}
        columns={columns}
        autoResetFilters={false}
        autoResetPage={false}
        autoResetSortBy={false}
        renderNoData={() => {
          return (
            <Stack
              spacing="12px"
              sx={{ mt: '93px', mb: '145px', alignItems: 'center' }}
            >
              <Image src={AddCookieIcon} width="88px" />
              <Text>
                {t('views.features.domains.show.manage.no')}
                {filterType === 'cookie'
                  ? t('views.misc.cookie')
                  : t('views.misc.scripts')}
                &nbsp;
                <Can I="create" a="cookie">
                  {() => (
                    <Link
                      id="Cookie-add"
                      as="button"
                      color="brand.400"
                      fontWeight="bold"
                      textDecoration="underline !important"
                      onClick={
                        filterType === 'cookie'
                          ? addCookieDisclosure.onOpen
                          : addScriptDisclosure.onOpen
                      }
                    >
                      {filterType === 'cookie'
                        ? t('views.features.domains.show.manage.add_new_cookie')
                        : t(
                            'views.features.domains.show.manage.add_new_script',
                          )}
                    </Link>
                  )}
                </Can>
              </Text>
            </Stack>
          )
        }}
        renderActionColumn={{
          width: '15%',
          Cell: (cookie) => {
            return (
              <Stack spacing="16px" direction="row" alignItems="center">
                <Can I="update" a="cookie">
                  {() => (
                    <Tooltip
                      hasArrow
                      label={
                        filterType === 'cookie'
                          ? t(
                              'views.features.domains.show.manage.form.edit_cookie',
                            )
                          : t(
                              'views.features.domains.show.manage.form.edit_script',
                            )
                      }
                      bg="#2b2b2b"
                      size="lg"
                    >
                      <Box
                        as="button"
                        id={`${
                          filterType === 'cookie' ? 'Cookie' : 'Script'
                        }-edit`}
                        onClick={() => {
                          setActionData(cookie)
                          if (filterType === 'cookie') {
                            editCookieDisclosure.onOpen()
                          } else {
                            editScriptDisclosure.onOpen()
                          }
                        }}
                      >
                        <Icon as={FiEdit2} color="brand.400" fontSize="20px" />
                      </Box>
                    </Tooltip>
                  )}
                </Can>

                <Can I="delete" a="cookie">
                  {() => (
                    <Tooltip
                      hasArrow
                      label={`${
                        cookie.scraped
                          ? t('views.features.domains.show.manage.uncategorize')
                          : t('views.shared.button.delete')
                      }${t('views.misc.space_en')}${
                        filterType === 'cookie'
                          ? t('views.misc.cookie_low')
                          : t('views.misc.script_low')
                      }`}
                      bg="#2b2b2b"
                      size="lg"
                    >
                      <Box
                        as="button"
                        id={`${
                          filterType === 'cookie' ? 'Cookie' : 'Script'
                        }-delete`}
                        onClick={() => {
                          setActionData(cookie)
                          if (filterType === 'cookie') {
                            deleteCookieDisclosure.onOpen()
                          } else {
                            deleteScriptDisclosure.onOpen()
                          }
                        }}
                      >
                        <Icon as={FiTrash2} color="red.400" fontSize="20px" />
                      </Box>
                    </Tooltip>
                  )}
                </Can>
              </Stack>
            )
          },
        }}
        renderSubRow={(cookie) => {
          return (
            <Stack
              spacing="6px"
              alignItems="stretch"
              sx={{ alignItems: 'stretch', color: 'gray.600' }}
            >
              <Text
                sx={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                }}
              >
                {t('views.shared.form.description')}
              </Text>

              <Text>{cookie.purpose[activeLanguage] || '-'}</Text>
            </Stack>
          )
        }}
        filterProps={{
          columnId: 'store_type',
          filterType,
        }}
      />

      {actionData && (
        <Fragment>
          <EditCookieModal
            defaultValues={actionData}
            disclosure={editCookieDisclosure}
            activeLanguage={activeLanguage}
          />
          <DeleteCookieModal
            cookie={actionData}
            disclosure={deleteCookieDisclosure}
            categoryName={currentCategory.name[activeLanguage]}
            activeLanguage={activeLanguage}
          />

          <EditScriptModal
            defaultValues={actionData}
            disclosure={editScriptDisclosure}
            activeLanguage={activeLanguage}
          />
          <DeleteScriptModal
            activeLanguage={activeLanguage}
            script={actionData}
            disclosure={deleteScriptDisclosure}
            categoryName={currentCategory.name[activeLanguage]}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
