import { UseToastOptions } from '@chakra-ui/react'

import { t } from '../helpers'

export const TOAST_CONFIG: UseToastOptions = {
  isClosable: true,
  position: 'top-right',
  status: 'success',
  title: t('views.shared.toast.success.title'),
  variant: 'left-accent',
}

export const ERROR_TOAST_CONFIG: UseToastOptions = {
  ...TOAST_CONFIG,
  status: 'error',
  title: t('views.shared.toast.error.title'),
}
