import {
  Icon,
  Flex,
  Box,
  HStack,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { AiOutlineSearch } from 'react-icons/ai'
import { get } from 'lodash'

import { Can } from '@/features/permissions/components'
import { ConsentCsvDownload, ConsentTable } from '../../components'

import { useDebounce } from '@/lib/hooks'

import { PollingStatus } from '../../../types'

import { useFilterContext } from '@/features/filters/context'
import { useMemoDisplayDashboard } from '@/lib/hooks'
import { useConsents } from '../../queries'

type ConsentLogContainerProps = {
  project?: Project
  downloadStatus: PollingStatus
  hasConsent: boolean
}

export const ConsentLogContainer = (props: ConsentLogContainerProps) => {
  const { project, downloadStatus, hasConsent } = props

  const { t } = useTranslation('domainConsent')
  const { t: tCommon } = useTranslation('common')

  const { setFilter } = useFilterContext()

  const debounceHandleChange = useDebounce((value: string) => {
    setFilter('search', value)
  })

  const isDisplayDashboard = useMemoDisplayDashboard()

  const { data: consents } = useConsents({
    enabled: isDisplayDashboard,
  })

  const rememberChoices: Record<string, number> = get(
    consents,
    'meta.remember_choices',
    {},
  )
  const category: Record<string, number> = get(
    consents,
    'meta.consent_categories',
    {},
  )

  return (
    <Box id="dashboard-log-section">
      <Flex sx={{ justifyContent: 'space-between' }}>
        <HStack spacing="6">
          <Text fontWeight="bold">{t('consentLog')}</Text>

          <HStack spacing="4">
            <InputGroup sx={{ flex: '1 0 280px' }} size="sm">
              <InputLeftElement pointerEvents="none">
                <Icon as={AiOutlineSearch} color="gray.600" />
              </InputLeftElement>
              <Input
                placeholder={tCommon('search')}
                onChange={(e) => debounceHandleChange(e.target.value)}
              />
            </InputGroup>

            <Select
              size="sm"
              onChange={(e) => {
                setFilter('remember_choice', e.target.value)
              }}
              sx={{ w: '280px', flex: '1 0 280px' }}
              id="consent-action-select"
            >
              <option value="">{tCommon('all')}</option>
              {Object.entries(rememberChoices).map(([key, value]) => (
                <option value={value} key={value}>
                  {t(`acceptedStatus.${key}`)}
                </option>
              ))}
            </Select>

            <Select
              size="sm"
              onChange={(e) => {
                setFilter('category_id', e.target.value)
              }}
              sx={{ w: '280px', flex: '1 0 280px' }}
              id="consent-action-select"
            >
              <option value="">{tCommon('all')}</option>
              {Object.entries(category).map(([key, value]) => (
                <option value={value} key={value}>
                  {key}
                </option>
              ))}
            </Select>
          </HStack>
        </HStack>

        {project && hasConsent && (
          <Can I="export" a="consent">
            {() => (
              <ConsentCsvDownload
                project={project}
                downloadStatus={downloadStatus}
              />
            )}
          </Can>
        )}
      </Flex>

      <Box mt="4">
        <ConsentTable />
      </Box>
    </Box>
  )
}
