import { Grid, Stack, StackDivider, useRadioGroup } from '@chakra-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import type { Position as PositionType } from '@datawowio/cookie-banner-ui'

import { useAppContext } from '@/features/layout'

import { positions } from './positions'
import { PositionRadioButton } from '../../PositionRadioButton'
import { ZIndexInput } from './ZIndexInput'

const freePositions: PositionType[] = [
  'classic-top',
  'classic-bottom',
  'fullwidth-top',
  'fullwidth-bottom',
]

export const Position = () => {
  const { isFree } = useAppContext()

  const position = useWatch({ name: 'position' })
  const { setValue } = useFormContext()

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'position',
    defaultValue: position,
    onChange: (value) => setValue('position', value),
  })

  return (
    <Stack spacing="6" divider={<StackDivider borderColor="brand.50" />}>
      <Grid
        sx={{
          gridTemplateColumns: '1fr 1fr',
          gridAutoRows: '156px',
          gap: '24px',
          ...getRootProps(),
        }}
      >
        {positions.map((p) => {
          return (
            <PositionRadioButton
              {...getRadioProps({ value: p.value })}
              id={p.id}
              key={p.value}
              title={p.title}
              img={p.img}
              disabled={isFree && !freePositions.includes(p.value)}
            />
          )
        })}
      </Grid>

      <ZIndexInput />
    </Stack>
  )
}
