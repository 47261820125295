import {
  Box,
  Text,
  Button,
  Link,
  useToast,
  Tooltip,
  Flex,
  Divider,
  useClipboard,
  Stack,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import Frame, { FrameContextConsumer } from 'react-frame-component'
import {
  Banner,
  BannerProvider,
  CookieCategory,
  defaultTheme,
} from '@datawowio/cookie-banner-ui'
import { CacheProvider } from '@emotion/react'

import { withPage } from '@/features/layout'
import { memoizedCreateCacheWithContainer } from '../../Customize/components'

import { copyText, t } from '@/lib/helpers'
import {
  DEFAULT_CONTENT_TEMPLATE,
  mockBannerText,
} from '@/features/domains/constants'
import { logEvent } from '@/lib/stats'

type Props = {
  project: Project
  script: string
  cookieCategories: CookieCategory[]
}

function DomainPreviewPage(props: Props) {
  const { project, script } = props

  const toast = useToast()
  const { hasCopied, onCopy } = useClipboard(project.token)

  const handleCopy = () => {
    copyText(script, () =>
      toast({
        description: t('views.misc.copied'),
        status: 'success',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      }),
    )
  }

  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent">
        <Box
          layerStyle="sectionHeader"
          sx={{
            d: 'grid',
            justifyContent: 'space-between',
            gridTemplateColumns: 'repeat(2, max-content)',
            border: 'none',
          }}
        >
          <Box>
            <Text>
              {t('views.features.domains.show.preview.consent_for')}&nbsp;
              <Link isExternal href={project.url} color="brand.400">
                {project.url}
              </Link>
            </Text>
          </Box>

          <Tooltip
            placement="top"
            label={t('views.features.domains.show.preview.skip_tooltip')}
          >
            <Button
              id="Preview-dashboard"
              colorScheme="brand"
              size="lg"
              variant="outline"
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'Preview',
                    action: 'Click dashboard',
                  },
                })
                window.location.href = `/projects/${project.id}/dialogs`
              }}
            >
              {t('views.features.domains.show.preview.to_dashboard')}
            </Button>
          </Tooltip>
        </Box>

        <Divider />

        <Box layerStyle="sectionContent">
          <Box sx={{ mt: '24px', mb: '16px' }}>
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 6,
              }}
            >
              <Text>{t('views.features.domains.show.preview.done')}</Text>
              <Button
                id="Preview-copyScript"
                colorScheme="brand"
                size="lg"
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Preview',
                      action: 'Click copy script',
                    },
                  })
                  handleCopy()
                }}
                sx={{ minW: '144px' }}
              >
                {t('views.features.domains.show.preview.copy_cookie')}
              </Button>
            </Flex>

            <Stack spacing={4}>
              <Script>{script}</Script>

              <Flex justify="space-between">
                <Stack sx={{ mb: 6 }}>
                  <Text>
                    {t('views.features.domains.show.preview.copy_desc')}
                  </Text>

                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      p: '7px 15px',
                      border: '1px solid',
                      borderColor: 'gray.300',
                      borderRadius: '8px',
                      bg: 'gray.100',
                      w: '600px',
                    }}
                  >
                    <Text id="Preview-copyID">
                      {hasCopied ? (
                        <Text as="span" sx={{ color: 'brand.400' }}>
                          {t('views.misc.copied')}
                        </Text>
                      ) : (
                        project.token
                      )}
                    </Text>
                    <Button variant="link" onClick={onCopy}>
                      {t('views.shared.button.copy')}
                    </Button>
                  </Flex>
                </Stack>

                <Link
                  id="Customize-gaLink"
                  isExternal
                  href="https://help.cookiewow.com/th/article/google-analytics-anonymous-google-analytics-gtm-15c7rdb/"
                  color="brand.400"
                >
                  {t('views.features.domains.show.modal.how_to_use_ga')}
                </Link>
              </Flex>
            </Stack>
          </Box>

          <Text sx={{ mb: '16px', fontWeight: 500, fontSize: '24px' }}>
            Preview
          </Text>
          <Flex
            sx={{
              alignItems: 'center',
              bg: 'gray.100',
              py: '16px',
            }}
          >
            <Preview />
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default withPage(DomainPreviewPage)

const Script = styled.pre({
  backgroundColor: '#f6f6f6',
  border: '1px solid #e8e8e8',
  borderRadius: '8px',
  height: '165px',
  padding: '29px 32px',
  whiteSpace: 'pre-wrap',
  overflowX: 'auto',
  fontSize: '16px',
  lineHeight: '25.6px',
})

const Preview = () => {
  return (
    <Frame width="100%" height="165px">
      <FrameContextConsumer>
        {({ document }) => {
          if (!document) return null
          return (
            <CacheProvider
              value={memoizedCreateCacheWithContainer(document.head)}
            >
              <BannerProvider
                theme={{
                  ...defaultTheme,
                  colors: {
                    ...defaultTheme.colors,
                    primary: '#3d98f3',
                  },
                }}
                bannerText={mockBannerText['en']}
                emotionContainer={document.head}
                logoType="cookiewow"
                showFooterLogo
                showPreferenceHeaderLogo
              >
                <Banner
                  title={DEFAULT_CONTENT_TEMPLATE['en']['short'].title}
                  description={DEFAULT_CONTENT_TEMPLATE['en']['short'].content}
                />
              </BannerProvider>
            </CacheProvider>
          )
        }}
      </FrameContextConsumer>
    </Frame>
  )
}
