import { get } from 'lodash'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { USERS } from '../constants'
import { User } from '../types'

const getUsers = async (): Promise<User[]> => {
  const response = await fetchAPI({
    path: '/user_managements',
  })

  const data = get(response, 'data', [])

  return data
}

export const useUsers = ({ initialData }: { initialData: User[] }) => {
  return useQuery<User[], AxiosError>({
    queryKey: [USERS],
    queryFn: () => getUsers(),
    initialData,
  })
}
