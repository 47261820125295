import { ReactElement } from 'react'
import { Stack, Text } from '@chakra-ui/layout'

const baseStyle = {
  borderRadius: '4px',
  border: '1px solid',
  borderColor: 'gray.200',
  color: 'gray.900',
  fontWeight: 400,
  fontSize: '14px',
}

type Props = {
  description: React.ReactNode
  detail: React.ReactNode
}

export const DomainDisplayDescription = (props: Props): ReactElement => {
  const { description, detail } = props

  return (
    <Stack
      sx={{
        ...baseStyle,
        p: '16px 8px',
        bg: 'gray.50',
      }}
    >
      <Text>{description}</Text>
      <Stack
        sx={{
          ...baseStyle,
          p: '4px 8px',
          bg: 'white',
        }}
      >
        <Text sx={{ wordBreak: 'break-word' }}>{detail}</Text>
      </Stack>
    </Stack>
  )
}
