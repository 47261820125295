import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { UnsubscribeReasonFormInput } from '../types'

type Payload = {
  data: Pick<UnsubscribeReasonFormInput, 'reason'>
}

const unsubscribe = async ({ data }: Payload) => {
  return fetchAPI({
    path: '/packages/cancel',
    method: 'delete',
    data,
  })
}

export const useUnsubscribe = () => {
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse, AxiosError, Payload>(unsubscribe, {
    onSuccess() {
      window.setTimeout(() => {
        window.location.reload()
      }, 2000)
    },
    onError(error) {
      handleError(error)
    },
  })
}
