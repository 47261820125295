import { ReactNode, useMemo } from 'react'
import { isNull } from 'lodash'
import {
  Image,
  Box,
  Text,
  Tooltip,
  Grid,
  HStack,
  Link,
  Icon,
  Flex,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { FiArrowLeft, FiEdit2 } from 'react-icons/fi'
import { MdOutlineError } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

import { t } from '@/lib/helpers'
import { useProject } from '../queries'

import { PollingStatus } from '@/features/types'

import { Can } from '@/features/permissions/components'
import { TabNavigation } from '@/components/shared'
import { BannerStatus } from './BannerStatus'
import { PublishVersionSection } from '../containers'
import { EditDomainModal } from './EditDomainModal'
import { EditDomainProject } from './EditDomainModal'
import { SurveyModal } from './SurveyModal'
import { SurveyProvider } from './SurveyModal/context'

import CookieHelpDesk from '@/images/project/cookie-helpdesk.png'

type Props = {
  buttons?: ReactNode
  children: ReactNode
  project: Project
  bannerPollingStatus?: PollingStatus
  linkTitle?: string
  linkHref?: string
  surveyUrl: string
}

export const DomainShowLayout = (props: Props) => {
  const {
    buttons,
    children,
    bannerPollingStatus,
    linkTitle,
    linkHref,
    surveyUrl,
  } = props
  const { id } = props.project
  const disclosure = useDisclosure()
  const editDomainDisclosure = useDisclosure()

  const { t: tDomains } = useTranslation('domains')

  const { data: project } = useProject({
    params: { project_id: id },
    initialData: props.project,
  })

  const navItems = useMemo(() => {
    return [
      {
        name: tDomains('layout.tabs.consent'),
        href: `/projects/${id}/consents`,
        checkActive: ['domainConsent'],
      },
      {
        name: tDomains('layout.tabs.scanner'),
        href: `/projects/${id}/cookies`,
        checkActive: ['domainCookiesScanner'],
      },
      {
        name: tDomains('layout.tabs.manage'),
        href: `/projects/${id}/manage`,
        checkActive: ['domainManageCookies'],
      },
      {
        name: tDomains('layout.tabs.customize'),
        href: `/projects/${id}/dialogs`,
        checkActive: ['domainCustomize'],
      },
      {
        name: tDomains('layout.tabs.bannerSettings'),
        href: `/projects/${id}/banner_setting`,
        checkActive: ['domainBannerSettings'],
      },
    ]
  }, [id])

  return (
    <Box layerStyle="container" sx={{ pb: '40px' }}>
      <SurveyProvider disclosure={disclosure}>
        <Flex sx={{ justifyContent: 'space-between', m: '12px 0 10px' }}>
          <Link
            id="Layout-domain"
            sx={{
              d: 'grid',
              gap: '8px',
              gridTemplateColumns: 'max-content 1fr',
              alignItems: 'center',
              fontWeight: 'bold',
              color: 'brand.400',
              w: 'max-content',
            }}
            href="/projects"
          >
            <Icon
              as={FiArrowLeft}
              sx={{ color: 'brand.400', fontSize: '24px' }}
            />
            {t('views.features.domains.show.back_to')}
          </Link>

          {project?.draft_version && (
            <PublishVersionSection draftVersion={project.draft_version} />
          )}
        </Flex>

        <Box layerStyle="mainContent" sx={{ mt: 0 }}>
          <Box
            layerStyle="sectionHeader"
            sx={{ border: 'none', minH: '122px' }}
          >
            <Flex sx={{ justifyContent: 'space-between' }}>
              <Stack>
                <HStack>
                  {!project?.registered_domain && (
                    <Tooltip
                      hasArrow
                      shouldWrapChildren
                      label={t('views.features.domains.show.domain_notfound')}
                      placement="top"
                      textAlign="center"
                    >
                      <Icon
                        as={MdOutlineError}
                        sx={{
                          cursor: 'pointer',
                          boxSize: '20px',
                          color: '#FFD79D',
                        }}
                      />
                    </Tooltip>
                  )}

                  <Text
                    isTruncated
                    sx={{
                      fontWeight: 'semibold',
                      maxW: '858px',
                    }}
                  >
                    {project?.url}
                  </Text>
                  <Can I="update" a="project">
                    {() => (
                      <Icon
                        as={FiEdit2}
                        sx={{
                          cursor: 'pointer',
                          boxSize: '20px',
                          color: 'brand.400',
                        }}
                        onClick={editDomainDisclosure.onOpen}
                      />
                    )}
                  </Can>
                </HStack>
                {project &&
                  (isNull(bannerPollingStatus) || bannerPollingStatus) && (
                    <BannerStatus
                      project={project}
                      bannerPollingStatus={bannerPollingStatus}
                    />
                  )}
              </Stack>

              {buttons}
            </Flex>
          </Box>

          <Flex
            sx={{
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: 'brand.25',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TabNavigation routes={navItems} />

            {linkHref && linkTitle && (
              <Grid sx={{ gridTemplateColumns: '22px 1fr', gap: '4px', pr: 8 }}>
                <Image src={CookieHelpDesk} />

                <Link
                  isExternal
                  href={linkHref}
                  sx={{
                    color: 'brand.400',
                    fontSize: '14px',
                    textDecor: 'underline',
                  }}
                >
                  {linkTitle}
                </Link>
              </Grid>
            )}
          </Flex>

          <Box layerStyle="sectionContent" sx={{ p: '0' }}>
            {children}
          </Box>

          {project && (
            <EditDomainProject
              disclosure={editDomainDisclosure}
              defaultValue={{
                folder_name: project.folder.name,
                name: project.url,
                id: project.id,
              }}
            />
          )}

          <SurveyModal disclosure={disclosure} surveyUrl={surveyUrl} />
        </Box>
      </SurveyProvider>
    </Box>
  )
}
