import { Box, Center, HStack, Text } from '@chakra-ui/react'

import { t } from '@/lib/helpers'
import { useAppContext } from '@/features/layout'
import { useActiveRoute } from '@/lib/routes'
import { useUserSecurity } from '../../queries'

import { Subjects } from '@/features/permissions/types'

import { Can } from '@/features/permissions/components'

type Props = {
  children: React.ReactNode
}

export const SettingLayout = (props: Props): React.ReactElement => {
  const { children } = props

  const { currentUser } = useAppContext()
  const checkActiveRoute = useActiveRoute()
  useUserSecurity()

  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent">
        <Box layerStyle="sectionHeader">
          <Text
            sx={{
              color: 'gray.600',
              fontSize: '14px',
              textTransform: 'uppercase',
              fontWeight: 700,
            }}
          >
            {t('views.features.settings.user')}
          </Text>
          {currentUser.email}
        </Box>

        <HStack
          sx={{ h: '50px', borderBottom: '1px solid', borderColor: 'brand.50' }}
        >
          {tabs.map((t) => {
            const isActive = checkActiveRoute(t.checkActive)

            if (t.feature) {
              return (
                <Can I="read" a={t.feature} key={t.name}>
                  {() => (
                    <Center
                      as="a"
                      href={t.href}
                      sx={{
                        h: 'full',
                        cursor: 'pointer',
                        px: '28px',
                        ...(isActive && {
                          color: 'brand.400',
                          fontWeight: 600,
                          boxShadow: 'inset 0 -4px 0 0 #ea834f',
                        }),
                      }}
                    >
                      {t.name}
                    </Center>
                  )}
                </Can>
              )
            }

            return (
              <Center
                as="a"
                key={t.name}
                href={t.href}
                sx={{
                  h: 'full',
                  cursor: 'pointer',
                  px: '28px',
                  ...(isActive && {
                    color: 'brand.400',
                    fontWeight: 600,
                    boxShadow: 'inset 0 -4px 0 0 #ea834f',
                  }),
                }}
              >
                {t.name}
              </Center>
            )
          })}
        </HStack>

        <Box layerStyle="sectionContent" sx={{ py: '24px', minH: '774px' }}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

const tabs: {
  name: string
  href: string
  checkActive: string[]
  feature?: Subjects
}[] = [
  {
    name: t('views.features.settings.tabs.profile'),
    href: '/settings/profile',
    checkActive: ['profileSettings'],
    feature: 'profile',
  },
  {
    name: t('views.features.settings.tabs.privacy'),
    href: '/settings/privacy',
    checkActive: ['privacySettings'],
  },
  {
    name: t('views.features.settings.tabs.security'),
    href: '/settings/security',
    checkActive: ['securitySettings'],
  },
  {
    name: t('views.features.settings.tabs.credit_cards'),
    href: '/settings/credit_cards',
    checkActive: ['creditCardsSettings']
  }
]
