import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Stack,
  Text,
  useNumberInput,
  Button,
} from '@chakra-ui/react'
import { FiMaximize, FiMinus, FiPlus } from 'react-icons/fi'
import { Controller } from 'react-hook-form'
import { useState } from 'react'

import { t } from '@/lib/helpers'

import { DEFAULT_BORDER_RADIUS } from '@/features/domains/constants'

import { UpgradeText } from '../../shared'

import { useAppContext } from '@/features/layout'

export const BorderRadius = (): React.ReactElement => {
  return (
    <Stack spacing={4}>
      <RadiusItem
        name="banner_radius"
        title={t('views.features.domains.show.customize.banner_radius')}
      />
      <RadiusItem
        name="button_radius"
        title={t('views.features.domains.show.customize.button_radius')}
      />

      <UpgradeText />
    </Stack>
  )
}

type RadiusItemProps = {
  title: string
  name: string
}

const RadiusItem = (props: RadiusItemProps): React.ReactElement => {
  const { name, title } = props

  const { isFree } = useAppContext()

  return (
    <Controller
      name={name}
      defaultValue={DEFAULT_BORDER_RADIUS[name]}
      render={({ field: { onChange, name, value } }) => {
        return (
          <Stack>
            <Flex justify="space-between">
              <Text className="customize-title">{title}</Text>

              {!isFree && (
                <Button
                  variant="link"
                  onClick={() => onChange(DEFAULT_BORDER_RADIUS[name])}
                >
                  {t('views.features.domains.show.customize.reset')}
                </Button>
              )}
            </Flex>

            <HStack spacing={3}>
              <Icon as={FiMaximize} boxSize="32px" />
              <CustomNumberInput
                onChange={onChange}
                value={value}
                name={name}
                min={0}
                isDisabled={isFree}
                max={50}
              />
              )
            </HStack>
          </Stack>
        )
      }}
    />
  )
}

type CustomNumberInputProps = {
  onChange: (...event: any[]) => void
  value: string
  name: string
  min: 0
  max?: number
  isDisabled: boolean
}

const CustomNumberInput = (
  props: CustomNumberInputProps,
): React.ReactElement => {
  const { onChange, value, name, min, max, isDisabled } = props

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      onChange,
      value,
      name,
      step: 1,
      min,
      max,
    })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps({
    disabled: isDisabled,
  })

  const [isFocus, setFocus] = useState(false)

  const format = (val: string) => `${val} px`

  return (
    <HStack
      sx={{
        border: '1px solid',
        borderColor: 'gray.300',
        borderRadius: '4px',
        p: '7px',
        w: 'full',
      }}
    >
      <IconButton
        {...dec}
        variant="outline"
        sx={{ fontSize: '16px', boxSize: '32px', minW: '32px' }}
        aria-label="minus-one"
        icon={<FiMinus />}
        disabled={isDisabled}
      />
      <Input
        {...input}
        sx={{ textAlign: 'center', border: 'none' }}
        value={isFocus ? value : format(value)}
        onFocus={(e) => {
          setFocus(true)
          input.onFocus?.(e)
        }}
        onBlur={(e) => {
          setFocus(false)
          input.onBlur?.(e)
        }}
        size="sm"
        pattern=".+"
      />
      <IconButton
        {...inc}
        variant="outline"
        sx={{ fontSize: '16px', boxSize: '32px', minW: '32px' }}
        aria-label="plus-one"
        icon={<FiPlus />}
        disabled={isDisabled}
      />
    </HStack>
  )
}
