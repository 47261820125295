import { useState } from 'react'
import { ModalBody, useBoolean, UseDisclosureReturn } from '@chakra-ui/react'

import { Modal } from '@/components/shared'
import { TwoFactorQrCode } from '../../components/2FactorQrCode'
import { TwoFactorRecoveryCode } from '../../components/2FactorRecoveryCode'
import { PasswordWrapper } from '../../components/PasswordWrapper'

import { useEnableTwoFactor, useVerifyOtp } from '../../mutations'
import { useNonInitialEffect } from '@/lib/hooks'

import { EnableTwoFactorResponse } from '../../types'

import { t } from '@/lib/helpers'

export type Enable2FAModalProps = {
  disclosure: UseDisclosureReturn
  isPasswordRequired: boolean
}

export const Enable2FAModal = (props: Enable2FAModalProps) => {
  const { disclosure, isPasswordRequired } = props
  const { isOpen, onClose } = disclosure

  const [enableResponse, setEnableResponse] =
    useState<EnableTwoFactorResponse>()

  const [passwordRequired, setRequired] = useBoolean(isPasswordRequired)
  const [isFirstStep, setStep] = useBoolean(true)

  const { mutate: enableTwoFactor } = useEnableTwoFactor()
  const { mutate: verifyOtp, isLoading: verifyOtpLoading } = useVerifyOtp()

  useNonInitialEffect(() => {
    if (isOpen) {
      if (!passwordRequired) {
        enableTwoFactor(undefined, {
          onSuccess(data) {
            setEnableResponse(data)
          },
        })
      }
    }
  }, [disclosure.isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <ModalBody sx={{ py: 5 }}>
        {isFirstStep ? (
          <PasswordWrapper
            title={t(
              'views.features.two_factor.title',
              undefined,
              'Two-Factor Authentication',
            )}
            isCompleted={!passwordRequired}
            onSuccess={() => {
              setRequired.off()

              enableTwoFactor(undefined, {
                onSuccess(data) {
                  setEnableResponse(data)
                },
              })
            }}
            onCancel={onClose}
          >
            <TwoFactorQrCode
              isLoading={verifyOtpLoading}
              onSubmit={(data) => {
                verifyOtp(
                  { data },
                  {
                    onSuccess() {
                      setStep.toggle()
                    },
                  },
                )
              }}
              enable2FactorData={enableResponse}
            />
          </PasswordWrapper>
        ) : (
          <TwoFactorRecoveryCode
            onClose={onClose}
            code={enableResponse?.backup_codes ?? []}
          />
        )}
      </ModalBody>
    </Modal>
  )
}
