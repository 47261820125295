export const Select = {
  parts: ['field'],
  variants: {
    outline: {
      field: {
        bg: 'white',
      },
    },
  },
  sizes: {
    lg: {
      field: {
        fontSize: '16px',
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'brand.300',
  },
}
