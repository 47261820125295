import { createIcon } from '@chakra-ui/react'

export const DesktopIcon = createIcon({
  displayName: 'DesktopIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M4.5 18L19.5 18C20.3284 18 21 17.3284 21 16.5V6C21 5.17157 20.3284 4.5 19.5 4.5L4.5 4.5C3.67157 4.5 3 5.17157 3 6V16.5C3 17.3284 3.67157 18 4.5 18Z"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="1"
    />,
    <path
      d="M15 21H9"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="2"
    />,
    <path
      d="M3 14.25H21"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="3"
    />,
    <path
      d="M12 18V21"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="4"
    />,
  ],
})
