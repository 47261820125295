export const NumberInput = {
  defaultProps: {
    focusBorderColor: 'brand.300',
  },
  baseStyle: {
    field: {
      _disabled: {
        bg: 'gray.300',
      },
    },
  },
}
