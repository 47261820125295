import { useMemo } from 'react'
import { Box, Collapse, Flex, Link, Text } from '@chakra-ui/react'
import { Trans } from 'react-i18next'

type Props = {
  quotaPercent: number
}

type Stage = 'one' | 'two' | 'three'

export const PageviewAlertBanner = (props: Props) => {
  const { quotaPercent } = props

  const stage = useMemo<Stage>(() => {
    if (quotaPercent >= 120) {
      return 'three'
    } else if (quotaPercent >= 100) {
      return 'two'
    } else {
      return 'one'
    }
  }, [quotaPercent])

  return (
    <Collapse in={quotaPercent >= 75}>
      <Box
        sx={{
          h: '40px',
          bg: 'yellow.300',
          fontSize: '14px',
          ...textTemplate[stage].color,
        }}
      >
        <Flex
          sx={{ alignItems: 'center', justifyContent: 'center', h: '100%' }}
        >
          {textTemplate[stage].Component}
        </Flex>
      </Box>
    </Collapse>
  )
}

const textTemplate = {
  one: {
    color: {},
    Component: (
      <Text>
        <Trans i18nKey="features.layout.exceed_75">
          Your website
          <Text as="span" sx={{ fontWeight: 600 }}>
            Pageview has reached maximum limit
          </Text>
          for the current plan.
          <Link href="/packages">
            <Text as="span" sx={{ fontWeight: 600, textDecor: 'underline' }}>
              Upgrade Now
            </Text>
          </Link>
          to keep Cookie Wow recording your visitor consents
        </Trans>
      </Text>
    ),
  },
  two: {
    color: {
      color: 'white',
      bg: 'black',
    },
    Component: (
      <Text>
        <Text as="span" sx={{ d: 'inline', mr: '16px' }}>
          🚨
        </Text>
        <Trans i18nKey="features.layout.exceed_100">
          Your website
          <Text as="span" sx={{ fontWeight: 600 }}>
            Pageview has reached maximum limit
          </Text>
          for the current plan.
          <Link href="/packages">
            <Text as="span" sx={{ fontWeight: 600, textDecor: 'underline' }}>
              Upgrade Now
            </Text>
          </Link>
          to keep Cookie Wow recording your visitor consents
        </Trans>
      </Text>
    ),
  },
  three: {
    color: {
      color: 'white',
      bg: 'red.300',
    },
    Component: (
      <Text>
        <Text as="span" sx={{ d: 'inline', mr: '16px' }}>
          🚨
        </Text>
        <Trans i18nKey="features.layout.exceed_120">
          Your website
          <Text as="span" sx={{ fontWeight: 600 }}>
            Pageview has exceeded maximum limit
          </Text>
          for the current plan.
          <Link href="/packages">
            <Text as="span" sx={{ fontWeight: 600, textDecor: 'underline' }}>
              Upgrade Now
            </Text>
          </Link>
          to keep Cookie Wow recording your visitor consents
        </Trans>
      </Text>
    ),
  },
}
