import { Fragment, useEffect, useMemo, useState } from 'react'
import { Button, Text, useDisclosure, useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie'

import { ListPageLayout } from '@/features/layout/components'
import { withPage } from '@/features/layout'

import { Domain } from '@/features/domains/types'
import { User } from '@/features/users/types'
import { CurrentPackage } from '@/features/types'

import { SelectDomainTable } from '@/features/domains/components'
import {
  ConfirmSelectDomainsModal,
  ConfirmSelectUsersModal,
  DomainContent,
  SelectUsersTable,
  UserContent,
} from '../components'
import { UnsubscribeEffectiveDateModal } from '@/features/payments/components'

import { t } from '@/lib/helpers'
import { UNSUB_NOTICE_COOKIE } from '../constants'

import { useDeleteDomains } from '@/features/domains/mutations'
import { useDeleteUsers } from '@/features/users/mutations'
import { ERROR_TOAST_CONFIG } from '@/lib/constants'

type Props = {
  data: {
    projects?: Domain[]
    users?: User[]
  }
  currentPackage: CurrentPackage
  quota: Quota
}

function MigrationPage(props: Props) {
  const { currentPackage, quota, data } = props

  const toast = useToast()

  const [step, setStep] = useState(() => {
    return data.projects ? 1 : 2
  })
  const [selectDomains, setSelectDomains] = useState<Domain[]>([])
  const [selectUsers, setSelectUsers] = useState<User[]>([])

  const { mutate: deleteDomains, isLoading: deleteDomainsLoading } =
    useDeleteDomains()
  const { mutate: deleteUsers, isLoading: deleteUsersLoading } =
    useDeleteUsers()

  const confirmSelectDomainsDisclosure = useDisclosure()
  const confirmSelectUsersDisclosure = useDisclosure()
  const unsubscribeNoticeDisclosure = useDisclosure()

  const isStep1 = step === 1
  const domainLimit = quota.domain.limit
  const userLimit = quota.user.limit

  const isQuotaExceed = useMemo(() => {
    return (
      quota.domain.used > (quota.domain.limit || Number.POSITIVE_INFINITY) ||
      quota.user.used > (quota.user.limit || Number.POSITIVE_INFINITY)
    )
  }, [])

  useEffect(() => {
    const unsubNotice = Cookies.get(UNSUB_NOTICE_COOKIE)

    if (isQuotaExceed && !unsubNotice) {
      unsubscribeNoticeDisclosure.onOpen()
      Cookies.set(UNSUB_NOTICE_COOKIE, 'true', { expires: 30 })
    }
  }, [])

  return (
    <Fragment>
      <ListPageLayout
        heading={
          isStep1 ? (
            <Fragment>
              {t('views.features.domains.index.choose_domain')}&nbsp;
              <Text as="span" sx={{ fontSize: '16px' }}>
                ({selectDomains.length}/{domainLimit})
              </Text>
            </Fragment>
          ) : (
            <Fragment>
              {t('views.features.users.index.title')}&nbsp;
              <Text as="span" sx={{ fontSize: '16px' }}>
                ({selectUsers.length}/{userLimit})
              </Text>
            </Fragment>
          )
        }
        contents={
          isStep1 ? (
            <DomainContent
              packageName={currentPackage.name}
              limit={quota.domain.limit}
            />
          ) : (
            <UserContent
              packageName={currentPackage.name}
              limit={quota.user.limit}
            />
          )
        }
        rightAddOn={
          isStep1 ? (
            <Button
              size="lg"
              onClick={() =>
                selectDomains.length
                  ? confirmSelectDomainsDisclosure.onOpen()
                  : toast({
                      ...ERROR_TOAST_CONFIG,
                      description: t(
                        'views.features.domains.index.block_migration',
                      ),
                      duration: 5000,
                    })
              }
            >
              {t('views.features.migration.index.choose_domains')}
            </Button>
          ) : (
            <Button size="lg" onClick={confirmSelectUsersDisclosure.onOpen}>
              {t('views.features.migration.index.choose_users')}
            </Button>
          )
        }
      >
        {isStep1
          ? data.projects && (
              <SelectDomainTable
                data={data.projects}
                handleSelectRow={setSelectDomains}
                limit={quota?.domain.limit || Infinity}
              />
            )
          : data.users && (
              <SelectUsersTable
                data={data.users}
                handleSelectRow={setSelectUsers}
                limit={quota?.user.limit || Infinity}
              />
            )}
      </ListPageLayout>

      <ConfirmSelectDomainsModal
        disclosure={confirmSelectDomainsDisclosure}
        isLoading={deleteDomainsLoading}
        onSubmitDelete={() => {
          deleteDomains(
            {
              projectIds: selectDomains.map((domain) => domain.id),
            },
            {
              onSuccess() {
                confirmSelectDomainsDisclosure.onClose()
                if (data.users) {
                  setStep(2)
                } else {
                  window.location.href = '/projects'
                }
              },
            },
          )
        }}
      />
      <ConfirmSelectUsersModal
        disclosure={confirmSelectUsersDisclosure}
        isLoading={deleteUsersLoading}
        onSubmitDelete={() => {
          deleteUsers({
            userIds: selectUsers.map((user) => user.id),
          })
        }}
      />
      <UnsubscribeEffectiveDateModal disclosure={unsubscribeNoticeDisclosure} />
    </Fragment>
  )
}

export default withPage(MigrationPage)
