import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'

import { COOKIE_CATEGORIES, PROJECT } from '../constants'
import { ERROR_TOAST_CONFIG, TOAST_CONFIG } from '@/lib/constants'

type Params = {
  project_id: number
  cookie_id: number
}

const deleteCookie = async (params: Params) => {
  const { project_id, cookie_id } = params

  return fetchAPI({
    path: `/projects/${project_id}/cookies/${cookie_id}`,
    method: 'delete',
  })
}

export const useDeleteCookie = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  return useMutation(deleteCookie, {
    onSuccess() {
      queryClient.invalidateQueries([COOKIE_CATEGORIES, params])
      queryClient.invalidateQueries([PROJECT, params])

      toast({
        ...TOAST_CONFIG,
        title: t('views.features.domains.show.manage.services.cookie_deleted'),
      })
    },
    onError(error) {
      toast({
        ...ERROR_TOAST_CONFIG,
        description: getErrorMsg(error),
      })
    },
  })
}
