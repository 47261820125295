import { Fragment } from 'react'
import {
  chakra,
  Link,
  Box,
  Grid,
  Center,
  PopoverContent,
  Popover,
  PopoverTrigger,
  Flex,
  CSSObject,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import logo from '@/images/icons/cookiewow.svg'

import { ControlPanel } from './ControlPanel'
import { Can } from '@/features/permissions/components'

import { t } from '@/lib/helpers'
import { useActiveRoute } from '@/lib/routes'

import {
  helpCenterLinks,
  navsData,
  subLinks,
} from '@/features/layout/constants'
import { useAbility } from '@/features/permissions'

dayjs.extend(relativeTime)

type Props = {
  isShowMenus?: boolean
}

export const NavBar = ({ isShowMenus = true }: Props) => {
  return (
    <Box as="nav" sx={{ width: '100%', background: 'white' }} id="Navigation">
      <Box layerStyle="container">
        <Grid
          sx={{
            gridTemplateColumns: '120px max-content 1fr',
            gap: '16px',
            h: '56px',
            alignItems: 'center',
          }}
        >
          <Logo id="NavBar-logo" href="/" />

          {isShowMenus && <NavBarMenus />}
        </Grid>
      </Box>
    </Box>
  )
}

const NavBarMenus = () => {
  const checkActiveRoute = useActiveRoute()

  const ability = useAbility()
  const canViewUserManagement = ability.can('read', 'user_management')
  const canViewActivityLog = ability.can('read', 'activity_log')

  return (
    <Fragment>
      <Grid
        sx={{
          h: '100%',
          gridAutoFlow: 'column',
          gridAutoColumns: '140px',
        }}
      >
        {navsData.map((n) => {
          const isActive = checkActiveRoute(n.checkActive)

          if (!n.feature) return null

          return (
            <Can I="read" a={n.feature} key={n.feature}>
              {() => (
                <Link key={n.title} href={n.href}>
                  <Center
                    sx={{
                      fontSize: 'sm',
                      h: '100%',
                      ...(isActive && {
                        backgroundColor: 'brand.400',
                        color: 'white',
                        fontWeight: 'bold',
                      }),
                    }}
                  >
                    {n.title}
                  </Center>
                </Link>
              )}
            </Can>
          )
        })}
        {(() => {
          if (!canViewUserManagement && !canViewActivityLog) return null

          const isActive = checkActiveRoute(['userManagements', 'activityLogs'])

          return (
            <Popover
              trigger="hover"
              placement="bottom-start"
              gutter={3}
              id="NavBar-settings"
            >
              <PopoverTrigger>
                <Center
                  sx={{
                    cursor: 'pointer',
                    ...(isActive && {
                      backgroundColor: 'brand.400',
                      color: 'white',
                    }),
                    fontSize: '14px',
                  }}
                >
                  {t('views.components.layout.nav.more')}
                </Center>
              </PopoverTrigger>
              <PopoverContent
                sx={{
                  w: '168px',
                  boxShadow: '0 0 8px 0 #cccccc',
                  border: 'none',
                }}
              >
                {subLinks.map((li) =>
                  li.feature ? (
                    <Fragment key={li.title}>
                      <Can I="read" a={li.feature}>
                        {() => (
                          <Link
                            key={li.title}
                            href={li.href}
                            id={`NavBar-${li.feature}`}
                          >
                            <Flex sx={LinkItemStyle}>{li.title}</Flex>
                          </Link>
                        )}
                      </Can>
                    </Fragment>
                  ) : null,
                )}
              </PopoverContent>
            </Popover>
          )
        })()}
        <Popover
          trigger="hover"
          placement="bottom-start"
          gutter={3}
          id="NavBar-helpcenter"
        >
          <PopoverTrigger>
            <Center
              sx={{
                cursor: 'pointer',
                fontSize: '14px',
              }}
            >
              {t('views.components.layout.nav.help_center')}
            </Center>
          </PopoverTrigger>
          <PopoverContent
            sx={{
              w: '168px',
              boxShadow: '0 0 8px 0 #cccccc',
              border: 'none',
            }}
          >
            {helpCenterLinks.map((li) => (
              <Link
                key={li.title}
                href={li.href}
                id={`NavBar-${li.feature}`}
                isExternal
              >
                <Flex sx={LinkItemStyle}>{li.title}</Flex>
              </Link>
            ))}
          </PopoverContent>
        </Popover>
      </Grid>

      <ControlPanel />
    </Fragment>
  )
}

export const Logo = chakra(Link, {
  baseStyle: {
    width: '120px',
    height: '25px',
    backgroundImage: `url(${logo})`,
    position: 'relative',
    top: '-4px',
  },
})

const LinkItemStyle: CSSObject = {
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 600,
  h: '48px',
  px: '18px',
  transition: 'background-color 0.25s ease-in-out',
  _hover: {
    backgroundColor: 'brand.25',
  },
}
