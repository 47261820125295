import { ComponentSingleStyleConfig, theme } from '@chakra-ui/react'
import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { theme as origTheme } from '@chakra-ui/react'

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c, theme } = props

  if (c !== 'green') {
    return origTheme.components.Alert.variants.solid(props)
  }
  return {
    container: {
      bg: `${c}.550`,
    },
  }
}

const variantLeftAccent: SystemStyleFunction = (props) => {
  const { colorScheme: c, theme } = props

  if (c !== 'green') {
    return origTheme.components.Alert.variants['left-accent'](props)
  }
  return {
    container: {
      bg: `#C6F6D5`,
      borderColor: '#38A169',
    },
    icon: {
      color: '#38A169',
    },
  }
}

const variants = {
  solid: variantSolid,
  'left-accent': variantLeftAccent,
}

export const Alert: ComponentSingleStyleConfig = {
  variants,
}
