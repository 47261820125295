export default {
  invalidUrlFull: 'รูปแบบ URL ไม่ถูกต้อง เริ่มต้นด้วย http:// หรือ https://',
  wildcardUrlFull:
    'หากคุณต้องการเพิ่ม Wildcard URL กรุณาใช้เครื่องหมายดอกจันทร์ ( * ) เพื่อกำหนดส่วนที่เป็น Wildcard',
  input: {
    required: 'จำเป็นต้องกรอก',
    invalidPassword: 'รหัสผ่านไม่ตรงตามเงื่อนไข',
    passwordMismatch: 'รหัสผ่านไม่ตรงกัน',
  },
}
