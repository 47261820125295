import { useMemo, useEffect } from 'react'
import { HStack, Stack, Text } from '@chakra-ui/layout'
import { useRadioGroup } from '@chakra-ui/radio'
import { useController, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as d3 from 'd3-format'

import { logEcommerce } from '@/lib/stats'

import { BillingCycleRadio } from './atoms'

import { updatedPriceConfig } from '../../payments/config'
import { formatThousand } from '@/lib/helpers'

const formatter = d3.format(',.2f')

export const PlanForm = (): React.ReactElement => {
  const { field } = useController({
    name: 'recurring',
    defaultValue: 'year',
  })

  const packageName = useWatch({
    name: 'package',
  }) as PackageNameInUse

  const { t } = useTranslation('freeTrial')

  const priceYear = updatedPriceConfig[packageName].price.year
  const priceMonth = updatedPriceConfig[packageName].price.month

  useEffect(() => {
    logEcommerce('add_to_cart', {
      currency: 'THB',
      value: priceYear,
      items: [
        {
          item_id: `${packageName}_year`,
          item_name: `${packageName}_year`,
          currency: 'THB',
          price: priceYear,
          quantity: 1,
        },
      ],
    })
  }, [])

  const RADIO_OPTIONS = useMemo(() => {
    return [
      {
        label: t('form.yearly'),
        subtitle: `${formatter(priceYear / 12)}.- / ${t('form.month')}`,
        description: t('form.billAnnually', {
          price: d3.format(',d')(priceYear),
        }),
        value: 'year',
      },
      {
        label: t('form.monthly'),
        subtitle: `${formatter(priceMonth)}.- / ${t('form.month')}`,
        description: t('form.billMonthly', {
          price: formatThousand(priceMonth),
        }),
        value: 'month',
      },
    ]
  }, [packageName])

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field.name,
    onChange: field.onChange,
    value: field.value,
  })

  const group = getRootProps()

  return (
    <Stack spacing={4}>
      <Text>{t('form.selectCycle')}</Text>

      <HStack spacing={4} sx={{ alignItems: 'flex-start' }} {...group}>
        {RADIO_OPTIONS.map(({ value, ...rest }) => {
          const radio = getRadioProps({ value })

          return <BillingCycleRadio key={value} {...radio} {...rest} />
        })}
      </HStack>
    </Stack>
  )
}
