import { Dispatch, SetStateAction, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { getErrorMsg, t } from '@/lib/helpers'
import { CONSENT_CSV_STATUS, PROJECT } from '../constants'

import { fetchAPI } from '@/lib/api'
import { getConsentCsvStatus } from '../queries'
import { ERROR_TOAST_CONFIG, TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  projectId: number
}

const checkConsentCsvStatus = async (payload: Payload) => {
  const { projectId } = payload

  return fetchAPI({
    path: `/projects/${projectId}/consents/export`,
    method: 'post',
  })
}

export const useCheckConsentCsvStatus = ({
  projectId,
  startOnMount,
}: {
  projectId: number
  startOnMount: boolean
}) => {
  const [start, setStart] = useState(startOnMount)

  const toast = useToast()

  const { mutate } = useMutation(checkConsentCsvStatus, {
    onSuccess() {
      setStart(true)
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })

  const { status } = usePolling({ projectId, start, setStart })

  return {
    checkConsentCsvStatus: mutate,
    isLoading: start,
    status,
  }
}

const usePolling = ({
  projectId,
  start,
  setStart,
}: {
  projectId: number
  start: boolean
  setStart: Dispatch<SetStateAction<boolean>>
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: [CONSENT_CSV_STATUS, { projectId }],
    queryFn: () => getConsentCsvStatus({ projectId }),
    async onSuccess(data) {
      if ('succeeded' === data.data.status) {
        queryClient.invalidateQueries([PROJECT, { project_id: projectId }])

        toast({
          ...TOAST_CONFIG,
          duration: null,
          description: t('views.features.domains.show.consent.download_ready'),
        })

        setStart(false)
      }

      if ('failed' === data.data.status) {
        toast({
          ...ERROR_TOAST_CONFIG,
          duration: null,
          description: t('views.features.domains.show.consent.export_failed'),
        })

        setStart(false)
      }
    },
    enabled: start,
    refetchInterval: start ? 5000 : false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  return {
    status: data?.data?.status,
  }
}
