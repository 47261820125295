import { BannerText } from '@datawowio/cookie-banner-ui'

export const mockBannerText: Record<string, BannerText> = {
  th: {
    acceptAll: 'ยอมรับทั้งหมด',
    alwaysActive: 'เปิดใช้งานตลอดเวลา',
    bannedTitle:
      'แบนเนอร์คุกกี้ไม่สามารถใช้งานได้ในขณะนี้ โปรดติดต่อเจ้าของเว็ปไซต์',
    changePreference: 'การตั้งค่าคุกกี้',
    cookieDetailLink: 'รายละเอียดคุกกี้',
    cookiePolicy: 'นโยบายคุกกี้',
    preference: 'การตั้งค่าความเป็นส่วนตัว',
    privacyPolicy: 'นโยบายความเป็นส่วนตัว',
    rejectAll: 'ปฏิเสธทั้งหมด',
    savePreference: 'ยืนยันตัวเลือกของฉัน',
    customPolicy: 'นโยบายคุกกี้',
  },
  en: {
    acceptAll: 'Accept All',
    alwaysActive: 'Always Active',
    bannedTitle:
      'The cookie banner is currently unavailable. Please contact the website owner.',
    changePreference: 'Change Preferences',
    cookieDetailLink: 'Cookie Detail',
    cookiePolicy: 'Cookie Policy',
    preference: 'Privacy Preference',
    privacyPolicy: 'Privacy Policy',
    rejectAll: 'Reject All',
    savePreference: 'Confirm My Choices',
    customPolicy: 'Cookie Policy',
  },
}
