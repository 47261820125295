import { Fragment, useMemo, useState } from 'react'
import { Box, Icon, Link, useDisclosure } from '@chakra-ui/react'
import { get } from 'lodash'
import * as d3 from 'd3-format'
import { Column } from 'react-table'
import { FiTrash2 } from 'react-icons/fi'

import { t } from '@/lib/helpers'
import { useDeleteDomain } from '../../mutations'

import { DataTable, AnimatedActionColumn } from '@/components/DataDisplay/Table'
import { Domain } from '../../types'
import { DeleteModal } from '@/components/shared'
import { useAbility } from '@/features/permissions'

type Props = {
  data: Domain[]
}

export const DomainTable = (props: Props) => {
  const { data } = props

  const [actionData, setActionData] = useState<Domain>()
  const disclosure = useDisclosure()

  const ability = useAbility()
  const canDeleteDomain = ability.can('delete', 'project')

  const { mutate: deleteDomain, isLoading } = useDeleteDomain()

  const columns: Array<Column<Domain>> = useMemo(() => {
    return [
      {
        Header: t('views.features.domains.index.domain_name'),
        accessor: 'url',
        sortKey: 'url',
        width: '60%',
        Cell: (cellProps) => {
          const data = get(cellProps, 'row.original')

          return (
            <Box>
              <Link href={`/projects/${data.id}/consents`}>{data.url}</Link>
            </Box>
          )
        },
      },
      {
        Header: t('views.features.domains.index.pageviews'),
        accessor: 'page_views_count',
        sortKey: 'page_views_count',
        width: '30%',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return d3.format(',d')(value)
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable
        data={memoizedData}
        columns={columns}
        renderActionColumn={{
          width: '10%',
          Cell: (domain) => {
            if (!canDeleteDomain) return null

            return (
              <>
                <AnimatedActionColumn
                  ariaLabel="domain column action button"
                  icons={[
                    {
                      icon: (
                        <Icon
                          id="Domain-delete"
                          as={FiTrash2}
                          onClick={() => {
                            disclosure.onOpen()
                            setActionData(domain)
                          }}
                          sx={{
                            color: 'red.400',
                            cursor: 'pointer',
                            boxSize: '20px',
                          }}
                        />
                      ),
                      label: t('views.shared.button.delete'),
                    },
                  ]}
                />
              </>
            )
          },
        }}
      />

      {canDeleteDomain && actionData && (
        <DeleteModal
          description={t('views.features.domains.index.delete_desc')}
          disclosure={disclosure}
          onDelete={() => {
            deleteDomain(
              { projectId: actionData.id },
              {
                onSettled: disclosure.onClose,
              },
            )
          }}
          isLoading={isLoading}
        />
      )}
    </Fragment>
  )
}
