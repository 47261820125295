import { Dispatch, Fragment, SetStateAction } from 'react'
import { Button, Icon, Tooltip } from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi'

import { Can } from '@/features/permissions/components'
import { ManageCategoryForm } from '../../components/ManageCategoryForm'

import { transformCategoryIntoForm } from '../../utils'
import { useAddCategory, useUpdateCategory } from '@/features/domains/mutations'
import { useAbility } from '@/features/permissions'
import { getPathParams } from '@/lib/routes'
import { useProjectData } from '@/features/domains/queries'
import { t } from '@/lib/helpers'

import { NECESSARY_CATEGORY_NAME } from '../../constants'
import { Category } from '@/features/domains/types'

type CategoryFormContainerProps = {
  activeLanguage: BannerLanguage
  category: Category
  createBoolean: UseBooleanReturn
  onOpenDeleteCategory: () => void
  setCurrentCat: Dispatch<SetStateAction<Category>>
  updateBoolean: UseBooleanReturn
}

export const CategoryFormContainer = (props: CategoryFormContainerProps) => {
  const {
    activeLanguage,
    category,
    createBoolean,
    onOpenDeleteCategory,
    updateBoolean,
    setCurrentCat,
  } = props

  const [createBool, setCreateBool] = createBoolean
  const [, setUpdateBool] = updateBoolean

  const { project_id } = getPathParams()
  const ability = useAbility()
  const canUpdateCategory = ability.can('update', 'category')

  const project = useProjectData()

  const { mutate: addCategory, isLoading: createLoading } = useAddCategory({
    onSuccess: (newCat) => {
      setCurrentCat(newCat)
      onCancel()
    },
  })
  const { mutate: updateCategory, isLoading: updateLoading } =
    useUpdateCategory({
      onSuccess: (updatedCat) => {
        setCurrentCat(updatedCat)
      },
    })

  const onCancel = () => {
    setCreateBool.off()
    setUpdateBool.off()
  }

  const isNoticeOnly = project?.banner_model === 'notice_only'

  const renderForm = () => {
    if (createBool) {
      return (
        <ManageCategoryForm
          isLoading={createLoading}
          isNoticeOnly={isNoticeOnly}
          onCancel={onCancel}
          onSubmitForm={(data) => {
            addCategory({
              data,
              params: {
                project_id: Number(project_id),
              },
            })
          }}
        />
      )
    }

    const { en, th } = NECESSARY_CATEGORY_NAME
    const isNecessaryCategory =
      category.name.en === en &&
      category.name.th === th &&
      category.default_category

    return (
      <ManageCategoryForm
        activeLanguage={activeLanguage}
        enName={category.name.en}
        defaultValues={transformCategoryIntoForm(category, activeLanguage)}
        isDefaultCategory={category.default_category}
        isLoading={updateLoading}
        isNecessaryCategory={isNecessaryCategory}
        isNoticeOnly={isNoticeOnly}
        isReadOnly={!canUpdateCategory}
        onCancel={onCancel}
        onSubmitForm={(data) => {
          updateCategory({
            data: {
              name: {
                [activeLanguage]: data.name,
              },
              description: {
                [activeLanguage]: data.description,
              },
              slug: data.slug,
              consent_model: data.consent_model,
            },
            params: {
              category_id: category.id,
              project_id: Number(project_id),
            },
          })
        }}
        deleteCategoryButton={
          category.default_category ? null : (
            <Can I="delete" a="category">
              {() => (
                <Tooltip
                  hasArrow
                  label={t(
                    'views.features.domains.show.manage.delete_category',
                  )}
                  bg="#2b2b2b"
                  size="lg"
                  placement="top"
                >
                  <Button
                    variant="unstyled"
                    onClick={onOpenDeleteCategory}
                    id="category-delete"
                  >
                    <Icon as={FiTrash2} color="red.400" fontSize="24px" />
                  </Button>
                </Tooltip>
              )}
            </Can>
          )
        }
      />
    )
  }

  return <Fragment>{renderForm()}</Fragment>
}
