import { PaymentProvider as DataWowPaymentProvider } from '@datawowio/payment-ui'
import { Global, css } from '@emotion/react'

import theme from '@/lib/styles/theme'

type PaymentProviderProps = {
  children: React.ReactNode
  email?: string
}

export const PaymentProvider = ({
  children,
  email = '',
}: PaymentProviderProps) => {
  return (
    <DataWowPaymentProvider
      project={{
        title: 'Cookie Wow',
        email,
        productType: 'cookie banner',
      }}
      theme={theme}
    >
      {children}

      <Global
        styles={css`
          body {
            background-color: #fffaf1;
          }
        `}
      />
    </DataWowPaymentProvider>
  )
}
