export default {
  all: 'ทั้งหมด',
  cancel: 'ยกเลิก',
  change: 'เปลี่ยน',
  close: 'ปิด',
  confirm: 'ยืนยัน',
  day_unit: 'วัน',
  discard: 'ยกเลิก',
  discardChanges: 'ยกเลิกการเปลี่ยนแปลง',
  lastUpdated: 'อัปเดตล่าสุด',
  month: '{{count}} เดือน',
  month_plural: '{{count}} เดือน',
  month_unit: 'เดือน',
  noData: 'ไม่มีข้อมูล',
  refresh: 'รีเฟรช',
  save: 'บันทึก',
  search: 'ค้นหา',
  seeMore: 'เพิ่มเติม',
  setting: 'ตั้งค่า',
  since: 'ตั้งแต่',
  times_unit: 'ครั้ง',
  total: 'ทั้งหมด',
  unlimited: 'ไม่จำกัด',
  year_unit: 'ปี',
}
