import ReactSelect, { GroupBase, Props } from 'react-select'

import { OptionTypeBase, SelectOption } from './types'
import { useThemeConfig } from './useThemeConfig'

export const Select = <
  OptionType extends OptionTypeBase = SelectOption<string>,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: Props<OptionType, IsMulti, GroupType>,
) => {
  const { styles, theme } = useThemeConfig<OptionType, IsMulti, GroupType>()

  return (
    <ReactSelect<OptionType, IsMulti, GroupType>
      styles={styles}
      theme={theme}
      menuPlacement="auto"
      {...props}
    />
  )
}
