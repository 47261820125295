import { isEmpty } from 'lodash'
import { Box } from '@chakra-ui/react'
import { CardItem } from '@datawowio/payment-ui'

import { usePaymentStore } from '@/features/payments/stores'
import { usePaymentServices } from '@/features/payments/services/PaymentServices'

import { CreditCard } from '../../types'

type Props = {
  cards: CreditCard[]
}

export const CreditCardList = ({ cards }: Props) => {
  const { cardId, setCardId } = usePaymentStore()
  const { deleteCard, setPrimaryCard } = usePaymentServices()

  if (isEmpty(cards)) {
    return (
      <Box p="4" textAlign="center" color="gray.400">
        ไม่มีบัตรเครดิต
      </Box>
    )
  }

  return (
    <Box>
      {cards.map((card) => {
        const { id } = card

        return (
          <CardItem
            key={id}
            id={String(id)}
            isSelected={cardId === id}
            primary={card.primary}
            cardType={card.brand}
            shownNumber={card.number}
            onClick={() => {
              setCardId(id)
            }}
          />
        )
      })}
    </Box>
  )
}
