import { CSSObject, Input, InputProps, Textarea } from '@chakra-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'

type LanguageDependantInputProps = {
  id: string
  name: string
  placeholder?: string
  activeLanguage: BannerLanguage
  useTextArea?: boolean
  sx?: CSSObject
  size?: InputProps['size']
}

export const LanguageDependantInput = (props: LanguageDependantInputProps) => {
  const {
    activeLanguage,
    id,
    name,
    placeholder,
    size,
    sx,
    useTextArea = false,
  } = props

  const inputValue = useWatch({
    name,
  })
  const { setValue } = useFormContext()

  const Component = useTextArea ? Textarea : Input

  return (
    <Component
      name={name}
      id={id}
      value={inputValue[activeLanguage] ?? ''}
      placeholder={placeholder}
      onChange={({ target: { value } }) => {
        setValue(name, {
          ...inputValue,
          [activeLanguage]: value,
        })
      }}
      sx={sx}
      size={size}
    />
  )
}
