import {
  Box,
  Button,
  Collapse,
  Flex,
  FormHelperText,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  Stack,
  Switch,
  Text,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useState } from 'react'

import { FormControl } from '@/components/FormControl'
import { Modal, ModalHeader, Span } from '@/components/shared'

import { useUpdatePasswordExpiration } from '../mutations'

import { Company } from '@/features/users/types'
import { PasswordExpirationFormInput } from '../types'

type PasswordExpirationSectionProps = {
  expirePasswordAfterDays: Company['expire_password_after_days']
}

export const PasswordExpirationSection = (
  props: PasswordExpirationSectionProps,
) => {
  const { expirePasswordAfterDays } = props

  const isOpen = Boolean(expirePasswordAfterDays)

  const { t: tCommon } = useTranslation('common')
  const { t: tSettings } = useTranslation('settings')

  const disclosure = useDisclosure()

  return (
    <Box>
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Text fontWeight="bold">{tSettings('passwordExpiration.title')}</Text>

        <Button
          variant="link"
          sx={{ textDecor: 'underline' }}
          onClick={disclosure.onOpen}
        >
          {tCommon('setting')}
        </Button>
      </Flex>

      <Text
        sx={{ color: isOpen ? 'brand.400' : 'gray.400', mt: 2, fontSize: 'sm' }}
      >
        {expirePasswordAfterDays ? (
          <Trans
            t={tSettings}
            i18nKey="passwordExpiration.detail"
            count={expirePasswordAfterDays}
          >
            มีการกำหนดนโยบายให้เปลี่ยนรหัสผ่านทุก&nbsp;
            <Span fontWeight="semibold">
              {{ days: expirePasswordAfterDays.toLocaleString() }}
            </Span>
            &nbsp;วัน
          </Trans>
        ) : (
          tSettings('passwordExpiration.emptyDetail')
        )}
      </Text>

      <PasswordExpirationModal
        disclosure={disclosure}
        expirePasswordAfterDays={expirePasswordAfterDays}
      />
    </Box>
  )
}

type PasswordExpirationModalProps = {
  disclosure: UseDisclosureReturn
  expirePasswordAfterDays: Company['expire_password_after_days']
}

const PasswordExpirationModal = (props: PasswordExpirationModalProps) => {
  const { disclosure, expirePasswordAfterDays } = props
  const { isOpen, onClose } = disclosure

  const { t: tSettings } = useTranslation('settings')

  const { mutate: updatePasswordExpiration, isLoading } =
    useUpdatePasswordExpiration()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{tSettings('passwordExpiration.modal.header')}</ModalHeader>
      <ModalBody>
        <PasswordExpirationForm
          onSubmitForm={(data) =>
            updatePasswordExpiration(
              { data },
              {
                onSuccess: onClose,
              },
            )
          }
          isLoading={isLoading}
          expirePasswordAfterDays={expirePasswordAfterDays}
          onClose={onClose}
        />
      </ModalBody>
    </Modal>
  )
}

type PasswordExpirationFormProps = {
  expirePasswordAfterDays: Company['expire_password_after_days']
  isLoading: boolean
  onSubmitForm: (data: PasswordExpirationFormInput) => void
  onClose: () => void
}

const PasswordExpirationForm = (props: PasswordExpirationFormProps) => {
  const { isLoading, onSubmitForm, expirePasswordAfterDays, onClose } = props

  const { t: tSettings } = useTranslation('settings')
  const { t: tCommon } = useTranslation('common')
  const [check, setCheck] = useState(Boolean(expirePasswordAfterDays))

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<PasswordExpirationFormInput>({
    defaultValues: {
      expire_password_after_days: expirePasswordAfterDays ?? 90,
    },
  })

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmitForm({
          expire_password_after_days: check
            ? data.expire_password_after_days
            : null,
        }),
      )}
    >
      <Stack spacing="6">
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'gray.200',
            p: 4,
          }}
        >
          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text sx={{ fontWeight: 'semibold' }}>
              {tSettings('passwordExpiration.modal.switchLabel')}
            </Text>

            <Switch
              isChecked={check}
              onChange={(e) => {
                setCheck(Boolean(e.target.checked))
              }}
            />
          </Flex>

          <Collapse in={check}>
            <FormControl
              label={tSettings(
                'passwordExpiration.form.expirePasswordAfterDays.label',
              )}
              isRequired
              sx={{
                mt: 4,
              }}
            >
              <InputGroup>
                <Input
                  type="number"
                  {...register('expire_password_after_days')}
                />
                <InputRightAddon>{tCommon('day_unit')}</InputRightAddon>
              </InputGroup>
              <FormHelperText>
                {tSettings(
                  'passwordExpiration.form.expirePasswordAfterDays.helper',
                )}
              </FormHelperText>
            </FormControl>
          </Collapse>
        </Box>

        <Stack>
          <Button
            size="xl"
            type="submit"
            isLoading={isLoading}
            isDisabled={!isDirty && Boolean(expirePasswordAfterDays) === check}
          >
            {tCommon('save')}
          </Button>
          <Button
            variant="ghost"
            onClick={onClose}
            size="xl"
            colorScheme="gray"
          >
            {tCommon('discard')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
