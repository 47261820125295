export default {
  allTime: 'All Time',
  allConsentType: 'All consent types',
  byCategory: 'by Category',
  byCategoryTooltip:
    'Show consent ratio and the total amount of each cookie category',
  byStatus: 'by Type',
  byStatusTooltip:
    'Show consent ratio and the total amount of each consent type',
  consentLog: 'Consent Log',
  consentOverview: 'Consent Overview',
  consentOverviewTooltip:
    'Show numbers of total accepted consents and the total amount of all consent types on the y-axis and time periods on the x-axis',
  consentRate: 'Consent Rate',
  consentRateTooltip:
    'Consent rate is the percentage of consents that compares between the number of “total accepted consents” and “total amount of all consent types”',
  consents: 'Consents',
  fetchingText: 'Dashboard is being refreshed. Please wait...',
  of: 'of',
  sessions: 'Sessions',
  thisMonth: 'This Month',
  thisYear: 'This Year',
  totalSession: 'Total (Session)',
  action: {
    accepted_all: 'Accepted All',
    rejected_all: 'Rejected All',
    partially_accepted: 'Partially Accepted',
    informed_all: 'Informed',
    dismissed_all: 'Dismiss',
  },
  acceptedStatus: {
    allActions: 'All Actions',
    allow: 'Allow',
    deny: 'Deny',
    dismiss: 'Dismiss',
    informed: 'Informed',
  },
  maintenance: {
    notice: {
      title: 'Announcement',
      description:
        'This section is under maintenance, please visit again few hours later. We sincerely apologize for any inconvenience this may cause.',
    },
  },
  apologize: {
    modal: {
      title:
        'NOTICE OF COOKIE WOW SERVICE INTERRUPTION <1>nDataThoth Limited</1>',
      contents: [
        'Dear Cookie Wow users',
        'Regarding the Cookie Wow service interruption on June 1, 2022. We had been experiencing challenges with an explosive growth in the number of users, resulting in the temporary service interruptions.',
        'We sincerely apologize for the inconvenience that this may cause. We did our best to resolve the issue immediately to make our platform available as soon as possible.',
        'Cookie Wow is now ready to serve you. We appreciate your patience and your trust in us. We will ensure continuous improvement for delivering the best service to you, and will prevent similar incidents in the future.',
        'Best regards,',
        'nDataThoth Limited Management',
      ],
    },
  },
}
