import AuthLayout from '@/features/auth/layouts/AuthLayout'
import SetPasswordForm from '@/features/auth/components/SetPasswordForm'

import Image from '@/images/project/auth/set_password.svg'

import { t } from '@/lib/helpers'
import { withAuthPage } from '@/features/layout'

type Props = {
  formActionPath: string
  minimumPasswordLength: number
}

function SetPassword(props: Props): React.ReactElement {
  return (
    <AuthLayout
      image={Image}
      header={t('devise.passwords.edit.change_my_password')}
    >
      <SetPasswordForm {...props} />
    </AuthLayout>
  )
}

export default withAuthPage(SetPassword)
