import { Fragment } from 'react'
import { Stack, Text, useDisclosure } from '@chakra-ui/react'

import { useAppContext, withPage } from '@/features/layout'
import { SettingLayout } from '../../components'
import { CompanySettingsForm } from '@/features/users/components'
import { ChangeEmailModal, ChangeEmailSection } from '../../containers'

import { t } from '@/lib/helpers'

import { Company } from '@/features/users/types'

import { useUpdateCompanyProfile } from '@/features/users/mutations'

type Props = {
  company: Company
}

function ProfileSettingPage(props: Props) {
  const { company } = props

  const { mutate: updateCompanyProfile, isLoading } = useUpdateCompanyProfile()
  const { currentUser } = useAppContext()

  const disclosure = useDisclosure()

  return (
    <Fragment>
      <SettingLayout>
        <Text sx={{ mb: '24px', fontWeight: 700 }}>
          {t('views.features.users.settings.profile_settings')}
        </Text>

        <Stack spacing="4" sx={{ w: '50%' }}>
          <ChangeEmailSection />

          <CompanySettingsForm
            isLoading={isLoading}
            onSubmit={(data) => {
              updateCompanyProfile({ data })
            }}
            defaultValues={{
              first_name: company.first_name,
              last_name: company.last_name,
              name: company.name,
              phone_number: company.phone_number,
            }}
            email={currentUser.email}
            onOpenEmailModal={disclosure.onOpen}
          />
        </Stack>
      </SettingLayout>

      <ChangeEmailModal disclosure={disclosure} />
    </Fragment>
  )
}

export default withPage(ProfileSettingPage)
