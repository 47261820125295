import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { getPathParams } from '@/lib/routes'

import { COOKIE_CATEGORIES } from '../constants'
import { Category } from '../Manage/types'

type Params = {
  project_id: number
}

export const getCookieCategories = async (
  params: Params,
): Promise<Category[]> => {
  const { project_id } = params

  const response = await fetchAPI({
    path: `/projects/${project_id}/categories`,
  })

  const data = get(response, 'data', [])

  return data
}

type Options = {
  initialData?: Category[]
  onSuccess?: (data: Category[]) => void
}

export const useCookieCategories = (config: Options = {}) => {
  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  return useQuery<Category[], AxiosError>({
    queryKey: [COOKIE_CATEGORIES, params],
    queryFn: () => getCookieCategories(params),
    ...config,
  })
}
