import { Fragment, useMemo } from 'react'

import { withPage } from '@/features/layout'
import { ListPageLayout } from '@/features/layout/components'

import { t } from '@/lib/helpers'
import { useActivityLogs } from '../queries'

import { FilterContextProvider } from '@/features/filters/context'
import { ActivityLogTableContainer } from '../containers'

import { ActivityLog, ActivityLogMeta } from '../types'
import { SelectOptions, FolderOptions } from '@/components/Select/types'

type Props = {
  activity_logs: ActivityLog[]
  initial_meta: ActivityLogMeta
  folder_options: FolderOptions
  function_options: SelectOptions
}

function ActivityLogsPage(props: Props) {
  const { activity_logs, folder_options, initial_meta, function_options } =
    props

  useActivityLogs({
    meta: initial_meta,
    initialData: activity_logs,
  })
  const folderOptions = useMemo(() => {
    return folder_options
  }, [])
  const functionOptions = useMemo(() => {
    return function_options
  }, [])

  return (
    <Fragment>
      <FilterContextProvider initialMeta={initial_meta}>
        <ListPageLayout heading={t('views.features.activity_logs.index.title')}>
          <ActivityLogTableContainer
            folderOptions={folderOptions}
            functionOptions={functionOptions}
          />
        </ListPageLayout>
      </FilterContextProvider>
    </Fragment>
  )
}

export default withPage(ActivityLogsPage)
