import { CSSObject, Icon } from '@chakra-ui/react'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

export const renderCheckMark = (bool: boolean, sx?: CSSObject) => {
  if (bool)
    return (
      <Icon as={FaCheckCircle} boxSize="24px" color="#60D0A6" sx={{ ...sx }} />
    )

  return (
    <Icon as={FaTimesCircle} boxSize="24px" color="#f97272" sx={{ ...sx }} />
  )
}
