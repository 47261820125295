import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'
import { FOLDERS } from '../constants'

type Payload = {
  params: {
    folder_id: number
  }
}

type Response = {
  id: number
  name: string
}

const deleteFolder = async ({ params }: Payload): Promise<Response> => {
  const { folder_id } = params

  const response = await fetchAPI({
    path: `/folders/${folder_id}`,
    method: 'delete',
  })

  return response.data
}

export const useDeleteFolder = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<Response, AxiosError, Payload>(deleteFolder, {
    async onSuccess(data) {
      await queryClient.refetchQueries([FOLDERS])

      toast({
        ...TOAST_CONFIG,
        title: t('views.features.folders.delete_folder_success_title'),
        description: t(
          'views.features.folders.delete_folder_success_description',
          {
            name: data.name,
          },
        ),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
