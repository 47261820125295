import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { ModalBody, ModalHeader } from '@chakra-ui/modal'

import { Modal } from '@/components/shared'
import { UserForm } from '../UserForm'

import { t } from '@/lib/helpers'

import { useInviteUser } from '../../mutations'
import { useAppContext } from '@/features/layout'
import { userIntoFormInput } from '../../utils'

export type AddUserModalProps = {
  disclosure: UseDisclosureReturn
}

export const AddUserModal = (props: AddUserModalProps) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  const { mutate, isLoading } = useInviteUser()
  const { currentUser } = useAppContext()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <ModalHeader sx={{ pb: 0 }}>
        {t('views.features.users.index.invite_user', undefined, 'Invite User')}
      </ModalHeader>

      <ModalBody>
        <UserForm
          defaultValues={userIntoFormInput(currentUser, true)}
          isLoading={isLoading}
          onClose={onClose}
          onSubmitForm={(data) => {
            mutate(
              { data },
              {
                onSuccess: onClose,
              },
            )
          }}
        />
      </ModalBody>
    </Modal>
  )
}
