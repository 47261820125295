import { useMemo } from 'react'
import { Column } from 'react-table'

import { DataTable } from '@/components/DataDisplay/Table'

type Props = {
  data: string[]
}

export const ScannedUrlTable = (props: Props) => {
  const { data } = props

  const columns: Array<Column<{ url: string }>> = useMemo(() => {
    return [
      {
        Header: 'URL',
        accessor: 'url',
        disableSortBy: true,
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data.map((datum) => ({ url: datum }))
  }, [data])

  return <DataTable columns={columns} data={memoizedData} showPagination />
}
