import { Fragment } from 'react'
import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/react'

import ResubImage from '@/images/email-unsubscribe/resub-success.png'
import { COOKIE_WOW_URL } from '../../constants'

export const EmailResubscribeSuccess = (): React.ReactElement => {
  return (
    <Fragment>
      <Image src={ResubImage} alt="resub-image" />
      <Box sx={{ p: '36px 40px' }}>
        <Stack sx={{ mb: '24px' }} spacing={4} align="center">
          <Heading sx={{ textAlign: 'center', fontSize: '24px' }}>
            สมัครรับอีเมลเรียบร้อยแล้ว
          </Heading>
          <Text textAlign="center">
            ขอบคุณที่ไว้วางใจสมัครรับอีเมลกับเรา คุณจะไม่พลาดทุกฟีเจอร์ใหม่ ๆ
            และข้อเสนอสุดพิเศษจากเรา
          </Text>
        </Stack>

        <Button
          as="a"
          size="xl"
          sx={{ w: 'full' }}
          onClick={() => {
            window.location.href = COOKIE_WOW_URL
          }}
        >
          กลับสู่หน้าหลัก
        </Button>
      </Box>
    </Fragment>
  )
}
