import { Button, useDisclosure } from '@chakra-ui/react'

import { withPage } from '@/features/layout'
import { ListPageLayout } from '@/features/layout/components'

import { UsersTable } from '../../components'
import { AddUserModal } from '../../components/UserModal'

import { useQuotaData, useUsers } from '../../queries'
import { useAbility } from '@/features/permissions'

import { t } from '@/lib/helpers'
import { User } from '../../types'

type Props = {
  users: User[]
}

function UsersListPage(props: Props) {
  const { data: users, isLoading } = useUsers({ initialData: props.users })
  const disclosure = useDisclosure()

  const ability = useAbility()
  const canAddUser = ability.can('create', 'user_management')

  const quota = useQuotaData()
  const canAddUserQuota = quota
    ? quota.user.used < (quota.user.limit || Infinity)
    : false

  return (
    <ListPageLayout
      heading={t('views.features.users.index.title')}
      rightAddOn={
        <Button
          size="lg"
          onClick={disclosure.onOpen}
          isDisabled={!canAddUser || !canAddUserQuota}
        >
          {t('views.features.users.index.invite_user')}
        </Button>
      }
    >
      {users && <UsersTable data={users} isLoading={isLoading} />}

      <AddUserModal disclosure={disclosure} />
    </ListPageLayout>
  )
}

export default withPage(UsersListPage)
