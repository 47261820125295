export default {
  creditCard: 'บัตรเครดิต',
  toast: {
    cardReject: 'บัตรของคุณถูกปฏิเสธ',
    failed: 'การชำระเงินล้มเหลว',
    success: 'การชำระเงินเสร็จสิ้น',
  },
  coupon: {
    toast: {
      applySuccess: 'เพิ่มคูปองสำเร็จ',
      removeSuccess: 'ลบคูปองสำเร็จ',
    },
  },
  deleteCreditCard: {
    noCardLeft:
      'ระบบได้ลบบัตรเครดิตหมายเลข {{card}} เรียบร้อยแล้ว หากสิ้นสุดระยะเวลาการใช้งานแพ็กเกจของคุณ คุณจะไม่สามารถใช้งานแบนเนอร์คุกกี้ของ Cookie Wow ได้ต่อ',
    hasCard:
      'ระบบได้ลบบัตรเครดิตหมายเลข {{deleteCard}} เรียบร้อยแล้ว ระบบจะใช้บัตรเครดิตหมายเลข {{newCard}} <br/> เป็นบัตรเครดิตเริ่มต้นในการชำระเงินสำหรับรอบบิลถัดไป',
    description: 'ระบบได้ลบบัตรเครดิตหมายเลข {{card}} เรียบร้อยแล้ว',
  },
  noPackage: 'กรุณา <1>เลือกแพ็กเกจ</1> เพื่อเริ่มต้นการใช้งาน',
}
