export const CARD_ELEMENT_STYLE = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',

      '::placeholder': {
        color: '#cccccc',
      },
    },
  },
}

export const OVERRIDE_STRIPE_STYLES = {
  '.StripeElement': {
    margin: 0,
    boxShadow: 'unset',
    borderColor: 'inherit',
    h: '48px',
    px: '16px',
    pt: '14px',

    '&--focus': {
      borderColor: 'brand.400',
      boxShadow: '0 0 0 1px #ea834f',
    },
  },
}

export const RECURRING_TYPES = ['year', 'month'] as const
export const TAX_PAYER_TYPES = ['individual', 'company'] as const

export const STRIPE_ELEMENTS = [
  'card_number',
  'card_expiry',
  'card_cvc',
] as const
