import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useErrorHandler } from '@/lib/hooks'

import { Cookie, CookieFormInput } from '../types'
import { TOAST_CONFIG } from '@/lib/constants'
import { COOKIE_CATEGORIES, PROJECT } from '../constants'

type Payload = {
  data: CookieFormInput
  params: {
    project_id: number
  }
}

const addCookie = async ({ data, params }: Payload) => {
  const { project_id } = params

  const response = await fetchAPI<Cookie>({
    path: `/projects/${project_id}/cookies`,
    method: 'post',
    data,
  })

  return response.data
}

export const useAddCookie = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  return useMutation<Cookie, AxiosError, Payload>(addCookie, {
    onSuccess() {
      queryClient.invalidateQueries([COOKIE_CATEGORIES, params])
      queryClient.invalidateQueries([PROJECT, params])

      toast({
        ...TOAST_CONFIG,
        title: t('views.features.domains.show.manage.services.cookie_created'),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
