import {
  Link,
  Grid,
  Text,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Stack,
} from '@chakra-ui/react'
import { get } from 'lodash'

import { t } from '@/lib/helpers'
import { useAppContext } from '@/features/layout/ApplicationContext'
import { useSignOut } from '@/features/users/mutations'
import { useUpdateLanguage } from '@/features/i18n/mutations'

import { NavLanguageSection } from '@/features/i18n/components'

export const UserPanel = () => {
  const { currentUser } = useAppContext()
  const email = get(currentUser, 'email')

  const { mutate: signOut } = useSignOut()
  const { mutate: updateLanguage } = useUpdateLanguage()

  return (
    <Popover placement="bottom-end" id="NavUserPanel">
      <PopoverTrigger>
        <Grid
          sx={{
            gap: '12px',
            pl: '24px',
            gridTemplateColumns: 'max-content 40px',
            alignItems: 'center',
            cursor: 'pointer',
            userSelect: 'none',
            background: 'white',
          }}
          tabIndex={1}
        >
          <Text sx={{ fontSize: '14px', fontWeight: 600 }}>{email}</Text>
          <Avatar name={email} boxSize="40px" />
        </Grid>
      </PopoverTrigger>

      <PopoverContent sx={{ w: '190px', ml: 'auto' }}>
        <PopoverBody sx={{ px: '16px', py: '18px' }}>
          <Stack spacing="4">
            <Stack
              sx={{
                color: 'gray.600',
                fontSize: '16px',
                a: {
                  _hover: {
                    textDecor: 'underline',
                  },
                },
              }}
              spacing="1"
            >
              <Link
                href={
                  currentUser.role === 'owner'
                    ? '/settings/profile'
                    : '/settings/privacy'
                }
              >
                {t('views.components.layout.nav.user_settings')}
              </Link>
              {/* <Link isExternal href="https://help.cookiewow.com/th/">
                {t(
                  'views.components.layout.nav.help_center',
                  undefined,
                  'Help Center',
                )}
              </Link> */}
            </Stack>

            <NavLanguageSection
              lang={gon.currentLocale}
              onChangeLang={(lang) => {
                updateLanguage({ locale: lang })
              }}
            />

            <Link
              onClick={() => signOut()}
              sx={{
                color: 'red.400',
                _hover: {
                  textDecor: 'underline',
                },
              }}
            >
              {t('views.components.layout.nav.logout', undefined, 'Logout')}
            </Link>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
