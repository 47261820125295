import { Table, Thead, Tbody, Tr, Th, Td, Flex, Box } from '@chakra-ui/react'
import { Fragment } from 'react'
import { useExpanded, useTable } from 'react-table'
import { isEmpty } from 'lodash'
import Cookies from 'js-cookie'

import { t } from '@/lib/helpers'
import { EXPANDED_STATE_COOKIE } from '@/constants/cookie'

import { thStyle } from './Table.styles'
import { DataTableProps } from './types'

export type ExpandingTableProps<T extends Record<string, unknown>> =
  DataTableProps<T>

export const ExpandingTable = <T extends Record<string, unknown>>(
  props: ExpandingTableProps<T>,
) => {
  const {
    data,
    columns,
    renderActionColumn,
    renderToggleRow,
    renderNoData,
    initialState,
    ...rest
  } = props

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>(
      {
        data,
        columns,
        initialState: {
          ...initialState,
        },
        ...rest,
      },
      useExpanded,
    )

  const colSpan = columns.length + 1

  return (
    <Flex
      sx={{
        minH: '700px',
        flexDir: 'column',
      }}
    >
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } =
              headerGroup.getHeaderGroupProps()
            return (
              <Tr key={key} {...headerGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...headerProps } = column.getHeaderProps()
                  const { isSorted, canSort, width } = column

                  return (
                    <Th
                      key={key}
                      {...headerProps}
                      sx={thStyle({ isSorted, canSort })}
                      width={width}
                    >
                      {column.render('Header')}
                    </Th>
                  )
                })}

                <Th
                  width={'40px'}
                  sx={{
                    bg: 'gray.100',
                  }}
                >
                  {''}
                </Th>
              </Tr>
            )
          })}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {isEmpty(rows) ? (
            <Tr>
              <Td colSpan={colSpan}>
                {renderNoData ? (
                  renderNoData()
                ) : (
                  <Box sx={{ textAlign: 'center', p: '8', color: 'gray.900' }}>
                    {t('views.components.table.no_data', undefined, 'No Data')}
                  </Box>
                )}
              </Td>
            </Tr>
          ) : (
            rows.map((row, index) => {
              prepareRow(row)

              const { canExpand, toggleRowExpanded, isExpanded } = row
              const { key, ...rowProps } = row.getRowProps()

              return (
                <Tr
                  key={key}
                  {...rowProps}
                  {...(canExpand && {
                    onClick: () => {
                      toggleRowExpanded()
                      const expandedState = Cookies.get(EXPANDED_STATE_COOKIE)
                      Cookies.set(
                        EXPANDED_STATE_COOKIE,
                        JSON.stringify({
                          ...(expandedState && JSON.parse(expandedState)),
                          [data[index]['folder_name'] as string]: !isExpanded,
                        }),
                      )
                    },
                  })}
                  sx={{
                    ...(canExpand && {
                      py: '10px',
                      cursor: 'pointer',
                    }),
                  }}
                >
                  {canExpand ? (
                    <Td colSpan={colSpan} sx={{ bg: 'brand.25', py: '10px' }}>
                      {renderToggleRow &&
                        renderToggleRow(row.original, isExpanded)}
                    </Td>
                  ) : (
                    <Fragment>
                      {row.original.url === '' ? (
                        <Td colSpan={colSpan} sx={{ textAlign: 'center' }}>
                          {t('views.features.domains.index.empty_folder')}
                        </Td>
                      ) : (
                        <Fragment>
                          {row.cells.map((cell) => {
                            const { key, ...cellProps } = cell.getCellProps()

                            return (
                              <Td key={key} {...cellProps}>
                                {cell.render('Cell')}
                              </Td>
                            )
                          })}

                          {renderActionColumn?.Cell && (
                            <Td>{renderActionColumn.Cell(row?.original)}</Td>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Tr>
              )
            })
          )}
        </Tbody>
      </Table>
    </Flex>
  )
}
