import { memo } from 'react'
import { Center, Box, keyframes, CSSObject } from '@chakra-ui/react'

type DotsLoaderProps = {
  size?: number
  sx?: CSSObject
}

export const DotsLoader = memo((props: DotsLoaderProps) => {
  const { size = 40, sx } = props

  const circleWidth = size / 6
  const distance = size / 4
  const kf = keyframes`
        0% {
          box-shadow: #ea834f -${distance}px -${distance}px 0 ${circleWidth}px, #ec8f60 ${distance}px -${distance}px 0 ${circleWidth}px, #f2af8c ${distance}px ${distance}px 0 ${circleWidth}px, #faceb8 -${distance}px ${distance}px 0 ${circleWidth}px;
        }
        8.33% {
          box-shadow: #ea834f ${distance}px -${distance}px 0 ${circleWidth}px, #ec8f60 ${distance}px -${distance}px 0 ${circleWidth}px, #f2af8c ${distance}px ${distance}px 0 ${circleWidth}px, #faceb8 -${distance}px ${distance}px 0 ${circleWidth}px;
        }
        16.67% {
          box-shadow: #ea834f ${distance}px ${distance}px 0 ${circleWidth}px, #ec8f60 ${distance}px ${distance}px 0 ${circleWidth}px, #f2af8c ${distance}px ${distance}px 0 ${circleWidth}px, #faceb8 -${distance}px ${distance}px 0 ${circleWidth}px;
        }
        25% {
          box-shadow: #ea834f -${distance}px ${distance}px 0 ${circleWidth}px, #ec8f60 -${distance}px ${distance}px 0 ${circleWidth}px, #f2af8c -${distance}px ${distance}px 0 ${circleWidth}px, #faceb8 -${distance}px ${distance}px 0 ${circleWidth}px;
        }
        33.33% {
          box-shadow: #ea834f -${distance}px -${distance}px 0 ${circleWidth}px, #ec8f60 -${distance}px ${distance}px 0 ${circleWidth}px, #f2af8c -${distance}px -${distance}px 0 ${circleWidth}px, #faceb8 -${distance}px -${distance}px 0 ${circleWidth}px;
        }
        41.67% {
          box-shadow: #ea834f ${distance}px -${distance}px 0 ${circleWidth}px, #ec8f60 -${distance}px ${distance}px 0 ${circleWidth}px, #f2af8c -${distance}px -${distance}px 0 ${circleWidth}px, #faceb8 ${distance}px -${distance}px 0 ${circleWidth}px;
        }
        50% {
          box-shadow: #ea834f ${distance}px ${distance}px 0 ${circleWidth}px, #ec8f60 -${distance}px ${distance}px 0 ${circleWidth}px, #f2af8c -${distance}px -${distance}px 0 ${circleWidth}px, #faceb8 ${distance}px -${distance}px 0 ${circleWidth}px;
        }
        58.33% {
          box-shadow: #ea834f -${distance}px ${distance}px 0 ${circleWidth}px, #ec8f60 -${distance}px ${distance}px 0 ${circleWidth}px, #f2af8c -${distance}px -${distance}px 0 ${circleWidth}px, #faceb8 ${distance}px -${distance}px 0 ${circleWidth}px;
        }
        66.67% {
          box-shadow: #ea834f -${distance}px -${distance}px 0 ${circleWidth}px, #ec8f60 -${distance}px -${distance}px 0 ${circleWidth}px, #f2af8c -${distance}px -${distance}px 0 ${circleWidth}px, #faceb8 ${distance}px -${distance}px 0 ${circleWidth}px;
        }
        75% {
          box-shadow: #ea834f ${distance}px -${distance}px 0 ${circleWidth}px, #ec8f60 ${distance}px -${distance}px 0 ${circleWidth}px, #f2af8c ${distance}px -${distance}px 0 ${circleWidth}px, #faceb8 ${distance}px -${distance}px 0 ${circleWidth}px;
        }
        83.33% {
          box-shadow: #ea834f ${distance}px ${distance}px 0 ${circleWidth}px, #ec8f60 ${distance}px -${distance}px 0 ${circleWidth}px, #f2af8c ${distance}px ${distance}px 0 ${circleWidth}px, #faceb8 ${distance}px ${distance}px 0 ${circleWidth}px;
        }
        91.67% {
          box-shadow: #ea834f -${distance}px ${distance}px 0 ${circleWidth}px, #ec8f60 ${distance}px -${distance}px 0 ${circleWidth}px, #f2af8c ${distance}px ${distance}px 0 ${circleWidth}px, #faceb8 -${distance}px ${distance}px 0 ${circleWidth}px;
        }
        100% {
          box-shadow: #ea834f -${distance}px -${distance}px 0 ${circleWidth}px, #ec8f60 ${distance}px -${distance}px 0 ${circleWidth}px, #f2af8c ${distance}px ${distance}px 0 ${circleWidth}px, #faceb8 -${distance}px ${distance}px 0 ${circleWidth}px;
        }
        `

  const animation = `${kf} infinite 3s linear`

  return (
    <Center sx={{ boxSize: size + 'px', ...sx }}>
      <Box
        sx={{
          boxSize: '2px',
          borderRadius: '50%',
        }}
        animation={animation}
      ></Box>
    </Center>
  )
})
