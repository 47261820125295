import { useState } from 'react'
import { Box, Center, Container } from '@chakra-ui/react'

import { Footer, Logo, NavBar } from '@/components/Layout'
import { withAuthPage } from '@/features/layout'

import { EmailResubscribeSuccess, EmailUnsubscribeSuccess } from '../components'
import { EmailUnsubscribe } from '../containers'
import { useEmailResubscribe } from '../mutations'

type Props = {
  email: string
  email_type: 'promotion' | 'knowledge'
  subscribed: boolean
  reasons: Record<string, string>
  token: string
}

function EmailUnsubscribePage(props: Props): React.ReactElement {
  const { email_type, subscribed, reasons, token } = props

  const [step, setStep] = useState(subscribed ? 0 : 1)

  const { mutate: resubscribe } = useEmailResubscribe()

  const components: Record<number, React.ReactElement> = {
    0: (
      <EmailUnsubscribe
        email_type={email_type}
        reasons={reasons}
        token={token}
        onSuccess={() => setStep(1)}
      />
    ),
    1: (
      <EmailUnsubscribeSuccess
        onResubscribe={() => {
          resubscribe(
            {
              data: {
                subscription: token,
              },
            },
            {
              onSuccess() {
                setStep(2)
              },
            },
          )
        }}
      />
    ),
    2: <EmailResubscribeSuccess />,
  }

  return (
    <Box sx={{ minH: '100vh', h: '100%' }}>
      <NavBar isShowMenus={false} />
      <Container
        sx={{
          p: 0,
          maxW: '580px',
          mx: 'auto',
          minH: 'calc(100vh - 56px - 56px)',
          py: '40px',
        }}
      >
        <Box
          sx={{
            bg: 'white',
            borderRadius: '20px',
          }}
        >
          <Center sx={{ h: '89px', boxShadow: 'inset 0 -8px 0 0 #ea834f' }}>
            <Logo
              sx={{
                w: '153px',
                h: '33px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            />
          </Center>

          {components[step]}
        </Box>
      </Container>
      <Footer />
    </Box>
  )
}

export default withAuthPage(EmailUnsubscribePage)
