import { noop } from 'lodash'
import {
  Box,
  Flex,
  Grid,
  IconButton,
  Icon,
  Button,
  CSSObject,
} from '@chakra-ui/react'
import {
  CgChevronLeft,
  CgChevronRight,
  CgChevronDoubleLeft,
  CgChevronDoubleRight,
} from 'react-icons/cg'
import * as d3 from 'd3-format'

const BUTTON_TO_SHOW = 6

type Props = {
  page: number
  totalPage: number
  onChangePage: (page: number) => void
}

export const Pagination = (props: Props) => {
  const { page, totalPage, onChangePage = noop } = props

  const renderButton = () => {
    if (totalPage <= BUTTON_TO_SHOW) {
      return Array(totalPage)
        .fill(undefined)
        .map((_, idx) => {
          const isActive = page === idx + 1

          return (
            <Button
              key={idx}
              sx={buttonStyle(isActive)}
              variant="link"
              colorScheme="brand"
              onClick={isActive ? noop : () => onChangePage(idx + 1)}
            >
              {d3.format(',')(idx + 1)}
            </Button>
          )
        })
    }

    const pages = []

    if (page + BUTTON_TO_SHOW - totalPage >= 2) {
      const numberVisibleInRange = totalPage - BUTTON_TO_SHOW
      pages.push(numberVisibleInRange)
      pages.push('. . .')

      for (let i = totalPage - 4; i <= totalPage; i += 1) {
        pages.push(i)
      }
    } else {
      if (page === 1) {
        for (let i = page; i <= page + 2; i += 1) {
          pages.push(i)
        }
      } else {
        for (let i = page - 1; i <= page + 1; i += 1) {
          pages.push(i)
        }
      }

      pages.push('. . .')

      for (let i = totalPage - 2; i <= totalPage; i += 1) {
        pages.push(i)
      }
    }

    return pages.map((p) => {
      if (p === '. . .') {
        return (
          <Box fontWeight="300" key={p}>
            {p}
          </Box>
        )
      }

      return (
        <Button
          key={p}
          onClick={() => onChangePage(p)}
          variant="link"
          colorScheme="brand"
          sx={buttonStyle(page === p)}
        >
          {typeof p === 'number' ? d3.format(',')(p) : p}
        </Button>
      )
    })
  }

  return (
    <Flex justify="center">
      <Flex align="center" id="Pagination">
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronDoubleLeft} />}
          isDisabled={page === 1}
          onClick={() => onChangePage(1)}
          aria-label="first-page"
        />
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronLeft} />}
          mr="8px"
          isDisabled={page === 1}
          onClick={() => onChangePage(page - 1)}
          aria-label="previous-page"
        />

        <Grid
          sx={{
            gridAutoFlow: 'column',
            gap: '12px',
            alignItems: 'center',
            gridAutoColumns: 'max-content',
          }}
        >
          {renderButton()}
        </Grid>

        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronRight} />}
          ml="8px"
          isDisabled={page >= totalPage}
          onClick={() => onChangePage(page + 1)}
          aria-label="next-page"
        />
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronDoubleRight} />}
          isDisabled={page >= totalPage}
          onClick={() => onChangePage(totalPage)}
          aria-label="last-page"
        />
      </Flex>
    </Flex>
  )
}

const buttonStyle = (isActive: boolean): CSSObject => {
  return {
    color: 'black',
    fontSize: '18px',
    fontWeight: 400,
    height: '40px',
    p: 'relative',
    px: 0,
    letterSpacing: '-0.7px',
    minW: '32px',
    _hover: {
      color: 'brand.400',
    },
    _before: {
      bg: 'transparent',
      borderRadius: '4px',
      bottom: '4px',
      content: '""',
      d: 'block',
      h: '4px',
      pos: 'absolute',
      w: '100%',
      ...(isActive && {
        bg: 'brand.400',
      }),
    },

    ...(isActive && {
      color: 'brand.400',
      variant: 'solid',
      cursor: 'unset',
      fontWeight: 700,
    }),
  }
}

const arrowButtonStyle = {
  w: '40px',
  h: '40px',
  minW: '0',
  fontSize: '28px',
  variant: 'ghost',
  color: 'brand.400',
  borderRadius: '50%',
}
