import { Circle, Flex, Grid, Text } from '@chakra-ui/layout'
import { CSSObject } from '@chakra-ui/styled-system'

type ColorItemProps = {
  text: string
  color: string
  value: number
}

export const ColorItem = (props: ColorItemProps) => {
  const { color, text, value } = props

  return (
    <Flex sx={listItemStyle}>
      <Grid
        sx={{
          gridTemplateColumns: '8px max-content',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <Circle sx={{ boxSize: '8px', bg: color }} />
        <Text>{text}</Text>
      </Grid>

      <Text>{value}</Text>
    </Flex>
  )
}

const listItemStyle: CSSObject = {
  fontSize: 'inherit',
  justifyContent: 'space-between',
  alignItems: 'center',
  '> p': {
    fontWeight: 'bold',
  },
}
