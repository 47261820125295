import { extendTheme } from '@chakra-ui/react'

import colors from './colors'
import layerStyles from './layerStyles'
import textStyles from './textStyles'
import components from './components'

const theme = extendTheme({
  colors,
  layerStyles,
  textStyles,
  components,
  fonts: {
    heading: 'Noto Sans Thai, Noto Sans, sans-serif',
    body: 'Noto Sans Thai, Noto Sans, sans-serif',
  },
})

export default theme
