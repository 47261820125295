import { get } from 'lodash'
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { IoIosWarning } from 'react-icons/io'
import * as d3 from 'd3-format'
import { Trans, useTranslation } from 'react-i18next'

import { formatDateTime, formatThousand, t } from '@/lib/helpers'

import {
  usePackageStore,
  useUpdatePaymentStore,
} from '@/features/payments/stores'
import { useAppContext } from '@/features/layout'
import { useQuotaData } from '@/features/users/queries'

import { PLAN_IMAGE } from '../../constants'
import Amex from '@/images/free-trial/brands/amex.png'
import Visa from '@/images/free-trial/brands/visa.png'
import Master from '@/images/free-trial/brands/master.png'
import { CreditCard } from '../../types'

export const PackageBanner = () => {
  const { currentPackage, resetOn } = usePackageStore()
  const { subscriptionCreditCard } = useUpdatePaymentStore()
  const quota = useQuotaData()

  const { t: tPackages } = useTranslation('packages')
  const { t: tCommon } = useTranslation('common')

  const pageviewUsed = quota?.page_view.used || 0
  const pageviewLimit = quota?.page_view.limit || 0
  const domainUsed = quota?.domain.used || 0
  const domainLimit = quota?.domain.limit

  // TODO Handle Enterprise case
  const isExceed =
    pageviewUsed > pageviewLimit && get(currentPackage, 'name') !== 'large'

  return (
    <Box id="current-package" sx={{ p: '24px 16px' }}>
      <Grid
        sx={{
          gridTemplateColumns: '2fr 1fr 1fr',
          gap: '16px',
          '> *': {
            bg: 'brand.25',
            border: '1px solid',
            borderColor: 'brand.100',
            borderRadius: '16px',
          },
        }}
      >
        <CurrentPackageDetail />
        <CurrentPackageItem
          used={formatThousand(domainUsed)}
          total={
            domainLimit
              ? formatThousand(domainLimit)
              : (tCommon('unlimited') as string)
          }
          title={tPackages('packageBanner.domains')}
          unit={tPackages('packageBanner.perAccount')}
        />
        <CurrentPackageItem
          used={formatThousand(pageviewUsed)}
          total={
            pageviewLimit
              ? formatThousand(pageviewLimit)
              : (tCommon('unlimited') as string)
          }
          title={tPackages('packageBanner.pageviews')}
          unit={tPackages('packageBanner.perMonth')}
          isExceed={isExceed}
          resetOn={resetOn}
        />
      </Grid>
      {!!subscriptionCreditCard && (
        <PaymentMethodItem subscriptionCreditCard={subscriptionCreditCard} />
      )}
    </Box>
  )
}

const CurrentPackageDetail = () => {
  const {
    cancelAtPeriodEnd,
    currentPackage,
    resubscribeDisclosure,
    unsubscribeDisclosure,
    willDowngradeToPackage,
  } = usePackageStore()
  const { name } = currentPackage
  const { isFree } = useAppContext()

  const { t: tPackages } = useTranslation('packages')

  const renderText = () => {
    const expiry = formatDateTime(get(currentPackage, 'expiry'), 'DD MMM YYYY')

    if (isFree) {
      return (
        <Text>
          <Trans t={tPackages} i18nKey="packageBanner.freePlanDetail"></Trans>
        </Text>
      )
    }

    if (willDowngradeToPackage) {
      return (
        <Text>
          {t(
            'views.features.packages.packages.banner.current_package_valid_until',
            {
              expiry,
            },
          )}
          <br />
          {t(
            'views.features.packages.packages.banner.after_that_will_changed_to',
            {
              package_name: willDowngradeToPackage.toTitleCase(),
            },
          )}
        </Text>
      )
    }

    if (cancelAtPeriodEnd) {
      return (
        <Text>
          <Trans t={tPackages} i18nKey="packageBanner.cancelAtPeriodEndText">
            คุณได้ยกเลิกแพ็กเกจนี้แล้ว ใช้ได้ถึงวันที่&nbsp;
            <Text as="span" fontWeight="semibold">
              {{ expiry }}
            </Text>
          </Trans>
        </Text>
      )
    }

    return (
      <Text>
        {t('views.features.packages.packages.banner.renew')}&nbsp;
        <Text as="span" fontWeight="semibold">
          {expiry}
        </Text>
      </Text>
    )
  }

  const renderLink = () => {
    if (cancelAtPeriodEnd) {
      return (
        <Button
          variant="link"
          colorScheme="green"
          onClick={resubscribeDisclosure.onOpen}
          size="sm"
        >
          {t('views.shared.button.resubscribe')}
        </Button>
      )
    }

    return (
      <Button
        variant="link"
        colorScheme="brand"
        onClick={unsubscribeDisclosure.onOpen}
        size="sm"
      >
        {t('views.shared.button.unsubscribe')}
      </Button>
    )
  }

  return (
    <Grid
      sx={{ gridTemplateColumns: '140px 1fr', gap: '40px', p: '16px 24px' }}
    >
      <Image
        src={PLAN_IMAGE[name]}
        boxSize="140px"
        sx={{ boxSize: '140px', alignSelf: 'center' }}
      />

      <Box>
        <Text>{tPackages('packageBanner.yourCurrentPackage')}</Text>
        <Text
          sx={{
            color: 'brand.400',
            fontSize: '32px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            mt: isFree ? 2 : 0,
          }}
        >
          {name} Package
        </Text>

        {!isFree && (
          <Text sx={{ mt: 1, fontWeight: 'semibold' }}>
            {d3.format(',d')(
              get(currentPackage, 'recurring_details.recurring_amount'),
            )}
            .- / {t(`views.misc.${get(currentPackage, 'recurring')}`)}
          </Text>
        )}

        <Box sx={{ color: 'gray.600', fontSize: 'sm' }}>{renderText()}</Box>

        {!isFree && <Box sx={{ mt: 1 }}>{renderLink()}</Box>}
      </Box>
    </Grid>
  )
}

type CurrentPackageItemProps = {
  used: string
  total: number | string
  title: string
  unit: string
  isExceed?: boolean
  resetOn?: string
}

const CurrentPackageItem = (props: CurrentPackageItemProps) => {
  const { used, total, title, unit, isExceed, resetOn } = props

  const { t: tPackages } = useTranslation('packages')

  return (
    <Flex sx={{ px: '16px', pt: '40px', pb: '26px', justifyContent: 'center' }}>
      <VStack>
        <VStack>
          <Text
            sx={{ fontSize: '32px', color: 'brand.400', fontWeight: 'bold' }}
          >
            {used}
            <Text
              as="span"
              sx={{ fontSize: 'xl', color: 'initial', fontWeight: 'semibold' }}
            >
              &nbsp;/ {total}
            </Text>
          </Text>

          <VStack spacing="0">
            <Tooltip
              hasArrow
              label={t('views.features.packages.packages.banner.exceed')}
              bg="#2b2b2bdb"
              size="lg"
              isDisabled={!isExceed}
            >
              <HStack sx={{ fontWeight: 'bold' }}>
                {isExceed && (
                  <Icon
                    as={IoIosWarning}
                    sx={{
                      transform: 'translateY(-1px)',
                      boxSize: '20px',
                      color: '#FFC23E',
                    }}
                  />
                )}
                <Text>{title}</Text>
              </HStack>
            </Tooltip>
            <Text sx={{ fontSize: 'xs', color: 'gray.600' }}>{unit}</Text>
          </VStack>

          {resetOn && (
            <Text
              sx={{ color: '#C6865C', fontSize: 'xs', fontWeight: 'semibold' }}
            >
              {tPackages('packageBanner.resetOn')}:&nbsp;
              {formatDateTime(resetOn, 'DD MMM YYYY')}
            </Text>
          )}
        </VStack>
      </VStack>
    </Flex>
  )
}

type PaymentMethodItemProps = {
  subscriptionCreditCard?: CreditCard
}

const PaymentMethodItem = (props: PaymentMethodItemProps) => {
  const { subscriptionCreditCard } = props

  const renderIcon = (card?: CreditCard) => {
    if (!card) return
    switch (card.brand) {
      case 'american-express':
        return <Image src={Amex} sx={{ h: '24px' }} />
      case 'visa':
        return <Image src={Visa} sx={{ h: '24px' }} />
      case 'mastercard':
        return <Image src={Master} sx={{ h: '24px' }} />
      default:
        return
    }
  }

  return (
    <Stack
      sx={{
        bg: 'brand.25',
        border: '1px solid',
        borderColor: 'brand.100',
        borderRadius: '16px',
        py: '4',
        px: '6',
        mt: '4',
        spacing: '2',
      }}
    >
      <Text sx={{ fontWeight: 700, color: 'gray.900' }}>
        {t('views.features.payments.contents.sub_title')}
      </Text>
      <HStack spacing="4">
        <Text sx={{ fontSize: '14px', color: 'gray.600' }}>
          {t('views.features.payments.contents.card')}
        </Text>
        <HStack spacing="2">
          {renderIcon(subscriptionCreditCard)}
          <Text>{subscriptionCreditCard?.number}</Text>
        </HStack>
        <Link
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            color: 'brand.400',
            textDecoration: 'underline',
            textTransform: 'uppercase',
          }}
          href="/settings/credit_cards"
        >
          {t('views.features.payments.contents.change')}
        </Link>
      </HStack>
    </Stack>
  )
}
