import { Button, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@/components/FormControl'
import { PasswordInput } from '@/components/Input'

import { VerifyPasswordFormInput } from '../types'

export type VerifyPasswordFormProps = {
  onSubmit: (data: VerifyPasswordFormInput) => void
  onCancel: () => void
  isLoading: boolean
}

export const VerifyPasswordForm = (props: VerifyPasswordFormProps) => {
  const { isLoading, onCancel, onSubmit } = props

  const { t: tSettings } = useTranslation('settings')
  const { t: tCommon } = useTranslation('common')

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<VerifyPasswordFormInput>()

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (isSubmitting) return

        onSubmit(data)
      })}
    >
      <Stack spacing="6">
        <FormControl
          label={tSettings('verifyPasswordForm.password.label')}
          isRequired
        >
          <PasswordInput
            size="lg"
            placeholder={tSettings('verifyPasswordForm.password.placeholder')}
            {...register('password')}
          />
        </FormControl>

        <Stack>
          <Button size="xl" type="submit" isLoading={isLoading}>
            {tCommon('confirm')}
          </Button>
          <Button
            size="xl"
            variant="ghost"
            colorScheme="gray"
            isDisabled={isLoading}
            onClick={onCancel}
            sx={{ color: 'gray.600' }}
          >
            {tCommon('discard')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
