import { useEffect, useState } from 'react'
import { get } from 'lodash'
import {
  Box,
  Button,
  Grid,
  Input,
  useToast,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'

import { usePaymentServices } from '@/features/payments/services/PaymentServices'

import { getErrorMsg } from '@/lib/helpers'

export const StripeForm = (props) => {
  const { onClose } = props

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [readyState, setReadyState] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })

  const toast = useToast()
  const stripe = useStripe()
  const elements = useElements()

  const PaymentServices = usePaymentServices()

  const checkReady = () => {
    return Object.values(readyState).every(Boolean)
  }

  const renderErrors = () => {
    return (
      <UnorderedList sx={{ color: 'red.400' }}>
        {Object.values(errors).map((err) => {
          return <ListItem key={err}>{err}</ListItem>
        })}
      </UnorderedList>
    )
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!checkReady()) {
      return setErrors((state) => ({
        ...state,
        form: 'Please fill all the inputs',
      }))
    }

    setLoading(true)

    if (!stripe || !elements) {
      return
    }

    const clientSecret = await PaymentServices.setupIntent({
      onError: () => setLoading(false),
    })

    if (!clientSecret) return

    try {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: get(event, 'target.name.value'),
          },
        },
      })

      if (result.error) {
        toast({
          title: get(result, 'error.message'),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      } else {
        PaymentServices.addNewCard(
          {
            paymentId: result.setupIntent.payment_method,
            isDefault: false,
          },
          {
            onSuccess: () => {
              onClose()
              setLoading(false)
            },
          },
        )
      }
    } catch (error) {
      toast({
        title: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = ({ error, elementType, complete }) => {
    setReadyState((state) => ({ ...state, [elementType]: complete }))

    if (error) {
      setErrors((state) => ({ ...state, [elementType]: get(error, 'message') }))
    }

    if (errors[elementType] && !error) {
      setErrors((state) => {
        const { [elementType]: _, ...rest } = state

        return rest
      })
    }
  }

  useEffect(() => {
    if (errors.form) {
      if (checkReady()) {
        setErrors((state) => {
          const { form, ...rest } = state

          return rest
        })
      }
    }
  }, [readyState])

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Grid sx={{ gap: '16px' }}>
          <Box>
            <label>
              ชื่อผู้ถือบัตร
              <Input
                name="name"
                placeholder="ชื่อผู้ถือบัตร"
                className="StripeElement"
                isRequired
                sx={{ border: 'none' }}
                _focus={{ border: 'none' }}
              />
            </label>
          </Box>

          <Box>
            <label>
              หมายเลขบัตรเครดิต
              <CardNumberElement onChange={handleChange} />
            </label>
          </Box>

          <Box>
            <label>
              วันหมดอายุบัตร MM / YY
              <CardExpiryElement onChange={handleChange} />
            </label>
          </Box>

          <Box>
            <label>
              รหัส CVV
              <CardCvcElement onChange={handleChange} />
            </label>
          </Box>

          {renderErrors()}

          <Button
            type="submit"
            disabled={!stripe || loading}
            isLoading={loading}
            colorScheme="brand"
            sx={{ justifySelf: 'end' }}
          >
            บันทึกบัตรใบนี้
          </Button>
        </Grid>
      </Box>
    </form>
  )
}
