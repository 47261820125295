import {
  Button,
  Image,
  ModalBody,
  Stack,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'

import { Modal, ModalHeader, Span } from '@/components/shared'

import CookieWarning from '@/images/cookies/cookie-warning.svg'

type NoticeOnlyWarningModalProps = {
  disclosure: UseDisclosureReturn
  isLoading: boolean
  onClick: () => void
}

export const NoticeOnlyWarningModal = (props: NoticeOnlyWarningModalProps) => {
  const { disclosure, isLoading, onClick } = props
  const { isOpen, onClose } = disclosure

  const { t } = useTranslation('domainSettings')
  const { t: tCommon } = useTranslation('common')

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Image src={CookieWarning} sx={{ boxSize: '134px', mx: 'auto', mt: 8 }} />
      <ModalHeader sx={{ textAlign: 'center', pb: 0 }}>
        {t('bannerModel.confirmBannerChanges')}
      </ModalHeader>
      <ModalBody>
        <Text sx={{ textAlign: 'center', w: '460px', mx: 'auto' }}>
          <Trans t={t} i18nKey="bannerModel.desc1">
            เมื่อทำการเปลี่ยนแปลงรูปแบบของแบนเนอร์&nbsp;
            <Span sx={{ color: 'brand.400', fontWeight: 'semibold' }}>
              สถิติของการให้ ความยินยอม (กราฟแสดงสถิติ)
              ของคุณจะถูกรีเซ็ตและเริ่มนับใหม่
            </Span>
            อย่างไรก็ตามการเปลี่ยนแปลงนี้จะไม่ส่งผลกับบันทึกความยินยอม
            โปรดตรวจสอบอีกครั้งก่อนทำการเผยแพร่
          </Trans>
        </Text>

        <br />

        <Text
          sx={{
            textAlign: 'center',
            color: 'gray.600',
            fontWeight: 'semibold',
          }}
        >
          {t('bannerModel.desc2')}
        </Text>

        <Stack sx={{ mt: 6 }}>
          <Button size="xl" isLoading={isLoading} onClick={onClick}>
            {tCommon('confirm')}
          </Button>
          <Button
            variant="outline"
            size="xl"
            isDisabled={isLoading}
            onClick={onClose}
          >
            {tCommon('cancel')}
          </Button>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
