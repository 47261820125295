import { ReactNode, Fragment } from 'react'
import {
  Box,
  Center,
  Menu,
  MenuButton,
  MenuList,
  Portal,
} from '@chakra-ui/react'

import { HiDotsHorizontal } from 'react-icons/hi'

type ActionColumnProps = {
  renderContent?: (props: any) => ReactNode
}

export const ActionColumn = (props: ActionColumnProps) => {
  const { renderContent } = props

  return (
    <Menu placement="bottom-end">
      {(popoverProps) => (
        <Fragment>
          <Box sx={{ position: 'relative' }}>
            <MenuButton>
              <Center
                layerStyle="actionButton"
                sx={{
                  '&:hover': {
                    bgColor: '#eee',
                  },
                }}
              >
                <HiDotsHorizontal size="20px" />
              </Center>
            </MenuButton>
          </Box>
          {renderContent && (
            <Portal>
              <MenuList
                sx={{
                  fontSize: '14px',
                  borderRadius: 'md',
                  py: '4px',
                  px: '0',
                  color: 'gray.900',
                  minWidth: '160px',
                  boxShadow:
                    'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px',
                }}
              >
                {renderContent({ ...popoverProps })}
              </MenuList>
            </Portal>
          )}
        </Fragment>
      )}
    </Menu>
  )
}
