import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { getPathParams } from '@/lib/routes'

import { PollingResponse } from '@/features/types'
import { CONSENT_CSV_STATUS } from '../constants'

type Params = {
  projectId: number
}

type ConsentCsvResponse = {
  data: PollingResponse
}

export const getConsentCsvStatus = async (
  params: Params,
): Promise<ConsentCsvResponse> => {
  const { projectId } = params

  const response = await fetchAPI({
    path: `/projects/${projectId}/consents/export`,
  })

  return {
    data: response.data,
  }
}

export const useConsentCsvStatus = () => {
  const query = getPathParams()

  const params = { projectId: Number(query?.project_id) }

  return useQuery<ConsentCsvResponse, AxiosError>({
    queryKey: [CONSENT_CSV_STATUS, params],
    queryFn: () => getConsentCsvStatus(params),
  })
}
