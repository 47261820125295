import { Grid, Flex } from '@chakra-ui/react'

import { useQuotaData } from '@/features/users/queries'
import { useAppContext } from '@/features/layout/ApplicationContext'

import { UserPanel } from './UserPanel'
import { PageviewInfo } from './PageviewInfo'
import { PackageInfo } from './PackageInfo'
import { Can } from '@/features/permissions/components'

export const ControlPanel = () => {
  const { currentPackage, isFree } = useAppContext()

  const quota = useQuotaData()

  const pageviewUsed = quota?.page_view.used ?? 0
  const pageviewLimit = quota?.page_view.limit

  return (
    <Grid
      sx={{
        h: '100%',
        justifySelf: 'end',
        gridTemplateColumns: 'repeat(3, max-content)',
      }}
    >
      <Can I="read" a="payment">
        {() => <PackageInfo isFree={isFree} currentPackage={currentPackage} />}
      </Can>

      <Flex
        sx={{
          px: '20px',
          borderRight: '1px solid',
          borderColor: 'gray.300',
          alignItems: 'center',
        }}
      >
        <PageviewInfo
          pageviewUsed={pageviewUsed}
          pageviewLimit={pageviewLimit}
        />
      </Flex>

      <UserPanel />
    </Grid>
  )
}
