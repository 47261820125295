import { Fragment } from 'react'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { Grid, Button, Text } from '@chakra-ui/react'

import { CurrentPackage } from '@/features/types'

import { formatDateTime, t } from '@/lib/helpers'
import { logEvent } from '@/lib/stats'

type PackageInfoProps = {
  isFree: boolean
  currentPackage: CurrentPackage
}

export const PackageInfo = (props: PackageInfoProps) => {
  const { isFree, currentPackage } = props

  const currentPackageName = get(currentPackage, 'name')
  const cancelAtPeriodEnd = get(currentPackage, 'cancel_at_period_end')

  return (
    <Grid
      sx={{
        alignContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: 'max-content 64px',
        gridTemplateRows: 'repeat(3, max-content)',
        columnGap: '12px',
        borderRight: '1px solid',
        borderColor: 'gray.300',
        px: '16px',
        ...(isFree && { gridTemplateRows: 'unset' }),
      }}
      id="NavUserPackage"
    >
      <Text
        sx={{
          color: 'gray.600',
          textTransform: 'uppercase',
          fontSize: '8px',
          fontWeight: 700,
        }}
      >
        {/* TODO i18n */}
        {currentPackageName} Package
      </Text>
      {!isFree && (
        <Fragment>
          <Text sx={{ fontSize: '12px', fontWeight: 600 }}>
            {formatDateTime(get(currentPackage, 'start_time'), 'DD MMM YYYY')}
            &nbsp;
            <Text
              as="span"
              sx={{ fontSize: '8px', fontWeight: 600, color: 'gray.600' }}
            >
              to
            </Text>
          </Text>

          <Text sx={{ fontSize: '8px', fontWeight: 600, color: 'gray.600' }}>
            {formatDateTime(get(currentPackage, 'expiry'), 'DD MMM YYYY')}
            &nbsp;
            {cancelAtPeriodEnd && (
              <Text
                as="span"
                sx={{
                  color: 'brand.400',
                  fontSize: '8px',
                  textDecor: 'italic',
                }}
              >
                <i>({dayjs(get(currentPackage, 'expiry')).fromNow()})</i>
              </Text>
            )}
          </Text>
        </Fragment>
      )}

      <Button
        size="xs"
        sx={{
          gridColumn: '2/3',
          gridRow: '1/-1',
          alignSelf: 'center',
          textTransform: 'capitalize',
        }}
        colorScheme="green"
        onClick={() => {
          logEvent({
            ga: {
              category: 'Upgrade',
              action: 'Click Upgrade',
            },
          })
          window.location.href = '/packages'
        }}
      >
        {t('views.shared.button.upgrade')}
      </Button>
    </Grid>
  )
}
