import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

import { useErrorHandler } from '@/lib/hooks'
import { fetchAPI } from '@/lib/api'

import { TOAST_CONFIG } from '@/lib/constants'
import { Invoice } from '../types'

type Payload = {
  data: {
    token: string
  }
}

export const useRemoveCoupon = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const { t: tPayment } = useTranslation('payment')

  return useMutation<Invoice, AxiosError, Payload>(
    async ({ data }) => {
      const { token } = data

      const response = await fetchAPI({
        method: 'delete',
        path: `/invoices/${token}/coupon`,
      })

      return response.data
    },
    {
      onSuccess() {
        toast({
          ...TOAST_CONFIG,
          status: 'info',
          variant: 'subtle',
          description: tPayment('coupon.toast.removeSuccess'),
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}
