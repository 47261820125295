import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

interface TagProps {
  children: React.ReactNode
}

export const Tag: React.FC<TagProps> = ({ children }) => {
  return (
    <Flex
      sx={{
        backgroundColor: 'brand.50',
        p: '4px 8px',
        color: 'brand.400',
        fontSize: '12px',
        fontWeight: 700,
        borderRadius: 'full',
      }}
    >
      <Text>{children}</Text>
    </Flex>
  )
}
