import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { BANNER_STATUS } from '../constants'
import { PollingResponse } from '@/features/types'

type Params = {
  projectId: number
}

type ApiResponse = {
  data: PollingResponse
}

export const getBannerStatus = async (params: Params): Promise<ApiResponse> => {
  const { projectId } = params

  const response = await fetchAPI({
    path: `/projects/${projectId}/banner_status`,
  })

  return {
    data: response.data,
  }
}

export const useBannerStatus = (params: { projectId: number }) => {
  return useQuery<ApiResponse, AxiosError>({
    queryKey: [BANNER_STATUS, params],
    queryFn: () => getBannerStatus(params),
  })
}
