import { Box, Grid, Text, Stack } from '@chakra-ui/layout'
import { useController, useFormContext } from 'react-hook-form'

import { LanguageSelect } from '@/components/Input/LanguageSelect'

import { t } from '@/lib/helpers'

export const PreviewLanguage = (): React.ReactElement => {
  const { control, trigger } = useFormContext()
  const { field } = useController({ control, name: 'preview_language' })

  return (
    <Stack spacing={2}>
      <Grid
        sx={{
          gridTemplateColumns: '1fr max-content',
          gridTemplateRows: 'max-content max-content',
          gap: 1,
          fontSize: '14px',
        }}
      >
        <Text>
          {t('views.features.domains.show.customize.content_language')}
        </Text>
        <Text sx={{ color: 'gray.600' }}>
          {t(
            'views.features.domains.show.customize.select_lang_to_customize',
            undefined,
            'Select language to customize',
          )}
        </Text>

        <Box sx={{ gridColumn: '2/-1', gridRow: '1/-1' }}>
          <LanguageSelect
            handleChange={async (lang) => {
              if (await trigger()) {
                field.onChange(lang)
              }
            }}
            value={field.value}
            id="PreviewLanguageSelect"
            isMini
          />
        </Box>
      </Grid>
    </Stack>
  )
}
