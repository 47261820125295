import AuthLayout from '@/features/auth/layouts/AuthLayout'
import ForgotPasswordForm from '@/features/auth/components/ForgotPasswordForm'

import Image from '@/images/project/auth/forgot_password.svg'

import { t } from '@/lib/helpers'
import { withAuthPage } from '@/features/layout'

type Props = {
  formActionPath: string
}

function ForgotPassword(props: Props): React.ReactElement {
  return (
    <AuthLayout
      image={Image}
      header={t('devise.passwords.new.forgot_your_password')}
    >
      <ForgotPasswordForm {...props} />
    </AuthLayout>
  )
}

export default withAuthPage(ForgotPassword)
