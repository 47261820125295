import { t } from '@/lib/helpers'

export const priceConfig: PriceConfig = {
  free: {
    planName: 'Free',
    description: 'ไม่มีค่าใช้จ่าย',
    details: {
      users: 1,
      pageView: 12_000,
      domains: 1,
      scanQuota: 50,
      noOfConsents: 100_000,
      retentionPeriod: 3,
      autoBlockScript: true,
      autoSaveConsent: true,
      customizeBanner: false,
      customizeLogo: false,
    },
    month: {
      price: 0,
    },
    year: {
      price: 0,
    },
  },
  small: {
    token: 'EhcScMoGnXNAruFD6bS5APNq',
    planName: 'Small',
    description: 'หรือ 2,400 บาทต่อปี (รวมภาษีมูลค่าเพิ่ม 7%)',
    details: {
      users: 1,
      pageView: 100_000,
      domains: 10,
      scanQuota: 500,
      noOfConsents: 5_000_000,
      retentionPeriod: 12,
      autoBlockScript: true,
      autoSaveConsent: true,
      customizeBanner: true,
      customizeLogo: true,
    },
    month: {
      price: 259,
    },
    year: {
      price: 2_400,
    },
  },
  medium: {
    token: 'wmHqv2akiv2poznaxoQ3FgXi',
    planName: 'Medium',
    description: 'หรือ 11,900 บาทต่อปี (รวมภาษีมูลค่าเพิ่ม 7%)',
    details: {
      users: 10,
      pageView: 500_000,
      domains: 200,
      scanQuota: 10_000,
      noOfConsents: t('views.features.packages.packages.unlimited'),
      retentionPeriod: 12,
      autoBlockScript: true,
      autoSaveConsent: true,
      customizeBanner: true,
      customizeLogo: true,
    },
    month: {
      price: 1_249,
    },
    year: {
      price: 11_900,
    },
  },
  large: {
    token: 'Gv5iiBAFSovwYYBmeHD3yH4u',
    planName: 'Large',
    description: 'หรือ 28,700 บาทต่อปี (รวมภาษีมูลค่าเพิ่ม 7%)',
    details: {
      users: t('views.features.packages.packages.unlimited'),
      pageView: 1_250_000,
      domains: t('views.features.packages.packages.unlimited'),
      scanQuota: 50_000,
      noOfConsents: t('views.features.packages.packages.unlimited'),
      retentionPeriod: 24,
      autoBlockScript: true,
      autoSaveConsent: true,
      customizeBanner: true,
      customizeLogo: true,
    },
    month: {
      price: 2_999,
    },
    year: {
      price: 28_700,
    },
  },
}

export const updatedPriceConfig = {
  free: {
    name: 'Free',
    description: 'pricingTable.free.description',
    details: {
      users: 1,
      pageView: 12_000,
      domains: 1,
      scanQuota: 50,
      consents: 100_000,
      retentionPeriod: 3,
      autoBlockScript: true,
      autoSaveConsent: true,
      customizeBanner: false,
      customizeLogo: false,
    },
    price: {
      month: 0,
      year: 0,
    },
  },
  small: {
    name: 'Small',
    description: 'pricingTable.small.description',
    details: {
      pageView: 100_000,
      domains: 10,
      users: 1,
      scanQuota: 500,
      consents: 5_000_000,
      retentionPeriod: 12,
      autoBlockScript: true,
      autoSaveConsent: true,
      customizeBanner: true,
      customizeLogo: true,
    },
    price: {
      month: 259,
      year: 2_400,
    },
  },
  medium: {
    name: 'Medium',
    description: 'pricingTable.medium.description',
    details: {
      pageView: 500_000,
      domains: 200,
      users: 10,
      scanQuota: 10_000,
      consents: Number.POSITIVE_INFINITY,
      retentionPeriod: 12,
      autoBlockScript: true,
      autoSaveConsent: true,
      customizeBanner: true,
      customizeLogo: true,
    },
    price: {
      month: 1_249,
      year: 11_900,
    },
  },
  large: {
    name: 'Large',
    description: 'pricingTable.large.description',
    details: {
      pageView: 1_250_000,
      domains: Number.POSITIVE_INFINITY,
      users: Number.POSITIVE_INFINITY,
      scanQuota: 50_000,
      consents: Number.POSITIVE_INFINITY,
      retentionPeriod: 24,
      autoBlockScript: true,
      autoSaveConsent: true,
      customizeBanner: true,
      customizeLogo: true,
    },
    price: {
      month: 2_999,
      year: 28_700,
    },
  },
}
