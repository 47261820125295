import { get, noop } from 'lodash'
import { useToast } from '@chakra-ui/react'
import { useStripe } from '@stripe/react-stripe-js'

import { axios, getErrorMsg } from '@/lib/helpers'

import { PAYMENT_METHOD } from '../constants'
import { usePaymentStore } from '../stores'
import { logEvent } from '@/lib/stats'

export const usePaymentServices = () => {
  const { token, paymentMethod, setRawCreditCards, onOpenTransferModal } =
    usePaymentStore()
  const toast = useToast()
  const stripe = useStripe()

  return {
    addNewCard: async (values, payload) => {
      const { onSuccess = noop } = payload

      try {
        const newData = await createCard(values)

        setRawCreditCards(newData)
        onSuccess()
        toast({
          title: 'Credit Card Created.',
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
        })

        logEvent({
          fb: { event: 'AddPaymentInfo' },
          ga: {
            category: 'Payment',
            action: 'AddPaymentInfo',
          },
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
    deleteCard: async (id) => {
      try {
        const newData = await deleteCard(id)
        setRawCreditCards(newData)
        toast({
          title: 'Credit Card Deleted.',
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
    setPrimaryCard: async (id) => {
      try {
        const newData = await setPrimaryCard(id)
        setRawCreditCards(newData)
        toast({
          title: 'Primary card has changed.',
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
    submitPayment: ({
      packageData,
      onSettled = noop,
    }: {
      packageData: {
        name: string
        price: number
      }
      onSettled?: (...args: any[]) => void
    }) => {
      const { CREDIT, TRANSFER } = PAYMENT_METHOD

      const handleSubmitByType = {
        [CREDIT]: async () => {
          function handlePaymentThatRequiresCustomerAction({ subscription }) {
            if (
              (subscription && subscription.status === 'active') ||
              subscription.status === 'not_started'
            ) {
              return { subscription }
            }

            const paymentIntent = subscription.latest_invoice.payment_intent

            if (paymentIntent.status === 'requires_action') {
              const paymentMethodId =
                subscription.latest_invoice.payment_intent.payment_method.id

              return stripe
                .confirmCardPayment(paymentIntent.client_secret, {
                  payment_method: paymentMethodId,
                })
                .then((result) => {
                  if (result.error) {
                    throw result
                  } else {
                    if (result.paymentIntent.status === 'succeeded') {
                      return {
                        subscription,
                      }
                    }
                  }
                })
                .catch((error) => {
                  toast({
                    title: error,
                    status: 'error',
                    position: 'top-right',
                    variant: 'left-accent',
                    isClosable: true,
                  })
                })
            } else {
              return { subscription }
            }
          }

          function handleRequiresPaymentMethod({ subscription }) {
            if (
              subscription.status === 'active' ||
              subscription.status === 'not_started'
            ) {
              return { subscription }
            } else if (
              subscription.latest_invoice.payment_intent.status ===
              'requires_payment_method'
            ) {
              localStorage.setItem(
                'latestInvoiceId',
                subscription.latest_invoice.id,
              )
              localStorage.setItem(
                'latestInvoicePaymentIntentStatus',
                subscription.latest_invoice.payment_intent.status,
              )
              throw new Error('Your card was declined.')
            } else {
              return { subscription }
            }
          }

          function onSubscriptionComplete(result) {
            if (
              result.subscription.status === 'active' ||
              result.subscription.status === 'not_started'
            ) {
              logEvent({
                ga: {
                  category: 'Payment Success',
                  action: 'Payment Success',
                  label: packageData.name,
                  value: packageData.price,
                },
                fb: {
                  event: 'Subscribe',
                  value: packageData.price,
                  currency: 'THB',
                  predicted_ltv: '0.00',
                },
              })
              window.location.href = `/payments/${token}/success`
            }
          }

          createChargeRecurring({
            cardId: paymentMethod.payload,
            token: token,
          })
            .then(({ data: subscription }) => {
              if (subscription.error) {
                throw subscription
              }

              return { subscription }
            })
            .then(handlePaymentThatRequiresCustomerAction)
            .then(handleRequiresPaymentMethod)
            .then(onSubscriptionComplete)
            .catch((error) => {
              toast({
                title: getErrorMsg(error),
                status: 'error',
                position: 'top-right',
                variant: 'left-accent',
                isClosable: true,
              })
            })
            .finally(() => onSettled())
        },
        [TRANSFER]: () => {
          onOpenTransferModal()
        },
      }[paymentMethod.type]

      handleSubmitByType()
    },
    setupIntent: async ({ onError = noop }) => {
      try {
        const clientSecret = await setupIntent()

        return clientSecret
      } catch (error) {
        onError()
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })

        return null
      }
    },
  }
}

const createCard = ({ paymentId, isDefault }) => {
  return axios
    .post('/credit_cards', {
      payment_method_id: paymentId,
      default_card: isDefault,
    })
    .then(({ data }) => data || [])
}

const setPrimaryCard = (id) => {
  return axios
    .put(`/credit_cards/${id}`, {
      default_card: true,
    })
    .then(({ data }) => {
      return data || []
    })
}

const deleteCard = (id) => {
  return axios.delete(`/credit_cards/${id}`).then(({ data }) => data || [])
}

const createChargeRecurring = ({ cardId, token }) => {
  return axios.post(`/recurring`, {
    token,
    credit_card_id: cardId,
  })
}

const setupIntent = () => {
  return axios
    .post('/credit_cards/setup')
    .then(({ data }) => get(data, 'client_secret'))
}
