export const signInURL = '/users/sign_in'
export const signUpURL =
  '/users/sign_up?return_to=' +
  encodeURIComponent('/packages/free_trial?package=medium')
export const forgotPasswordURL = '/users/password/new'
export const resendConfirmationURL = '/users/confirmation/new'
export const homeURL = 'https://www.cookiewow.com'

export const termsURL = 'https://cookiewow.com/terms'
export const privacyURL = 'https://cookiewow.com/privacy'
