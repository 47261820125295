import { useMutation } from 'react-query'

import { fetchAPI } from '@/lib/api'

const readNotification = () => {
  return fetchAPI({
    path: '/feature_updates/mark_as_read',
    method: 'post',
  })
}

export const useReadNotification = () => {
  return useMutation(readNotification)
}
