import { Button, Input, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@/components/FormControl'

import { ChangeEmailFormInput } from '../types'

type ChangeEmailFormProps = {
  onSubmit: (data: ChangeEmailFormInput) => void
  onCancel: () => void
  email: string
  isLoading: boolean
}

export const ChangeEmailForm = (props: ChangeEmailFormProps) => {
  const { email, onSubmit, onCancel, isLoading } = props

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ChangeEmailFormInput>()

  const { t: tSettings } = useTranslation('settings')
  const { t: tCommon } = useTranslation('common')

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (isSubmitting) return

        onSubmit(data)
      })}
    >
      <Stack spacing="6">
        <FormControl isDisabled label={tSettings('changeEmailForm.oldEmail')}>
          <Input value={email} size="lg" />
        </FormControl>
        <FormControl label={tSettings('changeEmailForm.newEmail')} isRequired>
          <Input
            {...register('email')}
            size="lg"
            placeholder={tSettings('changeEmailForm.newEmailPlaceholder')}
            type="email"
            autoFocus
          />
        </FormControl>

        <Stack>
          <Button type="submit" size="xl" isLoading={isLoading}>
            {tCommon('confirm')}
          </Button>
          <Button
            onClick={onCancel}
            variant="ghost"
            colorScheme="gray"
            size="xl"
            sx={{ color: 'gray.600' }}
            isDisabled={isLoading}
          >
            {tCommon('discard')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
