export default {
  tab: 'Cookie Scanner',
  scan: 'Scan Cookie',
  description_1:
    'Some known cookies that are found will be categorized. Categorized cookies will be automatically pushed into Manage Cookies tab. All cookies in Manage Cookies tab will be set blocked by Cookie Wow prior to consent.',
  description_2:
    'We try our best efforts to scan as much cookies as possible. However, sometimes we may not be able to detect 100% cookies on your website due to uncontrollable factors, we recommend that you recheck your cookie lists, and you can manually add cookies on <1>Manage Cookies</1> Page.',
  description_3:
    'Cookies are required to use for Cookie Wow banner to function correctly . You can read more about Cookie Wow’s use of cookies <1>here</1>.',
  category_updated: 'Category Updated',
  name: 'Name',
  domain_url: 'Domain / URL',
  type: 'type',
  duration: 'Duration',
  category: 'Category',
  last_scanned: 'Last Scanned',
  title_scanned_url: 'Scanned URL',
  service_provider: 'Service Provider',
  back_to_cookies_list: 'Back to cookies list',
  scan_modal: {
    title: 'Scan for cookies',
    description:
      'In order to optimize processing time, please limit your scan to only as many as necessary for your best benefit',
    description_2: '(up to 10 urls per day recommended).',
    number_scan: 'Number of page scans',
    remaining_for_your_package:
      'remaining for your package: %{remaining}/%{total} url',
  },
  unassigned: 'Unassign',
  include_label: 'Include URL',
  optional: 'optional',
  exclude_label: 'Exclude URL',
  exclude_scan_form_helper:
    'Enter URLs that you want to exclude in the scanning. Use comma ( , ) to separate multiple URLs.',
  include_scan_form_helper:
    'Enter URLs that you want to include in the scanning. Use comma ( , ) to separate multiple URLs.',
  scan_input_placeholder: 'type URL here (http:// or https://)',
  advanced_setting: 'Advanced Setting',
}
