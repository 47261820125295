import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  data: {
    reason: string
    subscription: string
  }
}

const emailUnsubscribe = async ({ data }: Payload) => {
  return await fetchAPI({
    path: `/unsubscribe`,
    method: 'post',
    data,
  })
}

export const useEmailUnsubscribe = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, Payload>(emailUnsubscribe, {
    async onSuccess() {
      await queryClient.refetchQueries([])

      toast({
        ...TOAST_CONFIG,
        description: 'ยกเลิกการรับอีเมลเรียบร้อยแล้ว',
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
