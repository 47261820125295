import { VStack, Input, Text, Button, Link } from '@chakra-ui/react'
import { useState } from 'react'

import { FormControl } from '@/components/FormControl'
import { PasswordInputValidator, PasswordInput } from '@/components/Input'

import {
  signInURL,
  resendConfirmationURL,
} from '@/features/auth/constants/links'
import { inputStyle, linkStyle } from '@/features/auth/constants/styles'

import { t } from '@/lib/helpers'
import { getAuthenticityToken } from '@/lib/api'
import { getQueryParam } from '@/lib/queryParams'

type Props = {
  initResetPasswordToken?: string
  formActionPath: string
  minimumPasswordLength: number
}

function SetPasswordForm({
  initResetPasswordToken,
  formActionPath,
  minimumPasswordLength,
}: Props): React.ReactElement {
  const authenticityToken = getAuthenticityToken()

  const resetPasswordToken =
    getQueryParam('reset_password_token') || initResetPasswordToken || ''
  const [password, setPassword] = useState('')

  return (
    <VStack
      spacing="4"
      sx={{
        alignItems: 'stretch',
      }}
    >
      <form
        id="new_user"
        action={formActionPath}
        acceptCharset="UTF-8"
        method="post"
      >
        <VStack
          spacing="4"
          sx={{
            alignItems: 'stretch',
          }}
        >
          <FormControl
            label={
              <Text>
                {t('activerecord.attributes.user.password')}{' '}
                {minimumPasswordLength ? (
                  <Text as="span" sx={{ fontWeight: 'light' }}>
                    {t('devise.shared.minimum_password_length', {
                      count: minimumPasswordLength,
                    })}
                  </Text>
                ) : (
                  ''
                )}
              </Text>
            }
          >
            <PasswordInputValidator
              isRequired
              autoFocus
              id="user_password"
              autoComplete="new-password"
              name="user[password]"
              minLength={minimumPasswordLength}
              sx={inputStyle}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              placeholder={t('activerecord.attributes.user.password')}
            />
          </FormControl>
          <FormControl
            label={t('activerecord.attributes.user.password_confirmation')}
          >
            <PasswordInput
              isRequired
              id="user_password_confirmation"
              autoComplete="new-password"
              name="user[password_confirmation]"
              minLength={minimumPasswordLength}
              sx={inputStyle}
              placeholder={t(
                'activerecord.attributes.user.password_confirmation',
              )}
            />
          </FormControl>

          <Input
            value={authenticityToken}
            type="hidden"
            name="authenticity_token"
          />
          <Input
            id="user_reset_password_token"
            value={resetPasswordToken}
            type="hidden"
            name="user[reset_password_token]"
          />
          <Input value="put" type="hidden" name="_method" />

          <Button
            type="submit"
            name="commit"
            size="xl"
            value={t('devise.passwords.edit.change_my_password')}
            data-disable-with={t('devise.passwords.edit.change_my_password')}
          >
            {t('devise.passwords.edit.change_my_password')}
          </Button>
        </VStack>
      </form>
      <VStack
        spacing="3"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Link href={signInURL} sx={linkStyle}>
          {t('devise.sessions.new.sign_in')}
        </Link>
        <Link href={resendConfirmationURL} sx={linkStyle}>
          {t('devise.shared.links.didn_t_receive_confirmation_instructions')}
        </Link>
      </VStack>
    </VStack>
  )
}

export default SetPasswordForm
