import {
  Box,
  Flex,
  HStack,
  Text,
  Icon,
  Tooltip as CKTooltip,
  Circle,
  Stack,
  Center,
} from '@chakra-ui/react'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { FiInfo } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { ConsentOverviewItem } from '../../types'
import { Fragment } from 'react'

export type ConsentOverviewProps = {
  data: ConsentOverviewItem[]
  isThisMonth: boolean
}

export const ConsentOverview = (props: ConsentOverviewProps) => {
  const { data, isThisMonth } = props

  const { t } = useTranslation('domainConsent')

  const barSize = data.length > 12 ? undefined : 26

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: '8px',
        py: 2,
        px: 4,
      }}
      id="consent-overview-card"
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <HStack>
          <Text sx={{ fontWeight: 'bold' }}>{t('consentOverview')}</Text>
          <CKTooltip
            label={t('consentOverviewTooltip')}
            aria-label="consent-overview-tooltip"
            placement="top"
          >
            <Center>
              <Icon as={FiInfo} color="gray.400" boxSize="16px" />
            </Center>
          </CKTooltip>
        </HStack>

        <HStack spacing="4">
          <ChartLegend color="brand.400" text={t('action.accepted_all')} />
          <ChartLegend color="brand.100" text={t('allConsentType')} />
        </HStack>
      </Flex>

      <ResponsiveContainer minWidth={500} minHeight={360}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 10,
            bottom: 10,
            left: -15,
          }}
        >
          <XAxis
            dataKey="time"
            scale="band"
            tick={<CustomizedAxisTick isThisMonth={isThisMonth} />}
            xAxisId={0}
            axisLine={{ stroke: '#e8e8e8' }}
          />
          <XAxis dataKey="time" scale="band" xAxisId={1} hide />
          <YAxis
            fontSize="10px"
            axisLine={{ stroke: '#e8e8e8' }}
            tickLine={{ stroke: '#e8e8e8' }}
            tick={{ fill: '#a3a3a3' }}
          />
          <Tooltip content={<CustomTooltip isThisMonth={isThisMonth} />} />

          <Bar
            dataKey="sessions"
            fill="#FFEDE0"
            xAxisId={1}
            barSize={barSize}
          />
          <Line
            type="monotone"
            dataKey="sessions"
            stroke="#F2AF8C"
            isAnimationActive={false}
          />

          <Bar
            dataKey="consents"
            fill="#EC8F60"
            xAxisId={0}
            barSize={barSize}
          />
          <Line
            type="monotone"
            dataKey="consents"
            stroke="#D96125"
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}

const CustomTooltip = (props: any) => {
  const { active, payload, label, isThisMonth } = props

  const { t } = useTranslation('domainConsent')

  if (active && payload && payload.length) {
    const date = dayjs(label)
      .locale('en')
      .format(isThisMonth ? 'D MMM YYYY' : 'MMM YYYY')

    return (
      <Stack
        spacing="1"
        sx={{
          bg: 'white',
          borderRadius: '4px',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
          padding: '8px 10px',
          fontSize: '10px',
        }}
      >
        <Text sx={{ fontWeight: 'semibold', color: 'brand.400' }}>{date}</Text>
        <Flex gap="16px" justify="space-between">
          <HStack>
            <Circle sx={{ boxSize: '8px', bg: 'brand.400' }} />
            <Text>{t('action.accepted_all')}</Text>
          </HStack>

          <Text>{payload[0].payload.consents}</Text>
        </Flex>
        <Flex gap="16px" justify="space-between">
          <HStack>
            <Circle sx={{ boxSize: '8px', bg: 'brand.100' }} />
            <Text>{t('allConsentType')}</Text>
          </HStack>

          <Text>{payload[0].payload.sessions}</Text>
        </Flex>
      </Stack>
    )
  }

  return null
}

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload, isThisMonth } = props

  const date = dayjs(payload.value)

  return (
    <g transform={`translate(${x},${y})`} fontSize="10px">
      {isThisMonth ? (
        <text x={0} y={0} dy={12} textAnchor="middle" fill="#888888">
          {date.format('D')}
        </text>
      ) : (
        <Fragment>
          <text x={0} y={0} dy={12} textAnchor="middle" fill="#888888">
            {date.locale('en').format('MMM')}
          </text>
          <text x={0} y={0} dy={24} textAnchor="middle" fill="#888888">
            {date.format('YYYY')}
          </text>
        </Fragment>
      )}
    </g>
  )
}

type ChartLegendProps = {
  color: string
  text: string
}

const ChartLegend = (props: ChartLegendProps) => {
  const { color, text } = props

  return (
    <HStack>
      <Circle sx={{ boxSize: '8px', bg: color }} />
      <Text sx={{ fontSize: 'xs', color: 'gray.600' }}>{text}</Text>
    </HStack>
  )
}
