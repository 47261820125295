import { Stack } from '@chakra-ui/react'

import { ActivityLogTable } from '../components'
import { ActivityLogFilterSection } from './ActivityLogFilterSection'
import { Pagination } from '@/components/Input'

import { useFilterContext } from '@/features/filters/context'
import { useActivityLogs } from '../queries'
import { ActivityLogFilterType } from '../types'
import { SelectOptions, FolderOptions } from '@/components/Select/types'

type Props = {
  folderOptions: FolderOptions
  functionOptions: SelectOptions
}

export const ActivityLogTableContainer = (props: Props) => {
  const { folderOptions, functionOptions } = props

  const { meta, setFilter } = useFilterContext<ActivityLogFilterType>()

  const { data: activityLogs, isLoading } = useActivityLogs({
    meta,
  })

  const page = activityLogs?.meta.page ?? 1
  const totalPage = Math.ceil(
    (activityLogs?.meta.total ?? 1) / (activityLogs?.meta.per ?? 1),
  )

  return (
    <Stack spacing="4">
      <ActivityLogFilterSection
        folderOptions={folderOptions}
        functionOptions={functionOptions}
      />

      {activityLogs?.data && (
        <ActivityLogTable data={activityLogs.data} isLoading={isLoading} />
      )}

      {totalPage > 1 && (
        <Pagination
          page={page}
          totalPage={totalPage}
          onChangePage={(page) => {
            setFilter('page', page)
          }}
        />
      )}
    </Stack>
  )
}
