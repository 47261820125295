import { ModalBody, ModalHeader, UseDisclosureReturn } from '@chakra-ui/react'

import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useUpdateCookie } from '../../mutations'

import { Modal } from '@/components/shared/Modal'
import { ManageCookieForm } from './ManageCookieForm'

import { BannerLanguage, Cookie, CookieFormInput } from '../../types'

type Props = {
  activeLanguage: BannerLanguage
  defaultValues: Cookie
  disclosure: UseDisclosureReturn
}

export const EditCookieModal = (props: Props) => {
  const { activeLanguage, disclosure, defaultValues } = props
  const { isOpen, onClose } = disclosure

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id), cookie_id: defaultValues.id }

  const { mutate: updateCookie, isLoading } = useUpdateCookie()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="560px" withCloseButton>
      <ModalHeader>
        {t('views.features.domains.show.manage.form.edit_cookie')}
      </ModalHeader>

      <ModalBody>
        <ManageCookieForm
          onSubmitForm={(data) => {
            updateCookie(
              { data, params },
              {
                onSuccess: onClose,
              },
            )
          }}
          onCancel={onClose}
          isLoading={isLoading}
          defaultValues={cookieIntoFormInput(defaultValues)}
          isScraped={defaultValues.scraped}
          activeLanguage={activeLanguage}
        />
      </ModalBody>
    </Modal>
  )
}

const cookieIntoFormInput = (cookie: Cookie): CookieFormInput => {
  return {
    name: cookie.name,
    category_id: String(cookie.category_id),
    duration: cookie.duration,
    purpose: cookie.purpose,
    url: cookie.url,
  }
}
