import { useRef } from 'react'
import { Button } from '@chakra-ui/button'
import { ModalHeader } from '@chakra-ui/modal'
import { Stack, Text } from '@chakra-ui/layout'
import { Trans } from 'react-i18next'
import * as d3 from 'd3-format'
import { get } from 'lodash'
import dayjs from 'dayjs'

import { Modal } from '@/components/shared'
import { SvgImage } from '@/components/DataDisplay'

import upgradePlan from '@/images/project/payments/upgrade-plan.png'

import { matchLanguage, t } from '@/lib/helpers'
import { logEvent } from '@/lib/stats'

import { usePackageStore } from '@/features/payments/stores'
import { priceConfig } from '../../config'
import { UpgradeModalProps } from './types'

export const UpgradeFreeModal = (props: UpgradeModalProps) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  const initialRef = useRef(null)

  const { period, plan, onCheckout } = usePackageStore()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withCloseButton
      initialFocusRef={initialRef}
      id="upgrade-free-modal"
    >
      <Stack justify="center" spacing="16px" sx={{ px: 8, py: 6 }}>
        <SvgImage
          src={upgradePlan}
          sx={{ w: '138px', h: '127px', mx: 'auto' }}
        />

        <ModalHeader
          sx={{
            textAlign: 'center',
            fontSize: '2xl',
            fontWeight: 400,
          }}
        >
          <Trans i18nKey={`features.packages.upgrade.subscribe_to_${period}`}>
            Subscribe to&nbsp;
            <Text
              as="span"
              sx={{
                textTransform: 'capitalize',
                fontWeight: matchLanguage(700, 600),
              }}
            >
              {{ package: plan }}
            </Text>
            &nbsp;Package&nbsp;
            <br />
            <Text
              as="span"
              sx={{
                textTransform: 'capitalize',
                fontWeight: matchLanguage(700, 600),
              }}
            >
              {{
                price: d3.format(',d')(
                  get(priceConfig, `${plan}.${period}.price`),
                ),
              }}
              THB.&nbsp;
            </Text>
            <Text
              as="span"
              sx={{
                fontWeight: matchLanguage(700, 600),
              }}
            >
              per {period}
            </Text>
          </Trans>
        </ModalHeader>

        <Text sx={{ textAlign: 'center' }}>
          {t('views.features.packages.packages.upgrade.next_billing', {
            date: dayjs().add(1, period).format('DD MMM YYYY'),
          })}
        </Text>

        <Button
          colorScheme="brand"
          size="xl"
          onClick={() => {
            logEvent({
              ga: {
                category: 'Upgrade',
                action: 'Click Choose Package',
                ...(plan && {
                  label: plan,
                  value: priceConfig[plan][period].price,
                }),
              },
            })
            onCheckout()
          }}
          ref={initialRef}
        >
          {t('views.shared.button.subscribe')}
        </Button>
        <Button variant="link" size="xl" onClick={onClose}>
          {t('views.shared.button.discard')}
        </Button>
      </Stack>
    </Modal>
  )
}
