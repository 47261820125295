import { Position } from '@datawowio/cookie-banner-ui'

import classicTop from '@/images/project/customize/banner-classic-top.svg'
import classicBottom from '@/images/project/customize/banner-classic-bottom.svg'
import pushTop from '@/images/project/customize/banner-push-top.svg'
import pushBottom from '@/images/project/customize/banner-push-bottom.svg'
import modernTop from '@/images/project/customize/banner-modern-top.svg'
import modernBottom from '@/images/project/customize/banner-modern-bottom.svg'
import stackTop from '@/images/project/customize/banner-stack-top.svg'
import stackBottom from '@/images/project/customize/banner-stack-bottom.svg'
import topLeft from '@/images/project/customize/banner-top-left.svg'
import topRight from '@/images/project/customize/banner-top-right.svg'
import bottomLeft from '@/images/project/customize/banner-bottom-left.svg'
import bottomRight from '@/images/project/customize/banner-bottom-right.svg'
import popup from '@/images/project/customize/banner-popup.svg'

import { t } from '@/lib/helpers'

export const positions: {
  id?: string
  img: any
  value: Position
  title: string
}[] = [
  {
    id: 'CustomizePosition-classicTop',
    img: classicTop,
    title: t(
      'views.features.domains.show.customize.classic_top',
      undefined,
      'Classic Top',
    ),
    value: 'classic-top',
  },
  {
    id: 'CustomizePosition-classicBottom',
    img: classicBottom,
    title: t(
      'views.features.domains.show.customize.classic_bottom',
      undefined,
      'Classic Bottom',
    ),
    value: 'classic-bottom',
  },
  {
    id: 'CustomizePosition-pushTop',
    img: pushTop,
    title: t(
      'views.features.domains.show.customize.push_top',
      undefined,
      'Push Top',
    ),
    value: 'fullwidth-top',
  },
  {
    id: 'CustomizePosition-pushBottom',
    img: pushBottom,
    title: t(
      'views.features.domains.show.customize.bottom',
      undefined,
      'Bottom',
    ),
    value: 'fullwidth-bottom',
  },
  {
    id: 'CustomizePosition-modernTop',
    img: modernTop,
    title: t(
      'views.features.domains.show.customize.modern_top',
      undefined,
      'Modern Top',
    ),
    value: 'modern-top',
  },
  {
    id: 'CustomizePosition-modernBottom',
    img: modernBottom,
    title: t(
      'views.features.domains.show.customize.modern_bottom',
      undefined,
      'Modern Bottom',
    ),
    value: 'modern-bottom',
  },
  {
    id: 'CustomizePosition-stackTop',
    img: stackTop,
    title: t(
      'views.features.domains.show.customize.stack_top',
      undefined,
      'Modern Top',
    ),
    value: 'stack-top',
  },
  {
    id: 'CustomizePosition-stackBottom',
    img: stackBottom,
    title: t(
      'views.features.domains.show.customize.stack_bottom',
      undefined,
      'Modern Bottom',
    ),
    value: 'stack-bottom',
  },
  {
    id: 'CustomizePosition-topLeft',
    img: topLeft,
    title: t(
      'views.features.domains.show.customize.top_left',
      undefined,
      'Top Left',
    ),
    value: 'side-top-left',
  },
  {
    id: 'CustomizePosition-topRight',
    img: topRight,
    title: t(
      'views.features.domains.show.customize.top_right',
      undefined,
      'Top Right',
    ),
    value: 'side-top-right',
  },
  {
    id: 'CustomizePosition-bottomLeft',
    img: bottomLeft,
    title: t(
      'views.features.domains.show.customize.bottom_left',
      undefined,
      'Bottom Left',
    ),
    value: 'side-bottom-left',
  },
  {
    id: 'CustomizePosition-bottomRight',
    img: bottomRight,
    title: t(
      'views.features.domains.show.customize.bottom_right',
      undefined,
      'Bottom Right',
    ),
    value: 'side-bottom-right',
  },
  {
    id: 'CustomizePosition-popup',
    img: popup,
    title: t('views.features.domains.show.customize.popup', undefined, 'Popup'),
    value: 'popup',
  },
]
