import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useErrorHandler } from '@/lib/hooks'
import { useCurrentUserData } from '@/features/users/queries'
import { useSurveyContext } from '../components/SurveyModal/context'

import { TOAST_CONFIG } from '@/lib/constants'
import { PROJECT } from '../constants'
import { AxiosError, AxiosResponse } from 'axios'

type Payload = {
  project_id: Project['id']
  reconsent_needed: boolean
}

const updateBannerVersion = async (payload: Payload) => {
  const { project_id, reconsent_needed } = payload

  return fetchAPI({
    path: `/projects/${project_id}/dialogs/version`,
    method: 'put',
    data: {
      reconsent_needed,
    },
  })
}

export const useUpdateBannerVersion = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()
  const userData = useCurrentUserData()
  const { disclosure } = useSurveyContext()

  const query = getPathParams()
  const params = { project_id: Number(query?.project_id) }

  return useMutation<AxiosResponse, AxiosError, Payload>(updateBannerVersion, {
    async onSuccess() {
      queryClient.invalidateQueries([PROJECT, params])

      if (userData?.display_survey) {
        disclosure.onOpen()
      } else {
        toast({
          ...TOAST_CONFIG,
          description: t('views.features.domains.index.update_success'),
        })
      }
    },
    onError(error) {
      handleError(error)
    },
  })
}
