import {
  Box,
  Button,
  HStack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import {
  useCancelUpdateEmail,
  useResendChangeEmailVerification,
} from '../mutations'
import { useCurrentUserData } from '@/features/users/queries'
import { formatDateTime } from '@/lib/helpers'

import { UpdateEmailSuccessModal } from '../containers'
import { ConfirmCancelUpdateEmailModal } from './ConfirmCancelUpdateEmailModal'

type ChangeEmailPendingProps = {
  email: string
}

export const ChangeEmailPending = (props: ChangeEmailPendingProps) => {
  const { email } = props

  const { t: tSettings } = useTranslation('settings')
  const { t: tCommon } = useTranslation('common')

  const { mutate: resendEmail, isLoading: resendLoading } =
    useResendChangeEmailVerification()
  const { mutate: cancelUpdateEmail, isLoading: cancelLoading } =
    useCancelUpdateEmail()

  const disclosure = useDisclosure()
  const successDisclosure = useDisclosure()

  const currentUser = useCurrentUserData()

  return (
    <Fragment>
      <Box sx={{ p: '8px 16px', bg: 'brand.25', borderRadius: '8px' }}>
        <Text sx={{ color: 'gray.500', fontWeight: 'medium' }}>
          <Trans t={tSettings} i18nKey="changeEmailPending.description">
            Pending change email. Please verify email to confirm your change
            within&nbsp;
            {{
              date: formatDateTime(
                dayjs(currentUser?.confirmation_sent_at).add(3, 'day'),
                'DD MMM YYYY',
              ),
            }}
            .
          </Trans>
        </Text>
        <Text sx={{ fontWeight: 'semibold', mt: 1 }}>{email}</Text>

        <HStack
          sx={{ mt: 3 }}
          divider={<StackDivider borderColor="brand.50" borderWidth="2px" />}
        >
          <Button
            variant="link"
            onClick={() =>
              resendEmail(undefined, {
                onSuccess: successDisclosure.onOpen,
              })
            }
            isLoading={resendLoading}
          >
            {tSettings('changeEmailPending.resendVerification')}
          </Button>
          <Button variant="link" onClick={disclosure.onOpen}>
            {tCommon('cancel')}
          </Button>
        </HStack>
      </Box>

      <ConfirmCancelUpdateEmailModal
        disclosure={disclosure}
        onConfirm={() =>
          cancelUpdateEmail(undefined, {
            onSuccess: disclosure.onClose,
          })
        }
        isLoading={cancelLoading}
      />
      <UpdateEmailSuccessModal disclosure={successDisclosure} email={email} />
    </Fragment>
  )
}
