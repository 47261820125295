export default {
  allTime: 'ทั้งหมด',
  allConsentType: 'ความยินยอมทั้งหมด',
  byCategory: 'โดยหมวดหมู่',
  byCategoryTooltip:
    'แสดงอัตราส่วนร้อยละและปริมาณของความยินยอมโดยแบ่งตามประเภทของคุกกี้',
  byStatus: 'โดยประเภทความยินยอม',
  byStatusTooltip: 'แสดงอัตราส่วนร้อยละและปริมาณของความยินยอมตามแต่ละรูปแบบ',
  consentLog: 'บันทึกความยินยอม',
  consentOverview: 'ภาพรวมความยินยอม',
  consentOverviewTooltip:
    'แสดงผลของความยินยอมที่ยอมรับทั้งหมด และจำนวนรวมของประเภทความยินยอมทั้งหมดในแกน y และระยะเวลาบนแกน x',
  consentRate: 'อัตราส่วนความยินยอม',
  consentRateTooltip:
    'คืออัตราส่วนร้อยละของความยินยอม ที่เปรียบเทียบระหว่างสัดส่วนของ “การยอมรับทั้งหมด” กับ “ผลรวมของความยินยอมทุกรูปแบบ”',
  consents: 'ความยินยอม',
  fetchingText: 'แดชบอร์ดกำลังรีเฟรช กรุณารอสักครู่...',
  of: 'จาก',
  sessions: 'เซสชัน',
  thisMonth: 'สำหรับเดือนนี้',
  thisYear: 'สำหรับปีนี้',
  totalSession: 'เซสชันทั้งหมด',
  action: {
    accepted_all: 'ยอมรับทั้งหมด',
    rejected_all: 'ปฎิเสธทั้งหมด',
    partially_accepted: 'ยอมรับบางส่วน',
    informed_all: 'ได้รับการแจ้ง',
    dismissed_all: 'ไม่พิจารณา',
  },
  acceptedStatus: {
    allActions: 'การกระทำทั้งหมด',
    allow: 'ยอมรับ',
    deny: 'ปฎิเสธ',
    dismiss: 'ไม่พิจารณา',
    informed: 'ได้รับการแจ้ง',
  },
  maintenance: {
    notice: {
      title: 'ประกาศ',
      description:
        'ขณะนี้การใช้งานในส่วนนี้อยู่ระหว่างการปรับปรุง ทีมงานกำลังทำการแก้ไขอย่างเร็วที่สุด ขออภัยในความไม่สะดวก',
    },
  },
  apologize: {
    modal: {
      title:
        'แถลงการณ์ขออภัยจากกรณีเหตุขัดข้องในการให้บริการ <2>Cookie Wow</2> <3>บริษัท เอ็นเดต้าธอธ จำกัด</3>',
      contents: [
        'เรียน ผู้ใช้บริการแพลตฟอร์ม Cookie Wow ทุกท่าน',
        'จากกรณีเหตุขัดข้องทางเทคนิคในการให้บริการแพลตฟอร์ม Cookie Wow ที่เกิดขึ้น เมื่อวันที่ 1 มิถุนายน 2565 อันเนื่องมาจากมีจำนวนผู้ใช้งานมากเกินกว่าความสามารถในการรองรับของระบบภายใน จึงเป็นผลให้ระบบขัดข้องและไม่สามารถให้บริการแพลตฟอร์มได้ชั่วคราว',
        'บริษัทต้องขออภัยผู้ใช้บริการแพลตฟอร์ม Cookie Wow ทุกท่าน ที่ก่อให้เกิดความไม่สะดวกในการใช้งานแพลตฟอร์ม ทั้งนี้ เรามิได้นิ่งนอนใจและได้เร่งแก้ไขปัญหาโดยทันที เพื่อให้ทุกท่านสามารถกลับมาใช้บริการแพลตฟอร์มของเราได้โดยเร็วที่สุด',
        'ปัจจุบัน Cookie Wow ได้กลับมาเปิดให้บริการได้ตามปกติ เราขอขอบคุณผู้ใช้บริการทุกท่านเป็นอย่างยิ่งที่มอบความไว้วางใจ Cookie Wow มาโดยตลอด เรามุ่งมั่นที่จะพัฒนาอย่างต่อเนื่องเพื่อส่งมอบบริการที่ดีที่สุดให้กับลูกค้า และป้องกันเพื่อไม่ให้เกิดเหตุการณ์เช่นนี้อีกในอนาคต',
        'ด้วยความเคารพ',
        'คณะผู้บริหารบริษัท เอ็นเดต้าธอธ จำกัด',
      ],
    },
  },
}
