import { Box, Flex, Text, useRadio, UseRadioProps } from '@chakra-ui/react'

import { UpgradeLink } from './shared'

type PositionRadioButtonProps = UseRadioProps & {
  img: any
  title: string
  disabled: boolean
  showUpgrade?: boolean
}

// used as controlled component
export const PositionRadioButton = (props: PositionRadioButtonProps) => {
  const { isChecked, img, title, disabled, showUpgrade = true, id } = props

  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Flex
      {...checkbox}
      as="label"
      id={id}
      sx={{
        flexDir: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: 'gray.300',
        cursor: 'pointer',
        transitionProperty: 'border-color, background-color, color',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease',
        userSelect: 'none',
        pos: 'relative',
        ...(isChecked && {
          borderColor: 'brand.400',
          backgroundColor: 'brand.50',
          fontWeight: 600,
        }),
        ...(disabled && {
          background: 'gray.100',
          color: 'gray.400',
          cursor: 'not-allowed',
          borderColor: 'gray.300',
        }),
      }}
    >
      <input {...input} disabled={disabled} />

      <Box
        sx={{
          background: `no-repeat center/contain url(${img})`,
          w: '64px',
          h: '54px',
          mb: '16px',
        }}
      />
      <Text
        sx={{
          textTransform: 'capitalize',
          transition: 'font-weight 0.2s ease',
        }}
      >
        {title}
      </Text>
      {disabled && showUpgrade && (
        <UpgradeLink id="CustomizePosition-upgrade" sx={{ fontSize: '12px' }} />
      )}
    </Flex>
  )
}
