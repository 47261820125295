export default {
  branding: {
    logoUploadDescription:
      'ขนาดแนะนำ: 172 x 32 px หรือไม่เกิน 20MB (.PNG, .JPG or .SVG)',
    logo: 'โลโก้',
    cookieWowLogo: 'โลโก้ Cookie Wow',
    customLogo: 'ปรับแต่งโลโก้เอง',
    position: 'ตำแหน่งที่แสดง',
    prefCenterHeader: 'ส่วนบนสุดของหน้าการตั้งค่าคุกกี้',
    bannerFooter: 'ส่วนล่างสุดของแบนเนอร์คุกกี้',
  },
  revokable: {
    default: 'ค่าเริ่มต้น',
    upload: 'อัปโหลด',
    favicon: 'Favicon',
    cookieIcon: 'คุกกี้ไอคอน',
    position: 'ตำแหน่ง',
    uploadPlaceholder: 'เลือกโลโก้',
    cookieIconUploadDescription:
      'ขนาดแนะนำ: 32 x 32 px หรือไม่เกิน 20MB (.PNG, .JPG or .SVG)',
    faviconDisclaimer:
      'ข้อควรระวัง: ภาพอาจไม่แสดงผลหากไม่สามารถตรวจพบ favicon บนเว็บไซต์ของคุณ',
  },
  privacy: {
    policyUrl: {
      helper:
        'หากไม่มีการใส่ลิงก์สู่นโยบาย ระบบจะเลือกลิงก์ที่เป็นภาษาเริ่มต้นของแบนเนอร์มาใช้',
    },
  },
  chores: {
    uploadYourLogo: 'เลือกโลโก้',
    logo_exceed: 'ไฟล์มีขนาดใหญ่เกินไป กรุณาเลือกไฟล์ใหม่',
  },
  surveysensum: {
    head: 'เผยแพร่แบนเนอร์สำเร็จ',
    subHead: 'เราขอขอบคุณที่ไว้วางใจ Cookie Wow',
    detail:
      'เพื่อส่งมอบประสบการณ์การใช้งานและบริการที่ดีที่สุดสำหรับคุณ <br/> รบกวนทำแบบประเมินความพึงพอใจในการให้บริการประมาณ 2-3 นาที เพื่อให้เรานำไปพัฒนาให้ดียิ่งขึ้น',
  },
}
