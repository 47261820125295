export type Route = {
  name: string
  pattern: string
}

export type PathParams = 'token' | 'project_id'

export const routes: Route[] = [
  {
    name: 'domains',
    pattern: '/projects',
  },
  {
    name: 'packages',
    pattern: '/packages',
  },
  {
    name: 'freeTrial',
    pattern: '/packages/free_trial',
  },
  {
    name: 'receipts',
    pattern: '/transactions',
  },
  {
    name: 'pageViews',
    pattern: '/page_views',
  },
  {
    name: 'checkout',
    pattern: '/checkout',
  },
  {
    name: 'payments',
    pattern: '/payments/[token]',
  },
  {
    name: 'paymentsSuccess',
    pattern: '/payments/[token]/success',
  },
  {
    name: 'domainConsent',
    pattern: '/projects/[project_id]/consents',
  },
  {
    name: 'domainCustomize',
    pattern: '/projects/[project_id]/dialogs',
  },
  {
    name: 'domainCookiesScanner',
    pattern: '/projects/[project_id]/cookies',
  },
  {
    name: 'domainManageCookies',
    pattern: '/projects/[project_id]/manage',
  },
  {
    name: 'domainPreview',
    pattern: '/projects/[project_id]/preview',
  },
  {
    name: 'domainBannerSettings',
    pattern: '/projects/[project_id]/banner_setting',
  },
  {
    name: 'profileSettings',
    pattern: '/settings/profile',
  },
  {
    name: 'privacySettings',
    pattern: '/settings/privacy',
  },
  {
    name: 'securitySettings',
    pattern: '/settings/security',
  },
  {
    name: 'creditCardsSettings',
    pattern: '/settings/credit_cards'
  },
  {
    name: 'companySettings',
    pattern: '/settings/company',
  },
  {
    name: 'userManagements',
    pattern: '/user_managements',
  },
  {
    name: 'activityLogs',
    pattern: '/activity_logs',
  },
]
