import { Heading, Text } from '@chakra-ui/react'

import AuthLayout from '../../layouts/AuthLayout'
import { withAuthPage } from '@/features/layout'
import { SignUpProfileForm } from '../../components/SignUpProfileForm'

import Image from '@/images/project/auth/sign_up.svg'

import { useUpdateCompanyProfile } from '@/features/users/mutations'
import { Company } from '@/features/users/types'

type Props = {
  cancel_url: string
  company: Company
}

function CompanySettings(props: Props) {
  const { cancel_url } = props

  const { mutate: updateCompanyProfile, isLoading } = useUpdateCompanyProfile()

  return (
    <AuthLayout image={Image} header={'บอกเราเกี่ยวกับบริษัทของคุณ'}>
      <Heading
        as="h2"
        sx={{
          color: 'brand.400',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: 1.5,
          mb: '12px',
        }}
      >
        เพื่อให้เรามอบประสบการณ์และพัฒนาบริการที่สมบูรณ์แบบ
        ให้กับคุณได้มากยิ่งขึ้น
      </Heading>

      <SignUpProfileForm
        onSubmit={(data) =>
          updateCompanyProfile(
            { data },
            {
              onSuccess() {
                window.setTimeout(() => {
                  window.location.href = cancel_url
                }, 500)
              },
            },
          )
        }
        isLoading={isLoading}
      />

      <Text sx={{ textAlign: 'center', fontSize: '14px', mt: '16px' }}>
        คุณสามารถเปลี่ยนรายละเอียดได้ในภายหลัง
      </Text>
    </AuthLayout>
  )
}

export default withAuthPage(CompanySettings)
