export default {
  features: {
    packages: {
      downgrade: {
        consent_caution:
          'เมื่อคุณดาวน์เกรด หรือ ยกเลิกแพ็กเกจ บันทึกความยินยอมที่เกินจำนวนที่กำหนดตามแพ็กเกจจะถูกลบ ดังนั้นควรดาวน์โหลดข้อมูล “บันทึกความยินยอม” ก่อนวันที่แพ็กเกจของคุณจะถูกเปลี่ยน',
        same_plan:
          'คุณจะสามารถทดลองใช้ฟรีได้ <1>จนถึงวันที่ {{ expiry }}</1> หลังจากนั้นแพ็กเกจของคุณจะถูกเปลี่ยนเป็นแบบรายเดือน',
      },
      upgrade: {
        subscribe_to_month:
          'เลือกแพ็กเกจ <1>{{ package }}</1><3/>ราคา <4>{{ price }}</4> บาทต่อเดือน?',
        subscribe_to_year:
          'เลือกแพ็กเกจ <1>{{ package }}</1><3/>ราคา <4>{{ price }}</4> บาทต่อปี?',
        upgrade_to_month:
          'อัปเกรดเป็นแพ็กเกจ <1>{{ package }}</1><3/>ราคา <4>{{ price }}</4> บาทต่อเดือน?',
        upgrade_to_year:
          'อัปเกรดเป็นแพ็กเกจ <1>{{ package }}</1><3/>ราคา <4>{{ price }}</4> บาทต่อปี?',
        consent_caution:
          'เมื่อคุณทำการยืนยันการเลือกแพ็กเกจ <1>ช่วงทดลองใช้ฟรีจะสิ้นสุดลงทันที</1> <3/>และบัญชีของคุณจะถูกหักค่าบริการในรอบบิลแรกตามแพ็กเกจใหม่ที่คุณเลือก',
      },
      cancel: {
        current_plan_expire:
          'แพ็กเกจปัจจุบันของคุณจะ<1>หมดอายุในวันที่ {{ expiry }}</1> คุณจะสามารถใช้ฟีเจอร์ที่มีเฉพาะในแพ็กเกจปัจจุบันของคุณได้ถึงวันที่ดังกล่าว',
        free_trial_expire:
          'คุณจะสามารถทดลองใช้ฟรีได้ <1>จนถึงวันที่ {{ expiry }}</1> และสามารถใช้ฟีเจอร์ <3/>ที่มีเฉพาะในแพ็กเกจปัจจุบันของคุณได้ถึงวันที่ดังกล่าว',
        can_resub_anytime: 'คุณสามารถเปลี่ยนไปใช้แพ็กเกจอื่นได้ทุกเวลา',
        back_to_free:
          'หลังจากนั้น แพ็กเกจของคุณจะถูกดาวน์เกรดเป็น <1>แพ็กเกจ {{ name }}</1> ด้วยข้อจำกัดดังต่อไปนี้:',
        bullet_1: 'Pageview <1>สูงสุดที่ {{ pageview }}</1> ต่อเดือน',
        limited_domain: 'จำนวนโดเมนสูงสุด {{ count }} โดเมน',
        limited_domain_plural: 'จำนวนโดเมนสูงสุด {{ count }} โดเมน',
        unsubscribe_notice_description:
          'เรายังคงเก็บการตั้งค่าแบนเนอร์แพลนเดิมของคุณไว้ ตราบใดที่ยังไม่มีการแก้ไขเพิ่มเติม หากคุณต้องการใช้การตั้งค่าแบนเนอร์เดิม โปรด <1>Subscribe</1> อีกครั้ง',
      },
      pricing: {},
    },
    payments: {
      update:
        'บัตรเครดิตที่ใช้จะถูกเปลี่ยนเป็น <1>{{ cardNumber }}</1> <2/> และจะถูกอัปเดตเป็นวิธีการชำระเงินในรอบบิลถัดไป',
    },
    domains: {
      scanner: {},
      banner_status: {
        unchecked: 'ยังไม่ได้ตรวจสอบ',
        checking: 'กำลังตรวจสอบ...',
        active: 'แบนเนอร์<1>กำลังทำงาน</1>',
        inactive: 'แบนเนอร์<1>ไม่ทำงาน</1>',
      },
      customize: {
        how_to_use_css:
          'อ่านรายละเอียดการปรับแต่ง CSS เพิ่มเติมได้ <1>ที่นี่</1>',
        include_page_desc: 'กรอก URL เพื่อ<1>แสดง</1>แบนเนอร์เฉพาะหน้าที่กำหนด',
        exclude_page_desc: 'กรอก URL เพื่อ<1>ซ่อน</1>แบนเนอร์เฉพาะหน้าที่กำหนด',
        banner_display_desc:
          'ระบบแสดงผลแบนเนอร์ของ Cookie Wow รองรับการกรอก URLs ได้ทั้ง <1>Specific URLs</1> และ <1>Wildcard URLs</1>',
        banner_display_detail:
          'หากต้องการใช้ <1>Wildcard URL</1> ผู้ใช้งานสามารถใช้สัญลักษณ์ดอกจันทร์ ( <5>*</5> ) เป็นตัวแทนส่วนของ URL ที่สามารถมีค่าต่างกันได้ในส่วนที่กำหนด เช่น <5>https://www.cookiewow.com/*</5> ระบบจะทำทำการปรับแบนเนอร์ในทุก URLs ที่ประกอบด้วย <5>https://www.cookiewow.com/</5>',
        scanner_display_desc:
          'เฉพาะระบบยกเว้นการสแกน URLs สามารถรองรับ <1>Specific URLs</1> และ <1>Wildcard URLs</1>',
        scanner_display_detail:
          'หากต้องการแสกน <1>Wildcard URL</1> ผู้ใช้งานสามารถใช้สัญลักษณ์ดอกจันทร์ ( <5>*</5> ) เป็นตัวแทนส่วนของ URL ที่สามารถมีค่าต่างกันได้ในส่วนที่กำหนด เช่น <5>https://www.cookiewow.com/*</5> ระบบจะทำการสแกนทุก URLs ที่ประกอบด้วย <5>https://www.cookiewow.com/</5>',
      },
      index: {
        domain_exceed:
          'จำนวนโดเมนของคุณถึงขีดจำกัดสำหรับแพ็กเกจปัจจุบัน <1>อัปเกรดแพ็กเกจ</1> เพื่อเพิ่มโดเมน',
        domain_exceed_free_plan:
          'จำนวนโดเมนของคุณถึงขีดจำกัดสำหรับแพ็กเกจปัจจุบัน <1>อัปเกรดแพ็กเกจ (ทดลองใช้ฟรีสูงสุด 14 วัน)</1> เพื่อเพิ่มโดเมน',
      },
    },
    layout: {
      exceed_75:
        '<1>จำนวน Pageview ของเว็บไซต์ของคุณกำลังจะถึงขีดจำกัด</1>สำหรับแพ็กเกจปัจจุบันเร็ว ๆ นี้ เราแนะนำให้อัปเกรดแพ็กเกจเพื่อให้ Cookie Wow บันทึกความยินยอมของผู้เยี่ยมชมเว็บไซต์ของคุณต่อไป <3><0>อัปเกรดแพ็กเกจ</0></3>',
      exceed_100:
        '<1>จำนวน Pageview ของเว็บไซต์ของคุณถึงขีดจำกัด</1>สำหรับแพ็กเกจปัจจุบันแล้ว เราแนะนำให้<3><0>อัปเกรดแพ็กเกจตอนนี้</0></3>เพื่อให้ Cookie Wow บันทึกความยินยอมของผู้เยี่ยมชมเว็บไซต์ของคุณต่อไป',
      exceed_120:
        '<1>จำนวน Pageview ของเว็บไซต์ของคุณเกิดขีดจำกัด</1>สำหรับแพ็กเกจปัจจุบันแล้ว เราแนะนำให้<3><0>อัปเกรดแพ็กเกจตอนนี้</0></3>เพื่อให้ Cookie Wow บันทึกความยินยอมของผู้เยี่ยมชมเว็บไซต์ของคุณต่อไป',
      free_trial_notice: {
        labels: {
          title:
            'การทดลองใช้งานแพ็กเกจ {{ currentPackage }} ของคุณจะหมดอายุใน{{ daysBeforeExpire }} <1>หากคุณยกเลิกแล้ว คุณจะยังคงใช้งานแพ็กเกจที่คุณเลือกได้จนสิ้นสุดระยะเวลาทดลองใช้ฟรี</1>',
          subtitle:
            'ทางบริษัทจะหักค่าบริการหลังจากสิ้นสุด Free trial ในวันที่ <1>{{- expiry }}</1>',
        },
        links: ['อัปเกรดแพ็กเกจ', 'ยกเลิกการทดลองใช้งาน'],
      },
    },
    users: {
      change_password: {
        minimum_password_length: 'จำนวนตัวอักษรขั้นต่ำ {{count}} ตัวอักษร',
        minimum_password_length_plural:
          'จำนวนตัวอักษรขั้นต่ำ {{count}} ตัวอักษร',
      },
    },
    migration: {
      choose_domains:
        'ปัจจุบันคุณใช้แพ็กเกจ <1>{{name}}</1> สามารถใช้งานได้สูงสุด <3>{{limit}}</3> โดเมน',
      please_select_domains: 'โปรดเลือกโดเมนที่คุณต้องการใช้งานต่อ',
      choose_users: `ปัจจุบันคุณใช้แพ็กเกจ <1>{{name}}</1> สามารถมีผู้ใช้งานได้สูงสุด <3>{{limit}}</3> คน`,
      please_select_users: 'กรุณาเลือกผู้ใช้งานที่จะสามารถใช้แพลตฟอร์มนี้ได้',
    },
  },
  shared: {},
  misc: {
    month: '{{count}} เดือน',
    month_plural: '{{count}} เดือน',
  },
  welcome: 'ยินดีต้อนรับ',
}
