import { Button, Grid, Input, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@/components/FormControl'

import { CompanySettingsFormInput } from '../../types'

import { t } from '@/lib/helpers'

export type CompanySettingsFormProps = {
  defaultValues: CompanySettingsFormInput
  email: string
  isLoading: boolean
  onSubmit: (data: CompanySettingsFormInput) => void
  onOpenEmailModal: () => void
}

export const CompanySettingsForm = (props: CompanySettingsFormProps) => {
  const { defaultValues, isLoading, onSubmit } = props

  const { t: tSettings } = useTranslation('settings')

  const { register, handleSubmit } = useForm<CompanySettingsFormInput>({
    defaultValues,
  })

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}
    >
      <Stack spacing="6">
        <Stack spacing="4">
          <Grid sx={{ gap: '12px', gridTemplateColumns: '1fr 1fr' }}>
            <FormControl
              id="first_name"
              label={tSettings('companySettingForm.firstName.label')}
              isRequired
            >
              <Input
                {...register('first_name')}
                placeholder={tSettings(
                  'companySettingForm.firstName.placeholder',
                )}
                size="lg"
              />
            </FormControl>

            <FormControl
              id="last_name"
              isRequired
              label={tSettings('companySettingForm.lastName.label')}
            >
              <Input
                {...register('last_name')}
                placeholder={tSettings(
                  'companySettingForm.lastName.placeholder',
                )}
                size="lg"
              />
            </FormControl>
          </Grid>

          <FormControl
            id="name"
            label={t(
              'views.features.users.settings.company_name',
              undefined,
              'Company Name',
            )}
          >
            <Input
              size="lg"
              {...register('name')}
              placeholder={t(
                'views.features.users.settings.company_name',
                undefined,
                'Company Name',
              )}
            />
          </FormControl>

          <FormControl
            id="phone_number"
            label={t(
              'views.features.users.settings.phone_number',
              undefined,
              'Phone Number',
            )}
            isRequired
          >
            <Input
              size="lg"
              {...register('phone_number')}
              placeholder="0XX-XXX-XXXX"
              type="tel"
              inputMode="tel"
            />
          </FormControl>
        </Stack>

        <Button size="xl" type="submit" isLoading={isLoading}>
          {t('views.shared.button.update', undefined, 'Update')}
        </Button>
      </Stack>
    </form>
  )
}
