import { ReactNode } from 'react'
import { UseDisclosureReturn } from '@chakra-ui/react'

import { createCtx } from '@/lib/helpers'

type SurveyProviderProps = {
  children: ReactNode
  disclosure: UseDisclosureReturn
}

type SurveyContextValue = {
  disclosure: UseDisclosureReturn
}

export const [useSurveyContext, Provider] = createCtx<SurveyContextValue>()

export const SurveyProvider = (props: SurveyProviderProps) => {
  const { children, disclosure } = props

  const contextValue = {
    disclosure,
  }

  return <Provider value={contextValue}>{children}</Provider>
}
