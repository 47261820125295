import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { EnableTwoFactorResponse } from '../types'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

const enableTwoFactor = async (): Promise<EnableTwoFactorResponse> => {
  const response = await fetchAPI<EnableTwoFactorResponse>({
    path: `/settings/two_factor`,
    method: 'post',
  })

  return response.data
}

export const useEnableTwoFactor = () => {
  const handleError = useErrorHandler()

  return useMutation<EnableTwoFactorResponse, AxiosError, void>(
    enableTwoFactor,
    {
      onError(error) {
        handleError(error)
      },
    },
  )
}
