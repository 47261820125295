import { useEffect, useRef, createContext, useContext } from 'react'
import { get, entries, nth, noop, isDate, isEmpty, omitBy } from 'lodash'
import __axios, { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import copy from 'copy-to-clipboard'
import * as d3 from 'd3-format'

export { getOpacity } from './getOpacity/getOpacity'

/*
 * Usage: t('user.title', {name: get(this.props, 'user.data.nickname')})
 */
export const t = (key: string, args = {}, fallback?: string) => {
  let text = get(gon.locales, `${gon.currentLocale}.${key}`, fallback || key)

  try {
    const argsEntries = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current) => {
        const pattern = new RegExp(`%{${nth(current, 0)}}`, 'g')

        return result.replace(pattern, nth(current, 1) || '')
      }, text)
    }
  } catch (e) {}

  return text
}

const _axios = __axios.create({
  headers: {
    'X-CSRF-Token': document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content'),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  responseType: 'json',
})

// We have our own lil' axios because it's easier
// to deal with `authenticity_token` in one-go.
export const axios = {
  get: (url, data) => {
    return _axios.get(url, data)
  },

  post: (url, data) => {
    return _axios.post(url, data)
  },

  patch: (url, data) => {
    return _axios.patch(url, data)
  },

  put: (url, data) => {
    return _axios.put(url, data)
  },

  delete: (url, data) => {
    return _axios.delete(url, {
      data,
    })
  },
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export const getErrorMsg = (error: AxiosError) => {
  let errorText = 'Something Wrong'

  if (error.response) {
    if (
      typeof error.response.data === 'string' &&
      !error.response.data.startsWith('<!DOCTYPE')
    ) {
      errorText = error.response.data
    }

    const errors = get(error, 'response.data.errors', [])
    if (errors.length > 0) {
      errorText = errors[0]
    }

    const errorMsg = get(error, 'response.data.error', '')
    if (errorMsg) {
      errorText = errorMsg
    }
  } else if (error.request) {
    errorText = 'Network Error'
  }

  return `${errorText} (${error.response?.status ?? error.message})`
}

export const formatDateTime = (
  date: string | undefined | Date | Dayjs,
  pattern = 'YYYY-MM-DD HH:mm',
) => {
  if (!date) return ''

  return dayjs(date).format(pattern)
}

export function createCtx<T extends {} | null>() {
  const ctx = createContext<T | undefined>(undefined)

  function useCtx<K = {}>() {
    const c = useContext(ctx)

    if (c === undefined) {
      throw new Error('useCtx must be inside a Provider with a value')
    }

    return c as T & K
  }

  return [useCtx, ctx.Provider, ctx] as const
}

export function copyText(text: string, cb: () => void = noop) {
  if (copy(text)) {
    cb()
  }
}

export function omitByEmptyKey<T extends {} = Object>(obj: T) {
  const checkEmpty = (keyValue: any) => {
    if (isDate(keyValue)) {
      return false
    }

    if (typeof keyValue === 'object') {
      return isEmpty(keyValue)
    }

    if (typeof keyValue === 'boolean') {
      return false
    }

    return !keyValue
  }

  return omitBy<T>(obj, checkEmpty)
}

export function matchLanguage<T = string>(valueIfThai: T, valueIfEng: T) {
  return gon.currentLocale === 'th' ? valueIfThai : valueIfEng
}

export const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined
}

export const formatThousand = d3.format(',d')
