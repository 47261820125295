import { VStack, Input, Button, Link } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { FormControl } from '@/components/FormControl'
import { PasswordInputValidator, PasswordInput } from '@/components/Input'
import { inputStyle, linkStyle } from '@/features/auth/constants/styles'

import { t } from '@/lib/helpers'
import { getAuthenticityToken } from '@/lib/api'
import { useSignOut } from '@/features/users/mutations'

type SetPasswordExpireFormProps = {
  formActionPath: string
}

export const SetPasswordExpireForm = (props: SetPasswordExpireFormProps) => {
  const { formActionPath } = props

  const authenticityToken = getAuthenticityToken()
  const { t: tAuth } = useTranslation('auth')
  const { mutate: signOut } = useSignOut()
  const [password, setPassword] = useState('')

  return (
    <VStack
      spacing="4"
      sx={{
        alignItems: 'stretch',
      }}
    >
      <form
        id="new_user"
        action={formActionPath}
        acceptCharset="UTF-8"
        method="post"
      >
        <VStack
          spacing="4"
          sx={{
            alignItems: 'stretch',
          }}
        >
          <FormControl
            label={t('activerecord.attributes.user.current_password')}
          >
            <PasswordInput
              isRequired
              autoFocus
              id="current_password"
              name="user[current_password]"
              sx={inputStyle}
              placeholder={t('activerecord.attributes.user.current_password')}
            />
          </FormControl>
          <FormControl label={t('activerecord.attributes.user.password')}>
            <PasswordInputValidator
              isRequired
              id="user_password"
              autoComplete="new-password"
              name="user[password]"
              sx={inputStyle}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              placeholder={t('activerecord.attributes.user.password')}
            />
          </FormControl>
          <FormControl
            label={t('activerecord.attributes.user.password_confirmation')}
          >
            <PasswordInput
              isRequired
              id="user_password_confirmation"
              autoComplete="new-password"
              name="user[password_confirmation]"
              sx={inputStyle}
              placeholder={t(
                'activerecord.attributes.user.password_confirmation',
              )}
            />
          </FormControl>

          <Input
            value={authenticityToken}
            type="hidden"
            name="authenticity_token"
          />
          <Input value="put" type="hidden" name="_method" />

          <Button
            type="submit"
            name="commit"
            size="xl"
            value={t('devise.passwords.edit.change_my_password')}
            data-disable-with={t('devise.passwords.edit.change_my_password')}
          >
            {t('devise.passwords.edit.change_my_password')}
          </Button>
        </VStack>
      </form>
      <VStack
        spacing="3"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Link onClick={() => signOut()} sx={linkStyle}>
          {tAuth('passwordExpired.signInAnotherAccount')}
        </Link>
      </VStack>
    </VStack>
  )
}
