import React, { useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { createCtx } from '@/lib/helpers'
import { withPage } from '@/features/layout'

import { useTransformPackageData, useTransformCreditCards } from '../hooks'
import {
  CreditCard,
  Invoice,
  PackageData,
  PaymentPageProps,
  RawAddress,
  RawCreditCard,
} from '../types'
import { PaymentProvider } from '../components'

type PaymentContextValue = {
  address: RawAddress
  cardId: number | undefined
  creditCards: CreditCard[]
  hostUrl: string
  invoice: Invoice
  isOpenTransferModal: boolean
  onCloseTransferModal: () => void
  onOpenTransferModal: () => void
  packageData: PackageData
  setCardId: React.Dispatch<React.SetStateAction<number | undefined>>
  setInvoice: React.Dispatch<React.SetStateAction<Invoice>>
  setRawCreditCards: React.Dispatch<React.SetStateAction<RawCreditCard[]>>
  token: string
  recurring: Period
}

const paymentStoreContext = createCtx<PaymentContextValue>()
const [, Provider] = paymentStoreContext
export const [usePaymentStore] = paymentStoreContext

export const withPaymentPage = (
  Component: React.ComponentType<PaymentPageProps>,
) =>
  withPage((props: PaymentPageProps) => {
    const { host_url, recurring, address, cards, token, stripePKey } = props

    const stripePromise = useMemo(() => loadStripe(stripePKey), [stripePKey])

    const [cardId, setCardId] = useState<number | undefined>(() => {
      return cards.find((c) => c.default_card)?.id ?? undefined
    })
    const [rawCreditCards, setRawCreditCards] = useState(cards)
    const {
      isOpen: isOpenTransferModal,
      onOpen: onOpenTransferModal,
      onClose: onCloseTransferModal,
    } = useDisclosure()
    const [invoice, setInvoice] = useState<Invoice>(props.invoice)

    const packageData = useTransformPackageData(
      props.package,
      recurring,
      address.taxpayer_type,
    )

    const creditCards = useTransformCreditCards(rawCreditCards)

    const paymentStoreValues: PaymentContextValue = {
      address,
      cardId,
      creditCards,
      hostUrl: host_url,
      isOpenTransferModal,
      onCloseTransferModal,
      onOpenTransferModal,
      packageData,
      setCardId,
      setRawCreditCards,
      token,
      invoice,
      setInvoice,
      recurring,
    }

    return (
      <Elements stripe={stripePromise}>
        <PaymentProvider>
          <Provider value={paymentStoreValues}>
            <Component {...props} />
          </Provider>
        </PaymentProvider>
      </Elements>
    )
  })
