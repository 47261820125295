import { Flex } from '@chakra-ui/layout'

type FakeInputProps = {
  children: React.ReactNode
}

export const FakeInput = (props: FakeInputProps): React.ReactElement => {
  return (
    <Flex
      sx={{
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'gray.300',
        px: 4,
        h: '48px',
        alignItems: 'center',
        borderRadius: 'md',
      }}
    >
      {props.children}
    </Flex>
  )
}
