import axios, { AxiosRequestConfig } from 'axios'

export function getAuthenticityToken(): string {
  return (
    document
      ?.querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content') || ''
  )
}

type FetchParams = {
  url?: string
  prefix?: string
  path: string
} & Omit<AxiosRequestConfig, 'baseURL'>

axios.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      'X-CSRF-Token': getAuthenticityToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
})

function parseUrl(url: string, config: any = {}): string {
  const params = config.params
  let regex: RegExp
  if (params) {
    Object.entries(params).forEach(([key, value]: [string, any]) => {
      regex = new RegExp(`:${key}`)
      if (regex.test(url)) {
        url = url.replace(`:${key}`, value)
        delete config.params[key]
      }
    })
  }

  return url
}

function getBaseUrl({ prefix, path }) {
  const urlObject = {
    ...(prefix && { prefix }),
    ...(path && { path }),
  }

  return Object.values(urlObject).join('')
}

export function fetchAPI<T = any>({ path, prefix, ...options }: FetchParams) {
  return axios.request<T>({
    baseURL: parseUrl(getBaseUrl({ prefix, path }), { params: options.params }),
    ...options,
  })
}
