import { Fragment } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  useDisclosure,
} from '@chakra-ui/react'
import { FiRefreshCw } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { t } from '@/lib/helpers'
import { useCurrentUserData } from '@/features/users/queries'

import { ChangeEmailPending } from '../components'
import { ChangeEmailModal } from './ChangeEmailModal'
import { useAppContext } from '@/features/layout'

type ChangeEmailSectionProps = {}

export const ChangeEmailSection = (props: ChangeEmailSectionProps) => {
  const {} = props

  const { t: tSettings } = useTranslation('settings')

  const emailModalDisclosure = useDisclosure()

  const {
    currentUser: { email },
  } = useAppContext()
  const currentUser = useCurrentUserData()

  return (
    <Fragment>
      <FormControl isDisabled>
        <Flex justify="space-between" mb={2}>
          <FormLabel m="0">
            {t('views.features.users.settings.email', undefined, 'Email')}
          </FormLabel>

          <Button
            variant="link"
            colorScheme="brand"
            leftIcon={<Icon as={FiRefreshCw} />}
            onClick={emailModalDisclosure.onOpen}
            sx={{ textTransform: 'capitalize' }}
          >
            {tSettings('changeEmail')}
          </Button>
        </Flex>
        <Input size="lg" value={email} />

        {currentUser?.unconfirmed_email && (
          <Box mt="2">
            <ChangeEmailPending email={currentUser.unconfirmed_email} />
          </Box>
        )}
      </FormControl>

      <ChangeEmailModal disclosure={emailModalDisclosure} />
    </Fragment>
  )
}
