import { useMemo } from 'react'
import { AspectRatio, Text, Box, Stack, Flex, Divider } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { PieChart } from 'react-minimal-pie-chart'

import { ConsentDashboardCard } from '../ConsentDashboardCard'
import { ColorItem } from '../ColorItem'

import { StatusStatItem } from '../../types'

import { byStatusColor, pieChartStyle } from '../../constants'
import { EmptyPie } from '../EmptyPie'

export type ByStatusProps = {
  data: StatusStatItem[]
}

export const ByStatus = (props: ByStatusProps): React.ReactElement => {
  const { data } = props

  const { t } = useTranslation('domainConsent')
  const { t: tCommon } = useTranslation('common')

  const pieData = useMemo(
    () =>
      data
        .filter((data) => Boolean(data.count))
        .map((entry) => {
          const color = byStatusColor[entry.consent_action]

          const title = t(`action.${entry.consent_action}`)

          return {
            ...entry,
            color,
            value: entry.count,
            title,
          }
        }),
    [data],
  )

  const total = useMemo(
    () => data.reduce((sum, cur) => sum + cur.count, 0),
    [data],
  )

  return (
    <ConsentDashboardCard
      title={t('byStatus')}
      tooltip={t('byStatusTooltip')}
      id="by-status-card"
      bottomSection={
        <Stack sx={{ fontSize: 'xs' }}>
          <Flex
            sx={{
              fontWeight: 'bold',
              justifyContent: 'space-between',
              fontSize: 'sm',
            }}
          >
            <Text>{tCommon('total')}</Text>
            <Text>{total}</Text>
          </Flex>

          <Divider />

          {data.map((datum) => {
            const title = t(`action.${datum.consent_action}`)

            return (
              <ColorItem
                color={byStatusColor[datum.consent_action]}
                key={title}
                text={title}
                value={datum.count}
              />
            )
          })}
        </Stack>
      }
    >
      <AspectRatio
        ratio={1 / 1}
        sx={{ w: 'full', alignSelf: 'start', maxH: '186px' }}
      >
        <Box sx={{ boxSize: 'full' }}>
          {total ? (
            <PieChart
              data={pieData}
              label={({ dataEntry }) =>
                ((dataEntry.value / total) * 100).toFixed(2) + '%'
              }
              {...pieChartStyle}
              paddingAngle={pieData.length > 1 ? 3 : 0}
            />
          ) : (
            <EmptyPie />
          )}
        </Box>
      </AspectRatio>
    </ConsentDashboardCard>
  )
}
