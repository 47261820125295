import {
  Box,
  Collapse,
  Divider,
  Icon,
  Input,
  Flex,
  FormLabel,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  Tooltip,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import {
  Controller,
  useWatch,
  useFormContext,
  useFormState,
} from 'react-hook-form'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

import { t } from '@/lib/helpers'
import { useAppContext } from '@/features/layout'
import { DialogFormInput } from '@/features/domains/types'

import {
  RadioSection,
  RadioSectionWithInput,
  UpgradeText,
  FormControl,
} from '../../shared'

export type BannerButtonsProps = {
  isNoticeOnly: boolean
}

export const BannerButtons = (props: BannerButtonsProps) => {
  const { isNoticeOnly } = props

  const { isFree } = useAppContext()
  const { t: tDomains } = useTranslation('domains')

  const { errors } = useFormState<DialogFormInput>()
  const previewLanguage = useWatch({
    name: 'preview_language',
  }) as BannerLanguage

  const closeButtonBehavior = useWatch<
    Pick<DialogFormInput, 'close_button_behavior'>
  >({
    name: 'close_button_behavior',
  })
  const closeButton = useWatch<Pick<DialogFormInput, 'close_button'>>({
    name: 'close_button',
  })

  return (
    <Stack
      spacing="16px"
      sx={{
        '.view-details': {
          d: isNoticeOnly ? 'grid' : 'none',
        },
        '.reject-all': {
          d: isNoticeOnly ? 'none' : 'grid',
        },
        '.ok-button': {
          d: isNoticeOnly ? 'block' : 'none',
        },
      }}
    >
      <Stack spacing={6} sx={{ d: isNoticeOnly ? 'none' : 'flex' }}>
        <ButtonLabelInput
          label={t('views.features.domains.show.customize.accept_button_label')}
          id="CustomizeButtons-accept-all-label"
          name="accept_all_label"
          isDisabled={isFree}
          errorMsg={
            errors.accept_all_label &&
            errors.accept_all_label[previewLanguage]?.message
          }
        />
        <ButtonLabelInput
          label={t(
            'views.features.domains.show.customize.change_preference_label',
          )}
          id="CustomizeButtons-change-preference-label"
          name="preference_label"
          isDisabled={isFree}
          errorMsg={
            errors.preference_label &&
            errors.preference_label[previewLanguage]?.message
          }
        />
      </Stack>

      <ButtonLabelInput
        label={t('views.features.domains.show.customize.ok_button_label')}
        className="ok-button"
        id="CustomizeButtons-ok-button-label"
        name="ok_button_label"
        isDisabled={isFree}
        errorMsg={
          errors.ok_button_label &&
          errors.ok_button_label[previewLanguage]?.message
        }
      />

      <RadioSectionWithInput
        className="view-details"
        title={t(
          'views.features.domains.show.customize.view_detail_title',
          undefined,
          'View Details',
        )}
        description={t(
          'views.features.domains.show.customize.view_detail_description',
          undefined,
          'View Detail description',
        )}
        name="preference_button"
        id="CustomizeButtons-view-detail-button"
        inputName="view_details_label"
        inputId="CustomizeButtons-view-details-label"
        isDisabledInput={isFree}
        errorMsg={
          errors.view_details_label &&
          errors.view_details_label[previewLanguage]?.message
        }
      />

      <RadioSectionWithInput
        className="reject-all"
        title={t(
          'views.features.domains.show.customize.reject_all_title',
          undefined,
          'Reject All',
        )}
        description={t(
          'views.features.domains.show.customize.reject_all_description',
          undefined,
          'Reject all description',
        )}
        name="reject_all"
        id="CustomizeButtons-reject-button"
        inputName="reject_all_label"
        inputId="CustomizeButtons-reject-all-label"
        isDisabledInput={isFree}
        errorMsg={
          errors.reject_all_label &&
          errors.reject_all_label[previewLanguage]?.message
        }
      />

      <UpgradeText
        linkID="CustomizeButtonLabel-upgrade"
        label={t(
          'views.features.domains.show.customize.to_edit_label',
          undefined,
          'to edit label',
        )}
      />
      <Divider color="brand.25" />

      <RadioSection
        title={t(
          'views.features.domains.show.customize.close_banner_title',
          undefined,
          'Close Banner',
        )}
        description={t(
          `views.features.domains.show.customize.close_banner_description${
            isNoticeOnly ? '_notice_only' : ''
          }`,
          undefined,
          'Close banner description',
        )}
        name="close_button"
        id="CustomizeButtons-close-button"
      />

      <Collapse in={closeButton}>
        <Stack spacing="16px">
          <Controller
            name="close_button_behavior"
            render={({ field }) => {
              return (
                <RadioGroup
                  colorScheme="brand"
                  onChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <Stack>
                    <Radio
                      value="default"
                      alignItems="flex-start"
                      id="CustomizeButtons-default"
                    >
                      {tDomains('customize.bannerButtons.closeBannerDefault')}
                    </Radio>
                    <Radio
                      value="session"
                      alignItems="flex-start"
                      id="CustomizeButtons-session"
                    >
                      {tDomains('customize.bannerButtons.closeBannerSession')}
                      &nbsp;&nbsp;
                      <Tooltip
                        label={tDomains(
                          'customize.bannerButtons.closeBannerSessionTooltip',
                        )}
                        placement="top-start"
                        sx={{ padding: '12px', borderRadius: '8px' }}
                        hasArrow
                      >
                        <Flex
                          sx={{
                            d: 'inline-flex',
                            verticalAlign: 'middle',
                          }}
                        >
                          <Icon
                            as={AiOutlineInfoCircle}
                            color="gray.300"
                            boxSize="18px"
                          />
                        </Flex>
                      </Tooltip>
                    </Radio>
                    <Radio
                      value="timer"
                      alignItems="flex-start"
                      id="CustomizeButtons-timer"
                    >
                      {tDomains('customize.bannerButtons.closeBannerTimer')}
                      &nbsp;&nbsp;
                      <Tooltip
                        label={tDomains(
                          'customize.bannerButtons.closeBannerTimerTooltip',
                        )}
                        placement="top-start"
                        sx={{ padding: '12px', borderRadius: '8px' }}
                        hasArrow
                      >
                        <Flex
                          sx={{
                            d: 'inline-flex',
                            verticalAlign: 'middle',
                          }}
                        >
                          <Icon
                            as={AiOutlineInfoCircle}
                            color="gray.300"
                            boxSize="18px"
                          />
                        </Flex>
                      </Tooltip>
                    </Radio>
                  </Stack>
                </RadioGroup>
              )
            }}
          />

          <Collapse in={closeButtonBehavior === 'timer'}>
            <Controller
              name="close_banner_timer"
              render={({ field }) => {
                return (
                  <Box pos="relative">
                    <NumberInput
                      min={0}
                      max={60 * 24 * 365}
                      inputMode={'numeric'}
                      value={field.value}
                      onChange={field.onChange}
                    >
                      <NumberInputField />
                    </NumberInput>

                    <Text
                      sx={{
                        pos: 'absolute',
                        color: 'gray.600',
                        right: '16px',
                        top: '8px',
                      }}
                    >
                      {t('views.misc.minutes', undefined, 'Minutes')}
                    </Text>
                  </Box>
                )
              }}
            />
          </Collapse>
        </Stack>
      </Collapse>
    </Stack>
  )
}

type ButtonLabelInputProps = {
  label: string
  name: string
  id: string
  className?: string
  isDisabled?: boolean
  errorMsg?: string
}

const ButtonLabelInput = (props: ButtonLabelInputProps): React.ReactElement => {
  const { name, label, id, className, isDisabled, errorMsg } = props

  const { setValue } = useFormContext()
  const previewLanguage = useWatch({
    name: 'preview_language',
  }) as BannerLanguage
  const inputValue = useWatch({
    name,
  })

  return (
    <FormControl className={className} htmlFor={id} errorMsg={errorMsg}>
      <FormLabel sx={{ fontSize: '14px', fontWeight: 700 }}>{label}</FormLabel>
      <Input
        id={id}
        name={`${name}.${previewLanguage}`}
        value={inputValue[previewLanguage]}
        isDisabled={isDisabled}
        onChange={({ target: { value } }) => {
          setValue(name, {
            ...inputValue,
            [previewLanguage]: value,
          })
        }}
      />
    </FormControl>
  )
}
