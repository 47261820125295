import { ReactNode } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'

import theme from './theme'

export const ThemeProvider = (props: { children: ReactNode }) => {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }

          body {
            background-color: #fffaf1;
          }

          .StripeElement {
            font-family: 'Noto Sans Thai', 'Noto Sans', sans-serif;
            height: 40px;
            padding: 10px 12px;
            margin: 10px 0;
            width: 100%;
            color: #32325d;
            background-color: white;
            border: 1px solid transparent;
            border-radius: 4px;

            box-shadow: 0 1px 3px 0 #e6ebf1;
            -webkit-transition: box-shadow 150ms ease;
            transition: box-shadow 150ms ease;
          }

          .StripeElement--focus {
            box-shadow: 0 1px 3px 0 #cfd7df;
          }

          .StripeElement--invalid {
            border-color: #fa755a;
          }

          .StripeElement--webkit-autofill {
            background-color: #fefde5 !important;
          }

          .js-focus-visible :focus:not([data-focus-visible-added]) {
            outline: none;
            box-shadow: none;
          }
        `}
      />
      {props.children}
    </ChakraProvider>
  )
}
