import { AxiosError, AxiosResponse } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { VerifyPasswordFormInput } from '../types'

import { TOAST_CONFIG } from '@/lib/constants'
import { USER_SECURITY } from '@/features/users/constants'

type Payload = {
  data: VerifyPasswordFormInput
}

export const useVerifyPassword = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const { t: tSettings } = useTranslation('settings')

  return useMutation<AxiosResponse, AxiosError, Payload>(
    async ({ data }) => {
      return await fetchAPI({
        path: `/settings/security`,
        method: 'post',
        data,
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(USER_SECURITY)
        toast({
          ...TOAST_CONFIG,
          description: tSettings('verifyPasswordForm.toast.success'),
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}
