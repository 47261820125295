import { Fragment, ReactNode, useState } from 'react'
import { Icon as AnimatedActionColumnIcon, IconButton } from '@chakra-ui/react'
import { useHover, useLayer } from 'react-laag'
import { HiDotsHorizontal } from 'react-icons/hi'
import { AnimatePresence } from 'framer-motion'

import { AnimatedBox } from '@/lib/animation/components'

export type AnimatedActionColumnIcon = {
  icon: ReactNode
  label: string
}

type Props = {
  ariaLabel: string
  icons: AnimatedActionColumnIcon[]
}

export const AnimatedActionColumn = (props: Props) => {
  const { ariaLabel, icons } = props

  const [active, setActive] = useState(false)

  const close = () => {
    setActive(false)
  }

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: active,
    onOutsideClick: close,
  })

  return (
    <Fragment>
      {renderLayer(
        <AnimatePresence>
          {active && (
            <div {...layerProps}>
              {icons.map((icon, idx) => {
                return (
                  <IconItem
                    icon={icon.icon}
                    label={icon.label}
                    idx={idx}
                    key={idx}
                  />
                )
              })}
            </div>
          )}
        </AnimatePresence>,
      )}

      <IconButton
        icon={<AnimatedActionColumnIcon as={HiDotsHorizontal} boxSize={6} />}
        aria-label={ariaLabel}
        variant="unstyled"
        size="xs"
        onClick={(e) => {
          e.stopPropagation()
          setActive((state) => !state)
        }}
        {...triggerProps}
        sx={{
          transform: active ? 'rotate(90deg)' : 'rotate(0)',
          color: active ? 'brand.400' : 'gray.500',
        }}
      />
    </Fragment>
  )
}

type IconItemProps = Pick<AnimatedActionColumnIcon, 'icon' | 'label'> & {
  idx: number
}

const IconItem = (props: IconItemProps) => {
  const { icon, idx, label } = props

  const [isOver, hoverProps, close] = useHover({
    delayEnter: 300,
    delayLeave: 100,
  })
  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: isOver,
    onParentClose: close,
  })

  return (
    <>
      <AnimatedBox
        initial={{ x: -30, opacity: 0, scale: 0.5 }}
        animate={{ x: -10 + -40 * (idx + 1), opacity: 1, scale: 1 }}
        exit={{ x: -10, opacity: 0, scale: 0 }}
        key={idx}
        sx={{ position: 'absolute' }}
        {...triggerProps}
        {...hoverProps}
      >
        {icon}
      </AnimatedBox>

      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <AnimatedBox
              {...layerProps}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              sx={{
                py: 1,
                px: 2,
                bg: 'gray.900',
                color: 'white',
                borderRadius: '4px',
                fontSize: '12px',
              }}
            >
              {label}
            </AnimatedBox>
          )}
        </AnimatePresence>,
      )}
    </>
  )
}
