import { get } from 'lodash'
import { useQuery, useQueryClient } from 'react-query'

import { fetchAPI } from '@/lib/api'

import { CURRENT_USER } from '../constants'
import { CurrentUser } from '../types'

export const useCurrentUser = () => {
  return useQuery<CurrentUser>({
    queryKey: [CURRENT_USER],
    queryFn: async function () {
      const response = await fetchAPI({
        path: '/users/me',
      })

      const data = get(response, 'data')

      return data
    },
  })
}

export const useCurrentUserData = () => {
  const queryClient = useQueryClient()

  return queryClient.getQueryData<CurrentUser>([CURRENT_USER])
}
