import React from 'react'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { Button } from '@chakra-ui/button'
import { useTranslation } from 'react-i18next'

import { Tag } from './Tag'

interface CouponDisplayProps {
  coupon: string
  discount: string
  onUseCoupon: () => void
}

export const CouponDisplay: React.FC<CouponDisplayProps> = ({
  coupon,
  discount,
  onUseCoupon,
}) => {
  const { t } = useTranslation(['freeTrial'])

  return (
    <Flex
      sx={{
        w: 'full',
        p: '8px 16px',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '14px',
        borderRadius: '8px',
        borderColor: 'brand.400',
        borderWidth: '1px',
        bgColor: 'white',
      }}
    >
      <HStack>
        <Tag>{t('coupon.discount', { discount: discount })}</Tag>
        <Text sx={{ textAlign: 'center', fontWeight: 'bold' }}>{coupon}</Text>
      </HStack>
      <Button
        sx={{
          h: '37px',
          borderRadius: '8px',
          fontSize: '14px',
          padding: '4px 16px',
        }}
        onClick={onUseCoupon}
      >
        {t('coupon.useCoupon')}
      </Button>
    </Flex>
  )
}
