import {
  Box,
  Collapse,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { NumberInput } from '@/components/Input'
import { UpgradeText } from '../../shared'

import { t } from '@/lib/helpers'
import { useNonInitialEffect } from '@/lib/hooks'
import { useAppContext } from '@/features/layout'

import { DEFAULT_Z_INDEX } from '../../../constants'

type RadioInput = '1' | '2'

export const ZIndexInput = () => {
  const zIndex = useWatch({
    name: 'z_index',
  })
  const { setValue } = useFormContext()
  const { isFree } = useAppContext()

  const [radioData, setRadioData] = useState<RadioInput>(() =>
    zIndex || zIndex === 0 ? '2' : '1',
  )

  useNonInitialEffect(() => {
    if (radioData === '1') {
      setValue('z_index', null)
    } else if (radioData === '2') {
      setValue('z_index', zIndex ?? DEFAULT_Z_INDEX)
    }
  }, [radioData])

  return (
    <Stack>
      <Text sx={{ fontWeight: 700 }}>
        {t(
          'views.features.domains.show.customize.z-index-title',
          undefined,
          'Custom Z-Index',
        )}
      </Text>

      <UpgradeText linkID="CustomizeZIndex-upgrade" />

      <Box>
        <RadioGroup
          colorScheme="brand"
          onChange={(data) => setRadioData(data as RadioInput)}
          value={radioData}
        >
          <Grid sx={{ gridTemplateColumns: '1fr 1fr' }}>
            <Radio value="1" isDisabled={isFree}>
              {t(
                'views.features.domains.show.customize.z-index-default',
                undefined,
                'Default',
              )}
            </Radio>
            <Radio value="2" isDisabled={isFree}>
              {t(
                'views.features.domains.show.customize.z-index-custom',
                undefined,
                'Custom',
              )}
            </Radio>
          </Grid>
        </RadioGroup>

        <Collapse in={radioData === '2'}>
          <Box sx={{ mt: '16px' }}>
            <NumberInput
              defaultValue={zIndex ?? DEFAULT_Z_INDEX}
              onChange={(val) => setValue('z_index', val)}
              step={1}
              min={0}
              max={0x7ffffff0}
            />
          </Box>
        </Collapse>
      </Box>
    </Stack>
  )
}
