export default {
  passwordExpired: {
    subHeader: 'เนื่องจากนโยบายของคุณมีการกำหนดอายุรหัสผ่าน',
    signInAnotherAccount: 'ลงชื่อเข้าใช้ด้วยบัญชีอื่น',
  },
  passwordHint: {
    title: 'รหัสผ่านต้องประกอบด้วย',
    lowercase: 'ตัวพิมพ์เล็ก (a-z)',
    uppercase: 'ตัวพิมพ์ใหญ่ (A-Z)',
    digits: 'ตัวเลข (0-9)',
    symbols: 'อักขระพิเศษ',
    min: 'อย่างน้อย 8 ตัวอักษร',
  },
}
