import {
  Collapse,
  Stack,
  HStack,
  Spacer,
  Text,
  Link,
  Divider,
} from '@chakra-ui/react'
import { useTranslation, Trans } from 'react-i18next'
import dayjs from 'dayjs'

import { PackageNameInUse } from '@/features/types'
import { formatDateTime } from '@/lib/helpers'

type Props = {
  date: string | Date
  currentPackage: PackageNameInUse
  isOpen?: boolean
}

export const UnsubscribeBanner = ({
  date,
  currentPackage,
  isOpen = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Collapse in={isOpen}>
      <HStack
        layerStyle="container"
        sx={{
          px: 6,
          py: 4,
          bg: 'white',
          border: '1px solid',
          borderColor: 'brand.200',
          mt: 8,
        }}
      >
        <Stack spacing="1" sx={{ textAlign: 'left', alignItems: 'flex-start' }}>
          <Text>
            <Trans
              t={t}
              i18nKey="features.layout.free_trial_notice.labels.title"
              values={{
                currentPackage: currentPackage.toTitleCase(),
                daysBeforeExpire: dayjs(date).fromNow(),
              }}
            >
              การทดลองใช้งานแพ็กเกจ Large ของคุณจะหมดอายุในอีก 7 วัน
              <Text as="span" sx={{ color: 'gray.600' }}>
                หากคุณยกเลิกแล้ว
                คุณจะยังคงใช้งานแพ็กเกจที่คุณเลือกได้จนสิ้นสุดระยะเวลาทดลองใช้ฟรี
              </Text>
            </Trans>
          </Text>
          <Text>
            <Trans
              t={t}
              i18nKey="features.layout.free_trial_notice.labels.subtitle"
              values={{ expiry: formatDateTime(date, 'DD/MM/YYYY') }}
            >
              ทางบริษัทจะหักค่าบริการหลังจากสิ้นสุด Free trial ในวันที่
              <Text as="strong" sx={{ color: 'brand.500' }}>
                DD/MM/YYYY
              </Text>
            </Trans>
          </Text>
        </Stack>
        <Spacer />
        <HStack spacing="2">
          <Link
            href="/packages#pricing-table"
            sx={{
              whiteSpace: 'nowrap',
              fontWeight: 'medium',
              color: 'brand.500',
            }}
          >
            {t('features.layout.free_trial_notice.links.0')}
          </Link>
          <Divider
            orientation="vertical"
            sx={{
              height: '1em',
              border: '1px solid',
              borderColor: 'brand.500',
              opacity: 1,
            }}
          />
          <Link
            href="/packages#current-package"
            sx={{
              whiteSpace: 'nowrap',
              fontWeight: 'medium',
              color: 'brand.500',
            }}
          >
            {t('features.layout.free_trial_notice.links.1')}
          </Link>
        </HStack>
      </HStack>
    </Collapse>
  )
}
