import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Checkbox,
  forwardRef,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import { MdCalendarToday } from 'react-icons/md'
import { components, StylesConfig, ValueContainerProps } from 'react-select'
import { useEffect, useState } from 'react'

import { t } from '@/lib/helpers'
import {
  DateType,
  PageViewFilter,
  ProjectOption,
  ProjectOptions,
} from '../../types'
import { GroupType, IsMulti, OptionType } from '@/components/Select/types'
import { Select } from '@/components/Select'
import { useFilterContext } from '@/features/filters/context'
import { useThemeConfig } from '@/components/Select/useThemeConfig'

import 'react-datepicker/dist/react-datepicker.css'
import PageViewCsvDownload from '../PageViewCsvDownload/PageViewCsvDownload'
import { PollingStatus } from '@/features/types'
import { Can } from '@/features/permissions/components'

type PageViewFilterSectionProps = {
  projectOptions: ProjectOptions
  downloadStatus: PollingStatus
  lastExportedAt: Date
}

function PageViewFilterSection(props: PageViewFilterSectionProps) {
  const { projectOptions, downloadStatus, lastExportedAt } = props

  const [dateRange, setDateRange] = useState<DateType[]>([null, null])

  const [selectedDomain, setSelectedDomain] = useState<any[]>()
  const [startDate, endDate] = dateRange

  const { setFilter } = useFilterContext()

  const { control } = useForm<PageViewFilter>()

  const { styles } = useThemeConfig<OptionType, IsMulti, GroupType>()

  const overWriteStyles: StylesConfig<OptionType, IsMulti, GroupType> = {
    option(provided) {
      return {
        ...provided,
        backgroundColor: 'white',
        color: 'black',
      }
    },
  }

  const onCalendarChange = (dates: DateType[]) => {
    setDateRange(dates)
    setFilter('start_date', dates[0]?.toDateString())
    setFilter('end_date', dates[1]?.toDateString())
  }

  const setUrlValue = () => {
    const filterSelectedDomain = selectedDomain?.map(
      (domain: ProjectOption) => domain.value,
    )

    setFilter('url', filterSelectedDomain)
  }

  useEffect(() => {
    setUrlValue()
  }, [selectedDomain])

  return (
    <HStack align="flex-end" justifyContent="space-between">
      <HStack align="flex-end" spacing="14px">
        <Box width="340px">
          <Text fontWeight="500" paddingBottom="6px">
            {t('views.features.packages.page_views.filter.domain')}
          </Text>
          <Controller
            name="url"
            control={control}
            render={() => {
              return (
                <Select
                  placeholder={t(
                    'views.features.packages.page_views.filter.domain_place_holder',
                  )}
                  options={projectOptions}
                  isMulti={true}
                  isSearchable={false}
                  styles={{ ...styles, ...overWriteStyles }}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  value={selectedDomain}
                  onChange={(values, actionMeta) => {
                    const { action, option } = actionMeta

                    if (action === 'select-option' && option?.value === 'all') {
                      setSelectedDomain(values.filter((v) => v.value === 'all'))
                    } else {
                      setSelectedDomain(values.filter((v) => v.value !== 'all'))
                    }
                  }}
                  components={{
                    ValueContainer: CustomValueContainer,
                    MultiValueLabel: CustomMultiValueContainer,
                    Option: (props) => Option({ ...props }),
                  }}
                />
              )
            }}
          />
        </Box>

        <Box width="250px">
          <Text fontWeight="500" paddingBottom="6px">
            {t('views.features.packages.page_views.filter.date_range')}
          </Text>
          <Controller
            name="date_range"
            control={control}
            render={() => {
              return (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selectsRange
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onCalendarChange}
                  customInput={<InputCalendar />}
                />
              )
            }}
          />
        </Box>
      </HStack>

      <Can I="export" a="page_views">
        {() => (
          <PageViewCsvDownload
            downloadStatus={downloadStatus}
            lastExportedAt={lastExportedAt}
          />
        )}
      </Can>
    </HStack>
  )
}

export default PageViewFilterSection

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <HStack alignContent="center" spacing="6px">
        <Checkbox isChecked={props.isSelected} />
        <Text fontSize="14px">{props.label}</Text>
      </HStack>
    </components.Option>
  )
}

const CustomValueContainer = ({ children, ...rest }: any) => {
  const selectedCount = rest.getValue().length
  const conditional = selectedCount < 2

  const firstChild: ValueContainerProps[] =
    !conditional && children ? [children[0].shift(), children[1]] : []

  return (
    <components.ValueContainer {...rest}>
      {conditional ? children : firstChild}{' '}
      {!conditional &&
        `${t('views.features.packages.page_views.filter.and_more')} ${
          selectedCount - 1
        } ${t('views.features.packages.page_views.filter.domains')}`}
    </components.ValueContainer>
  )
}

const CustomMultiValueContainer = (props: any) => {
  return (
    <Box
      maxWidth={props.selectProps.value?.length === 1 ? '180px' : '100px'}
      width="100%"
    >
      <components.MultiValueLabel {...props} />
    </Box>
  )
}

const InputCalendar = forwardRef(({ value, onClick }, ref) => {
  return (
    <InputGroup>
      <Input
        placeholder={t(
          'views.features.packages.page_views.filter.date_range_place_holder',
        )}
        value={value}
        onClick={onClick}
        onChange={() => {}}
        ref={ref}
        height="48px"
        width="250px"
        _placeholder={{ color: 'gray.600' }}
      />
      <InputRightElement
        paddingTop="6px"
        children={<Icon as={MdCalendarToday} color="gray.400" />}
      />
    </InputGroup>
  )
})
