export default {
  branding: {
    logoUploadDescription:
      'Recommended: 172 x 32 px or not over 20MB (.PNG, .JPG or .SVG)',
    logo: 'Logo',
    cookieWowLogo: 'Cookie Wow Logo',
    customLogo: 'Custom Logo',
    position: 'Position',
    prefCenterHeader: 'Preference Center Header',
    bannerFooter: 'Banner Footer',
  },
  revokable: {
    default: 'Default',
    upload: 'Upload',
    favicon: 'Favicon',
    cookieIcon: 'Cookie icon',
    position: 'Position',
    uploadPlaceholder: 'Upload your logo',
    cookieIconUploadDescription:
      'Recommended: 32 x 32 px or not over 20MB (.PNG, .JPG or .SVG)',
    faviconDisclaimer:
      'Caution: Logo may not be displayed if the favicon cannot be detected on your website.',
  },
  privacy: {
    policyUrl: {
      helper: 'If left blank, policy for default banner language will be used.',
    },
  },
  chores: {
    uploadYourLogo: 'Upload your logo',
    logo_exceed:
      'Image size is exceeded than 20MB. Please select another image.',
  },
  surveysensum: {
    head: 'Your banner has been published.',
    subHead: 'Thank you for using Cookie Wow.',
    detail:
      'We value your feedback. Please help us by completing our 3-minute satisfaction survey. <br/> Your comment will help us improve our user experience and service for you.',
  },
}
