import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { FOLDERS } from '../constants'
import { Folder } from '../types'

const getFolders = async () => {
  const response = await fetchAPI({
    path: '/projects',
  })

  const data = get(response, 'data', [])

  return data
}

export const useFolders = ({ initialData }: { initialData: Folder[] }) => {
  return useQuery<Folder[], AxiosError, Folder[]>({
    queryKey: [FOLDERS],
    queryFn: () => getFolders(),
    initialData,
  })
}
