import { Fragment, useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { HStack, Link, Stack, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { AxiosError } from 'axios'
import { useToast } from '@chakra-ui/toast'
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'

import { formatDateTime, t } from '@/lib/helpers'
import { getScanStatus } from '../../queries'
import { checkScanStatus } from '../../mutations'
import { useErrorHandler } from '@/lib/hooks'
import { useAbility } from '@/features/permissions'
import { useProjectData } from '../../queries'

import { PollingResponse } from '@/features/types'
import { ScanCookieFormInput } from '../../Scanner/types'

import { ScanCookieModal, ScanTimeModal } from '../../Scanner/components'
import { TimeIcon } from './TimeIcon'

import { SCANNED_COOKIES, SCANNED_URL, SCAN_STATUS } from '../../constants'
import { QUOTA } from '@/features/users/constants'
import { ERROR_TOAST_CONFIG, TOAST_CONFIG } from '@/lib/constants'

type Props = {
  projectId: number
  onToggle?: () => void
  scanStatus?: PollingResponse
  scanStatusQuery: UseQueryResult<PollingResponse, AxiosError>
}

type MutationPayload = {
  data: ScanCookieFormInput
  params: {
    projectId: number
  }
}

export const ScanStatus = (props: Props) => {
  const { projectId, onToggle, scanStatusQuery } = props
  const { data: scanStatus } = scanStatusQuery

  const [enableQuery, setEnableQuery] = useState(false)
  const ability = useAbility()
  const canCreateScanCookie = ability.can('create', 'scan_cookie')

  useEffect(() => {
    if (scanStatus) {
      if (['processing', 'queued'].includes(scanStatus.status)) {
        setEnableQuery(true)
      }
    }
  }, [scanStatus])

  const toast = useToast()
  const handlerError = useErrorHandler()
  const queryClient = useQueryClient()
  const projectData = useProjectData()

  const disclosure = useDisclosure()
  const setTimeScanDisclosure = useDisclosure()
  const { onOpen } = disclosure
  const { onOpen: onTimeScanOpen } = setTimeScanDisclosure

  const { mutateAsync } = useMutation<
    void,
    AxiosError<RailsError>,
    MutationPayload
  >(checkScanStatus, {
    onSuccess() {
      setEnableQuery(true)
    },
    onError(error) {
      handlerError(error)
    },
  })

  useQuery({
    queryKey: [SCAN_STATUS, { projectId }],
    queryFn: () => getScanStatus({ projectId }),
    async onSuccess(data) {
      if (!data) {
        return setEnableQuery(false)
      }

      if (['succeeded', 'failed'].includes(data.status)) {
        await queryClient.refetchQueries([SCANNED_COOKIES, { projectId }])
        await queryClient.refetchQueries([QUOTA])

        queryClient.setQueryData([SCANNED_URL, { projectId }], data)

        if (data.status === 'succeeded') {
          toast({
            ...TOAST_CONFIG,
            description: t('views.features.domains.index.scan_success'),
            duration: null,
          })
        } else {
          toast({
            ...ERROR_TOAST_CONFIG,
            description: t('views.features.domains.index.scan_failed'),
            duration: null,
          })
        }

        setEnableQuery(false)
      }
    },
    enabled: enableQuery,
    refetchInterval: enableQuery ? 5000 : false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  const handleManualScan = async (data: ScanCookieFormInput) => {
    await mutateAsync({ data, params: { projectId } })
  }

  return (
    <Fragment>
      <Stack sx={{ flexDir: 'column', alignItems: 'flex-end' }} spacing="8px">
        <HStack spacing={4}>
          <Button
            id="Cookie-scan"
            size="lg"
            onClick={onOpen}
            isLoading={scanStatusQuery.isLoading || enableQuery}
            loadingText={t('views.features.domains.index.scanning')}
            isDisabled={!canCreateScanCookie}
          >
            {t('views.features.domains.show.scanner.scan')}
          </Button>

          <Button
            size="lg"
            variant="outline"
            onClick={onTimeScanOpen}
            leftIcon={<TimeIcon />}
          >
            {t('views.features.domains.show.scanner.scan_time_set')}
          </Button>
        </HStack>

        {scanStatus && (
          <HStack align="baseline" sx={{ fontSize: '12px' }}>
            {scanStatus.ended_at && (
              <Text sx={{ color: 'gray.600' }}>
                {t('views.features.domains.show.scanner.last_scanned')}:&nbsp;
                {formatDateTime(scanStatus.ended_at)}
              </Text>
            )}

            {onToggle && (
              <Link
                sx={{ color: 'brand.400', textDecor: 'underline' }}
                onClick={onToggle}
              >
                {t('views.features.domains.show.scanner.title_scanned_url')}
              </Link>
            )}
          </HStack>
        )}
      </Stack>

      {canCreateScanCookie && (
        <ScanCookieModal
          disclosure={disclosure}
          handleScan={handleManualScan}
          isLoading={enableQuery}
        />
      )}

      <ScanTimeModal
        disclosure={setTimeScanDisclosure}
        projectId={projectId}
        defaultTimeScan={projectData?.cookie_scan_interval as number}
      />
    </Fragment>
  )
}
