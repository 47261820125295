import axios from 'axios'

interface GetConsentProps {
  apiKey: string
  baseUrl: string
}

export async function getConsents({ apiKey, baseUrl }: GetConsentProps) {
  const res = await axios.get(`${baseUrl}/api/v1/consent_records`, {
    headers: {
      Authorization: apiKey,
    },
  })
  return res.data
}
