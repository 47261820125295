import {
  Box,
  Grid,
  Text,
  useRadioGroup,
  RadioGroup,
  Collapse,
  Radio,
  useToast,
  ButtonGroup,
  Button,
  Stack,
} from '@chakra-ui/react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import bottomLeft from '@/images/project/customize/icon-bottom-left.svg'
import bottomRight from '@/images/project/customize/icon-bottom-right.svg'

import { PositionRadioButton } from '../../PositionRadioButton'
import { PreviewCodeBlock, UpgradeText } from '../../shared'
import { LogoUploader } from '../Branding/LogoUploader'

import {
  COOKIE_ICON_OPTIONS,
  DISPLAY_BANNER_SCRIPT,
} from '@/features/domains/constants'
import { DialogFormInput, Widget } from '@/features/domains/types'
import { WidgetIconType } from '@datawowio/cookie-banner-ui'

import { copyText, t } from '@/lib/helpers'
import { useAppContext } from '@/features/layout'
import { useTranslation } from 'react-i18next'

const positions: { img: any; value: 'left' | 'right'; title: string }[] = [
  {
    img: bottomLeft,
    title: t(
      'views.features.domains.show.customize.bottom_left',
      undefined,
      'Bottom Left',
    ),
    value: 'left',
  },
  {
    img: bottomRight,
    title: t(
      'views.features.domains.show.customize.bottom_right',
      undefined,
      'Bottom Right',
    ),
    value: 'right',
  },
]

export const Revokable = () => {
  const { isFree } = useAppContext()
  const { register, setValue } = useFormContext()
  const toast = useToast()
  const { t: tDomainCustomize } = useTranslation('domainCustomize')

  const formValues = useWatch<DialogFormInput>({})

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'widget',
    defaultValue: formValues?.widget,
    onChange: (value: Widget) => setValue('widget', value),
  })

  const handleCopy = () => {
    copyText(DISPLAY_BANNER_SCRIPT, () =>
      toast({
        description: t('views.misc.copied', undefined, 'Copied'),
        status: 'success',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      }),
    )
  }

  return (
    <RadioGroup
      name="revokable"
      size="lg"
      colorScheme="brand"
      defaultValue={formValues?.revokable}
      sx={{
        '.chakra-radio__label': {
          fontWeight: 700,
          fontSize: '16px',
        },
        '.chakra-radio': {
          mb: '8px',
        },
      }}
    >
      <Grid sx={{ gap: '24px' }}>
        <Box>
          <Radio
            id="CustomizeReset-default"
            value="1"
            {...register('revokable')}
          >
            {t(
              'views.features.domains.show.customize.always_active',
              undefined,
              'Always active',
            )}
          </Radio>

          <Stack spacing="3">
            <Text sx={{ color: 'gray.600', fontSize: '14px' }}>
              {t(
                'views.features.domains.show.customize.revoke_desc_1',
                undefined,
                'Revoke description 1',
              )}
            </Text>

            <Stack spacing="3">
              <Stack>
                <Text>{tDomainCustomize('revokable.cookieIcon')}</Text>

                <ButtonGroup
                  colorScheme="brand"
                  spacing="3"
                  size="md"
                  sx={{
                    button: {
                      fontWeight: 500,
                      letterSpacing: 'normal',
                      textTransform: 'capitalize',
                      borderRadius: '16px',
                      px: '18px',
                      minW: '72px',
                    },
                  }}
                >
                  {COOKIE_ICON_OPTIONS.map((option) => {
                    const isActive = formValues.widget_icon_type === option
                    const isDisabled = isFree || formValues?.revokable === '2'

                    return (
                      <Button
                        colorScheme={isDisabled ? 'blackAlpha' : 'brand'}
                        id={`CookieIconButton-${option}`}
                        isDisabled={isDisabled}
                        key={option}
                        onClick={() => setValue('widget_icon_type', option)}
                        variant={isActive ? 'solid' : 'outline'}
                      >
                        {tDomainCustomize(`revokable.${option}`)}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              </Stack>

              <Collapse
                in={
                  (['upload', 'favicon'] as WidgetIconType[]).includes(
                    formValues.widget_icon_type ?? 'default',
                  ) && formValues.revokable === '1'
                }
              >
                {formValues.widget_icon_type === 'upload' ? (
                  <Controller
                    name="widget_icon_url"
                    render={({ field }) => {
                      return (
                        <LogoUploader
                          name={field.name}
                          image={field.value}
                          onChange={field.onChange}
                          description={tDomainCustomize(
                            'revokable.cookieIconUploadDescription',
                          )}
                          placeholder={tDomainCustomize(
                            'revokable.uploadPlaceholder',
                          )}
                        />
                      )
                    }}
                  />
                ) : formValues.widget_icon_type === 'favicon' ? (
                  <Text sx={{ color: 'gray.600', fontSize: 'sm' }}>
                    {tDomainCustomize('revokable.faviconDisclaimer')}
                  </Text>
                ) : null}
              </Collapse>

              <UpgradeText linkID="CustomizeRevokable-cookie-icon" />
            </Stack>

            <Stack>
              <Text>{tDomainCustomize('revokable.position')}</Text>

              <Grid
                sx={{
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: '156px',
                  gap: '24px',
                  ...getRootProps(),
                }}
              >
                {positions.map((p) => (
                  <PositionRadioButton
                    {...getRadioProps({ value: p.value })}
                    id={`CustomizeReset-default-${p.value}`}
                    showUpgrade={false}
                    key={p.value}
                    title={p.title}
                    img={p.img}
                    disabled={formValues?.revokable === '2'}
                    isChecked={formValues?.widget === p.value}
                  />
                ))}
              </Grid>
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Radio
            id="CustomizeReset-custom"
            value="2"
            {...register('revokable')}
          >
            {t(
              'views.features.domains.show.customize.implement',
              undefined,
              'Implement your own Cookie Settings',
            )}
          </Radio>

          <Collapse in={formValues?.revokable === '2'}>
            <Box sx={{ color: 'gray.600', fontSize: '14px', mb: '16px' }}>
              <Text sx={{ fontWeight: 600 }}>
                {t(
                  'views.features.domains.show.customize.copy',
                  undefined,
                  'Copy this code',
                )}
              </Text>
              {t(
                'views.features.domains.show.customize.revoke_desc_2',
                undefined,
                'Revoke description 2.1',
              )}
              <br />
              <br />
              {t(
                'views.features.domains.show.customize.revoke_desc_3',
                undefined,
                'Revoke description 2.2',
              )}
            </Box>

            <PreviewCodeBlock>
              <Text
                id="CustomizeReset-copyCode"
                onClick={handleCopy}
                sx={{ cursor: 'pointer' }}
              >
                {DISPLAY_BANNER_SCRIPT}
              </Text>
            </PreviewCodeBlock>
          </Collapse>
        </Box>
      </Grid>
    </RadioGroup>
  )
}
