import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { ModalBody, ModalHeader } from '@chakra-ui/modal'

import { t } from '@/lib/helpers'

import { Modal } from '@/components/shared/Modal'
import { ManageScriptForm } from '.'

import { getPathParams } from '@/lib/routes'
import { useAddScript } from '../../mutations'
import { populateMultiLangField } from '../../Manage/helpers'

import { BannerLanguage, Category } from '../../types'

type Props = {
  activeLanguage: BannerLanguage
  disclosure: UseDisclosureReturn
  currentCategory: Category
}

export const AddScriptModal = (props: Props) => {
  const { activeLanguage, disclosure, currentCategory } = props
  const { isOpen, onClose } = disclosure

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  const { mutate: addScript, isLoading } = useAddScript()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="560px" withCloseButton>
      <ModalHeader>
        {t('views.features.domains.show.manage.form.create_script')}
      </ModalHeader>

      <ModalBody>
        <ManageScriptForm
          onSubmitForm={(data) => {
            const populatedPurpose = populateMultiLangField(
              data.purpose[activeLanguage],
            )

            addScript(
              {
                data: {
                  ...data,
                  purpose: populatedPurpose,
                },
                params,
              },
              {
                onSuccess: onClose,
              },
            )
          }}
          onCancel={onClose}
          isLoading={isLoading}
          currentCategory={currentCategory}
          activeLanguage={activeLanguage}
        />
      </ModalBody>
    </Modal>
  )
}
