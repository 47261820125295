import { ChangeEvent, useCallback } from 'react'
import { Box, Button, ButtonGroup, Grid, Stack } from '@chakra-ui/react'
import { useFormContext, useWatch, Controller } from 'react-hook-form'

import { ThemeRadioButton } from './ThemeRadioButton'
import { ColorPickerInput } from './ColorPickerInput'
import { FormControl, UpgradeText } from '../../shared'
import { CustomColorPickerInput } from './CustomColorPickerInput'

import { DialogFormInput } from '@/features/domains/types'

import { useAppContext } from '@/features/layout'
import { t } from '@/lib/helpers'

import { DEFAULT_THEME_COLOR, FONT_SIZE } from '@/features/domains/constants'

export const Appearance = () => {
  const { isFree } = useAppContext()
  const { setValue } = useFormContext()
  const formValues = useWatch<DialogFormInput>({})

  const onRadioChange = useCallback(
    (onChange: (...event: any[]) => void) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        onChange(e)

        setValue('bg_color', DEFAULT_THEME_COLOR[value].bg_color)
        setValue('text_color', DEFAULT_THEME_COLOR[value].text_color)
      },
    [],
  )

  return (
    <Grid sx={{ gap: '24px' }}>
      <FormControl
        label={t(
          'views.features.domains.show.customize.font_size',
          undefined,
          'Font Size',
        )}
        disabled={isFree}
      >
        <Stack spacing={4}>
          <ButtonGroup
            size="md"
            spacing="3"
            sx={{
              button: {
                fontWeight: 500,
                letterSpacing: 'normal',
                textTransform: 'capitalize',
                borderRadius: '16px',
                px: '18px',
                minW: '72px',
              },
            }}
          >
            {FONT_SIZE.map((size) => {
              const isActive = size === formValues.font_size

              return (
                <Button
                  key={size}
                  onClick={() => setValue('font_size', size)}
                  variant={isActive ? 'solid' : 'outline'}
                  id={`FontSizeButton-${size}`}
                  isDisabled={isFree}
                  colorScheme={isFree ? 'blackAlpha' : 'brand'}
                >
                  {t(
                    `views.features.domains.show.customize.font_size_${size}`,
                    undefined,
                    size.toUpperCase(),
                  )}
                </Button>
              )
            })}
          </ButtonGroup>

          <UpgradeText linkID="CustomizeAppearance-font-size-upgrade" />
        </Stack>
      </FormControl>

      <FormControl
        label={t(
          'views.features.domains.show.customize.theme',
          undefined,
          'Theme',
        )}
      >
        <Controller
          name="theme"
          defaultValue={formValues.theme}
          render={({ field }) => {
            return (
              <Grid
                role="radiogroup"
                sx={{
                  gridTemplateColumns: '1fr 1fr',
                  gap: '16px',
                }}
              >
                <ThemeRadioButton
                  {...field}
                  radioValue="light"
                  onChange={onRadioChange(field.onChange)}
                />
                <ThemeRadioButton
                  {...field}
                  radioValue="dark"
                  onChange={onRadioChange(field.onChange)}
                />
              </Grid>
            )
          }}
        />
      </FormControl>

      <Controller
        name="color"
        render={({ field: { value, onChange } }) => {
          return (
            <FormControl
              label={t(
                'views.features.domains.show.customize.primary_color',
                undefined,
                'Primary Color',
              )}
            >
              <CustomColorPickerInput
                id="CustomizeAppearance-primaryColor"
                value={value}
                onChange={onChange}
              />
            </FormControl>
          )
        }}
      />

      <Box>
        <Controller
          name="bg_color"
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl
                label={t(
                  'views.features.domains.show.customize.bg_color',
                  undefined,
                  'Background Color',
                )}
                sx={{ mb: '24px' }}
                disabled={isFree}
              >
                <CustomColorPickerInput
                  id="CustomizeAppearance-bgColor"
                  isDisabled={isFree}
                  value={value}
                  onChange={onChange}
                />
              </FormControl>
            )
          }}
        />

        <Controller
          name="text_color"
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl
                label={t(
                  'views.features.domains.show.customize.text_color',
                  undefined,
                  'Text Color',
                )}
                sx={{ mb: '16px' }}
                disabled={isFree}
              >
                <CustomColorPickerInput
                  id="CustomizeAppearance-textColor"
                  isDisabled={isFree}
                  value={value}
                  onChange={onChange}
                />
              </FormControl>
            )
          }}
        />

        <UpgradeText linkID="CustomizeAppearance-upgrade" />
      </Box>
    </Grid>
  )
}
