import { useMemo } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { SelectCreditCard } from '@datawowio/payment-ui'
import { t } from '@/lib/helpers'
import {
  withUpdatePaymentPage,
  useUpdatePaymentStore,
} from '@/features/payments/stores'

import { UpdateCreditForm } from '@/features/payments/containers'
import {
  UpdateCreditCardList,
  ConfirmUpdatePaymentModal,
  UnsaveChangeModal,
} from '@/features/payments/components'
import {
  Box,
  Button,
  Divider,
  Icon,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'

function UpdatePaymentPage() {
  const { cardId, creditCards, subscriptionCreditCard } =
    useUpdatePaymentStore()
  const updatePaymentDisclosure = useDisclosure()
  const unsaveChangeDisclosure = useDisclosure()

  const hasChange = useMemo(() => {
    return subscriptionCreditCard?.id !== cardId
  }, [cardId, subscriptionCreditCard])

  const unsaveWarning = () => {
    if (hasChange) {
      unsaveChangeDisclosure.onOpen()
    } else {
      window.location.href = '/packages'
    }
  }

  return (
    <Box layerStyle="container">
      <Stack pt="7" spacing="7">
        <Link
          sx={{
            d: 'grid',
            gap: '8px',
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'center',
            fontWeight: 'bold',
            color: 'brand.400',
            w: 'max-content',
          }}
          onClick={unsaveWarning}
        >
          <Icon
            as={FiArrowLeft}
            sx={{ color: 'brand.400', fontSize: '24px' }}
          />
          {t('views.features.payments.show.back_to')}
        </Link>
        <Box layerStyle="mainContent" p="6">
          <Text sx={{ fontSize: '24px', fontWeight: 500, color: 'gray.900' }}>
            {t('views.features.payments.contents.title')}
          </Text>
          <Divider mt="4" mb="8" />

          <Box w="720px">
            <SelectCreditCard
              currentLng={gon.currentLocale}
              renderCreditForm={({ onClose }) => {
                return <UpdateCreditForm onClose={onClose} />
              }}
            >
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: 'gray.200',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}
              >
                <UpdateCreditCardList
                  cards={creditCards}
                  subscriptionCreditCard={subscriptionCreditCard}
                />
              </Box>
            </SelectCreditCard>
            <Text
              sx={{
                color: 'brand.400',
                fontSize: '12px',
                textDecoration: 'underline',
                mt: '4',
              }}
            >
              {t('views.features.payments.contents.remark.title')}
            </Text>
            <UnorderedList
              sx={{ fontSize: '12px', color: 'gray.500', pl: '2', mt: '2' }}
            >
              <ListItem>
                {t('views.features.payments.contents.remark.1')}
              </ListItem>
            </UnorderedList>
            <Button
              isDisabled={!hasChange}
              colorScheme="brand"
              size="xl"
              sx={{ mt: '4', w: 'full' }}
              onClick={updatePaymentDisclosure.onOpen}
            >
              {t('views.features.payments.contents.update')}
            </Button>
          </Box>
        </Box>
      </Stack>

      <ConfirmUpdatePaymentModal disclosure={updatePaymentDisclosure} />

      <UnsaveChangeModal disclosure={unsaveChangeDisclosure} />
    </Box>
  )
}

export default withUpdatePaymentPage(UpdatePaymentPage)
