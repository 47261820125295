import { get, noop } from 'lodash'
import { useToast } from '@chakra-ui/react'

import { axios, getErrorMsg, t } from '@/lib/helpers'

import { useUpdatePaymentStore } from '../stores'
import { logEvent } from '@/lib/stats'

export const useUpdatePaymentServices = () => {
  const {
    setCardId,
    setRawCreditCards,
    setIsDeletePrimary,
    setNewSubscriptionCard,
  } = useUpdatePaymentStore()
  const toast = useToast()

  return {
    addNewCard: async (values, payload) => {
      const { onSuccess = noop } = payload

      try {
        const newData = await createCard(values)

        if (values.isDefault) {
          setCardId(newData.find((card) => card.default_card)?.id)
        }

        setRawCreditCards(newData)
        onSuccess()
        toast({
          title: t('views.features.payments.services.create_success'),
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
        })

        logEvent({
          fb: { event: 'AddPaymentInfo' },
          ga: {
            category: 'Payment',
            action: 'AddPaymentInfo',
          },
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
    deleteCard: async (id, password, onSuccess, onError) => {
      try {
        const newData = await deleteCard(id, password)
        const deleteCardId = newData.deleted_card.id
        const isDeletePrimary = newData.primary
        const newSubscriptionCard = newData.subscription_card

        setIsDeletePrimary(isDeletePrimary)
        setNewSubscriptionCard(newSubscriptionCard)

        setRawCreditCards((prev) =>
          prev.filter((card) => card.id !== deleteCardId),
        )

        onSuccess()
      } catch (error) {
        onError(error)
      }
    },
    setSubscriptionCard: async (id) => {
      try {
        const newData = await setSubscriptionCard(id)
        setRawCreditCards(newData)
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
    setupIntent: async ({ onError = noop }) => {
      try {
        const clientSecret = await setupIntent()

        return clientSecret
      } catch (error) {
        onError()
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })

        return null
      }
    },
  }
}

const createCard = ({ paymentId, isDefault }) => {
  return axios
    .post('/credit_cards', {
      payment_method_id: paymentId,
      default_card: isDefault,
    })
    .then(({ data }) => data || [])
}

const setSubscriptionCard = (id) => {
  return axios.put(`/recurrings/credit_cards/${id}`).then(({ data }) => {
    return data || []
  })
}

const deleteCard = (id, password) => {
  return axios
    .delete(`/credit_cards/${id}`, { password })
    .then(({ data }) => data || [])
}

const setupIntent = () => {
  return axios
    .post('/credit_cards/setup')
    .then(({ data }) => get(data, 'client_secret'))
}
