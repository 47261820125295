function baseStyle(props: Record<string, any>) {
  return {
    control: {
      ...(props.alignItems === 'flex-start' && {
        mt: '4px',
      }),
    },
  }
}

export const Radio = {
  parts: ['container', 'control', 'label', 'icon'],
  baseStyle,
}
