import { Text } from '@chakra-ui/layout'

import { useAppContext, withPage } from '@/features/layout'
import { useUpdateMarketingConsent } from '@/features/users/mutations'

import { SettingLayout } from '../../components'
import { MarketingConsentForm } from '@/features/users/components'

import { t } from '@/lib/helpers'
import { Box } from '@chakra-ui/react'

type Props = {
  consentApiUrl: string
  consentPurposeId: string
  consentApiKey: string
}

function PrivacySettingPage(props: Props) {
  const { consentApiUrl, consentPurposeId, consentApiKey } = props

  const { currentUser } = useAppContext()
  const { mutate: updateMarketingConsent, isLoading } =
    useUpdateMarketingConsent()

  return (
    <SettingLayout>
      <Text sx={{ mb: '24px', fontWeight: 700 }}>
        {t('views.features.users.settings.marketing_consent.title')}
      </Text>

      <Box sx={{ w: '50%' }}>
        <MarketingConsentForm
          defaultValues={{
            email: currentUser.email,
            marketing_consent: currentUser.marketing_consent,
            consent_base_url: consentApiUrl,
            consent_api_key: consentApiKey,
            consent_purpose_id: consentPurposeId,
          }}
          onSubmitForm={(data) => {
            updateMarketingConsent(data)
          }}
          isLoading={isLoading}
        />
      </Box>
    </SettingLayout>
  )
}

export default withPage(PrivacySettingPage)
