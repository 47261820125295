import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { Stack, Text } from '@chakra-ui/layout'
import { ModalBody, ModalHeader } from '@chakra-ui/modal'
import { Image } from '@chakra-ui/image'
import { Button } from '@chakra-ui/button'

import { Modal } from '@/components/shared'
import DeactivateIcon from '@/images/project/manage/deactivate.svg'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureReturn
  isLoading: boolean
  handleDeactivate: () => void
}

export const DeactivateCategoryModal = (props: Props) => {
  const { disclosure, isLoading, handleDeactivate } = props
  const { isOpen, onClose } = disclosure

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Stack direction="row" justify="center" sx={{ mt: '24px' }}>
        <Image src={DeactivateIcon} h="98px" w="108px" />
      </Stack>

      <ModalHeader
        sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 400 }}
      >
        {t('views.features.domains.show.manage.deactivate_title')}
      </ModalHeader>

      <ModalBody>
        <Stack spacing="24px">
          <Text sx={{ textAlign: 'center' }}>
            {t('views.features.domains.show.manage.deactivate_description')}
          </Text>

          <Stack spacing="8px">
            <Button size="xl" isLoading={isLoading} onClick={handleDeactivate}>
              {t('views.features.domains.show.manage.deactivate')}
            </Button>
            <Button
              variant="ghost"
              colorScheme="gray"
              color="gray.600"
              onClick={onClose}
              size="xl"
            >
              {t('views.shared.button.discard')}
            </Button>
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
