import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'

import { QUOTA } from '@/features/users/constants'
import { FOLDERS } from '@/features/folders/constants'
import { useErrorHandler } from '@/lib/hooks'

type Payload = {
  projectId: number
}

const deleteDomain = async (payload: Payload) => {
  const { projectId } = payload

  return fetchAPI({
    path: `/projects/${projectId}`,
    method: 'delete',
  })
}

export const useDeleteDomain = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse, AxiosError, Payload>(deleteDomain, {
    async onSuccess() {
      await queryClient.refetchQueries([FOLDERS])
      await queryClient.refetchQueries([QUOTA])

      toast({
        description: t('views.features.domains.index.delete_success'),
        status: 'success',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
