import {
  Input,
  Button,
  Stack,
  Text,
  FormLabel,
  Select,
  Grid,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFieldArray } from 'react-hook-form'

import { FormControl } from '@/components/FormControl'

import { UserFormInput } from '../../types'
import { t } from '@/lib/helpers'

import { createUserSchema, editUserSchema } from '../../schema'

export type UserFormProps = {
  defaultValues?: UserFormInput
  onSubmitForm: (data: UserFormInput) => void
  onClose: () => void
  isLoading: boolean
}

export const UserForm = (props: UserFormProps) => {
  const { defaultValues, onClose, onSubmitForm, isLoading } = props

  const isEdit = Boolean(defaultValues?.email)

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<UserFormInput>({
    defaultValues,
    resolver: zodResolver(isEdit ? editUserSchema : createUserSchema),
  })
  const { fields } = useFieldArray({
    control,
    name: 'folder_permissions',
  })

  const onSubmit = (data: UserFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="8">
        <FormControl
          id="email"
          label={t(
            'views.features.users.index.email',
            undefined,
            'Email Address',
          )}
          errorMsg={errors.email?.message}
          isRequired
          isDisabled={isEdit}
        >
          <Input
            {...register('email')}
            size="lg"
            placeholder={t(
              'views.features.users.index.email',
              undefined,
              'Email Address',
            )}
            type="email"
          />
        </FormControl>

        <Stack>
          <Grid
            sx={{
              color: 'gray.600',
              fontSize: '12px',
              fontWeight: 700,
              gap: '16px',
              gridTemplateColumns: '1fr 150px',
              textTransform: 'uppercase',
            }}
          >
            <Text>
              {t(
                'views.features.users.index.folder_name',
                undefined,
                'Folder Name',
              )}
            </Text>
            <Text>
              {t('views.features.users.index.role', undefined, 'Role')}
            </Text>
          </Grid>

          <Stack spacing="4" as="ul">
            {fields.map((field, index) => {
              return (
                <Grid
                  as="li"
                  sx={{
                    d: 'grid',
                    gridTemplateColumns: '1fr 150px',
                    alignItems: 'center',
                  }}
                  key={field.id}
                >
                  <FormLabel sx={{ mb: 0 }}>{field.folder_name}</FormLabel>

                  <Select
                    {...register(`folder_permissions.${index}.role` as const)}
                    defaultValue={field.role}
                    size="lg"
                  >
                    <option value="no_access">No Access</option>
                    <option value="viewer">Viewer</option>
                    <option value="editor">Editor</option>
                  </Select>
                </Grid>
              )
            })}
          </Stack>
        </Stack>

        <Stack alignItems="stretch">
          <Button size="xl" type="submit" isLoading={isLoading}>
            {isEdit
              ? t(
                  'views.features.users.index.edit_user',
                  undefined,
                  'Edit User',
                )
              : t(
                  'views.features.users.index.invite_user',
                  undefined,
                  'Invite User',
                )}
          </Button>
          <Button
            variant="ghost"
            colorScheme="gray"
            color="gray.600"
            size="xl"
            onClick={onClose}
            isDisabled={isLoading}
          >
            {t('views.shared.button.discard', undefined, 'Discard')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
