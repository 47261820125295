import { Stack, Text } from '@chakra-ui/layout'
import { Controller } from 'react-hook-form'

import { t } from '@/lib/helpers'
import { LanguageSelect } from '@/components/Input/LanguageSelect'

export const DefaultLanguage = () => {
  return (
    <Stack spacing="12px">
      <Text sx={{ fontWeight: 700, fontSize: '14px' }}>
        {t(
          'views.features.domains.show.customize.default_language',
          undefined,
          'Banner Default Language',
        )}
      </Text>

      <Controller
        name="banner_language"
        render={({ field }) => {
          return (
            <LanguageSelect
              handleChange={field.onChange}
              value={field.value}
              isFullSize
              id={'DefaultLanguageSelect'}
            />
          )
        }}
      />
    </Stack>
  )
}
