import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'
import { CouponRule } from '../types'

type Payload = {
  packageName: Exclude<PackageName, 'free' | 'enterprise'>
  recurring: Period
}

type RawActiveFreeTrialCoupons = {
  data: ActiveFreeTrialCoupons[]
}

export type ActiveFreeTrialCoupons = {
  coupon: string
  discount_amount: number
  discount_type: string
  from_package?: CouponRule
  to_package: CouponRule
  created_at?: number
}

export const useCheckActiveCoupons = () => {
  const handleError = useErrorHandler()

  return useMutation<ActiveFreeTrialCoupons[], AxiosError, Payload>(
    async ({ packageName, recurring }) => {
      const { data } = await fetchAPI<RawActiveFreeTrialCoupons>({
        path: '/free_trial_coupon/coupon_suggestions',
        method: 'get',
        params: {
          package: packageName,
          recurring,
        },
      })

      return data.data
    },
    {
      onError(error) {
        handleError(error)
      },
    },
  )
}
