import { ReactNode, useEffect, useState } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalProps as ChakraModalProps,
  ModalHeader as ChakraModalHeader,
  Box,
  Text,
  Input,
  ModalFooter,
  Grid,
  Button,
  CSSObject,
  ModalHeaderProps,
} from '@chakra-ui/react'

import { t } from '@/lib/helpers'
import { UseDisclosureReturn } from '@chakra-ui/hooks'

export type ModalProps = {
  children: ReactNode
  isLoading?: boolean
  isOpen: boolean
  maxW?: string
  onClose: () => void
  withCloseButton?: boolean
  contentSx?: CSSObject
  closeButtonSx?: CSSObject
} & ChakraModalProps

export const Modal = (props: ModalProps) => {
  const {
    children,
    closeOnOverlayClick = false,
    isCentered = false,
    isLoading = false,
    isOpen,
    maxW = '574px',
    onClose,
    withCloseButton = true,
    contentSx,
    closeButtonSx,
    ...rest
  } = props

  return (
    <ChakraModal
      onClose={onClose}
      isOpen={isOpen}
      isCentered={isCentered}
      motionPreset="slideInBottom"
      closeOnOverlayClick={closeOnOverlayClick}
      {...rest}
    >
      <ModalOverlay>
        <ModalContent sx={{ maxW, pb: 4, ...contentSx }}>
          {children}
          {withCloseButton && (
            <ModalCloseButton
              sx={{ ...closeButtonSx }}
              isDisabled={isLoading}
            />
          )}
        </ModalContent>
      </ModalOverlay>
    </ChakraModal>
  )
}

export type DeleteModalProps = {
  description?: string
  disclosure: UseDisclosureReturn
  isLoading: boolean
  onDelete: () => void
  title?: string
}

export const DeleteModal = (props: DeleteModalProps) => {
  const {
    description,
    disclosure,
    isLoading,
    onDelete,
    title = t(
      'views.components.modal.are_you_sure',
      undefined,
      'Are you sure?',
    ),
  } = props
  const { isOpen } = disclosure

  const [text, setText] = useState('')

  useEffect(() => {
    if (!isOpen) {
      setText('')
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      maxW="500px"
      isLoading={isLoading}
    >
      <ChakraModalHeader>{title}</ChakraModalHeader>

      <Box px={6}>
        {description && <Box mb={2}>{description}</Box>}
        <Text mb={4}>
          {t('views.components.modal.please_type')}&nbsp;<strong>DELETE</strong>
          &nbsp;{t('views.components.modal.to_confirm')}
        </Text>
        <Input
          name="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Box>

      <ModalFooter>
        <Grid gridTemplateColumns="repeat(2, max-content)" gap={4}>
          <Button
            size="md"
            colorScheme="gray"
            onClick={disclosure.onClose}
            isDisabled={isLoading}
          >
            {t('views.shared.button.cancel', undefined, 'Cancel')}
          </Button>
          <Button
            id="Domain-deleted"
            size="md"
            colorScheme="red"
            disabled={text !== 'DELETE'}
            onClick={onDelete}
            isLoading={isLoading}
          >
            {t('views.shared.button.delete', undefined, 'Delete')}
          </Button>
        </Grid>
      </ModalFooter>
    </Modal>
  )
}

export const ModalHeader = (props: ModalHeaderProps) => {
  return (
    <ChakraModalHeader sx={{ fontSize: '2xl', ...props.sx }}>
      {props.children}
    </ChakraModalHeader>
  )
}
