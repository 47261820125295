import { Fragment } from 'react'
import {
  Box,
  Button,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'

import UnsubImage from '@/images/email-unsubscribe/unsub-success.png'
import { COOKIE_WOW_URL } from '../../constants'

type Props = {
  onResubscribe: () => void
}

export const EmailUnsubscribeSuccess = (props: Props): React.ReactElement => {
  const { onResubscribe } = props

  return (
    <Fragment>
      <Image src={UnsubImage} alt="resub-image" />
      <Box sx={{ p: '36px 40px' }}>
        <Stack sx={{ mb: '24px' }} spacing={4} align="center">
          <Heading sx={{ textAlign: 'center', fontSize: '24px' }}>
            ยกเลิกการรับอีเมลเรียบร้อยแล้ว
          </Heading>
          <Text textAlign="center">
            หากคุณไม่ได้มีเจตนายกเลิกรับอีเมล กรุณากด ที่นี่&nbsp;
            <Link
              sx={{ color: 'brand.400', textDecor: 'underline' }}
              onClick={onResubscribe}
            >
              Resubscribe
            </Link>
            <br />
            &nbsp;เพื่อสมัครรับอีเมลต่ออีกครั้ง คุณจะไม่พลาดทุกฟีเจอร์ใหม่ ๆ
            และข้อเสนอสุดพิเศษจากเรา
          </Text>
        </Stack>

        <Button
          as="a"
          size="xl"
          w="full"
          onClick={() => {
            window.location.href = COOKIE_WOW_URL
          }}
        >
          กลับสู่หน้าหลัก
        </Button>
      </Box>
    </Fragment>
  )
}
