import {
  Button,
  Stack,
  ModalBody,
  UseDisclosureReturn,
  Image,
  Text,
} from '@chakra-ui/react'
// import DOMPurify from 'dompurify'

import { Modal } from '@/components/shared/Modal'

import FeatureUpdate from '@/images/project/feature_update.svg'

import { t } from '@/lib/helpers'

import { Notification } from '@/features/types'

export type NewFeatureModalProps = {
  disclosure: UseDisclosureReturn
  notifications: Notification[]
  onReadNotification: () => void
}

export const NewFeatureModal = (props: NewFeatureModalProps) => {
  const { disclosure, notifications, onReadNotification } = props
  const { isOpen, onClose } = disclosure

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton={false}>
      <ModalBody>
        <Stack sx={{ mb: '16px' }}>
          <Image src={FeatureUpdate} sx={{ boxSize: '110px', mx: 'auto' }} />
          <Text sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 600 }}>
            {t('views.features.feature_update.title')}
          </Text>
        </Stack>

        <Stack spacing="6">
          <Stack
            sx={{
              border: '1px solid',
              borderColor: 'gray.300',
              bg: 'gray.50',
              p: '17px 23px 17px 15px',
              maxH: '545px',
              overflowY: 'scroll',
            }}
          >
            {notifications.map((noti, idx) => {
              return (
                <Stack key={`${noti.title}-${idx}`}>
                  <Text
                    sx={{
                      textAlign: 'center',
                      fontSize: '18px',
                      fontWeight: 600,
                    }}
                  >
                    {noti.title}
                  </Text>

                  {noti.content.map((con, _idx) => (
                    <Stack key={`${con.title}-${idx}`}>
                      <Text sx={{ color: 'brand.400', fontWeight: 600 }}>
                        {con.title}
                      </Text>
                      <Text sx={{ fontSize: '14px' }}>{con.description}</Text>
                    </Stack>
                  ))}
                </Stack>
              )
            })}
          </Stack>

          <Button size="xl" onClick={onReadNotification}>
            {t('views.misc.got_it', undefined, 'Got it')}
          </Button>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
