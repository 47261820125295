import { Fragment, ReactNode } from 'react'
import {
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl as CKFormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  Textarea,
  Link,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FiRefreshCw } from 'react-icons/fi'

import { CategoryFormInput } from '@/features/domains/manage/types'

import { t } from '@/lib/helpers'
import { categorySchema } from '../../schema'
import { DEFAULT_CATEGORY_CONTENTS } from '../../constants'

import { FakeInput } from '@/components/Input'
import { FormControl } from '@/components/FormControl'
import { ConsentSettingSection } from './ConsentSettingSection'

export type ManageCategoryFormProps = {
  activeLanguage?: BannerLanguage
  defaultValues?: CategoryFormInput
  enName?: string
  isDefaultCategory?: boolean
  isLoading: boolean
  isNecessaryCategory?: boolean
  isNoticeOnly?: boolean
  isReadOnly?: boolean
  onCancel: () => void
  onSubmitForm: (data: CategoryFormInput) => void
  deleteCategoryButton?: ReactNode
}

export const ManageCategoryForm = (props: ManageCategoryFormProps) => {
  const {
    activeLanguage,
    defaultValues,
    enName,
    isDefaultCategory,
    isLoading,
    isNecessaryCategory,
    isNoticeOnly = false,
    isReadOnly,
    onCancel,
    onSubmitForm,
    deleteCategoryButton,
  } = props

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    control,
  } = useForm<CategoryFormInput>({
    resolver: zodResolver(categorySchema),
    defaultValues,
    shouldUnregister: true,
  })

  const onSubmit = (data: CategoryFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  const setDefaultContentValue = () => {
    if (activeLanguage && isDefaultCategory && enName) {
      setValue('description', DEFAULT_CATEGORY_CONTENTS[activeLanguage][enName])
    }
  }

  const disableName = isNecessaryCategory || isDefaultCategory

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Flex justify="space-between" align="center">
          <Text sx={{ fontWeight: 600 }}>
            {defaultValues
              ? t(
                  'views.features.domains.show.manage.form.edit_category',
                  { name: defaultValues.name },
                  `Edit ${defaultValues.name} Category`,
                )
              : t(
                  'views.features.domains.show.manage.form.create_category',
                  undefined,
                  'Create Category',
                )}
          </Text>

          {deleteCategoryButton}
        </Flex>

        <Grid sx={{ gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
          <FormControl
            isRequired
            id="name"
            label={t(
              'views.features.domains.show.manage.form.category_name',
              undefined,
              'Category Name',
            )}
            errorMsg={errors.name?.message}
            isReadOnly={isReadOnly}
            fakeInput={disableName}
          >
            <Input
              {...register('name')}
              placeholder={t(
                'views.features.domains.show.manage.form.category_name',
                undefined,
                'Category Name',
              )}
              size="lg"
              isReadOnly={isReadOnly}
              type={disableName ? 'hidden' : 'text'}
            />
            {disableName && <FakeInput>{defaultValues?.name}</FakeInput>}
          </FormControl>

          <FormControl
            errorMsg={errors.slug?.message}
            formHelperText={t(
              'views.features.domains.show.manage.form.category_slug_helper_text',
              undefined,
              'Use Lower case (a-z) and Dash (-) to separate words.',
            )}
            id="slug"
            isReadOnly={isReadOnly}
            isRequired
            label={t(
              'views.features.domains.show.manage.form.category_slug',
              undefined,
              'Slug',
            )}
            tooltipText={t(
              'views.features.domains.show.manage.form.category_slug_tooltip_text',
            )}
          >
            <Input
              {...register('slug')}
              placeholder={t(
                'views.features.domains.show.manage.form.category_slug',
                undefined,
                'Slug',
              )}
              size="lg"
              isReadOnly={isReadOnly}
            />
          </FormControl>
        </Grid>

        <CKFormControl
          isRequired
          isReadOnly={isReadOnly}
          isInvalid={Boolean(errors.description?.message)}
          id="description"
        >
          <HStack align="center" sx={{ mb: 2 }}>
            <FormLabel sx={{ m: 0 }} htmlFor="description">
              {t(
                'views.features.domains.show.manage.form.description',
                undefined,
                'Description',
              )}
            </FormLabel>

            {isDefaultCategory && (
              <Link
                sx={{
                  color: 'brand.400',
                  textDecor: 'underline',
                }}
                onClick={setDefaultContentValue}
              >
                <Icon
                  as={FiRefreshCw}
                  sx={{ mr: '8px' }}
                  boxSize="14px"
                  color="brand.400"
                />
                {t(
                  'views.features.domains.show.manage.form.reset_content',
                  undefined,
                  'Reset content to default',
                )}
              </Link>
            )}
          </HStack>

          <Textarea
            id="description"
            {...register('description')}
            placeholder={t(
              'views.features.domains.show.manage.form.description',
              undefined,
              'Description',
            )}
            size="lg"
            sx={{ minH: '124px' }}
            isReadOnly={isReadOnly}
          />

          <Collapse in={Boolean(errors.description?.message)} animateOpacity>
            <FormErrorMessage data-testid="error-msg">
              {errors.description?.message}
            </FormErrorMessage>
          </Collapse>
        </CKFormControl>

        {!isNecessaryCategory && (
          <Fragment>
            <Divider />

            <ConsentSettingSection
              isNoticeOnly={isNoticeOnly}
              control={control}
            />
          </Fragment>
        )}

        <Flex sx={{ justifyContent: 'flex-end' }}>
          <HStack spacing="4">
            <Button variant="outline" onClick={onCancel}>
              {t('views.shared.button.discard', undefined, 'Discard')}
            </Button>
            <Button
              id={defaultValues ? 'Category-updated' : 'Category-added'}
              type="submit"
              isLoading={isLoading}
              isDisabled={isReadOnly}
              sx={{ w: '160px' }}
            >
              {defaultValues
                ? t('views.shared.button.save', undefined, 'Save')
                : t(
                    'views.features.domains.show.manage.form.create_category',
                    undefined,
                    'Create Category',
                  )}
            </Button>
          </HStack>
        </Flex>
      </Stack>
    </form>
  )
}
