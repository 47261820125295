import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Stack, Input, Button } from '@chakra-ui/react'
import { get, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

import { t } from '@/lib/helpers'

import { DomainFormInput } from '../../types'
import { SelectOptions } from '@/components/Select/types'

import { FormControl } from '@/components/FormControl'
import { Creatable, valueToOption } from '@/components/Select'

import { domainSchema } from '../../schema'

export type DomainFormProps = {
  onSubmitForm: (data: DomainFormInput) => void
  isLoading: boolean
  onClose: () => void
  defaultValues?: DomainFormInput
  folderNameOptions: SelectOptions
}

export const DomainForm = (props: DomainFormProps) => {
  const { isLoading, onClose, onSubmitForm, folderNameOptions, defaultValues } =
    props

  const { t: tCommon } = useTranslation('common')

  const isCreate = isEmpty(defaultValues)
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useForm<DomainFormInput>({
    resolver: zodResolver(domainSchema),
    defaultValues,
  })

  const onSubmit = async (data: DomainFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} role="form">
      <Stack spacing="6">
        <FormControl
          id="url"
          label={t(
            'views.features.domains.index.add_description',
            undefined,
            'Domain URL',
          )}
          errorMsg={errors.url?.message}
          isRequired
        >
          <Input
            tabIndex={1}
            placeholder="https://www.example.com or http://www.example.com"
            data-testid="url-input"
            id="url"
            {...register('url')}
          />
        </FormControl>

        {folderNameOptions.length && (
          <Controller
            name="folder_name"
            control={control}
            defaultValue=""
            render={({ field }) => {
              const { onChange, value } = field
              const inputValue = valueToOption(value, folderNameOptions)
              const error = get(errors, 'folder_name.message')

              return (
                <FormControl
                  errorMsg={error}
                  htmlFor="folderName"
                  id="role"
                  isRequired={true}
                  label={t(
                    'views.features.users.index.folder_name',
                    undefined,
                    'Folder Name',
                  )}
                >
                  <Creatable
                    placeholder={t(
                      'views.features.domains.index.create_folder_placeholder',
                      undefined,
                      'Placeholder',
                    )}
                    inputId="folderName"
                    value={inputValue}
                    options={folderNameOptions}
                    onChange={(option) => {
                      onChange(option?.value)
                    }}
                    formatCreateLabel={(str) => {
                      return `${t(
                        'views.features.domains.index.create_folder_label',
                        undefined,
                        'Create Folder',
                      )}: "${str}"`
                    }}
                    id="FolderSelect"
                  />
                </FormControl>
              )
            }}
          />
        )}

        <Stack spacing="2" sx={{ mt: '40px !important' }}>
          <Button
            colorScheme="brand"
            size="xl"
            type="submit"
            isLoading={isSubmitting || isLoading}
            data-testid={isCreate ? 'add-domain-submit' : 'edit-domain-submit'}
          >
            {isCreate
              ? t('views.features.domains.index.add_domain')
              : tCommon('save')}
          </Button>

          <Button
            colorScheme="gray"
            variant="ghost"
            size="xl"
            color="gray.600"
            onClick={onClose}
          >
            {t('views.shared.button.discard', undefined, 'Discard')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
