import { useToast } from '@chakra-ui/react'
import { useMutation } from 'react-query'

import { ChangePasswordFormInput } from '../types'

import { t, getErrorMsg } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { ERROR_TOAST_CONFIG, TOAST_CONFIG } from '@/lib/constants'

const updatePassword = async (data: ChangePasswordFormInput) => {
  const { new_password, confirm_password, current_password } = data

  await fetchAPI({
    method: 'put',
    path: '/settings',
    data: {
      current_password,
      password: new_password,
      password_confirmation: confirm_password,
    },
  })
}

export const useUpdatePassword = () => {
  const toast = useToast()

  return useMutation(updatePassword, {
    onSuccess: () => {
      toast({
        ...TOAST_CONFIG,
        description: t('views.shared.form.update_success'),
      })
    },
    onError: (err) => {
      toast({
        ...ERROR_TOAST_CONFIG,
        title: getErrorMsg(err),
      })
    },
  })
}
