import { useMutation } from 'react-query'
import { useTranslation, Trans } from 'react-i18next'
import { useToast } from '@chakra-ui/react'
import { get } from 'lodash'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { useErrorHandler } from '@/lib/hooks'
import { logEvent } from '@/lib/stats'

import { DomainFormInput } from '../types'
import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  data: DomainFormInput
}

type Response = {
  id: number
  registered_domain: boolean
}

const addDomain = async (payload: Payload): Promise<Response> => {
  const response = await fetchAPI<Response>({
    method: 'post',
    path: '/projects',
    data: payload.data,
  })

  const data = get(response, 'data')

  return data
}

export const useAddDomain = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const { t: tDomains } = useTranslation('domains')

  return useMutation<Response, AxiosError, Payload>(addDomain, {
    onSuccess: async (data) => {
      logEvent({
        ga: {
          category: 'Create Domain Success',
          action: 'Create Domain Success',
        },
      })

      if (data.registered_domain) {
        toast({
          ...TOAST_CONFIG,
          title: t('views.features.domains.index.add_success.title'),
          description: t('views.features.domains.index.add_success.desc'),
          duration: 50000,
          variant: 'left-accent',
        })
      } else {
        toast({
          ...TOAST_CONFIG,
          title: tDomains('addDomain.warning.title'),
          description: <Trans>{tDomains('addDomain.warning.desc')}</Trans>,
          status: 'warning',
          variant: 'left-accent',
        })
      }

      setTimeout(() => {
        window.location.href = `/projects/${data.id}/preview`
      }, 700)
    },
    onError(error) {
      handleError(error)
    },
  })
}
