import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useStripe } from '@stripe/react-stripe-js'

import { fetchAPI } from '@/lib/api'
import { logEvent, logEcommerce } from '@/lib/stats'

import { PaymentSubscription } from '../types'
import { useErrorHandler } from '@/lib/hooks'

type Payload = {
  data: {
    creditCardId: string
    token: string
    packageData: {
      name: PackageNameInUse
      price: number
      recurring: Period
    }
  }
}

type UseSubscriptionResult = {
  token: string
  packageData: {
    name: PackageNameInUse
    price: number
    recurring: Period
  }
}

export const useSubscription = () => {
  const stripe = useStripe()
  const { t } = useTranslation('payment')
  const handleError = useErrorHandler()

  const refresh = () => {
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  }

  return useMutation<UseSubscriptionResult | undefined, AxiosError, Payload>(
    async ({ data }: Payload) => {
      const { creditCardId, token, packageData } = data

      if (!stripe || !creditCardId) {
        throw new Error(t('toast.failed'))
      }

      const { data: subscription } = await fetchAPI<PaymentSubscription>({
        method: 'post',
        path: '/recurring',
        data: {
          credit_card_id: creditCardId,
          token,
        },
      })

      if (subscription.error) {
        throw subscription.error
      }

      if (
        !['active', 'not_started'].includes(subscription.status) ||
        subscription.pending_update
      ) {
        const paymentIntent = subscription.latest_invoice.payment_intent

        if (paymentIntent.status === 'requires_action') {
          const paymentMethodId =
            subscription.latest_invoice.payment_intent.payment_method.id

          const response = await stripe.confirmCardPayment(
            paymentIntent.client_secret,
            {
              payment_method: paymentMethodId,
            },
          )

          if (response.error) {
            throw response.error
          }

          return { token, packageData }
        }

        if (paymentIntent.status === 'requires_payment_method') {
          throw new Error('Your card was declined.')
        }
      } else {
        return { token, packageData }
      }
    },
    {
      onSuccess(result) {
        if (result) {
          const { token, packageData } = result

          logEvent({
            ga: {
              category: 'Payment Success',
              action: 'Payment Success',
              label: packageData.name,
              value: packageData.price,
            },
            fb: {
              event: 'Subscribe',
              value: packageData.price,
              currency: 'THB',
              predicted_ltv: '0.00',
            },
          })

          logEcommerce('purchase', {
            value: packageData.price,
            items: [
              {
                item_id: `${packageData.name}_${packageData.recurring}`,
                item_name: packageData.name,
                price: packageData.price,
                quantity: 1,
              },
            ],
          })
          window.location.href = `/payments/${token}/success`
        } else {
          refresh()
        }
      },
      onError(error) {
        handleError(error)
        refresh()
      },
    },
  )
}
