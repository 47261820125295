import { Box, Heading, Text } from '@chakra-ui/react'

import { t } from '@/lib/helpers'

import { TwoFactorPasswordForm } from '../2FactorPasswordForm'

import { useVerifyPassword } from '../../mutations'

export type PasswordWrapperProps = {
  children: React.ReactElement
  isCompleted: boolean
  onSuccess?: () => void
  onCancel: () => void
  title: string
}

export const PasswordWrapper = (
  props: PasswordWrapperProps,
): React.ReactElement => {
  const { title, onSuccess, isCompleted, children, onCancel } = props

  const { mutate: verifyPassword, isLoading: verifyLoading } =
    useVerifyPassword()

  if (isCompleted) return children

  return (
    <Box>
      <Heading as="h3" sx={{ pb: 0, fontSize: '24px' }}>
        {title}
      </Heading>
      <Text sx={{ fontSize: '12px', color: 'gray.600', mb: '16px' }}>
        {t(
          'views.features.two_factor.enter_your_password',
          undefined,
          'Enter your account password.',
        )}
      </Text>

      <TwoFactorPasswordForm
        isLoading={verifyLoading}
        onCancel={onCancel}
        onSubmit={(data) => {
          verifyPassword(
            { data },
            {
              onSuccess() {
                onSuccess?.()
              },
            },
          )
        }}
      />
    </Box>
  )
}
