import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/toast'

import { fetchAPI } from '@/lib/api'
import { ERROR_TOAST_CONFIG } from '@/lib/constants'
import { getErrorMsg } from '@/lib/helpers'

type Payload = {
  data: {
    payment_method_id: string
    default_card: boolean
  }
}

export const useCreateCreditCard = () => {
  const toast = useToast()

  return useMutation<AxiosResponse<void>, AxiosError, Payload>(
    ({ data }) => {
      return fetchAPI<void>({
        method: 'post',
        path: '/credit_cards',
        data,
      })
    },
    {
      onError(error) {
        toast({
          ...ERROR_TOAST_CONFIG,
          description: getErrorMsg(error),
        })
      },
    },
  )
}
