import { useToast } from '@chakra-ui/react'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { CompanySettingsFormInput } from '../types'
import { TOAST_CONFIG } from '@/lib/constants'
import { t } from '@/lib/helpers'

type Payload = {
  data: CompanySettingsFormInput
}

const updateCompanyProfile = async ({ data }: Payload) => {
  return await fetchAPI({
    path: `/settings/profile`,
    method: 'put',
    data,
  })
}

export const useUpdateCompanyProfile = () => {
  const handleError = useErrorHandler()
  const toast = useToast()

  return useMutation<AxiosResponse, AxiosError, Payload>(updateCompanyProfile, {
    async onSuccess() {
      toast({
        ...TOAST_CONFIG,
        description: t('views.features.users.services.update_company_success'),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
