import { get, omit } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { ConsentLog } from '../types'
import { Meta } from '@/features/filters/types'

import { fetchAPI } from '@/lib/api'
import { getPathParams } from '@/lib/routes'
import { CONSENTS } from '../constants'
import { useFilterContext } from '@/features/filters/context'

type ConsentsResponse = {
  data: ConsentLog[]
  meta: Meta & {
    remember_choices?: Record<string, number>
    consent_categories?: Record<string, number>
  }
}

type Params = {
  project_id: number
  meta: Meta & {
    remember_choice?: number | string
    category_id?: number | string
  }
}

const transformedData = (
  datum: any,
  rememberChoices: Record<string, string>,
) => {
  return {
    ...datum,
    remember_choice: get(
      rememberChoices,
      `${datum.remember_choice}`,
      'unchecked',
    ),
  }
}

const getConsents = async (params: Params): Promise<ConsentsResponse> => {
  const { project_id } = params

  const response = await fetchAPI({
    path: `/projects/${project_id}/consents`,
    params: params.meta,
  })

  const meta = get(response, 'data.meta', {})
  const rememberChoices = Object.fromEntries(
    Object.entries(get(meta, 'remember_choices', {})).map(([key, value]) => [
      value,
      key,
    ]),
  )

  const data = get(response, 'data.data', []).map((datum: any) =>
    transformedData(datum, rememberChoices),
  )

  return {
    data,
    meta,
  }
}

export const useConsents = ({
  initialData,
  enabled,
}: {
  initialData?: ConsentsResponse
  enabled?: boolean
}) => {
  const { meta } = useFilterContext()
  const query = getPathParams()

  const params = {
    project_id: Number(query?.project_id),
    meta: omit(meta, 'counts'),
  }

  return useQuery<ConsentsResponse, AxiosError>({
    queryKey: [CONSENTS, params],
    queryFn: () => getConsents(params),
    keepPreviousData: true,
    initialData,
    enabled,
  })
}
