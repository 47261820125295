import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { ModalBody, ModalHeader } from '@chakra-ui/modal'

import { Modal } from '@/components/shared'
import { UserForm } from '../UserForm'

import { t } from '@/lib/helpers'
import { useUpdateUser } from '../../mutations'

import { NormalUser } from '../../types'
import { userIntoFormInput } from '../../utils'

export type EditUserModalProps = {
  user: NormalUser
  disclosure: UseDisclosureReturn
}

export const EditUserModal = (props: EditUserModalProps) => {
  const { user, disclosure } = props
  const { isOpen, onClose } = disclosure

  const { mutate, isLoading } = useUpdateUser()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <ModalHeader sx={{ pb: 0 }}>
        {t('views.features.users.index.edit_user', undefined, 'Edit User')}
      </ModalHeader>

      <ModalBody>
        <UserForm
          defaultValues={userIntoFormInput(user)}
          isLoading={isLoading}
          onClose={onClose}
          onSubmitForm={(data) => {
            mutate(
              {
                data,
                params: {
                  user_id: user.id,
                },
              },
              {
                onSuccess: onClose,
              },
            )
          }}
        />
      </ModalBody>
    </Modal>
  )
}
