export default {
  packageBanner: {
    freePlanDetail: 'คุณสามารถใช้แพ็กเกจนี้ได้จนกว่าจะมีการเปลี่ยนแปลง',
    domains: 'Domains',
    pageviews: 'Pageviews',
    perAccount: 'Per Account',
    perMonth: 'Per Month',
    yourCurrentPackage: 'แพ็กเกจปัจจุบัน',
    cancelAtPeriodEndText:
      'คุณได้ยกเลิกแพ็กเกจนี้แล้ว ใช้ได้ถึงวันที่ <1>{{expiry}}</1>',
    resetOn: 'เริ่มนับใหม่เมื่อ',
  },
  pricingTable: {
    subtitle: 'ประหยัดกว่าถึง 20% เพียงจ่ายแบบรายปี (ราคารวม VAT 7%)',
    smallTryFree: 'ทดลองฟรี 14 วัน',
    mediumTryFree: 'ทดลองฟรี 14 วัน',
    largeTryFree: 'ทดลองฟรี 7 วัน',
    numberOfPageview: 'Pageview รวมต่อเดือน',
    numberOfPageviewTooltip:
      'จำนวนครั้งที่เว็บไซต์ถูกเข้าชมนับรวมกันในทุกโดเมน ซึ่งจะนับทุกครั้งที่มีผู้เยี่ยมชมเว็บไซต์ไม่ว่าจะเคยเข้ามาแล้วหรือไม่ก็ตาม',
    numberOfDomain: 'จำนวนโดเมน',
    numberOfDomainTooltip:
      'จำนวนโดเมนที่ต้องการติดคุกกี้แบนเนอร์ โดยซับโดเมนจะถูกนับเป็น 1 โดเมนเช่นกัน',
    numberOfUser: 'จำนวนบัญชีผู้ใช้งาน',
    numberOfUserTooltip:
      'จำนวนบัญชีผู้ใช้งานที่สามารถจัดการคุกกี้แบนเนอร์ โดยสามารถแบ่งให้ผู้ใช้งานแต่ละคนสามารถเข้าถึงโดเมนที่แตกต่างกันได้',
    numberOfWebsiteScan: 'สแกนเว็บไซต์',
    numberOfWebsiteScanUnit: '(จำนวน URL ต่อวัน)',
    numberOfWebsiteScanTooltip:
      'จำนวน URL ที่สามารถสแกนได้ใน 1 วันนับรวมทุกโดเมน',
    numberOfConsent: 'บันทึกความยินยอมสูงสุด',
    numberOfConsentTooltip:
      'จำนวนของบันทึกความยินยอมสูงสุดที่สามารถจัดเก็บไว้ได้ เมื่อบันทึกความยินยอมมีจำนวนมากจนใกล้ถึงปริมาณสูงสุด บันทึกที่เก่าที่สุดจะถูกลบออก',
    retentionPeriod: 'ระยะเวลาจัดเก็บ',
    retentionPeriodTooltip:
      'ระยะเวลาในการจัดเก็บบันทึกความยินยอม บันทึกที่หมดอายุจะถูกแทนที่ด้วยบันทึกอันใหม่',
    autoScriptBlock: 'บล็อกสคริปต์อัตโนมัติ',
    autoScriptBlockTooltip:
      'Cookie wow มีการบล็อกคุกกี้อัตโนมัติจนกว่าจะได้รับความยินยอมจากผู้เข้าชมเว็บไซต์',
    consentLogs: 'บันทึกความยินยอม',
    consentLogsTooltip:
      'บันทึกความยินยอมที่จัดเก็บทุกครั้งที่ผู้เข้าชมเว็บไซต์ได้ให้ความยินยอมทุกสถานะ',
    advancedCustomize: 'ปรับแต่งแบนเนอร์คุกกี้ขั้นสูง',
    advancedCustomizeTooltip:
      'การปรับแต่งแบนเนอร์ขั้นสูงด้วยการใช้โค้ด CSS เข้าไปปรับแต่งตามจุดต่างๆ บนคุกกี้แบนเนอร์',
    brandingCustomize: 'ปรับแต่งโลโก้',
    brandingCustomizeTooltip:
      'สามารถปรับแต่งโลโก้ให้เป็นไปตามแบรนด์ของคุณได้ตามต้องการ',
    unlimitedDomains: 'ไม่จำกัดโดเมน',
    unlimitedUsers: 'ไม่จำกัดจำนวนผู้ใช้',
    unlimitedConsents: 'ไม่จำกัดจำนวนความยินยอม',
    consent: 'ความยินยอม',
    // free: {
    //   description:
    //     'เหมาะสำหรับบริษัทหรือทีมขนาดเล็ก รองรับได้ 30,000 pageviews',
    // },
    small: {
      description:
        'เหมาะสำหรับองค์กร<strong>ขนาดเล็กถึงกลาง</strong> บริหารจัดการได้ 10 โดเมน รวมถึงปรับแต่งดีไซน์ตัวแบนเนอร์ได้อย่างอิสระ',
    },
    medium: {
      description:
        'เหมาะสำหรับองค์กร<strong>ขนาดกลางไปจนถึงขนาดใหญ่</strong> สามารถเพิ่มผู้ใช้งานได้ถึง 10 แอคเคาท์ บริหารจัดการได้ 200 โดเมน อีกทั้งไม่จำกัดจำนวนความยินยอม',
    },
    large: {
      description:
        'เหมาะสำหรับกลุ่ม<strong>ธุรกิจขนาดใหญ่</strong>หรือเอเจนซี่ สามารถใช้งานได้อย่างไร้ขีดจำกัด ไม่จำกัดจำนวนโดเมน จำนวนผู้ใช้งาน ไปจนถึงจำนวนความยินยอม',
    },
    enterprise: {
      description:
        'สำหรับ<strong>องค์กรขนาดใหญ่หรือเอเจนซี่</strong>ที่ต้องการการปรับแต่งที่มากกว่า',
      bullet_0: 'Dedicated infrastructure',
      bullet_1: 'มีผู้เชี่ยวชาญ Solution Engineer คอยให้คำปรึกษา',
      bullet_2: 'มีการอบรมสำหรับผู้ใช้งาน',
      bullet_3: 'ดูแลการใช้งาน API',
      contactUs: 'ติดต่อเรา',
    },
    enterpriseText:
      'ติดตั้งระบบ <1>Cookie Wow</1><br />บนคลาวด์หรือบนเซิร์ฟเวอร์ ของคุณเอง<br />ติดต่อเรา <5>โทร. 02-024-5560</5>',
  },
  monthlyBilling: 'ชำระเป็นรายเดือน',
  yearlyBilling: 'ชำระเป็นรายปี',
  unsubscription: {
    unsubReason: {
      title: 'เราเสียใจเป็นอย่างยิ่ง<br />ที่คุณต้องการยกเลิกบริการจากเรา',
      description:
        'โปรดสละเวลาสักครู่เพื่อบอกเราว่า เหตุใดคุณจึงไม่ต้องการสมัครสมาชิกอีกต่อไป',
    },
  },
  schedulePackage:
    'คุณมีแพ็กเกจที่จะเริ่มใช้งานอยู่ในระบบ<br/>กรุณาติดต่อเจ้าหน้าที่',
  manualPaymentPackage:
    'ไม่สามารถดำเนินการนี้กับแพ็กเกจที่ชำระเงินด้วยตนเองได้<br/>กรุณาติดต่อเจ้าหน้าที่',
}
