import { t } from '@/lib/helpers'

import { NavItem } from '../types'

export const navsData: NavItem[] = [
  {
    title: t('views.components.layout.nav.manage_cookie'),
    href: '/projects',
    checkActive: [
      'domains',
      'domainConsent',
      'domainCustomize',
      'domainCookiesScanner',
      'domainManageCookies',
      'domainPreview',
      'domainBannerSettings',
    ],
    feature: 'project',
  },
  {
    title: t('views.components.layout.nav.packages'),
    href: '/packages',
    checkActive: [
      'packages',
      'freeTrial',
      'receipts',
      'checkout',
      'pageViews',
      'payments',
      'paymentsSuccess',
    ],
    feature: 'payment',
  },
]

export const subLinks: NavItem[] = [
  {
    title: t('views.components.layout.nav.user_managements'),
    href: '/user_managements',
    checkActive: ['userManagements', 'activityLogs'],
    feature: 'user_management',
  },
  {
    title: t('views.components.layout.nav.activity_log'),
    href: '/activity_logs',
    checkActive: ['userManagements', 'activityLogs'],
    feature: 'activity_log',
  },
]

export const helpCenterLinks: NavItem[] = [
  {
    title: t('views.components.layout.nav.quick_start_guide'),
    href: 'https://help.cookiewow.com/th/article/cookie-wow-quick-start-guide-cookie-banner-1dcsx08',
    checkActive: [],
  },
  {
    title: t('views.components.layout.nav.documentation'),
    href: 'https://help.cookiewow.com/th',
    checkActive: [],
  },
]
