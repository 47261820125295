import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { UserFormInput } from '../types'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { USERS } from '../constants'
import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  data: Partial<UserFormInput>
}

const inviteUser = async ({ data }: Payload) => {
  return await fetchAPI({
    path: '/users/invitation',
    method: 'post',
    data: {
      user: data,
    },
  })
}

export const useInviteUser = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, Payload>(inviteUser, {
    async onSuccess() {
      await queryClient.refetchQueries([USERS])

      toast({
        ...TOAST_CONFIG,
        description: t('views.features.users.services.invite_user_success'),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
