import { ModalBody, ModalHeader, UseDisclosureReturn } from '@chakra-ui/react'

import { t } from '@/lib/helpers'

import { Modal } from '@/components/shared/Modal'
import { ManageCookieForm } from './ManageCookieForm'

import { useAddCookie } from '../../mutations'
import { getPathParams } from '@/lib/routes'
import { populateMultiLangField } from '../../Manage/helpers'

import { BannerLanguage, Category } from '../../types'

type Props = {
  disclosure: UseDisclosureReturn
  currentCategory: Category
  activeLanguage: BannerLanguage
}

export const AddCookieModal = (props: Props) => {
  const { activeLanguage, currentCategory, disclosure } = props
  const { isOpen, onClose } = disclosure

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  const { mutate: addCookie, isLoading } = useAddCookie()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="560px" withCloseButton>
      <ModalHeader>
        {t('views.features.domains.show.manage.form.create_cookie')}
      </ModalHeader>

      <ModalBody>
        <ManageCookieForm
          onSubmitForm={(data) => {
            const populatedPurpose = populateMultiLangField(
              data.purpose[activeLanguage],
            )
            const populatedDuration = populateMultiLangField(
              data.duration[activeLanguage],
            )

            addCookie(
              {
                data: {
                  ...data,
                  purpose: populatedPurpose,
                  duration: populatedDuration,
                },
                params,
              },
              {
                onSuccess: onClose,
              },
            )
          }}
          onCancel={onClose}
          isLoading={isLoading}
          categoryId={currentCategory.id}
          activeLanguage={activeLanguage}
        />
      </ModalBody>
    </Modal>
  )
}
