import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import { fetchAPI } from '@/lib/api'

import { useErrorHandler } from '@/lib/hooks'

const resubscribe = () => {
  return fetchAPI({
    path: '/packages/auto_renew',
    method: 'put',
  })
}

export const useResubscribe = () => {
  const handleError = useErrorHandler()

  return useMutation<AxiosResponse, AxiosError>(resubscribe, {
    onSuccess() {
      window.setTimeout(() => {
        window.location.reload()
      }, 2000)
    },
    onError(error) {
      handleError(error)
    },
  })
}
