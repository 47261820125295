import { BANNER_LANGUAGE } from '@datawowio/cookie-banner-ui'

import { MultiLangField } from '../types'

export const populateMultiLangField = (value: string): MultiLangField => {
  return BANNER_LANGUAGE.reduce(
    (sum, cur) => ({ ...sum, [cur]: value }),
    {} as MultiLangField,
  )
}
