import { useMemo } from 'react'

export const useMemoImage = (image?: string | File | null) => {
  return useMemo(() => {
    if (!image) return undefined

    if (typeof image === 'string') {
      return image
    }

    return URL.createObjectURL(image)
  }, [image])
}
