import { useEffect, useState } from 'react'
import { Box, Image, Text, useDisclosure } from '@chakra-ui/react'
import { CardItem } from '@datawowio/payment-ui'

import { useUpdatePaymentStore } from '@/features/payments/stores'
import NoCard from '@/images/project/payments/no-creditcard.svg'
import { t } from '@/lib/helpers'

import { CreditCard } from '../../types'
import { getNewCard, sortCreditCards } from '@/lib/credit_cards'
import { DeleteCardModal, DeleteCardSuccessModal } from '../UpdatePaymentModal'

type Props = {
  cards?: CreditCard[]
  subscriptionCreditCard?: CreditCard
}

export const UpdateCreditCardList = ({
  cards,
  subscriptionCreditCard,
}: Props) => {
  const deleteDisclosure = useDisclosure()
  const deleteSuccessDisclosure = useDisclosure()
  const { cardId, setCardId } = useUpdatePaymentStore()
  const [deleteCard, setDeleteCard] = useState<CreditCard>()
  const [sortedCards, setSortedCards] = useState(() =>
    sortCreditCards(cards, subscriptionCreditCard),
  )
  const [prevCards, setPrevCards] = useState(() =>
    sortCreditCards(cards, subscriptionCreditCard),
  )

  useEffect(() => {
    if (!cards) return

    const newSortedCards = sortCreditCards(cards, subscriptionCreditCard)

    if (cards?.length > prevCards?.length) {
      const newCard = getNewCard(cards, prevCards)
      setSortedCards((prevSortedCards) => newCard.concat(prevSortedCards))
      setPrevCards(newSortedCards)
    } else {
      setSortedCards(newSortedCards)
      setPrevCards(newSortedCards)
    }
  }, [cards])

  return (
    <>
      {!cards?.length ? (
        <Box sx={{ textAlign: 'center' }}>
          <Image
            boxSize="120px"
            objectFit="cover"
            src={NoCard}
            sx={{ margin: 'auto', mt: 9 }}
          />
          <Text sx={{ color: 'gray.600', mt: 4, fontWeight: 700 }}>
            {t('views.features.payments.update.no_credit_card')}
          </Text>
        </Box>
      ) : (
        <Box>
          {sortedCards.map((card) => {
            const { id } = card

            return (
              <CardItem
                key={id}
                id={String(id)}
                currentLng={gon.currentLocale}
                isSelected={cardId === id}
                primary={subscriptionCreditCard?.id === id}
                cardType={card.brand}
                shownNumber={card.number}
                onClick={() => {
                  setCardId(id)
                }}
                onDelete={() => {
                  setDeleteCard(card)
                  deleteDisclosure.onOpen()
                }}
              />
            )
          })}
        </Box>
      )}

      {deleteCard && (
        <>
          <DeleteCardModal
            disclosure={deleteDisclosure}
            card={deleteCard}
            successOpen={deleteSuccessDisclosure.onOpen}
          />
          <DeleteCardSuccessModal
            disclosure={deleteSuccessDisclosure}
            deleteCard={deleteCard}
          />
        </>
      )}
    </>
  )
}
