import { ComponentSingleStyleConfig } from '@chakra-ui/react'
import {
  mode,
  transparentize,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'

const variantGhost: SystemStyleFunction = (props) => {
  const { colorScheme: c, theme } = props

  if (c === 'gray') {
    return {
      color: mode(`gray.600`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
    }
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme)
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme)

  return {
    color: mode(`${c}.600`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  }
}

const variants = {
  ghost: variantGhost,
}

export const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: '4px',
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'brand',
  },
  sizes: {
    xl: {
      h: '64px',
      fontSize: 'xl',
      px: 8,
    },
  },
  variants,
}
