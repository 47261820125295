import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { AxiosError, AxiosResponse } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { useErrorHandler } from '@/lib/hooks'

import { DomainFormInput } from '../types'
import { PROJECT } from '../constants'
import { FOLDERS } from '@/features/folders/constants'

type Payload = {
  data: DomainFormInput
  params: {
    projectId: number
  }
  isProjectPage?: boolean
}

const updateDomain = async ({
  data,
  params,
}: Payload): Promise<AxiosResponse> => {
  return await fetchAPI<Response>({
    path: `/projects/${params.projectId}`,
    method: 'put',
    data,
  })
}

export const useUpdateDomain = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, Payload>(updateDomain, {
    onSuccess: async (_, payload) => {
      if (payload.isProjectPage) {
        await queryClient.refetchQueries([PROJECT])
      } else {
        await queryClient.refetchQueries([FOLDERS])
      }

      toast({
        description: t('views.features.domains.index.edit_success'),
        status: 'success',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
