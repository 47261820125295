import { Fragment } from 'react'
import { Link, Stack, Text } from '@chakra-ui/layout'
import { useDisclosure } from '@chakra-ui/hooks'

import { DomainShowLayout } from '@/features/domains/components'
import { withPage } from '@/features/layout'

import { CookiesTable } from '@/features/domains/components/CookiesScanner'
import { ScanStatus } from '@/features/domains/components/ScanStatus'
import { ScannerDescription } from './ScannerDescription'
import { ScannedUrlTable } from '../../Scanner/components'

import { t } from '@/lib/helpers'
import { useScannedCookies, useScannedURL } from '@/features/domains/queries'
import { useAbility } from '@/features/permissions'

import { PollingResponse, PollingStatus } from '@/features/types'
import { Cookie } from '../../types'
import { HELPDESK_LINK } from '../../constants'

interface Props {
  project: Project
  cookies: Cookie[]
  categories: Category[]
  scan_status: PollingResponse
  banner_polling_status: PollingStatus
  surveysensum_url: string
}

function DomainScannerPage(props: Props) {
  const {
    cookies,
    project,
    categories,
    banner_polling_status,
    surveysensum_url,
  } = props
  const { id } = project

  const disclosure = useDisclosure({
    defaultIsOpen: true,
  })
  const { isOpen: isShowScannedCookie, onToggle } = disclosure

  const ability = useAbility()
  const canUpdateCookie = ability.can('update', 'cookie')

  const { data: scannedCookies } = useScannedCookies({
    params: {
      projectId: id,
    },
    initialData: cookies,
  })
  const scanStatusQuery = useScannedURL({
    projectId: id,
  })
  const { data: scanStatus } = scanStatusQuery

  return (
    <DomainShowLayout
      project={project}
      surveyUrl={surveysensum_url}
      buttons={
        <ScanStatus
          projectId={project.id}
          scanStatusQuery={scanStatusQuery}
          {...(scanStatus?.data?.urls?.length && { onToggle })}
        />
      }
      bannerPollingStatus={banner_polling_status}
      linkHref={HELPDESK_LINK.COOKIE_SCANNER}
      linkTitle={t('views.features.domains.show.scanner_guide')}
    >
      <Stack p="32px 32px 0" spacing="24px" alignItems="stretch">
        {isShowScannedCookie ? (
          <Fragment>
            <ScannerDescription />

            {scannedCookies && (
              <CookiesTable
                cookies={scannedCookies}
                categories={categories}
                project={project}
                canUpdateCookie={canUpdateCookie}
              />
            )}
          </Fragment>
        ) : (
          <Stack spacing="16px" sx={{ pb: '24px' }}>
            <Text sx={{ fontWeight: 700 }}>
              {t('views.features.domains.show.scanner.title_scanned_url')}

              <Link
                sx={{
                  color: 'brand.400',
                  textDecor: 'underline',
                  d: 'inline-block',
                  ml: '8px',
                }}
                onClick={onToggle}
              >
                {t('views.features.domains.show.scanner.back_to_cookies_list')}
              </Link>
            </Text>

            {scanStatus?.data.urls && (
              <ScannedUrlTable data={scanStatus?.data.urls} />
            )}
          </Stack>
        )}
      </Stack>
    </DomainShowLayout>
  )
}

export default withPage(DomainScannerPage)
