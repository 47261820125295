import { BANNER_LANGUAGE } from '@datawowio/cookie-banner-ui'

import { MultiLangField } from '../types'

export * from './queryKeys'
export * from './bannerText'
export * from './consents'
export * from './helpdesk'

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
}

export const DEFAULT_THEME_COLOR = {
  [THEME.LIGHT]: {
    bg_color: '#ffffffff',
    text_color: '#4a4a4a',
  },
  [THEME.DARK]: {
    bg_color: '#2b2b2bff',
    text_color: '#ffffff',
  },
}

export const POSITION = {
  ClassicBottom: 'classic-bottom',
  ClassicTop: 'classic-top',
  FullwidthBottom: 'fullwidth-bottom',
  FullwidthTop: 'fullwidth-top',
  SideTopLeft: 'side-top-left',
  SideTopRight: 'side-top-right',
  SideBottomLeft: 'side-bottom-left',
  SideBottomRight: 'side-bottom-right',
  Popup: 'popup',
}

export const PositionArray = Object.values(POSITION)

export const DEFAULT_CONTENT_TEMPLATE: Record<BannerLanguage, any> = {
  en: {
    short: {
      title: 'We use cookies',
      content:
        'We use cookies to improve your experience and performance on our website. You can manage your preferences by clicking "Change Preferences".',
    },
    long: {
      title: 'We use cookies',
      content:
        'This website uses cookies to enhance your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from. You can manage your preferences by clicking "Change Preferences".',
    },
    notice_only: {
      title: 'We use cookies',
      content:
        'Our website uses cookies to enhance the website’s usage and to assist in our marketing.',
    },
  },
  th: {
    short: {
      title: 'เว็บไซต์นี้ใช้คุกกี้',
      content:
        'เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ และประสบการณ์ที่ดีในการใช้งานเว็บไซต์ คุณสามารถเลือกตั้งค่าความยินยอมการใช้คุกกี้ได้ โดยคลิก "การตั้งค่าคุกกี้"',
    },
    long: {
      title: 'เว็บไซต์นี้ใช้คุกกี้',
      content:
        'เราใช้คุกกี้เพื่อเพิ่มประสบการณ์ที่ดีในการใช้เว็บไซต์ แสดงเนื้อหาและโฆษณาให้ตรงกับความสนใจ รวมถึงเพื่อวิเคราะห์การเข้าใช้งานเว็บไซต์และทำความเข้าใจว่าผู้ใช้งานมาจากที่ใด คุณสามารถเลือกตั้งค่าความยินยอมการใช้คุกกี้ได้ โดยคลิก “การตั้งค่าคุกกี้”',
    },
    notice_only: {
      title: 'เว็บไซต์นี้ใช้คุกกี้',
      content:
        'เว็บไซต์ของเรามีการใช้คุกกี้เพื่อใช้ในการเพิ่มประสิทธิภาพการใช้งานของเว็บไซต์และเพื่อช่วยส่งเสริมการตลาดของเรา',
    },
  },
  zh: {
    short: {
      title: '关于Cookie',
      content:
        '此网页使用Cookie来分析访问数据以提供更好的浏览体验。您可以在「Cookie设置」中允许或关闭Cookie的使用权。',
    },
    long: {
      title: '关于Cookie',
      content:
        '为了更好的浏览体验，此网页使用Cookie来分析访问数据，以及调整广告的显示。您可以在「Cookie设置」中允许或关闭Cookie的使用权。',
    },
    notice_only: {
      title: '关于Cookie',
      content: '此网页使用Cookie来增强浏览体验以提高用户对网页的使用频率。',
    },
  },
  ja: {
    short: {
      title: 'Cookieの使用について',
      content:
        'このサイトはCookieを使用して、サイトのパーフォマンスやお客様のブラウジング経験を向上させています。Cookieの許可や拒否などは「Cookieの設定」をクリックしてください。',
    },
    long: {
      title: 'Cookieの使用について',
      content:
        'このサイトはCookieを使用して、訪問者のアクセス元やトラフィックを分析し、表示される広告を調整することでお客様のブラウジング体験を向上させています。Cookieの許可や拒否などは「Cookieの設定」をクリックしてください。',
    },
    notice_only: {
      title: 'Cookieの使用について',
      content:
        'このサイトはCookieを使用して、マーケティングを支援し、サイトの使用頻度を高めています。',
    },
  },
} as const

export const CONTENT_TEMPLATE = {
  title: {
    en: DEFAULT_CONTENT_TEMPLATE.en.short.title,
    th: DEFAULT_CONTENT_TEMPLATE.th.short.title,
    ja: DEFAULT_CONTENT_TEMPLATE.ja.short.title,
    zh: DEFAULT_CONTENT_TEMPLATE.zh.short.title,
  },
  content: {
    en: DEFAULT_CONTENT_TEMPLATE.en.short.content,
    th: DEFAULT_CONTENT_TEMPLATE.th.short.content,
    ja: DEFAULT_CONTENT_TEMPLATE.ja.short.content,
    zh: DEFAULT_CONTENT_TEMPLATE.zh.short.content,
  },
} as const

export const DISPLAY_BANNER_SCRIPT =
  `<a class="cwc-banner-show-settings">Cookie Settings</a>` as const

export const COOKIE_ICON_OPTIONS = ['default', 'upload', 'favicon'] as const

export const FONT_SIZE = ['sm', 'md'] as const

export const DEFAULT_BORDER_RADIUS: Record<string, string> = {
  banner_radius: '8',
  button_radius: '6',
}

export const INITIAL_MULTI_LANG_FIELD: MultiLangField = BANNER_LANGUAGE.reduce(
  (sum, cur) => ({ ...sum, [cur]: '' }),
  {} as MultiLangField,
)

export const PATTERN = /https?:\/\/[^\/]*\/.*/
