import { useForm } from 'react-hook-form'
import {
  UseDisclosureReturn,
  ModalBody,
  ModalHeader,
  Input,
  Stack,
  Button,
} from '@chakra-ui/react'

import { t } from '@/lib/helpers'

import { Modal } from '@/components/shared'
import { FormControl } from '@/components/FormControl'

import { EditFolderFormInput, Folder } from '@/features/folders/types'

import { useUpdateFolder } from '../../mutations'
import { useNonInitialEffect } from '@/lib/hooks'

export type EditFolderModalProps = {
  disclosure: UseDisclosureReturn
  folder: Folder
}

export const EditFolderModal = (props: EditFolderModalProps) => {
  const { disclosure, folder } = props
  const { isOpen, onClose } = disclosure

  const { handleSubmit, register, setValue } = useForm<EditFolderFormInput>({
    defaultValues: {
      name: folder.url,
    },
  })

  useNonInitialEffect(() => {
    setValue('name', folder.url)
  }, [folder])

  const { mutate: updateFolder, isLoading } = useUpdateFolder()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <ModalHeader>
        {t('views.features.folders.edit_folder', undefined, 'Edit Folder')}
      </ModalHeader>

      <ModalBody>
        <form
          onSubmit={handleSubmit((values) => {
            updateFolder(
              {
                data: values,
                params: {
                  folder_id: folder.id,
                },
              },
              {
                onSuccess: onClose,
              },
            )
          })}
        >
          <Stack spacing="6">
            <FormControl
              label={t(
                'views.features.folders.folder_name',
                undefined,
                'Folder Name',
              )}
            >
              <Input {...register('name')} autoFocus />
            </FormControl>
            <Stack>
              <Button size="xl" type="submit" isLoading={isLoading}>
                {t('views.shared.button.update', undefined, 'Update')}
              </Button>
              <Button
                variant="ghost"
                colorScheme="gray"
                color="gray.600"
                size="xl"
                onClick={onClose}
                isDisabled={isLoading}
              >
                {t('views.shared.button.discard', undefined, 'Discard')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </ModalBody>
    </Modal>
  )
}
