export default {
  layout: {
    bannerStatus: 'สถานะของแบนเนอร์',
    checkStatus: 'ตรวจสอบสถานะ',
    bannerStatusUpdated: 'ตรวจสอบสถานะแบนเนอร์เมื่อ',
    tabs: {
      consent: 'บันทึกความยินยอม',
      scanner: 'สแกนคุกกี้',
      manage: 'จัดการคุกกี้',
      customize: 'ปรับแต่งแบนเนอร์',
      bannerSettings: 'ตั้งค่าแบนเนอร์',
    },
  },
  bannerSettings: {
    menus: {
      bannerModel: 'รูปแบบของแบนเนอร์',
      displayBanner: 'ตั้งค่าการแสดงผลแบนเนอร์',
    },
    toast: {
      updateBannerModelSuccess: 'อัปเดตรูปแบบของแบนเนอร์สำเร็จ',
      updateBannerDisplaySuccess: 'อัปเดตการตั้งค่าการแสดงผลแบนเนอร์สำเร็จ',
    },
  },
  customize: {
    bannerButtons: {
      closeBannerDefault: 'ให้แบนเนอร์ขึ้นมาอีกครั้งหลังจากผ่านไป 1 ปี',
      closeBannerSession: 'ให้แบนเนอร์ขึ้นมาอีกครั้งเมื่อ Session หมดอายุ',
      closeBannerSessionTooltip:
        'แบนเนอร์จะปรากฏขึ้นมาอีกครั้งเมื่อ Session ID ของผู้ใช้งานเปลี่ยนแปลง เช่น เมื่อปิดบราวเซอร์แล้วเปิดขึ้นมาใหม่อีกครั้ง',
      closeBannerTimer: 'ให้แบนเนอร์ขึ้นมาอีกครั้งตามเวลาที่กำหนด (นาที)',
      closeBannerTimerTooltip:
        'แบนเนอร์จะปรากฏขึ้นมาใหม่หากเวลาผ่านไปตามที่ท่านกำหนด ค่าต่ำสุดคือ 0 นาที (แบนเนอร์จะปรากฏทันทีหากผู้เยี่ยมชมเว็บไซต์รีเฟรชหน้าเว็บไซต์หรือเข้าไปยังหน้าอื่นภายใต้โดเมนเดียวกัน) และค่าสูงสุดคือ 1 ปี หรือ 525,600 นาที',
    },
  },
  manage: {
    category: {
      optInDesc:
        'คุกกี้ในหมวดหมู่นี้จะถูกปิดการทำงานตั้งแต่แรก และผู้เยี่ยมชมเว็บไซต์ต้องให้ความยินยอม(ติ๊กเข้า)ก่อน Cookie Wow จึงจะอนุญาตให้คุกกี้ในหมวดหมู่นี้ทำงานได้',
      optOutDesc:
        'คุกกี้ในหมวดหมู่นี้จะถูกเปิดการทำงานตั้งแต่แรก และผู้เยี่ยมชมเว็บไซต์สามารถปิดการทำงาน(ติ๊กออก)ได้',
      noticeOnlyDesc:
        'คุกกี้ในหมวดหมู่นี้จะถูกอนุญาตให้ทำงานตั้งแต่แรกโดยผู้เยี่ยมชมเว็บไซต์จะไม่สามารถปิดได้',
    },
  },
  addDomain: {
    warning: {
      title: 'ไม่พบโดเมนบนเซิร์ฟเวอร์',
      desc: 'ไม่พบโดเมนของคุณบนเซิร์ฟเวอร์ <br/> แต่คุณยังสามารถใช้งานได้ต่อ',
    },
  },
}
