import {
  Button,
  Text,
  Grid,
  Image,
  Stack,
  Icon,
  Box,
  Link,
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import { FaCheckCircle } from 'react-icons/fa'

import EnterprisePlan from '@/images/pricing/plans/enterprise.png'

import { NDATA_SALES_EMAIL } from '@/constants/links'

export const EnterpriseBanner = () => {
  const { t: tPackages } = useTranslation('packages')

  return (
    <Grid
      sx={{
        p: '16px 28px',
        gap: '40px',
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: '16px',
        gridTemplateColumns: '182px 1fr 215px',
      }}
    >
      <Image src={EnterprisePlan} sx={{ boxSize: '182px' }} />

      <Box>
        <Text sx={{ fontSize: '32px', fontWeight: 'bold' }}>Enterprise</Text>

        <Text sx={{ color: 'gray.600', mt: 0.5 }}>
          <Trans
            t={tPackages}
            i18nKey="pricingTable.enterprise.description"
          ></Trans>
        </Text>

        <Stack spacing="2px" sx={{ mt: 2 }}>
          {Array.from(Array(4)).map((_, idx) => {
            return (
              <Grid
                key={idx}
                sx={{
                  gridTemplateColumns: '16px max-content',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <Icon as={FaCheckCircle} boxSize="16px" color="#60D0A6" />

                <Text>
                  {tPackages(`pricingTable.enterprise.bullet_${idx}`)}
                </Text>
              </Grid>
            )
          })}
        </Stack>
      </Box>

      <Link href={`mailto:${NDATA_SALES_EMAIL}`} sx={{ alignSelf: 'end' }}>
        <Button sx={{ borderRadius: 'full', w: 'full' }} size="lg">
          {tPackages('pricingTable.enterprise.contactUs')}
        </Button>
      </Link>
    </Grid>
  )
}
