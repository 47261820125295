import { AxiosError } from 'axios'
import { get } from 'lodash'
import { useQuery } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { Meta } from '@/features/filters/types'
import { PageView } from '../types'
import { PAGEVIEWS, PAGEVIEWS_DEFAULT_PER } from '../constants'
import { useFilterContext } from '@/features/filters/context'

type GetPageViewsParams = {
  meta: Meta & {
    start_date: string | undefined
    end_date: string | undefined
    url: string[]
  }
}

type PageViewsResponse = {
  data: PageView[]
  meta: Meta
}

const getPageViews = async (
  params: GetPageViewsParams,
): Promise<PageViewsResponse> => {
  const response = await fetchAPI({
    path: '/retrieve_page_views',
    params: params.meta,
  })

  const meta = get(response, 'data.meta', {})
  const data = get(response, 'data.data', [])

  return {
    data,
    meta,
  }
}

export const usePageViews = () => {
  const { meta } = useFilterContext()

  const startDate = meta.start_date?.toString()
  const endDate = meta.end_date?.toString()

  const params = {
    meta: {
      page: meta.page,
      per: PAGEVIEWS_DEFAULT_PER,
      start_date: startDate,
      end_date: endDate,
      url: meta.url?.filter((u) => u) || [],
    },
  }

  return useQuery<PageViewsResponse, AxiosError>({
    queryKey: [PAGEVIEWS, params],
    queryFn: () => getPageViews(params),
  })
}
