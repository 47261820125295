import { useRef, useState, useEffect } from 'react'
import { Button, ModalHeader, Stack, Text, useToast } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import { useTransition } from 'react-spring'

import { Modal } from '@/components/shared'
import { SvgImage } from '@/components/DataDisplay'
import { PackageDetails } from './shared'
import { AnimatedStack } from '@/lib/springComponent'
import { UnsubscribeReasonForm } from '../UnsubscrbeReasonForm'

import unsubPlan from '@/images/project/payments/unsub-plan.png'

import { usePackageStore } from '@/features/payments/stores'
import { useUnsubscribe } from '../../mutations'
import { useNonInitialEffect } from '@/lib/hooks'
import { matchLanguage, t } from '@/lib/helpers'
import { getQueryParam } from '@/lib/queryParams'

import { TOAST_CONFIG } from '@/lib/constants'

export const UnsubscribeModal = () => {
  const initialRef = useRef(null)
  const {
    expiry,
    unsubscribeDisclosure: { isOpen, onClose, onOpen },
  } = usePackageStore()
  const { mutate: unsubscribe, isLoading } = useUnsubscribe()
  const [show, setShow] = useState(false)

  const toast = useToast()

  const { t: tPackages } = useTranslation('packages')
  const transitions = useTransition(show, {
    from: { opacity: 0, transform: 'translate3d(100%, 0px, 0px)' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0px, 0px)' },
    leave: {
      opacity: 0,
      position: 'absolute',
      transform: 'translate3d(-100%, 0px, 0px)',
      top: 150,
    },
  })

  useNonInitialEffect(() => {
    if (isOpen === false) {
      setShow(false)
    }
  }, [isOpen])

  useEffect(() => {
    const isUnsubModalOpen = getQueryParam('show_unsubscribe_modal')
    if (isUnsubModalOpen === 'true') {
      onOpen()
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      withCloseButton
      id="unsubscribe-modal"
      contentSx={{ overflow: 'hidden' }}
    >
      <Stack
        justify="center"
        spacing={4}
        sx={{ pos: 'relative', p: '24px 32px 16px' }}
      >
        <SvgImage src={unsubPlan} sx={{ w: '133px', h: '126px', mx: 'auto' }} />

        {transitions((style, item) =>
          item ? (
            <AnimatedStack spacing={4} style={style}>
              <ModalHeader
                sx={{
                  textAlign: 'center',
                  fontSize: '2xl',
                  fontWeight: 600,
                  p: 0,
                }}
              >
                <Trans
                  t={tPackages}
                  i18nKey={'unsubscription.unsubReason.title'}
                >
                  เราเสียใจเป็นอย่างยิ่งที่คุณต้องการ ยกเลิกบริการจากเรา
                </Trans>
              </ModalHeader>
              <Text>{tPackages('unsubscription.unsubReason.description')}</Text>
              <UnsubscribeReasonForm
                isLoading={isLoading}
                onSubmit={(data) => {
                  unsubscribe(
                    { data: { reason: data.reason_input || data.reason } },
                    {
                      onSuccess() {
                        toast({
                          ...TOAST_CONFIG,
                          title: t(
                            'views.features.packages.packages.downgrade.unsubscribe_success_title',
                          ),
                          description: t(
                            'views.features.packages.packages.downgrade.unsubscribe_success_description',
                            {
                              expiry,
                            },
                          ),
                        })
                        onClose()
                      },
                    },
                  )
                }}
              />
            </AnimatedStack>
          ) : (
            <AnimatedStack spacing={6} style={style}>
              <ModalHeader
                sx={{
                  textAlign: 'center',
                  fontSize: '2xl',
                  fontWeight: 600,
                  p: 0,
                }}
              >
                {t('views.features.packages.packages.downgrade.unsubscribe')}
              </ModalHeader>

              <Text>
                <Trans i18nKey="features.packages.cancel.current_plan_expire">
                  Your current plan will&nbsp;
                  <Text
                    as="span"
                    sx={{
                      fontWeight: matchLanguage<number>(700, 600),
                      color: 'red.400',
                    }}
                  >
                    expire on {{ expiry }}
                  </Text>
                  . All of features of your current plan will be available until
                  then.
                </Trans>
              </Text>

              <PackageDetails plan={'free'} />

              <Button
                colorScheme="brand"
                size="xl"
                onClick={() => {
                  setShow(true)
                }}
              >
                {t('views.misc.next')}
              </Button>
            </AnimatedStack>
          ),
        )}

        <Button
          colorScheme="gray"
          onClick={onClose}
          size="xl"
          sx={{ color: 'gray.600' }}
          variant="ghost"
        >
          {t('views.shared.button.discard')}
        </Button>
      </Stack>
    </Modal>
  )
}
