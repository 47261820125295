import { fetchAPI } from '@/lib/api'
import { useMutation } from 'react-query'

const signOut = async () => {
  return fetchAPI({
    method: 'delete',
    path: '/users/sign_out',
  })
}

export const useSignOut = () => {
  return useMutation(signOut, {
    onSuccess: () => {
      window.location.href = '/users/sign_in'
    },
  })
}
