import { useMemo, useCallback, ChangeEvent } from 'react'
import { Column } from 'react-table'
import { Select, Text } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'

import { t } from '@/lib/helpers'

import { DataTable } from '@/components/DataDisplay/Table'

import { Cookie, StoreType } from '@/features/domains/types'
import { SCANNED_COOKIES } from '../../constants'

import { useUpdateCookie } from '../../mutations'
import { useAppContext } from '@/features/layout'

type Props = {
  cookies: Cookie[]
  categories: Category[]
  project: Project
  canUpdateCookie: boolean
}

const CookiesTable = (props: Props) => {
  const { cookies, categories, project, canUpdateCookie } = props
  const { id } = project
  const { platformLanguage } = useAppContext()

  const queryClient = useQueryClient()

  const { mutate: updateCookie } = useUpdateCookie()
  const handleChangeCategory = useCallback(
    (cookieId: number, categoryId: number) => {
      updateCookie(
        {
          params: {
            project_id: id,
            cookie_id: cookieId,
          },
          data: {
            category_id: categoryId,
          },
        },
        {
          async onSuccess() {
            await queryClient.refetchQueries([SCANNED_COOKIES])
          },
        },
      )
    },
    [],
  )

  const handleSortCategory = useCallback(
    (_rowA: { original: Cookie }, _rowB: { original: Cookie }) => {
      const rowA = _rowA?.original
      const rowB = _rowB?.original

      if (
        (!rowA || rowA.category == undefined) &&
        (!rowB || rowB.category == undefined)
      ) {
        return 0
      }

      if (!rowA || rowA.category == undefined) return 1

      if (!rowB || rowB.category == undefined) return -1

      return rowA.category.id - rowB.category.id
    },
    [],
  )

  const columns: Array<Column<Cookie>> = useMemo(() => {
    return [
      {
        Header: t('views.features.domains.show.scanner.name'),
        accessor: 'name',
        sortKey: 'name',
        width: '14%',
      },
      {
        Header: t('views.features.domains.show.scanner.service_provider'),
        accessor: 'provider',
        sortKey: 'provider',
        width: '10%',
      },
      {
        Header: t('views.features.domains.show.scanner.domain_url'),
        accessor: 'url',
        sortKey: 'url',
        width: '26%',
        Cell: ({ value }: any) => (
          <Text
            sx={{
              wordBreak: 'break-all',
            }}
          >
            {value}
          </Text>
        ),
      },
      {
        Header: t('views.features.domains.show.scanner.type'),
        accessor: 'store_type',
        sortKey: 'store_type',
        width: '10%',
        Cell: ({ value }) => {
          return (
            Object.entries(StoreType).find(([_, v]) => v === value)?.[0] ??
            value
          )
        },
      },
      {
        Header: t('views.features.domains.show.scanner.duration'),
        accessor: 'duration',
        sortKey: 'duration',
        width: '10%',
        Cell: ({ value }) => {
          return <Text>{value?.[platformLanguage] ?? '-'}</Text>
        },
      },
      {
        Header: t('views.features.domains.show.scanner.category'),
        sortType: handleSortCategory,
        accessor: 'category_id',
        sortKey: 'category_id',
        width: '20%',
        Cell: (cellProps) => {
          const { value, row } = cellProps

          return (
            <Select
              value={value ?? -1}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                handleChangeCategory(row.original.id, Number(e.target.value))
              }}
              color={value ? 'black' : 'gray.600'}
              isDisabled={!canUpdateCookie}
            >
              <option value={-1} disabled>
                {t('views.features.domains.show.scanner.unassigned')}
              </option>
              {[...categories].map((cat) => {
                return (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                )
              })}
            </Select>
          )
        },
      },
    ]
  }, [canUpdateCookie])

  const memoizedData = useMemo(() => cookies, [cookies])

  return (
    <DataTable<Cookie>
      data={memoizedData}
      columns={columns}
      autoResetPage={false}
      autoResetSortBy={false}
      showPagination
    />
  )
}

export default CookiesTable
