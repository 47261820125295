import { Box, CSSObject } from '@chakra-ui/react'

type Props = {
  src: string
  sx: CSSObject
}

export const SvgImage = ({ src, sx }: Props) => {
  return (
    <Box
      sx={{
        background: `no-repeat center/contain url(${src})`,
        ...sx,
      }}
    />
  )
}
