import { useState, useEffect, useMemo } from 'react'
import { capitalize, get } from 'lodash'
import { Box, Grid, Text, useToast } from '@chakra-ui/react'
import {
  Discount,
  PaymentMethodContainer,
  SelectCreditCard,
  Summary,
} from '@datawowio/payment-ui'

import { withPaymentPage, usePaymentStore } from '@/features/payments/stores'

import { StripeForm } from '@/features/payments/containers'
import { CreditCardList } from '@/features/payments/components'
import { t } from '@/lib/helpers'
import {
  useApplyCoupon,
  useRemoveCoupon,
  useSubscription,
} from '../../mutations'
import { ERROR_TOAST_CONFIG } from '@/lib/constants'

function PaymentPage() {
  const {
    address,
    packageData,
    creditCards,
    cardId,
    token,
    invoice,
    setInvoice,
    recurring,
  } = usePaymentStore()

  const { mutate: subscribe, isLoading } = useSubscription()
  const { mutate: applyCoupon, isLoading: isLoadingApplyCoupon } =
    useApplyCoupon()
  const { mutate: removeCoupon, isLoading: isLoadingRemoveCoupon } =
    useRemoveCoupon()

  const [errors, setErrors] = useState<Dict>({})
  const toast = useToast()

  useEffect(() => {
    if (errors.cardId) {
      toast({
        ...ERROR_TOAST_CONFIG,
        description: errors.cardId as string,
      })
    }
  }, [errors])

  const discounts = useMemo(() => {
    const discounts: Discount[] = []

    if (invoice.coupon) {
      if (invoice.coupon_fixed) {
        discounts.push({
          label: invoice.coupon,
          value: invoice.coupon_discount,
        })
      } else {
        discounts.push({
          label: invoice.coupon,
          value: invoice.coupon_discount,
          onDelete: () => {
            removeCoupon(
              {
                data: {
                  token: invoice.token,
                },
              },
              {
                onSuccess(data) {
                  setInvoice(data)
                },
              },
            )
          },
        })
      }
    }

    return discounts
  }, [invoice])

  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent" sx={{ padding: '48px 40px' }}>
        <Text sx={{ fontSize: '24px', mb: '45px', fontWeight: 500 }}>
          {t('views.features.packages.checkout.summary')}
        </Text>

        <Grid
          sx={{
            gap: '24px',
            gridTemplateColumns: '1fr 440px',
            alignItems: 'start',
          }}
        >
          <PaymentMethodContainer>
            <Box>
              <SelectCreditCard
                renderCreditForm={({ onClose }) => {
                  return <StripeForm onClose={onClose} />
                }}
              >
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'gray.200',
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}
                >
                  <CreditCardList cards={creditCards} />
                </Box>
              </SelectCreditCard>
            </Box>
          </PaymentMethodContainer>

          <Box>
            <Summary
              net={invoice.amount}
              total={Math.max(invoice.total_amount, 0)}
              vat={invoice.vat}
              vatPercent={invoice.vat_percent}
              preVatTotal={invoice.before_vat}
              name={capitalize(packageData.name)}
              linkTermOfUse="https://cookiewow.com/terms"
              linkPrivacy="https://cookiewow.com/privacy"
              address={get(address, 'details')}
              onErrors={(err) => {
                setErrors(err)
              }}
              extraValues={{
                cardId,
              }}
              validate={(values) => {
                const errors: Dict = {}

                if (!values.cardId) {
                  errors.cardId = t(
                    'views.features.packages.checkout.errors.payment_method_required',
                  )
                }

                return errors
              }}
              onSubmit={() => {
                subscribe({
                  data: {
                    token,
                    creditCardId: String(cardId),
                    packageData: {
                      name: packageData.name,
                      price: invoice.subtotal,
                      recurring,
                    },
                  },
                })
              }}
              isLoading={isLoading}
              isFixedCoupon={invoice.coupon_fixed}
              discounts={discounts}
              {...(invoice.prorated_discount && {
                proRateDiscount: {
                  label: 'ส่วนลดมูลค่าคงเหลือแพ็กเกจ',
                  value: invoice.prorated_discount,
                },
              })}
              isLoadingCoupon={isLoadingApplyCoupon || isLoadingRemoveCoupon}
              {...(invoice.coupon_enabled && {
                onUseCoupon: (coupon) => {
                  applyCoupon(
                    {
                      data: {
                        token: invoice.token,
                        coupon,
                      },
                    },
                    {
                      onSuccess(invoice) {
                        setInvoice(invoice)
                      },
                    },
                  )
                },
              })}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default withPaymentPage(PaymentPage)
