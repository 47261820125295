import { BannerLanguage } from '../../../types'
import { Category, CategoryFormInput } from '../../types'

export const transformCategoryIntoForm = (
  cat: Category,
  lang: BannerLanguage,
): CategoryFormInput => {
  return {
    name: cat.name[lang] || '',
    description: cat.description[lang] || '',
    slug: cat.slug || '',
    consent_model: cat.consent_model || 'opt_out',
  }
}
