import { OptionTypeBase, Props } from 'react-select'
import ReactSelectCreatable, { CreatableProps } from 'react-select/creatable'

import { SelectOption } from '../types'
import { useThemeConfig } from '../useThemeConfig'

export const Creatable = <
  OptionType extends OptionTypeBase = SelectOption,
  IsMulti extends boolean = false,
>(
  props: CreatableProps<OptionType, IsMulti> & Props<OptionType, IsMulti>,
) => {
  const { styles: overrideStyles, ...rest } = props
  const { styles, theme } = useThemeConfig<OptionType, IsMulti>()

  return (
    <ReactSelectCreatable<OptionType, IsMulti>
      styles={{ ...styles, ...overrideStyles }}
      theme={theme}
      menuPlacement="auto"
      {...rest}
    />
  )
}
