import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { FOLDERS } from '../constants'
import { TOAST_CONFIG } from '@/lib/constants'
import { EditFolderFormInput } from '../types'

type Payload = {
  data: EditFolderFormInput
  params: {
    folder_id: number
  }
}

const updateFolder = async ({ data, params }: Payload) => {
  const { folder_id } = params

  return await fetchAPI({
    path: `/folders/${folder_id}`,
    method: 'put',
    data,
  })
}

export const useUpdateFolder = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, Payload>(updateFolder, {
    async onSuccess() {
      await queryClient.refetchQueries([FOLDERS])

      toast({
        ...TOAST_CONFIG,
        description: t(
          'views.features.folders.edit_folder_success_description',
        ),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
