import { useRef } from 'react'
import { Button } from '@chakra-ui/button'
import { ModalHeader } from '@chakra-ui/modal'
import { Stack, Text } from '@chakra-ui/layout'
import { Trans } from 'react-i18next'
import * as d3 from 'd3-format'
import { get } from 'lodash'

import { Modal } from '@/components/shared'
import { SvgImage } from '@/components/DataDisplay'
import { PackageDetails } from './shared'

import { usePackageStore } from '@/features/payments/stores'
import { useCurrentUser } from '@/features/users/queries'
import { matchLanguage, t } from '@/lib/helpers'
import { logEvent } from '@/lib/stats'
import { priceConfig } from '../../config'

import downgradeMonth from '@/images/project/payments/downgrade-month.png'
import downgradeYear from '@/images/project/payments/downgrade-year.png'

import { DowngradeModalProps } from './types'
import { Period } from '@/features/types'

type Props = DowngradeModalProps & {
  period: Period
  useForType4?: boolean
}

// Shared with Downgrade Type 4

export const DowngradeType1Modal = (props: Props) => {
  const { disclosure, period = 'month', useForType4 = false } = props
  const { isOpen, onClose } = disclosure

  const initialRef = useRef(null)

  const { plan, onCheckout, expiry } = usePackageStore()
  const { data: user } = useCurrentUser()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      withCloseButton
      id={useForType4 ? 'downgrade-type-4-modal' : 'downgrade-type-1-modal'}
    >
      <Stack justify="center" spacing="16px" sx={{ px: 8, py: 6 }}>
        <SvgImage
          src={period === 'month' ? downgradeMonth : downgradeYear}
          sx={{ w: '138px', h: '127px', mx: 'auto' }}
        />
        <ModalHeader
          sx={{ textAlign: 'center', fontSize: '2xl', fontWeight: 600 }}
        >
          {useForType4 ? (
            <Trans i18nKey={`features.packages.upgrade.subscribe_to_${period}`}>
              Subscribe to&nbsp;
              <Text
                as="span"
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: matchLanguage(700, 600),
                }}
              >
                {{ package: plan }}
              </Text>
              &nbsp;Package&nbsp;
              <br />
              <Text
                as="span"
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: matchLanguage(700, 600),
                }}
              >
                {{
                  price: d3.format(',d')(
                    get(priceConfig, `${plan}.${period}.price`),
                  ),
                }}
                THB.&nbsp;
              </Text>
              <Text
                as="span"
                sx={{
                  fontWeight: matchLanguage(700, 600),
                }}
              >
                per {period}
              </Text>
            </Trans>
          ) : (
            t('views.features.packages.packages.downgrade.continue_downgrade')
          )}
        </ModalHeader>

        <Text>
          {user?.current_package.trialing ? (
            <Trans i18nKey="features.packages.cancel.free_trial_expire">
              You can try the free trial&nbsp;
              <Text
                as="span"
                sx={{
                  fontWeight: matchLanguage<number>(700, 600),
                  color: 'red.400',
                }}
              >
                until {{ expiry }}
              </Text>
              and be able to use the features <br /> that are only available in
              your current package.
            </Trans>
          ) : (
            <Trans i18nKey="features.packages.cancel.current_plan_expire">
              Your current plan will&nbsp;
              <Text
                as="span"
                sx={{
                  fontWeight: matchLanguage<number>(700, 600),
                  color: 'red.400',
                }}
              >
                expire on {{ expiry }}
              </Text>
              . All of features of your current plan will be available until
              then.
            </Trans>
          )}
        </Text>

        {plan && (
          <PackageDetails
            plan={plan}
            trialing={user?.current_package.trialing}
          />
        )}

        <Button
          colorScheme="brand"
          size="xl"
          ref={initialRef}
          onClick={() => {
            logEvent({
              ga: {
                category: 'Upgrade',
                action: 'Click Downgrade',
                ...(plan && {
                  label: plan,
                }),
              },
            })
            onCheckout()
          }}
        >
          {useForType4
            ? t('views.shared.button.subscribe')
            : t('views.shared.button.downgrade')}
        </Button>
        <Button variant="link" size="xl" onClick={onClose}>
          {t('views.shared.button.discard')}
        </Button>
      </Stack>
    </Modal>
  )
}
