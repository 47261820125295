import { Box, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { usePackageStore } from '@/features/payments/stores'
import { t } from '@/lib/helpers'

import {
  DowngradeType1Modal,
  DowngradeType2Modal,
  PriceSwitch,
  PricingTable,
  ResubscribeModal,
  UnsubscribeModal,
  UpgradeFreeModal,
  UpgradeType1Modal,
  UpgradeType2Modal,
} from '../../components'

import { PackageNameInUse, Period } from '@/features/types'

export type PricingTableContainerProps = {
  currentPlan: {
    plan: PackageNameInUse
    period: Period
  }
  hadSubscribed: boolean
}

export const PricingTableContainer = (props: PricingTableContainerProps) => {
  const { currentPlan, hadSubscribed } = props

  const {
    period,
    setPeriod,
    setPlan,
    upgradeFreeDisclosure,
    upgradeType1Disclosure,
    upgradeType2Disclosure,
    downgradeType1Disclosure,
    downgradeType2Disclosure,
    downgradeType31Disclosure,
    downgradeType32Disclosure,
    downgradeType4Disclosure,
  } = usePackageStore()

  const { t: tPackages } = useTranslation('packages')

  return (
    <Box id="pricing-table" sx={{ pt: '48px' }}>
      <Text
        sx={{
          fontSize: '40px',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {t('views.features.packages.packages.pricing.pricing_package')}
      </Text>
      <Text
        sx={{
          fontSize: 'xl',
          textAlign: 'center',
          mt: '12px',
          fontWeight: 'medium',
        }}
      >
        {tPackages('pricingTable.subtitle')}
      </Text>

      <Box sx={{ mt: '24px' }}>
        <PriceSwitch period={period} onChange={setPeriod} />
      </Box>

      <Box sx={{ mt: 10 }}>
        <PricingTable
          period={period}
          currentPlan={currentPlan}
          setPlan={setPlan}
          hadSubscribed={hadSubscribed}
        />
      </Box>

      <UnsubscribeModal />
      <ResubscribeModal />

      <UpgradeFreeModal disclosure={upgradeFreeDisclosure} />
      <UpgradeType1Modal disclosure={upgradeType1Disclosure} />
      <UpgradeType2Modal disclosure={upgradeType2Disclosure} period="year" />

      <DowngradeType1Modal
        disclosure={downgradeType1Disclosure}
        period={period}
      />
      <DowngradeType2Modal disclosure={downgradeType2Disclosure} />
      <UpgradeType2Modal
        disclosure={downgradeType31Disclosure}
        period="month"
      />
      <DowngradeType2Modal
        disclosure={downgradeType32Disclosure}
        useForType32
      />
      <DowngradeType1Modal
        disclosure={downgradeType4Disclosure}
        period="year"
        useForType4
      />
    </Box>
  )
}
