export default {
  all: 'All',
  cancel: 'Cancel',
  change: 'Change',
  close: 'Close',
  confirm: 'Confirm',
  day_unit: 'Day',
  discard: 'Discard',
  discardChanges: 'Discard Changes',
  lastUpdated: 'Last Updated',
  month: '{{count}} month',
  month_plural: '{{count}} months',
  month_unit: 'Month',
  noData: 'No Data',
  refresh: 'Refresh',
  save: 'Save',
  search: 'Search',
  seeMore: 'See More',
  setting: 'Setting',
  since: 'Since',
  times_unit: 'Times',
  total: 'Total',
  unlimited: 'Unlimited',
  year_unit: 'Year',
}
