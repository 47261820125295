import { get, omit } from 'lodash'
import { useQuery, UseQueryOptions } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { ACTIVITY_LOGS } from '../constants'

import { ActivityLog, ActivityLogMeta } from '../types'
import { AxiosError } from 'axios'

type ActivityResponse = {
  data: ActivityLog[]
  meta: ActivityLogMeta
}

const getActivityLogs = async (
  meta: ActivityLogMeta,
): Promise<ActivityResponse> => {
  const response = await fetchAPI({
    path: '/activity_logs',
    params: meta,
  })

  return {
    data: get(response, 'data.data', []),
    meta: get(response, 'data.meta', {}),
  }
}

type UseActivityLogsProps = {
  meta: ActivityLogMeta
} & UseQueryOptions

export const useActivityLogs = (props: UseActivityLogsProps) => {
  const { meta } = props

  const newMeta = omit(meta, 'total')

  return useQuery<ActivityResponse, AxiosError>({
    queryKey: [ACTIVITY_LOGS, newMeta],
    queryFn: () => getActivityLogs(newMeta),
    keepPreviousData: true,
  })
}
