import { useStripe } from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/toast'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import { fetchAPI } from '@/lib/api'
import { isAxiosError, getErrorMsg } from '@/lib/helpers'
import { logEvent, logEcommerce } from '@/lib/stats'
import {
  useCreateCreditCard,
  useSetupIntent,
} from '@/features/payments/mutations'

import { FreeTrialFormInput, FreeTrialSubscriptionResult } from '../types'
import { updatedPriceConfig } from '../../payments/config'

import { ERROR_TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  data: {
    form: FreeTrialFormInput
    number: StripeCardNumberElement | null
    expiry: StripeCardExpiryElement | null
    cvc: StripeCardCvcElement | null
  }
}

export const useSubmitFreeTrial = () => {
  const stripe = useStripe()

  const toast = useToast()

  const setupIntentMutation = useSetupIntent()
  const createCreditCardMutation = useCreateCreditCard()

  const { t } = useTranslation('payment')

  return useMutation(
    async ({ data }: Payload) => {
      const { form, number, expiry, cvc } = data

      if (!stripe || !number || !expiry || !cvc) {
        throw new Error(t('toast.failed'))
      }

      const clientSecret = await setupIntentMutation.mutateAsync(null)

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: number,
          billing_details: {
            name: form.name,
          },
        },
      })

      if (result.error) {
        throw new Error(result.error.message)
      }

      if (!result.setupIntent.payment_method) {
        throw new Error(t('toast.paymentFailed'))
      }

      await createCreditCardMutation.mutateAsync({
        data: {
          payment_method_id: result.setupIntent.payment_method,
          default_card: true,
        },
      })

      return await fetchAPI<FreeTrialSubscriptionResult>({
        method: 'post',
        path: '/packages/free_trial',
        data: {
          details: form.address,
          name: form.name,
          package: form.package,
          payment_method_id: result.setupIntent.payment_method,
          recurring: form.recurring,
          taxpayer_id: form.tax_id,
          taxpayer_type: form.taxpayer_type,
          code: form.code,
        },
      })
    },
    {
      onSuccess(result, payload) {
        const { form } = payload.data

        const predictedLTV =
          form.recurring === 'year'
            ? updatedPriceConfig[form.package].price.year
            : updatedPriceConfig[form.package].price.month * 12

        const value = updatedPriceConfig[form.package].price[form.recurring]
        logEvent({
          ga: {
            category: 'Free Trial Success',
            action: `${form.package} ${form.recurring}`,
            label: `${value}`,
            value,
          },
          fb: {
            event: 'StartTrial',
            value: '0',
            currency: 'THB',
            predicted_ltv: `${predictedLTV}.00`,
          },
        })

        logEcommerce('purchase', {
          value,
          items: [
            {
              item_id: `${form.package}_${form.recurring}`,
              item_name: `${form.package}_${form.recurring}`,
              price: value,
              quantity: 1,
            },
          ],
        })

        window.location.href = `/payments/${result.data.invoice.token}/success?type=free-trial&package=${result.data.package}`
      },
      onError(error: Error | AxiosError) {
        if (isAxiosError(error)) {
          toast({
            ...ERROR_TOAST_CONFIG,
            description: getErrorMsg(error),
          })
        } else {
          toast({
            ...ERROR_TOAST_CONFIG,
            description: error.message,
          })
        }
      },
    },
  )
}
