import { Input } from '@chakra-ui/input'
import { Text, Stack, Box, Grid, HStack, Flex, Link } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Visa from '@/images/free-trial/brands/visa.png'
import Master from '@/images/free-trial/brands/master.png'
import JCB from '@/images/free-trial/brands/jcb.png'
import Amex from '@/images/free-trial/brands/amex.png'

import { CARD_ELEMENT_STYLE } from '../constants'
import { StripeElementErrors } from '../types'
import { linkStyle } from '@/features/auth/constants/styles'

type CreditCardFormProps = {
  errors: StripeElementErrors
}

export const CreditCardForm = (
  props: CreditCardFormProps,
): React.ReactElement => {
  const { errors } = props

  const { register } = useFormContext()

  const { t } = useTranslation(['freeTrial'])

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <HStack justifyContent="space-between">
          <Flex gap={4}>
            <Text>{t('form.creditDebit')}</Text>
            <HStack>
              {[Visa, Master, Amex].map((logo, idx) => (
                <Image src={logo} key={idx} sx={{ h: '24px' }} />
              ))}
            </HStack>
          </Flex>
          <Link
            href={t('form.helpdeskLink')}
            isExternal
            sx={{ ...linkStyle, fontSize: 'xs' }}
          >
            {t('form.howToPay')}
          </Link>
        </HStack>
        <Text sx={{ fontSize: 'xs' }}>{t('form.changeCard')}</Text>
      </Stack>
      <Grid sx={{ gridTemplateColumns: 'repeat(6, 1fr)', gap: '8px' }}>
        <Input
          isRequired
          placeholder={t('form.cardHolderNamePlaceholder')}
          size="lg"
          sx={{ gridColumn: '1/-1' }}
          {...register('card_name')}
        />
        <Box sx={{ gridColumn: '1/5' }}>
          <CardNumberElement
            options={CARD_ELEMENT_STYLE}
            className="card_number"
          />
          {errors.card_number && (
            <Text textStyle="formError" mt="2" fontSize="sm">
              {errors.card_number}
            </Text>
          )}
        </Box>
        <Box>
          <CardExpiryElement
            options={CARD_ELEMENT_STYLE}
            className="card_expiry"
          />
          {errors.card_expiry && (
            <Text textStyle="formError" mt="2" fontSize="sm">
              {errors.card_expiry}
            </Text>
          )}
        </Box>
        <Box>
          <CardCvcElement options={CARD_ELEMENT_STYLE} className="card_cvc" />
          {errors.card_cvc && (
            <Text textStyle="formError" mt="2" fontSize="sm">
              {errors.card_cvc}
            </Text>
          )}
        </Box>
      </Grid>
    </Stack>
  )
}
