import {
  Button,
  ModalBody,
  ModalHeader,
  Stack,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { Trans } from 'react-i18next'

import { Modal } from '@/components/shared'
import { SvgImage } from '@/components/DataDisplay'
import selectDomain from '@/images/project/payments/select-domain.png'

import { t } from '@/lib/helpers'
import { useQuotaData } from '@/features/users/queries'
import { useAppContext } from '@/features/layout'

type Props = {
  disclosure: UseDisclosureReturn
  onSubmitDelete: () => void
  isLoading: boolean
}

export const ConfirmSelectDomainsModal = (props: Props) => {
  const { disclosure, onSubmitDelete, isLoading } = props
  const { isOpen, onClose } = disclosure

  const { currentPackage } = useAppContext()
  const quota = useQuotaData()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxW="524px"
      withCloseButton={false}
    >
      <Stack justify="center" spacing="16px" sx={{ pt: 4 }}>
        <SvgImage
          src={selectDomain}
          sx={{ w: '128px', h: '114px', mx: 'auto' }}
        />
        <ModalHeader
          sx={{
            textAlign: 'center',
            fontSize: '2xl',
            pb: 0,
          }}
        >
          {t('views.features.migration.index.confirm_choose_domains')}
        </ModalHeader>
        <ModalBody>
          <Stack spacing="24px">
            <Text>
              <Trans i18nKey="features.migration.choose_domains">
                ปัจจุบันคุณใช้&nbsp;
                <Text
                  as="span"
                  sx={{ textTransform: 'capitalize', fontWeight: 600 }}
                >
                  {{ name: currentPackage.name }}
                </Text>
                &nbsp;สามารถใช้งานได้สูงสุด&nbsp;
                <Text as="span" sx={{ fontWeight: 600 }}>
                  {{
                    limit:
                      quota?.domain.limit ||
                      t('views.features.packages.packages.unlimited'),
                  }}
                </Text>
                &nbsp;โดเมน
              </Trans>
            </Text>

            <Text>
              <Text as="span" sx={{ color: 'red.300' }}>
                <Text as="span" sx={{ fontWeight: 600 }}>
                  {t('views.misc.warning')}:&nbsp;
                </Text>
                {t('views.features.migration.index.choose_domains_caution')}
              </Text>
              <br />
              {t('views.features.domains.index.if_ready_please_confirm')}
            </Text>

            <Stack>
              <Button
                size="xl"
                onClick={() => {
                  onSubmitDelete()
                }}
                isLoading={isLoading}
              >
                {t('views.features.domains.index.confirm')}
              </Button>
              <Button
                variant="ghost"
                size="xl"
                onClick={onClose}
                colorScheme="gray"
                isDisabled={isLoading}
              >
                {t('views.shared.button.discard')}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </Stack>
    </Modal>
  )
}
