import { useMemo } from 'react'
import {
  Text,
  AspectRatio,
  HStack,
  Stack,
  Flex,
  Divider,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { PieChart } from 'react-minimal-pie-chart'

import { ConsentDashboardCard } from '../ConsentDashboardCard'
import { ColorItem } from '../ColorItem'

import { StatusStatItem } from '../../types'
import { byStatusColor } from '../../constants'
import { EmptyPie } from '../EmptyPie'

export type ByStatusNoticeOnlyProps = {
  data: StatusStatItem[]
}

export const ByStatusNoticeOnly = (props: ByStatusNoticeOnlyProps) => {
  const { data } = props

  const { t } = useTranslation('domainConsent')
  const { t: tCommon } = useTranslation('common')

  const pieData = useMemo(
    () =>
      data
        .filter(
          (data) =>
            data.consent_action === 'dismissed_all' ||
            data.consent_action === 'informed_all',
        )
        .map((entry) => {
          const color = byStatusColor[entry.consent_action]

          const title = t(`action.${entry.consent_action}`)

          return {
            ...entry,
            color,
            value: entry.count,
            title,
          }
        }),
    [data],
  )

  const total = useMemo(
    () => data.reduce((sum, cur) => sum + cur.count, 0),
    [data],
  )

  return (
    <ConsentDashboardCard
      title={t('byStatus')}
      tooltip={t('byStatusTooltip')}
      sx={{ w: '50%' }}
      id="by-status-notice-only-card"
      bottomSection={null}
    >
      <HStack spacing="10">
        <AspectRatio
          ratio={1 / 1}
          sx={{ w: '362px', alignSelf: 'start', maxH: '362px' }}
        >
          {total ? (
            <PieChart
              data={pieData}
              labelPosition={109}
              lineWidth={22}
              radius={32}
              startAngle={90}
              label={({ dataEntry }) => {
                const percent = (dataEntry.value / total) * 100

                return percent < 5 ? null : percent.toFixed(2) + '%'
              }}
              labelStyle={{
                fontSize: '4px',
              }}
              animate
            />
          ) : (
            <EmptyPie />
          )}
        </AspectRatio>

        <Stack
          sx={{
            fontSize: 'sm',
            alignSelf: 'end',
            minW: '252px',
          }}
        >
          <Flex
            sx={{
              fontWeight: 'bold',
              justifyContent: 'space-between',
              fontSize: 'md',
            }}
          >
            <Text>{tCommon('total')}</Text>
            <Text>{total}</Text>
          </Flex>

          <Divider />

          {pieData.map((datum) => {
            const title = t(`action.${datum.consent_action}`)

            return (
              <ColorItem
                color={datum.color}
                key={title}
                text={title}
                value={datum.value}
              />
            )
          })}
        </Stack>
      </HStack>
    </ConsentDashboardCard>
  )
}
