export const DEFAULT_CATEGORY_CONTENTS: Record<
  BannerLanguage,
  Record<string, string>
> = {
  en: {
    Necessary:
      'Necessary cookies are required to help a website usable by enabling core functions and access to secure areas of the website. The website cannot be function properly without these cookies and they are enabled by default and cannot be disabled.',
    Analytics:
      'Analytics cookies help website to understand how visitors interact through the website. These cookies help to improve user experiences by collecting and reporting information.',
    Marketing:
      'Marketing cookies are used to track visitors across websites to display relevant advertisements for the individual user and thereby more valuable for publishers and third party advertisers.',
  },
  th: {
    Necessary:
      'คุกกี้พื้นฐานที่จำเป็น เพื่อช่วยให้การทำงานหลักของเว็บไซต์ใช้งานได้ รวมถึงการเข้าถึงพื้นที่ที่ปลอดภัยต่าง ๆ ของเว็บไซต์ หากไม่มีคุกกี้นี้เว็บไซต์จะไม่สามารถทำงานได้อย่างเหมาะสม และจะใช้งานได้โดยการตั้งค่าเริ่มต้น โดยไม่สามารถปิดการใช้งานได้',
    Analytics:
      'คุกกี้ในส่วนวิเคราะห์ จะช่วยให้เว็บไซต์เข้าใจรูปแบบการใช้งานของผู้เข้าชมและจะช่วยปรับปรุงประสบการณ์การใช้งาน โดยการเก็บรวบรวมข้อมูลและรายงานผลการใช้งานของผู้ใช้งาน',
    Marketing:
      'คุกกี้ในส่วนการตลาด ใช้เพื่อติดตามพฤติกรรมผู้เข้าชมเว็บไซต์เพื่อแสดงโฆษณาที่เหมาะสมสำหรับผู้ใช้งานแต่ละรายและเพื่อเพิ่มประสิทธิผลการโฆษณาสำหรับผู้เผยแพร่และผู้โฆษณาสำหรับบุคคลที่สาม',
  },
  zh: {
    Necessary:
      '网页核心功能和访问网站安全相关的Cookie。 如果没有这些Cookie网站将无法正常运行，它们默认启用且无法禁用。',
    Analytics:
      '访问分析Cookie将帮助网站了解用户如何通过网站进行互动。这些Cookie通过收集和报告信息来帮助改善用户体验。',
    Marketing:
      '市场分析Cookie通过分析用户数据像来显示与个人用户相关的广告，从而取得更大效果的广告收益。',
  },
  ja: {
    Necessary:
      'サイトの安全な領域へのアクセスを有効にすることなど、サイトの運用に技術的に必要なCookieです。このタイプのCookieはデフォルトで有効になっていて、無効にすることはできません。',
    Analytics:
      'トラフィック分析Cookieは訪問者がサイトを使用するときにサイト内をどのように移動するかを確認します。訪問者の情報を分析することで、ブラウジング体験を向上させることができます。',
    Marketing:
      'マーケティング分析Cookieは他のサイトのアクセスデータに基づいて、ユーザーに関連する広告を表示するために使用されます。広告主の収益性を向上させることができます。',
  },
}

export const NECESSARY_CATEGORY_NAME = {
  en: 'Necessary',
  th: 'คุกกี้พื้นฐานที่จำเป็น',
  zh: '必要Cookie',
  ja: '必要なCookie',
}

export const CONSENT_MODEL = ['opt_in', 'opt_out', 'notice_only'] as const

export const DEFAULT_CATEGORY_SLUG = [
  'necessary',
  'analytics',
  'marketing',
] as const
