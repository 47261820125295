import { Pagination } from '@/components/Input'
import { useFilterContext } from '../context'

type Props = {
  totalPage: number
}

export const PaginationFilter = (props: Props) => {
  const { meta, setFilter } = useFilterContext()

  const page = meta.page || 1
  const totalPage = props.totalPage

  const handleChangePage = (page: number) => {
    setFilter('page', page)
  }

  return (
    <Pagination
      page={page}
      totalPage={totalPage}
      onChangePage={handleChangePage}
    />
  )
}
