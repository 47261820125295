import { Box, Flex, Grid, Text, VStack, Image, Center } from '@chakra-ui/react'
import { startCase } from 'lodash'

import {
  ConsentRate,
  ByStatus,
  ByCategory,
  ConsentOverview,
  ByStatusNoticeOnly,
  LoadingOverlay,
} from '../components'

import { useProjectData } from '../../queries'
import { useDashboardStats } from '../queries'

import { FilterPeriod } from '../types'
import { formatDateTime } from '@/lib/helpers'
import { useMemoDisplayDashboard } from '@/lib/hooks'
import { useTranslation } from 'react-i18next'

import logo from '@/images/icons/maintenance.svg'

type ChartSectionProps = {
  filterPeriod: FilterPeriod
}

export const ChartSection = (props: ChartSectionProps) => {
  const { filterPeriod } = props

  const project = useProjectData()

  const isDisplayDashboard = useMemoDisplayDashboard()
  const { data: stats, isLoading } = useDashboardStats({
    enabled: isDisplayDashboard,
    date_range: filterPeriod,
  })

  const { t: tCommon } = useTranslation('common')
  const { t: tDomains } = useTranslation('domains')

  return (
    <Box sx={{ pos: 'relative' }} id="dashboard-chart-section">
      <Flex
        sx={{
          justifyContent: 'space-between',
          fontSize: 'sm',
          color: 'gray.600',
          fontWeight: 'semibold',
        }}
      >
        <Text>
          {tDomains('bannerSettings.menus.bannerModel')}:&nbsp;
          {startCase(project?.current_published_banner_model)}
        </Text>
        <Text>
          {tCommon('since')}: {formatDateTime(stats?.meta.since[filterPeriod])}
        </Text>
      </Flex>

      {project?.current_published_banner_model === 'default' ? (
        <Grid
          sx={{
            gap: '36px 16px',
            gridTemplateColumns: 'repeat(3, 220px) 1fr',
            gridTemplateRows: '402px',
            mt: 2,
          }}
        >
          <ConsentRate
            sessions={stats?.data.consent_rate.sessions_count ?? 0}
            consents={stats?.data.consent_rate.accepted_all_count ?? 0}
            rate={stats?.data.consent_rate.accepted_all_rate ?? 0}
          />
          <ByStatus data={stats?.data.consent_action_counts ?? []} />
          <ByCategory data={stats?.data.category_counts ?? []} />

          <Box sx={{ gridColumn: '-2/-1' }}>
            <ConsentOverview
              data={stats?.data.consent_overview[filterPeriod] ?? []}
              isThisMonth={false}
            />
          </Box>
        </Grid>
      ) : (
        <Box
          sx={{
            gap: '36px 16px',
            gridTemplateColumns: 'repeat(3, 220px) 1fr',
            mt: 2,
          }}
        >
          <ByStatusNoticeOnly data={stats?.data.consent_action_counts ?? []} />
        </Box>
      )}

      {isLoading && <LoadingOverlay />}
    </Box>
  )
}

export const MaintenanceNotice = () => {
  const { t: tDomainConsent } = useTranslation('domainConsent')

  return (
    <Center
      sx={{
        pos: 'absolute',
        boxSize: 'full',
        top: 0,
        left: 0,
        bg: '#88888840',
        cursor: 'not-allowed',
        zIndex: 'overlay',
      }}
    >
      <Box sx={{ p: 6, borderRadius: '8px', bg: 'white' }}>
        <VStack>
          <Image src={logo} alt="" width="140px" height="140px" />
          <Text sx={{ fontWeight: 'bold', fontSize: '32px', px: 10 }}>
            {tDomainConsent('maintenance.notice.title')}
          </Text>
          <Text sx={{ fontWeight: 'light', px: 10 }}>
            {tDomainConsent('maintenance.notice.description')}
          </Text>
          <Text sx={{ color: 'brand.500' }}>Cookie Wow Team</Text>
        </VStack>
      </Box>
    </Center>
  )
}
