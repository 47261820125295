export default {
  packageBanner: {
    freePlanDetail:
      'You can continue to enjoy your current package until further notice',
    domains: 'Domains',
    pageviews: 'Pageviews',
    perAccount: 'Per Account',
    perMonth: 'Per Month',
    yourCurrentPackage: 'Your Current Package',
    cancelAtPeriodEndText:
      'You have unsubscribed this package, it will valid until <1>{{expiry}}</1>',
    resetOn: 'Reset on',
  },
  pricingTable: {
    subtitle:
      'Pay annually and save up to 20% of the plan price. (VAT 7% included)',
    smallTryFree: 'Free 14-Day Trial',
    mediumTryFree: 'Free 14-Day Trial',
    largeTryFree: 'Free 7-Day Trial',
    numberOfPageview: 'Total pageviews per month',
    numberOfPageviewTooltip:
      'Number of times the website was visited will be counted every time someone enters, whether that person has ever entered or not.',
    numberOfDomain: 'Number of domains',
    numberOfDomainTooltip:
      'Number of domains that require installing a cookie banner. The sub-domain will also be counted as a domain.',
    numberOfUser: 'Number of users',
    numberOfUserTooltip:
      'Number of users who can manage a cookie banner. Each user can approach the different domains.',
    numberOfWebsiteScan: 'Website Scan',
    numberOfWebsiteScanUnit: '(URL/account/day)',
    numberOfWebsiteScanTooltip:
      'Number of URL pages scanned per account per day.',
    numberOfConsent: 'Maximum consent records',
    numberOfConsentTooltip:
      'Maximum amount of consent records that can be stored on your account. When the number of consent records is close to the maximum, the oldest records will be removed but still be stored in our system.',
    retentionPeriod: 'Retention Period',
    retentionPeriodTooltip:
      'How long the consent records will be kept in your account. The expired record will be replaced with the new ones, but still, be stored in our system.',
    autoScriptBlock: 'Automatic script blocking',
    autoScriptBlockTooltip:
      'Cookie Wow automatically blocks cookies until receiving consent from a site visitor.',
    consentLogs: 'Consent record',
    consentLogsTooltip:
      'Consents will be recorded every time website visitors give permission in any status.',
    advancedCustomize: 'Advanced cookie banner customization',
    advancedCustomizeTooltip:
      'Advanced cookie banner customization with CSS Code to customize a cookie banner in details.',
    brandingCustomize: 'Logo customization',
    brandingCustomizeTooltip:
      'Customize the logo to match your brand identity.',
    unlimitedDomains: 'Unlimited Domains',
    unlimitedUsers: 'Unlimited Users',
    unlimitedConsents: 'Unlimited Consents',
    consent: 'consents',
    free: {
      description:
        'For small businesses or small in-house teams. Up to 30,000 pageviews per month.',
    },
    small: {
      description:
        'For <strong>SMEs</strong> and growing companies. Can manage up to 10 domains with advanced banner customization.',
    },
    medium: {
      description:
        'For medium to <strong>large enterprises</strong>. Can add up to 10 users and 200 domains with unlimited consents.',
    },
    large: {
      description:
        'For <strong>large enterprises</strong> or agencies with unlimited features - domain, user and consent.',
    },
    enterprise: {
      description:
        'For <strong>large enterprises or agencies</strong> that need more customization.',
      bullet_0: 'Dedicated infrastructure',
      bullet_1: 'Dedicated Solution Engineer',
      bullet_2: 'Training Course for Users',
      bullet_3: 'Monitoring API',
      contactUs: 'Contact Us',
    },
    enterpriseText:
      'Setup <1>Cookie Wow</1><br />on-cloud or on-premise<br />Contact us <5>tel. 02-024-5560</5>',
  },
  monthlyBilling: 'Monthly Billing',
  yearlyBilling: 'Yearly Billing',
  unsubscription: {
    unsubReason: {
      title: "We're sad to see you go",
      description:
        'Please take a moment to tell us why you no longer want to subscribe.',
    },
  },
  schedulePackage:
    'You already have a package in progress.<br/>Please contact our support.',
  manualPaymentPackage:
    'Cannot perform this action on manual payment package, please contact our support.',
}
