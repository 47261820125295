import { Fragment } from 'react'
import { Divider, Flex, Link, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { Icon, keyframes, Tooltip } from '@chakra-ui/react'
import { FiDownload, FiRefreshCw, FiExternalLink } from 'react-icons/fi'

import { PollingStatus } from '@/features/types'
import { useCheckConsentCsvStatus } from '../../mutations'
import { formatDateTime, t } from '@/lib/helpers'

type Props = {
  project: Project
  downloadStatus: PollingStatus
}

export const ConsentCsvDownload = (props: Props) => {
  const { project, downloadStatus } = props
  const { consents_csv_url, id, consents_csv_generated_at } = project

  const { checkConsentCsvStatus, isLoading } = useCheckConsentCsvStatus({
    projectId: id,
    startOnMount: ['processing', 'queued'].includes(downloadStatus),
  })

  if (isLoading) {
    return (
      <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
        <Text
          sx={{ textDecor: 'underline', color: 'gray.300', fontSize: '14px' }}
        >
          <Icon
            as={FiRefreshCw}
            sx={{ mr: '8px' }}
            boxSize="16px"
            animation={`${spin} infinite 2s linear`}
          />
          {t('views.features.domains.show.consent.download_process')}
        </Text>
      </Flex>
    )
  }

  return (
    <Flex
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {consents_csv_url && (
        <Fragment>
          <Tooltip
            label={`${t(
              'views.features.domains.show.consent.last_exported_at',
            )}: ${formatDateTime(consents_csv_generated_at)}`}
            aria-label={`last-update at ${formatDateTime(
              consents_csv_generated_at,
            )}`}
            hasArrow
            placement="top"
          >
            <Link
              colorScheme="green"
              href={consents_csv_url}
              isExternal
              sx={{
                d: 'inline-flex',
                color: 'green.400',
                alignItems: 'center',
                _hover: {
                  textDecor: 'underline',
                },
              }}
            >
              <Icon as={FiDownload} sx={{ mr: '8px' }} />
              {t('views.features.domains.show.consent.download_button')}
            </Link>
          </Tooltip>

          <Divider orientation="vertical" sx={{ h: '20px', mx: '8px' }} />
        </Fragment>
      )}

      <Button
        variant="link"
        colorScheme="brand"
        leftIcon={<Icon as={FiExternalLink} />}
        onClick={() => {
          checkConsentCsvStatus({ projectId: id })
        }}
        sx={{ textTransform: 'capitalize' }}
      >
        {t('views.features.domains.show.consent.export_button')}
      </Button>
    </Flex>
  )
}

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`
