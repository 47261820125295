import { useState, useMemo } from 'react'
import {
  Button,
  HStack,
  ModalBody,
  ModalHeader,
  Stack,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'

import { SCAN_TIME } from './constants'
import { useUpdateScanInterval } from '@/features/domains/mutations'

import { Modal } from '@/components/shared'
import { Select } from '@/components/Select'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureReturn
  projectId: number
  defaultTimeScan: number
}

export const ScanTimeModal = (props: Props) => {
  const { disclosure, projectId, defaultTimeScan } = props
  const { isOpen, onClose } = disclosure
  const params = { project_id: projectId }

  const [currentScanTime, setCurrentScanTime] =
    useState<number>(defaultTimeScan)
  const { mutateAsync: updateScanInterval, isLoading } = useUpdateScanInterval()

  const timeOptions = SCAN_TIME.map((time) => {
    switch (time) {
      case null:
        return {
          label: t('views.features.domains.show.scanner.no_time_set'),
          value: time,
        }
      // currently not avaliable
      // case 1:
      //   return {
      //     label: t('views.features.domains.show.scanner.select_time_scan.day', {
      //       day: time,
      //     }),
      //     value: time,
      //   }
      default:
        return {
          label: t(
            'views.features.domains.show.scanner.select_time_scan.days',
            { day: time },
          ),
          value: time,
        }
    }
  })

  const currentSelect = useMemo(() => {
    return timeOptions.find((item) => item.value === currentScanTime)
  }, [currentScanTime])

  const onCloseModal = () => {
    onClose()
    setCurrentScanTime(defaultTimeScan)
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} maxW="480px" withCloseButton>
      <ModalHeader
        sx={{ p: '32px', pb: '24px', fontSize: '24px', fontWeight: 600 }}
      >
        {t('views.features.domains.show.scanner.scan_time_set')}
      </ModalHeader>
      <ModalBody sx={{ px: '32px', py: 0, pb: '8px' }}>
        <HStack spacing="24px" sx={{ '> div': { flex: 1 } }}>
          <Text sx={{ fontWeight: 500 }}>
            {t('views.features.domains.show.scanner.scan_time_modal.auto_scan')}
          </Text>
          <Select
            isSearchable={false}
            value={currentSelect}
            options={timeOptions}
            onChange={(option) => {
              setCurrentScanTime(option?.value as number)
            }}
          />
        </HStack>

        <Text
          sx={{ fontSize: '14px', fontWeight: 400, color: 'gray.600', mt: 4 }}
        >
          {t('views.features.domains.show.scanner.scan_time_modal.description')}
        </Text>

        <Stack spacing={2} mt={6}>
          <Button
            size="xl"
            sx={{ h: '56px' }}
            isLoading={isLoading}
            onClick={async () => {
              await updateScanInterval(
                { intervalNumber: currentScanTime, params },
                {
                  onSuccess() {
                    onClose()
                  },
                },
              )
            }}
          >
            {t('views.features.domains.show.scanner.scan_time_modal.confirm')}
          </Button>
          <Button
            size="xl"
            variant="ghost"
            colorScheme="gray"
            sx={{ h: '56px' }}
            onClick={onCloseModal}
          >
            {t('views.features.domains.show.scanner.scan_time_modal.cancel')}
          </Button>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
