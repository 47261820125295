export const Input = {
  sizes: {
    lg: {
      field: {
        fontSize: '16px',
      },
    },
  },
  variants: {
    outline: {
      field: {
        bg: 'white',

        _disabled: {
          bg: 'gray.300',
        },
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'brand.300',
  },
}
