import { AxiosError, AxiosResponse } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'
import { CURRENT_USER } from '@/features/users/constants'

export const useCancelUpdateEmail = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const { t: tSettings } = useTranslation('settings')

  return useMutation<AxiosResponse, AxiosError, void>(
    () => {
      return fetchAPI({
        path: '/settings/email/cancel',
        method: 'put',
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(CURRENT_USER)

        toast({
          ...TOAST_CONFIG,
          description: tSettings('changeEmailPending.toast.cancelSuccess'),
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}
