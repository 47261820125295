import th from '@/icons/flags/th.svg'
import en from '@/icons/flags/en.svg'
import zh from '@/icons/flags/zh.svg'
import ja from '@/icons/flags/ja.svg'

import { LanguageOption } from './types'

export const languageOptions: Omit<LanguageOption, 'shortLabel'>[] = [
  {
    icon: en,
    label: 'English',
    value: 'en',
    isPaid: false,
  },
  {
    icon: th,
    label: 'ภาษาไทย',
    value: 'th',
    isPaid: false,
  },
  {
    icon: zh,
    label: '中文',
    value: 'zh',
    isPaid: true,
  },
  {
    icon: ja,
    label: '日本語',
    value: 'ja',
    isPaid: true,
  },
]

export const LANGUAGE_CONSTANT: {
  [K in BannerLanguage]: LanguageOption
} = {
  en: {
    icon: en,
    shortLabel: 'EN',
    label: 'English',
    value: 'en',
    isPaid: false,
  },
  th: {
    icon: th,
    shortLabel: 'TH',
    label: 'ภาษาไทย',
    value: 'th',
    isPaid: false,
  },
  zh: {
    icon: zh,
    shortLabel: 'ZH',
    label: '中文',
    value: 'zh',
    isPaid: true,
  },
  ja: {
    icon: ja,
    shortLabel: 'JA',
    label: '日本語',
    value: 'ja',
    isPaid: true,
  },
} as const

export const LANGUAGE_OPTIONS = Object.values(LANGUAGE_CONSTANT)
