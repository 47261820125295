import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg } from '@/lib/helpers'

type Params = {
  locale: PlatformLanguage
}

const updateLanguage = async (params: Params) => {
  await fetchAPI({
    path: `/locales`,
    params: params,
  })
}

export const useUpdateLanguage = () => {
  const toast = useToast()

  return useMutation(updateLanguage, {
    onSuccess: () => {
      window.location.reload()
    },
    onError: (error) => {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
