import { ChangeEvent, forwardRef } from 'react'
import { Box, Text, Center } from '@chakra-ui/react'

import { t } from '@/lib/helpers'
import { Theme } from '@/features/domains/types'

type Props = {
  radioValue: Theme
  value: Theme
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

// used as controlled component
export const ThemeRadioButton = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { radioValue, onChange, value } = props

    const isChecked = value === radioValue

    return (
      <Box
        as="label"
        sx={{
          p: '8px',
          pb: '19px',
          border: '1px solid',
          borderColor: isChecked ? 'brand.400' : 'gray.300',
          borderRadius: '4px',
          cursor: 'pointer',
          userSelect: 'none',
          position: 'relative',
          transition: 'border-color ease 0.2s',
        }}
      >
        <input
          checked={isChecked}
          name="theme"
          onChange={onChange}
          ref={ref}
          style={radioStyle}
          type="radio"
          value={radioValue}
        />

        <Center
          sx={{
            backgroundColor: radioValue === 'light' ? 'gray.100' : 'gray.900',
            h: '106px',
            border: '1px solid',
            borderColor: 'gray.300',
            mb: '16px',
          }}
        ></Center>
        <Text
          sx={{
            textAlign: 'center',
            transition: 'font-weight ease 0.2s',

            ...(isChecked && { fontWeight: 600 }),
          }}
        >
          {radioValue === 'light'
            ? t(
                'views.features.domains.show.customize.light_theme',
                undefined,
                'Light Theme',
              )
            : t(
                'views.features.domains.show.customize.dark_theme',
                undefined,
                'Dark Theme',
              )}
        </Text>
      </Box>
    )
  },
)

const radioStyle = {
  border: '0px',
  clip: 'rect(0px, 0px, 0px, 0px)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0px',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
} as const
