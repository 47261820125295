import { Button, Icon, keyframes, Tooltip, useToast } from '@chakra-ui/react'
import { Fragment } from 'react'
import { FiDownload, FiRefreshCw } from 'react-icons/fi'

import { ERROR_TOAST_CONFIG } from '@/lib/constants'
import { PollingStatus } from '@/features/types'
import { formatDateTime, t } from '@/lib/helpers'
import { useCheckPageViewCsvStatus } from '../../mutations/usePollingPageViewCsv'
import { useFilterContext } from '@/features/filters/context'

type PageViewCsvDownloadProps = {
  downloadStatus: PollingStatus
  lastExportedAt: Date
}

function PageViewCsvDownload(props: PageViewCsvDownloadProps) {
  const { downloadStatus, lastExportedAt } = props

  const { checkPageViewCsvStatus, isLoading } = useCheckPageViewCsvStatus({
    startOnMount: ['processing', 'queued'].includes(downloadStatus),
  })

  const toast = useToast()

  const { meta } = useFilterContext()

  const buttonIcon = isLoading ? (
    <Icon
      as={FiRefreshCw}
      sx={{ mr: '8px' }}
      animation={`${spin} infinite 2s linear`}
    />
  ) : (
    <Icon as={FiDownload} />
  )

  const onClickDownload = () => {
    const startDate = meta.start_date?.toString()
    const endDate = meta.end_date?.toString()

    if (startDate && endDate && !!meta.url?.length) {
      checkPageViewCsvStatus({
        domain: meta.url?.filter((u) => u) || [],
        startDate: startDate,
        endDate: endDate,
      })
    } else {
      toast({
        ...ERROR_TOAST_CONFIG,
        description: t('views.features.packages.page_views.empty_condition'),
      })
    }
  }

  return (
    <Fragment>
      <Tooltip
        label={`${t(
          'views.features.packages.page_views.last_export',
        )} ${formatDateTime(lastExportedAt)}`}
      >
        <Button
          size="lg"
          variant="outline"
          leftIcon={buttonIcon}
          disabled={isLoading}
          onClick={onClickDownload}
          sx={{ textTransform: 'capitalization' }}
        >
          {isLoading
            ? t('views.features.packages.page_views.downloading')
            : t('views.features.packages.page_views.export')}
        </Button>
      </Tooltip>
    </Fragment>
  )
}

export default PageViewCsvDownload

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`
