import { useRef } from 'react'
import * as d3 from 'd3-format'
import dayjs from 'dayjs'
import { Button } from '@chakra-ui/button'
import { Icon } from '@chakra-ui/icon'
import { ModalHeader } from '@chakra-ui/modal'
import { HStack, Stack, Text } from '@chakra-ui/layout'
import { Trans } from 'react-i18next'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { get } from 'lodash'

import { Modal } from '@/components/shared'
import { SvgImage } from '@/components/DataDisplay'

import { usePackageStore } from '@/features/payments/stores'
import { useCurrentUser } from '@/features/users/queries'
import { matchLanguage, t } from '@/lib/helpers'
import { logEvent } from '@/lib/stats'
import { priceConfig } from '../../config'

import upgradeMonth from '@/images/project/payments/upgrade-month.png'
import upgradeYear from '@/images/project/payments/upgrade-year.png'
import { UpgradeModalProps } from './types'

export const UpgradeType1Modal = (props: UpgradeModalProps) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  const initialRef = useRef(null)
  const { period, plan, onCheckout } = usePackageStore()
  const { data: user } = useCurrentUser()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      withCloseButton
      id="upgrade-type-1-modal"
    >
      <Stack justify="center" spacing="6" sx={{ px: 8, py: 6 }}>
        <SvgImage
          src={period === 'month' ? upgradeMonth : upgradeYear}
          sx={{ w: '138px', h: '127px', mx: 'auto' }}
        />
        <ModalHeader
          sx={{ textAlign: 'center', fontSize: '2xl', fontWeight: 600 }}
        >
          <Trans i18nKey={`features.packages.upgrade.upgrade_to_${period}`}>
            Upgrade to&nbsp;
            <Text
              as="span"
              sx={{
                textTransform: 'capitalize',
                fontWeight: matchLanguage(700, 600),
              }}
            >
              {{ package: plan }}
            </Text>
            &nbsp;Package&nbsp;
            <br />
            <Text
              as="span"
              sx={{
                textTransform: 'capitalize',
                fontWeight: matchLanguage(700, 600),
              }}
            >
              {{
                price: d3.format(',d')(
                  get(priceConfig, `${plan}.${period}.price`),
                ),
              }}
              THB.&nbsp;
            </Text>
            <Text
              as="span"
              sx={{
                fontWeight: matchLanguage(700, 600),
              }}
            >
              per {period}
            </Text>
          </Trans>
        </ModalHeader>

        <Text sx={{ textAlign: 'center' }}>
          {user?.current_package.trialing
            ? t('views.features.packages.packages.upgrade.free_trial')
            : t('views.features.packages.packages.upgrade.next_billing')}
          &nbsp;
          <Text as="span" sx={{ fontWeight: 900 }}>
            {user?.current_package.trialing
              ? dayjs().format('DD MMM YYYY')
              : dayjs().add(1, period).format('DD MMM YYYY')}
          </Text>
        </Text>

        {user?.current_package.trialing ? (
          <Stack
            spacing="1"
            sx={{
              bg: '#f6505020',
              borderRadius: '8px',
              color: 'red.300',
              p: '4',
            }}
          >
            <HStack spacing="2">
              <Icon as={IoAlertCircleOutline} fontSize="22px" />
              <Text sx={{ fontWeight: 900, textTransform: 'uppercase' }}>
                {t('views.misc.warning')}
              </Text>
            </HStack>
            <Text sx={{ fontSize: '14px', pl: '6' }}>
              <Trans i18nKey="features.packages.upgrade.consent_caution">
                When the new package selection has been confirmed. &nbsp;
                <Text as="span" sx={{ fontWeight: 900 }}>
                  The free trial period will end immediately
                </Text>
                &nbsp; <br />
                and your account will be charged in the first billing according
                to the new package you choose.
              </Trans>
            </Text>
          </Stack>
        ) : (
          <Text>
            {t('views.features.packages.packages.upgrade.discounted')}
          </Text>
        )}

        <Button
          colorScheme="brand"
          size="xl"
          onClick={() => {
            logEvent({
              ga: {
                category: 'Upgrade',
                action: 'Click Upgrade',
                ...(plan && {
                  label: plan,
                }),
              },
            })
            onCheckout()
          }}
          ref={initialRef}
        >
          {t('views.shared.button.upgrade')}
        </Button>
        <Button variant="link" size="xl" onClick={onClose}>
          {t('views.shared.button.discard')}
        </Button>
      </Stack>
    </Modal>
  )
}
