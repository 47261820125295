import { VStack, Input, Button, Link, Text } from '@chakra-ui/react'

import { FormControl } from '@/components/FormControl'

import { inputStyle, linkStyle } from '@/features/auth/constants/styles'

import { t } from '@/lib/helpers'
import { getAuthenticityToken } from '@/lib/api'

type Props = {
  backupable: boolean
  formActionPath: string
  backupMode: boolean
}

function SignInTwoFactorForm({
  formActionPath,
  backupMode: initialBackupMode,
}: Props): React.ReactElement {
  const authenticityToken = getAuthenticityToken()
  const backupMode = initialBackupMode || false

  return (
    <VStack
      spacing="4"
      sx={{
        alignItems: 'stretch',
      }}
    >
      <form
        id="new_user"
        action={formActionPath}
        acceptCharset="UTF-8"
        method="post"
      >
        <VStack
          spacing="4"
          sx={{
            alignItems: 'stretch',
          }}
        >
          <FormControl
            isRequired
            label="รหัสที่ได้จากแอปพลิเคชันยืนยันตัวตน หรือ รหัสสำรอง"
          >
            <Input
              isRequired
              id="user_otp_attempt"
              type="text"
              autoComplete="off"
              autoFocus
              name="user[otp_attempt]"
              placeholder="โปรดกรอกรหัสที่ได้จากแอปพลิเคชันยืนยันตัวตน หรือ รหัสสำรอง"
              sx={inputStyle}
            />
          </FormControl>

          <Input
            value={authenticityToken}
            type="hidden"
            name="authenticity_token"
          />

          <Input
            value={backupMode.toString()}
            type="hidden"
            name="user[backup_mode]"
          />

          <Button
            type="submit"
            name="commit"
            size="xl"
            value={t('devise.sessions.new.sign_in')}
            data-disable-with={t('devise.sessions.new.sign_in')}
          >
            {t('devise.sessions.new.sign_in')}
          </Button>
        </VStack>
      </form>

      <VStack
        spacing="1"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Text>
          หากคุณไม่สามารถเข้าสู่ระบบได้&nbsp;
          <Link
            sx={linkStyle}
            onClick={() => {
              window.$crisp && window.$crisp.push(['do', 'chat:open'])
            }}
          >
            ติดต่อทีมงาน
          </Link>
        </Text>
      </VStack>
    </VStack>
  )
}

export default SignInTwoFactorForm
