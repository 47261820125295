import { DEFAULT_CATEGORY_SLUG } from '../Manage/constants'
import { byCategoryColor } from './constants'

import { DefaultCategorySlug } from '../Manage/types'
import { CategoryStatItem } from './types'

import { ColorItem } from './components/ColorItem'

export const isDefaultCategory = (
  slug: string,
): slug is DefaultCategorySlug => {
  return DEFAULT_CATEGORY_SLUG.includes(slug as DefaultCategorySlug)
}

export const randomColor = (index: number, total: number) => {
  const h = ~~((index / total) * 360)

  return `hsl(${h},50%,70%)`
}

export const renderColorItem = (data: CategoryStatItem[]) => {
  return data.map((datum, idx, array) => {
    let color: string

    if (isDefaultCategory(datum.slug)) {
      color = byCategoryColor[datum.slug]
    } else {
      color = randomColor(idx, array.length)
    }

    return (
      <ColorItem
        color={color}
        text={datum.label}
        value={datum.count}
        key={datum.label}
      />
    )
  })
}
