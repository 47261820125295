import { useTranslation } from 'react-i18next'
import { PieChart } from 'react-minimal-pie-chart'

import { pieChartStyle } from '../constants'

export const EmptyPie = () => {
  const { t } = useTranslation('common')

  return (
    <PieChart
      data={[{ value: 1, label: '', color: '#e2e8f0' }]}
      {...pieChartStyle}
      paddingAngle={0}
      label={({ x, y, dx, dy }) => (
        <text
          x={x}
          y={y}
          dx={dx}
          dy={dy + 38}
          key={'key'}
          dominantBaseline="central"
          textAnchor="middle"
          style={{
            fontSize: '5px',
            fill: '#b8b8b8',
          }}
        >
          {t('noData')}
        </text>
      )}
    />
  )
}
