import {
  Box,
  Button,
  Center,
  CircularProgress,
  Heading,
  Input,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import QRCode from 'qrcode.react'

import { FormControl } from '@/components/FormControl'
import { EnableTwoFactorResponse, TwoFactorOtpFormInput } from '../../types'
import { t } from '@/lib/helpers'

export type TwoFactorQrCodeProps = {
  isLoading: boolean
  onSubmit: (data: TwoFactorOtpFormInput) => void
  enable2FactorData?: EnableTwoFactorResponse
}

export const TwoFactorQrCode = (
  props: TwoFactorQrCodeProps,
): React.ReactElement => {
  const { enable2FactorData, onSubmit, isLoading } = props

  const { register, handleSubmit } = useForm<TwoFactorOtpFormInput>()
  const { hasCopied, onCopy } = useClipboard(enable2FactorData?.secret || '')

  return (
    <Box>
      <Heading as="h3" sx={{ fontSize: '24px', mb: 2 }}>
        {t(
          'views.features.two_factor.setup_authenticator',
          undefined,
          'Setup Authenticator',
        )}
      </Heading>

      {enable2FactorData ? (
        <Stack spacing="4">
          <Text>
            {t(
              'views.features.two_factor.setup_authenticator_description',
              undefined,
              'Scan the QR code into your Authenticator application of choice:',
            )}
          </Text>

          <Center>
            <QRCode value={enable2FactorData.qr_code_uri} size={300} />
          </Center>

          <Stack>
            <Text>
              {t(
                'views.features.two_factor.scan_manually',
                undefined,
                'Or enter the Secret Key manually:',
              )}
            </Text>
            <Button size="lg" onClick={onCopy}>
              {hasCopied
                ? t('views.misc.copied', undefined, 'Copied')
                : enable2FactorData.secret}
            </Button>
          </Stack>

          <form
            onSubmit={handleSubmit((data) => {
              onSubmit(data)
            })}
          >
            <Stack spacing="6">
              <FormControl
                label={t(
                  'views.features.two_factor.form.code',
                  undefined,
                  'Code',
                )}
                isRequired
              >
                <Input
                  size="lg"
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  autoFocus
                  {...register('otp_attempt')}
                  placeholder={t(
                    'views.features.two_factor.form.code_placeholder',
                    undefined,
                    'Enter your One-Time-code',
                  )}
                />
              </FormControl>

              <Button size="xl" type="submit" isLoading={isLoading}>
                {t('views.shared.button.submit', undefined, 'Submit')}
              </Button>
            </Stack>
          </form>
        </Stack>
      ) : (
        <Center sx={{ boxSize: '300px', mx: 'auto' }}>
          <CircularProgress isIndeterminate color="brand.400" size="100px" />
        </Center>
      )}
    </Box>
  )
}
