import { z } from 'zod'

import { t } from '@/lib/helpers'
import { CONSENT_MODEL } from './constants'

export const scrapedCookieInputSchema = z.object({
  category_id: z.string().nonempty(t('views.shared.input.required')),
  duration: z.string().nonempty(t('views.shared.input.required')),
  purpose: z.string().nonempty(t('views.shared.input.required')),
})

export const cookieInputSchema = scrapedCookieInputSchema.extend({
  name: z.string().nonempty(t('views.shared.input.required')),
  url: z.string().nonempty(t('views.shared.input.required')),
})

export const categorySchema = z.object({
  description: z
    .string()
    .nonempty({ message: t('views.shared.input.required') }),
  slug: z
    .string()
    .nonempty({ message: t('views.shared.input.required') })
    .regex(
      /^[a-z0-9-]+$/,
      t('views.features.domains.show.manage.form.category_slug_error_text'),
    ),
  name: z.string().nonempty({ message: t('views.shared.input.required') }),
  consent_model: z.enum(CONSENT_MODEL).optional(),
})
