import { useEffect, useMemo } from 'react'
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'

import { Table } from './Table'
import { useSort } from './hooks'
import { DataTableProps } from './types'

type TableHook = (typeof useSortBy | typeof usePagination | typeof useFilters)[]

export const DataTable = <T extends Record<string, unknown>>(
  props: DataTableProps<T>,
) => {
  const {
    columns,
    data,
    initialState,
    isLoading,
    autoResetSortBy,
    autoResetPage = false,
    showPagination = false,
    renderActionColumn,
    renderNoData,
    renderSubRow,
    tableStyle,
    filterProps,
    tableSx,
    ...rest
  } = props

  const tableHooks = useMemo(() => {
    const hooks: TableHook = [useSortBy]

    if (showPagination) hooks.push(usePagination)
    if (filterProps) hooks.unshift(useFilters)

    return hooks
  }, [showPagination])

  const tableInstance = useTable<T>(
    {
      // manualSortBy: true,
      columns,
      data,
      autoResetPage,
      autoResetSortBy,
      initialState: {
        ...initialState,
        pageSize: showPagination ? 10 : undefined,
      },
      ...rest,
    },
    ...tableHooks,
  )

  useSort({
    ...props,
    tableInstance,
  })

  useEffect(() => {
    if (!filterProps) return
    const { columnId, filterType } = filterProps

    if (tableInstance.setFilter) {
      tableInstance.setFilter(columnId, filterType)
    }
  }, [filterProps?.filterType, tableInstance.setFilter])

  return (
    <Table
      isLoading={isLoading}
      showPagination={showPagination}
      tableInstance={tableInstance}
      renderActionColumn={renderActionColumn}
      renderNoData={renderNoData}
      tableStyle={tableStyle}
      renderSubRow={renderSubRow}
      tableSx={tableSx}
    />
  )
}
