import { useQuery } from 'react-query'

import { fetchAPI } from '@/lib/api'

import { TWO_FACTOR } from '../constants'

import { TwoFactorStatus } from '../types'
import { AxiosError } from 'axios'

const getTwoFactorStatus = async () => {
  const response = await fetchAPI<TwoFactorStatus>({
    path: '/settings/security',
  })

  return response.data
}

export const useTwoFactorStatus = (initialData: TwoFactorStatus) => {
  return useQuery<TwoFactorStatus, AxiosError>({
    queryKey: [TWO_FACTOR],
    queryFn: () => getTwoFactorStatus(),
    initialData,
  })
}
