import { Center, Box, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { DotsLoader } from '@/components/Loader'

export const LoadingOverlay = () => {
  const { t } = useTranslation('domainConsent')

  return (
    <Center
      sx={{
        pos: 'absolute',
        boxSize: 'full',
        top: 0,
        left: 0,
        bg: '#88888840',
        cursor: 'not-allowed',
      }}
    >
      <Box sx={{ p: 6, borderRadius: '8px', bg: 'white' }}>
        <Center>
          <DotsLoader />
        </Center>
        <Text mt={4}>{t('fetchingText')}</Text>
      </Box>
    </Center>
  )
}
