import { ModalBody, ModalHeader } from '@chakra-ui/modal'
import { Text } from '@chakra-ui/layout'
import { UseDisclosureReturn } from '@chakra-ui/hooks'
import * as d3 from 'd3-format'

import { Modal } from '@/components/shared'
import { ScanCookieForm } from '../ScanCookieForm'

import { t } from '@/lib/helpers'
import { useQuotaData } from '@/features/users/queries'
import { useScannedURLData } from '@/features/domains/queries'

import { ScanCookieFormInput } from '../../types'

type Props = {
  disclosure: UseDisclosureReturn
  handleScan: (data: ScanCookieFormInput) => Promise<void>
  isLoading: boolean
}

export const ScanCookieModal = (props: Props) => {
  const { disclosure, handleScan, isLoading } = props
  const { isOpen, onClose } = disclosure

  const quota = useQuotaData()
  const scannedUrl = useScannedURLData()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <ModalHeader>
        {t('views.features.domains.show.scanner.scan_modal.title')}
      </ModalHeader>

      <ModalBody>
        <Text sx={{ mb: '24px' }}>
          {t('views.features.domains.show.scanner.scan_modal.description')}
          &nbsp;
          <Text as="span" color="gray.300">
            {t('views.features.domains.show.scanner.scan_modal.description_2')}
          </Text>
        </Text>

        <Text sx={{ mb: '16px' }}>
          {t('views.features.domains.show.scanner.scan_modal.number_scan')}
          <br />
          {quota && (
            <Text as="span" sx={{ fontWeight: 700 }}>
              {t(
                'views.features.domains.show.scanner.scan_modal.remaining_for_your_package',
                {
                  remaining: d3.format(',d')(
                    quota.page_scan?.limit - quota.page_scan?.used,
                  ),
                  total: d3.format(',d')(quota.page_scan?.limit),
                },
              )}
            </Text>
          )}
        </Text>

        {quota && (
          <ScanCookieForm
            isLoading={isLoading}
            onCancel={onClose}
            onSubmitForm={async (data) => {
              await handleScan(data)
              onClose()
            }}
            quota={quota.page_scan?.limit - quota.page_scan?.used}
            defaultValues={{
              includes: scannedUrl?.args?.includes ?? [],
              excludes: scannedUrl?.args?.excludes ?? [],
            }}
          />
        )}
      </ModalBody>
    </Modal>
  )
}
