export default {
  month: 'month',
  month_plural: 'months',
  year: 'year',
  year_plural: 'years',
  user: 'user',
  user_plural: 'users',
  domain: 'domain',
  domain_plural: 'domains',
}
