import { Fragment, useMemo, useEffect } from 'react'
import {
  Box,
  Button,
  Image,
  ListItem,
  Link,
  Text,
  useToast,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { SelectCreditCard } from '@datawowio/payment-ui'
import { Trans, useTranslation } from 'react-i18next'

import { t } from '@/lib/helpers'
import NoPackage from '@/images/project/payments/no-package.svg'
import Contact from '@/images/icons/contact.svg'

// @ts-ignore
import { UpdateCreditForm } from '@/features/payments/containers'
import {
  UpdateCreditCardList,
  ConfirmUpdatePaymentModal,
} from '@/features/payments/components'
import {
  withUpdatePaymentPage,
  useUpdatePaymentStore,
} from '@/features/payments/stores'
import { SettingLayout } from '../../components'

const CreditCardsSettingPage = () => {
  const {
    cardId,
    creditCards,
    currentPackage,
    subscriptionCreditCard,
    manualPayment,
  } = useUpdatePaymentStore()
  const toast = useToast()
  const { t: tPayment } = useTranslation('payment')

  const updatePaymentDisclosure = useDisclosure()

  const hasChange = useMemo(() => {
    return subscriptionCreditCard?.id !== cardId
  }, [cardId, subscriptionCreditCard])

  const renderContent = () => {
    if (currentPackage?.name === 'free') {
      return (
        <Box w="476px" textAlign="center">
          <Image
            boxSize="120px"
            objectFit="cover"
            src={NoPackage}
            sx={{ margin: 'auto' }}
          />
          <Text sx={{ mt: 4, color: 'gray.600', fontWeight: 600 }}>
            <Trans t={tPayment} i18nKey="noPackage">
              กรุณา&nbsp;
              <Link
                sx={{ color: 'brand.400', textDecoration: 'underline' }}
                href="/packages"
              >
                เลือกแพ็กเกจ
              </Link>
              &nbsp;เพื่อเริ่มต้นการใช้งาน
            </Trans>
          </Text>
        </Box>
      )
    }

    if (manualPayment) {
      return (
        <Box w="476px" textAlign="center">
          <Image
            boxSize="120px"
            objectFit="cover"
            src={Contact}
            sx={{ margin: 'auto' }}
          />
          <Text sx={{ mt: 4, color: 'gray.600', fontWeight: 600 }}>
            {t('views.features.payments.update.contact')}
          </Text>
        </Box>
      )
    }

    return (
      <Box w="720px">
        <SelectCreditCard
          currentLng={gon.currentLocale}
          renderCreditForm={({ onClose }) => {
            return <UpdateCreditForm onClose={onClose} />
          }}
        >
          <Box
            sx={{
              border: creditCards?.length ? '1px solid' : 'none',
              borderColor: 'gray.200',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <UpdateCreditCardList
              cards={creditCards}
              subscriptionCreditCard={subscriptionCreditCard}
            />
          </Box>
        </SelectCreditCard>
        {creditCards?.length ? (
          <>
            <Text
              sx={{
                color: 'brand.400',
                fontSize: '12px',
                textDecoration: 'underline',
                mt: '4',
              }}
            >
              {t('views.features.payments.contents.remark.title')}
            </Text>
            <UnorderedList
              sx={{ fontSize: '12px', color: 'gray.500', pl: '2', mt: '2' }}
            >
              <ListItem>
                {t('views.features.payments.contents.remark.1')}
              </ListItem>
            </UnorderedList>
            <Button
              isDisabled={!hasChange}
              colorScheme="brand"
              size="xl"
              sx={{ mt: '4', w: 'full' }}
              onClick={updatePaymentDisclosure.onOpen}
            >
              {t('views.features.payments.contents.update')}
            </Button>
          </>
        ) : null}
      </Box>
    )
  }

  ///read session for success toast
  useEffect(() => {
    if (sessionStorage.getItem('updateSuccess') === '1') {
      sessionStorage.removeItem('updateSuccess')
      toast({
        title: t('views.features.payments.services.update_success'),
        status: 'success',
        position: 'top-right',
        variant: 'left-accent',
      })
    }
  }, [])

  return (
    <Fragment>
      <SettingLayout>
        <Text sx={{ mb: '24px', fontWeight: 700 }}>
          {t('views.features.users.settings.credit_cards_settings')}
        </Text>

        {renderContent()}
      </SettingLayout>

      <ConfirmUpdatePaymentModal disclosure={updatePaymentDisclosure} />
    </Fragment>
  )
}

export default withUpdatePaymentPage(CreditCardsSettingPage)
