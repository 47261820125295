import { Trans, useTranslation } from 'react-i18next'
import { Image, VStack, Stack, Text, Button } from '@chakra-ui/react'
import dayjs from 'dayjs'

import CookieChecked from '@/images/cookies/cookie-checked.svg'
import { Span } from '@/components/shared'

type ChangeEmailSuccessProps = {
  email: string
  onClose: () => void
}

export const ChangeEmailSuccess = (props: ChangeEmailSuccessProps) => {
  const { email, onClose } = props

  const { t: tSettings } = useTranslation('settings')
  const { t: tCommon } = useTranslation('common')

  return (
    <Stack spacing={6}>
      <Image
        src={CookieChecked}
        alt="change email success"
        boxSize="134px"
        sx={{ mx: 'auto' }}
      />

      <VStack>
        <Text sx={{ fontSize: 'xl', fontWeight: 'bold' }}>
          {tSettings('changeEmailSuccess.title')}
        </Text>
        <Text
          sx={{
            color: 'gray.600',
            textAlign: 'center',
            px: 8,
            span: {
              color: 'brand.400',
              fontWeight: 'semibold',
            },
          }}
        >
          <Trans t={tSettings} i18nKey="changeEmailSuccess.description">
            You need to verify the new email before changes. please check your
            inbox in&nbsp;
            <Span>{{ email }}</Span>
            to verify within&nbsp;
            <Span>{{ date: dayjs().add(3, 'day').format('DD MMM YYYY') }}</Span>
          </Trans>
        </Text>
      </VStack>

      <Button size="xl" onClick={onClose}>
        {tCommon('close')}
      </Button>
    </Stack>
  )
}
