import { Box, Text, Grid, Flex, Divider, Stack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { PieChart } from 'react-minimal-pie-chart'

import { pieChartStyle } from '../../constants'

import { ColorItem } from '../ColorItem'
import { EmptyPie } from '../EmptyPie'

export type ByCategoryDetailedChartProps = {
  data: { value: number; color: string; title: string }[]
  total: number
}

export const ByCategoryDetailedChart = (
  props: ByCategoryDetailedChartProps,
): React.ReactElement => {
  const { data, total } = props

  const { t: tCommon } = useTranslation('common')

  const pieData = data.filter((data) => Boolean(data.value))

  return (
    <Box>
      <Grid
        sx={{
          px: 8,
          gap: '72px',
          gridTemplateColumns: '300px 220px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ boxSize: 'full' }}>
          {total ? (
            <PieChart
              data={pieData}
              label={({ dataEntry }) => {
                const percent = Math.round((dataEntry.value / total) * 100)

                return percent < 5 ? null : percent + '%'
              }}
              {...pieChartStyle}
              paddingAngle={pieData.length > 1 ? 3 : 0}
            />
          ) : (
            <EmptyPie />
          )}
        </Box>

        <Stack>
          <Flex
            sx={{
              fontSize: 'xl',
              fontWeight: 'bold',
              justifyContent: 'space-between',
            }}
          >
            <Text>{tCommon('total')}</Text>
            <Text>{total}</Text>
          </Flex>

          <Divider />

          {data.map((datum) => {
            return (
              <ColorItem
                key={datum.title}
                text={datum.title}
                color={datum.color}
                value={datum.value}
              />
            )
          })}
        </Stack>
      </Grid>
    </Box>
  )
}
