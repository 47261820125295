import { Icon } from '@chakra-ui/icon'
import {
  Box,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/layout'
import { Trans, useTranslation } from 'react-i18next'
import { IoAlertCircleOutline } from 'react-icons/io5'
import * as d3 from 'd3-format'

import { matchLanguage, t } from '@/lib/helpers'
import { priceConfig } from '../../config'
import { PackageNameInUse } from '@/features/types'
import { Fragment } from 'react'

export const ConsentCautionText = () => {
  return (
    <Stack
      spacing="1"
      sx={{
        bg: '#f6505020',
        borderRadius: '8px',
        color: 'red.300',
        p: '4',
      }}
    >
      <HStack spacing="2">
        <Icon as={IoAlertCircleOutline} fontSize="22px" />
        <Text sx={{ fontWeight: 900, textTransform: 'uppercase' }}>
          {t('views.misc.warning')}
        </Text>
      </HStack>
      <Text sx={{ fontSize: '14px', pl: '6' }}>
        <Trans i18nKey="features.packages.downgrade.consent_caution">
          When you downgrade or unsubscribe, exceeded consent recordswill be
          permanently deleted. You should export your consent before your next
          plan’s effective date.
        </Trans>
      </Text>
    </Stack>
  )
}

type PackageDetailsProps = {
  plan: PackageNameInUse
  trialing?: boolean
}

export const PackageDetails = (props: PackageDetailsProps) => {
  const { plan, trialing } = props
  const packageDetails = priceConfig[plan].details

  const { t: T } = useTranslation()

  return (
    <Box
      sx={{
        strong: {
          fontWeight: matchLanguage<number>(700, 600),
        },
      }}
    >
      {!trialing && (
        <Text mb="4">{T('features.packages.cancel.can_resub_anytime')}</Text>
      )}

      <Box mb="6">
        <Text>
          <Trans i18nKey="features.packages.cancel.back_to_free">
            หลังจากนั้น แพ็กเกจของคุณจะถูกดาวน์เกรดเป็น
            <strong>แพ็กเกจ {{ name: plan.toTitleCase() }}</strong>&nbsp;
            ด้วยข้อจำกัดดังนี้:
          </Trans>
        </Text>

        <UnorderedList
          sx={{
            pr: 6,
            li: {
              _before: {
                content: '"-"',
                d: 'inline-block',
                mr: '8px',
              },
            },
          }}
          listStyleType="none"
        >
          <ListItem>
            <Trans i18nKey="features.packages.cancel.bullet_1">
              Pageview&nbsp;
              <strong>
                สูงสุดที่&nbsp;
                {{
                  pageview: d3.format(',d')(packageDetails.pageView),
                }}
              </strong>
              &nbsp;ต่อเดือน
            </Trans>
          </ListItem>
          <ListItem>
            {T('features.packages.cancel.limited_domain', {
              count:
                typeof packageDetails.domains === 'number'
                  ? packageDetails.domains
                  : 0,
            })}
          </ListItem>
          <ListItem>
            {t('views.features.packages.packages.downgrade.limited_page_scan', {
              page_scan: d3.format(',')(packageDetails.scanQuota),
            })}
          </ListItem>
          <ListItem>
            {t(
              'views.features.packages.packages.downgrade.limited_consent_record',
              {
                consent_record:
                  typeof packageDetails.noOfConsents === 'number'
                    ? d3.format(',')(packageDetails.noOfConsents)
                    : packageDetails.noOfConsents,
                month: packageDetails.retentionPeriod,
              },
            )}
          </ListItem>
          {plan === 'free' && (
            <Fragment>
              <ListItem>
                {t(
                  'views.features.packages.packages.downgrade.limited_banner_customize',
                )}
              </ListItem>
              <ListItem>
                {t(
                  'views.features.packages.packages.downgrade.limited_branding',
                )}
              </ListItem>
            </Fragment>
          )}
        </UnorderedList>
      </Box>

      <ConsentCautionText />
    </Box>
  )
}
