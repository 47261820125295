import { CSSObject } from '@chakra-ui/styled-system'
import { t } from '@/lib/helpers'

import FreePlan from '@/images/pricing/plans/free.png'
import SmallPlan from '@/images/pricing/plans/small.png'
import MediumPlan from '@/images/pricing/plans/medium.png'
import LargePlan from '@/images/pricing/plans/large.png'

export const PAGEVIEWS = 'page_views'
export const PAGEVIEWS_DEFAULT_PER = 4
export const PAGEVIEWS_STATUS = 'page_views_status'
export const PAGEVIEWS_CSV_STATUS = 'page_views_csv_status'

export const PAYMENT_METHOD = {
  CREDIT: 'credit',
  TRANSFER: 'transfer',
}

export const PLAN_IMAGE: Record<PackageNameInUse, any> = {
  free: FreePlan,
  small: SmallPlan,
  medium: MediumPlan,
  large: LargePlan,
}

export const PACKAGE_NAMES_IN_USE = [
  // 'free',
  'small',
  'medium',
  'large',
] as const

export const PAYMENT_LAYOUT_TABS = [
  {
    name: t('views.features.packages.packages.title'),
    href: '/packages',
    checkActive: ['packages'],
  },
  // {
  //   name: t('views.features.packages.receipts.title'),
  //   href: '/transactions',
  //   checkActive: ['receipts'],
  // },
  {
    name: t('views.features.packages.page_views.title'),
    href: '/page_views',
    checkActive: ['pageViews'],
  },
]

export const PRICING_TABLE_COLUMNS: {
  value: string
  sx?: CSSObject
  tooltip?: string
  helperText?: string
  ariaLabel?: string
}[] = [
  {
    value: 'pricingTable.numberOfPageview',
    tooltip: 'pricingTable.numberOfPageviewTooltip',
    ariaLabel: 'number-of-pageview-tooltip',
    sx: { borderTopLeftRadius: '16px', d: 'flex', alignItems: 'center' },
  },
  {
    value: 'pricingTable.numberOfDomain',
    tooltip: 'pricingTable.numberOfDomainTooltip',
    ariaLabel: 'number-of-domain-tooltip',
  },
  {
    value: 'pricingTable.numberOfUser',
    tooltip: 'pricingTable.numberOfUserTooltip',
    ariaLabel: 'number-of-user-tooltip',
  },
  {
    value: 'pricingTable.numberOfWebsiteScan',
    tooltip: 'pricingTable.numberOfWebsiteScanTooltip',
    ariaLabel: 'number-of-website-tooltip',
    helperText: 'pricingTable.numberOfWebsiteScanUnit',
  },
  {
    value: 'pricingTable.numberOfConsent',
    tooltip: 'pricingTable.numberOfConsentTooltip',
    ariaLabel: 'number-of-consent-tooltip',
  },
  {
    value: 'pricingTable.retentionPeriod',
    tooltip: 'pricingTable.retentionPeriodTooltip',
    ariaLabel: 'retention-period-tooltip',
  },
  {
    value: 'pricingTable.autoScriptBlock',
    tooltip: 'pricingTable.autoScriptBlockTooltip',
    ariaLabel: 'auto-script-block-tooltip',
  },
  {
    value: 'pricingTable.consentLogs',
    tooltip: 'pricingTable.consentLogsTooltip',
    ariaLabel: 'consent-logs-tooltip',
  },
  {
    value: 'pricingTable.advancedCustomize',
    tooltip: 'pricingTable.advancedCustomizeTooltip',
    ariaLabel: 'branding-customize-tooltip',
  },
  {
    value: 'pricingTable.brandingCustomize',
    tooltip: 'pricingTable.brandingCustomizeTooltip',
    ariaLabel: 'branding-customize-tooltip',
  },
]
