import { Box, Center, Grid, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { Image } from '@chakra-ui/react'

import Error403Image from '@/images/errors/403-error.png'
import { errorPageButtonStyle } from './styles'

function Error403() {
  return (
    <Center
      sx={{
        w: '100%',
        h: '100vh',
        bg: 'white',
      }}
    >
      <Box maxW="850px" m="0 auto" p="4">
        <Grid gridTemplateColumns="max-content 1fr" gap="4" alignItems="center">
          <Box mr="32px" textAlign="center">
            <Image maxW="402px" src={Error403Image} />
          </Box>

          <Grid gap="1">
            <Text as="h2" fontSize="36px" fontWeight="700">
              :/ Nahhhh…
            </Text>
            <Text fontSize="26px">Access Denied</Text>
            <Text fontSize="20px">
              We are sorry, but you do not have a permission to access to this
              page.
            </Text>

            <Button sx={errorPageButtonStyle} as="a" href="/">
              Back Homepage
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Center>
  )
}

export default Error403
