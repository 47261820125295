import { useEffect, useMemo, useState } from 'react'
import { Flex, HStack, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { Trans, useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { Tooltip } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { formatDateTime } from '@/lib/helpers'
import { ColorDot } from '@/components/shared'

import { useCheckBannerStatus } from '../../mutations'
import { PollingStatus } from '@/features/types'
import { logEvent } from '@/lib/stats'

type Props = {
  project: Project
  bannerPollingStatus: PollingStatus
}

const WAIT_PERIOD_MINUTE = 5
const MINUTE_IN_MS = 60000
const COOKIE_KEY = 'lastCheck'

export const BannerStatus = (props: Props) => {
  const { project, bannerPollingStatus } = props
  const { id, banner_status, banner_status_checked_at } = project

  const [countdown, setCountdown] = useState(0)

  const { t } = useTranslation('domains')
  const { checkBannerStatus, isLoading, status } = useCheckBannerStatus({
    projectId: id,
    startOnMount: ['processing', 'queued'].includes(bannerPollingStatus),
  })

  const magicKey = useMemo<BannerStatus>(() => {
    if (!status) {
      // first hit case
      if (['processing', 'queued'].includes(bannerPollingStatus)) {
        return 'checking'
      }

      // not processing case
      return banner_status
    }

    // processing
    if (['processing', 'queued'].includes(status)) {
      return 'checking'
    } else {
      return banner_status
    }
  }, [banner_status, status])

  useEffect(() => {
    const lastCheckAt = Cookies.get(COOKIE_KEY)

    if (!lastCheckAt) return

    const now = new Date().getTime()

    const minuteSinceLastScan = Math.ceil(
      (now - Number(lastCheckAt)) / MINUTE_IN_MS,
    )

    setCountdown(Math.max(0, WAIT_PERIOD_MINUTE + 1 - minuteSinceLastScan))
  }, [])

  useEffect(() => {
    let timer: number

    if (countdown > 0) {
      timer = window.setTimeout(() => {
        setCountdown((state) => state - 1)
      }, MINUTE_IN_MS)
    }

    return () => window.clearTimeout(timer)
  }, [countdown])

  const generateComponent = () => {
    const data = {
      unchecked: {
        color: 'gray.300',
        text: (
          <Trans i18nKey={`features.domains.banner_status.${magicKey}`}>
            Not verify
          </Trans>
        ),
      },
      checking: {
        color: 'gray.300',
        text: (
          <Trans i18nKey={`features.domains.banner_status.${magicKey}`}>
            Checking...
          </Trans>
        ),
      },
      active: {
        color: 'green.400',
        text: (
          <Trans i18nKey={`features.domains.banner_status.${magicKey}`}>
            Banner is <strong>active</strong>
          </Trans>
        ),
      },
      inactive: {
        color: 'red.300',
        text: (
          <Trans i18nKey={`features.domains.banner_status.${magicKey}`}>
            Banner is <strong>inactive</strong>
          </Trans>
        ),
      },
    }[magicKey]

    return (
      <Flex sx={{ alignItems: 'center' }}>
        <ColorDot size="12px" color={data.color} />
        <Text sx={{ ml: '8px' }}>{data.text}</Text>
      </Flex>
    )
  }

  return (
    <HStack sx={{ fontSize: '14px' }} spacing={4}>
      <Tooltip label={t('layout.bannerStatus')} placement="top" hasArrow>
        <Button
          colorScheme="brand"
          size="xs"
          isLoading={isLoading}
          onClick={() => {
            logEvent({
              ga: {
                category: 'Check status',
                action: 'Click check status',
              },
            })
            checkBannerStatus({ projectId: id })
            setCountdown(WAIT_PERIOD_MINUTE)
            Cookies.set(COOKIE_KEY, `${new Date().getTime()}`)
          }}
        >
          {t('layout.checkStatus')}
        </Button>
      </Tooltip>

      <HStack>
        {generateComponent()}

        {banner_status_checked_at && (
          <Tooltip
            label={formatDateTime(banner_status_checked_at)}
            placement="top"
            hasArrow
          >
            <Text
              as="span"
              sx={{
                fontStyle: 'italic',
                textTransform: 'initial',
              }}
            >
              ({t('layout.bannerStatusUpdated')}:&nbsp;
              {dayjs(banner_status_checked_at).fromNow()})
            </Text>
          </Tooltip>
        )}
      </HStack>
    </HStack>
  )
}
