export default {
  month: 'เดือน',
  month_plural: 'เดือน',
  year: 'ปี',
  year_plural: 'ปี',
  user: 'บัญชี',
  user_plural: 'บัญชี',
  domain: 'โดเมน',
  domain_plural: 'โดเมน',
}
