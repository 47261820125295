import { z } from 'zod'

import { passwordValidator } from '@/components/Input/PasswordInput'

export const changePasswordFormSchema = z
  .object({
    current_password: z.string().nonempty('input.required'),
    new_password: z
      .string()
      .nonempty('input.required')
      .refine(passwordValidator, 'input.invalidPassword'),
    confirm_password: z.string().nonempty('input.required'),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: 'input.passwordMismatch',
    path: ['confirm_password'],
  })
