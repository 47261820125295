import { useMemo } from 'react'
import { Column } from 'react-table'
import { Text } from '@chakra-ui/react'

import { SelectionTable } from '@/components/DataDisplay/Table'

import { formatDateTime, t } from '@/lib/helpers'

import { HandleSelectTableRow } from '@/components/DataDisplay/Table/types'
import { User } from '@/features/users/types'
import { ShowFolder } from '@/features/users/components'

export type SelectUsersTableProps = {
  data: User[]
  handleSelectRow: HandleSelectTableRow<User>
  limit: number
}

export const SelectUsersTable = (props: SelectUsersTableProps) => {
  const { data, handleSelectRow, limit } = props

  const columns = useMemo<Array<Column<User>>>(() => {
    return [
      {
        Header: t('views.misc.email', undefined, 'Email'),
        accessor: 'email',
        sortKey: 'email',
        width: '30%',
      },
      {
        Header: t('views.features.users.index.status', undefined, 'Status'),
        accessor: 'status',
        sortKey: 'status',
        width: '25%',
        Cell(cellProps) {
          const data = cellProps.row.original

          const shouldNotShowResend =
            data.invitation_sent_at === null ||
            data.invitation_accepted_at !== null // if satisfied === active

          return (
            <Text>
              {shouldNotShowResend
                ? t(
                    'views.features.users.index.user_status_active',
                    undefined,
                    'Active',
                  )
                : t(
                    'views.features.users.index.user_status_pending',
                    undefined,
                    'Pending',
                  )}
            </Text>
          )
        },
      },
      {
        Header: t(
          'views.features.users.index.folder_name',
          undefined,
          'Folder Name',
        ),
        accessor: 'folder_permissions',
        disableSortBy: true,
        width: '20%',
        Cell(cellProps) {
          const value = cellProps.value.filter((v) => v.role !== 'no_access')

          return <ShowFolder folder_permissions={value} />
        },
      },
      {
        Header: t('views.misc.created_at', undefined, 'Created at'),
        accessor: 'created_at',
        sortKey: 'createdAt',
        width: '20%',
        Cell(cellProps) {
          const value = cellProps.value

          return formatDateTime(value)
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  const selectedRowIds = useMemo(() => {
    const result: {
      [k: string]: boolean
    } = {}

    const index = memoizedData.findIndex((data) => data.role === 'owner')

    result[index] = true

    return result
  }, [memoizedData])

  return (
    <SelectionTable<User>
      columns={columns}
      data={memoizedData}
      selectProps={{
        handleSelectRow,
        limit,
        customRowDisable(user) {
          return user.role === 'owner'
        },
      }}
      initialState={{
        selectedRowIds,
      }}
    />
  )
}
