import { Fragment, useState, useRef, useEffect } from 'react'
import { isEmpty, get } from 'lodash'
import { CookieCategory } from '@datawowio/cookie-banner-ui'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Grid, HStack } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { useToast } from '@chakra-ui/toast'
import { zodResolver } from '@hookform/resolvers/zod'

import { DomainShowLayout } from '@/features/domains/components'
import { withPage } from '@/features/layout/ApplicationLayout'
import {
  CustomizePreview,
  CustomizeForm,
  CopyScriptModal,
} from '../../Customize/components'

import { PollingStatus } from '@/features/types'
import { Dialog, DialogFormInput } from '@/features/domains/types'

import { dialogToFormInput } from '@/features/domains/utils'

import { useUpdateDialog } from '@/features/domains/mutations'
import { useAppContext } from '@/features/layout'
import { useDialog } from '../../Customize/queries'
import { editDialogInputSchema } from '../../schema'
import { useNonInitialEffect } from '@/lib/hooks'
import { useAbility } from '@/features/permissions'
import { t } from '@/lib/helpers'
import { ERROR_TOAST_CONFIG } from '@/lib/constants'
import { PreviewLanguage } from '../../Customize/components/PreviewLanguage'

type Props = {
  initialDialog: Dialog
  project: Project
  script: string
  categories: Record<BannerLanguage, CookieCategory[]>
  banner_polling_status: PollingStatus
  surveysensum_url: string
}

function DomainCustomizePage(props: Props) {
  const {
    project,
    initialDialog,
    script,
    categories,
    banner_polling_status,
    surveysensum_url,
  } = props

  useDialog({ initialData: initialDialog })
  const { mutate: updateDialog, isLoading } = useUpdateDialog()
  const { isFree } = useAppContext()
  const toast = useToast()

  const [showWidget, setShowWidget] = useState(false)

  const copyScriptDisclosure = useDisclosure()

  const ability = useAbility()
  const canUpdate = ability.can('update', 'customization')

  const method = useForm<DialogFormInput>({
    resolver: zodResolver(editDialogInputSchema),
    defaultValues: dialogToFormInput(initialDialog, isFree),
    mode: 'onSubmit',
  })
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    setValue,
  } = method

  const revokable = watch('revokable')
  const preview_language = watch('preview_language')
  const close_button = watch('close_button')
  const widget = watch('widget')
  const widgetType = watch('widget_icon_type')

  const isNoticeOnly = initialDialog.banner_model === 'notice_only'

  const timeoutRef = useRef<number>()

  useEffect(() => {
    if (!isEmpty(errors)) {
      toast({
        ...ERROR_TOAST_CONFIG,
        description: t('views.features.domains.show.customize.invalid'),
      })
    }
  }, [errors])

  useNonInitialEffect(() => {
    const defaultRevokable =
      initialDialog.widget && ['left', 'right'].includes(initialDialog.widget)
        ? '1'
        : '2'

    if (defaultRevokable === '2' && revokable === '1') {
      setShowWidget(true)
      setValue('widget', 'left')
    }
  }, [revokable])

  useNonInitialEffect(() => {
    if (close_button === false) {
      setValue('close_button_behavior', 'default')
    }
  }, [close_button])

  useNonInitialEffect(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = window.setTimeout(() => {
      setShowWidget(false)
    }, 4000)

    return () => {
      window.clearTimeout(timeoutRef.current)
    }
  }, [showWidget, revokable, widgetType])

  useNonInitialEffect(() => {
    setShowWidget(true)
  }, [widget, widgetType])

  return (
    <Fragment>
      <FormProvider {...method}>
        <form
          onSubmit={handleSubmit((data) => {
            if (isSubmitting) return

            const { bg_color, text_color, custom_css, ...rest } = data

            updateDialog({
              form: isFree ? rest : data,
              id: get(initialDialog, 'id'),
              projectId: get(project, 'id'),
            })
          })}
        >
          <DomainShowLayout
            project={project}
            bannerPollingStatus={banner_polling_status}
            surveyUrl={surveysensum_url}
            buttons={
              <HStack spacing="16px">
                <Button
                  id="Customize-getScript"
                  variant="outline"
                  onClick={copyScriptDisclosure.onOpen}
                  sx={{ fontSize: '14px' }}
                >
                  {t(
                    'views.features.domains.show.customize.get_script',
                    undefined,
                    'Get Script',
                  )}
                </Button>
                <Button
                  id="Customize-updateStyle"
                  sx={{ fontSize: '14px', flex: 1 }}
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={!canUpdate}
                >
                  {t(
                    'views.features.domains.show.customize.update',
                    undefined,
                    'Update',
                  )}
                </Button>
              </HStack>
            }
          >
            <Grid sx={{ gridTemplateColumns: '396px minmax(0,1fr)' }}>
              <Box>
                <Box
                  sx={{
                    p: '16px 24px',
                    borderBottom: '1px solid',
                    borderBottomColor: 'gray.200',
                  }}
                >
                  <PreviewLanguage />
                </Box>

                <Box
                  sx={{
                    h: 'calc(100vh - 360px)',
                    maxH: '890px',
                    overflowY: 'auto',
                    pos: 'relative',
                    p: '16px 24px',
                  }}
                >
                  <CustomizeForm
                    isNoticeOnly={isNoticeOnly}
                    setShowWidget={setShowWidget}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: 'gray.300',
                  pos: 'relative',
                }}
              >
                <CustomizePreview
                  control={control}
                  cookieCategories={categories[preview_language]}
                  showWidget={showWidget}
                />
              </Box>
            </Grid>
          </DomainShowLayout>
        </form>
      </FormProvider>

      <CopyScriptModal
        disclosure={copyScriptDisclosure}
        script={script}
        token={project.token}
      />
    </Fragment>
  )
}

export default withPage(DomainCustomizePage)
