import { useState, useMemo } from 'react'
import {
  Button,
  Text,
  Stack,
  Image,
  useToast,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { Trans } from 'react-i18next'

import { useUpdatePaymentStore } from '../../stores'
import { useUpdatePaymentServices } from '@/features/payments/services/UpdatePaymentServices'

import Warning from '@/images/cookies/cookie-warning.svg'
import { t } from '@/lib/helpers'
import { Modal } from '@/components/shared'

type ConfirmUpdatePaymentProps = {
  disclosure: UseDisclosureReturn
}

export const ConfirmUpdatePaymentModal = (props: ConfirmUpdatePaymentProps) => {
  const { disclosure } = props
  const toast = useToast()
  const { isOpen, onClose } = disclosure
  const [loading, setLoading] = useState<boolean>(false)

  const { cardId, creditCards } = useUpdatePaymentStore()
  const { setSubscriptionCard } = useUpdatePaymentServices()

  const cardNumber = useMemo(() => {
    return creditCards?.find((card) => card.id === cardId)?.number
  }, [cardId])

  const setSubscription = () => {
    setLoading(true)
    setSubscriptionCard(cardId).then(() => {
      onClose()
      setLoading(false)
      ///set session for success toast
      ///and refresh page for receive update value from controller
      sessionStorage.setItem('updateSuccess', '1')
      window.location.reload()
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <Stack spacing="9" mt="12" px="8">
        <Image
          boxSize="155px"
          objectFit="cover"
          src={Warning}
          sx={{ margin: 'auto' }}
        />
        <Stack spacing="2" sx={{ textAlign: 'center' }}>
          <Text sx={{ fontSize: '24px', fontWeight: 700 }}>
            {t('views.features.payments.update.card')}
          </Text>
          <Text>
            <Trans i18nKey="features.payments.update">
              The credit card will be changed to&nbsp;
              <Text as="span" color="brand.400">
                {{ cardNumber }}
              </Text>
              <br />
              An update to your current subscription will be reflected <br /> on
              your next billing date.
            </Trans>
          </Text>
        </Stack>
        <Stack spacing="4">
          <Button
            colorScheme="brand"
            size="xl"
            onClick={setSubscription}
            isLoading={loading}
            loadingText={t('views.shared.button.confirm')}
          >
            {t('views.features.domains.index.confirm')}
          </Button>
          <Button variant="link" size="xl" py="3" onClick={onClose}>
            {t('views.shared.button.discard')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}
