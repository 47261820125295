import { Box, Flex, Text } from '@chakra-ui/layout'
import { useRadio, UseRadioProps } from '@chakra-ui/radio'
import { Image } from '@chakra-ui/image'
import { useTranslation } from 'react-i18next'

import { ConsentModel } from '../../types'

import { RecommendedBadge } from '@/features/domains/Customize/components'

import OptIn from '@/images/project/manage/opt-in.png'
import OptOut from '@/images/project/manage/opt-out.png'
import NoticeOnly from '@/images/project/manage/notice-only.png'
import DefaultNoticeOnly from '@/images/project/manage/default-notice-only.png'

type ConsentModelRadioProps = UseRadioProps & {
  isBannerModelNoticeOnly: boolean
}

export const ConsentModelRadio = (props: ConsentModelRadioProps) => {
  const { value, isBannerModelNoticeOnly, isChecked } = props

  const { t: tDomains } = useTranslation('domains')

  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  const radioValue = value as ConsentModel
  const currentModel = consentConstant[radioValue]
  const isDisabled = radioValue !== 'notice_only' && isBannerModelNoticeOnly

  return (
    <Box
      {...checkbox}
      as="label"
      sx={{
        cursor: 'pointer',
        p: 4,
        borderRadius: 'md',
        bg: 'white',
        border: '1px solid',
        borderColor: 'gray.200',
        transition: 'background-color 0.2s, border-color 0.2s',
        _checked: {
          bg: 'brand.50',
          borderColor: 'brand.400',
        },
        ...(isDisabled && {
          cursor: 'not-allowed',
          color: 'gray.500',
          bg: 'gray.100',
        }),
      }}
    >
      <input {...input} disabled={isDisabled} />

      <Flex
        align="center"
        justify="space-between"
        sx={{ fontWeight: 'semibold' }}
      >
        {currentModel.title}
        {radioValue === 'opt_in' && (
          <RecommendedBadge isDisabled={isDisabled} />
        )}
      </Flex>
      <Image
        src={
          radioValue === 'notice_only' && isBannerModelNoticeOnly
            ? NoticeOnly
            : currentModel.image
        }
        alt={currentModel.title}
        sx={{ mt: 3 }}
      />
      <Text
        sx={{
          fontSize: 'xs',
          mt: 3,
          ...(isChecked && { color: 'brand.400' }),
        }}
      >
        {tDomains(currentModel.description)}
      </Text>
    </Box>
  )
}

type ConsentModelRadioItem = {
  title: string
  image: any
  description: string
}

const consentConstant: Record<ConsentModel, ConsentModelRadioItem> = {
  opt_in: {
    title: 'Opt-In',
    image: OptIn,
    description: 'manage.category.optInDesc',
  },
  opt_out: {
    title: 'Opt-Out',
    image: OptOut,
    description: 'manage.category.optOutDesc',
  },
  notice_only: {
    title: 'Notice Only',
    image: DefaultNoticeOnly,
    description: 'manage.category.noticeOnlyDesc',
  },
}
