import { t } from '@/lib/helpers'
import { Stack, Text, HStack, Link, Image, Icon } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'

import EnFlag from '@/icons/flags/en.svg'
import ThFlag from '@/icons/flags/th.svg'

export type NavLanguageSectionProps = {
  lang: PlatformLanguage
  onChangeLang: (lang: PlatformLanguage) => void
}

export const NavLanguageSection = (props: NavLanguageSectionProps) => {
  const { onChangeLang } = props

  return (
    <Stack>
      <Text
        sx={{
          color: 'gray.600',
          fontSize: '12px',
          textTransform: 'uppercase',
          fontWeight: 600,
          lineHeight: 1,
        }}
      >
        {t('views.misc.languages', undefined, 'Languages')}
      </Text>
      <HStack spacing="12px">
        {languages.map((lang) => {
          const isActive = lang.value === props.lang

          return (
            <Link
              key={lang.value}
              sx={{
                position: 'relative',
              }}
              onClick={() => {
                onChangeLang(lang.value)
              }}
              id={`UserMenuLanguageButton-${lang.value}`}
            >
              <Image src={lang.img} />

              {isActive && (
                <Icon
                  as={FaCheckCircle}
                  boxSize="12px"
                  color="green.400"
                  sx={{
                    pos: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bg: 'white',
                    borderRadius: '50%',
                  }}
                />
              )}
            </Link>
          )
        })}
      </HStack>
    </Stack>
  )
}

const languages: {
  value: PlatformLanguage
  img: any
}[] = [
  {
    value: 'en',
    img: EnFlag,
  },
  {
    value: 'th',
    img: ThFlag,
  },
]
