import { useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { PROJECT } from '../constants'
import { getPathParams } from '@/lib/routes'

type Params = {
  project_id: number
}

const getProject = async (params: Params): Promise<Project> => {
  const { project_id } = params

  const response = await fetchAPI<Project>({
    path: `/projects/${project_id}`,
  })

  return response.data
}

export const useProject = ({
  params,
  initialData,
}: {
  params: Params
  initialData: Project
}) => {
  return useQuery<Project, AxiosError>({
    queryKey: [PROJECT, params],
    queryFn: () => getProject(params),
    initialData,
  })
}

export const useProjectData = () => {
  const queryClient = useQueryClient()

  const query = getPathParams()
  const params = { project_id: Number(query?.project_id) }

  return queryClient.getQueryData<Project>([PROJECT, params])
}
