import {
  Box,
  Button,
  Text,
  Stack,
  Image,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { CreditCard } from '../../types'
import { useUpdatePaymentStore } from '../../stores'

import Success from '@/images/cookies/cookie-success.svg'
import { t } from '@/lib/helpers'
import { Modal } from '@/components/shared'

type DeleteCardSuccessModalProps = {
  disclosure: UseDisclosureReturn
  deleteCard: CreditCard
}

export const DeleteCardSuccessModal = (props: DeleteCardSuccessModalProps) => {
  const { disclosure, deleteCard } = props
  const { isOpen, onClose } = disclosure
  const { t: tPayment } = useTranslation('payment')
  const {
    isDeletePrimary,
    newSubscriptionCard,
    setIsDeletePrimary,
    setNewSubscriptionCard,
  } = useUpdatePaymentStore()
  const newCardNumber = useMemo(() => {
    if (newSubscriptionCard) {
      return `XXXX XXXX XXXX ${newSubscriptionCard.data.last4}`
    }

    return undefined
  }, [JSON.stringify(newSubscriptionCard)])

  const onCloseModal = () => {
    onClose()
    if (isDeletePrimary) {
      window.location.reload()
    }
  }

  const renderDescription = () => {
    if (isDeletePrimary && newCardNumber) {
      return (
        <Trans t={tPayment} i18nKey={'deleteCreditCard.hasCard'}>
          ระบบได้ลบบัตรเครดิตหมายเลข&nbsp;
          {{ deleteCard: deleteCard.number }}
          &nbsp;เรียบร้อยแล้ว ระบบจะใช้บัตรเครดิตหมายเลข&nbsp;
          {{ newCard: newCardNumber }}&nbsp;
          เป็นบัตรเครดิตเริ่มต้นในการชำระเงินสำหรับรอบบิลถัดไป
        </Trans>
      )
    }

    if (isDeletePrimary) {
      return (
        <Trans t={tPayment} i18nKey={'deleteCreditCard.noCardLeft'}>
          ระบบได้ลบบัตรเครดิตหมายเลข {{ card: deleteCard.number }}&nbsp;
          เรียบร้อยแล้ว หากสิ้นสุดระยะเวลาการใช้งานแพ็กเกจของคุณ
          คุณจะไม่สามารถใช้งาน Cookie Wow ได้ต่อ
        </Trans>
      )
    }

    return (
      <Trans t={tPayment} i18nKey={'deleteCreditCard.description'}>
        ระบบได้ลบบัตรเครดิตหมายเลข {{ card: deleteCard.number }}&nbsp;
        เรียบร้อยแล้ว
      </Trans>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} maxW="524px" isCentered>
      <Stack spacing="9" mt="12" px="8">
        <Image
          boxSize="140px"
          objectFit="cover"
          src={Success}
          sx={{ margin: 'auto' }}
        />
        <Box sx={{ textAlign: 'center' }}>
          <Text sx={{ fontSize: '24px', fontWeight: 700 }}>
            {t('views.features.payments.delete.modal.success.header')}
          </Text>
          <Text sx={{ mt: 2, color: 'gray.600' }}>{renderDescription()}</Text>
          <Text sx={{ color: 'gray.600', mt: 9 }}>
            {t('views.features.payments.delete.modal.success.footer.1')}
          </Text>
          <Text sx={{ color: 'brand.400', fontWeight: 600 }}>
            {t('views.features.payments.delete.modal.success.footer.2')}
          </Text>
          <Button
            colorScheme="brand"
            size="xl"
            sx={{ h: '56px', w: 'full', mt: 9 }}
            onClick={onCloseModal}
            loadingText={t('views.shared.button.confirm')}
          >
            {t('views.features.payments.delete.modal.success.close')}
          </Button>
        </Box>
      </Stack>
    </Modal>
  )
}
