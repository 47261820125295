import { createCtx } from '@/lib/helpers'
import { CurrentPackage } from '../types'
import { CurrentUser } from '../users/types'

type AppContextValue = {
  platformLanguage: PlatformLanguage
  currentPackage: CurrentPackage
  isFree: boolean
  quota: Quota
  currentUser: CurrentUser
}

export const [useAppContext, AppContextProvider] = createCtx<AppContextValue>()
