import {
  Text,
  Button,
  ModalBody,
  Stack,
  UseDisclosureReturn,
} from '@chakra-ui/react'

import { Modal, ModalHeader } from '@/components/shared'
import { useTranslation } from 'react-i18next'

type ConfirmCancelUpdateEmailModalProps = {
  disclosure: UseDisclosureReturn
  isLoading: boolean
  onConfirm: () => void
}

export const ConfirmCancelUpdateEmailModal = (
  props: ConfirmCancelUpdateEmailModalProps,
) => {
  const { disclosure, onConfirm, isLoading } = props
  const { isOpen, onClose } = disclosure

  const { t: tSettings } = useTranslation('settings')
  const { t: tCommon } = useTranslation('common')

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        {tSettings('confirmCancelUpdateEmailModal.title')}
        <Text sx={{ mt: 1, color: 'gray.600', fontSize: 'md' }}>
          {tSettings('confirmCancelUpdateEmailModal.description')}
        </Text>
      </ModalHeader>

      <ModalBody>
        <Stack>
          <Button size="xl" onClick={onConfirm} isLoading={isLoading}>
            {tCommon('confirm')}
          </Button>
          <Button
            colorScheme="gray"
            onClick={onClose}
            size="xl"
            sx={{ color: 'gray.600' }}
            variant="ghost"
          >
            {tCommon('discard')}
          </Button>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
