import { Box, Checkbox, Flex, Text } from '@chakra-ui/react'
import { Trans } from 'react-i18next'

import { t } from '@/lib/helpers'

type ContentType = {
  packageName: string
  limit: number | null
}

export const DomainContent = ({ packageName, limit }: ContentType) => {
  return (
    <Box sx={{ color: 'red.400' }}>
      <Box>
        <Trans i18nKey="features.migration.choose_domains">
          ปัจจุบันคุณใช้&nbsp;
          <Text as="span" sx={{ textTransform: 'capitalize' }}>
            {{ name: packageName }}
          </Text>
          &nbsp;สามารถใช้งานได้สูงสุด&nbsp;
          <Text as="span">
            {{
              limit: limit || t('views.features.packages.packages.unlimited'),
            }}
          </Text>
          &nbsp;โดเมน
        </Trans>

        <Flex align="baseline" d="inline-flex" px="4px">
          <Checkbox isChecked={true} readOnly size="lg" />
        </Flex>

        <Text as="span">
          <Trans i18nKey="features.migration.please_select_domains">
            โปรดเลือกโดเมนที่คุณต้องการใช้งานต่อ
          </Trans>
        </Text>
      </Box>

      <Text>{t('views.features.migration.index.choose_domains_caution')}</Text>
    </Box>
  )
}

export const UserContent = ({ packageName, limit }: ContentType) => {
  return (
    <Box sx={{ color: 'red.400' }}>
      <Box>
        <Trans i18nKey="features.migration.choose_users">
          ปัจจุบันคุณใช้&nbsp;
          <Text as="span" sx={{ textTransform: 'capitalize' }}>
            {{ name: packageName }}
          </Text>
          &nbsp;สามารถมีผู้ใช้งานได้สูงสุด&nbsp;
          <Text as="span">
            {{
              limit: limit || t('views.features.packages.packages.unlimited'),
            }}
          </Text>
          &nbsp;คน
        </Trans>

        <Flex align="baseline" d="inline-flex" px="4px">
          <Checkbox isChecked={true} readOnly size="lg" />
        </Flex>

        <Text as="span">
          <Trans i18nKey="features.migration.please_select_users">
            กรุณาเลือกผู้ใช้งานที่จะสามารถใช้แพลตฟอร์มนี้ได้
          </Trans>
        </Text>
      </Box>

      <Text>{t('views.features.migration.index.choose_users_caution')}</Text>
    </Box>
  )
}
