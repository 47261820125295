import { Link, Stack, Text, Textarea, FormHelperText } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { t } from '@/lib/helpers'

import { useAppContext } from '@/features/layout'
import { UpgradeText, FormControl } from '../../shared'

export const CssCustomization = () => {
  const { isFree } = useAppContext()
  const { register } = useFormContext()

  return (
    <Stack spacing={4}>
      <Text>
        <Trans i18nKey={'features.domains.customize.how_to_use_css'}>
          For Cookie banner CSS Customization, please visit
          <Link
            href="https://help.cookiewow.com/th/article/cookie-banner-css-1298ujr/"
            isExternal
            sx={{
              color: 'brand.400',
              textDecor: 'underline',
            }}
          >
            here
          </Link>
        </Trans>
      </Text>

      <UpgradeText linkID="CustomizeCss-upgrade" />

      <Stack spacing="14px">
        <FormControl
          id={'custom_css'}
          label={t(
            'views.features.domains.show.customize.add_your_css',
            undefined,
            'Add your custom CSS here',
          )}
          disabled={isFree}
        >
          <FormHelperText>
            {t(
              'views.features.domains.show.customize.font_family_not_appear',
              undefined,
              'Font fam wont appear here',
            )}
          </FormHelperText>
          <Textarea
            {...(!isFree && register('custom_css'))}
            sx={{ minH: '329px' }}
            id="CustomizeCss-css"
            disabled={isFree}
          />
        </FormControl>
      </Stack>
    </Stack>
  )
}
