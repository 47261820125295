import { FormProvider, useForm } from 'react-hook-form'
import { Stack } from '@chakra-ui/layout'
import { Input } from '@chakra-ui/input'
import { Button } from '@chakra-ui/button'
import { Select } from '@chakra-ui/select'
import { Textarea } from '@chakra-ui/textarea'

import { t } from '@/lib/helpers'
import { useCookieCategories } from '../../queries'

import { FormControl } from '@/components/FormControl'
import { LanguageDependantInput } from '../../Manage/components'

import { BannerLanguage, Category, ScriptFormInput } from '../../types'
import { INITIAL_MULTI_LANG_FIELD } from '../../constants'

type Props = {
  currentCategory?: Category
  defaultValues?: ScriptFormInput
  isLoading: boolean
  onCancel: () => void
  onSubmitForm: (data: ScriptFormInput) => void
  activeLanguage: BannerLanguage
}

export const ManageScriptForm = (props: Props) => {
  const {
    activeLanguage,
    currentCategory,
    defaultValues,
    isLoading,
    onCancel,
    onSubmitForm,
  } = props

  const { data: cookieCategories } = useCookieCategories()

  const method = useForm<ScriptFormInput>({
    defaultValues: {
      ...defaultValues,
      purpose: defaultValues?.purpose ?? INITIAL_MULTI_LANG_FIELD,
      category_id: `${currentCategory?.id || defaultValues?.category_id}`,
    },
  })
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = method

  const onSubmit = (data: ScriptFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="24px">
          <FormControl
            isRequired
            id="name"
            label={t('views.features.domains.show.manage.form.script_name')}
            errorMsg={errors.name?.message}
          >
            <Input
              {...register('name')}
              placeholder={t(
                'views.features.domains.show.manage.form.script_name',
              )}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="category_id"
            label={t('views.features.domains.show.manage.form.category')}
            isRequired
            sx={{ flex: 1 }}
            errorMsg={errors.category_id?.message}
          >
            <Select {...register('category_id')} size="lg">
              {cookieCategories?.map((cat) => {
                return (
                  <option key={cat.id} value={cat.id}>
                    {cat.name[activeLanguage]}
                  </option>
                )
              })}
            </Select>
          </FormControl>

          <FormControl
            isRequired
            id="script_url"
            label={t('views.features.domains.show.manage.form.script_url')}
            errorMsg={errors.url?.message}
          >
            <Textarea
              {...register('url')}
              placeholder={t(
                'views.features.domains.show.manage.form.script_url',
              )}
              size="lg"
              sx={{ minH: '124px' }}
            />
          </FormControl>

          <FormControl
            isRequired
            id="description"
            label={t('views.features.domains.show.manage.form.description')}
          >
            <LanguageDependantInput
              activeLanguage={activeLanguage}
              id="purpose"
              name="purpose"
              useTextArea
              placeholder={t(
                'views.features.domains.show.manage.form.description_script_placeholder',
              )}
              size="lg"
              sx={{ minH: '124px' }}
            />
          </FormControl>

          <Stack spacing="8px" alignItems="stretch">
            <Button
              id={
                defaultValues
                  ? 'submit_editScript_button'
                  : 'submit_addScript_button'
              }
              size="xl"
              type="submit"
              isLoading={isLoading}
            >
              {defaultValues
                ? t('views.features.domains.show.manage.form.edit_script')
                : t('views.features.domains.show.manage.form.create_script')}
            </Button>
            <Button
              variant="ghost"
              colorScheme="gray"
              color="gray.600"
              size="xl"
              onClick={onCancel}
            >
              {t('views.shared.button.discard')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}
