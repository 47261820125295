import { useState, useEffect } from 'react'
import * as Cookies from 'js-cookie'
import {
  AspectRatio,
  Box,
  Button,
  Checkbox,
  Flex,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FiX } from 'react-icons/fi'
import { SvgImage } from '@/components/DataDisplay'

import { useTranslation } from 'react-i18next'
import { logEvent } from '@/lib/stats'

import referralLeaves from '@/images/referral/referral-leaves.svg'
import referralMan from '@/images/referral/referral-man.svg'

import { REFERRAL_FORM } from '@/constants/links'
import { COOKIE_HIDE_REFERRAL_BANNER } from './constants'

export const ReferralBanner = (): React.ReactElement | null => {
  const { t: tReferral } = useTranslation('referral')

  const [isBannerVisible, setIsBannerVisible] = useState(true)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

  useEffect(() => {
    const hideReferralBannerCookie = Cookies.get(COOKIE_HIDE_REFERRAL_BANNER)
    if (hideReferralBannerCookie) {
      setIsBannerVisible(false)
    }
  }, [])

  if (!isBannerVisible) return null

  return (
    <Flex
      sx={{
        w: '655px',
        h: '204px',
        pos: 'relative',
        borderRadius: '16px',
        bg: 'transparent',
      }}
    >
      <Box sx={{ pos: 'absolute', boxSize: 'full', zIndex: 1 }}>
        <AspectRatio
          ratio={227 / 203}
          sx={{
            pos: 'absolute',
            left: 0,
            bottom: 0,
            borderBottomLeftRadius: '16px',
            overflow: 'hidden',
            w: '227px',
          }}
        >
          <SvgImage
            src={referralMan}
            sx={{
              layout: 'fill',
              objectFit: 'contain',
              alt: 'Referral Man',
            }}
          />
        </AspectRatio>

        <AspectRatio
          ratio={122 / 88}
          sx={{
            pos: 'absolute',
            right: 0,
            bottom: 0,

            w: '122px',
          }}
        >
          <SvgImage
            src={referralLeaves}
            sx={{
              layout: 'fill',
              objectFit: 'contain',
              alt: 'Referral Leaves',
            }}
          />
        </AspectRatio>
      </Box>

      <Flex
        sx={{
          w: 'full',
          borderRadius: '16px',
          bg: '#FFF1E3',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
          p: '18px 28px',
          h: '148px',
          mt: 'auto',
          position: 'relative',
        }}
      >
        <Button
          variant={'unstyled'}
          sx={{
            position: 'absolute',
            top: -8,
            right: 1,
            display: 'flex',
            alignItems: 'center',
            textTransform: 'capitalize',
            gap: 1,
            height: 'auto',
            zIndex: 1,
          }}
          onClick={() => {
            if (isCheckboxChecked) {
              const now = new Date()
              const tomorrow = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 1,
              )
              Cookies.set(COOKIE_HIDE_REFERRAL_BANNER, 'true', {
                expires: tomorrow,
              })
            }
            setIsBannerVisible(false)
          }}
        >
          <Text fontSize="12px" fontFamily="Anakotmai" fontWeight={500}>
            Close
          </Text>
          <FiX style={{ width: '16px' }} />
        </Button>

        <Checkbox
          sx={{
            position: 'absolute',
            bottom: -8,
            left: 1,
          }}
          isChecked={isCheckboxChecked}
          onChange={(e) => setIsCheckboxChecked(e.target.checked)}
        >
          <Text fontSize="12px" fontFamily="Anakotmai">
            Don’t show again within today
          </Text>
        </Checkbox>

        <Stack
          spacing="7px"
          sx={{
            textAlign: 'center',
            w: '425px',
            mx: 'unset',
            ml: 'auto',
            alignItems: 'center',
            fontFamily: 'Anakotmai',
            zIndex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: '22px',
            }}
          >
            {tReferral('banner.joinReferral')}
          </Text>
          <Text fontSize="16px">{tReferral('banner.justShare')}</Text>

          <Link isExternal href={REFERRAL_FORM}>
            <Button
              sx={{
                bg: '#5e4545',
                w: '140px',
                _hover: {
                  bg: '#483f37',
                },
                _active: {
                  bg: '#2d261f',
                },
              }}
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'Referral Program',
                    action: 'Click Referral Program Banner',
                  },
                })
              }}
            >
              {tReferral('banner.joinNow')}
            </Button>
          </Link>
        </Stack>
      </Flex>
    </Flex>
  )
}
