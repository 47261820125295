import {
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Square,
} from '@chakra-ui/react'
import { ChromePicker } from 'react-color'
import { useState, memo } from 'react'

import { getOpacity } from '@/lib/helpers'

export type CustomColorPickerInputProps = {
  id: string
  isDisabled?: boolean
  value: string
  onChange: (...event: any[]) => void
}

export const CustomColorPickerInput = memo(
  (props: CustomColorPickerInputProps): React.ReactElement => {
    const { id, isDisabled, value, onChange } = props

    const [isOpen, setIsOpen] = useState(false)
    const open = () => setIsOpen(!isOpen)
    const close = () => setIsOpen(false)

    return (
      <Popover
        placement="bottom-start"
        isOpen={isOpen}
        onClose={close}
        returnFocusOnClose={false}
      >
        <InputGroup size="lg">
          <InputLeftElement
            children={
              <PopoverTrigger>
                <Square
                  cursor={isDisabled ? 'not-allowed' : 'pointer'}
                  size="32px"
                  bg={isDisabled ? '#e8e8e8' : value}
                  sx={{
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: 'gray.300',
                  }}
                  onClick={isDisabled ? undefined : open}
                />
              </PopoverTrigger>
            }
          />
          <Input
            id={id}
            sx={{
              textTransform: 'uppercase',
              borderRadius: '4px',
              borderColor: 'gray.300',
              ...(isDisabled && {
                _disabled: {
                  bg: 'gray.100',
                  color: 'gray.400',
                  cursor: 'not-allowed',
                  borderColor: 'gray.300',
                },
              }),
            }}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </InputGroup>

        <PopoverContent sx={{ w: '225px' }}>
          <ChromePicker
            className={`${id}-color-picker-wrapper`}
            color={value}
            onChange={(colorResult) => {
              const { hex, rgb } = colorResult
              const value = getOpacity(hex, rgb?.a ?? 1)

              onChange(value)
            }}
          />
        </PopoverContent>
      </Popover>
    )
  },
)
