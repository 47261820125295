import { Button } from '@chakra-ui/button'
import { Image } from '@chakra-ui/image'
import { ModalBody, ModalHeader } from '@chakra-ui/modal'
import { Stack, Text } from '@chakra-ui/layout'
import { UseDisclosureReturn } from '@chakra-ui/hooks'

import { Modal } from '@/components/shared'
import GarbageIcon from '@/icons/garbage.svg'

import { t } from '@/lib/helpers'
import { useDeleteCategory } from '../../mutations'
import { getPathParams } from '@/lib/routes'

type Props = {
  categoryId: number
  disclosure: UseDisclosureReturn
  name: string
  setDefaultCategory: () => void
}

export const DeleteCategoryModal = (props: Props) => {
  const { disclosure, name, categoryId, setDefaultCategory } = props
  const { isOpen, onClose } = disclosure

  const { project_id } = getPathParams()
  const params = {
    project_id: Number(project_id),
    category_id: categoryId,
  }

  const { mutate: deleteCategory, isLoading } = useDeleteCategory()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton maxW="574px">
      <Stack justify="center" sx={{ pt: '48px' }}>
        <Image src={GarbageIcon} h="72px" />
      </Stack>

      <ModalHeader
        sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 400 }}
      >
        {t('views.features.domains.show.manage.delete')}&nbsp;
        <Text as="span" fontWeight={600}>
          {name}
        </Text>
        &nbsp;?
      </ModalHeader>

      <ModalBody>
        <Stack spacing="20px">
          <Text sx={{ textAlign: 'center', px: '24px' }}>
            {t('views.features.domains.show.manage.no_block_cookie_cat')}
          </Text>

          <Stack>
            <Button
              id="Category-deleted"
              size="xl"
              onClick={() => {
                deleteCategory(
                  { params },
                  {
                    onSuccess() {
                      setDefaultCategory()
                      onClose()
                    },
                  },
                )
              }}
              isLoading={isLoading}
            >
              {t('views.shared.button.delete')}
            </Button>
            <Button
              variant="ghost"
              colorScheme="gray"
              color="gray.600"
              onClick={onClose}
              size="xl"
            >
              {t('views.shared.button.discard')}
            </Button>
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
