import AuthLayout from '@/features/auth/layouts/AuthLayout'
import SignInForm from '@/features/auth/components/SignInForm'

import Image from '@/images/project/auth/sign_in.svg'

import { t } from '@/lib/helpers'
import { withAuthPage } from '@/features/layout'

type Props = {
  rememberable: boolean
  formActionPath: string
}

function SignIn(props: Props): React.ReactElement {
  return (
    <AuthLayout image={Image} header={t('devise.sessions.new.sign_in')}>
      <SignInForm {...props} />
    </AuthLayout>
  )
}

export default withAuthPage(SignIn)
