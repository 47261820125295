import th from './th'
import en from './en'

import errorsTH from './th/errors'
import errorsEN from './en/errors'
import commonTH from './th/common'
import commonEN from './en/common'
import unitTH from './th/unit'
import unitEN from './en/unit'

import authTH from './th/auth'
import authEN from './en/auth'

import domainsTH from './th/domains'
import domainsEN from './en/domains'

import domainConsentTH from './th/domainConsent'
import domainConsentEN from './en/domainConsent'
import domainCustomizeTH from './th/domainCustomize'
import domainCustomizeEN from './en/domainCustomize'
import domainSettingsTH from './th/domainSettings'
import domainSettingsEN from './en/domainSettings'
import domainScannerTH from './th/domainScanner'
import domainScannerEN from './en/domainScanner'

import freeTrialTH from './th/free-trial'
import freeTrialEN from './en/free-trial'

import packagesTH from './th/packages'
import packagesEN from './en/packages'

import paymentTH from './th/payment'
import paymentEN from './en/payment'

import referralTH from './th/referral'
import referralEN from './en/referral'

import settingsTH from './th/settings'
import settingsEN from './en/settings'

export default {
  th: {
    translation: {
      ...th,
    },
    auth: authTH,
    common: commonTH,
    domains: domainsTH,
    domainConsent: domainConsentTH,
    domainCustomize: domainCustomizeTH,
    domainSettings: domainSettingsTH,
    domainScanner: domainScannerTH,
    freeTrial: freeTrialTH,
    packages: packagesTH,
    errors: errorsTH,
    payment: paymentTH,
    unit: unitTH,
    referral: referralTH,
    settings: settingsTH,
  },
  en: {
    translation: {
      ...en,
    },
    auth: authEN,
    common: commonEN,
    domains: domainsEN,
    domainConsent: domainConsentEN,
    domainCustomize: domainCustomizeEN,
    domainSettings: domainSettingsEN,
    domainScanner: domainScannerEN,
    freeTrial: freeTrialEN,
    packages: packagesEN,
    errors: errorsEN,
    payment: paymentEN,
    unit: unitEN,
    referral: referralEN,
    settings: settingsEN,
  },
}
