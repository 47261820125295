import { Stack, StackDivider, Text } from '@chakra-ui/react'

import { withPage } from '@/features/layout'
import { SettingLayout } from '../../components'

import { TwoFactorStatus } from '@/features/2fa/types'

import { ChangePasswordContainer } from '@/features/users/containers'
import { TwoFactorSetting } from '@/features/2fa/containers'
import { PasswordExpirationSection } from '../../containers'

import { useTwoFactorStatus } from '@/features/2fa/queries'

import { t } from '@/lib/helpers'
import { Company } from '@/features/users/types'

type Props = {
  company: Company
} & TwoFactorStatus

function SecuritySettingPage(props: Props) {
  const { password_required, two_factor_enabled, company } = props

  const { data: twoFactorStatus } = useTwoFactorStatus({
    password_required,
    two_factor_enabled,
    company,
  })

  return (
    <SettingLayout>
      <Stack
        spacing="6"
        sx={{ w: '50%' }}
        divider={<StackDivider borderColor="gray.200" />}
      >
        <Stack spacing="4">
          <Text sx={{ fontWeight: 700 }}>
            {t('views.features.two_factor.title')}
          </Text>

          {twoFactorStatus && (
            <TwoFactorSetting
              is2FactorEnabled={twoFactorStatus.two_factor_enabled}
              isPasswordRequired={twoFactorStatus.password_required}
            />
          )}
        </Stack>

        <ChangePasswordContainer />

        <PasswordExpirationSection
          expirePasswordAfterDays={
            twoFactorStatus?.company.expire_password_after_days ||
            company.expire_password_after_days
          }
        />
      </Stack>
    </SettingLayout>
  )
}

export default withPage(SecuritySettingPage)
