import { Fragment } from 'react'
import { Box, Heading, Image, Stack, Text } from '@chakra-ui/react'

import UnsubImage from '@/images/email-unsubscribe/unsub.png'
import { EmailUnsubscribeForm } from '../../components/EmailUnsubscribeForm'

import { useEmailUnsubscribe } from '../../mutations'

type Props = {
  email_type: 'promotion' | 'knowledge'
  onSuccess: () => void
  reasons: Record<string, string>
  token: string
}

export const EmailUnsubscribe = (props: Props): React.ReactElement => {
  const { email_type, reasons, token, onSuccess } = props

  const { mutate, isLoading } = useEmailUnsubscribe()

  return (
    <Fragment>
      <Image src={UnsubImage} alt="resub-image" />
      <Box sx={{ p: '36px 40px' }}>
        <Stack sx={{ mb: '24px' }} spacing={4}>
          <Heading sx={{ textAlign: 'center', fontSize: '24px' }}>
            ยกเลิกการรับอีเมลจาก Cookie Wow
          </Heading>
          <Text>
            เราเสียใจเป็นอย่างยิ่งที่คุณต้องการยกเลิกรับข่าวสารจากเรา กดที่ปุ่ม
            &apos;ยืนยัน&apos; ด้านล่าง เพื่อหยุดรับอีเมล&nbsp;
            {email_type.toTitleCase()} จาก Cookie Wow
          </Text>
        </Stack>

        <EmailUnsubscribeForm
          reasons={reasons}
          onSubmit={(data) => {
            mutate(
              { data: { subscription: token, ...data } },
              {
                onSuccess,
              },
            )
          }}
          isLoading={isLoading}
        />
      </Box>
    </Fragment>
  )
}
