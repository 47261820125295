import { z } from 'zod'

import { t } from '@/lib/helpers'

export const URL_REGEX = /^https?:\/\/[^\s]+$/

export const domainSchema = z.object({
  folder_name: z.string().min(1, {
    message: t('views.shared.input.required', undefined, 'Required'),
  }),
  url: z.string().regex(URL_REGEX, {
    message: t('views.shared.form.invalid_url', undefined, 'Invalid URL'),
  }),
})
