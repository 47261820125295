import * as d3 from 'd3-format'
import { Fragment, useMemo } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { get } from 'lodash'

import DefaultPageViewTable from './DefaultPageViewTable'

import { Column } from 'react-table'
import { DataTable } from '@/components/DataDisplay/Table'
import { PageView } from '../../types'
import { PaginationFilter } from '@/features/filters/components'
import { t } from '@/lib/helpers'
import { usePageViews } from '../../queries/getPageView'

function PageViewTable() {
  const { data: pageViewData, isFetched } = usePageViews()

  const columns = useMemo<Array<Column<PageView>>>(() => {
    return [
      {
        Header: t('views.features.packages.page_views.url'),
        accessor: 'domain',
        sortKey: 'domain',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return <Text sx={{ color: 'gray.900' }}>{value}</Text>
        },
      },
      {
        Header: t('views.features.packages.page_views.date_range'),
        accessor: 'date_range',
        sortKey: 'date_range',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return <Text sx={{ color: 'gray.900' }}>{value}</Text>
        },
      },
      {
        Header: t('views.features.packages.page_views.page_views_count'),
        accessor: 'page_views_count',
        sortKey: 'page_views_count',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return (
            <Text sx={{ color: 'gray.900' }}>{d3.format(',d')(value)}</Text>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return pageViewData?.data ?? []
  }, [pageViewData?.data])

  if (memoizedData.length === 0 && isFetched) {
    return <DefaultPageViewTable />
  }

  return (
    <Fragment>
      <DataTable<PageView>
        isLoading={!isFetched}
        data={memoizedData}
        columns={columns}
        tableStyle={{ minH: '264px' }}
      />

      {pageViewData?.meta.total && pageViewData?.meta.per && (
        <Flex sx={{ justifyContent: 'center', paddingBottom: '-100px' }}>
          <PaginationFilter
            totalPage={Math.ceil(
              pageViewData.meta.total / pageViewData.meta.per,
            )}
          />
        </Flex>
      )}
    </Fragment>
  )
}

export default PageViewTable
