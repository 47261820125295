import { useTab, chakra, forwardRef, useStyles } from '@chakra-ui/react'

const StyledTab = chakra('button', {
  baseStyle: {
    border: '1px solid',
    borderColor: 'gray.200',
    borderRadius: '4px',
    fontWeight: 600,
    w: '332px',
    fontSize: 'sm',
    textAlign: 'start',
    h: '56px',
    _notFirst: {
      mt: 2,
    },
  },
})

export const CustomTab = forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref })
  const isSelected = !!tabProps['aria-selected']
  const styles = useStyles()

  return (
    <StyledTab
      __css={styles.tab}
      sx={{
        ...(isSelected && {
          color: 'white',
          bg: 'brand.400',
        }),
        _notFirst: {
          mt: 2,
        },
      }}
      {...tabProps}
    >
      {tabProps.children}
    </StyledTab>
  )
})
