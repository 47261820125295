import { z } from 'zod'

import { PACKAGE_NAMES_IN_USE } from '../payments/constants'
import { RECURRING_TYPES, TAX_PAYER_TYPES } from './constants'

export const freeTrialFormSchema = z.object({
  recurring: z.enum(RECURRING_TYPES),
  package: z.enum(PACKAGE_NAMES_IN_USE),
  card_name: z.string(),
  name: z.string(),
  tax_id: z
    .string()
    .length(13, { message: 'เลขประจำตัวผู้เสียภาษีไม่ถูกต้อง' }),
  taxpayer_type: z.enum(TAX_PAYER_TYPES),
  address: z.string(),
  code: z.string().optional(),
})
