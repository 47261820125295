export * from './queryKeys'

import { ConsentAction } from '../types'
import { DefaultCategorySlug } from '../../Manage/types'

export const pieChartStyle = {
  animate: true,
  labelPosition: 109,
  lineWidth: 26,
  paddingAngle: 3,
  radius: 34,
  startAngle: 90,
  segmentsStyle: { transition: 'stroke .3s' },
  labelStyle: {
    fontSize: '4.5px',
  },
}

export const byStatusColor: Record<ConsentAction, string> = {
  accepted_all: '#00C4A8',
  rejected_all: '#FF5E5E',
  partially_accepted: '#5BDFCC',
  informed_all: '#F2AF8C',
  dismissed_all: '#FFEDE0',
}

export const byCategoryColor: Record<DefaultCategorySlug, string> = {
  necessary: '#EA834F',
  analytics: '#7887FF',
  marketing: '#FACEB8',
}

export const consentActions = [
  'accepted_all',
  'rejected_all',
  'dismissed_all',
  'informed_all',
  'partially_accepted',
]
