import { memo } from 'react'
import { Box, chakra } from '@chakra-ui/react'

export * from './Modal'
export * from './TabNavigation'

export const ColorDot = memo(
  ({ color, size = '15px' }: { color: string; size?: string }) => (
    <Box
      sx={{
        borderRadius: '50%',
        boxSize: size,
        bg: color,
      }}
    />
  ),
)

export const Span = chakra('span')
