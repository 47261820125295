import {
  VStack,
  Box,
  Image,
  Text,
  Flex,
  Button,
  Link,
  Icon,
} from '@chakra-ui/react'
import { FaHome } from 'react-icons/fa'

import '@/features/i18n'

import { Footer, NavBar } from '@/components/Layout'
import { t } from '@/lib/helpers'

import { homeURL } from '@/features/auth/constants/links'

import logo from '@/images/icons/cookiewow.svg'

type Props = {
  children: React.ReactNode
  image: string
  header: string
  subHeader?: string
  showBackHome?: boolean
}

function AuthLayout({
  children,
  image,
  header,
  subHeader,
  showBackHome = true,
}: Props): React.ReactElement {
  return (
    <Box
      sx={{
        h: '100vh',
        maxH: '100vh',
        maxW: '100vw',
        '& > *': { w: 'full' },
        color: 'gray.900',
        minW: '1024px',
      }}
    >
      <NavBar isShowMenus={false} />
      <Flex
        sx={{
          w: 'full',
          minH: 'calc(100% - 56px - 56px)',
          overflowX: 'hidden',
          overflowY: 'auto',
          '& > *': {
            flex: '0 0 50%',
          },
        }}
      >
        <Box
          sx={{
            w: 'full',
            boxShadow: '10px 0 24px 0 #efe2d5',
          }}
        >
          <Box
            sx={{
              mx: 'auto',
              w: 'full',
              maxW: '460px',
              pb: 8,
            }}
          >
            {showBackHome && (
              <Button
                as={Link}
                variant="ghost"
                leftIcon={<Icon as={FaHome} sx={{ boxSize: 5 }} />}
                sx={{ mt: 4 }}
                href={homeURL}
              >
                {t('views.shared.button.back_home')}
              </Button>
            )}
            <VStack
              spacing="4"
              sx={{
                w: 'full',
                alignItems: 'stretch',
                mt: showBackHome ? '60px' : '120px',
              }}
            >
              <Image src={logo} alt={header} sx={{ maxW: '264px' }} />
              <Box>
                <Text as="h1" sx={{ fontSize: '32px', fontWeight: 'bold' }}>
                  {header}
                </Text>
                {subHeader && (
                  <Text
                    as="h3"
                    sx={{ color: 'gray.600', fontSize: '20px', mb: 2 }}
                  >
                    {subHeader}
                  </Text>
                )}
              </Box>
              <Box>{children}</Box>
            </VStack>
          </Box>
        </Box>
        <Box
          sx={{
            w: 'full',
            minH: '100%',
            maxH: 'full',
            bgImage: `url("${image}")`,
            bgRepeat: 'no-repeat',
            bgSize: 'cover',
            bgPosition: 'center',
            zIndex: -1,
          }}
        />
      </Flex>
      <Footer />
    </Box>
  )
}

export default AuthLayout
