import { Link, Text, VStack } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'
import { Button } from '@chakra-ui/button'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import SuccessFeedbackImage from '@/images/free-trial/success-feedback.svg'

import { UnescapedTrans } from '@/components/UnescapedTrans'

import { formatDateTime } from '@/lib/helpers'
import { getQueryParam } from '@/lib/queryParams'

export const FreeTrialSuccess = (): React.ReactElement => {
  const { t } = useTranslation('freeTrial')

  const packageName = getQueryParam('package') as PackageNameInUse

  return (
    <VStack spacing={4}>
      <Image
        sx={{ boxSize: '126px' }}
        src={SuccessFeedbackImage}
        alt="success-feedback"
      />

      <VStack>
        <Text sx={{ fontSize: '24px' }}>{t('success.title')}</Text>
        <Text>
          <UnescapedTrans t={t} i18nKey="success.description">
            ช่วงทดลองใช้ฟรีของคุณสามารถใช้ได้ถึงวันที่&nbsp;
            <Text as="span" sx={{ fontWeight: 700, color: 'brand.400' }}>
              {{
                date: formatDateTime(
                  dayjs().add(packageName === 'large' ? 7 : 14, 'day'),
                  'DD/MM/YYYY',
                ),
              }}
            </Text>
            &nbsp;และคุณสามารถยกเลิกได้ตลอดเวลา
          </UnescapedTrans>
        </Text>
      </VStack>

      <Link href="/packages">
        <Button size="xl" sx={{ w: '368px' }}>
          {t('success.complete')}
        </Button>
      </Link>

      <Text sx={{ fontSize: 'sm' }}>{t('success.info')}</Text>
    </VStack>
  )
}
