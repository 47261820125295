import React, { useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { createCtx } from '@/lib/helpers'
import { withPage } from '@/features/layout'

import { useTransformCreditCards } from '../hooks'
import { CreditCard, UpdatePaymentPageProps, RawCreditCard } from '../types'
import { CurrentPackage } from '@/features/types'
import { PaymentProvider } from '../components'

type UpdatePaymentContextValue = {
  cardId: number | undefined
  creditCards: CreditCard[] | undefined
  currentPackage?: CurrentPackage
  subscriptionCreditCard: CreditCard | undefined
  isDeletePrimary: boolean
  manualPayment?: boolean
  newSubscriptionCard: RawCreditCard | undefined
  setCardId: React.Dispatch<React.SetStateAction<number | undefined>>
  setRawCreditCards: React.Dispatch<React.SetStateAction<RawCreditCard[]>>
  setIsDeletePrimary: React.Dispatch<React.SetStateAction<boolean>>
  setNewSubscriptionCard: React.Dispatch<
    React.SetStateAction<RawCreditCard | undefined>
  >
}

const updatePaymentStoreContext = createCtx<UpdatePaymentContextValue>()
const [, Provider] = updatePaymentStoreContext
export const [useUpdatePaymentStore] = updatePaymentStoreContext

export const withUpdatePaymentPage = (
  Component: React.ComponentType<UpdatePaymentPageProps>,
) =>
  withPage((props: UpdatePaymentPageProps) => {
    const {
      cards,
      stripePKey,
      subscriptionCard,
      currentPackage,
      manualPayment,
    } = props

    const stripePromise = useMemo(() => loadStripe(stripePKey), [stripePKey])

    return (
      <Elements stripe={stripePromise}>
        <PaymentProvider>
          <UpdatePaymentProvider
            cards={cards}
            subscriptionCard={subscriptionCard}
            currentPackage={currentPackage}
            manualPayment={manualPayment}
          >
            <Component {...props} />
          </UpdatePaymentProvider>
        </PaymentProvider>
      </Elements>
    )
  })

type UpdatePaymentProviderProps = {
  children: React.ReactNode
  cards: RawCreditCard[]
  subscriptionCard: RawCreditCard
  currentPackage?: CurrentPackage
  manualPayment?: boolean
}

export const UpdatePaymentProvider = (props: UpdatePaymentProviderProps) => {
  const { cards, currentPackage, subscriptionCard, manualPayment, children } =
    props

  const [cardId, setCardId] = useState<number | undefined>(subscriptionCard?.id)

  const [rawCreditCards, setRawCreditCards] = useState(cards)
  const [isDeletePrimary, setIsDeletePrimary] = useState(false)
  const [newSubscriptionCard, setNewSubscriptionCard] =
    useState<RawCreditCard>()

  const creditCards = cards
    ? useTransformCreditCards(rawCreditCards)
    : undefined

  const subscriptionCreditCard = subscriptionCard
    ? useTransformCreditCards([subscriptionCard])[0]
    : undefined

  const paymentStoreValues: UpdatePaymentContextValue = {
    cardId,
    creditCards,
    currentPackage,
    subscriptionCreditCard,
    isDeletePrimary,
    newSubscriptionCard,
    manualPayment,
    setCardId,
    setRawCreditCards,
    setIsDeletePrimary,
    setNewSubscriptionCard,
  }

  return <Provider value={paymentStoreValues}>{children}</Provider>
}
