import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t, formatDateTime } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'
import { PROJECT } from '../constants'

type Payload = {
  intervalNumber: number
  params: {
    project_id: number
  }
}

const scanInterval = async ({ intervalNumber, params }: Payload) => {
  const { project_id } = params

  const response = await fetchAPI<Project>({
    path: `/projects/${project_id}/scheduled_scrape`,
    method: 'put',
    data: {
      cookie_scan_interval: intervalNumber,
    },
  })

  return response.data
}

export const useUpdateScanInterval = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  return useMutation<Project, AxiosError, Payload>(scanInterval, {
    onSuccess(data) {
      const newInterval = data.cookie_scan_interval
      const time = formatDateTime(data.updated_at, 'HH:mm')

      let description

      switch (newInterval) {
        case null:
          description = t(
            'views.features.domains.show.scanner.toast_success.not_set',
          )
          break
        // currently not avaliable
        // case 1:
        //   description = t(
        //     'views.features.domains.show.scanner.toast_success.day',
        //     {
        //       day: newInterval,
        //       time,
        //     },
        //   )
        //   break
        default:
          description = t(
            'views.features.domains.show.scanner.toast_success.days',
            {
              day: newInterval,
              time,
            },
          )
          break
      }

      queryClient.invalidateQueries([PROJECT, params])

      toast({
        ...TOAST_CONFIG,
        title: t('views.features.domains.show.scanner.success'),
        description,
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
