import { useRef } from 'react'
import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { Link, Stack, Text } from '@chakra-ui/layout'
import { ModalHeader } from '@chakra-ui/modal'
import { Button } from '@chakra-ui/button'
import { Trans } from 'react-i18next'

import { Modal } from '@/components/shared'
import { SvgImage } from '@/components/DataDisplay'

import unsubNotice from '@/images/project/payments/unsub-notice.png'
import { t } from '@/lib/helpers'

export type UnsubscribeEffectiveDateModalProps = {
  disclosure: UseDisclosureReturn
}

export const UnsubscribeEffectiveDateModal = (
  props: UnsubscribeEffectiveDateModalProps,
) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  const initialRef = useRef(null)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withCloseButton
      initialFocusRef={initialRef}
    >
      <Stack justify="center" spacing="16px" sx={{ px: 8, py: 6 }}>
        <SvgImage
          src={unsubNotice}
          sx={{ w: '131px', h: '124px', mx: 'auto' }}
        />
        <ModalHeader
          sx={{ textAlign: 'center', fontSize: '2xl', fontWeight: 600, pb: 0 }}
        >
          {t(
            'views.features.packages.packages.downgrade.unsubscribe_notice_title',
            undefined,
            'แจ้งให้ทราบ',
          )}
        </ModalHeader>

        <Text>
          <Trans i18nKey="features.packages.cancel.unsubscribe_notice_description">
            เรายังคงเก็บการตั้งค่าแบนเนอร์แพลนเดิมของคุณไว้
            ตราบใดที่ยังไม่มีการแก้ไขเพิ่มเติม
            หากคุณต้องการใช้การตั้งค่าแบนเนอร์เดิม โปรด&nbsp;
            <Link
              href="/packages"
              sx={{ textDecor: 'underline', color: 'brand.400' }}
            >
              Subscribe
            </Link>
            &nbsp;อีกครั้ง
          </Trans>
        </Text>

        <Button
          colorScheme="brand"
          size="xl"
          ref={initialRef}
          onClick={() => {
            window.location.href = '/packages'
          }}
        >
          {t('views.shared.button.resubscribe', undefined, 'Resubscribe')}
        </Button>
        <Button variant="link" size="xl" colorScheme="gray" onClick={onClose}>
          {t('views.shared.button.discard', undefined, 'Discard')}
        </Button>
      </Stack>
    </Modal>
  )
}
