import { useMemo } from 'react'
import Cookies from 'js-cookie'

const COOKIE_NAME = '_cwc_display_dashboard_'

export function useMemoDisplayDashboard() {
  return useMemo(() => {
    const isDisplayDashboardCookie = Cookies.get(COOKIE_NAME)

    if (isDisplayDashboardCookie) {
      const random = parseInt(isDisplayDashboardCookie)

      return random <= parseInt(gon.displayDashboardRate)
    }

    const random = Math.round(Math.random() * 100)

    Cookies.set(COOKIE_NAME, `${random}`)

    return random <= parseInt(gon.displayDashboardRate)
  }, [])
}
