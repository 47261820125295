import { Dispatch, Fragment, SetStateAction, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Center, Flex, Grid, Link, Stack, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { Image } from '@chakra-ui/image'

import AddIcon from '@/icons/plus-white.svg'
import { t } from '@/lib/helpers'
import {
  AddCookieModal,
  AddScriptModal,
  CookieTable,
} from '../../components/Manage'
import { BannerLanguage, Category, CookieType } from '../../types'
import { Can } from '@/features/permissions/components'

type Props = {
  currentCategory: Category
  activeLanguage: BannerLanguage
}

export const ManageTableContainer = (props: Props) => {
  const { currentCategory, activeLanguage } = props

  const [type, setType] = useState<CookieType>('cookie')

  const addScriptDisclosure = useDisclosure()
  const addCookieDisclosure = useDisclosure()

  const handleOpenAddModal = () => {
    if (type === 'cookie') {
      addCookieDisclosure.onOpen()
    } else {
      addScriptDisclosure.onOpen()
    }
  }

  return (
    <Fragment>
      <Tabs
        currentTab={type}
        onClickTab={setType}
        onOpenAddModal={handleOpenAddModal}
      />

      <CookieTable
        data={currentCategory.cookies}
        filterType={type}
        currentCategory={currentCategory}
        disclosure={{
          addCookieDisclosure,
          addScriptDisclosure,
        }}
        activeLanguage={activeLanguage}
      />

      <Fragment>
        <AddCookieModal
          disclosure={addCookieDisclosure}
          currentCategory={currentCategory}
          activeLanguage={activeLanguage}
        />
        <AddScriptModal
          disclosure={addScriptDisclosure}
          currentCategory={currentCategory}
          activeLanguage={activeLanguage}
        />
      </Fragment>
    </Fragment>
  )
}

type TabProps = {
  currentTab: CookieType
  onClickTab: Dispatch<SetStateAction<CookieType>>
  onOpenAddModal: () => void
}

const Tabs = (props: TabProps) => {
  const { currentTab, onClickTab, onOpenAddModal } = props

  const links = useMemo(() => {
    return [
      {
        id: 'Cookie-select-cookie',
        onClick: () => onClickTab('cookie'),
        label: t('views.misc.cookies'),
        checkActive: 'cookie',
      },
      {
        id: 'Cookie-select-script',
        onClick: () => onClickTab('script'),
        label: t('views.misc.scripts'),
        checkActive: 'script',
      },
    ]
  }, [])

  return (
    <Flex sx={{ justifyContent: 'space-between', pt: '6px' }}>
      <Grid
        sx={{
          gridTemplateColumns: `repeat(2, 82px)`,
          h: '48px',
        }}
      >
        {links.map((li) => {
          return (
            <Link
              id={li.id}
              key={li.id}
              onClick={li.onClick}
              sx={{
                d: 'flex',
                justifyContent: 'center',
                ...(currentTab === li.checkActive && {
                  fontWeight: 700,
                  color: 'brand.400',
                  boxShadow: 'inset 0 -3px 0 0 #ea834f',
                }),
              }}
            >
              <Center
                sx={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  margin: 'auto',
                }}
              >
                {li.label}
              </Center>
            </Link>
          )
        })}
      </Grid>

      <Can I="create" a="cookie">
        <Button
          id="Cookie-add"
          variant="solid"
          sx={{ h: '32px', borderRadius: '32px' }}
          onClick={onOpenAddModal}
        >
          <Stack direction="row" justifyContent="center" spacing="6px">
            <Image src={AddIcon} boxSize="16px" color="inherit" />
            <Text>
              {currentTab === 'cookie'
                ? t('views.features.domains.show.manage.add_cookie')
                : t('views.features.domains.show.manage.add_script')}
            </Text>
          </Stack>
        </Button>
      </Can>
    </Flex>
  )
}
