import { memo } from 'react'
import { Box, Grid, Flex, Stack, Link, Text } from '@chakra-ui/react'

import { t } from '@/lib/helpers'

export const Footer = memo(() => {
  return (
    <Box
      as="footer"
      sx={{
        backgroundColor: 'white',
        h: '56px',
        color: 'gray.600',
        fontSize: '14px',
      }}
    >
      <Box layerStyle="container" sx={{ h: '100%' }}>
        <Grid
          sx={{
            justifyContent: 'space-between',
            gridTemplateColumns: 'repeat(2, max-content)',
            h: '100%',
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            ©{new Date().getFullYear()} nDataThoth Limited All Rights Reserved.
          </Flex>

          <Stack direction="row" align="center" spacing="24px">
            <Link isExternal href="https://cookiewow.crisp.help/th/">
              {t('views.components.layout.footer.help_center')}
            </Link>
            <Link isExternal href="https://cookiewow.com/terms">
              {t('views.components.layout.footer.terms')}
            </Link>
            <Link isExternal href="https://cookiewow.com/privacy">
              {t('views.components.layout.footer.privacy')}
            </Link>
            <Text>Made with ♥ in Bangkok</Text>
          </Stack>
        </Grid>
      </Box>
    </Box>
  )
})
