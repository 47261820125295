import { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { Icon, Box, Text, Flex, HStack, Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FiRefreshCw } from 'react-icons/fi'

import { DomainShowLayout } from '@/features/domains/components'

import { PeriodFilter } from '../../Consents/components'
import {
  ChartSection,
  ConsentLogContainer,
  MaintenanceNotice,
} from '../../Consents/containers'

import { formatDateTime, t } from '@/lib/helpers'
import { useMemoDisplayDashboard } from '@/lib/hooks'
import { withPage } from '@/features/layout'
import { FilterContextProvider } from '@/features/filters/context'

import { PollingStatus } from '@/features/types'
import { Meta } from '@/features/filters/types'
import { FilterPeriod } from '../../Consents/types'

import { useProject } from '@/features/domains/queries'
import { useDashboardStats } from '../../Consents/queries'
import { DASHBOARD_STATS } from '../../Consents/constants'

import { HELPDESK_LINK } from '@/features/domains/constants'

type Props = {
  project: Project
  hasConsent: boolean
  banner_polling_status: PollingStatus
  download_status: PollingStatus
  surveysensum_url: string
  meta: Meta
}

function DomainConsentPage(props: Props) {
  const {
    hasConsent,
    // meta: initialMeta,
    banner_polling_status,
    download_status,
    surveysensum_url,
  } = props

  const [filterPeriod, setFilterPeriod] = useState<FilterPeriod>('this_month')
  const { t: tCommon } = useTranslation('common')
  const queryClient = useQueryClient()

  const { data: project } = useProject({
    params: { project_id: props.project.id },
    initialData: props.project,
  })

  const isDisplayDashboard = useMemoDisplayDashboard()
  const { data: stats, isLoading } = useDashboardStats({
    enabled: isDisplayDashboard,
    date_range: filterPeriod,
  })

  const onRefetch = () => {
    if (isDisplayDashboard) {
      queryClient.refetchQueries([DASHBOARD_STATS])
    }
  }

  useEffect(() => {
    onRefetch()
  }, [project?.draft_version])

  return (
    <DomainShowLayout
      project={props.project}
      bannerPollingStatus={banner_polling_status}
      linkHref={HELPDESK_LINK.CONSENT_TRANSACTION_RECORD}
      linkTitle={t('views.features.domains.show.consent_guide')}
      surveyUrl={surveysensum_url}
    >
      <Box sx={{ py: 6, px: 8, position: 'relative' }}>
        <Flex
          sx={{ justifyContent: 'space-between' }}
          id="dashboard-controller-section"
        >
          <HStack spacing="4">
            <Text sx={{ fontWeight: 'bold' }}>
              {t('views.features.domains.show.consent.dashboard')}
            </Text>

            <PeriodFilter period={filterPeriod} setPeriod={setFilterPeriod} />
          </HStack>

          <HStack spacing="4">
            <Text
              sx={{
                color: 'gray.600',
                fontSize: 'sm',
                fontWeight: 'semibold',
              }}
            >
              {tCommon('lastUpdated')}&nbsp;:&nbsp;
              {formatDateTime(stats?.meta.last_updated_at)}
            </Text>
            <Button
              leftIcon={<Icon as={FiRefreshCw} />}
              size="sm"
              variant="outline"
              onClick={onRefetch}
              isLoading={isLoading}
            >
              {tCommon('refresh')}
            </Button>
          </HStack>
        </Flex>

        <Box mt="3">
          <ChartSection filterPeriod={filterPeriod} />
        </Box>

        <Box mt="9">
          <FilterContextProvider>
            <ConsentLogContainer
              project={project}
              hasConsent={hasConsent}
              downloadStatus={download_status}
            />
          </FilterContextProvider>
        </Box>
        {!isDisplayDashboard && <MaintenanceNotice />}
      </Box>
    </DomainShowLayout>
  )
}

export default withPage(DomainConsentPage)
