export default {
  creditCard: 'Credit Card',
  toast: {
    cardReject: 'Your card was declined',
    failed: 'Payment failed',
    success: 'Payment successful',
  },
  coupon: {
    toast: {
      applySuccess: 'Apply coupon success',
      removeSuccess: 'Remove coupon success',
    },
  },
  deleteCreditCard: {
    noCardLeft:
      'The credit card number {{card}} has been deleted. If your package usage period ends, You will not be able to continue using Cookie Wow’s banner.',
    hasCard:
      'The credit card number {{deleteCard}} has been deleted. The credit card number {{newCard}} will be set as the primary card for your next billing.',
    description: 'The credit card number {{card}} has been deleted.',
  },
  noPackage: 'Please <1>select a package</1> to get started with Cookie Wow',
}
