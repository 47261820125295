import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { fetchAPI } from '@/lib/api'
import { TOAST_CONFIG } from '@/lib/constants'
import { useCurrentUserData } from '@/features/users/queries'

export const useResendChangeEmailVerification = () => {
  const toast = useToast()
  const { t: tSettings } = useTranslation('settings')
  const currentUser = useCurrentUserData()

  return useMutation(
    async () => {
      return await fetchAPI({
        path: '/users/confirmation',
        method: 'post',
        data: {
          email: currentUser?.unconfirmed_email,
        },
      })
    },
    {
      onSuccess() {
        toast({
          ...TOAST_CONFIG,
          description: tSettings('changeEmailPending.toast.resendSuccess'),
        })
      },
    },
  )
}
