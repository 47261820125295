import { ReactNode } from 'react'
import { Box, Text } from '@chakra-ui/react'

import { t } from '@/lib/helpers'
import { useQuotaData } from '@/features/users/queries'

type Props = {
  children: ReactNode
  contents: ReactNode
  rightAddOn?: ReactNode
}

export const DomainsLayout = (props: Props) => {
  const { children, rightAddOn, contents } = props

  const quota = useQuotaData()

  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent">
        <Box
          layerStyle="sectionHeader"
          sx={{
            d: 'grid',
            justifyContent: 'space-between',
            gridTemplateColumns: 'repeat(2, max-content)',
            border: 'none',
          }}
        >
          <Box>
            <Text>
              {t('views.features.domains.index.manage_cookie')}
              <Text
                as="span"
                sx={{ fontSize: '16px', d: 'inline-block', ml: '8px' }}
              >
                ({quota?.domain.used}/
                {quota?.domain.limit
                  ? quota?.domain.limit
                  : t('views.features.packages.packages.unlimited')}
                )
              </Text>
            </Text>

            {contents}
          </Box>

          {rightAddOn}
        </Box>

        <Box layerStyle="sectionContent">{children}</Box>
      </Box>
    </Box>
  )
}
