import { CSSObject } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const TableStyled = styled.table(({ marginBottom }) => ({
  width: '100%',
  textAlign: 'left',
  color: '#888',
  tableLayout: 'fixed',
  marginBottom: marginBottom,
}))

export const TableRow = styled.tr(({ theme }) => ({
  transitionDuration: '0.3s',
  transitionTimingFunction: 'ease',
  transitionProperty: 'background-color',

  '&:hover': {
    backgroundColor: theme.colors.brand[25],
    '.chakra-link': {
      color: theme.colors.brand[400],
      textDecoration: 'underline',
    },
  },
}))

export const ColumnHeader = styled.th(
  ({ isSorted, canSort, theme, width }) => ({
    width: width,
    padding: '11px 16px',
    textTransform: 'uppercase',
    fontSize: '12px',
    backgroundColor: theme.colors.gray[100],
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
    transitionProperty: 'color, background-color',

    ...(isSorted && {
      color: theme.colors.brand[400],
    }),

    ...(canSort && {
      '&:hover': {
        cursor: 'pointer',
        userSelect: 'none',
        color: theme.colors.gray[900],
        backgroundColor: theme.colors.gray[100],
      },
    }),
  }),
)

export const Cell = styled.td(({ width, hideBorder, paddingTop }) => ({
  width: width,
  padding: '18px 18px 17px',
  paddingTop: paddingTop,
  borderBottom: hideBorder ? 'none' : '1px solid #ffede0',
  transition: 'color 0.3s ease',
  verticalAlign: 'baseline',
}))

export const thStyle = ({
  isSorted,
  canSort,
}: {
  isSorted: boolean
  canSort: boolean
}): CSSObject => {
  return {
    p: '11px 16px',
    fontSize: '12px',
    bg: 'gray.100',
    transitionDuration: '0.3s',
    msTransitionTimingFunction: 'ease',
    transitionProperty: 'color, background-color',
    letterSpacing: gon.currentLocale === 'th' ? 0 : 'normal',
    ...(isSorted && {
      color: 'brand.400',
    }),
    ...(canSort && {
      _hover: {
        cursor: 'pointer',
        userSelect: 'none',
        color: 'gray.900',
      },
    }),
  }
}
