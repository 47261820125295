import { Dispatch, SetStateAction, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { getErrorMsg, t } from '@/lib/helpers'
import { BANNER_STATUS, PROJECT } from '../constants'

import { getBannerStatus } from '../queries'
import { fetchAPI } from '@/lib/api'

type Payload = {
  projectId: number
}

const checkBannerStatus = async (payload: Payload) => {
  const { projectId } = payload

  return fetchAPI({
    path: `/projects/${projectId}/banner_status`,
    method: 'post',
  })
}

export const useCheckBannerStatus = ({
  projectId,
  startOnMount = false,
}: {
  projectId: number
  startOnMount?: boolean
}) => {
  const [start, setStart] = useState(startOnMount)

  const toast = useToast()

  const { mutate } = useMutation(checkBannerStatus, {
    onSuccess() {
      setStart(true)
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })

  const { status } = usePolling({ projectId, start, setStart })

  return {
    checkBannerStatus: mutate,
    isLoading: start,
    status,
  }
}

const usePolling = ({
  projectId,
  start,
  setStart,
}: {
  projectId: number
  start: boolean
  setStart: Dispatch<SetStateAction<boolean>>
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: [BANNER_STATUS, { projectId }],
    queryFn: () => getBannerStatus({ projectId }),
    async onSuccess(data) {
      if (['succeeded', 'failed'].includes(data.data.status)) {
        await queryClient.refetchQueries([PROJECT, { projectId }])

        toast({
          description: t('views.features.domains.index.check_success'),
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
          duration: null,
        })
        setStart(false)
      }
    },
    enabled: start,
    refetchInterval: start ? 5000 : false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  return {
    status: data?.data?.status,
  }
}
