import { Fragment, useState } from 'react'
import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Stack,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react'

import { DomainShowLayout } from '@/features/domains/components'
import { withPage } from '@/features/layout'
import { ManageTableContainer } from './ManageTableContainer'
import {
  CategoryList,
  DeleteCategoryModal,
  ManageHeader,
} from '../../components/Manage'
import { ManageMenuBar } from '../../Manage/components'
import { CategoryFormContainer } from '../../Manage/containers'

import { useCookieCategories } from '../../queries'
import { useAbility } from '@/features/permissions'
import { useNonInitialEffect } from '@/lib/hooks'
import { t } from '@/lib/helpers'

import { PollingStatus } from '@/features/types'
import { HELPDESK_LINK } from '../../constants'

import { Category } from '../../types'

interface Props {
  project: Project
  categories: Category[]
  bannerLanguage: BannerLanguage
  banner_polling_status: PollingStatus
  surveysensum_url: string
}

function ManageCookiesPage(props: Props) {
  const {
    project,
    categories: _categories,
    bannerLanguage,
    banner_polling_status,
    surveysensum_url,
  } = props
  const [currentCat, setCurrentCat] = useState<Category>(_categories[0])

  const { data: cookieCats } = useCookieCategories()

  const [activeLanguage, setActiveLanguage] =
    useState<BannerLanguage>(bannerLanguage)

  const [createBool, setCreateBool] = useBoolean()
  const [updateBool, setUpdateBool] = useBoolean()
  const deleteDisclosure = useDisclosure()

  const ability = useAbility()
  const canDeleteCategory = ability.can('delete', 'category')

  const setDefaultCategory = () => {
    setCurrentCat(cookieCats![0])
  }

  useNonInitialEffect(() => {
    setCurrentCat(() => {
      const newCat =
        cookieCats?.find((cat) => cat.id === currentCat.id) || cookieCats![0]
      return newCat
    })
  }, [cookieCats])

  return (
    <Fragment>
      <DomainShowLayout
        project={project}
        surveyUrl={surveysensum_url}
        bannerPollingStatus={banner_polling_status}
        linkHref={HELPDESK_LINK.MANAGE_COOKIE}
        linkTitle={t('views.features.domains.show.manage_guide')}
      >
        <Grid gridTemplateColumns="396px 1fr">
          <Box
            sx={{
              p: 8,
              borderRight: '1px solid',
              borderColor: 'brand.50',
            }}
          >
            {cookieCats ? (
              <CategoryList
                activeLanguage={activeLanguage}
                cookieCategories={cookieCats}
                currentCategory={createBool ? undefined : currentCat}
                isDisabled={createBool || updateBool}
                onClickCategory={setCurrentCat}
                onOpenCreateCategory={setCreateBool.on}
              />
            ) : (
              <LoadingStack />
            )}
          </Box>

          <Stack spacing="18px" sx={{ p: 8, alignItems: 'stretch' }}>
            <ManageMenuBar
              activeLanguage={activeLanguage}
              category={currentCat}
              isCreating={createBool}
              isDisabled={createBool || updateBool}
              setActiveLanguage={setActiveLanguage}
              setCurrentCat={setCurrentCat}
            />

            <Divider />
            {createBool || updateBool ? (
              <CategoryFormContainer
                activeLanguage={activeLanguage}
                category={currentCat}
                createBoolean={[createBool, setCreateBool]}
                onOpenDeleteCategory={deleteDisclosure.onOpen}
                setCurrentCat={setCurrentCat}
                updateBoolean={[updateBool, setUpdateBool]}
              />
            ) : (
              <Fragment>
                <ManageHeader
                  category={currentCat}
                  activeLanguage={activeLanguage}
                  onOpenUpdateCategory={setUpdateBool.on}
                  onOpenDeleteCategory={deleteDisclosure.onOpen}
                />

                <ManageTableContainer
                  currentCategory={currentCat}
                  activeLanguage={activeLanguage}
                />
              </Fragment>
            )}
          </Stack>
        </Grid>
      </DomainShowLayout>

      {canDeleteCategory && (
        <DeleteCategoryModal
          disclosure={deleteDisclosure}
          name={currentCat.name[activeLanguage]}
          categoryId={currentCat.id}
          setDefaultCategory={setDefaultCategory}
        />
      )}
    </Fragment>
  )
}

export default withPage(ManageCookiesPage)

const LoadingStack = () => {
  return (
    <Stack spacing="4">
      <Skeleton h="56px" />
      <Skeleton h="56px" />
      <Skeleton h="56px" />
      <Skeleton h="56px" />
    </Stack>
  )
}
