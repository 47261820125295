import { Fragment, useState } from 'react'
import { ModalBody, Text, UseDisclosureReturn } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Modal, ModalHeader } from '@/components/shared'
import { ChangeEmailForm, ChangeEmailSuccess } from '../components'
import { PasswordWrapper } from '@/features/settings/components'

import { useUpdateEmail } from '../mutations'
import { useCurrentUserData } from '@/features/users/queries'
import { useNonInitialEffect } from '@/lib/hooks'

type ChangeEmailModalProps = {
  disclosure: UseDisclosureReturn
}

export const ChangeEmailModal = (props: ChangeEmailModalProps) => {
  const { disclosure } = props

  const { isOpen, onClose } = disclosure
  const { t: tSettings } = useTranslation('settings')

  const [success, setSuccess] = useState(false)
  const [newEmail, setNewEmail] = useState('')

  const { mutate: updateEmail, isLoading } = useUpdateEmail()
  const currentUser = useCurrentUserData()

  useNonInitialEffect(() => {
    if (!isOpen) {
      setSuccess(false)
      setNewEmail('')
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      {success ? (
        <ModalBody mt="8">
          <ChangeEmailSuccess email={newEmail} onClose={onClose} />
        </ModalBody>
      ) : (
        <PasswordWrapper onCancel={onClose} title={tSettings('changeEmail')}>
          <Fragment>
            <ModalHeader>
              {tSettings('changeEmail')}
              <br />
              <Text sx={{ fontSize: 'xs', color: 'gray.600' }}>
                {tSettings('changeEmailDesc')}
              </Text>
            </ModalHeader>
            <ModalBody>
              {currentUser && (
                <ChangeEmailForm
                  onCancel={onClose}
                  onSubmit={(data) => {
                    updateEmail(
                      { data },
                      {
                        onSuccess() {
                          setNewEmail(data.email)
                          setSuccess(true)
                        },
                      },
                    )
                  }}
                  isLoading={isLoading}
                  email={currentUser.email}
                />
              )}
            </ModalBody>
          </Fragment>
        </PasswordWrapper>
      )}
    </Modal>
  )
}
