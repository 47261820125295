import { ReactNode } from 'react'
import { makeObservable, observable, action } from 'mobx'

export class ModalState {
  isShown = false
  isCentered = false
  showCloseButton = false
  isLoading = false
  child: ReactNode = null
  maxW?: string

  constructor() {
    this.setLoading = this.setLoading.bind(this)
    this.toggleShow = this.toggleShow.bind(this)
    this.setChild = this.setChild.bind(this)
    this.clearChild = this.clearChild.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.showModal = this.showModal.bind(this)

    makeObservable(this, {
      child: observable,
      isShown: observable,
      toggleShow: action,
      setChild: action,
      clearChild: action,
      hideModal: action,
      showModal: action,
    })
  }

  setLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  toggleShow(): void {
    this.isShown = !this.isShown
  }

  setChild(child: ReactNode): void {
    this.child = child
  }

  clearChild(): void {
    this.child = null
  }

  hideModal(): void {
    const body = document.querySelector('body')
    if (body) body.style.overflow = 'auto'

    this.isLoading = false
    this.isShown = false
    this.child = null
    this.showCloseButton = false
    this.maxW = undefined
  }

  showModal(
    child: ReactNode,
    options: {
      showCloseButton: boolean
      maxW?: any
      isCentered?: boolean
      isLoading?: boolean
    } = {
      showCloseButton: false,
      maxW: undefined,
      isCentered: false,
      isLoading: false,
    },
  ): void {
    const body = document.querySelector('body')
    if (body) body.style.overflow = 'hidden'

    this.isLoading = Boolean(options.isLoading)
    this.isShown = true
    this.child = child
    this.showCloseButton = options.showCloseButton
    this.maxW = options.maxW
    this.isCentered = options.isCentered
  }
}

const modalState = new ModalState()

export default modalState
