import { Fragment, ReactNode, useMemo, useState } from 'react'
import {
  Box,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Column } from 'react-table'
import { FiChevronRight, FiEdit2, FiFolder, FiTrash2 } from 'react-icons/fi'
// import * as d3 from 'd3-format'
import { get } from 'lodash'
import Cookies from 'js-cookie'

import { Folder } from '../../types'
import { SelectOptions } from '@/components/Select/types'

import { ExpandingTable } from '@/components/DataDisplay/Table/ExpandingTable'
import {
  AnimatedActionColumn,
  AnimatedActionColumnIcon,
} from '@/components/DataDisplay/Table'
import {
  EditDomainModal,
  DeleteDomainModal,
} from '@/features/domains/components'
import { EditFolderModal } from '../EditFolderModal'

import { t } from '@/lib/helpers'
import { useAbility } from '@/features/permissions'
import { useDeleteDomain } from '@/features/domains/mutations'
import { useDeleteFolder } from '../../mutations'

import { EXPANDED_STATE_COOKIE } from '@/constants/cookie'
import { DEFAULT_FOLDER_NAME } from '../../constants'

export type FolderDomainTableProps = {
  data: Folder[]
  isDisabled: boolean
  folderOptions: SelectOptions
  editableFolders: string[]
  renderNoData: () => ReactNode
}

export const FolderDomainTable = (props: FolderDomainTableProps) => {
  const { data, isDisabled, folderOptions, editableFolders, renderNoData } =
    props

  const [actionData, setActionData] = useState<Folder>()
  const [folderActionData, setFolderActionData] = useState<Folder>()

  const deleteDomainDisclosure = useDisclosure()
  const editDomainDisclosure = useDisclosure()
  const deleteFolderDisclosure = useDisclosure()
  const editFolderDisclosure = useDisclosure()

  const ability = useAbility()
  const canDeleteFolder = ability.can('delete', 'folder')
  const canUpdateFolder = ability.can('update', 'folder')

  const { mutate: deleteDomain, isLoading: deleteDomainLoading } =
    useDeleteDomain()
  const { mutate: deleteFolder, isLoading: deleteFolderLoading } =
    useDeleteFolder()

  const columns = useMemo<Array<Column<Folder>>>(() => {
    return [
      {
        Header: t(
          'views.features.domains.index.domain_name',
          undefined,
          'Domain Name',
        ),
        accessor: 'url',
        width: '90%',
        Cell: (cellProps) => {
          const data = get(cellProps, 'row.original')

          if (isDisabled) {
            return <Text>{data.url}</Text>
          }

          return (
            <Link
              href={`/projects/${data.id}/consents`}
              sx={{ wordBreak: 'break-all' }}
            >
              {data.url}
            </Link>
          )
        },
      },
      // {
      //   Header: t(
      //     'views.features.domains.index.pageviews',
      //     undefined,
      //     'Pageviews',
      //   ),
      //   accessor: 'page_views',
      //   width: '30%',
      //   Cell: (cellProps) => {
      //     const value = get(cellProps, 'value')

      //     return d3.format(',d')(value)
      //   },
      // },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data.map((d) => {
      if (!d.subRows.length) {
        return {
          ...d,
          subRows: [
            {
              url: '',
              folder_name: '',
              id: 0,
              page_views: 0,
              subRows: [],
            },
          ],
        }
      } else {
        return {
          ...d,
        }
      }
    })
  }, [data])

  const initialExpandState = useMemo(() => {
    const expandedState = Cookies.get(EXPANDED_STATE_COOKIE)
    const expanded = expandedState ? JSON.parse(expandedState) : {}
    return data.reduce((prev, folder, index) => {
      return {
        ...prev,
        [index]:
          expanded[folder.folder_name] === undefined
            ? true
            : expanded[folder.folder_name],
      }
    }, {})
  }, [])

  return (
    <Fragment>
      <ExpandingTable<Folder>
        data={memoizedData}
        columns={columns}
        autoResetExpanded={false}
        initialState={{
          expanded: initialExpandState,
        }}
        renderNoData={renderNoData}
        renderToggleRow={(folder, isExpanded) => {
          const icons: AnimatedActionColumnIcon[] = []
          const shouldDisplayActionColumn =
            (canDeleteFolder || canUpdateFolder) &&
            folder.url !== DEFAULT_FOLDER_NAME
          if (canDeleteFolder) {
            icons.push({
              icon: (
                <Icon
                  id="folder-delete"
                  as={FiTrash2}
                  onClick={(e) => {
                    e.stopPropagation()
                    setFolderActionData(folder)
                    deleteFolderDisclosure.onOpen()
                  }}
                  sx={{
                    color: 'red.400',
                    cursor: 'pointer',
                    boxSize: '20px',
                  }}
                />
              ),
              label: t('views.shared.button.delete', undefined, 'Delete'),
            })
          }
          if (canUpdateFolder) {
            icons.push({
              icon: (
                <Icon
                  id="folder-edit"
                  as={FiEdit2}
                  onClick={(e) => {
                    e.stopPropagation()
                    setFolderActionData(folder)
                    editFolderDisclosure.onOpen()
                  }}
                  sx={{
                    cursor: 'pointer',
                    boxSize: '20px',
                    color: 'brand.400',
                  }}
                />
              ),
              label: t('views.shared.button.edit', undefined, 'Edit'),
            })
          }

          return (
            <Flex justify="space-between">
              <HStack alignItems="center">
                <Icon as={FiFolder} color="brand.400" />
                <Text>{folder.url}</Text>
                <Icon
                  as={FiChevronRight}
                  sx={{
                    transition: 'transform 0.25s ease-in-out',
                    transform: `rotate(${isExpanded ? 90 : -90}deg)`,
                  }}
                />
              </HStack>

              <Box>
                {shouldDisplayActionColumn && (
                  <AnimatedActionColumn
                    ariaLabel="folder action button"
                    icons={icons}
                  />
                )}
              </Box>
            </Flex>
          )
        }}
        renderActionColumn={{
          width: '10%',
          Cell: (folder) => {
            const canUpdateDomain = editableFolders.includes(folder.folder_name)
            if (isDisabled) return null
            const icons: AnimatedActionColumnIcon[] = []

            if (canUpdateDomain) {
              icons.push(
                {
                  icon: (
                    <Icon
                      id="Domain-delete"
                      as={FiTrash2}
                      onClick={() => {
                        setActionData(folder)
                        deleteDomainDisclosure.onOpen()
                      }}
                      sx={{
                        color: 'red.400',
                        cursor: 'pointer',
                        boxSize: '20px',
                      }}
                    />
                  ),
                  label: t('views.shared.button.delete'),
                },
                {
                  icon: (
                    <Icon
                      id="Domain-edit"
                      as={FiEdit2}
                      onClick={() => {
                        setActionData(folder)
                        editDomainDisclosure.onOpen()
                      }}
                      sx={{
                        cursor: 'pointer',
                        boxSize: '20px',
                        color: 'brand.400',
                      }}
                    />
                  ),
                  label: t('views.shared.button.edit', undefined, 'Edit'),
                },
              )
            }

            return (
              <Flex justify="flex-end">
                {Boolean(icons.length) && (
                  <AnimatedActionColumn
                    ariaLabel="domain column action button"
                    icons={icons}
                  />
                )}
              </Flex>
            )
          },
        }}
      />

      {actionData && (
        <Fragment>
          <DeleteDomainModal
            description={t('views.features.domains.index.remove_banner')}
            disclosure={deleteDomainDisclosure}
            defaultValue={{ name: actionData.url }}
            onDelete={() => {
              deleteDomain(
                { projectId: actionData.id },
                {
                  onSettled: deleteDomainDisclosure.onClose,
                },
              )
            }}
            isLoading={deleteDomainLoading}
          />

          <EditDomainModal
            disclosure={editDomainDisclosure}
            defaultValue={{
              folder_name: actionData.folder_name,
              name: actionData.url,
              id: actionData.id,
            }}
            folderOptions={folderOptions}
          />
        </Fragment>
      )}

      {folderActionData && (
        <Fragment>
          <DeleteDomainModal
            title={t('views.features.folders.delete_folder_title', {
              name: folderActionData.url,
            })}
            disclosure={deleteFolderDisclosure}
            defaultValue={{ name: folderActionData.url }}
            onDelete={() => {
              deleteFolder(
                { params: { folder_id: folderActionData.id } },
                {
                  onSettled: deleteFolderDisclosure.onClose,
                },
              )
            }}
            isLoading={deleteFolderLoading}
          />

          <EditFolderModal
            disclosure={editFolderDisclosure}
            folder={folderActionData}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
