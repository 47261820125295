export default {
  h1: 'Start your Free trial',
  backToPackage: 'Back to Packages',
  coupon: {
    placeholder: 'Referral Code or coupon',
    buttonTitle: 'Apply',
    useCoupon: 'USE COUPON',
    discount: '{{ discount }} OFF',
    useDiscountCode: 'Applied Coupon',
    discountCodeAutoApply:
      'The discount will be automatically applied to your bill after the free trial ends.',
  },
  note: 'Note',
  note_0:
    'You will receive a notice from us that your free trial period has ended or that the paying portion of your subscription has begun.',
  note_1:
    'We will begin billing your payment method for subscription fees at the end of the free trial period of your subscription unless you cancel prior to the end of the free trial period.',
  orderSummary: {
    title: 'Order Summary',
    order: 'Order',
    sumTitle: 'Amount (Baht)',
    preVat: 'Pre-VAT',
    afterVat: 'Grand Total',
    wht: 'Withholding Tax (3%)',
    whtDescription:
      '<strong>Cookie Wow</strong> is supported by The board of investment of Thailand (BOI) and is exempt from withholding tax.',
    total: 'Net',
    payNow: 'Pay Today',
    freeTrialDuration: 'Free Trial {{ day }} days',
    timer: {
      newUserCode: 'For New User',
      freeBy: 'when start free trial within',
      time: {
        hour: 'Hours',
        minute: 'Minutes',
        second: 'Seconds',
      },
    },
    acceptTerms: 'I accept <1>Terms</1> and <3>Privacy Policy</3>',
    collectAfter:
      'You can use Cookie Wow for free until <1>{{ day }}/{{ month }}/{{ year }}</1>, and after that, <1>charges will be applied</1> based on the payment information provided. You can cancel your subscription at any time. For more information, <4>Click here</4>',
  },
  form: {
    yearly: 'Yearly',
    monthly: 'Monthly',
    month: 'Month',
    selectPackage: 'Select your package',
    freeTrial: 'Free trial for {{ day }} days',
    selectCycle: 'Select your billing cycle',
    pricePerMonth: '{{ price }}.- /month',
    billMonthly: 'Billed monthly for {{ price }}.-',
    billAnnually: 'Billed annually for {{ price }}.-',
    individual: 'Individual',
    company: 'Company',
    creditDebit: 'Enter your payment method',
    changeCard:
      'You can change your credit/debit card later in the User Settings menu.',
    howToPay: 'How to make payment ?',
    helpdeskLink:
      'https://help.cookiewow.com/en/article/how-do-i-make-payment-1777860/?bust=1694145288759',
    cardHolderNamePlaceholder: 'Name on card',
    billingInformation: 'Billing information',
    taxInvoice:
      'The full version of tax invoice/receipt will be emailed in E-Tax format within 3 business days of payment.',
    namePlaceholder: 'Firstname - Lastname',
    taxIdPlaceholder: 'Tax ID',
    addressPlaceholder: 'Address',
    submit: 'Starts {{ day }}-Day Free Trial',
    contactUsAt:
      'To make a quote or inquiry request, please contact Cookie Wow Live Chat or <1>{{mail}}</1> or call <3>02-024-5560</3>',
    errors: {
      numberRequired: 'Please input your card number',
      numberInvalid: 'Incorrect card number',
      expiryRequired: 'Please input valid date',
      expiryInvalid: 'Incorrect expiry date',
      cvcRequired: 'Please input cvv',
      cvcInvalid: 'Incorrect cvc or cvv',
    },
  },
  toast: {
    success: '',
    failed: '',
  },
  alreadySub: {
    title: `We’re sorry...`,
    description: `The free trial is only available for users who have not subscribed to any plans before.`,
    button: 'Back to Package',
  },
  success: {
    title: 'Your free trial starts now',
    description:
      'You can enjoy your 14-day free trial until <1>{{ date }}</1> and you can cancel the subscription anytime.',
    complete: 'Get Started',
    info: '(After the free trial ends, your subscription will be charged automatically.)',
  },
}
