export const inputStyle = {
  bg: 'white',
}

export const linkStyle = {
  color: 'brand.400',
  fontWeight: 'medium',
  fontSize: '16px',
  textDecoration: 'underline',
}
