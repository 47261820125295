import React, { Fragment, useMemo } from 'react'
import {
  Box,
  Button,
  Center,
  Image,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Trans } from 'react-i18next'

import NoData from '@/images/project/no-domains.png'

import { DomainsLayout, AddDomainModal } from '@/features/domains/components'
import { withPage } from '@/features/layout'
import { Can } from '@/features/permissions/components'
import { FolderDomainTable } from '@/features/folders/components'
import { ReferralBanner } from '@/features/referral/ReferralBanner'

import { CurrentPackage } from '@/features/types'
import { Folder } from '@/features/folders/types'

import { t } from '@/lib/helpers'
import { useQuotaData } from '@/features/users/queries'
import { useFolders } from '@/features/folders/queries'

type Props = {
  initialFolders: Folder[]
  currentPackage: CurrentPackage
  quota: Quota
  editableFolders: {
    created_at: string
    id: number
    name: string
    updated_at: string
  }[]
  hadSubscribed: boolean
}

function DomainListPage(props: Props) {
  const {
    initialFolders,
    hadSubscribed,
    editableFolders: rawEditableFolders,
  } = props

  const { data: folders } = useFolders({ initialData: initialFolders })

  const addDomainDisclosure = useDisclosure()

  const folderOptions = useMemo(() => {
    return (
      folders?.map((f) => ({
        value: f.url,
        label: f.url,
      })) ?? []
    )
  }, [folders?.length])

  const quota = useQuotaData()

  const domainQuotaDetail = useMemo(() => {
    if (!quota) {
      return {
        canAddDomain: false,
        isQuotaExceed: true,
      }
    }

    return {
      canAddDomain:
        quota.domain.used < (quota.domain.limit || Number.POSITIVE_INFINITY),
      isQuotaExceed:
        quota.domain.used > (quota.domain.limit || Number.POSITIVE_INFINITY) ||
        quota.user.used > (quota.user.limit || Number.POSITIVE_INFINITY),
    }
  }, [quota])

  const editableFolders = useMemo(() => {
    return rawEditableFolders.map((folder) => folder.name)
  }, [])

  return (
    <Fragment>
      <DomainsLayout
        contents={
          <Box sx={{ fontSize: '16px', fontWeight: 400 }}>
            {domainQuotaDetail.isQuotaExceed ? (
              <Text sx={{ color: 'red.400' }}>
                {t('views.features.migration.index.contact_owner')}
              </Text>
            ) : domainQuotaDetail.canAddDomain ? (
              <Text sx={{ color: 'gray.600' }}>
                {t('views.features.domains.index.select_domain')}
              </Text>
            ) : (
              <Box
                sx={{
                  color: 'red.300',
                  span: {
                    fontWeight: 700,
                  },
                }}
              >
                <Text>
                  <Trans
                    i18nKey={`features.domains.index.domain_exceed${
                      hadSubscribed ? '' : '_free_plan'
                    }`}
                  >
                    Your domain numbers has reached maximum for the current
                    plan&nbsp;
                    <Link
                      href="/packages"
                      sx={{ fontWeight: 700, textDecor: 'underline' }}
                    >
                      Upgrade plan
                    </Link>
                    &nbsp;to add more domain
                  </Trans>
                </Text>
              </Box>
            )}
          </Box>
        }
        rightAddOn={
          <Can I="create" a="project">
            {() => (
              <Button
                id="Domain-add"
                onClick={addDomainDisclosure.onOpen}
                isDisabled={
                  !domainQuotaDetail.canAddDomain ||
                  domainQuotaDetail.isQuotaExceed
                }
                size="lg"
              >
                {t('views.features.domains.index.add_domain')}
              </Button>
            )}
          </Can>
        }
      >
        <Box sx={{ pos: 'relative' }}>
          {folders && (
            <FolderDomainTable
              data={folders}
              isDisabled={domainQuotaDetail.isQuotaExceed}
              folderOptions={folderOptions}
              editableFolders={editableFolders}
              renderNoData={() => (
                <Center sx={{ minH: '100px' }}>
                  {t('views.features.domains.index.not_assigned')}
                </Center>
              )}
            />
          )}

          {quota?.domain.used === 0 && (
            <FirstTimer onClick={addDomainDisclosure.onOpen} />
          )}

          <Box
            sx={{
              mt: 2,
              py: 4,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ReferralBanner />
          </Box>
        </Box>
      </DomainsLayout>

      <AddDomainModal
        disclosure={addDomainDisclosure}
        folderOptions={folderOptions}
      />
    </Fragment>
  )
}

export default withPage(DomainListPage)

const FirstTimer = ({
  onClick,
}: {
  onClick: () => void
}): React.ReactElement => {
  return (
    <VStack
      sx={{
        pos: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Image src={NoData} sx={{ boxSize: '130px' }} />
      <Text sx={{ fontSize: '24px' }}>
        {t('views.features.domains.index.get_started')}
      </Text>

      <Button onClick={onClick} size="lg">
        {t('views.features.domains.index.add_domain')}
      </Button>
    </VStack>
  )
}
