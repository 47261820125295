import { BANNER_LANGUAGE } from '@datawowio/cookie-banner-ui'
import { get } from 'lodash'

import { CONTENT_TEMPLATE, DEFAULT_THEME_COLOR } from './constants'

import { Dialog, DialogFormInput, MultiLangField, Widget } from './types'

const generateDefaultMultiLangField = (): MultiLangField => {
  return BANNER_LANGUAGE.reduce(
    (sum, cur) => ({ ...sum, [cur]: '' }),
    {} as MultiLangField,
  )
}

export const dialogToFormInput = (
  dialog: Dialog,
  isFree: boolean,
): DialogFormInput => {
  const widget = get(dialog, 'widget') as Widget
  const banner_language = get(dialog, 'banner_language') || 'en'
  const close_button_behavior = get(dialog, 'close_button_behavior')
  const theme = get(dialog, 'theme') || 'light'

  const bg_color = isFree
    ? DEFAULT_THEME_COLOR[theme].bg_color
    : get(dialog, 'bg_color') ?? DEFAULT_THEME_COLOR[theme].bg_color
  const text_color = isFree
    ? DEFAULT_THEME_COLOR[theme].text_color
    : get(dialog, 'text_color') ?? DEFAULT_THEME_COLOR[theme].text_color

  return {
    accept_all_label: get(dialog, 'accept_all_label'),
    auto_detect: get(dialog, 'auto_detect', false),
    banner_language,
    banner_model: get(dialog, 'banner_model') ?? 'default',
    banner_radius: get(dialog, 'banner_radius') ?? '8',
    bg_color,
    button_radius: get(dialog, 'button_radius') ?? '6',
    close_banner_timer: get(dialog, 'close_banner_timer') ?? '',
    close_button: get(dialog, 'close_button', false),
    close_button_behavior: close_button_behavior || 'default',
    color: get(dialog, 'color') || '#3d98f3',
    content: get(dialog, 'content') || CONTENT_TEMPLATE.content,
    custom_css: get(dialog, 'custom_css') ?? '',
    excluded_urls: get(dialog, 'excluded_urls') ?? [],
    font_size: get(dialog, 'font_size', 'md'),
    included_urls: get(dialog, 'included_urls') ?? [],
    logo: get(dialog, 'logo_url'),
    logo_type: dialog.logo_type,
    ok_button_label: get(dialog, 'ok_button_label'),
    page_setting: get(dialog, 'page_setting') ?? 'all',
    policy_title: get(dialog, 'policy_title'),
    policy_type: get(dialog, 'policy_type') || 'cookie',
    policy_url: get(dialog, 'policy_url') ?? generateDefaultMultiLangField(),
    position: get(dialog, 'position') || 'classic-bottom',
    preference_button: get(dialog, 'preference_button') ?? false,
    preference_label: get(dialog, 'preference_label'),
    preview_language: banner_language,
    reject_all: get(dialog, 'reject_all', false),
    reject_all_label: get(dialog, 'reject_all_label'),
    revokable: ['left', 'right'].includes(widget) ? '1' : '2',
    show_footer_logo: dialog.show_footer_logo,
    show_logo: dialog.show_logo,
    show_preference_header_logo: dialog.show_preference_header_logo,
    show_title: get(dialog, 'show_title'),
    text_color,
    theme,
    title: get(dialog, 'title') || CONTENT_TEMPLATE.title,
    view_details_label: get(dialog, 'view_details_label'),
    widget,
    widget_icon_type: get(dialog, 'widget_icon_type') ?? 'default',
    widget_icon_url: get(dialog, 'widget_icon_url'),
    z_index: get(dialog, 'z_index'),
  }
}

export const isValidHex = (color: string) => {
  if (!color || typeof color !== 'string') return false

  // Validate hex values
  const regexPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return regexPattern.test(color)
}
