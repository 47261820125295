import { Text, Box, HStack, Stack } from '@chakra-ui/layout'
import { Radio, useRadio, UseRadioProps } from '@chakra-ui/radio'

type BillingCycleRadioProps = UseRadioProps & {
  label: string
  subtitle: string
  description?: string
}

export const BillingCycleRadio = (
  props: BillingCycleRadioProps,
): React.ReactElement => {
  const { label, subtitle, description, ...rest } = props

  const { getInputProps, getCheckboxProps } = useRadio(rest)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" cursor="pointer">
      <input {...input} />

      <Stack
        spacing={1}
        {...checkbox}
        sx={{
          w: '200px',
          p: 4,
          borderRadius: 'lg',
          border: '1px solid',
          borderColor: 'gray.200',
          transitionDuration: '0.2s',
          transitionProperty: 'border-color, background-color',
          _checked: {
            bg: 'brand.50',
            borderColor: 'brand.400',
          },
        }}
      >
        <HStack>
          <Radio isChecked={rest.isChecked} colorScheme="brand" />
          <Text sx={{ color: 'brand.400', fontWeight: 700 }}>{label}</Text>
        </HStack>
        <Text sx={{ fontSize: '12px', color: 'gray.500', pl: 6 }}>
          {subtitle}
        </Text>
        {description && (
          <Text sx={{ fontSize: '12px', pl: 6 }}>{description}</Text>
        )}
      </Stack>
    </Box>
  )
}
