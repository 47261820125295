export default {
  invalidUrlFull:
    'Please type in a valid URL starting with http:// or https://.',
  wildcardUrlFull:
    'If you want to apply Wildcard URL, please use the asterisk ( * ) to define the Wildcard part.',
  input: {
    required: 'Required',
    invalidPassword: 'Invalid password',
    passwordMismatch: "Password dont't match",
  },
}
