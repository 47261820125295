import { Fragment, useState } from 'react'
import {
  VStack,
  Input,
  Text,
  Checkbox,
  Button,
  Link,
  Stack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@/components/FormControl'
import { PasswordInputValidator, PasswordInput } from '@/components/Input'

import {
  signInURL,
  resendConfirmationURL,
  termsURL,
  privacyURL,
} from '@/features/auth/constants/links'
import { inputStyle, linkStyle } from '@/features/auth/constants/styles'

import { t } from '@/lib/helpers'
import { getAuthenticityToken } from '@/lib/api'
import { getQueryParam } from '@/lib/queryParams'
import { logEvent } from '@/lib/stats'

type Props = {
  formActionPath: string
  minimumPasswordLength: number
  email?: string
}

function SignUpForm({
  email,
  formActionPath,
  minimumPasswordLength,
}: Props): React.ReactElement {
  const authenticityToken = getAuthenticityToken()
  const { t: T } = useTranslation()

  const invitationToken = getQueryParam('invitation_token') || ''

  const [password, setPassword] = useState('')

  return (
    <VStack
      spacing="4"
      sx={{
        alignItems: 'stretch',
      }}
    >
      <form
        id={invitationToken ? 'edit_user' : 'new_user'}
        action={formActionPath}
        acceptCharset="UTF-8"
        method="post"
      >
        <VStack
          spacing="4"
          sx={{
            alignItems: 'stretch',
          }}
        >
          <FormControl
            id="user_email"
            label={t('activerecord.attributes.user.email')}
          >
            <Input
              isRequired
              id="user_email"
              type="email"
              autoComplete="email"
              autoFocus
              name="user[email]"
              placeholder="example@cookiewow.com"
              isReadOnly={Boolean(email)}
              value={email ? email : undefined}
              sx={inputStyle}
            />
          </FormControl>
          <FormControl
            id="user_password"
            label={
              <Text>
                {t('activerecord.attributes.user.password')}&nbsp;
                {minimumPasswordLength ? (
                  <Text as="span" sx={{ fontWeight: 'light' }}>
                    (
                    {T(
                      'features.users.change_password.minimum_password_length',
                      {
                        count: minimumPasswordLength,
                      },
                    )}
                    )
                  </Text>
                ) : (
                  ''
                )}
              </Text>
            }
          >
            <PasswordInputValidator
              isRequired
              id="user_password"
              autoComplete="new-password"
              name="user[password]"
              minLength={minimumPasswordLength}
              sx={inputStyle}
              placeholder={t('activerecord.attributes.user.password')}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </FormControl>
          <FormControl
            id="user_password_confirmation"
            label={t('activerecord.attributes.user.password_confirmation')}
          >
            <PasswordInput
              isRequired
              id="user_password_confirmation"
              autoComplete="new-password"
              name="user[password_confirmation]"
              minLength={minimumPasswordLength}
              sx={inputStyle}
              placeholder={t(
                'activerecord.attributes.user.password_confirmation',
              )}
            />
          </FormControl>

          <Checkbox
            isRequired
            id="user_accept_terms"
            value="1"
            name="user[accept_terms]"
            size="md"
            alignItems="flex-start"
          >
            {t('views.misc.I_accept')}&nbsp;
            <Link isExternal href={termsURL} sx={linkStyle}>
              {t('views.misc.terms_of_use')}
            </Link>
            &nbsp;{t('views.misc.and')}&nbsp;
            <Link isExternal href={privacyURL} sx={linkStyle}>
              {t('views.misc.privacy_policy')}
            </Link>
          </Checkbox>
          <Checkbox
            id="user_marketing_consent"
            value="1"
            name="user[marketing_consent]"
            size="md"
            alignItems="flex-start"
          >
            <Stack spacing={1}>
              <Text>{t('devise.registrations.new.marketing_consent')}</Text>
              <Text sx={{ color: 'gray.600', fontSize: '14px' }}>
                {t('devise.registrations.new.marketing_consent_description')}
              </Text>
            </Stack>
          </Checkbox>
          <Input
            value={authenticityToken}
            type="hidden"
            name="authenticity_token"
          />
          {Boolean(invitationToken) && (
            <Fragment>
              <Input
                value={invitationToken}
                type="hidden"
                name="user[invitation_token]"
              />
              <Input value="put" type="hidden" name="_method" />
            </Fragment>
          )}
          <Button
            size="xl"
            type="submit"
            name="commit"
            value={t('devise.registrations.new.sign_up')}
            data-disable-with={t('devise.registrations.new.sign_up')}
            onClick={() =>
              logEvent({
                fb: { event: 'CompleteRegistration' },
                ga: {
                  category: 'Auth',
                  action: 'Click Submit',
                },
              })
            }
          >
            {t('devise.registrations.new.sign_up')}
          </Button>
        </VStack>
      </form>
      <VStack
        spacing="3"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Link href={signInURL} sx={linkStyle}>
          {t('devise.sessions.new.sign_in')}
        </Link>
        <Link href={resendConfirmationURL} sx={linkStyle}>
          {t('devise.shared.links.didn_t_receive_confirmation_instructions')}
        </Link>
      </VStack>
    </VStack>
  )
}

export default SignUpForm
