import { Alert } from './alert'
import { Button } from './button'
import { Checkbox } from './checkbox'
import { Input } from './input'
import { Link } from './link'
import { NumberInput } from './number-input'
import { Radio } from './radio'
import { Select } from './select'
import { Switch } from './switch'
import { Textarea } from './textarea'

export default {
  Alert,
  Button,
  Checkbox,
  Input,
  Link,
  NumberInput,
  Radio,
  Select,
  Switch,
  Textarea,
}
