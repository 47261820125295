import { t } from '@/lib/helpers'
import {
  Box,
  Grid,
  HStack,
  Icon,
  RadioGroup,
  Stack,
  Text,
  useRadioGroup,
} from '@chakra-ui/react'
import { Fragment } from 'react'
import { Control, useController } from 'react-hook-form'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import { CategoryFormInput } from '../../types'

import { ConsentModelRadio } from './ConsentModelRadio'

import { CONSENT_MODEL } from '../../constants'

export type ConsentSettingSectionProps = {
  isNoticeOnly: boolean
  control: Control<CategoryFormInput>
}

export const ConsentSettingSection = (props: ConsentSettingSectionProps) => {
  const { isNoticeOnly, control } = props

  const {
    field: { onChange, value, name },
  } = useController({
    name: 'consent_model',
    control,
    defaultValue: isNoticeOnly ? 'notice_only' : 'opt_in',
  })

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    defaultValue: isNoticeOnly ? 'notice_only' : value,
  })

  const group = getRootProps()

  return (
    <Fragment>
      <RadioGroup>
        <Stack spacing={4}>
          <Text fontWeight="medium">
            {t(
              'views.features.domains.show.manage.form.consent_model',
              undefined,
              'Consent Model',
            )}
          </Text>

          <Grid
            sx={{ gridTemplateColumns: 'repeat(3, 1fr)', gap: '16px' }}
            {...group}
          >
            {CONSENT_MODEL.map((model) => {
              const radio = getRadioProps({ value: model })

              return (
                <ConsentModelRadio
                  key={model}
                  {...radio}
                  isBannerModelNoticeOnly={isNoticeOnly}
                />
              )
            })}
          </Grid>
        </Stack>
      </RadioGroup>

      <Box
        sx={{
          p: '13px 24px',
          border: '1px solid',
          borderColor: 'red.300',
          borderRadius: 'md',
          bg: 'red.50',
          color: 'red.300',
        }}
      >
        <HStack align="center">
          <Text sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
            {t('views.misc.caution', undefined, 'Caution')}
          </Text>
          <Icon as={AiOutlineInfoCircle} color="red.300" boxSize="18px" />
        </HStack>
        <Text>
          {t('views.features.domains.show.customize.notice_only_caution')}
        </Text>
      </Box>
    </Fragment>
  )
}
