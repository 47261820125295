import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { User } from '../types'

import { t } from '@/lib/helpers'
import { fetchAPI } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { USERS } from '../constants'
import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  params: {
    user_id: User['id']
  }
}

const deleteUser = async ({ params }: Payload) => {
  const { user_id } = params

  return await fetchAPI({
    path: `/user_managements/${user_id}`,
    method: 'delete',
  })
}

export const useDeleteUser = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, Payload>(deleteUser, {
    async onSuccess() {
      await queryClient.refetchQueries([USERS])

      toast({
        ...TOAST_CONFIG,
        description: t('views.features.users.services.delete_user_success'),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
