import { AxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useErrorHandler } from '@/lib/hooks'

import { Dialog, DialogFormInput } from '../types'
import { DIALOG, PROJECT } from '../constants'
import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  form: DialogFormInput
  id: number
  projectId: number
}

const updateDialog = async ({ form, id, projectId }: Payload) => {
  const formData = new FormData()

  formData.append('id', String(id))

  if (typeof form.logo !== 'string') {
    formData.append('logo', form.logo || '')
  }

  if (typeof form.widget_icon_url !== 'string') {
    formData.append('widget_icon', form.widget_icon_url || '')
  }

  const logoPromise = fetchAPI<Dialog>({
    path: `/projects/${projectId}/dialogs`,
    method: 'put',
    data: formData,
  })

  const restPromise = fetchAPI<Dialog>({
    path: `/projects/${projectId}/dialogs`,
    method: 'put',
    data: {
      id,
      accept_all_label: form.accept_all_label,
      auto_detect: form.auto_detect,
      banner_language: form.banner_language,
      banner_radius: form.banner_radius,
      bg_color: form.bg_color,
      button_radius: form.button_radius,
      close_banner_timer: form.close_banner_timer,
      close_button: form.close_button,
      close_button_behavior:
        form.close_button_behavior === 'default'
          ? ''
          : form.close_button_behavior,
      color: form.color,
      content: form.content,
      custom_css: form.custom_css,
      font_size: form.font_size,
      logo_type: form.logo_type,
      ok_button_label: form.ok_button_label,
      policy_title: form.policy_title,
      policy_type: form.policy_type,
      policy_url: form.policy_url,
      position: form.position,
      preference_button: form.preference_button,
      preference_label: form.preference_label,
      preview_language: form.preview_language,
      reject_all: form.reject_all,
      reject_all_label: form.reject_all_label,
      show_footer_logo: form.show_footer_logo,
      show_logo: form.show_logo,
      show_preference_header_logo: form.show_preference_header_logo,
      show_title: form.show_title,
      text_color: form.text_color,
      theme: form.theme,
      title: form.title,
      view_details_label: form.view_details_label,
      widget: form.revokable === '1' ? form.widget : '',
      widget_icon_type: form.widget_icon_type,
      z_index: form.z_index,
    },
  })

  const [, response] = await Promise.all([logoPromise, restPromise])

  return response.data
}

export const useUpdateDialog = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const query = getPathParams()
  const params = { project_id: Number(query?.project_id) }

  return useMutation<Dialog, AxiosError, Payload>(updateDialog, {
    async onSuccess() {
      queryClient.invalidateQueries([DIALOG, params])
      queryClient.invalidateQueries([PROJECT, params])

      toast({
        ...TOAST_CONFIG,
        title: t(
          'views.features.domains.show.customize.services.update_success',
        ),
        description: t(
          'views.features.domains.show.customize.services.update_success_desc',
        ),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      handleError(error, { isClosable: true })
    },
  })
}
