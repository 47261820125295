import { get } from 'lodash'
import { useQuery } from 'react-query'

import { USER_SECURITY } from '@/features/users/constants'
import { fetchAPI } from '@/lib/api'

import { UserSecurity } from '../types'

export const useUserSecurity = () => {
  return useQuery({
    queryKey: [USER_SECURITY],
    queryFn: async () => {
      const response = await fetchAPI<UserSecurity>({
        path: '/settings/security',
      })

      return get(response, 'data')
    },
  })
}
