import { HStack, Stack, Text, useRadioGroup } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BillingCycleRadio } from './atoms'

export const PackageForm = () => {
  const { t } = useTranslation('freeTrial')

  const { field } = useController({
    name: 'package',
    defaultValue: 'small',
  })

  const RADIO_OPTIONS = useMemo(
    () => [
      {
        label: 'Small',
        subtitle: t('form.freeTrial', { day: 14 }),
        value: 'small',
      },
      {
        label: 'Medium',
        subtitle: t('form.freeTrial', { day: 14 }),
        value: 'medium',
      },
      {
        label: 'Large',
        subtitle: t('form.freeTrial', { day: 7 }),
        value: 'large',
      },
    ],
    [],
  )

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field.name,
    onChange: field.onChange,
    value: field.value,
  })

  const group = getRootProps()

  return (
    <Stack spacing={4}>
      <Text>{t('form.selectPackage')}</Text>

      <HStack spacing={4} {...group}>
        {RADIO_OPTIONS.map(({ value, ...rest }) => {
          const radio = getRadioProps({ value })

          return <BillingCycleRadio key={value} {...radio} {...rest} />
        })}
      </HStack>
    </Stack>
  )
}
