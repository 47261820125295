export default {
  companySettingForm: {
    firstName: {
      label: 'ชื่อ',
      placeholder: 'ชื่อ',
    },
    lastName: {
      label: 'นามสกุล',
      placeholder: 'นามสกุล',
    },
  },
  changeEmail: 'เปลี่ยนอีเมล',
  changeEmailDesc: 'กรุณากรอกอีเมลที่ต้องการเปลี่ยน',
  changeEmailForm: {
    oldEmail: 'อีเมลปัจจุบัน',
    newEmail: 'อีเมลใหม่',
    newEmailPlaceholder: 'อีเมลใหม่',
    toast: {
      success: 'เปลี่ยนแปลงอีเมลสำเร็จ',
    },
  },
  changeEmailSuccess: {
    title: 'กรุณายืนยันอีเมล',
    description:
      'คุณต้องทำการยืนยันการเปลี่ยนอีเมล กรุณาตรวจสอบกล่องขาเข้าของอีเมล <1>{{ email }}</1> ของคุณ เพื่อทำการยืนยันภายในวันที่ <3>{{ date }}</3>',
  },
  changeEmailPending: {
    description:
      'มีอีเมลที่รอการเปลี่ยนแปลง โปรดยืนยันอีเมลเพื่อยืนยันการเปลี่ยนแปลงของคุณ<br />ภายใน <1>{{ date }}</1>',
    resendVerification: 'ส่งอีเมลอีกครั้ง',
    toast: {
      resendSuccess:
        'ส่งอีเมลยืนยันอีกครั้งสำเร็จ กรุณาตรวจสอบกล่องขาเข้าอีเมลของคุณ',
      cancelSuccess: 'ยกเลิกการเปลี่ยนแปลงอีเมลสำเร็จ',
    },
  },
  confirmCancelUpdateEmailModal: {
    title: 'คุณแน่ใจหรือไม่ ?',
    description:
      'หากคุณต้องการยกเลิกการเปลี่ยนอีเมล กรุณากดปุ่ม "ยืนยัน" ข้างล่าง',
  },
  passwordWrapper: {
    title: '',
    subtitle: 'ป้อนรหัสผ่านสำหรับบัญชีของคุณ',
  },
  verifyPasswordForm: {
    password: {
      label: 'รหัสผ่าน',
      placeholder: 'ป้อนรหัสผ่านสำหรับบัญชีของคุณ',
    },
    toast: {
      success: '',
    },
  },
  passwordExpiration: {
    title: 'นโยบายรหัสผ่าน',
    emptyDetail: 'ไม่มีการกำหนดนโยบายด้านความปลอดภัยของรหัสผ่าน',
    detail: 'มีการกำหนดนโยบายให้เปลี่ยนรหัสผ่านทุก <1>{{ days }}</1> วัน',
    detail_plural:
      'มีการกำหนดนโยบายให้เปลี่ยนรหัสผ่านทุก <1>{{ days }}</1> วัน',
    modal: {
      header: 'ตั้งค่าอายุการใช้งานของรหัสผ่าน',
      switchLabel: 'เปิดการใช้งานการตั้งอายุของรหัสผ่าน',
    },
    form: {
      expirePasswordAfterDays: {
        label: 'อายุการใช้งานของรหัสผ่าน',
        helper: 'กรุณาระบุขั้นต่ำ 1 วัน และสูงสุดไม่เกิน 365 วัน',
      },
      toast: {
        success: 'อัปเดตนโยบายรหัสผ่านสำเร็จ',
      },
    },
  },
}
