import { HStack, Box, VStack, Text, chakra, Divider } from '@chakra-ui/react'

import { ConsentDashboardCard } from '../ConsentDashboardCard'
import { formatThousand } from '@/lib/helpers'
import { useTranslation } from 'react-i18next'

export type ConsentRateProps = {
  consents: number
  sessions: number
  rate: number
}

export const ConsentRate = (props: ConsentRateProps): React.ReactElement => {
  const { consents, sessions, rate } = props

  const { t } = useTranslation('domainConsent')

  return (
    <ConsentDashboardCard
      title={t('consentRate')}
      tooltip={t('consentRateTooltip')}
      id="consent-rate-card"
      bottomSection={
        <VStack
          spacing="2"
          sx={{
            p: 4,
            textAlign: 'center',
            borderRadius: '8px',
            bg: '#F6F6F6B2',
            w: 'full',
            mt: 4,
          }}
        >
          <Text sx={{ color: 'brand.400', fontSize: 'xs' }}>
            <Text as="span" sx={{ fontWeight: 'bold', fontSize: 'xl' }}>
              {formatThousand(consents)}
            </Text>
            <br />
            {t('action.accepted_all')}
          </Text>

          <HStack spacing="1" w="full">
            <Divider borderColor="gray.300" />
            <Text sx={{ fontSize: 'xs', color: 'gray.300' }}>{t('of')}</Text>
            <Divider borderColor="gray.300" />
          </HStack>

          <Text sx={{ color: 'gray.600', fontSize: 'xs' }}>
            <Text as="span" sx={{ fontWeight: 'bold', fontSize: 'xl' }}>
              {formatThousand(sessions)}
            </Text>
            <br />
            {t('allConsentType')}
          </Text>
        </VStack>
      }
    >
      <VStack mt="2">
        <ProgressCircle percent={rate} />
      </VStack>
    </ConsentDashboardCard>
  )
}

const ProgressCircle = ({ percent }: { percent: number }) => {
  const { t } = useTranslation('domainConsent')

  return (
    <Box sx={{ position: 'relative', boxSize: '156px' }}>
      <Svg
        sx={{
          position: 'relative',
          boxSize: '156px',
          circle: {
            fill: 'none',
            strokeWidth: 14,
            transformOrigin: 'center',
            strokeDasharray: 465,
          },
        }}
      >
        <Circle
          cx="78"
          cy="78"
          r="71"
          sx={{
            strokeDashoffset: '0',
            stroke: '#e2e8f0',
          }}
        ></Circle>
        <Circle
          cx="78"
          cy="78"
          r="71"
          sx={{
            strokeDashoffset: `calc(465 - (465 * ${percent}) / 100)`,
            stroke: `#ea834f`,
            transform: 'rotate(90deg)',
          }}
        ></Circle>
      </Svg>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Text
            sx={{
              fontSize: '28px',
              color: 'brand.400',
              fontWeight: 'bold',
              lineHeight: 'none',
            }}
          >
            {percent}%
          </Text>
          <Text
            sx={{
              fontSize: 'xs',
              color: 'gray.600',
              fontWeight: 'semibold',
              textTransform: 'uppercase',
            }}
          >
            {t('action.accepted_all')}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

const Svg = chakra('svg')
const Circle = chakra('circle')
