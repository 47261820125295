import { Button, Checkbox, Stack, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { MarketingConsentFormInput } from '@/features/users/types'
import { t } from '@/lib/helpers'

type Props = {
  defaultValues?: MarketingConsentFormInput
  isLoading: boolean
  onSubmitForm: (data: MarketingConsentFormInput) => void
}

export const MarketingConsentForm = (props: Props) => {
  const { onSubmitForm, defaultValues, isLoading } = props

  const { handleSubmit, register } = useForm<MarketingConsentFormInput>({
    defaultValues,
  })

  const onSubmit = (data: MarketingConsentFormInput) => {
    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ mb: '24px' }} spacing="6">
        <Checkbox
          colorScheme="brand"
          {...register('marketing_consent')}
          alignItems="flex-start"
        >
          <Stack>
            <Text sx={{ fontSize: '14px' }}>
              {t('views.features.users.settings.marketing_consent.accept')}
            </Text>

            <Text sx={{ fontSize: '12px', color: 'gray.600' }}>
              {t(
                'views.features.users.settings.marketing_consent.accept_description',
              )}
            </Text>
          </Stack>
        </Checkbox>

        <Button type="submit" size="lg" isLoading={isLoading}>
          {t('views.shared.button.update')}
        </Button>
      </Stack>
    </form>
  )
}

// type SettingItemProps = {
//   name: string
//   title: string
//   detail: string
//   value: boolean
//   onChange: (...event: any[]) => void
// }

// const SettingItem = (props: SettingItemProps) => {
//   const { title, detail, value, name, onChange } = props

//   return (
//     <Stack spacing={1}>
//       <Flex sx={{ justifyContent: 'space-between' }}>
//         <Text>{title}</Text>

//         <Switch name={name} size="lg" isChecked={value} onChange={onChange} />
//       </Flex>

//       <Text sx={{ fontSize: '12px', color: 'gray.600' }}>{detail}</Text>
//     </Stack>
//   )
// }
