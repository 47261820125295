import { get, noop } from 'lodash'
import { useToast } from '@chakra-ui/react'

import { axios, getErrorMsg } from '@/lib/helpers'
import { logEvent } from '@/lib/stats'

import { useCheckoutStore } from '../stores'

export const useCheckoutServices = () => {
  const toast = useToast()

  const {
    hostUrl,
    packageData,
    addressType,
    setRawAddresses,
    selectedAddress,
    recurringType,
  } = useCheckoutStore()

  return {
    createAddress: async (values, payload = {}) => {
      const { onSuccess = noop } = payload

      try {
        const addressResponse = await createAddress({ ...values, addressType })

        setRawAddresses(addressResponse)
        onSuccess()
        toast({
          title: 'Address Created.',
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
    updateAddress: async (values, payload = {}) => {
      const { onSuccess = noop } = payload

      try {
        const addressResponse = await updateAddress({ ...values, addressType })

        setRawAddresses(addressResponse)
        onSuccess()
        toast({
          title: 'Address Updated.',
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
    deleteAddress: async (id) => {
      try {
        const addressResponse = await deleteAddress(id)

        setRawAddresses(addressResponse)
        toast({
          title: 'Address Deleted.',
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
        })
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
      }
    },
    saveInformation: async ({ onSuccess = noop, onError = noop }) => {
      try {
        const data = {
          package: packageData.id,
          addressId: selectedAddress,
          recurring: recurringType,
        }

        const token = await saveInformation(data)

        logEvent({
          fb: { event: 'InitiateCheckout' },
          ga: {
            category: 'Payment',
            action: 'InitiateCheckout',
          },
        })

        onSuccess()

        window.location.href = `${hostUrl}/payments/${token}`
      } catch (error) {
        toast({
          title: getErrorMsg(error),
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          isClosable: true,
        })
        onError()
      }
    },
  }
}

const createAddress = async (payload) => {
  return axios
    .post('/addresses', {
      name: payload.name,
      details: payload.address,
      primary: payload.primary,
      taxpayer_id: payload.taxId,
      taxpayer_type: payload.addressType,
    })
    .then(({ data }) => data || [])
}

const updateAddress = (values) => {
  return axios
    .put(`/addresses/${values.id}`, {
      name: values.name,
      details: values.address,
      primary: values.primary,
      taxpayer_id: values.taxId,
      taxpayer_type: values.addressType,
    })
    .then(({ data }) => data || [])
}

const deleteAddress = (id) => {
  return axios.delete(`/addresses/${id}`).then(({ data }) => data || [])
}

const saveInformation = (data) => {
  return axios
    .post('/invoices', {
      address_id: data.addressId,
      package: data.package,
      recurring: data.recurring,
    })
    .then(({ data }) => get(data, 'token'))
}
