import { useEffect, useRef, useState, KeyboardEvent } from 'react'
import {
  Box,
  chakra,
  Grid,
  IconButton,
  Portal,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { FiX } from 'react-icons/fi'
import { useNonInitialEffect } from '@/lib/hooks'

export type CreatableInput2Props = {
  name: string
  id: string
  pattern?: RegExp
  onChange: (values: string[]) => void
  placeholder: string
  placeholderId: string
  values: string[]
}

export const CreatableInput = (props: CreatableInput2Props) => {
  const { id, name, placeholder, placeholderId, onChange, pattern } = props

  const [values, setValues] = useState(props.values)
  const [inputValue, setInputValue] = useState<string>('')
  const [width, setWidth] = useState(4)

  const inputRef = useRef<HTMLInputElement>(null)

  const setValue = async () => {
    let newValue: string[] = []

    await setValues((state) => {
      newValue = [...state, inputValue]
      return newValue
    })

    onChange(newValue)
    setInputValue('')
  }

  useNonInitialEffect(() => {
    setValues(props.values)
  }, [props.values])

  const handleKeyDown = async (event: KeyboardEvent<HTMLElement>) => {
    if (!inputValue) {
      if (event.key === 'Backspace') {
        let newValue: string[] = []

        await setValues((state) => {
          newValue = state.slice(0, -1)
          return newValue
        })
        onChange(newValue)
      }

      return
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
        setValue()
        event.preventDefault()
    }
  }

  const handleBlur = async () => {
    if (!inputValue) return
    setValue()
  }

  const handleRemoveItem = async (idx: number) => {
    let newValue: string[] = []

    await setValues((state) => {
      newValue = state.filter((_, _idx) => idx !== _idx)
      return newValue
    })
    onChange(newValue)
  }

  const getPlaceholderWidth = (id: string) => {
    return document.querySelector(id)?.getBoundingClientRect().width
  }

  const setInputWidth = () => {
    const placeholderWidth = getPlaceholderWidth(`#${placeholderId}`)

    setWidth(placeholderWidth ? placeholderWidth + 2 : 100)
  }

  useEffect(() => {
    window.setTimeout(setInputWidth, 100)
  }, [])

  useNonInitialEffect(setInputWidth, [inputValue])

  return (
    <Box
      sx={{
        p: '7px 11px',
        border: '1px solid',
        borderColor: 'gray.300',
        borderRadius: '4px',
        transition: 'border-color 0.2s ease',
        minH: '48px',
        _focusWithin: {
          borderColor: 'brand.400',
        },
      }}
      onClick={() => {
        inputRef.current?.focus()
      }}
    >
      <Wrap
        align="center"
        sx={{
          maxW: 'full',
          '> ul': {
            maxW: 'full',
          },
        }}
      >
        {values.map((v, idx) => {
          const isValid = Boolean(pattern ? v.match(pattern) : true)

          return (
            <WrapItem key={`${idx}__${name}__${v}__key`}>
              <Grid
                key={`${idx}__${name}__${v}__key`}
                sx={{
                  p: '3px',
                  pl: '11px',
                  gridTemplateColumns: '1fr 24px',
                  alignItems: 'center',
                  gap: '8px',
                  color: 'green.800',
                  border: '1px solid',
                  borderColor: 'green.800',
                  borderRadius: 'md',
                  bg: 'green.100',
                  maxW: 'full',
                  ...(!isValid && {
                    color: 'red.500',
                    borderColor: 'red.500',
                    bg: 'red.100',
                  }),
                }}
              >
                <Text sx={{ maxW: 'full', overflowX: 'auto' }}>{v}</Text>
                <IconButton
                  aria-label="remove url"
                  icon={<FiX size="16px" />}
                  variant="unstyled"
                  size="sm"
                  isRound
                  sx={{
                    minW: '24px',
                    d: 'flex',
                    justifyContent: 'center',
                    h: '24px',
                  }}
                  onClick={() => {
                    handleRemoveItem(idx)
                  }}
                />
              </Grid>
            </WrapItem>
          )
        })}
        <WrapItem sx={{ maxW: 'full' }}>
          <Box sx={{ maxW: 'inherit' }}>
            <chakra.input
              name={name}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
              sx={{
                w: `${width}px`,
                minW: '100px',
                maxW: 'full',
                h: '32px',
                outline: 'none',
                margin: '0 !important',
                bg: 'transparent',
                px: '1px',
              }}
              id={id}
              ref={inputRef}
              placeholder={placeholder}
            />
            <Portal>
              <Text
                as="span"
                id={placeholderId}
                sx={{
                  height: 0,
                  pos: 'absolute',
                  right: 0,
                  top: 0,
                  visibility: 'hidden',
                }}
              >
                {inputValue || placeholder}
              </Text>
            </Portal>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  )
}
