import axios from 'axios'

export type ConsentData = {
  name?: string
  surname?: string
  email: string
  phone_number?: string
}

interface AcceptConsentProps {
  apiKey: string
  purposeId: string
  baseURL: string
  data: ConsentData
}

export async function acceptConsent({
  apiKey,
  purposeId,
  baseURL,
  data,
}: AcceptConsentProps) {
  const res = await axios.post(
    `${baseURL}/api/v1/consent_records`,
    {
      first_name: data.name,
      last_name: data.surname,
      email: data.email,
      phone_number: data.phone_number,
      consents: [
        {
          status: 'accepted',
          consent_purpose_id: purposeId,
        },
      ],
    },
    {
      headers: {
        Authorization: apiKey,
      },
    },
  )
  return res.data
}
