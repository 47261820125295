import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { getPathParams } from '@/lib/routes'
import { useErrorHandler } from '@/lib/hooks'

import { TOAST_CONFIG } from '@/lib/constants'
import { COOKIE_CATEGORIES, PROJECT } from '../constants'

type Payload = {
  params: {
    project_id: number
    category_id: number
  }
}

const deleteCategory = async ({ params }: Payload) => {
  const { project_id, category_id } = params

  await fetchAPI({
    path: `/projects/${project_id}/categories/${category_id}`,
    method: 'delete',
  })
}
export const useDeleteCategory = () => {
  const toast = useToast()
  const handleError = useErrorHandler()
  const queryClient = useQueryClient()

  const { project_id } = getPathParams()
  const params = { project_id: Number(project_id) }

  return useMutation<void, AxiosError, Payload>(deleteCategory, {
    onSuccess() {
      queryClient.invalidateQueries([COOKIE_CATEGORIES, params])
      queryClient.invalidateQueries([PROJECT, params])

      toast({
        ...TOAST_CONFIG,
        title: t(
          'views.features.domains.show.manage.services.category_removed',
        ),
      })
    },
    onError(error) {
      handleError(error)
    },
  })
}
