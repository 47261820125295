import { useRef } from 'react'
import { Button } from '@chakra-ui/button'
import { Stack, Text } from '@chakra-ui/layout'
import { ModalHeader } from '@chakra-ui/modal'
import { useToast } from '@chakra-ui/toast'

import { Modal } from '@/components/shared'
import { SvgImage } from '@/components/DataDisplay'
import resub from '@/images/project/payments/resub.png'

import { usePackageStore } from '../stores'
import { useAppContext } from '@/features/layout'
import { useResubscribe } from '../mutations'

import { t } from '@/lib/helpers'
import { TOAST_CONFIG } from '@/lib/constants'

export const ResubscribeModal = () => {
  const {
    resubscribeDisclosure: { isOpen, onClose },
  } = usePackageStore()
  const { currentPackage } = useAppContext()

  const initialRef = useRef(null)

  const { mutate: resubscribe, isLoading } = useResubscribe()

  const toast = useToast()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withCloseButton
      id="resubscribe-modal"
    >
      <Stack justify="center" spacing="16px" sx={{ px: 8, py: 6 }}>
        <SvgImage src={resub} sx={{ w: '138px', h: '127px', mx: 'auto' }} />

        <ModalHeader sx={{ textAlign: 'center', pb: 0 }}>
          {t('views.features.packages.packages.downgrade.resubscribe_title', {
            package_name: currentPackage.name.toTitleCase(),
          })}
        </ModalHeader>

        <Text sx={{ textAlign: 'center' }}>
          {t(
            'views.features.packages.packages.downgrade.resubscribe_contents',
            {
              package_name: currentPackage.name.toTitleCase(),
            },
          )}
        </Text>

        <Button
          colorScheme="brand"
          size="xl"
          ref={initialRef}
          isLoading={isLoading}
          onClick={() => {
            resubscribe(undefined, {
              onSuccess() {
                toast({
                  ...TOAST_CONFIG,
                  title: t(
                    'views.features.packages.packages.downgrade.resubscribe_success_title',
                  ),
                  description: t(
                    'views.features.packages.packages.downgrade.resubscribe_success_description',
                    {
                      package: currentPackage.name.toTitleCase(),
                    },
                  ),
                })
                onClose()
              },
            })
          }}
        >
          {t('views.shared.button.confirm')}
        </Button>
        <Button variant="link" size="xl" onClick={onClose} colorScheme="gray">
          {t('views.shared.button.discard')}
        </Button>
      </Stack>
    </Modal>
  )
}
