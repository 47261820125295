import { PropsWithChildren } from 'react'
import {
  Text,
  Grid,
  Icon,
  Tooltip,
  HStack,
  Center,
  CSSObject,
  Box,
} from '@chakra-ui/react'
import { FiInfo } from 'react-icons/fi'

type ConsentDashboardCardProps = {
  title: string
  tooltip: string
  sx?: CSSObject
  id?: string
  bottomSection: React.ReactNode
}

export const ConsentDashboardCard = (
  props: PropsWithChildren<ConsentDashboardCardProps>,
): React.ReactElement => {
  const { title, tooltip, children, sx, id, bottomSection } = props

  return (
    <Grid
      id={id}
      sx={{
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: '8px',
        bg: 'white',
        gridTemplateRows: 'max-content 1fr',
        gap: '4px',
        ...sx,
      }}
    >
      <HStack
        spacing="1"
        sx={{
          px: 4,
          pt: 2,
        }}
      >
        <Text sx={{ fontWeight: 'bold' }}>{title}</Text>
        <Tooltip label={tooltip} aria-label="dashboard-tooltip" placement="top">
          <Center>
            <Icon as={FiInfo} color="gray.400" boxSize="16px" />
          </Center>
        </Tooltip>
      </HStack>

      <Grid
        sx={{
          alignContent: 'start',
          gridTemplateRows: 'max-content 1fr',
        }}
      >
        {children}

        <Box sx={{ p: 4, pt: 0 }}>{bottomSection}</Box>
      </Grid>
    </Grid>
  )
}
