import { CurrentUser, NormalUser, UserFormInput } from './types'

export const userIntoFormInput = (
  user: NormalUser | CurrentUser,
  isInvite: boolean = false,
): UserFormInput => {
  return {
    email: isInvite ? undefined : user.email,
    folder_permissions: user.folder_permissions.map((f) => {
      return {
        ...f,
        role: isInvite ? 'no_access' : f.role,
      }
    }),
  }
}
