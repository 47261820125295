import { useState } from 'react'
import {
  Button,
  ModalBody,
  ModalHeader,
  Stack,
  Text,
  UseDisclosureReturn,
  Checkbox,
  Icon,
  Box,
  HStack,
} from '@chakra-ui/react'
import { Image } from '@chakra-ui/image'
import {
  BsCheckCircleFill,
  BsCheckCircle,
  BsFillExclamationCircleFill,
} from 'react-icons/bs'

import { Modal } from '@/components/shared'

import { t } from '@/lib/helpers'
import { useUpdateBannerVersion } from '../../mutations'
import { getPathParams } from '@/lib/routes'
import { logEvent } from '@/lib/stats'

import NewCookieIcon from '@/images/project/cookie-new.png'

export type BumpVersionModalProps = {
  disclosure: UseDisclosureReturn
  nextVersion: string
  changesSummary: {
    bannerConsentModel: boolean
    cookieConsentModel: boolean
    cookieCategories: boolean
    cookieContent: boolean
    bannerStyle: boolean
  }
  warningFlags: {
    reconsentNeeded: boolean
    willResetStat: boolean
  }
}

export const BumpVersionModal = (props: BumpVersionModalProps) => {
  const { disclosure, nextVersion, changesSummary, warningFlags } = props
  const { isOpen, onClose } = disclosure

  const [reconsentNeeded, setReconsentNeeded] = useState(false)
  const { mutate: updateBannerVersion, isLoading } = useUpdateBannerVersion()

  const query = getPathParams()

  const hasWarningFlag = Object.values(warningFlags).some(Boolean)

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Stack direction="row" justify="center" sx={{ mt: '24px' }}>
        <Image
          src={NewCookieIcon}
          sx={{ w: '137px', h: '128px', mx: 'auto' }}
        />
      </Stack>

      <ModalHeader
        sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 600, pb: 2 }}
      >
        {t(
          'views.features.domains.show.version.publish_banner',
          undefined,
          'Publish Banner',
        )}
        ?
      </ModalHeader>

      <ModalBody>
        <Stack spacing="4">
          <Stack spacing="2" align="center">
            <Text
              sx={{
                textAlign: 'center',
                bg: 'brand.400',
                color: 'white',
                py: 1,
                px: 4,
                borderRadius: 'full',
                fontWeight: 'bold',
              }}
            >
              {t(
                'views.features.domains.show.version.publish_to',
                undefined,
                'Publish to version',
              )}
              : {nextVersion}
            </Text>

            <Text
              sx={{ textAlign: 'center', fontSize: 'sm', color: 'gray.500' }}
            >
              {t(
                'views.features.domains.show.version.auto_save_changes',
                undefined,
                'Cookie Wow will specify the version number automatically.',
              )}
            </Text>
          </Stack>

          <Box sx={{ bg: 'gray.50', padding: 5 }}>
            <Stack
              spacing="3"
              sx={{
                alignItems: 'flex-start',
                width: 'fit-content',
                m: 'auto',
                fontSize: 'lg',
              }}
            >
              <Text
                sx={{
                  alignSelf: 'center',
                  fontWeight: 'bold',
                  color: 'brand.500',
                }}
              >
                {t(
                  'views.features.domains.show.version.change_summary.title',
                  undefined,
                  'Changes Summary',
                )}
              </Text>
              {Object.entries(changesSummary).map(([key, value]) => (
                <HStack
                  key={key}
                  spacing="6px"
                  sx={{
                    justifyContent: 'flex-start',
                    color: value ? 'brand.500' : 'gray.400',
                    fontWeight: 'medium',
                    fontSize: 'sm',
                    alignItems: 'flex-start',
                  }}
                >
                  <Icon
                    as={value ? BsCheckCircleFill : BsCheckCircle}
                    boxSize="1.3em"
                    sx={{ mt: '1px' }}
                  />
                  <Text>
                    {t(
                      `views.features.domains.show.version.change_summary.${key}`,
                    )}
                  </Text>
                </HStack>
              ))}
            </Stack>
          </Box>

          <Checkbox
            alignItems="flex-start"
            sx={{ fontSize: '16px' }}
            isChecked={warningFlags.reconsentNeeded || reconsentNeeded}
            isDisabled={warningFlags.reconsentNeeded}
            onChange={() => setReconsentNeeded((state) => !state)}
          >
            {t(
              'views.features.domains.show.version.request_consent_again',
              undefined,
              `Would you like to reappear the banner to re-collect cookies consent?`,
            )}
          </Checkbox>

          {hasWarningFlag && (
            <HStack
              spacing="6px"
              sx={{
                justifyContent: 'flex-start',
                color: 'brand.500',
                fontWeight: 'medium',
                fontSize: 'sm',
                padding: 5,
                bg: 'brand.25',
                alignItems: 'flex-start',
              }}
            >
              <Icon
                as={BsFillExclamationCircleFill}
                boxSize="1.3em"
                sx={{ mt: '1px' }}
              />
              <Text>
                {t(
                  warningFlags.reconsentNeeded
                    ? 'views.features.domains.show.version.change_summary.warning_reconsent'
                    : 'views.features.domains.show.version.change_summary.warning',
                  undefined,
                )}
              </Text>
            </HStack>
          )}

          <Stack spacing="8px">
            <Button
              size="xl"
              isLoading={isLoading}
              onClick={() => {
                updateBannerVersion(
                  {
                    project_id: Number(query?.project_id),
                    reconsent_needed:
                      reconsentNeeded || warningFlags.reconsentNeeded,
                  },
                  {
                    onSuccess() {
                      logEvent({
                        ga: {
                          category: 'Publish Banner',
                          action: 'Confirm publish banner',
                        },
                      })
                      onClose()
                    },
                  },
                )
              }}
            >
              {t(
                'views.features.domains.show.version.publish_banner',
                undefined,
                'Publish Banner',
              )}
            </Button>
            <Button
              variant="ghost"
              colorScheme="gray"
              color="gray.600"
              onClick={onClose}
              size="xl"
            >
              {t('views.shared.button.discard', undefined, 'Discard')}
            </Button>
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  )
}
