import {
  AspectRatio,
  Flex,
  Image,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { useTranslation, Trans } from 'react-i18next'

import SurveySuccess from '@/images/project/survey-success.svg'

import { Modal } from '@/components/shared'

type SurveyModalProps = {
  disclosure: UseDisclosureReturn
  surveyUrl: string
}

export const SurveyModal = (props: SurveyModalProps) => {
  const { disclosure, surveyUrl } = props
  const { isOpen, onClose } = disclosure
  const { t } = useTranslation('domainCustomize')

  return (
    <Modal
      contentSx={{
        p: 0,
      }}
      isOpen={isOpen}
      maxW="1280px"
      onClose={() => {
        onClose()
        window.location.reload()
      }}
      closeButtonSx={{
        boxSize: '40px',
        color: 'white',
        fontSize: '16px',
        top: '0.75rem',
      }}
      isCentered
    >
      <Flex
        sx={{
          bgColor: '#604545',
          pb: 6,
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          color: 'white',
          textAlign: 'center',
          borderTopRadius: '6px',
        }}
      >
        <Image
          boxSize="120px"
          objectFit="cover"
          src={SurveySuccess}
          sx={{ marginTop: '-90px' }}
        />
        <Text sx={{ fontSize: '24px', fontWeight: 600 }}>
          {t('surveysensum.head')}
        </Text>
        <Text sx={{ fontSize: '20px', fontWeight: 500, mt: 2 }}>
          {t('surveysensum.subHead')}
        </Text>
        <Text sx={{ fontSize: '20px', fontWeight: 400, mt: 1 }}>
          <Trans>{t('surveysensum.detail')}</Trans>
        </Text>
      </Flex>

      <AspectRatio ratio={2 / 1}>
        <iframe
          style={{
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
          }}
          src={surveyUrl}
          allowFullScreen
        ></iframe>
      </AspectRatio>
    </Modal>
  )
}
