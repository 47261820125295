import { FormEvent, useState } from 'react'
import { Button } from '@chakra-ui/button'
import { HStack } from '@chakra-ui/layout'
import { Input } from '@chakra-ui/input'

import { useNonInitialEffect } from '@/lib/hooks'

type Props = {
  max: number
  min?: number
  defaultValue?: number
  step?: number
  onChange: (value: number) => void
  isDisabled: boolean
}

export const SubpathNumberInput = (props: Props) => {
  const {
    max,
    min = 1,
    onChange,
    defaultValue = 10,
    step = 10,
    isDisabled = false,
  } = props

  const [value, setValue] = useState(defaultValue.toString())

  const handleIncrease = () => {
    setValue((val) => {
      return Math.min(max, Number(val) + step).toString()
    })
  }

  const handleDecrease = () => {
    setValue((val) => {
      return Math.max(min, Number(val) - step).toString()
    })
  }

  const handleBlur = (e: FormEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value)

    if (value > max) setValue(max.toString())
    if (value < min) setValue(min.toString())
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }

  useNonInitialEffect(() => {
    onChange(Number(value))
  }, [value])

  return (
    <HStack
      sx={{
        p: '8px',
        border: '1px solid',
        borderColor: 'gray.300',
        borderRadius: '4px',
      }}
    >
      <Button
        sx={buttonStyle}
        variant="outline"
        onClick={handleDecrease}
        isDisabled={Number(value) <= min || isDisabled}
      >
        -
      </Button>
      <Input
        size="sm"
        sx={{ textAlign: 'center', border: 'none', fontSize: '16px' }}
        inputMode="decimal"
        type="number"
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        isDisabled={isDisabled}
      />
      <Button
        sx={buttonStyle}
        variant="outline"
        onClick={handleIncrease}
        isDisabled={Number(value) >= max || isDisabled}
      >
        +
      </Button>
    </HStack>
  )
}

const buttonStyle = {
  minW: '32px',
  p: 0,
}
