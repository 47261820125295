import {
  Grid,
  HStack,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useFormContext, useController } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { FormControl } from '@/components/FormControl'

import { FreeTrialFormInput } from '../types'
import { NDATA_SALES_EMAIL } from '@/constants/links'

export const AddressForm = (): React.ReactElement => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<FreeTrialFormInput>()
  const { t } = useTranslation(['freeTrial'])

  const { field } = useController({ control, name: 'taxpayer_type' })

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Text>{t('form.billingInformation')}</Text>
        <Text sx={{ fontSize: 'xs' }}>{t('form.taxInvoice')}</Text>
      </Stack>

      <Grid sx={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
        <RadioGroup
          colorScheme="brand"
          sx={{
            gridColumn: '1 / -1',
          }}
          {...field}
        >
          <HStack>
            <Radio value="individual">{t('form.individual')}</Radio>
            <Radio value="company">{t('form.company')}</Radio>
          </HStack>
        </RadioGroup>

        <Input
          isRequired
          placeholder={t('form.namePlaceholder')}
          size="lg"
          {...register('name')}
        />
        <FormControl errorMsg={errors.tax_id?.message}>
          <Input
            isRequired
            maxLength={13}
            minLength={13}
            size="lg"
            {...register('tax_id')}
            placeholder={t('form.taxIdPlaceholder')}
          />
        </FormControl>
        <Input
          isRequired
          placeholder={t('form.addressPlaceholder')}
          size="lg"
          sx={{ gridColumn: '1/-1' }}
          {...register('address')}
        />
        <Text
          textStyle="formHelper"
          sx={{
            gridColumn: '1/-1',
            a: {
              color: 'brand.400',
            },
          }}
        >
          <Trans t={t} i18nKey="form.contactUsAt">
            หากต้องการใบเสนอราคา ติดต่อได้ที่ Live Chat ของ Cookie Wow หรือ
            <Link href={`mailto:${NDATA_SALES_EMAIL}`}>
              {{ mail: NDATA_SALES_EMAIL }}
            </Link>
            &nbsp;หรือ โทร
            <Link href="tel:020245560">02-024-5560</Link>
          </Trans>
        </Text>
      </Grid>
    </Stack>
  )
}
