import React, { Fragment, PropsWithChildren } from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { VerifyPasswordForm } from './VerifyPasswordForm'

import { useVerifyPassword } from '@/features/settings/mutations'
import { useUserSecurity } from '../queries'

type PasswordWrapperProps = PropsWithChildren<{
  onCancel: () => void
  title?: string
}>

export const PasswordWrapper = (
  props: PasswordWrapperProps,
): React.ReactElement => {
  const { title, children, onCancel } = props

  const { data: userSecurity } = useUserSecurity()

  const { mutate: verifyPassword, isLoading } = useVerifyPassword()
  const { t: tSettings } = useTranslation('settings')

  if (!userSecurity?.password_required) return <Fragment>{children}</Fragment>

  return (
    <Box>
      <Box sx={{ p: '16px 24px' }}>
        <Heading as="h3" sx={{ p: 0, fontSize: '24px' }}>
          {title ?? tSettings('passwordWrapper.title')}
        </Heading>
        <Text sx={{ fontSize: '12px', color: 'gray.600' }}>
          {tSettings('passwordWrapper.subtitle')}
        </Text>
      </Box>

      <Box sx={{ p: 6, py: 2 }}>
        <VerifyPasswordForm
          isLoading={isLoading}
          onCancel={onCancel}
          onSubmit={(data) => {
            verifyPassword({
              data,
            })
          }}
        />
      </Box>
    </Box>
  )
}
