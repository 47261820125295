import { Fragment } from 'react'
import { Stack, Button, HStack, Text, useDisclosure } from '@chakra-ui/react'
import { IoAlert } from 'react-icons/io5'

import { formatDateTime, t } from '@/lib/helpers'
import { useAbility } from '@/features/permissions'
import { logEvent } from '@/lib/stats'

import { BumpVersionModal } from '../../components'

export type PublishVersionSectionProps = {
  draftVersion: DraftVersion
}

export const PublishVersionSection = (props: PublishVersionSectionProps) => {
  const { draftVersion } = props

  const disclosure = useDisclosure()
  const ability = useAbility()
  const canPublish = ability.can('publish', 'customization')

  return (
    <Fragment>
      <HStack spacing="4" alignItems="center">
        <Stack spacing="0" sx={{ fontWeight: 600, textAlign: 'end' }}>
          <Text sx={{ fontSize: '12px', color: 'gray.600' }}>
            {t(
              'views.features.domains.show.version.last_published',
              undefined,
              'Last Published',
            )}
            :&nbsp;{formatDateTime(draftVersion.last_published_at)}
          </Text>
          {draftVersion.changes_count && (
            <Text
              sx={{
                fontSize: '12px',
                color: 'white',
                bgColor: '#7887FF',
                borderRadius: '50px',
                padding: '3px 10px',
              }}
            >
              {t(
                'views.features.domains.show.version.unpublished_changes_detected',
                undefined,
                'Unpublished changes detected',
              )}
            </Text>
          )}
        </Stack>
        <Button
          size="lg"
          onClick={() => {
            logEvent({
              ga: {
                category: 'Publish Banner',
                action: 'Click publish banner',
              },
            })
            disclosure.onOpen()
          }}
          isDisabled={!canPublish}
          sx={{ pos: 'relative' }}
        >
          {t(
            'views.features.domains.show.version.publish_banner',
            undefined,
            'Publish Banner',
          )}
          {Boolean(draftVersion.changes_count) && (
            <IoAlert
              style={{
                backgroundColor: '#7887FF',
                color: 'white',
                borderRadius: '18px',
                position: 'absolute',
                width: '18px',
                height: '18px',
                top: '-7px',
                right: '-8px',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
                textAlign: 'center',
                padding: '3px',
              }}
            />
          )}
        </Button>
      </HStack>

      {canPublish && (
        <BumpVersionModal
          disclosure={disclosure}
          nextVersion={draftVersion.name}
          changesSummary={{
            bannerConsentModel:
              draftVersion.changes_summary.banner_consent_model,
            cookieConsentModel:
              draftVersion.changes_summary.category_consent_model,
            cookieCategories: draftVersion.changes_summary.cookie_category,
            cookieContent: draftVersion.changes_summary.cookies_content_script,
            bannerStyle: draftVersion.changes_summary.banner_style,
          }}
          warningFlags={{
            reconsentNeeded: draftVersion.reconsent_needed,
            willResetStat: draftVersion.will_reset_stat,
          }}
        />
      )}
    </Fragment>
  )
}
