import { ChangeEvent, Fragment } from 'react'
import { Box, Grid, Switch, Text, useDisclosure } from '@chakra-ui/react'

import { Enable2FAModal } from '../Enable2FAModal'
import { Disable2FAModal } from '../Disable2FAModal'

import { t } from '@/lib/helpers'

import { UpgradeText } from '@/features/domains/Customize/components/shared'
import { useAppContext } from '@/features/layout'

type Props = {
  is2FactorEnabled: boolean | null
  isPasswordRequired: boolean
}

export const TwoFactorSetting = (props: Props) => {
  const { is2FactorEnabled, isPasswordRequired } = props

  const { isFree } = useAppContext()

  const enableDisclosure = useDisclosure()
  const disableDisclosure = useDisclosure()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!is2FactorEnabled) {
      enableDisclosure.onOpen()
    } else {
      disableDisclosure.onOpen()
    }

    e.preventDefault()
  }

  return (
    <Fragment>
      <Grid
        sx={{
          gridTemplateColumns: '400px 48px',
          justifyContent: 'space-between',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <Text>{t('views.features.two_factor.authenticator_app')}</Text>
        <Box>
          <Switch
            size="lg"
            isDisabled={!is2FactorEnabled && isFree}
            isChecked={Boolean(is2FactorEnabled)}
            onChange={handleChange}
          />
        </Box>
        <Text sx={{ color: 'gray.600', fontSize: '12px' }}>
          {t('views.features.two_factor.authenticator_app_detail')}
        </Text>
      </Grid>
      <UpgradeText
        linkID="TwoFactorSetting-upgrade"
        sx={{ bg: 'brand.25', px: 3, py: 2, borderRadius: 'md' }}
      />

      <Enable2FAModal
        disclosure={enableDisclosure}
        isPasswordRequired={isPasswordRequired}
      />
      <Disable2FAModal
        disclosure={disableDisclosure}
        isPasswordRequired={isPasswordRequired}
      />
    </Fragment>
  )
}
